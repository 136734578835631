import React from "react";
import PropTypes from "prop-types";

// ant design
import { Form, Select } from "antd";
import TextNameAuthEvaluated from "../common/TextNameAuthEvaluated";
import { languagesPopular } from "../../../utils/constants";

const FormItemSelectPrimaryLanguage = ({
  label,
  name,
  validateStatus,
  help,
  hasFeedback,
  mode,
}) => {
  return (
    <Form.Item
      label={label}
      name={name}
      validateStatus={validateStatus}
      help={help}
      hasFeedback={hasFeedback}
    >
      <Select
        mode={mode}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) =>
          optionA.children
            .toString().toLowerCase()
            .localeCompare(optionB.children.toString().toLowerCase())
        }
      >
        {languagesPopular.map((e) => (
          <Select.Option key={e} value={e}>
            {e}
            {/* overkill permissions check, disabled until further discussion */}
            {/* <TextNameAuthEvaluated entity={language} /> */}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

FormItemSelectPrimaryLanguage.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  help: PropTypes.string,
  validateStatus: PropTypes.string,
  hasFeedback: PropTypes.bool,
  mode: PropTypes.string,
};

export default FormItemSelectPrimaryLanguage;
