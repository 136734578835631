import { gql } from "@apollo/client";

export const PRINT_FORMTAMES5 = gql`
  mutation printStudentFormTames5(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $studentDob: String
    $intdAdverseEffects: String
    $intdEducationalNeeds: String
    $intdEligible: Boolean
    $piAdverseEffects: String
    $piEducationalNeeds: String
    $piEligible: Boolean
    $sldAdverseEffects: String
    $sldEducationalNeeds: String
    $sldEligible: Boolean
    $viAdverseEffects: String
    $viEducationalNeeds: String
    $viEligible: Boolean
    $hiAdverseEffects: String
    $hiEducationalNeeds: String
    $hiEligible: Boolean
    $dAdverseEffects: String
    $dEducationalNeeds: String
    $dEligible: Boolean
    $dbAdverseEffects: String
    $dbEducationalNeeds: String
    $dbEligible: Boolean
    $ohiAdverseEffects: String
    $ohiEducationalNeeds: String
    $ohiEligible: Boolean
    $edAdverseEffects: String
    $edEducationalNeeds: String
    $edEligible: Boolean
    $ohAdverseEffects: String
    $ohEducationalNeeds: String
    $ohEligible: Boolean
    $mdAdverseEffects: String
    $mdEducationalNeeds: String
    $mdEligible: Boolean
    $autAdverseEffects: String
    $autEducationalNeeds: String
    $autEligible: Boolean
    $tbiAdverseEffects: String
    $tbiEducationalNeeds: String
    $tbiEligible: Boolean
    $conferenceNotes: String
    $formAction: String
$formStatus: String
  ) {
    printStudentFormTames5(
      studentFormTames5Input: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        studentDob: $studentDob
        intdAdverseEffects: $intdAdverseEffects
        intdEducationalNeeds: $intdEducationalNeeds
        intdEligible: $intdEligible
        piAdverseEffects: $piAdverseEffects
        piEducationalNeeds: $piEducationalNeeds
        piEligible: $piEligible
        sldAdverseEffects: $sldAdverseEffects
        sldEducationalNeeds: $sldEducationalNeeds
        sldEligible: $sldEligible
        viAdverseEffects: $viAdverseEffects
        viEducationalNeeds: $viEducationalNeeds
        viEligible: $viEligible
        hiAdverseEffects: $hiAdverseEffects
        hiEducationalNeeds: $hiEducationalNeeds
        hiEligible: $hiEligible
        dAdverseEffects: $dAdverseEffects
        dEducationalNeeds: $dEducationalNeeds
        dEligible: $dEligible
        dbAdverseEffects: $dbAdverseEffects
        dbEducationalNeeds: $dbEducationalNeeds
        dbEligible: $dbEligible
        ohiAdverseEffects: $ohiAdverseEffects
        ohiEducationalNeeds: $ohiEducationalNeeds
        ohiEligible: $ohiEligible
        edAdverseEffects: $edAdverseEffects
        edEducationalNeeds: $edEducationalNeeds
        edEligible: $edEligible
        ohAdverseEffects: $ohAdverseEffects
        ohEducationalNeeds: $ohEducationalNeeds
        ohEligible: $ohEligible
        mdAdverseEffects: $mdAdverseEffects
        mdEducationalNeeds: $mdEducationalNeeds
        mdEligible: $mdEligible
        autAdverseEffects: $autAdverseEffects
        autEducationalNeeds: $autEducationalNeeds
        autEligible: $autEligible
        tbiAdverseEffects: $tbiAdverseEffects
        tbiEducationalNeeds: $tbiEducationalNeeds
        tbiEligible: $tbiEligible
        conferenceNotes: $conferenceNotes
formAction: $formAction
formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;
