import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { useQuery } from '@apollo/client'

// ant design
import { Form, Input, DatePicker, Radio, Alert, Typography, Card, Select } from 'antd'

import FormContainer from '../../../FormContainer'
import FormItem from 'antd/lib/form/FormItem'
import Title from 'antd/lib/typography/Title'

import { PRINT_FORM3457K } from '../../../../operations/mutations/forms/3457K'
import { QUERY_STUDENT_COMMON_SIS } from '../../../../operations/queries/forms/SIS'
import { QUERY_FORM3457K_VERSION } from '../../../../operations/queries/forms/3457K'

import FormCollaboration from '../../../FormCollaboration'

import { IepFormContext } from '../../../../utils/iepFormProvider'
import StudentFormActions from '../StudentFormActions'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import StudentDobSIS from '../sis/StudentDobSIS'

const { Paragraph } = Typography

const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId
}) => {
  const [form] = Form.useForm()
  const { itemErrors, formError } = useContext(FormErrorContext)

  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()
    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      studentMeetingId: studentMeetingId
    }
  }
  const options = [{
    value: 'Father',
    label: 'Father',
  }, {
    value: 'Mother',
    label: 'Mother',
  }, {
    value: 'Adoptive Parent',
    label: 'Adoptive Parent',
  }, {
    value: 'Grandparent',
    label: 'Grandparent',
  }, {
    value: 'Other Relative',
    label: 'Other Relative',
  }, {
    value: 'Legal Guardian',
    label: 'Legal Guardian',
  }, {
    value: 'Foster Parent',
    label: 'Foster Parent',
  }]
  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    const update = Object.assign({})
    switch (fieldName) {
      case 'parentDelegatedRightsSignatureDate':
      case 'formDate':
      case 'studentDelegatedRightsSignatureDate':
      case 'authSignatureDate':
      case 'notDelegatedRightsDate':
      case 'terminateRightsStudentSignatureDate':
        update[fieldName] = moment(fieldValue)
        break
      case 'terminateRights':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        setTerminateRights(update[fieldName])
        break
      case 'notDelegatedRights':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        setNotDelegatedRights(update[fieldName])
        break
      case 'delegatedRights':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        setDelegatedRights(update[fieldName])
        break
      case 'ageOfMajorityDate':
        update[fieldName] = fieldValue ? moment(fieldValue) : ''
        break
      default:
        update[fieldName] = fieldValue
    }
    return update
  }

  const [notDelegatedRights, setNotDelegatedRights] = useState(
    data?.notDelegatedRights
  )
  const [delegatedRights, setDelegatedRights] = useState(data?.delegatedRights)
  const [terminateRights, setTerminateRights] = useState(data?.terminateRights)

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }

  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          formDisabledState,
          canSave,
          handleActionEvents,
          validationProps
        }) => (
          <Form
            form={form}
            id='studentForm3457K'
            name='studentForm3457K'
            initialValues={data}
            layout='vertical'
            preserve
            className='page-padding-leftRight page-padding-topBottom form-labels-bold'
          >
            <Title level={4} className='center'>
              DELEGATION OF RIGHTS TO MAKE EDUCATIONAL DECISIONS
            </Title>
            <Form.Item
              label='Form Created Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
              />
            </Form.Item>
            <Card
              title='Student SIS Data'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />

              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
              <StudentDobSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentDob}
                error={itemErrors.studentDob}
              />
            </Card>

            <Card
              title='Detail'
              style={{
                marginBottom: '30px'
              }}
            >
              <Form.Item
                label='Date of age majority'
                name='ageOfMajorityDate'
                {...validationProps('ageOfMajorityDate')}
              >
                <DatePicker
                  {...handleFocusEvents}
                  disabled={formDisabledState.ageOfMajorityDate}
                  format={[
                    'MM-DD-YYYY',
                    'MM/DD/YYYY',
                    'MM.DD.YY',
                    'M-D-YYYY',
                    'M/D/YYYY',
                    'M.D.YYYY',
                    'MM.DD.YYYY',
                    'MM-DD-YY',
                    'MM/DD/YY',
                    'M-D-YY',
                    'M/D/YY',
                    'M.D.YY',
                    'MMDDYYYY',
                    'MMDDYY'
                  ]}
                />
              </Form.Item>
            </Card>
            <Card
              title='Rights Delegation'
              style={{
                marginBottom: 30
              }}
            >
              <Card
                style={{
                  marginBottom: '30px'
                }}
                hidden={delegatedRights || terminateRights}
              >
                <FormItem
                  label='I have received this form and have chosen NOT to delegate my rights'
                  name='notDelegatedRights'
                  {...validationProps('notDelegatedRights')}
                >
                  <Radio.Group
                    onChange={e => {
                      setNotDelegatedRights(e.target.value)
                      handleMouseEvents.onChange(e)
                    }}
                    buttonStyle='solid'
                    disabled={formDisabledState.notDelegatedRights}
                    name='notDelegatedRights'
                  >
                    <Radio.Button value>Yes</Radio.Button>
                    <Radio.Button value={false}>No</Radio.Button>
                  </Radio.Group>
                </FormItem>

                <Form.Item
                  hidden={!notDelegatedRights}
                  label='Student Signature Date'
                  name='notDelegatedRightsDate'
                  {...validationProps('notDelegatedRightsDate')}
                >
                  <DatePicker
                    {...handleFocusEvents}
                    disabled={formDisabledState.notDelegatedRightsDate}
                    format={[
                      'MM-DD-YYYY',
                      'MM/DD/YYYY',
                      'MM.DD.YY',
                      'M-D-YYYY',
                      'M/D/YYYY',
                      'M.D.YYYY',
                      'MM.DD.YYYY',
                      'MM-DD-YY',
                      'MM/DD/YY',
                      'M-D-YY',
                      'M/D/YY',
                      'M.D.YY',
                      'MMDDYYYY',
                      'MMDDYY'
                    ]}
                  />
                </Form.Item>
              </Card>

              <Card
                style={{
                  marginBottom: '30px'
                }}
                hidden={notDelegatedRights || terminateRights}
              >
                <FormItem
                  label='Have CHOSEN to delegate my rights to the individual listed below.'
                  name='delegatedRights'
                  {...validationProps('delegatedRights')}
                >
                  <Radio.Group
                    onChange={e => {
                      setDelegatedRights(e.target.value)
                      handleMouseEvents.onChange(e)
                    }}
                    buttonStyle='solid'
                    disabled={formDisabledState.delegatedRights}
                    name='delegatedRights'
                  >
                    <Radio.Button value>Yes</Radio.Button>
                    <Radio.Button value={false}>No</Radio.Button>
                  </Radio.Group>
                </FormItem>

                <Form.Item
                  label='Name of “Parent” representative'
                  name='parentName'
                  hidden={!delegatedRights}
                  {...validationProps('parentName')}
                >
                  <Input
                    {...handleFocusEvents}
                    disabled={formDisabledState.parentName}
                  />
                </Form.Item>

                <Form.Item
                  label='“Parent” representative/student Relationship'
                  name='parentRelationship'
                  hidden={!delegatedRights}
                  {...validationProps('parentRelationship')}
                >
                  <Select      {...handleFocusEvents} options={options}
                    disabled={formDisabledState.parentRelationship} />
                  {/* <Input
                    {...handleFocusEvents}
                    disabled={formDisabledState.parentRelationship}
                  /> */}
                </Form.Item>

                <Form.Item
                  hidden={!delegatedRights}
                  label='Parent Signature Date'
                  name='parentDelegatedRightsSignatureDate'
                  {...validationProps('parentDelegatedRightsSignatureDate')}
                >
                  <DatePicker
                    {...handleFocusEvents}
                    disabled={
                      formDisabledState.parentDelegatedRightsSignatureDate
                    }
                    format={[
                      'MM-DD-YYYY',
                      'MM/DD/YYYY',
                      'MM.DD.YY',
                      'M-D-YYYY',
                      'M/D/YYYY',
                      'M.D.YYYY',
                      'MM.DD.YYYY',
                      'MM-DD-YY',
                      'MM/DD/YY',
                      'M-D-YY',
                      'M/D/YY',
                      'M.D.YY',
                      'MMDDYYYY',
                      'MMDDYY'
                    ]}
                  />
                </Form.Item>

                <Form.Item
                  hidden={!delegatedRights}
                  label='Student Signature Date'
                  name='studentDelegatedRightsSignatureDate'
                  {...validationProps('studentDelegatedRightsSignatureDate')}
                >
                  <DatePicker
                    {...handleFocusEvents}
                    disabled={
                      formDisabledState.studentDelegatedRightsSignatureDate
                    }
                    format={[
                      'MM-DD-YYYY',
                      'MM/DD/YYYY',
                      'MM.DD.YY',
                      'M-D-YYYY',
                      'M/D/YYYY',
                      'M.D.YYYY',
                      'MM.DD.YYYY',
                      'MM-DD-YY',
                      'MM/DD/YY',
                      'M-D-YY',
                      'M/D/YY',
                      'M.D.YY',
                      'MMDDYYYY',
                      'MMDDYY'
                    ]}
                  />
                </Form.Item>

                <Form.Item
                  hidden={!delegatedRights}
                  label='Authorized School Personnel Signature Date'
                  name='authSignatureDate'
                  {...validationProps('authSignatureDate')}
                >
                  <DatePicker
                    {...handleFocusEvents}
                    disabled={formDisabledState.authSignatureDate}
                    format={[
                      'MM-DD-YYYY',
                      'MM/DD/YYYY',
                      'MM.DD.YY',
                      'M-D-YYYY',
                      'M/D/YYYY',
                      'M.D.YYYY',
                      'MM.DD.YYYY',
                      'MM-DD-YY',
                      'MM/DD/YY',
                      'M-D-YY',
                      'M/D/YY',
                      'M.D.YY',
                      'MMDDYYYY',
                      'MMDDYY'
                    ]}
                  />
                </Form.Item>
              </Card>

              <Card hidden={delegatedRights || notDelegatedRights}>
                <FormItem
                  label='(REQUIRED, WHEN APPLICABLE) ‐ I wish to TERMINATE the Delegation of Rights at this time and assume the right to make my
                  own decisions regarding my education.'
                  name='terminateRights'
                  {...validationProps('terminateRights')}
                >
                  <Radio.Group
                    onChange={e => {
                      setTerminateRights(e.target.value)
                      handleMouseEvents.onChange(e)
                    }}
                    buttonStyle='solid'
                    disabled={formDisabledState.terminateRights}
                    name='terminateRights'
                  >
                    <Radio.Button value>Yes</Radio.Button>
                    <Radio.Button value={false}>No</Radio.Button>
                  </Radio.Group>
                </FormItem>

                <Form.Item
                  hidden={!terminateRights}
                  label='Student Signature Date'
                  name='terminateRightsStudentSignatureDate'
                  {...validationProps('terminateRightsStudentSignatureDate')}
                >
                  <DatePicker
                    {...handleFocusEvents}
                    disabled={
                      formDisabledState.terminateRightsStudentSignatureDate
                    }
                    format={[
                      'MM-DD-YYYY',
                      'MM/DD/YYYY',
                      'MM.DD.YY',
                      'M-D-YYYY',
                      'M/D/YYYY',
                      'M.D.YYYY',
                      'MM.DD.YYYY',
                      'MM-DD-YY',
                      'MM/DD/YY',
                      'M-D-YY',
                      'M/D/YY',
                      'M.D.YY',
                      'MMDDYYYY',
                      'MMDDYY'
                    ]}
                  />
                </Form.Item>
              </Card>
            </Card>

            {formError && <Paragraph type='danger'>{formError}</Paragraph>}
            <StudentFormActions
              query={PRINT_FORM3457K}
              extractDataProp='printStudentForm3457K'
              canSave={canSave}
              
              handleActionEvents={handleActionEvents}
              getFormFieldValues={getFormFieldValues}
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired
}

const StudentForm3457K = () => {
  // get current form information
  const { currentStudentInfo, currentEditableForm, studentMeetingId } =
    useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId } = currentEditableForm

  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    QUERY_STUDENT_COMMON_SIS,
    { variables: { id: studentId } }
  )
  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORM3457K_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )

  const createInitialFormData = (studentInfo, versionInfo) => {
    let initialValues = {}

    if (!studentInfo || !versionInfo) return initialValues

    const { studentForm3457K: form } = versionInfo
    const { student } = studentInfo
    const { dateOfBirth } = student

    const {
      formDate,
      studentFullName,
      studentDob,
      ageOfMajorityDate,
      notDelegatedRightsDate,
      parentDelegatedRightsSignatureDate,
      studentDelegatedRightsSignatureDate,
      authSignatureDate,
      terminateRightsStudentSignatureDate
    } = form

    initialValues = {
      ...form,
      formDate: formDate ? moment(formDate) : moment(Date.now()),
      studentFullName: studentFullName || student.fullName || '',
      studentDob: studentDob
        ? moment(studentDob).format('YYYY-MM-DD')
        : dateOfBirth
          ? moment(dateOfBirth).format('YYYY-MM-DD')
          : moment(Date.now()).format('YYYY-MM-DD'),
      ageOfMajorityDate: ageOfMajorityDate
        ? moment(ageOfMajorityDate)
        : moment(Date.now()),
      notDelegatedRightsDate: notDelegatedRightsDate
        ? moment(notDelegatedRightsDate)
        : moment(Date.now()),
      parentDelegatedRightsSignatureDate: parentDelegatedRightsSignatureDate
        ? moment(parentDelegatedRightsSignatureDate)
        : moment(Date.now()),
      studentDelegatedRightsSignatureDate: studentDelegatedRightsSignatureDate
        ? moment(studentDelegatedRightsSignatureDate)
        : moment(Date.now()),
      authSignatureDate: authSignatureDate
        ? moment(authSignatureDate)
        : moment(Date.now()),
      terminateRightsStudentSignatureDate: terminateRightsStudentSignatureDate
        ? moment(terminateRightsStudentSignatureDate)
        : moment(Date.now())
    }

    return initialValues
  }

  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
          />
        }
      />
    </FormErrorProvider>
  )
}

export default StudentForm3457K
