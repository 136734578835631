import React, { useContext } from 'react'

import Page from '../../components/Page'
import SchoolReports from '../../components/sections/report/SchoolReports'
import Card from 'antd/lib/card/Card'
import AuthContext from '../../utils/authProvider'
const SchoolReportPage = () => {
  const breadcrumb = [
    {
      path: '/',
      breadcrumbName: 'Home'
    },
    {
      path: '',
      breadcrumbName: 'School report'
    }
  ]
  const Auth = useContext(AuthContext)
  const isSuperAdmin = Auth.hasRole('SUPER_ADMIN')
  return (
    <>
      <Page title='School report' breadcrumb={breadcrumb}>
        <SchoolReports />
      </Page>
    </>
  )
}

export default SchoolReportPage
