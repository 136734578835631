import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useFormState,
  useRef
} from 'react'
import PropTypes from 'prop-types'
import { useMutation, useSubscription } from '@apollo/client'
import AuthContext from '../utils/authProvider'
import { IepFormContext } from '../utils/iepFormProvider'
import {
  notificationError,
  notificationSuccess,
  notificationWarn
} from '../utils/notification'
import { MUTATE_FORM_COLLABORATION } from '../operations/mutations/collaboration'
import { FORM_COLLABORATION_SUBSCRIPTION } from '../operations/subscriptions/collaboration'
import { Alert, Tag, Typography } from 'antd'
import { MeetingSISContext } from '../utils/meetingSISProvider'
import { mapAccompanyingSIS } from '../utils/mapAccompanyingSIS'
import { has } from 'lodash'

const resetFieldEnableState = (form, isDisabled = false) => {
  const fieldWithValues = form.getFieldsValue()
  const fieldKeys = Object.keys(fieldWithValues)
  const initialState = Object.create({})

  fieldKeys.forEach(key => {
    initialState[key] = isDisabled
  })
  return initialState
}

const extractFieldName = fieldId => fieldId.split(/_(.+)/)[1]
let collaborationLock = false

const FormCollaboration = props => {
  const { form, handleUpdate, itemErrors } = props
  const Auth = useContext(AuthContext)
  const {
    hasAddPermission,
    hasPrintPermission,
    hasViewPermission,
    currentEditableForm,
    setCurrentEditableForm
  } = useContext(IepFormContext)
  const { 
    studentFormId, 
    formStatusEnumName,
    isFinalized,
    newFormStatus,
    isFinalizeChecked,
    isAmendChecked,
  } = currentEditableForm
  const [formFieldsDisabled, setFormFieldsDisabled] = useState(
    resetFieldEnableState(form)
  )
  const [formFieldCurrentUser, setFormFieldCurrentUser] = useState({})
  const [formCollaborators, setFormCollaborators] = useState([])
  const [canSave, setCanSave] = useState(true)
  const [mutateFormCollaboration] = useMutation(MUTATE_FORM_COLLABORATION)
  const { data: subscription } = useSubscription(
    FORM_COLLABORATION_SUBSCRIPTION,
    { variables: { studentFormId }, fetchPolicy: 'network-only' }
  )
  const { meta } = useContext(MeetingSISContext)

  const setNewFormStatus = (formStatus) => {
    setCurrentEditableForm({
      ...currentEditableForm,
      newFormStatus: formStatus
    })
  }

  const setIsAmendChecked = (newIsAmendChecked) => {
    setCurrentEditableForm({
      ...currentEditableForm,
      isAmendChecked: newIsAmendChecked
    })
  }

  const setIsFinalizeChecked = (newIsFinalizeChecked) => {
    setCurrentEditableForm({
      ...currentEditableForm,
      isFinalizeChecked: newIsFinalizeChecked
    })
  }

  //--------------------------------------------------------------------
  //Sends user's current fieldName/fieldValue/onFocus(true/false) to api.
  //Called by:
  //  handleFocusEvents.onFocus()
  //  handleFocusEvents.onBlur()
  //  handleMouseEvents.onChange()
  //  handleEditorEvents.onFocus()
  //  handleEditorEvents.onBlur()
  //--------------------------------------------------------------------
  const changeCollaborationStatus = async (
    eventId,
    fieldName,
    fieldValue = ''
  ) => {
    //console.log('enter collab', eventId, fieldName, fieldValue)
    switch (eventId) {
      case 'SETVALUE':
        try {
          // Empty field hack
          // TODO: find a better alternative
          if (fieldValue === '') {
            fieldValue = ' '
          }
          if (Array.isArray(fieldValue)) {
            //console.log('changecollabstat',fieldName,fieldValue,typeof(fieldValue),fieldValue.length,fieldValue.join('|'))
            //fieldValue = fieldValue.toString()
            fieldValue = fieldValue.length > 0 ? fieldValue.join('|') : ""
          }
          if (typeof fieldValue === 'number') {
            fieldValue = fieldValue.toString()
          }
          await mutateFormCollaboration({
            variables: {
              studentFormId,
              eventId: 'SETVALUE',
              fieldName,
              fieldValue
            }
          })
        } catch (e) {
          //console.log('formcollanail',e)
          notificationError('Form collaboration failed')
        }
        break
      case 'GETFOCUS':
        try {
          await mutateFormCollaboration({
            variables: { studentFormId, eventId: 'GETFOCUS', fieldName }
          })
        } catch (e) {
          notificationError('Form collaboration failed')
        }
        break
      case 'SETFORMSTATUS':
        //console.log('changeCollaborationStatus-SETFORMSTATUS',fieldName,fieldValue,newFormStatus)
        try {
          await mutateFormCollaboration({
            variables: {
              studentFormId,
              eventId: 'SETFORMSTATUS',
              fieldName: fieldName,
              fieldValue: fieldValue
            }
          })
        } catch (e) {
          notificationError('Form status collaboration failed')
        }
        break
      case 'SAVEFORM':
        try {
          await mutateFormCollaboration({
            variables: { 
              studentFormId,
              eventId: 'SAVEFORM',
              fieldName: 'formActionSave',
              fieldValue: fieldValue.toString() //true/false 
            }
          })
        } catch (e) {
          notificationError('Form save collaboration failed')
        }
        break
      case 'PRINTFORM':
        try {
          await mutateFormCollaboration({
            variables: { studentFormId, eventId: 'PRINTFORM' }
          })
        } catch (e) {
          notificationError('Form print failed')
        }
        break
      case 'DOWNLOADFORM':
        try {
          await mutateFormCollaboration({
            variables: { studentFormId, eventId: 'DOWNLOADFORM' }
          })
        } catch (e) {
          notificationError('Form download failed')
        }
        break
      }
    collaborationLock = false
  }

  // run on load
  useEffect(() => {
    //Notify other users that you have entered the form if you have edit rights
    if (hasPrintPermission) {
      mutateFormCollaboration({ variables: { studentFormId, eventId: 'JOIN' } })
    } else {
      setFormFieldsDisabled(resetFieldEnableState(form, true))
    }
  }, [])

  //Reset collaboration when a new studentFormId is opened.
  //This catches all instances where a user leaves a form
  //EXCEPT when they close the TA browser tab or the browser itself.
  useEffect(() => {
    return () => {
      mutateFormCollaboration({
        variables: { studentFormId, eventId: 'LEAVE' }
      })
    }
  }, [studentFormId])


  // Handle Meta Update
  // TODO: move this to MeetingSIS inputs ?
  useEffect(() => {
    // Convert this to only use the form fields
    form.setFieldsValue(mapAccompanyingSIS(currentEditableForm.formCode, meta))
  }, [meta])

  //
  useEffect(() => {
    setFormFieldsDisabled(resetFieldEnableState(form, isFinalized))
  }, [isFinalized])

  useEffect(() => {
    if (!subscription) return
    const {eventId, fieldName, fieldValue} = subscription.formCollaboration

    //Set local state if another user checked the finalize or amend checkbox
    if (fieldName === "amendCheckbox") {
      setIsAmendChecked(fieldValue === "AMENDED")
    }
    if (fieldName === "finalizeCheckbox") {
      setIsFinalizeChecked(fieldValue === "FINALIZED")
    }
    if (fieldName === "formActionSave") {
      // setCurrentEditableForm({
      if (isFinalizeChecked) {
        setCurrentEditableForm({
          ...currentEditableForm,
          formStatusEnumName: "FINALIZED",
          isFinalized: true,
          isAmendChecked: false,
          isFinalizeChecked: false
        })
      }
      if (isAmendChecked) {
        setCurrentEditableForm({
          ...currentEditableForm,
          formStatusEnumName: "AMENDED",
          isFinalized: false,
          isAmendChecked: true,
          isFinalizeChecked: false
        })
      }
      if (!isAmendChecked && !isFinalizeChecked) {
        setCurrentEditableForm({
          ...currentEditableForm,
          formStatusEnumName: "DRAFT",
          isFinalized: false,
          isAmendChecked: false,
          isFinalizeChecked: false
        })
      }
    }
    
  }, [subscription])

  //Handle updates published by the api
  //All collaboration updates received from api are handled here
  useMemo(() => {
    if (!subscription) return
    const {
      onFocusFields,
      users,
      updatedFields,
      canSave,
      eventId,
      currentUser,
      fieldName,
      fieldValue,
      pendingFormStatusChange
    } = subscription.formCollaboration

    //Display emails of all users currently editing the form at the top of the form
    setFormCollaborators(users)

    //Display group JOIN/LEAVE/SAVEFORM/SETFORMSTATUS notifications based on form action
    if (Auth.userId !== currentUser.id && currentUser.id != "") {
    //if (currentUser.id != "") { //Temporarily display messages even for current user.  Use above line for production.
      switch (eventId) {
        case "JOIN":
          notificationSuccess(currentUser.emailObfuscated + " opened this form");
          break;
        case "LEAVE":
          notificationSuccess(currentUser.emailObfuscated + " opened this form");
          break;
        case "SAVEFORM":
          notificationSuccess(currentUser.emailObfuscated + " saved this form");
          break;
        case "PRINTFORM":
          notificationSuccess(currentUser.emailObfuscated + " printed this form");
          break;
        case "DOWNLOADFORM":
          notificationSuccess(currentUser.emailObfuscated + " downloaded this form");
          break;
        case "SETFORMSTATUS":
          if (fieldName === "amendCheckbox") {
            if (fieldValue === "AMENDED") {
              setIsAmendChecked(true)
              setNewFormStatus("AMENDED")
              notificationWarn(currentUser.emailObfuscated + " checked this form for pending amendment")
            } else {
              setIsAmendChecked(false)
              setNewFormStatus("")
              notificationWarn(currentUser.emailObfuscated + " removed pending amendment for this form")
            }
          } else {
            if (fieldValue === "FINALIZED") {
              setIsFinalizeChecked(true)
              setNewFormStatus("FINALIZED")
              notificationWarn(currentUser.emailObfuscated + " checked this form for pending finalization")
            } else {
              setIsFinalizeChecked(false)
              setNewFormStatus("")
              notificationWarn(currentUser.emailObfuscated + " removed pending finalization for this form")
            }
          }
          break;
      }
      currentUser.id = ''
    }

    //Update all user's fields when they first join collaboration.
    if (eventId === 'JOIN') {
      updatedFields.forEach(field => {
        handleUpdate(field.fieldName, field.fieldValue)
      })
    }

    //Update a changed value
    if (eventId === 'SETVALUE') {
      handleUpdate(fieldName, fieldValue)
    }

    //Load a list of user emails for display under fields that are in use by any users with the form open
    const fieldsCurrentUser = Object.assign({})
    onFocusFields.forEach(field => {
      if (Auth.userId === field.user.id) return
      fieldsCurrentUser[field.fieldName] = field.user.emailObfuscated
    })

    //Handle enabling/disabling of fields based on permissions, finalize/amend checkbox status and field in use(onFocusFields) properties
    //1) Start with all fields enabled
    //2) Disable all fields if user lacks permission
    //3) Disable all fields if form is finalized or there is a pending change to amend/finalize (e.g. amend/finalize checked but not saved)
    //4) Disable any field that exists in the onFocusFields object(list of fields in use by all users with the form open)
    let fieldsDisabled = Object.assign({}, resetFieldEnableState(form, false))
    if (!hasAddPermission && !hasPrintPermission) {
      fieldsDisabled = Object.assign({}, resetFieldEnableState(form, true))
    } else if (currentEditableForm.formStatusEnumName === "FINALIZED" || fieldValue === "FINALIZED" || fieldValue === "AMENDED") {
      fieldsDisabled = Object.assign({}, resetFieldEnableState(form, true))
    }
    onFocusFields.forEach(field => {
      if (Auth.userId === field.user.id) return
      fieldsDisabled[field.fieldName] = true
    })
    setFormFieldCurrentUser(fieldsCurrentUser)
    setFormFieldsDisabled(fieldsDisabled)
    
    //NOTE ABOUT canSave:
    //canSave originally was used to control whether a form could be saved by a user.  It's set in the api subscription.js/loadResponseFromCache() code.
    //canSave is false until there are no onFocusFields array entries (no user in a form field).  This was then used to control enable/disable of form Save button.
    //This restriction is no longer necessary as form collaboration is based on the studentFormId and not the studentFormVersionId.
    setCanSave(canSave)
    //collaborationLock = false;
  }, [subscription])

  const handleFocusEvents = {
    onFocus: async e => {
      changeCollaborationStatus('GETFOCUS', extractFieldName(e.target.id))
    },
    onBlur: async e => {
      const fieldName = extractFieldName(e.target.id)
      //const fieldValue = form.getFieldValue(fieldName);
      let fieldValue = e.target.value
        ? e.target.value
        : form.getFieldValue(fieldName)
      changeCollaborationStatus('SETVALUE', fieldName, fieldValue)
    }
  }

  const handleMouseEvents = {
    onChange: async e => {
      if (e.target.name !== '') {
        const fieldName = e.target.name
        let fieldValue = e.target.value
          ? e.target.value
          : form.getFieldValue(fieldName)
        fieldValue = fieldValue === null ? '' : fieldValue.toString()
        //console.log('handleMouseEvents',fieldValue,fieldName)
        changeCollaborationStatus('SETVALUE', fieldName, fieldValue)
      }
    }
  }

  const handleEditorEvents = {
    onFocus: async fieldName => {
      changeCollaborationStatus('GETFOCUS', fieldName)
    },
    onBlur: async (fieldName, fieldValue) => {
      changeCollaborationStatus('SETVALUE', fieldName, fieldValue)
    }
  }

  //Handles clicks from StudentFormActions component (save/print/download - amend/finalize)
  const handleActionEvents = {
    onClick: async (e, fieldName, fieldValue) => {
      switch (fieldName) {
        case 'formActionSave':
          if(e === ''){
            //console.log('how and why this is finalized')
            changeCollaborationStatus('SAVEFORM', fieldName, isFinalized)
          }

          
          break
        case 'formActionPrint':
          changeCollaborationStatus('PRINTFORM', fieldName)
          break
        case 'formActionDownload':
          changeCollaborationStatus('DOWNLOADFORM', fieldName)
          break
        case 'amendCheckbox':
          if(e === ''){changeCollaborationStatus('SETFORMSTATUS', fieldName, e.target.checked ? "AMENDED" : "")}
          
          break
        case 'finalizeCheckbox':
          if(e === ''){changeCollaborationStatus('SETFORMSTATUS', fieldName, e.target.checked ? "FINALIZED" : "")}

          
          break
      }
   }
};

  const validationProps = fieldName => {
    const help = () => {
      let message = ''
      if (itemErrors[fieldName]) {
        message = itemErrors[fieldName]
      }
      if (formFieldCurrentUser[fieldName]) {
        message +=
          message.length === 0
            ? `Currently being edited by ${formFieldCurrentUser[fieldName]}`
            : ` <===> Currently being edited by ${formFieldCurrentUser[fieldName]}`
      }
      return message
      // errors get priority
      // if (itemErrors[fieldName]) {
      //   return itemErrors[fieldName];
      // }
      // if (formFieldCurrentUser[fieldName]) {
      //   return `Currently being edited by ${formFieldCurrentUser[fieldName]}`;
      // }
      //if (formFieldsDisabled[fieldName]) {
      //  return `Currently being edited by ${formFieldCurrentUser[fieldName]}`;
      //}
      //return "";
    }
    const validateStatus = () => {
      if (itemErrors[fieldName]) {
        return 'error'
      }

      if (formFieldsDisabled[fieldName]) {
        return 'warning'
      }

      return ''
    }
    return {
      help: help(),
      validateStatus: validateStatus()
    }
  }

  const collaboratorsBadges = () => {
    return (
      <Typography>
        Currently editing document : &nbsp;
        {formCollaborators.map((user, index) => (
          <Tag color='default' key={index}>
            {user.emailObfuscated}
          </Tag>
        ))}
      </Typography>
    )
  }

  return (
    <>
      {formCollaborators.length ? (
        <Alert
          message={
            hasPrintPermission
              ? collaboratorsBadges()
              : 'Missing edit permissions'
          }
          type='info'
        />
      ) : (
        ''
      )}
      {!hasPrintPermission && (
        <Alert
          message='You need print permission to edit this form'
          type='info'
        />
      )}
      {/* Why do we only display validation props if user hasPrintPermission?  Changed so that view-only permissions can still see who is editing field*/}
      {hasPrintPermission
        ? props.children({
            handleFocusEvents,
            handleMouseEvents,
            handleEditorEvents,
            handleActionEvents,
            formDisabledState: formFieldsDisabled,
            validationProps,
            canSave,
          })
        : props.children({
            handleFocusEvents: () => {},
            handleMouseEvents: () => {},
            handleEditorEvents: () => {},
            handleActionEvents: () => {},
            formDisabledState: formFieldsDisabled,
            validationProps,
            // validationProps: () => {
            //   return {
            //     help: "",
            //     validateStatus: "",
            //   };
            // },
            canSave: false,
      })}
    </>
  )
}

FormCollaboration.propTypes = {
  children: PropTypes.element.isRequired,
  form: PropTypes.object.isRequired,
  studentFormId: PropTypes.string.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  itemErrors: PropTypes.any.isRequired
}

export default FormCollaboration
