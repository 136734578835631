// react
import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'

// Contexts
import AuthContext from '../../../utils/authProvider'

// ant design
import { Card, Button, Tag, Tooltip } from 'antd'

// operations
import { QUERY_DOCUMENTS } from '../../../operations/queries/documents'

// components
import DocumentSearchForm from './DocumentSearchForm'
import Listing from '../../Listing'

const DocumentList = () => {
  const Auth = useContext(AuthContext)
  const isSuperAdmin = Auth.hasRole('SUPER_ADMIN')
  const [criterias, setCriterias] = useState({})
  const canAddDocuments = Auth.hasPermission('ADD_DOCUMENTS')
  const extra = (
    <>
      {canAddDocuments && (
        <Tooltip title='Add new document'>
          <Link to='../document/add'>
            <Button type='primary'>Add Document</Button>
          </Link>
        </Tooltip>
      )}
    </>
  )

  const onSearch = criterias => {
    setCriterias(criterias)
  }
  const canEditDocuments = Auth.hasPermission('EDIT_DOCUMENTS')
  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      sorter: (a, b) => a.title.localeCompare(b.title),
      render: (value, record) => (
        <div>
          <div>
            {canEditDocuments ? (
              <Link to={`../document/${record.id}`}>{value}</Link>
            ) : (
              <>{value}</>
            )}
            &nbsp;
            {isSuperAdmin && record.hidden && <Tag color='orange'>Hidden</Tag>}
          </div>
          {isSuperAdmin && <div className='cellWithId'>{record.id}</div>}
        </div>
      )
    }
  ]
  return (
    <>
      <Listing
        query={QUERY_DOCUMENTS}
        columns={columns}
        dataKey='documents'
        criterias={criterias}
        render={table => {
          return (
            <>
              <div className='page-container'>
                <Card title='Search' bordered={false}>
                  <DocumentSearchForm onSearch={onSearch} />
                </Card>
              </div>
              <div className='page-container'>
                <Card title='Documents' bordered={false} extra={extra}>
                  {table}
                </Card>
              </div>
            </>
          )
        }}
      />
    </>
  )
}

export default DocumentList
