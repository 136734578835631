import { gql } from "@apollo/client";

export const QUERY_HEALTHPLAN_VERSION = gql`
  query studentFormHealthPlan(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
  ) {
    studentFormHealthPlan(
      studentFormId: $studentFormId
      studentId: $studentId
      formId: $formId
      schoolId: $schoolId
      studentMeetingId: $studentMeetingId
    ) {
      versionId
      formId
      formDate
      studentFullName
      studentDob  
      studentGrade
      startDate
      endDate
      service
      disorder
      medicalNecessity
      diagnosed
      doctorName
      dateOfDiagnosis
      officialDiagnosis
      minutes
      frequency
      location
      behavior
      behaviorNotes
      goal
      dismissed
      goalNotes
      formAction
      formStatus
    }
  }
`;
