import { gql } from "@apollo/client";

export const ADD_SUBROLE_TO_USER = gql`
  mutation addSubRoleToUser($userId: ID!, $subRoleId: ID!) {
    addSubRoleToUser(userId: $userId, subRoleId: $subRoleId) {
      id
      subRole {
        id
        enumName
      }
    }
  }
`;
