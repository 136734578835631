import React from "react";
import PropTypes from "prop-types";

// antd
import { Row, Col, Form, Button } from "antd";

// components
import FormItemSelectDistrict from "../../elements/formItems/FormItemSelectDistrict";

const DistrictReportSearchForm = ({ onSearch }) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    if (!values.districtId) {
      values.districtId = "";
    }
    onSearch(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.error("Failed:", errorInfo);
  };

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        id="districtReportSearchFormSearchForm"
        name="basic"
        initialValues={{}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={32}>
          <Col xl={6} lg={8} md={9} sm={12} xs={24}>
            <FormItemSelectDistrict
              label="District"
              name="districtId"
              required
            />
          </Col>
        </Row>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
        <Button
          style={{ margin: "0 8px" }}
          onClick={() => {
            form.resetFields();
            form.submit();
          }}
        >
          Reset
        </Button>
      </Form>
    </div>
  );
};

DistrictReportSearchForm.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

export default DistrictReportSearchForm;
