import { gql } from "@apollo/client";

export const QUERY_ROLES = gql`
  query {
    roles {
      id
      name
    }
  }
`;

export const QUERY_ROLE = gql`
  query Role($id: ID!) {
    role(id: $id) {
      id
      name
      hidden
      createdBy
      createdAtAgo
      updatedAtAgo
      createdAt(format: "ddd mmm dd yyyy h:MM:ss TT Z")
      updatedAt(format: "ddd mmm dd yyyy h:MM:ss TT Z")
    }
  }
`;
