import { useState } from 'react'
import { Button, Card } from 'antd'
import { Document, Page } from 'react-pdf'
import { GET_S3_SIGNED_URL } from '../../operations/queries/fileUploads'
import { useQuery } from '@apollo/client'
import './pdfStudent.css'
// commented out lines are to render all pages at once, may want this functionality in the future
const RenderStudentHistory = ({ pdfData }) => {
  let [numPages, setNumPages] = useState(null)
  let [pageNumber, setPageNumber] = useState(1)
  let [renderPdf, setRenderPdf] = useState(false)
  let { data: s3DataGet, loading: s3LoadingGet } = useQuery(GET_S3_SIGNED_URL, {
    variables: {
      fileName: pdfData.uniqueName,
      contentType: pdfData.contentType
    }
  })
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }
  const pageForward = () => {
    if (pageNumber++ <= numPages) {
      setPageNumber(pageNumber++)
    }
  }
  const pageBackward = () => {
    if (pageNumber-- != 0) {
      setPageNumber(pageNumber--)
    }
  }
  const showPdf = () => {
    setRenderPdf(true)
  }
  const hidePdf = () => {
    setRenderPdf(false)
  }
  return (
    <>
      {/* <Document
                  file={pdf}
                  onLoadSuccess={onDocumentLoadSuccess}
                  error=''
                  onError=''
                  onLoadError={''}
                >
                  {Array.apply(null, Array(numPages))
                    .map((x, i) => i + 1)
                    .map(page => (
                      <>
                        <Row>
                          <Col span={24}>
                            <Page
                              pageNumber={page}
                              error=''
                              renderTextLayer={true}
                              renderAnnotationLayer={false}
                              //    height='10'
                              //    renderInteractiveForms={true}
                            />
                          </Col>
                        </Row>
                      </>
                    ))}{' '}
                </Document> */}
      {s3DataGet != undefined && pdfData !== undefined && (
        <>
          <Card
            bodyStyle={{ padding: '2px' }}
            // below line does nothing, need to style the bodyStyle of the card itself for some stupid reason
            style={{ padding: '0px !important' }}
            renderTextLayer={false}
            title={pdfData.name}
            extra={
              <>
                {renderPdf === false ? (
                  <>
                    <Button onClick={showPdf}>Show Document</Button>
                  </>
                ) : (
                  <>
                    <Button onClick={hidePdf}>Hide Document</Button>
                  </>
                )}
              </>
            }
          >
            {renderPdf === true ? (
              <>
                <Document
                  file={{ url: s3DataGet.s3GetSignedURLRequest.signedURL }}
                  onLoadSuccess={onDocumentLoadSuccess}
                  error=''
                >
                  <Page pageNumber={pageNumber} />{' '}
                  <Button onClick={pageBackward} disabled={pageNumber <= 1}>
                    Previous
                  </Button>{' '}
                  <Button
                    onClick={pageForward}
                    disabled={pageNumber === numPages}
                  >
                    Next
                  </Button>
                </Document>
              </>
            ) : (
              <></>
            )}
          </Card>
        </>
      )}
    </>
  )
}

export default RenderStudentHistory
