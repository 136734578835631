import { gql } from "@apollo/client";

export const PRINT_FORM3454R = gql`
  mutation printStudentForm3454R(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $studentDisability: String
    $disabilityIncidents: String
    $studentIEPandPlacement: String
    $studentObservations: String
    $parentProvidedInformation: String
    $causeDisability: String
    $causeDistrict: String
    $causeBehavior: String
    $formAction: String
$formStatus: String
  ) {
    printStudentForm3454R(
      studentForm3454RInput: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        studentDisability: $studentDisability
        disabilityIncidents: $disabilityIncidents
        studentIEPandPlacement: $studentIEPandPlacement
        studentObservations: $studentObservations
        parentProvidedInformation: $parentProvidedInformation
        causeDisability: $causeDisability
        causeDistrict: $causeDistrict
        causeBehavior: $causeBehavior
formAction: $formAction
formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;
