// react
import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'

// Contexts
import AuthContext from '../../../utils/authProvider'

// ant design
import { Card, Button, Tag, Tooltip } from 'antd'

// operations
import { QUERY_DISTRICT_FORMS } from '../../../operations/queries/districts'
import { useQuery } from '@apollo/client'

// components
import DistrictFormSearchForm from './DistrictFormSearchForm'
import Listing from '../../Listing'

const getDistrictName = record =>
  record.district?.name ? record.district.name : record.districtId

const DistrictFormList = () => {
  const Auth = useContext(AuthContext)
  const isSuperAdmin = Auth.hasRole('SUPER_ADMIN')
  const [criterias, setCriterias] = useState({})
  const canAddDistrictForms = Auth.hasPermission('ADD_DISTRICT_FORMS')

  const extra = (
    <>
      {canAddDistrictForms && (
        <Tooltip title='Add new districtform'>
          <Link to='./districtform/add'>
            <Button type='primary'>Add form</Button>
          </Link>
        </Tooltip>
      )}
    </>
  )

  const onSearch = criterias => {
    setCriterias(criterias)
  }
  const { data, loading } = useQuery(QUERY_DISTRICT_FORMS)
  let setDistricts = new Set()
  let districtFilter = []
  const districtNames = data?.districtForms?.map(x =>
    setDistricts.add(x.district.name)
  )
  const filterDistricts = [...setDistricts]
  filterDistricts.map(x => {
    districtFilter.push({ text: x, value: x })
  })
  const canEditDistrictForms = Auth.hasPermission('EDIT_DISTRICT_FORMS')
  const columns = [
    {
      title: 'Name',
      dataIndex: 'form',
      sorter: (a, b) => a.form.name.localeCompare(b.form.name),
      render: (value, record) => (
        <div>
          <div>
            {canEditDistrictForms ? (
              <Link to={`./districtform/${record.id}`}>{value?.name}</Link>
            ) : (
              <>{value?.name}</>
            )}
            &nbsp;
            {isSuperAdmin && record.hidden && <Tag color='orange'>Hidden</Tag>}
          </div>
          {isSuperAdmin && <div className='cellWithId'>{record.id}</div>}
        </div>
      )
    },
    {
      title: 'Code',
      dataIndex: 'form',
      sorter: (a, b) => a.form.formCode.localeCompare(b.form.formCode),
      render: (value, record) => record.form?.formCode
    },
    // {
    //   title: 'Sub-Roles',
    //   dataIndex: 'form',
    //   render: (value, record) => (
    //     <div>
    //       {record.subRolesApplied.map(subRole => (
    //         <Tag key={Math.random()} color='geekblue'>
    //           {subRole}
    //         </Tag>
    //       ))}
    //     </div>
    //   )
    // },
    {
      title: `District`,
      dataIndex: 'districtName',
      filters: districtFilter,
      // filterSearch: true,
      onFilter: (value, record) => record.district.name.includes(value),
      render: (value, record) => <>{record.district.name} </>
    }
  ]
  return (
    <>
      {!loading && (
        <>
          <Listing
            query={QUERY_DISTRICT_FORMS}
            columns={columns}
            dataKey='districtForms'
            criterias={criterias}
            render={table => {
              return (
                <>
                  <div className='page-container'>
                    <Card title='Search' bordered={false}>
                      <DistrictFormSearchForm onSearch={onSearch} />
                    </Card>
                  </div>
                  <div className='page-container'>
                    <Card title='District forms' bordered={false} extra={extra}>
                      {table}
                    </Card>
                  </div>
                </>
              )
            }}
          />
        </>
      )}
    </>
  )
}

export default DistrictFormList
