import { useState, useContext } from 'react'
import { Button } from 'antd'
import { Document, Page } from 'react-pdf'
import { IepFormContext } from '../../utils/iepFormProvider'
import { GET_S3_SIGNED_URL } from '../../operations/queries/fileUploads'
import { useQuery } from '@apollo/client'
import './pdf.css'

const RenderPdf = () => {
  const { currentEditableForm } =
    useContext(IepFormContext)
  let [numPages, setNumPages] = useState(null)
  let [pageNumber, setPageNumber] = useState(1)

  const { data: s3DataGet, loading: s3LoadingGet } = useQuery(
    GET_S3_SIGNED_URL,
    {
      variables: {
        fileName: currentEditableForm.pdfData.uniqueName,
        contentType: currentEditableForm.pdfData.contentType
      }
    }
  )
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }
  const pageForward = () => {
    if (pageNumber++ <= numPages) {
      setPageNumber(pageNumber++)
    }
  }
  const pageBackward = () => {
    if (pageNumber-- != 0) {
      setPageNumber(pageNumber--)
    }
  }
  return (
    <>
      {currentEditableForm.pdfData && s3DataGet != undefined ? (
        <>
          <Document
            file={{ url: s3DataGet.s3GetSignedURLRequest.signedURL }}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} />{' '}
            <Button onClick={pageBackward} disabled={pageNumber <= 1}>
              Previous
            </Button>
            {' '}
            <Button onClick={pageForward} disabled={pageNumber === numPages}>
              Next
            </Button>
          </Document>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default RenderPdf
