import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { IepFormContext } from '../../../../utils/iepFormProvider'
import { useQuery } from '@apollo/client'
import { QUERY_STUDENT_COMMON_SIS } from '../../../../operations/queries/forms/SIS'
import { QUERY_FORM504A_VERSION } from '../../../../operations/queries/forms/504A'
import moment from 'moment'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import FormContainer from '../../../FormContainer'
import {
  Alert,
  Card,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Typography
} from 'antd'
import FormCollaboration from '../../../FormCollaboration'
import Title from 'antd/lib/typography/Title'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import StudentDobSIS from '../sis/StudentDobSIS'
import StudentParent1SIS from '../sis/StudentParent1SIS'
import StudentParent1PhoneSIS from '../sis/StudentParent1PhoneSIS'
import StudentParent2SIS from '../sis/StudentParent2SIS'
import StudentParent2PhoneSIS from '../sis/StudentParent2PhoneSIS'

import FormItem from 'antd/lib/form/FormItem'
import StudentFormActions from '../StudentFormActions'
import { PRINT_FORM504A } from '../../../../operations/mutations/forms/504A'
import Editor from '../../../elements/common/Editor'

const { Paragraph } = Typography

const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId
}) => {
  const [form] = Form.useForm()

  const { itemErrors, formError } = useContext(FormErrorContext)
  const [parentsList, setParentsList] = useState([])

  useEffect(() => {
    setParentsList(data.parents)
  }, [])

  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()
    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      studentMeetingId: studentMeetingId
    }
  }

  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    console.log(fieldName, fieldValue)
    const update = Object.assign({})
    switch (fieldName) {
      case 'formDate':
      case 'parentSignatureDate':
        update[fieldName] = fieldValue ? moment(fieldValue) : ''
        break
      case 'parentConsent':
        update[fieldName] = fieldValue ? fieldValue : 'no'
        break
      default:
        update[fieldName] = fieldValue
    }
    return update
  }

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }

  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          handleActionEvents,
          formDisabledState,
          canSave,
          validationProps
        }) => (
          <Form
            form={form}
            id='studentForm504A'
            name='studentForm504A'
            initialValues={data}
            preserve
            layout='vertical'
            className='page-padding-leftRight page-padding-topBottom form-labels-bold'
          >
            <Title level={4} className='center'>
              CONSENT FOR 504 EVALUATION
            </Title>
            <Form.Item
              label='Form Created Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
              />
            </Form.Item>

            <Card
              title='Student SIS Data'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />
              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
              <StudentDobSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentDob}
                error={itemErrors.studentDob}
              />
              <StudentParent1SIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.parent1FullName}
                error={itemErrors.parent1FullName}
              />
              <StudentParent2SIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.parent2FullName}
                error={itemErrors.parent2FullName}
              />
            </Card>

            <Card
              title='Section 504 Background Information'
              style={{
                marginBottom: 30
              }}
            >
              <FormItem label='Section 504 protects qualified individuals with disabilities'>
                <Typography>
                  Under this law, individuals with disabilities are defined as
                  persons with a physical or mental impairment which
                  substantially limits one or more major life activities. People
                  who have a history of, or who are regarded as having a physical
                  or mental impairment that substantially limits one or more
                  major life activities, are also covered. Major life activities
                  include caring for one's self, walking, seeing, hearing,
                  speaking, breathing, working, performing manual tasks, and
                  learning. Some examples of impairments which may substantially
                  limit major life activities, even with the help of medication
                  or aids/devices, are: AIDS, alcoholism, blindness or visual
                  impairment, cancer, deafness or hearing impairment, diabetes,
                  drug addiction, heart disease, and mental illness.
                </Typography>
              </FormItem>
              <br></br>

              <FormItem label='Explanation and Purpose of an Evaluation'>
                <Typography>
                  Section 504 requires the use of evaluation procedures that
                  ensure that children are not misclassified, unnecessarily
                  labeled as having a disability, or incorrectly placed based on
                  inappropriate selection, administration, or interpretation of
                  evaluation materials.
                </Typography>
                <br></br>
                <Typography>
                  The purpose of an evaluation under Section 504 is to determine
                  whether a child has a disability that substantially limits one
                  or more major life functions, which may need accommodation.
                </Typography>
              </FormItem>
            </Card>

            <Card
              title='Evaluation Parameters'
              style={{
                marginBottom: 30
              }}
            >
              <FormItem
                label='Major life activity that may be affected'
                name='majorLifeActivityThatMayBeAffected'
                {...validationProps('majorLifeActivityThatMayBeAffected')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={
                    formDisabledState.majorLifeActivityThatMayBeAffected
                  }
                />
              </FormItem>
              <FormItem
                label='Sources of evaluation information'
                name='sourcesOfEvaluationInformation'
                {...validationProps('sourcesOfEvaluationInformation')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.sourcesOfEvaluationInformation}
                />
              </FormItem>
            </Card>

            <Card
              title='Parent Consent'
              style={{
                marginBottom: 30
              }}
            >
              <FormItem
                label='Parent grants consent for 504 evaluation'
                name='parentConsent'
                {...validationProps('parentConsent')}
              >
                <Radio.Group
                  defaultValue
                  defaultChecked='no'
                  buttonStyle='solid'
                  {...handleMouseEvents}
                  name='parentConsent'
                  disabled={formDisabledState.parentConsent}
                >
                  <Radio.Button value='yes'>Yes</Radio.Button>
                  <Radio.Button value='no'>No</Radio.Button>
                </Radio.Group>
              </FormItem>

              <Form.Item
                label='Signing Parent'
                name='parentSignatureName'
                validateStatus={itemErrors.parentSignatureName ? 'error' : ''}
                help={itemErrors.parentSignatureName}
                {...handleFocusEvents}
                {...validationProps('parentSignatureName')}
                hasFeedback
              >
                <Select
                  style={{ width: '100%' }}
                  allowClear
                  disabled={formDisabledState.parentSignatureName}
                >
                  {parentsList.map(parent => (
                    <Select.Option key={parent} value={parent}>
                      {parent}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label='Signature Date'
                name='parentSignatureDate'
                {...validationProps('parentSignatureDate')}
              >
                <DatePicker
                  format={[
                    'MM-DD-YYYY',
                    'MM/DD/YYYY',
                    'MM.DD.YY',
                    'M-D-YYYY',
                    'M/D/YYYY',
                    'M.D.YYYY',
                    'MM.DD.YYYY',
                    'MM-DD-YY',
                    'MM/DD/YY',
                    'M-D-YY',
                    'M/D/YY',
                    'M.D.YY',
                    'MMDDYYYY',
                    'MMDDYY'
                  ]}
                  {...handleFocusEvents}
                  disabled={formDisabledState.parentSignatureDate}
                />
              </Form.Item>
            </Card>

            {formError && <Paragraph type='danger'>{formError}</Paragraph>}
            <StudentFormActions
              query={PRINT_FORM504A}
              extractDataProp='printStudentForm504A'
              canSave={canSave}
              getFormFieldValues={getFormFieldValues}
              handleActionEvents={handleActionEvents}

              
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired
}

const StudentForm504A = () => {
  // get current form information
  const { currentStudentInfo, currentEditableForm, studentMeetingId } =
    useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId } = currentEditableForm

  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    QUERY_STUDENT_COMMON_SIS,
    { variables: { id: studentId } }
  )
  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORM504A_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )

  const createInitialFormData = (studentInfo, versionInfo) => {
    let initialValues = {}
    if (!studentInfo || !versionInfo) return initialValues

    const { studentForm504A: form } = versionInfo
    const { student } = studentInfo
    const {
      formDate,
      studentFullName,
      studentDob,
      parent1FullName,
      parent2FullName,
      majorLifeActivityThatMayBeAffected,
      sourcesOfEvaluationInformation,
      parentConsent,
      parentSignatureName,
      parentSignatureDate
    } = form

    initialValues = {
      ...form,
      formDate: formDate ? moment(formDate) : moment(Date.now()),
      studentFullName: studentFullName || studentInfo.fullName || '',
      studentDob: studentDob
        ? moment(studentDob).format('YYYY-MM-DD')
        : student.dateOfBirth
          ? moment(student.dateOfBirth).format('YYYY-MM-DD')
          : '',
      parent1FullName: parent1FullName
        ? parent1FullName
        : student.parent1FullName,
      parent2FullName: parent2FullName
        ? parent2FullName
        : student.parent2FullName,
      majorLifeActivityThatMayBeAffected: majorLifeActivityThatMayBeAffected
        ? majorLifeActivityThatMayBeAffected
        : '',
      sourcesOfEvaluationInformation: sourcesOfEvaluationInformation
        ? sourcesOfEvaluationInformation
        : '',
      parentConsent: parentConsent ? parentConsent : 'no',
      parentSignatureName: parentSignatureName || student.parent1FullName || '',
      parentSignatureDate: parentSignatureDate
        ? moment(parentSignatureDate)
        : moment(Date.now()),
      parents: [student.parent1FullName, student.parent2FullName]
    }

    return initialValues
  }

  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
          />
        }
      />
    </FormErrorProvider>
  )
}
export default StudentForm504A
