// react
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useParams } from 'react-router-dom'

// apollo & operations
import { useQuery, useMutation } from '@apollo/client'
import { QUERY_DISTRICT_FORM } from '../../../operations/queries/districts'
import {
  UPDATE_DISTRICT_FORM,
  UPDATE_DISTRICT_FORM_PERMISSION
} from '../../../operations/mutations/districts'

// ant design
import { Form, Input, Button, Switch, Divider } from 'antd'

// elements
import FormItemsSuperAdmin from '../../elements/formItems/FormItemsSuperAdmin'
import FormItemError from '../../elements/formItems/FormItemError'

// other libraries
import formatErrors from '../../../utils/form'
import FormContainer from '../../../components/FormContainer'
import ModalDialog from '../../../components/ModalDialog'
import notificationSuccess from '../../../utils/notification'

const FormComponent = ({ data }) => {
  const history = useNavigate()
  const { id } = useParams()
  const [form] = Form.useForm()
  const [formError, setFormError] = useState()
  const [itemErrors, setItemErrors] = useState({})
  const [updateDistrictform, { loading: updateLoading }] =
    useMutation(UPDATE_DISTRICT_FORM)
  const [
    updateDistrictformPermission,
    { loading: updateDistrictformPermissionLoading }
  ] = useMutation(UPDATE_DISTRICT_FORM_PERMISSION)

  const continueEditing = _id => _id && history(`/districtform/${_id}`)

  const onFinish = async values => {
    try {
      clearErrors()
      const subRolePermissions = values.subRolePermissions
      delete values.subRolePermissions
      if (!values.formWarning) {
        values.formWarning = ''
      }
      await updateDistrictform({
        variables: {
          id: id,
          districtFormInput: {
            formId: data.districtForm.formId,
            districtId: data.districtForm.districtId,
            ...values
          }
        }
      })
      subRolePermissions.map(async subRolePermission => {
        const permissions = {
          add: subRolePermission.add,
          view: subRolePermission.view,
          print: subRolePermission.print
        }
        await updateDistrictformPermission({
          variables: {
            formSubRolePermissionInput: {
              subRoleId: subRolePermission.subRoleId,
              districtFormId: data.districtForm.id,
              permissions
            }
          }
        })
      })
      // ModalDialog.success({
      //   title: 'Success',
      //   content: `The form has been ${id ? 'updated' : 'added'}.`,
      //   okText: 'Return to listing',
      //   onOk: () => history('/districtforms'),
      //   cancelText: 'Continue editing',
      //   onCancel: () => continueEditing(id)
      // })
      notificationSuccess(`The form has been ${id ? 'updated' : 'added'}.`)
    } catch (e) {
      setErrors(formatErrors(e))
    }
  }

  const setErrors = errors =>
    errors.itemErrors
      ? setItemErrors(errors.itemErrors)
      : setFormError(errors.formError)

  const clearErrors = () => {
    setFormError()
    setItemErrors({})
  }

  return (
    <>
      {data && (
        <Form
          form={form}
          id='districtformForm'
          name='districtformForm'
          onFinish={onFinish}
          preserve={true}
          initialValues={data?.districtForm}
          labelCol={{ sm: 8, md: 5, lg: 6, xl: 4, xxl: 3 }}
          wrapperCol={{ sm: 10, md: 7, lg: 8, xl: 6, xxl: 4 }}
        >
          <Form.Item
            className='form-item-text'
            label='District'
            wrapperCol={{ sm: 20 }}
          >
            <span className='ant-form-text'>
              {data.districtForm.district.name}
            </span>
          </Form.Item>
          <Form.Item
            className='form-item-text'
            label='Form name'
            wrapperCol={{ sm: 20 }}
          >
            <span className='ant-form-text'>{data.districtForm.form.name}</span>
          </Form.Item>

          <Form.Item
            className='form-item-text'
            label='Code'
            wrapperCol={{ sm: 20 }}
          >
            <span className='ant-form-text'>
              {data.districtForm.form.formCode}
            </span>
          </Form.Item>

          <Form.Item
            className='form-item-text'
            label='Allow multiple instances'
            name='allowMultipleInstances'
            valuePropName='checked'
            wrapperCol={{ sm: 20 }}
          >
            <Switch />
          </Form.Item>

          <Form.Item
            label='PDF filename'
            name='downloadFileName'
            hasFeedback
            validateStatus={itemErrors.downloadFileName ? 'error' : ''}
            help={itemErrors.downloadFileName}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label='Form warning'
            name='formWarning'
            hasFeedback
            validateStatus={itemErrors.formWarning ? 'error' : ''}
            help={itemErrors.formWarning}
          >
            <Input.TextArea />
          </Form.Item>

          <Form.Item
            className='form-item-text'
            label='Active'
            name='active'
            valuePropName='checked'
            wrapperCol={{ sm: 20 }}
          >
            <Switch />
          </Form.Item>

          <Form.List name='subRolePermissions'>
            {fields => (
              <div>
                {fields.map(field => (
                  <div key={field.key}>
                    <Divider plain orientation='left'>
                      {data.districtForm.subRolePermissions[
                        field.fieldKey
                      ].enumName
                        .charAt(0)
                        .toUpperCase() +
                        data.districtForm.subRolePermissions[
                          field.fieldKey
                        ].enumName.slice(1)}
                      :
                    </Divider>
                    <Form.Item
                      {...field}
                      label='Add'
                      name={[field.name, 'add']}
                      fieldKey={[field.fieldKey, 'add']}
                      valuePropName='checked'
                    >
                      <Switch />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      label='Print'
                      name={[field.name, 'print']}
                      fieldKey={[field.fieldKey, 'print']}
                      valuePropName='checked'
                    >
                      <Switch />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      label='View'
                      name={[field.name, 'view']}
                      fieldKey={[field.fieldKey, 'view']}
                      valuePropName='checked'
                    >
                      <Switch />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, 'subRoleId']}
                      fieldKey={[field.fieldKey, 'subRoleId']}
                      valuePropName='checked'
                      hidden
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, 'enumName']}
                      fieldKey={[field.fieldKey, 'enumName']}
                      valuePropName='checked'
                      hidden
                    >
                      <Input />
                    </Form.Item>
                  </div>
                ))}
              </div>
            )}
          </Form.List>

          <FormItemsSuperAdmin data={data.districtForm} hasHidden />

          <FormItemError error={formError} />

          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              loading={updateDistrictformPermissionLoading || updateLoading}
            >
              {id ? 'Update' : 'Add'}
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  )
}

FormComponent.propTypes = {
  data: PropTypes.object.isRequired
}

const DistrictFormForm = () => {
  const { id } = useParams()

  const { data, loading } = useQuery(QUERY_DISTRICT_FORM, {
    variables: { id },
    skip: !id
  })
  const initialValues = {
    districtForm: {},
    formWarning: '',
    allowMultipleInstances: false
  }

  return (
    <FormContainer
      loading={loading}
      size={2}
      form={<FormComponent data={{ ...initialValues, ...data }} />}
    />
  )
}

export default DistrictFormForm
