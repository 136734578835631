import { gql } from "@apollo/client";

export const QUERY_FORM3454N_VERSION = gql`
  query studentForm3454N(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
  ) {
    studentForm3454N(
      studentFormId: $studentFormId
      studentId: $studentId
      formId: $formId
      schoolId: $schoolId
      studentMeetingId: $studentMeetingId
    ) {
      versionId
      formId
      formDate
      studentFullName
      requiresAssistiveTechnology
      assistiveTechnologyNeeded
      communicationNeeds
      englishLearner
      provisionBraille
      behaviorImpedesLearning
      behaviorImpedesLearningForms
      requiresLinguisticCultural
      linguisticCulturalAccommodations
      specialEducationRelatedServicesLanguage
      specialEducationLinguisticAccommodations
      languageCommunicationNeeds
      otherText
      directCommunicationOpportunities
      modeOfCommunication
      supplementaryAids
      programTrainings
      programTrainingsText
      parentTraining
      parentTrainingText
      formAction
      formStatus
    }
  }
`;
