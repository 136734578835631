import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { useQuery } from '@apollo/client'

// ant design
import { Form, DatePicker, Radio, Alert, Typography, Card } from 'antd'

import FormContainer from '../../../FormContainer'
import FormItem from 'antd/lib/form/FormItem'
import Title from 'antd/lib/typography/Title'

import { PRINT_FORM3457C } from '../../../../operations/mutations/forms/3457C'
import { QUERY_FORM3457C_VERSION } from '../../../../operations/queries/forms/3457C'
import { QUERY_STUDENT_COMMON_SIS } from '../../../../operations/queries/forms/SIS'

import FormCollaboration from '../../../FormCollaboration'

import { IepFormContext } from '../../../../utils/iepFormProvider'
import StudentFormActions from '../StudentFormActions'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import StudentDobSIS from '../sis/StudentDobSIS'
import StudentParent1SIS from '../sis/StudentParent1SIS'
import StudentParent2SIS from '../sis/StudentParent2SIS'
import MeetingSISInput from '../sis/MeetingSISInput'

const { Paragraph } = Typography

const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId
}) => {
  const [form] = Form.useForm()
  const { itemErrors, formError } = useContext(FormErrorContext)

  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()
    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      studentMeetingId: studentMeetingId
    }
  }

  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    const update = Object.assign({})
    switch (fieldName) {
      case 'signatureDate':
      case 'formDate':
        update[fieldName] = moment(fieldValue)
        break
      case 'guardianAdditionalRequirementConsent':
      //case "guardianEvaluationConsent":
      //  update[fieldName] =
      //    fieldValue === "null" ? null : fieldValue === "true";
      //  break;
      default:
        update[fieldName] = fieldValue
    }
    return update
  }

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }

  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          formDisabledState,
          canSave,
          handleActionEvents,
          validationProps
        }) => (
          <Form
            form={form}
            id='studentForm3457C'
            name='studentForm3457C'
            initialValues={data}
            preserve
            layout='vertical'
            className='page-padding-leftRight page-padding-topBottom form-labels-bold'
          >
            <Title level={4} className='center'>
              PARENT/GUARDIAN CONSENT FOR A REEVALUATION
            </Title>
            <Form.Item
              label='Form Created Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
              />
            </Form.Item>

            <Card
              title='Student SIS Data'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />
              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
              <StudentDobSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentDob}
                error={itemErrors.studentDob}
              />
              <StudentParent1SIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.parent1}
                error={itemErrors.parent1}
              />
              <StudentParent2SIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.parent2}
                error={itemErrors.parent2}
              />
            </Card>

            <Card
              title='Parent / Guardian Consent'
              style={{
                marginBottom: 30
              }}
            >
              <FormItem
                label='Agreement that no additional data is needed'
                name='guardianAdditionalRequirementConsent'
                {...validationProps('guardianAdditionalRequirementConsent')}
              >
                <Radio.Group
                  defaultValue
                  defaultChecked='n/a'
                  buttonStyle='solid'
                  {...handleMouseEvents}
                  disabled={
                    formDisabledState.guardianAdditionalRequirementConsent
                  }
                  name='guardianAdditionalRequirementConsent'
                >
                  <Radio.Button value='yes'>I agree</Radio.Button>
                  <Radio.Button value='no'>I do not agree</Radio.Button>
                  <Radio.Button value='n/a'>Decision Delayed</Radio.Button>
                </Radio.Group>
              </FormItem>

              <FormItem
                label='Consent to collect additional evaluation data'
                name='guardianEvaluationConsent'
                {...validationProps('guardianEvaluationConsent')}
              >
                <Radio.Group
                  defaultValue
                  defaultChecked='n/a'
                  buttonStyle='solid'
                  {...handleMouseEvents}
                  disabled={formDisabledState.guardianEvaluationConsent}
                  name='guardianEvaluationConsent'
                >
                  <Radio.Button value='yes'>I give consent</Radio.Button>
                  <Radio.Button value='no'>I do not give consent</Radio.Button>
                  <Radio.Button value='n/a'>Decision Delayed</Radio.Button>
                </Radio.Group>
              </FormItem>

              <Form.Item
                label='Signature Date'
                name='signatureDate'
                {...validationProps('signatureDate')}
              >
                <DatePicker
                  {...handleFocusEvents}
                  disabled={formDisabledState.signatureDate}
                  format={[
                    'MM-DD-YYYY',
                    'MM/DD/YYYY',
                    'MM.DD.YY',
                    'M-D-YYYY',
                    'M/D/YYYY',
                    'M.D.YYYY',
                    'MM.DD.YYYY',
                    'MM-DD-YY',
                    'MM/DD/YY',
                    'M-D-YY',
                    'M/D/YY',
                    'M.D.YY',
                    'MMDDYYYY',
                    'MMDDYY'
                  ]}
                />
              </Form.Item>
            </Card>

            {formError && <Paragraph type='danger'>{formError}</Paragraph>}
            <StudentFormActions
              query={PRINT_FORM3457C}
              extractDataProp='printStudentForm3457C'
              canSave={canSave}
              handleActionEvents={handleActionEvents}

              getFormFieldValues={getFormFieldValues}
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired
}

const StudentForm3457C = () => {
  // get current form information
  const { currentStudentInfo, currentEditableForm, studentMeetingId } =
    useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId } = currentEditableForm

  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    QUERY_STUDENT_COMMON_SIS,
    { variables: { id: studentId } }
  )
  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORM3457C_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )

  const createInitialFormData = (studentInfo, versionInfo) => {
    let initialValues = {}

    if (!studentInfo || !versionInfo) return initialValues

    const { studentForm3457C: form } = versionInfo
    const { student } = studentInfo
    const { parent1FullName, parent2FullName, dateOfBirth } = student
    const {
      formDate,
      studentFullName,
      studentDob,
      parent1,
      parent2,
      signatureDate
    } = form

    initialValues = {
      ...form,
      formDate: formDate ? moment(formDate) : moment(Date.now()),
      studentFullName: studentFullName || student.fullName || '',
      studentDob: studentDob
        ? moment(studentDob).format('YYYY-MM-DD')
        : dateOfBirth
        ? moment(dateOfBirth).format('YYYY-MM-DD')
        : '',
      parent1: parent1 || parent1FullName || '',
      parent2: parent2 || parent2FullName || '',
      signatureDate: signatureDate ? moment(signatureDate) : moment(Date.now())
    }

    return initialValues
  }

  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
          />
        }
      />
    </FormErrorProvider>
  )
}

export default StudentForm3457C
