import { gql } from "@apollo/client";

export const QUERY_FORM3457G_VERSION = gql`
  query studentForm3457G(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
  ) {
    studentForm3457G(
      studentFormId: $studentFormId
      studentId: $studentId
      formId: $formId
      schoolId: $schoolId
      studentMeetingId: $studentMeetingId
    ) {
      versionId
      formId
      formDate
      studentFullName
      studentDob
      parent1
      parent2
      dateOfContact
      beginChangesDate
      districtPersonnelName
      communicationMode
      changesDescription
      documentContactName
      documentContactTitle
      documentContactPhone
      signatureName
      signatureTitle
      signatureName
      signatureTitle
      formAction
      formStatus
    }
  }
`;
