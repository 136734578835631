import { gql } from "@apollo/client";

export const QUERY_DISTRICTS = gql`
  query ($searchCriteria: String) {
    districts(searchCriteria: $searchCriteria) {
      id
      name
      nameGeneral
      schools {
        name
      }
      hidden
    }
  }
`;

export const QUERY_DISTRICT = gql`
  query District($id: ID!) {
    district(id: $id) {
      id
      name
      phone
      fax
      website
      address
      city
      state
      country
      zip
      directorOfEducation
      chairOfBoard
      logo
      selectedPlans
      hidden
      createdBy
      createdAtAgo
      updatedAtAgo
      createdAt(format: "ddd mmm dd yyyy h:MM:ss TT Z")
      updatedAt(format: "ddd mmm dd yyyy h:MM:ss TT Z")
    }
  }
`;

export const QUERY_DISTRICT_FORMS = gql`
  query districtForms($searchCriteria: String, $active: Boolean, $districtIds: [ID]) {
    districtForms(searchCriteria: $searchCriteria, active: $active, districtIds: $districtIds) {
      id
      active
      formId
      downloadFileName
      allowMultipleInstances
      form {
        id
        formCode
        name
      }
      districtId
      district {
        id
        name
        nameGeneral
      }
    }
  }
`;

export const QUERY_DISTRICT_FORM = gql`
  query districtForm($id: ID!) {
    districtForm(id: $id) {
      id
      active
      formId
      districtId
      district {
        id
        name
        nameGeneral
      }
      downloadFileName
      formWarning
      allowMultipleInstances
      form {
        id
        formCode
        name
      }
      subRolePermissions {
        enumName
        subRoleId
        add
        print
        view
      }
      createdBy
      createdAtAgo
      updatedAtAgo
      createdAt(format: "ddd mmm dd yyyy h:MM:ss TT Z")
      updatedAt(format: "ddd mmm dd yyyy h:MM:ss TT Z")
    }
  }
`;


export const QUERY_DISTRICT_REPORTS = gql`
  query districtReports($searchCriteria: String, $districtIds: [ID]) {
    districtReports(searchCriteria: $searchCriteria, districtIds: $districtIds) {
      id
      districtId
      userId
      isUserReport
      baseReportId
      baseReport {
        id
        fields {
          fieldName
          fieldGroup
          fieldCaption
          fieldIncludesGroup
          fieldOrder
          fieldWidth
          fieldAlignment
          fieldHasFilter
          fieldHasSort
          dataType
          dataFormat
          visible
          default
          required
        }
      }
      reportCode
      reportName
      reportTitle
      reportGroup
      reportNotes
      reportRowNotes {
        rowId
        notes
      }
      reportParameters
      selectedFields {
        fieldName
        fieldGroup
        fieldCaption
        fieldIncludesGroup
        fieldOrder
        fieldWidth
        fieldAlignment
        fieldHasFilter
        fieldHasSort
        dataType
        dataFormat
        visible
        default
        required
      }
      reportColumns
      sortInfo {
        field
        order
      }
      filterValues
      filters {
        fieldName
        comparisonOperator
        fieldValue
        logicalOperator
        default
      }
      grouping {
        fieldName
        function
        includeGrandTotal
      }
      distribution {
        emailRecipients {
          email
        }
        emailFrom
        emailSubject
        emailText
        emailAttachments
      }
      schedule {
        scheduleActive
        recurrenceTimePeriod
        recurrenceInterval
        recurrencePeriods
        recurrenceDayNumber
        recurrenceDate
        scheduleStartDate
        scheduleEndDate
      }
      reportPageLayout {
        pageFormat
        pageHeight
        pageWidth
        pageOrientation
        pageMargins
        marginTop
        marginBottom
        marginLeft
        marginRight
        fontName
        fontStyle
        fontSize
      }
      hidden
    }
  }
`;

export const QUERY_DISTRICT_REPORT = gql`
  query districtReport($id: ID!) {
    districtReport(id: $id) {
      id
      districtId
      userId
      isUserReport
      baseReportId
      baseReport {
        id
        fields {
          fieldName
          fieldGroup
          fieldCaption
          fieldIncludesGroup
          fieldOrder
          fieldWidth
          fieldAlignment
          fieldHasFilter
          fieldHasSort
          dataType
          dataFormat
          visible
          default
          required
        }
      }
      reportCode
      reportName
      reportTitle
      reportGroup
      reportNotes
      reportRowNotes {
        rowId
        notes
      }
      reportParameters
      selectedFields {
        fieldName
        fieldGroup
        fieldCaption
        fieldIncludesGroup
        fieldOrder
        fieldWidth
        fieldAlignment
        fieldHasFilter
        fieldHasSort
        dataType
        dataFormat
        visible
        default
        required
      }
      reportColumns
      sortInfo {
        field
        order
      }
      filterValues
      filters {
        fieldName
        comparisonOperator
        fieldValue
        logicalOperator
        default
      }
      grouping {
        fieldName
        function
        includeGrandTotal
      }
      distribution {
        emailRecipients {
          email
        }
        emailFrom
        emailSubject
        emailText
        emailAttachments 
      }
      schedule {
        scheduleActive
        recurrenceTimePeriod
        recurrenceInterval
        recurrencePeriods
        recurrenceDayNumber
        recurrenceDate
        scheduleStartDate
        scheduleEndDate
      }
      reportPageLayout {
        pageFormat
        pageHeight
        pageWidth
        pageOrientation
        pageMargins
        marginTop
        marginBottom
        marginLeft
        marginRight
        fontName
        fontStyle
        fontSize
      }
      hidden
      createdBy
      createdAtAgo
      updatedAtAgo
      createdAt(format: "ddd mmm dd yyyy h:MM:ss TT Z")
      updatedAt(format: "ddd mmm dd yyyy h:MM:ss TT Z")
    }
  }
`;

export const QUERY_USER_DISTRICT_REPORTS = gql`
  query districtReports($userId: ID, $districtIds: [ID], $searchCriteria: String) {
    districtReports(userId: $userId, districtIds: $districtIds, searchCriteria: $searchCriteria) {
      id
      districtId
      userId
      isUserReport
      baseReportId
      baseReport {
        id
        fields {
          fieldName
          fieldGroup
          fieldCaption
          fieldIncludesGroup
          fieldOrder
          fieldWidth
          fieldAlignment
          fieldHasFilter
          fieldHasSort
          dataType
          dataFormat
          visible
          default
          required
        }
      }
      reportCode
      reportName
      reportTitle
      reportGroup
      reportNotes
      reportRowNotes {
        rowId
        notes
      }
      reportParameters
      selectedFields {
        fieldName
        fieldGroup
        fieldCaption
        fieldIncludesGroup
        fieldOrder
        fieldWidth
        fieldAlignment
        fieldHasFilter
        fieldHasSort
        dataType
        dataFormat
        visible
        default
        required
      }
      reportColumns
      sortInfo {
        field
        order
      }
      filterValues
      filters {
        fieldName
        comparisonOperator
        fieldValue
        logicalOperator
        default
      }
      grouping {
        fieldName
        function
        includeGrandTotal
      }
      distribution {
        emailRecipients {
          email
        }
        emailFrom
        emailSubject
        emailText
        emailAttachments
      }
      schedule {
        scheduleActive
        recurrenceTimePeriod
        recurrenceInterval
        recurrencePeriods
        recurrenceDayNumber
        recurrenceDate
        scheduleStartDate
        scheduleEndDate
      }
      reportPageLayout {
        pageFormat
        pageHeight
        pageWidth
        pageOrientation
        pageMargins
        marginTop
        marginBottom
        marginLeft
        marginRight
        fontName
        fontStyle
        fontSize
      }
      hidden
    }
  }
`;

export const QUERY_DISTRICT_REPORTS_LIST = gql`
  query districtReports($districtIds: [ID], $searchCriteria: String) {
    districtReports(districtIds: $districtIds, searchCriteria: $searchCriteria) {
      id
      districtId
      userId
      isUserReport
      baseReportId
      reportCode
      reportName
      reportGroup
      hidden
    }
  }
`;

export const QUERY_USER_DISTRICT_REPORTS_LIST = gql`
  query userDistrictReports($userId: ID, $districtIds: [ID], $searchCriteria: String) {
    userDistrictReports(userId: $userId, districtIds: $districtIds, searchCriteria: $searchCriteria) {
      id
      districtId
      userId
      isUserReport
      baseReportId
      reportCode
      reportName
      reportGroup
      hidden
    }
  }
`;