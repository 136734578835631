/* global fetch:false */

// React
import React from 'react'
import PropTypes from 'prop-types'

// Apollo
import { ApolloClient, ApolloLink, ApolloProvider as ApolloClientProvider, createHttpLink, InMemoryCache, split } from '@apollo/client'
import { WebSocketLink } from '@apollo/client/link/ws'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { fromPromise } from '@apollo/client/link/utils/fromPromise'
import { getMainDefinition } from '@apollo/client/utilities'

// graphql operations
import { GET_TOKEN_QUERY } from '../operations/mutations/authentication'
import { SESSION_EXPIRY } from '../operations/queries/authentication'

// other libraries
import Cookies from 'universal-cookie'
import { ErrorWithNotification } from './errorWithNotification'
import { ApplicationError } from './applicationError'

// Create ApolloContext
const ApolloContext = React.createContext()

// create a cookies jar
const cookies = new Cookies()

// local save of accessToken
let accessToken

// tell if we are currently refreshing tokens
let isRefreshingTokens

// queue of pending apollo queries during a refresh tokens process
let pendingRequests = []

// session expiry value in seconds
let sessionExpiry

// clear session expiry value
const clearSessionExpiry = () => {
  sessionExpiry = 0
}

// return session expiry value
const getSessionExpiry = () => sessionExpiry

// resolve pending requests
const resolvePendingRequests = () => {
  pendingRequests.map(callback => callback())
  pendingRequests = []
}

// set jwt tokens
const setTokens = (newAccessToken, newRefreshToken) => {
  cookies.set('refreshToken', newRefreshToken, { path: '/' })
  accessToken = newAccessToken
}

// get jwt tokens
const getTokens = () => {
  return {
    refreshToken: cookies.get('refreshToken'),
    accessToken: accessToken || ''
  }
}

// clear jwt tokens
const clearTokens = () => {
  cookies.remove('refreshToken', { path: '/' })
  accessToken = undefined
  clearSessionExpiry()
}

// get a new access token
const getNewToken = async () => {
  try {
    const response = await fetch(process.env.REACT_APP_API_URL, {
      method: 'POST',
      cache: 'no-cache',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: GET_TOKEN_QUERY,
        variables: {
          accessToken: '',
          refreshToken: getTokens().refreshToken
        }
      })
    })
    const result = await response.json()

    // extract your accessToken from your response data and return it
    const newAccessToken = result.data?.refreshAuth?.accessToken
    const newRefreshToken = result.data?.refreshAuth?.refreshToken
    if (!newAccessToken || !newRefreshToken) {
      // clear tokens and force the session to expire
      throw new ApplicationError('Refresh token failed')
    } else {
      // update the jwt tokens
      setTokens(newAccessToken, newRefreshToken)

      // update the session expiry
      updateSessionExpiry()

      // dispatch a global event
      window.dispatchEvent(new CustomEvent('tokenRefreshed'))

      return {
        newAccessToken,
        newRefreshToken
      }
    }
  } catch (error) {
    clearTokens()
    sessionExpiry = -1 // force a sign out on the next authProvider session expiry interval
  }
}

// the apollo link handling unauthenticated response and refreshing jwt tokens
const jwtLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  // graphQLErrors && console.log('graphQLErrors', graphQLErrors)
  // networkError && console.log('networkError', networkError)
  if (graphQLErrors && Array.isArray(graphQLErrors)) {
    const { extensions } = graphQLErrors[0]
    // try to refresh the tokens on UNAUTHENTICATED only if the refresh token is present
    if (extensions.code === 'UNAUTHENTICATED' && getTokens().refreshToken) {
      let forward$
      if (!isRefreshingTokens) {
        isRefreshingTokens = true
        forward$ = fromPromise(
          getNewToken()
            .then(() => {
              const oldHeaders = operation.getContext().headers
              operation.setContext({
                headers: {
                  ...oldHeaders,
                  authorization: `Bearer ${accessToken}`
                }
              })
              resolvePendingRequests()
              return true
            })
            .catch((e) => {
              pendingRequests = []
              return false
            })
            .finally(() => {
              isRefreshingTokens = false
            })
        ).filter(value => Boolean(value))
      } else {
        forward$ = fromPromise(
          new Promise(resolve => {
            pendingRequests.push(() => resolve())
          })
        )
      }
      return forward$.flatMap(() => forward(operation))
    }
  }

  if (networkError?.statusCode === 413) {
    ErrorWithNotification('Network error', { title: 'Network error', message: 'Payload too large' })
  } else if (networkError?.result?.errors?.[0]) {
    ErrorWithNotification('Network error', { title: 'Network error', message: 'An unknown error has occurred. E001', error: networkError.result.errors?.[0].message })
  }
})

const auth = setContext(async (operation, context) => {
  return {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  }
})

// apollo link retry when the network is down
const linkRetry = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true
  },
  attempts: {
    max: Infinity,
    retryIf: (error, _operation) => !!error && error?.statusCode !== 400
  }
})

// Apollo Client
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
  credentials: 'include'
})

// Websocket Client
const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_WSS_URL,
  options: {
    reconnect: true
  }
})

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink
)

// instantiate the apollo client
const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    linkRetry,
    jwtLink,
    auth,
    splitLink
  ]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only'
    }
  },
  //Following was preliminary work for pagination functionality
  // typePolicies: {
  //   Query: {
  //     fields: {
  //       students: {
  //         keyArgs: false,
  //         merge(existing = [], incoming) {
  //           return[...existing, ...incoming];
  //         },
  //       }
  //     }
  //   }
  // }
})

// retrieve the session expiry
const updateSessionExpiry = async () => {
  try {
    const response = await apolloClient.mutate({ mutation: SESSION_EXPIRY })
    sessionExpiry = response.data?.sessionExpiresSeconds
  } catch (error) {
    ErrorWithNotification('Session error', { title: 'Session error', message: 'Error retrieving session expiry. E003', error })
  }
}

/**
 * ApolloProvider
 */
function ApolloProvider(props) {
  return (
    <ApolloContext.Provider
      value={{
        client: apolloClient,
        getTokens,
        setTokens,
        clearTokens,
        updateSessionExpiry,
        getSessionExpiry,
        clearSessionExpiry
      }}
    >
      <ApolloClientProvider client={apolloClient}>
        {props.children}
      </ApolloClientProvider>
    </ApolloContext.Provider>
  )
}

ApolloProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export default ApolloContext

export { ApolloContext, ApolloProvider }
