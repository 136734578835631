// react
import React, { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

// ant design
import { Card, Button, Select, Typography, Layout, Form, Row, Col } from 'antd'

// contexts
import AppContext from '../utils/appProvider'
import AuthContext from '../utils/authProvider'

import Page from './Page'

const { Paragraph } = Typography
const { Option } = Select

const Selector = () => {
  const App = useContext(AppContext)
  const Auth = useContext(AuthContext)
  const { districtsAdmin, schoolsAdmin, schoolsGeneral } = Auth
  const isSuperAdmin = Auth.hasRole('SUPER_ADMIN')
  const isSchoolsGeneral = Auth.hasRole('SCHOOLS_GENERAL')
  const isSchoolsAdmin = Auth.hasRole('SCHOOLS_ADMIN')
  const isDistrictsAdmin = Auth.hasRole('DISTRICTS_ADMIN')
  const history = useNavigate()
  const [form] = Form.useForm()
  const [type, setType] = useState('')
  const [ids, setIds] = useState([])
  const [selectedId, setSelectedId] = useState()
  const [disabled, setDisabled] = useState(true)

  const onFinish = async values => {
    // landing page for superadmin
    if (type === 'superadmin') {
      App.openSuperAdmin()
      history('/districts')
    } else if (type === 'district_admin') {
      App.openDistrictAdmin(selectedId)
      history('/')
    } else if (type === 'school_admin') {
      App.openSchoolAdmin(selectedId)
      history('/')
    } else if (type === 'school_general') {
      App.openSchoolAdmin(selectedId)
      history('/')
    }
  }

  const handleTypeChange = async value => {
    // unselect the selected district or school id when the type has changed
    form.setFieldsValue({
      id: undefined
    })

    if (value === 'district_admin') {
      setIds(districtsAdmin)
    }

    if (value === 'school_admin') {
      setIds(schoolsAdmin)
    }

    if (value === 'school_general') {
      setIds(schoolsGeneral)
    }

    setType(value)
    if (value !== 'superadmin') {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }

  const handleSelectedIdChange = async value => {
    setSelectedId(value)
    setDisabled(false)
  }

  return (
    <>
      <Layout className='content-layout' style={{ maxHeight: '100vh' }}>
        <Page>
          <Row>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 18, offset: 3 }}
              md={{ span: 14, offset: 5 }}
              lg={{ span: 12, offset: 6 }}
              xl={{ span: 10, offset: 7 }}
              xxl={{ span: 8, offset: 8 }}
            >
              <div className='page-container' style={{ paddingTop: '50%' }}>
                <Card
                  title='Open'
                  bordered={false}
                  className='chooseLayout-card'
                >
                  <Paragraph>
                    Please select the access you would like to open:
                  </Paragraph>
                  <Form name='login' form={form} onFinish={onFinish}>
                    <Form.Item name='type'>
                      <Select
                        placeholder='Please select a role...'
                        onChange={handleTypeChange}
                      >
                        {isSuperAdmin && (
                          <Option value='superadmin'>Super admin</Option>
                        )}
                        {isDistrictsAdmin && districtsAdmin.length && (
                          <Option value='district_admin'>District Admin</Option>
                        )}
                        {isSchoolsAdmin && schoolsAdmin.length && (
                          <Option value='school_admin'>School Admin</Option>
                        )}
                        {isSchoolsGeneral && schoolsGeneral.length && (
                          <Option value='school_general'>School General</Option>
                        )}
                      </Select>
                    </Form.Item>

                    {type !== 'superadmin' && (
                      <Form.Item name='id'>
                        <Select
                          placeholder='Select...'
                          onChange={handleSelectedIdChange}
                        >
                          {ids.map(obj => (
                            <Option key={obj.id} value={obj.id}>
                              {obj.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )}

                    <Form.Item style={{ marginBottom: '0' }}>
                      <Button
                        type='primary'
                        htmlType='submit'
                        className='form-button'
                        disabled={disabled}
                      >
                        Open
                      </Button>
                    </Form.Item>
                  </Form>
                </Card>
              </div>
            </Col>
          </Row>
        </Page>
      </Layout>
    </>
  )
}

export default Selector
