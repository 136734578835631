import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { PageHeader } from "antd";

const PdfPage = (props) => {
  const itemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 1;
    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <Link to={route.path}>{route.breadcrumbName}</Link>
    );
  };

  return (
    <>
      {props.title !== undefined && (
        <PageHeader
          className="page-header"
          title={props.title}
          subTitle={props.subtitle}
          ghost={false}
          breadcrumb={{ itemRender: itemRender, routes: props.breadcrumb }}
          extra={props.extra}
        />
      )}
      {props.children}
    </>
  );
};

PdfPage.propTypes = {
  children: PropTypes.element.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  breadcrumb: PropTypes.array,
  extra: PropTypes.array,
};

export default PdfPage;
