import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { IepFormContext } from '../../../../utils/iepFormProvider'
import { useQuery } from '@apollo/client'
import { QUERY_STUDENT_NAME } from '../../../../operations/queries/forms/SIS'
import { QUERY_FORM3454N_VERSION } from '../../../../operations/queries/forms/3454N'
import moment from 'moment'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import FormContainer from '../../../FormContainer'
import {
  Alert,
  Card,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Radio,
  Typography
} from 'antd'
import FormCollaboration from '../../../FormCollaboration'
import Title from 'antd/lib/typography/Title'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import StudentFormActions from '../StudentFormActions'
import { PRINT_FORM3454N } from '../../../../operations/mutations/forms/3454N'
import Editor from '../../../elements/common/Editor'
import FormItem from 'antd/lib/form/FormItem'

const { Paragraph } = Typography

const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId
}) => {
  const [form] = Form.useForm()
  const { itemErrors, formError } = useContext(FormErrorContext)

  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()
    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      studentMeetingId: studentMeetingId
    }
  }

  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    const update = Object.assign({})

    switch (fieldName) {
      case 'formDate':
        update[fieldName] = fieldValue ? moment(fieldValue) : ''
        break
      case 'requiresAssistiveTechnology':
        update[fieldName] = fieldValue.toString()
        setRequiresAssistiveTechnology(update[fieldName])
        break
      case 'communicationNeeds':
        update[fieldName] = fieldValue.toString()
        setCommunicationNeeds(update[fieldName])
        break
      case 'requiresLinguisticCultural':
        update[fieldName] = fieldValue.toString()
        setRequiresLinguisticCultural(update[fieldName])
        break
      case 'specialEducationRelatedServicesLanguage':
        update[fieldName] = fieldValue.toString()
        setSpecialEducationRelatedServicesLanguage(update[fieldName])
        break
      case 'englishLearner':
        update[fieldName] = fieldValue.toString()
        setEnglishLearner(update[fieldName])
        break
      case 'provisionBraille':
        update[fieldName] = fieldValue.toString()
        setProvisionBraille(update[fieldName])
        break
      case 'behaviorImpedesLearning':
        update[fieldName] = fieldValue.toString()
        setBehaviorImpedesLearning(update[fieldName])
        break
      case 'programTrainings':
        update[fieldName] = fieldValue.toString()
        setProgramTrainings(update[fieldName])
        break
      case 'parentTraining':
        update[fieldName] = fieldValue.toString()
        setParentTraining(update[fieldName])
        break
      case 'languageCommunicationNeeds':
        update[fieldName] = fieldValue.split(',')
        setOther(fieldValue.includes('other') ? true : false)
        break
      default:
        update[fieldName] = fieldValue
    }
    return update
  }

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }

  const accommodationOptions = [
    { label: 'American Sign Language', value: 'asl' },
    { label: 'Auditory/Oral', value: 'auditory-oral' },
    { label: 'Cued Speech', value: 'cued-speech' },
    { label: 'Tactile', value: 'tactile' },
    { label: 'Signed English', value: 'signed-english' },
    { label: 'Speech Generated Device', value: 'speech-generated-device' },
    { label: 'Other', value: 'other' }
  ]

  const isOther = values => {
    return values.includes('other')
  }

  const [requiresAssistiveTechnology, setRequiresAssistiveTechnology] =
    useState(data?.requiresAssistiveTechnology)
  const [communicationNeeds, setCommunicationNeeds] = useState(
    data?.communicationNeeds
  )
  const [other, setOther] = useState(isOther(data?.languageCommunicationNeeds))
  const [requiresLinguisticCultural, setRequiresLinguisticCultural] = useState(
    data?.requiresLinguisticCultural
  )
  const [
    specialEducationRelatedServicesLanguage,
    setSpecialEducationRelatedServicesLanguage
  ] = useState(data?.specialEducationRelatedServicesLanguage)
  const [englishLearner, setEnglishLearner] = useState(data?.englishLearner)
  const [provisionBraille, setProvisionBraille] = useState(
    data?.provisionBraille
  )
  const [behaviorImpedesLearning, setBehaviorImpedesLearning] = useState(
    data?.behaviorImpedesLearning
  )
  const [programTrainings, setProgramTrainings] = useState(
    data?.programTrainings
  )
  const [parentTraining, setParentTraining] = useState(data?.parentTraining)

  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          formDisabledState,
          canSave,
          handleActionEvents,
          validationProps
        }) => (
          <Form
            form={form}
            id='studentForm3454N'
            name='studentForm3454N'
            initialValues={data}
            preserve
            layout='vertical'
            className='page-padding-leftRight page-padding-topBottom form-labels-bold'
          >
            <Title level={4} className='center'>
              EDUCATIONAL ACCOMMODATIONS AND SUPPORTS
            </Title>
            <Form.Item
              label='Form Created Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
              />
            </Form.Item>

            <Card
              title='Student SIS Data'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />
              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
            </Card>

            <Card
              title='CONSIDERATION OF SPECIAL FACTORS'
              style={{
                marginBottom: 30
              }}
            >
              <FormItem
                style={{ marginBottom: '0px' }}
                label="Select 'Yes' to indicate if the student requires any supplementary aids and/or services due to the following factors:"
              ></FormItem>

              <FormItem
                label='Assistive Technology (AT) devices and/or services'
                name='requiresAssistiveTechnology'
                {...validationProps('requiresAssistiveTechnology')}
              >
                <Radio.Group
                  defaultValue={data.requiresAssistiveTechnology}
                  onChange={e => {
                    setRequiresAssistiveTechnology(e.target.value)
                    handleMouseEvents.onChange(e)
                  }}
                  buttonStyle='solid'
                  name='requiresAssistiveTechnology'
                  disabled={formDisabledState.requiresAssistiveTechnology}
                >
                  <Radio.Button value='true'>Yes</Radio.Button>
                  <Radio.Button value='false'>No</Radio.Button>
                </Radio.Group>
              </FormItem>

              <Form.Item
                label="If 'Yes', specify AT needed.  If 'No', specify why AT is not needed to access free and appropriate public education"
                name='assistiveTechnologyNeeded'
                {...validationProps('assistiveTechnologyNeeded')}
              >
                <Editor
                  {...handleEditorEvents}
                  formField='assistiveTechnologyNeeded'
                  disabled={formDisabledState.assistiveTechnologyNeeded}
                />
              </Form.Item>

              <FormItem
                label='Communication needs including students who are deaf/hard of hearing.'
                name='communicationNeeds'
                {...validationProps('communicationNeeds')}
              >
                <Radio.Group
                  defaultValue={data.communicationNeeds}
                  onChange={e => {
                    setCommunicationNeeds(e.target.value)
                    handleMouseEvents.onChange(e)
                  }}
                  buttonStyle='solid'
                  name='communicationNeeds'
                  disabled={formDisabledState.communicationNeeds}
                >
                  <Radio.Button value='true'>Yes</Radio.Button>
                  <Radio.Button value='false'>No</Radio.Button>
                </Radio.Group>
              </FormItem>

              <FormItem
                label='English learner status/language needs'
                name='englishLearner'
                {...validationProps('englishLearner')}
              >
                <Radio.Group
                  defaultValue={data.englishLearner}
                  onChange={e => {
                    setEnglishLearner(e.target.value)
                    handleMouseEvents.onChange(e)
                  }}
                  buttonStyle='solid'
                  name='englishLearner'
                  disabled={formDisabledState.englishLearner}
                >
                  <Radio.Button value='true'>Yes</Radio.Button>
                  <Radio.Button value='false'>No</Radio.Button>
                </Radio.Group>
              </FormItem>

              <FormItem
                label='Blind/Visually impaired/provision of braille instruction'
                name='provisionBraille'
                {...validationProps('provisionBraille')}
              >
                <Radio.Group
                  defaultValue={data.provisionBraille}
                  onChange={e => {
                    setProvisionBraille(e.target.value)
                    handleMouseEvents.onChange(e)
                  }}
                  buttonStyle='solid'
                  name='provisionBraille'
                  disabled={formDisabledState.provisionBraille}
                >
                  <Radio.Button value='true'>Yes</Radio.Button>
                  <Radio.Button value='false'>No</Radio.Button>
                </Radio.Group>
              </FormItem>

              <FormItem
                label='Behavior impedes student’s learning or that of others'
                name='behaviorImpedesLearning'
                {...validationProps('behaviorImpedesLearning')}
              >
                <Radio.Group
                  defaultValue={data.behaviorImpedesLearning}
                  onChange={e => {
                    setBehaviorImpedesLearning(e.target.value)
                    handleMouseEvents.onChange(e)
                  }}
                  buttonStyle='solid'
                  name='behaviorImpedesLearning'
                  disabled={formDisabledState.behaviorImpedesLearning}
                >
                  <Radio.Button value='true'>Yes</Radio.Button>
                  <Radio.Button value='false'>No</Radio.Button>
                </Radio.Group>
              </FormItem>

              <Form.Item
                hidden={!(behaviorImpedesLearning === 'true')}
                label='Include any completed Functional Behavioral Assessment/Behavioral Intervention Plans:'
                name='behaviorImpedesLearningForms'
                {...validationProps('behaviorImpedesLearningForms')}
              >
                <Editor
                  {...handleEditorEvents}
                  formField='behaviorImpedesLearningForms'
                  disabled={formDisabledState.behaviorImpedesLearningForms}
                />
              </Form.Item>
            </Card>

            <Card
              hidden={
                !(communicationNeeds === 'true') && !(englishLearner === 'true')
              }
              title='LINGUISTIC AND CULTURAL ACCOMMODATIONS'
              style={{
                marginBottom: 30
              }}
            >
              <FormItem
                label='The student requires accommodations for the IEP to meet her/his linguistic and cultural needs. This includes students who are deaf/hard of hearing.'
                name='requiresLinguisticCultural'
                {...validationProps('requiresLinguisticCultural')}
              >
                <Radio.Group
                  defaultValue={data.requiresLinguisticCultural}
                  onChange={e => {
                    setRequiresLinguisticCultural(e.target.value)
                    handleMouseEvents.onChange(e)
                  }}
                  buttonStyle='solid'
                  name='requiresLinguisticCultural'
                  disabled={formDisabledState.requiresLinguisticCultural}
                >
                  <Radio.Button value='true'>Yes</Radio.Button>
                  <Radio.Button value='false'>No</Radio.Button>
                </Radio.Group>
              </FormItem>

              <Form.Item
                hidden={!(requiresLinguisticCultural === 'true')}
                label='Linguistic and cultural accommodations needed.'
                name='linguisticCulturalAccommodations'
                {...validationProps('linguisticCulturalAccommodations')}
              >
                <Editor
                  {...handleEditorEvents}
                  formField='linguisticCulturalAccommodations'
                  disabled={formDisabledState.linguisticCulturalAccommodations}
                />
              </Form.Item>

              <FormItem
                label='The special education and related services will be provided in a language or mode of communication other than or in addition to English. This includes services provided to students who are deaf/hard of hearing.'
                name='specialEducationRelatedServicesLanguage'
                {...validationProps('specialEducationRelatedServicesLanguage')}
              >
                <Radio.Group
                  defaultValue={data.specialEducationRelatedServicesLanguage}
                  onChange={e => {
                    setSpecialEducationRelatedServicesLanguage(e.target.value)
                    handleMouseEvents.onChange(e)
                  }}
                  buttonStyle='solid'
                  name='specialEducationRelatedServicesLanguage'
                  disabled={
                    formDisabledState.specialEducationRelatedServicesLanguage
                  }
                >
                  <Radio.Button value='true'>Yes</Radio.Button>
                  <Radio.Button value='false'>No</Radio.Button>
                </Radio.Group>
              </FormItem>

              <Form.Item
                hidden={!(specialEducationRelatedServicesLanguage === 'true')}
                label='Special education and related services accommodations needed:'
                name='specialEducationLinguisticAccommodations'
                {...validationProps('specialEducationLinguisticAccommodations')}
              >
                <Editor
                  {...handleEditorEvents}
                  formField='specialEducationLinguisticAccommodations'
                  disabled={
                    formDisabledState.specialEducationLinguisticAccommodations
                  }
                />
              </Form.Item>

              <Form.Item
                label='Identify the language and communication need(s):'
                name='languageCommunicationNeeds'
                {...validationProps('languageCommunicationNeeds')}
              >
                <Checkbox.Group
                  options={accommodationOptions}
                  onChange={checkedValues => {
                    setOther(isOther(checkedValues))
                    const e = {
                      target: {
                        name: 'languageCommunicationNeeds',
                        value: checkedValues.toString()
                      }
                    }
                    handleMouseEvents.onChange(e)
                  }}
                  defaultValue={[]}
                  disabled={formDisabledState.languageCommunicationNeeds}
                />
              </Form.Item>

              <FormItem
                hidden={!other}
                label='Please describe other:'
                name='otherText'
                validateStatus={itemErrors.otherText ? 'error' : ''}
                help={itemErrors.otherText}
                {...validationProps('otherText')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.otherText}
                />
              </FormItem>

              <Form.Item
                label='List the opportunities for direct communication/interaction with peers and professional personnel in the child’s language and communication mode:'
                name='directCommunicationOpportunities'
                {...validationProps('directCommunicationOpportunities')}
              >
                <Editor
                  {...handleEditorEvents}
                  formField='directCommunicationOpportunities'
                  disabled={formDisabledState.directCommunicationOpportunities}
                />
              </Form.Item>

              <Form.Item
                label='List the identified mode of communication accessible in academic instruction, school services, and extracurricular activities that the student will receive:'
                name='modeOfCommunication'
                {...validationProps('modeOfCommunication')}
              >
                <Editor
                  {...handleEditorEvents}
                  formField='modeOfCommunication'
                  disabled={formDisabledState.modeOfCommunication}
                />
              </Form.Item>
            </Card>

            <Card
              title='Supplementary Aids, Accommodations, and Modifications'
              style={{
                marginBottom: 30
              }}
            >
              <Form.Item
                label='Specify what aids, accommodations, and modifications are needed for the child to make progress toward annual goals.'
                name='supplementaryAids'
                {...validationProps('supplementaryAids')}
              >
                <Editor
                  {...handleEditorEvents}
                  formField='supplementaryAids'
                  disabled={formDisabledState.supplementaryAids}
                />
              </Form.Item>
            </Card>

            <Card
              title='SUPPORTS FOR SCHOOL PERSONNEL'
              style={{
                marginBottom: 30
              }}
            >
              <FormItem
                label='Program trainings and/or supports for school personnel are needed for the student to advance appropriately
              toward attaining the annual goals, participate in the general curriculum, and be educated and participate with
              other students in educational activities.
              '
                name='programTrainings'
                {...validationProps('programTrainings')}
              >
                <Radio.Group
                  defaultValue={data.programTrainings}
                  onChange={e => {
                    setProgramTrainings(e.target.value)
                    handleMouseEvents.onChange(e)
                  }}
                  buttonStyle='solid'
                  name='programTrainings'
                  disabled={formDisabledState.programTrainings}
                >
                  <Radio.Button value='true'>Yes</Radio.Button>
                  <Radio.Button value='false'>No</Radio.Button>
                </Radio.Group>
              </FormItem>

              <Form.Item
                hidden={!(programTrainings === 'true')}
                label='Trainings and/or supports needed:'
                name='programTrainingsText'
                {...validationProps('programTrainingsText')}
              >
                <Editor
                  {...handleEditorEvents}
                  formField='programTrainingsText'
                  disabled={formDisabledState.programTrainingsText}
                />
              </Form.Item>
            </Card>

            <Card
              title='PARENT TRAINING AND COUNSELING'
              style={{
                marginBottom: 30
              }}
            >
              <FormItem
                label="Parent counseling and training services are needed to assist parents in understanding the special needs of their child, 
                provide parents with information about child development, and help parents to acquire the necessary skills that will allow 
                them to support the implementation of their child's IEP."
                name='parentTraining'
                {...validationProps('parentTraining')}
              >
                <Radio.Group
                  defaultValue={data.parentTraining}
                  onChange={e => {
                    setParentTraining(e.target.value)
                    handleMouseEvents.onChange(e)
                  }}
                  buttonStyle='solid'
                  name='parentTraining'
                  disabled={formDisabledState.parentTraining}
                >
                  <Radio.Button value='true'>Yes</Radio.Button>
                  <Radio.Button value='false'>No</Radio.Button>
                </Radio.Group>
              </FormItem>

              <Form.Item
                hidden={!(parentTraining === 'true')}
                label='Parent Training and Counseling'
                name='parentTrainingText'
                {...validationProps('parentTrainingText')}
              >
                <Editor
                  {...handleEditorEvents}
                  formField='parentTrainingText'
                  disabled={formDisabledState.parentTrainingText}
                />
              </Form.Item>
            </Card>

            {formError && <Paragraph type='danger'>{formError}</Paragraph>}
            <StudentFormActions
              query={PRINT_FORM3454N}
              extractDataProp='printStudentForm3454N'
              canSave={canSave}
              handleActionEvents={handleActionEvents}

              getFormFieldValues={getFormFieldValues}
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired
}

const StudentForm3454N = () => {
  // get current form information
  const { currentStudentInfo, currentEditableForm, studentMeetingId } =
    useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId } = currentEditableForm

  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    QUERY_STUDENT_NAME,
    { variables: { id: studentId } }
  )
  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORM3454N_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )

  const createInitialFormData = (studentInfo, versionInfo) => {
    let initialValues = {}

    if (!studentInfo || !versionInfo) return initialValues

    const { studentForm3454N: form } = versionInfo
    const { student } = studentInfo
    const { formDate, studentFullName } = form

    initialValues = {
      ...form,
      formDate: formDate ? moment(formDate) : moment(Date.now()),
      studentFullName: studentFullName || student.fullName || ''
    }

    return initialValues
  }
  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
          />
        }
      />
    </FormErrorProvider>
  )
}
export default StudentForm3454N
