// react
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

// apollo & operations
import { useQuery } from '@apollo/client'
import { QUERY_STUDENT } from '../../../operations/queries/students'

// ant design
import { Skeleton, Card, Grid, Row, Col, Button } from 'antd'
// import { CaretRightOutlined, CaretDownOutlined } from '@ant-design/icons'

// moment
import moment from 'moment'

import FileUploadStudent from '../../elements/FileUploadStudent'
const { useBreakpoint } = Grid

const StudentCard = ({ id }) => {
  const screens = useBreakpoint()
  const [collapse, setCollapse] = useState()
  const { loading, data } = useQuery(QUERY_STUDENT, {
    variables: { id, includeGoals: false },
    skip: !id
  })
  useEffect(() => {
    // collapse by default on smaller screens
    collapse === undefined &&
      screens.lg !== undefined &&
      setCollapse(screens.lg)
  }, [screens])

  if (!id) {
    return <></>
  }

  const gridStyle = {
    // display: "flex",
    width: '100%',
    outerHeight: 'flex',
    textAlign: 'left'
  }
  return (
    <>
      {data && (
        <>
          <div style={{ display: 'flex' }}>
            <Card style={gridStyle} title={<> Student info </>}>
              <Skeleton loading={loading} active>
                <Row gutter={16}>
                  <Col flex='100px'>First Name:</Col>
                  <Col flex='auto'>{data?.student.firstName}</Col>
                </Row>
                <Row gutter={16}>
                  <Col flex='100px'>Last Name:</Col>
                  <Col flex='auto'>{data?.student.lastName}</Col>
                </Row>
                <Row gutter={16}>
                  <Col flex='100px'>Grade:</Col>
                  <Col flex='auto'>{data?.student.grade}</Col>
                </Row>
                <Row gutter={16}>
                  <Col flex='100px'>Code:</Col>
                  <Col flex='auto'>{data?.student.studentCode}</Col>
                </Row>
                <Row gutter={16}>
                  <Col flex='100px'>School:</Col>
                  <Col flex='auto'>{data?.student.schools[0]?.name}</Col>
                </Row>
                <Row gutter={16}>
                  <Col flex='100px'>DOB:</Col>
                  <Col flex='auto'>
                    {moment(data?.student.dateOfBirth).format('MM-DD-YYYY')}
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col flex='100px'>Age:</Col>
                  <Col flex='auto'>{data?.student.age}</Col>
                </Row>
                <Row gutter={16}>
                  <Col flex='100px'>Phone:</Col>
                  <Col flex='auto'>{data?.student.phoneNumber}</Col>
                </Row>
                <Row gutter={16}>
                  <Col flex='100px'>Address:</Col>
                  <Col flex='auto'>{data?.student.address}</Col>
                </Row>
                <Row gutter={16}>
                  <Col flex='100px'>City:</Col>
                  <Col flex='auto'>{data?.student.city}</Col>
                </Row>
                <Row gutter={16}>
                  <Col flex='100px'>Zip:</Col>
                  <Col flex='auto'>{data?.student.zip}</Col>
                </Row>
                <Row gutter={16}>
                  <Col flex='100px'>State:</Col>
                  <Col flex='auto'>{data?.student.state}</Col>
                </Row>
                <Row gutter={16} type='flex' align='center'>
                  <Col flex='100px' style={{ paddingBottom: '10px' }}>
                    <Link to={`../../student/edit/${data?.student.id}`}>
                      <Button>Edit Student Info</Button>
                    </Link>
                  </Col>
                  <Col>
                    <FileUploadStudent studentData={data} />
                  </Col>
                </Row>
              </Skeleton>
            </Card>
          </div>
        </>
      )}
    </>
  )
}

StudentCard.propTypes = {
  id: PropTypes.number.isRequired
}

export default StudentCard
