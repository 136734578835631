// react
import React, { useState } from 'react'

// ant design
import { Card } from 'antd'

// operations
import { QUERY_DISTRICTS_SUMMARY_REPORT } from '../../../operations/queries/reportBuilder'

// components
import Listing from '../../Listing'
import DistrictSummaryReportSearchForm from './DistrictSummaryReportSearchForm'

const DistrictSummaryReport = () => {
  const [criteria, setCriteria] = useState()

  const onSearch = criteria => {
    setCriteria(criteria)
    console.log('after onSearch', criteria)
  }

  const columns = [
    {
      title: 'District',
      dataIndex: 'district',
      render: district => district.name
    },
    {
      title: 'Number of Schools',
      dataIndex: 'schools'
    },
    {
      title: 'Number of Students',
      dataIndex: 'students'
    },
    {
      title: 'Number of Ieps',
      dataIndex: 'ieps'
    },
    {
      title: 'Number of Users',
      dataIndex: 'users'
    }
  ]

  const fields = {
    district: {
      header: 'District Name',
      formatter: fieldValue => fieldValue.name
    },
    schools: {
      header: 'Number of Schools',
      formatter: fieldValue => fieldValue
    },
    students: {
      header: 'Number of Students',
      formatter: fieldValue => fieldValue
    },
    ieps: {
      header: 'Number of IEPs',
      formatter: fieldValue => fieldValue
    },
    users: {
      header: 'Number of Users',
      formatter: fieldValue => fieldValue
    }
  }

  return (
    <>
      <div className='page-container'>
        <Card title='Filters' bordered={false}>
          <DistrictSummaryReportSearchForm onSearch={onSearch} />
        </Card>
      </div>
      {!!criteria && (
        <Listing
          query={QUERY_DISTRICTS_SUMMARY_REPORT}
          exportable={{
            exportTableButton: {
              fields
            }
          }}
          columns={columns}
          criterias={criteria}
          fields={fields}
          dataKey='districtSummaryReport'
          render={(table, exportCsvButton) => {
            return (
              <>
                <div className='page-container'>
                  <Card
                    title='District Summary Report'
                    bordered={false}
                    extra={exportCsvButton}
                  >
                    {table}
                  </Card>
                </div>
              </>
            )
          }}
        />
      )}
    </>
  )
}

export default DistrictSummaryReport
