import { gql } from "@apollo/client";

export const QUERY_FORM3457I_VERSION = gql`
  query studentForm3457I(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
  ) {
    studentForm3457I(
      studentFormId: $studentFormId
      studentId: $studentId
      formId: $formId
      schoolId: $schoolId
      studentMeetingId: $studentMeetingId
    ) {
      versionId
      formId
      formDate
      studentFullName
      studentDob
      parent1
      parent2
      ageOfMajorityDate
      noticeTypeAnticipated
      noticeTypeTransfered
      documentContactName
      documentContactTitle
      documentContactPhone
      signatureName
      signatureTitle
      formAction
      formStatus
    }
  }
`;
