// react
import React, { useEffect, useContext } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

// ant design
import { Modal } from "antd";

// contexts
import ApolloContext from "../../utils/apolloProvider";

// components
import Page from "../../components/Page";

// apollo & operations
import { QUERY_STUDENT } from "../../operations/queries/students";
import { UPDATE_STUDENT } from "../../operations/mutations/students";

// query params
const useQuery = () => new URLSearchParams(useLocation().search);

const StudentDelete = (props) => {
  const apollo = useContext(ApolloContext);
  const history = useNavigate();
  const { id } = useParams();
  const query = useQuery();
  const breadcrumb = [
    {
      path: "/",
      breadcrumbName: "Home",
    },
    {
      path: "/students",
      breadcrumbName: "Students",
    },
    {
      path: "",
      breadcrumbName: "Delete student",
    },
  ];

  const deleteStudent = async () => {
    try {
      const response = await apollo.client.query({
        query: QUERY_STUDENT,
        variables: { id, includeGoals: true },
      });
      const student = { ...response.data.student };
      // @todo should only update hidden
      await apollo.client.mutate({
        mutation: UPDATE_STUDENT,
        variables: {
          id: id,
          studentInput: { email: student.email, hidden: true },
        },
      });
      Modal.success({
        title: "Success",
        content: "The student has been hidden.",
        okText: "Return to listing",
        onOk: () => history.replace("/students"),
      });
    } catch (e) {
      Modal.error({
        title: "An error has occurred",
        content: "The student has not been hidden.",
        okText: "Return to listing",
        onOk: () => history.replace("/students"),
      });
    }
  };

  useEffect(() => {
    if (query.get("confirm") === "1") {
      deleteStudent();
    }
  }, []);

  return (
    <>
      <Page title="Delete student" breadcrumb={breadcrumb} />
    </>
  );
};

export default StudentDelete;
