import { gql } from "@apollo/client";

export const QUERY_LOOKUPS = gql`
  query ($type: String!, $districtId: ID) {
    lookups(type: $type, districtId: $districtId) {
      id
      districtId
      district {
        name
      }
      value
      valueGeneral
      defaultFormCodes
      hidden
      isGlobal
      canBeIep
    }
  }
`;

export const QUERY_LOOKUP = gql`
  query ($id: ID!) {
    lookup(id: $id) {
      id
      districtId
      district {
        name
      }
      typeId
      type {
        name
      }
      value
      valueGeneral
      #defualtFormCodes[String] and defaultForms{} only loaded for a lookup "type" of "Meeting Type"
      defaultFormCodes
      defaultForms {
        #id in forms collection
        id
        formCode
        name
      }
      isGlobal
      canBeIep
      hidden
      createdBy
      createdAtAgo
      updatedAtAgo
      createdAt
      updatedAt
    }
  }
`;

export const QUERY_LOOKUP_TYPES = gql`
  query {
    lookupTypes {
      id
      name
      nameGeneral
      hidden
    }
  }
`;
