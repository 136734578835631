import { gql } from "@apollo/client";

export const QUERY_FORM3457D_VERSION = gql`
  query studentForm3457D(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
  ) {
    studentForm3457D(
      studentFormId: $studentFormId
      studentId: $studentId
      formId: $formId
      schoolId: $schoolId
      studentMeetingId: $studentMeetingId
    ) {
      versionId
      formId
      formDate
      studentFullName
      studentDob
      parent1
      parent2
      meetingDateAndTime
      meetingLocation
      tenDayWaiverAccepted
      tenDayWaiverInitials
      tenDayWaiverAcceptedDate
      bringOtherIndividualContactInfo
      interpretationQuestionsContactInfo
      requestInterpreterContactName
      requestInterpreterContactTitle
      requestInterpreterContactTelephone
      meetingArrangementsContactName
      meetingArrangementsContactTitle
      meetingArrangementsContactTelephone
      purposeReview
      purposeInitialEvaluation
      purposeReEvaluation
      purposeIndividualizedEducation
      purposeIEPReview
      purposeSecondaryTransition
      purposeFunctionalAssessment
      purposeManifestationReview
      purposeGraduation
      purposeOther
      generalEducationTeacherName
      specialEducationTeacherName
      representativeLEAName
      otherNames
      iepName
      iepTitle
      iepTelephone
      signatureName
      signatureTitle
      formAction
      formStatus
    }
  }
`;
