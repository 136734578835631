import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useLazyQuery } from "@apollo/client";

import { Form, Input } from "antd";
import { SyncOutlined } from "@ant-design/icons";

import { QUERY_STUDENT_PARENT_NAMES } from "../../../../operations/queries/forms/SIS";
import SyncSISButton from "../../../elements/formItems/SyncSISButton";

const StudentParent2SIS = ({
  form,
  sis,
  id,
  initialValue,
  error,
  disabled = true,
}) => {
  const [
    getStudentParents,
    { data: studentParentsData, loading: parentsLoading },
  ] = useLazyQuery(QUERY_STUDENT_PARENT_NAMES);

  const [studentParent2FullName, setStudentParent2FullName] =
    useState(initialValue);
  useEffect(() => {
    if (!studentParentsData) return;
    const parent2 = studentParentsData.student.parent2FullName || "";
    form.setFieldsValue({
      parent2,
    });
    setStudentParent2FullName(parent2);
  }, [studentParentsData]);

  return (
    <>
      <Form.Item
        label="Parent/Guardian Name #2"
        name="parent2"
        validateStatus={error ? "error" : ""}
        help={error}
      >
        <Input
          onChange={(e) => setStudentParent2FullName(e.target.value)}
          disabled={disabled}
          addonAfter={
            <SyncSISButton
              loading={parentsLoading}
              sisValue={sis?.student.parent2FullName}
              currentValue={studentParent2FullName}
            >
              <SyncOutlined
                onClick={() => getStudentParents({ variables: { id: id } })}
              />
            </SyncSISButton>
          }
        />
      </Form.Item>
    </>
  );
};

StudentParent2SIS.propTypes = {
  form: PropTypes.object.isRequired,
  initialValue: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  error: PropTypes.string,
  disabled: PropTypes.bool,
};

export default StudentParent2SIS;
