import React from "react";
import PropTypes from "prop-types";

// apollo & operations
import { useQuery } from "@apollo/client";

// ant design
import { Form, Select } from "antd";
import { QUERY_STATUES } from "../../../operations/queries/status";

const FormItemSelectFormStatus = ({
  label,
  name,
  validateStatus,
  help,
  hasFeedback,
  mode,
}) => {
  const { data, loading } = useQuery(QUERY_STATUES);

  return (
    <Form.Item
      label={label}
      name={name}
      validateStatus={validateStatus}
      help={help}
      hasFeedback={hasFeedback}
    >
      <Select
        mode={mode}
        loading={loading}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) =>
          optionA.children
            .toString().toLowerCase()
            .localeCompare(optionB.children.toString().toLowerCase())
        }
      >
        {data?.formStatuses &&
          data.formStatuses.map((status) => (
            <Select.Option key={status.id} value={status.id}>
              {status.enumName}
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  );
};

FormItemSelectFormStatus.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  help: PropTypes.string,
  validateStatus: PropTypes.string,
  hasFeedback: PropTypes.bool,
  mode: PropTypes.string,
};

export default FormItemSelectFormStatus;
