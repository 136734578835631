import { gql } from "@apollo/client";

export const QUERY_FORMTAMES5_VERSION = gql`
  query studentFormTames5(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
  ) {
    studentFormTames5(
      studentFormId: $studentFormId
      studentId: $studentId
      formId: $formId
      schoolId: $schoolId
      studentMeetingId: $studentMeetingId
    ) {
      versionId
      formId
      formDate
      studentDob
      studentFullName
      intdAdverseEffects
      intdEducationalNeeds
      intdEligible
      piAdverseEffects
      piEducationalNeeds
      piEligible
      sldAdverseEffects
      sldEducationalNeeds
      sldEligible
      viAdverseEffects
      viEducationalNeeds
      viEligible
      hiAdverseEffects
      hiEducationalNeeds
      hiEligible
      dAdverseEffects
      dEducationalNeeds
      dEligible
      dbAdverseEffects
      dbEducationalNeeds
      dbEligible
      ohiAdverseEffects
      ohiEducationalNeeds
      ohiEligible
      edAdverseEffects
      edEducationalNeeds
      edEligible
      ohAdverseEffects
      ohEducationalNeeds
      ohEligible
      mdAdverseEffects
      mdEducationalNeeds
      mdEligible
      autAdverseEffects
      autEducationalNeeds
      autEligible
      tbiAdverseEffects
      tbiEducationalNeeds
      tbiEligible
      conferenceNotes
      formAction
      formStatus
    }
  }
`;
