import { gql } from "@apollo/client";

export const QUERY_FORMTAMES1_VERSION = gql`
  query studentFormTames1(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
  ) {
    studentFormTames1(
      studentFormId: $studentFormId
      studentId: $studentId
      formId: $formId
      schoolId: $schoolId
      studentMeetingId: $studentMeetingId
    ) {
      versionId
      formId
      formDate
      studentDob
      teamFormationDate
      teamInitialMeetingDate
      studentFullName
      teamMemberName
      teamMemberTitle
      formAction
      formStatus
    }
  }
`;
