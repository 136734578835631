import { gql } from "@apollo/client";

export const IMPORT_CSV = gql`
  mutation importCsv($importCsvInput: ImportCsvInput!) {
    importCsv(importCsvInput: $importCsvInput) {
      records
      inserted
      updated
    }
  }
`;

export const QUERY_DISTRICT_IMPORT_MODELS = gql`
  query ($id: ID!) {
    district(id: $id) {
      districtImportModels {
        model
        requiredFieldsDisplay
        optionalFieldsDisplay
      }
    }
  }
`;
