import { gql } from "@apollo/client";

export const INSERT_STUDENT = gql`
  mutation insertStudent(
    $schoolIds: [ID]
    $studentCode: String
    $firstName: String
    $middleName: String
    $lastName: String
    $medicaidNumber: String
    $dateOfBirth: String
    $grade: String
    $primaryLanguage: String
    $gender: String
    $ethnicity: String
    $phoneNumber: String
    $address: String
    $city: String
    $state: String
    $country: String
    $zip: String
    $parent1FirstName: String
    $parent1LastName: String
    $parent1Relationship: String
    $parent1PrimaryLanguage: String
    $parent1PhoneNumber: String
    $parent1Address: String
    $parent1City: String
    $parent1State: String
    $parent1Country: String
    $parent1Zip: String
    $parent2FirstName: String
    $parent2LastName: String
    $parent2Relationship: String
    $parent2PrimaryLanguage: String
    $parent2PhoneNumber: String
    $parent2Address: String
    $parent2City: String
    $parent2State: String
    $parent2Country: String
    $parent2Zip: String
    $anticipatedGraduationDate: String
    $initialEvaluationConsent: String
    $initialEvaluationConsentDate: String
    $reEvaluationConsent: String
    $reEvaluationConsentDate: String
    $lastAnnualReviewDate: String
    $nextAnnualReviewDate: String
    $triennialReEvaluationDueDate: String
    $caseManager: String
    $currentGrade: String
    $residentDistrict: String
    $residentSchool: String
    $servingDistrict: String
    $servingSchool: String
    $nextYearCaseManager: String
    $nextYearGrade: String
    $nextYearResidentDistrict: String
    $nextYearResidentSchool: String
    $nextYearServingDistrict: String
    $nextYearServingSchool: String
    $currentGoalIds: [ID]
    $priorGoalIds: [ID]
    $hidden: Boolean
  ) {
    insertStudent(
      studentInput: {
        schoolIds: $schoolIds
        studentCode: $studentCode
        firstName: $firstName
        middleName: $middleName
        lastName: $lastName
        medicaidNumber: $medicaidNumber
        dateOfBirth: $dateOfBirth
        grade: $grade
        primaryLanguage: $primaryLanguage
        gender: $gender
        ethnicity: $ethnicity
        phoneNumber: $phoneNumber
        address: $address
        city: $city
        state: $state
        country: $country
        zip: $zip
        parent1FirstName: $parent1FirstName
        parent1LastName: $parent1LastName
        parent1Relationship: $parent1Relationship
        parent1PrimaryLanguage: $parent1PrimaryLanguage
        parent1PhoneNumber: $parent1PhoneNumber
        parent1Address: $parent1Address
        parent1City: $parent1City
        parent1State: $parent1State
        parent1Country: $parent1Country
        parent1Zip: $parent1Zip
        parent2FirstName: $parent2FirstName
        parent2LastName: $parent2LastName
        parent2Relationship: $parent2Relationship
        parent2PrimaryLanguage: $parent2PrimaryLanguage
        parent2PhoneNumber: $parent2PhoneNumber
        parent2Address: $parent2Address
        parent2City: $parent2City
        parent2State: $parent2State
        parent2Country: $parent2Country
        parent2Zip: $parent2Zip
        anticipatedGraduationDate: $anticipatedGraduationDate
        initialEvaluationConsent: $initialEvaluationConsent
        initialEvaluationConsentDate: $initialEvaluationConsentDate
        reEvaluationConsent: $reEvaluationConsent
        reEvaluationConsentDate: $reEvaluationConsentDate
        lastAnnualReviewDate: $lastAnnualReviewDate
        nextAnnualReviewDate: $nextAnnualReviewDate
        triennialReEvaluationDueDate: $triennialReEvaluationDueDate
        caseManager: $caseManager
        currentGrade: $currentGrade
        residentDistrict: $residentDistrict
        residentSchool: $residentSchool
        servingDistrict: $servingDistrict
        servingSchool: $servingSchool
        nextYearCaseManager: $nextYearCaseManager
        nextYearGrade: $nextYearGrade
        nextYearResidentDistrict: $nextYearResidentDistrict
        nextYearResidentSchool: $nextYearResidentSchool
        nextYearServingDistrict: $nextYearServingDistrict
        nextYearServingSchool: $nextYearServingSchool
        currentGoalIds: $currentGoalIds
        priorGoalIds: $priorGoalIds
        hidden: $hidden
      }
    ) {
      id
      studentCode
      firstName
      middleName
      lastName
      medicaidNumber
      dateOfBirth
      grade
      primaryLanguage
      gender
      ethnicity
      phoneNumber
      address
      city
      state
      country
      zip
      parent1FirstName
      parent1LastName
      parent1Relationship
      parent1PrimaryLanguage
      parent1PhoneNumber
      parent1Address
      parent1City
      parent1State
      parent1Country
      parent1Zip
      parent2FirstName
      parent2LastName
      parent2Relationship
      parent2PrimaryLanguage
      parent2PhoneNumber
      parent2Address
      parent2City
      parent2State
      parent2Country
      parent2Zip
      anticipatedGraduationDate
      initialEvaluationConsent
      initialEvaluationConsentDate
      reEvaluationConsent
      reEvaluationConsentDate
      lastAnnualReviewDate
      nextAnnualReviewDate
      triennialReEvaluationDueDate
      caseManager
      currentGrade
      residentDistrict
      residentSchool
      servingDistrict
      servingSchool
      nextYearCaseManager
      nextYearGrade
      nextYearResidentDistrict
      nextYearResidentSchool
      nextYearServingDistrict
      nextYearServingSchool
      hidden
      schoolIds
      schools {
        id
      }
      currentGoalIds
      priorGoalIds
    }
  }
`;

export const UPDATE_STUDENT = gql`
  mutation updateStudent($id: ID!, $studentInput: StudentInput) {
    updateStudent(id: $id, studentInput: $studentInput) {
      id
      studentCode
      firstName
      middleName
      lastName
      medicaidNumber
      dateOfBirth
      grade
      primaryLanguage
      gender
      ethnicity
      phoneNumber
      address
      city
      state
      country
      zip
      parent1FirstName
      parent1LastName
      parent1Relationship
      parent1PrimaryLanguage
      parent1PhoneNumber
      parent1Address
      parent1City
      parent1State
      parent1Country
      parent1Zip
      parent2FirstName
      parent2LastName
      parent2Relationship
      parent2PrimaryLanguage
      parent2PhoneNumber
      parent2Address
      parent2City
      parent2State
      parent2Country
      parent2Zip
      anticipatedGraduationDate
      initialEvaluationConsent
      initialEvaluationConsentDate
      reEvaluationConsent
      reEvaluationConsentDate
      lastAnnualReviewDate
      nextAnnualReviewDate
      triennialReEvaluationDueDate
      caseManager
      currentGrade
      residentDistrict
      residentSchool
      servingDistrict
      servingSchool
      nextYearCaseManager
      nextYearGrade
      nextYearResidentDistrict
      nextYearResidentSchool
      nextYearServingDistrict
      nextYearServingSchool
      hidden
      schools {
        id
      }
      currentGoalIds 
      priorGoalIds
    }
  }
`;

export const UPDATE_STUDENT_IEP_META = gql`
  mutation updateStudentIEPMeta(
    $id: ID!
    $studentIEPMetaInput: StudentIEPMetaInput
  ) {
    updateStudentIEPMeta(id: $id, studentIEPMetaInput: $studentIEPMetaInput) {
      id
      caseManager
      anticipatedGraduationDate
      residentDistrict
      residentSchool
      servingDistrict
      servingSchool
      currentGrade
      nextYearCaseManager
      nextYearGrade
      nextYearResidentDistrict
      nextYearResidentSchool
      nextYearServingDistrict
      nextYearServingSchool
      lastAnnualReviewDate
      nextAnnualReviewDate
      initialEvaluationConsent
      initialEvaluationConsentDate
      reEvaluationConsent
      reEvaluationConsentDate
      triennialReEvaluationDueDate
    }
  }
`;

export const INSERT_STUDENT_UPLOADED_DOC = gql`
  mutation insertStudentUploadedDoc(
    $id: ID!
    $uploadedDoc: UploadedDocInput
  ) {
    insertStudentUploadedDoc(
      id: $id
      uploadedDoc: $uploadedDoc
    ) {
      id
      uploadedDocs {
        id
        name
        contentType
        uniqueName
        url
        docDate
        createdAt
      }
    }
  }
`;

export const DELETE_STUDENT_UPLOADED_DOC = gql`
  mutation deleteStudentUploadedDoc(
    $id: ID!
    $uploadedDocId: ID!
  ) {
    deleteStudentUploadedDoc(
      id: $id
      uploadedDocId: $uploadedDocId
    ) {
      id
      uploadedDocs {
        id
        name
        contentType
        uniqueName
        url
        docDate
        createdAt
      }
    }
  }
`;