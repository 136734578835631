import { gql } from "@apollo/client";

export const QUERY_STUDENT_MEETING_DISTRICT_FORMS = gql`
  query StudentMeetingGeneral($id: ID!) {
    studentMeetingGeneral(id: $id) {
      id
      meetingType
      meetingDate
      isActiveIep
      iepMeetingId
      parentNotifications {
        parentName
        contactMethod
        date
      }
      studentId
      schoolId
      districtId
      defaultFormCodes
      defaultFormIds
      districtFormsList {
      formId
      formCode
      name
      formTypeId
      formType
      formTypeOrder
      formWarning
      allowMultipleInstances
      active
      downloadFileName
      keyDates
      meetingDefaultForm
      meetingFormInstances
      userSubRoleFormPermission {
        view
        print
        add
      }
      }
    }
  }
`;

export const QUERY_STUDENT_MEETING_FORMS = gql`
  query StudentMeetingGeneral($id: ID!) {
    studentMeetingGeneral(id: $id) {
      id
      meetingType
      meetingDate
      isActiveIep
      iepMeetingId
      parentNotifications {
        id
        parentName
        contactMethod
        date
      }
      studentId
      schoolId
      districtId
      district {
        id
      }
      studentFormIds
      uploadedDocs {
        id
        name
        contentType
        uniqueName
        url
        docDate
        createdAt
      }
      meetingForms {
        formTypeId
        display
        forms {
          studentFormId
          formId
          currentVersionId
          formStatusId
          isFinalized
          formCode
          name
          keyDates
          formTypeId
          formType
          formTypeOrder
          formStatusEnumName
          formStatusIsDraft
          formWarning
          allowMultipleInstances
          formInstance
          formInstances
          userSubRoleFormPermission {
            view
            print
            add
          }
        }
      }
    }
  }
`;
export const QUERY_STUDENT_FORM_STATUS = gql`
  query StudentFormStatus( $studentFormId: ID!, $formId: ID!, $studentMeetingId: ID!) {
    studentFormStatus( studentFormId: $studentFormId, formId: $formId, studentMeetingId: $studentMeetingId) {
      enumName
      display
      description
    }
  }
`;

export const QUERY_STUDENT_MEETING_META = gql`
  query StudentMeetingGeneral($id: ID!) {
    studentMeetingGeneral(id: $id) {
      meta {
        caseManager
        anticipatedGraduationDate
        residentDistrict
        residentSchool
        servingDistrict
        servingSchool
        currentGrade
        nextYearCaseManager
        nextYearGrade
        nextYearResidentDistrict
        nextYearResidentSchool
        nextYearServingDistrict
        nextYearServingSchool
        lastAnnualReviewDate
        nextAnnualReviewDate
        initialEvaluationConsent
        initialEvaluationConsentDate
        reEvaluationConsent
        reEvaluationConsentDate
        triennialReEvaluationDueDate
      }
    }
  }
`;
