import React from "react";
import { Layout, Row, Col } from "antd";
import { Link } from "react-router-dom";
// routes
import Router from "../Routes";

const { Content, Footer } = Layout;

const PublicLayout = () => {
  return (
    <Layout className="app-layout" style={{ minHeight: "100vh" }}>
      <Layout className="content-layout">
        <Content style={{ marginTop: "24px" }}>
          <Layout>
            <Router />
          </Layout>
        </Content>
        <Row justify="center">
          <Col >
            {/* footer for public is in login.js */}
            {/* <a href="http://tameshrs.com/" target="_blank">
          © {new Date().getFullYear()} TAMES/Health Resource Service Management,
          Inc.
        </a> */}
          </Col>
        </Row>
      </Layout>
    </Layout>
  );
};

export default PublicLayout;
