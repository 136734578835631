import { gql } from "@apollo/client";

export const PRINT_FORM3454G = gql`
  mutation printStudentForm3454G(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $studentDob: String
    $studentStrengths: String
    $parentalEducationConcerns: String
    $presentLevelAcademicAchievement: String
    $presentLevelFunctionalPerformance: String
    $evaluationResults: String
    $disabilityEffects: String
    $formAction: String
$formStatus: String

  ) {
    printStudentForm3454G(
      studentForm3454GInput: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        studentDob: $studentDob
        studentStrengths: $studentStrengths
        parentalEducationConcerns: $parentalEducationConcerns
        presentLevelAcademicAchievement: $presentLevelAcademicAchievement
        presentLevelFunctionalPerformance: $presentLevelFunctionalPerformance
        evaluationResults: $evaluationResults
        disabilityEffects: $disabilityEffects

formAction: $formAction
formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;
