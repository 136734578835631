import React, { useContext } from 'react'

import Page from '../../components/Page'
import SchoolReports from '../../components/sections/report/SchoolReports'
import Card from 'antd/lib/card/Card'
import AuthContext from '../../utils/authProvider'
import { useLocation } from 'react-router-dom';

const SchoolReportsPage = () => {
  //const location = useLocation(); //Odd.  Without using location here, the link state isn't available in the SchoolReports call below.  With it, alls good.
  //console.log('SchoolReportsPage',location) 

  const breadcrumb = [
    {
      path: '/',
      breadcrumbName: 'Home'
    },
    {
      path: '',
      breadcrumbName: 'School reports'
    }
  ]
  const Auth = useContext(AuthContext)
  const isSuperAdmin = Auth.hasRole('SUPER_ADMIN')
  return (
    <>
      <Page title='School reports' breadcrumb={breadcrumb}>
        <SchoolReports />

      </Page>
    </>
  )
}

export default SchoolReportsPage
