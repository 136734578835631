import { gql } from "@apollo/client";

export const QUERY_FORM3457C_VERSION = gql`
  query studentForm3457C(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
  ) {
    studentForm3457C(
      studentFormId: $studentFormId
      studentId: $studentId
      formId: $formId
      schoolId: $schoolId
      studentMeetingId: $studentMeetingId
    ) {
      versionId
      formId
      formDate
      studentFullName
      studentDob
      parent1
      parent2
      guardianAdditionalRequirementConsent
      guardianEvaluationConsent
      signatureDate
      formAction
      formStatus
    }
  }
`;
