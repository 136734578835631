// react
import React, { useState } from "react";

// ant design
import { Card } from "antd";

// operations
import { QUERY_DISTRICTS_USAGE_REPORT } from "../../../operations/queries/reportBuilder";

// components
import Listing from "../../Listing";
import DistrictSummaryReportSearchForm from "./DistrictSummaryReportSearchForm";

const DistrictUsageReport = () => {
  const [criteria, setCriteria] = useState();

  const onSearch = (criteria) => {
    setCriteria(criteria);
  };

  const columns = [
    {
      title: "District",
      dataIndex: "district",
      render: (district) => district.name,
    },
    {
      title: "Number of Ieps",
      dataIndex: "ieps",
    },
    {
      title: "Number of Users",
      dataIndex: "users",
    },
  ];

  const fields = {
    district: {
      header: "District Name",
      formatter: (fieldValue) => fieldValue.name,
    },
    ieps: {
      header: "Number of IEPs",
      formatter: (fieldValue) => fieldValue,
    },
    users: {
      header: "Number of Users",
      formatter: (fieldValue) => fieldValue,
    },
  };

  return (
    <>
      <div className="page-container">
        <Card title="Filters" bordered={false}>
          <DistrictSummaryReportSearchForm onSearch={onSearch} />
        </Card>
      </div>
      {!!criteria && (
        <Listing
          query={QUERY_DISTRICTS_USAGE_REPORT}
          exportable={{
            exportTableButton: {
              fields,
            },
          }}
          columns={columns}
          criterias={criteria}
          fields={fields}
          dataKey="districtUsageReport"
          render={(table, exportCsvButton) => {
            return (
              <>
                <div className="page-container">
                  <Card
                    title="District Usage Report"
                    bordered={false}
                    extra={exportCsvButton}
                  >
                    {table}
                  </Card>
                </div>
              </>
            );
          }}
        />
      )}
    </>
  );
};

export default DistrictUsageReport;
