import { useState, useEffect } from 'react'
import { Button, Card, Row, Col, Affix } from 'antd'
import { Document, Page } from 'react-pdf'
import { PRINT_ALL_STUDENT_MEETING_FORMS } from '../../operations/mutations/meetings'
import { useMutation } from '@apollo/client'
// import './pdf.css'

const RenderFormPdf = ({ formData, meetingData, props }) => {
  let [numPages, setNumPages] = useState(null)
  let [pageNumber, setPageNumber] = useState(1)
  const [renderForm, setRenderForm] = useState(false)
  const [pdfData, setPdfData] = useState({})
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }
  const pageForward = () => {
    if (pageNumber++ <= numPages) {
      setPageNumber(pageNumber++)
    }
  }
  const pageBackward = () => {
    if (pageNumber-- != 0) {
      setPageNumber(pageNumber--)
    }
  }

  const [printStudentMeetings, { loading }] = useMutation(
    PRINT_ALL_STUDENT_MEETING_FORMS
  )
  const studentFormIds = formData.formId

  const hideForm = () => {
    setRenderForm(false)
  }

  useEffect(async () => {
    if (props === true) {
      const response = await printStudentMeetings({
        variables: {
          studentMeetingId: formData?.meetingId,
          studentFormIds: [studentFormIds]
        }
      })
      setPdfData(response)
    }
  }, [])

  if (props === false) {
    hideForm
  }

  return (
    <>
      {formData?.formData?.length > 0 && (
        <>
          {pdfData != undefined && formData != undefined && !loading ? (
            <>
              <Affix offsetTop={5} onChange={affixed => console.log(affixed)}>
                <div>
                  <Row>
                    <Col>
                      <Button
                        type='primary'
                        onClick={pageBackward}
                        disabled={pageNumber <= 1}
                      >
                        Previous
                      </Button>
                    </Col>
                    &nbsp;&nbsp;
                    <Col>
                      <Button
                        type='primary'
                        onClick={pageForward}
                        disabled={pageNumber === numPages}
                      >
                        Next
                      </Button>
                    </Col>
                  </Row>
                  <Document
                    file={{
                      url: pdfData?.data?.printAllStudentMeetingForms?.link
                    }}
                    onLoadSuccess={onDocumentLoadSuccess}
                    // error=''
                    // onLoadError={''}
                  >
                    <Page
                      pageNumber={pageNumber}
                      scale={2}
                      renderTextLayer={false}
                    />{' '}
                  </Document>
                </div>
              </Affix>
            </>
          ) : (
            <>Loading...</>
          )}
        </>
      )}
    </>
  )
}

export default RenderFormPdf
