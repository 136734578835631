import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { notificationError } from "../../utils/notification";

const FilePrint = ({ url, fileName, version }) => {
  const [blobUrl, setBlobUrl] = useState("");

  const printIframe = (id) => {
    const iframe = document.frames
      ? document.frames[id]
      : document.getElementById(id);
    const iframeWindow = iframe.contentWindow || iframe;

    iframe.focus();
    iframeWindow.print();

    return false;
  };

  useEffect(() => {
    if (!url || !fileName) return;
    fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        setBlobUrl(url);
        setTimeout(() => {
          printIframe("print-iframe");
        }, 500);
      })
      .catch((e) => {
        notificationError(`Failed to download file at : ${url}`);
      });
    return () => {};
  }, [version]);

  return (
    <>
      <iframe
        src={blobUrl}
        title={fileName}
        style={{ display: "none" }}
        id="print-iframe"
      />
    </>
  );
};

FilePrint.propTypes = {
  url: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
};

export default FilePrint;
