import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { IepFormContext } from '../../../../utils/iepFormProvider'
import { useQuery } from '@apollo/client'
import { QUERY_STUDENT_COMMON_SIS } from '../../../../operations/queries/forms/SIS'
import { QUERY_FORMTAMES5_VERSION } from '../../../../operations/queries/forms/tames5'
import moment from 'moment'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import FormContainer from '../../../FormContainer'
import { Alert, Card, DatePicker, Form, Radio, Typography } from 'antd'
import FormCollaboration from '../../../FormCollaboration'
import Title from 'antd/lib/typography/Title'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import StudentDobSIS from '../sis/StudentDobSIS'
import StudentFormActions from '../StudentFormActions'
import { PRINT_FORMTAMES5 } from '../../../../operations/mutations/forms/tames5'
import Editor from '../../../elements/common/Editor'

const { Paragraph } = Typography

const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId
}) => {
  const [form] = Form.useForm()

  const { itemErrors, formError } = useContext(FormErrorContext)

  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()

    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      studentMeetingId: studentMeetingId
    }
  }

  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    const update = Object.assign({})
    switch (fieldName) {
      case 'formDate':
        update[fieldName] = fieldValue ? moment(fieldValue) : ''
        break
      case 'studentDob':
        update[fieldName] = fieldValue ? moment(fieldValue) : ''
        break
      case 'intdEligible':
      case 'piEligible':
      case 'sldEligible':
      case 'viEligible':
      case 'hiEligible':
      case 'dEligible':
      case 'dbEligible':
      case 'ohiEligible':
      case 'edEligible':
      case 'ohEligible':
      case 'mdEligible':
      case 'autEligible':
      case 'tbiEligible':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        break
      default:
        update[fieldName] = fieldValue
    }
    return update
  }

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }

  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          formDisabledState,
          canSave,
          handleActionEvents,
          validationProps
        }) => (
          <Form
            form={form}
            id='studentFormTames5'
            name='studentFormTames5'
            initialValues={data}
            preserve
            layout='vertical'
            className='page-padding-leftRight page-padding-topBottom form-labels-bold'
          >
            <Title level={4} className='center'>
              ELIGIBILITY DETERMINATION
            </Title>
            <Form.Item
              label='Form Created Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
              />
            </Form.Item>

            <Card
              title='Student SIS Data'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />
              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
              <StudentDobSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentDob}
                error={itemErrors.studentDob}
              />
            </Card>

            <Card
              title='Disability Areas and Details'
              style={{
                marginBottom: 30
              }}
            >
              <p>
                <strong>
                  Identify the adverse effects and educational needs for each
                  disability area listed below. Determine if student is eligible
                  for special education in the identified disability area(s).
                </strong>
                <br />
                <br />
                Describe how the disability affects educational performance in
                the <u>"Adverse Effects"</u> section
                <br />
                <br />
                Identify the student's educational needs that require special
                education services to address the adverse effects on educational
                performace in the <u>"Educational Needs"</u> section.
              </p>
              <Card
                title='Intellectual Disability (IntD)'
                style={{
                  marginBottom: 30
                }}
              >
                <Form.Item
                  label='Adverse Effects'
                  name='intdAdverseEffects'
                  {...validationProps('intdAdverseEffects')}
                >
                  <Editor
                    formField='intdAdverseEffects'
                    disabled={formDisabledState.intdAdverseEffects}
                    {...handleEditorEvents}
                  />
                </Form.Item>
                <Form.Item
                  label='Educational Needs'
                  name='intdEducationalNeeds'
                  {...validationProps('intdEducationalNeeds')}
                >
                  <Editor
                    formField='intdEducationalNeeds'
                    disabled={formDisabledState.intdEducationalNeeds}
                    {...handleEditorEvents}
                  />
                </Form.Item>
                <Form.Item
                  label='Eligible For Special Education Services'
                  name='intdEligible'
                  {...validationProps('intdEligible')}
                >
                  <Radio.Group
                    {...handleMouseEvents}
                    buttonStyle='solid'
                    disabled={formDisabledState.intdEligible}
                    name='intdEligible'
                  >
                    <Radio value>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Card>

              <Card
                title='Orthopedic Impairment (PI)'
                style={{
                  marginBottom: 30
                }}
              >
                <Form.Item
                  label='Adverse Effects'
                  name='piAdverseEffects'
                  {...validationProps('piAdverseEffects')}
                >
                  <Editor
                    formField='piAdverseEffects'
                    disabled={formDisabledState.piAdverseEffects}
                    {...handleEditorEvents}
                  />
                </Form.Item>
                <Form.Item
                  label='Educational Needs'
                  name='piEducationalNeeds'
                  {...validationProps('piEducationalNeeds')}
                >
                  <Editor
                    formField='piEducationalNeeds'
                    disabled={formDisabledState.piEducationalNeeds}
                    {...handleEditorEvents}
                  />
                </Form.Item>
                <Form.Item
                  label='Eligible For Special Education Services'
                  name='piEligible'
                  {...validationProps('piEligible')}
                >
                  <Radio.Group
                    {...handleMouseEvents}
                    buttonStyle='solid'
                    disabled={formDisabledState.piEligible}
                    name='piEligible'
                  >
                    <Radio value>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Card>

              <Card
                title='Specific Learning Disability (SLD)'
                style={{
                  marginBottom: 30
                }}
              >
                <Form.Item
                  label='Adverse Effects'
                  name='sldAdverseEffects'
                  {...validationProps('sldAdverseEffects')}
                >
                  <Editor
                    formField='sldAdverseEffects'
                    disabled={formDisabledState.sldAdverseEffects}
                    {...handleEditorEvents}
                  />
                </Form.Item>
                <Form.Item
                  label='Educational Needs'
                  name='sldEducationalNeeds'
                  {...validationProps('sldEducationalNeeds')}
                >
                  <Editor
                    formField='sldEducationalNeeds'
                    disabled={formDisabledState.sldEducationalNeeds}
                    {...handleEditorEvents}
                  />
                </Form.Item>
                <Form.Item
                  label='Eligible For Special Education Services'
                  name='sldEligible'
                  {...validationProps('sldEligible')}
                >
                  <Radio.Group
                    {...handleMouseEvents}
                    buttonStyle='solid'
                    disabled={formDisabledState.sldEligible}
                    name='sldEligible'
                  >
                    <Radio value>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Card>

              <Card
                title='Visual Impairment (VI)'
                style={{
                  marginBottom: 30
                }}
              >
                <Form.Item
                  label='Adverse Effects'
                  name='viAdverseEffects'
                  {...validationProps('viAdverseEffects')}
                >
                  <Editor
                    formField='viAdverseEffects'
                    disabled={formDisabledState.viAdverseEffects}
                    {...handleEditorEvents}
                  />
                </Form.Item>
                <Form.Item
                  label='Educational Needs'
                  name='viEducationalNeeds'
                  {...validationProps('viEducationalNeeds')}
                >
                  <Editor
                    formField='viEducationalNeeds'
                    disabled={formDisabledState.viEducationalNeeds}
                    {...handleEditorEvents}
                  />
                </Form.Item>
                <Form.Item
                  label='Eligible For Special Education Services'
                  name='viEligible'
                  {...validationProps('viEligible')}
                >
                  <Radio.Group
                    {...handleMouseEvents}
                    buttonStyle='solid'
                    disabled={formDisabledState.viEligible}
                    name='viEligible'
                  >
                    <Radio value>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Card>

              <Card
                title='Hearing Impairment (HI)'
                style={{
                  marginBottom: 30
                }}
              >
                <Form.Item
                  label='Adverse Effects'
                  name='hiAdverseEffects'
                  {...validationProps('hiAdverseEffects')}
                >
                  <Editor
                    formField='hiAdverseEffects'
                    disabled={formDisabledState.hiAdverseEffects}
                    {...handleEditorEvents}
                  />
                </Form.Item>
                <Form.Item
                  label='Educational Needs'
                  name='hiEducationalNeeds'
                  {...validationProps('hiEducationalNeeds')}
                >
                  <Editor
                    formField='hiEducationalNeeds'
                    disabled={formDisabledState.hiEducationalNeeds}
                    {...handleEditorEvents}
                  />
                </Form.Item>
                <Form.Item
                  label='Eligible For Special Education Services'
                  name='hiEligible'
                  {...validationProps('hiEligible')}
                >
                  <Radio.Group
                    {...handleMouseEvents}
                    buttonStyle='solid'
                    disabled={formDisabledState.hiEligible}
                    name='hiEligible'
                  >
                    <Radio value>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Card>

              <Card
                title='Deafness (D))'
                style={{
                  marginBottom: 30
                }}
              >
                <Form.Item
                  label='Adverse Effects'
                  name='dAdverseEffects'
                  {...validationProps('dAdverseEffects')}
                >
                  <Editor
                    formField='dAdverseEffects'
                    disabled={formDisabledState.dAdverseEffects}
                    {...handleEditorEvents}
                  />
                </Form.Item>
                <Form.Item
                  label='Educational Needs'
                  name='dEducationalNeeds'
                  {...validationProps('dEducationalNeeds')}
                >
                  <Editor
                    formField='dEducationalNeeds'
                    disabled={formDisabledState.dEducationalNeeds}
                    {...handleEditorEvents}
                  />
                </Form.Item>
                <Form.Item
                  label='Eligible For Special Education Services'
                  name='dEligible'
                  {...validationProps('dEligible')}
                >
                  <Radio.Group
                    {...handleMouseEvents}
                    buttonStyle='solid'
                    disabled={formDisabledState.dEligible}
                    name='dEligible'
                  >
                    <Radio value>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Card>

              <Card
                title='Deaf-Blindness (D-B)'
                style={{
                  marginBottom: 30
                }}
              >
                <Form.Item
                  label='Adverse Effects'
                  name='dbAdverseEffects'
                  {...validationProps('dbAdverseEffects')}
                >
                  <Editor
                    formField='dbAdverseEffects'
                    disabled={formDisabledState.dbAdverseEffects}
                    {...handleEditorEvents}
                  />
                </Form.Item>
                <Form.Item
                  label='Educational Needs'
                  name='dbEducationalNeeds'
                  {...validationProps('dbEducationalNeeds')}
                >
                  <Editor
                    formField='dbEducationalNeeds'
                    disabled={formDisabledState.dbEducationalNeeds}
                    {...handleEditorEvents}
                  />
                </Form.Item>
                <Form.Item
                  label='Eligible For Special Education Services'
                  name='dbEligible'
                  {...validationProps('dbEligible')}
                >
                  <Radio.Group
                    {...handleMouseEvents}
                    buttonStyle='solid'
                    disabled={formDisabledState.dbEligible}
                    name='dbEligible'
                  >
                    <Radio value>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Card>

              <Card
                title='Speech or Language Impairment (OHI)'
                style={{
                  marginBottom: 30
                }}
              >
                <Form.Item
                  label='Adverse Effects'
                  name='ohiAdverseEffects'
                  {...validationProps('ohiAdverseEffects')}
                >
                  <Editor
                    formField='ohiAdverseEffects'
                    disabled={formDisabledState.ohiAdverseEffects}
                    {...handleEditorEvents}
                  />
                </Form.Item>
                <Form.Item
                  label='Educational Needs'
                  name='ohiEducationalNeeds'
                  {...validationProps('ohiEducationalNeeds')}
                >
                  <Editor
                    formField='ohiEducationalNeeds'
                    disabled={formDisabledState.ohiEducationalNeeds}
                    {...handleEditorEvents}
                  />
                </Form.Item>
                <Form.Item
                  label='Eligible For Special Education Services'
                  name='ohiEligible'
                  {...validationProps('ohiEligible')}
                >
                  <Radio.Group
                    {...handleMouseEvents}
                    buttonStyle='solid'
                    disabled={formDisabledState.ohiEligible}
                    name='ohiEligible'
                  >
                    <Radio value>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Card>

              <Card
                title='Emotional Disability (ED)'
                style={{
                  marginBottom: 30
                }}
              >
                <Form.Item
                  label='Adverse Effects'
                  name='edAdverseEffects'
                  {...validationProps('edAdverseEffects')}
                >
                  <Editor
                    formField='edAdverseEffects'
                    disabled={formDisabledState.edAdverseEffects}
                    {...handleEditorEvents}
                  />
                </Form.Item>
                <Form.Item
                  label='Educational Needs'
                  name='edEducationalNeeds'
                  {...validationProps('edEducationalNeeds')}
                >
                  <Editor
                    formField='edEducationalNeeds'
                    disabled={formDisabledState.edEducationalNeeds}
                    {...handleEditorEvents}
                  />
                </Form.Item>
                <Form.Item
                  label='Eligible For Special Education Services'
                  name='edEligible'
                  {...validationProps('edEligible')}
                >
                  <Radio.Group
                    {...handleMouseEvents}
                    buttonStyle='solid'
                    disabled={formDisabledState.edEligible}
                    name='edEligible'
                  >
                    <Radio value>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Card>
              <Card
                title='Other Health Impairment (OHI)'
                style={{
                  marginBottom: 30
                }}
              >
                <Form.Item
                  label='Adverse Effects'
                  name='ohAdverseEffects'
                  {...validationProps('ohAdverseEffects')}
                >
                  <Editor
                    formField='ohAdverseEffects'
                    disabled={formDisabledState.ohAdverseEffects}
                    {...handleEditorEvents}
                  />
                </Form.Item>
                <Form.Item
                  label='Educational Needs'
                  name='ohEducationalNeeds'
                  {...validationProps('ohEducationalNeeds')}
                >
                  <Editor
                    formField='ohEducationalNeeds'
                    disabled={formDisabledState.ohEducationalNeeds}
                    {...handleEditorEvents}
                  />
                </Form.Item>
                <Form.Item
                  label='Eligible For Special Education Services'
                  name='ohEligible'
                  {...validationProps('ohEligible')}
                >
                  <Radio.Group
                    {...handleMouseEvents}
                    buttonStyle='solid'
                    disabled={formDisabledState.ohEligible}
                    name='ohEligible'
                  >
                    <Radio value>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Card>
              <Card
                title='Multiple Disability (MD)'
                style={{
                  marginBottom: 30
                }}
              >
                <Form.Item
                  label='Adverse Effects'
                  name='mdAdverseEffects'
                  {...validationProps('mdAdverseEffects')}
                >
                  <Editor
                    formField='mdAdverseEffects'
                    disabled={formDisabledState.mdAdverseEffects}
                    {...handleEditorEvents}
                  />
                </Form.Item>
                <Form.Item
                  label='Educational Needs'
                  name='mdEducationalNeeds'
                  {...validationProps('mdEducationalNeeds')}
                >
                  <Editor
                    formField='mdEducationalNeeds'
                    disabled={formDisabledState.mdEducationalNeeds}
                    {...handleEditorEvents}
                  />
                </Form.Item>
                <Form.Item
                  label='Eligible For Special Education Services'
                  name='mdEligible'
                  {...validationProps('mdEligible')}
                >
                  <Radio.Group
                    {...handleMouseEvents}
                    buttonStyle='solid'
                    disabled={formDisabledState.mdEligible}
                    name='mdEligible'
                  >
                    <Radio value>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Card>

              <Card
                title='Autism (AUT)'
                style={{
                  marginBottom: 30
                }}
              >
                <Form.Item
                  label='Adverse Effects'
                  name='autAdverseEffects'
                  {...validationProps('autAdverseEffects')}
                >
                  <Editor
                    formField='autAdverseEffects'
                    disabled={formDisabledState.autAdverseEffects}
                    {...handleEditorEvents}
                  />
                </Form.Item>
                <Form.Item
                  label='Educational Needs'
                  name='autEducationalNeeds'
                  {...validationProps('autEducationalNeeds')}
                >
                  <Editor
                    formField='autEducationalNeeds'
                    disabled={formDisabledState.autEducationalNeeds}
                    {...handleEditorEvents}
                  />
                </Form.Item>
                <Form.Item
                  label='Eligible For Special Education Services'
                  name='autEligible'
                  {...validationProps('autEligible')}
                >
                  <Radio.Group
                    {...handleMouseEvents}
                    buttonStyle='solid'
                    disabled={formDisabledState.autEligible}
                    name='autEligible'
                  >
                    <Radio value>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Card>

              <Card
                title='Traumatic Brain Injury (TBI)'
                style={{
                  marginBottom: 30
                }}
              >
                <Form.Item
                  label='Adverse Effects'
                  name='tbiAdverseEffects'
                  {...validationProps('tbiAdverseEffects')}
                >
                  <Editor
                    formField='tbiAdverseEffects'
                    disabled={formDisabledState.tbiAdverseEffects}
                    {...handleEditorEvents}
                  />
                </Form.Item>
                <Form.Item
                  label='Educational Needs'
                  name='tbiEducationalNeeds'
                  {...validationProps('tbiEducationalNeeds')}
                >
                  <Editor
                    formField='tbiEducationalNeeds'
                    disabled={formDisabledState.tbiEducationalNeeds}
                    {...handleEditorEvents}
                  />
                </Form.Item>
                <Form.Item
                  label='Eligible For Special Education Services'
                  name='tbiEligible'
                  {...validationProps('tbiEligible')}
                >
                  <Radio.Group
                    {...handleMouseEvents}
                    buttonStyle='solid'
                    disabled={formDisabledState.tbiEligible}
                    name='tbiEligible'
                  >
                    <Radio value>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Card>
            </Card>

            <Card
              style={{
                marginBottom: 30
              }}
            >
              <Form.Item
                name='conferenceNotes'
                label='Conference Notes:'
                {...validationProps('conferenceNotes')}
              >
                <Editor
                  formField='conferenceNotes'
                  disabled={formDisabledState.conferenceNotes}
                  {...handleEditorEvents}
                />
              </Form.Item>
            </Card>

            {formError && <Paragraph type='danger'>{formError}</Paragraph>}
            <StudentFormActions
              query={PRINT_FORMTAMES5}
              extractDataProp='printStudentFormTames5'
              canSave={canSave}
              handleActionEvents={handleActionEvents}

              getFormFieldValues={getFormFieldValues}
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired
}

const StudentFormTames5 = () => {
  // get current form information
  const { currentStudentInfo, currentEditableForm, studentMeetingId } =
    useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId } = currentEditableForm

  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    QUERY_STUDENT_COMMON_SIS,
    { variables: { id: studentId } }
  )
  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORMTAMES5_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )

  const createInitialFormData = (studentInfo, versionInfo) => {
    let initialValues = {}

    if (!studentInfo || !versionInfo) return initialValues

    const { studentFormTames5: form } = versionInfo
    const { student } = studentInfo
    const { dateOfBirth } = student
    const { formDate, studentFullName, studentDob } = form

    initialValues = {
      ...form,
      formDate: formDate ? moment(formDate) : moment(Date.now()),
      studentFullName: studentFullName || student.fullName || '',
      studentDob: studentDob
        ? moment(studentDob).format('YYYY-MM-DD')
        : dateOfBirth
        ? moment(dateOfBirth).format('YYYY-MM-DD')
        : moment(Date.now()).format('YYYY-MM-DD')
    }

    return initialValues
  }
  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
          />
        }
      />
    </FormErrorProvider>
  )
}
export default StudentFormTames5
