import React, { useState } from "react";
import PropTypes from "prop-types";
import { message } from "antd";

// Create Auth context
const FormErrorContext = React.createContext();

if (!typeof formError === "undefined") {
  console.log("errorProvider");
  message.error(
    "Error(s) need to be corrected before the student can be updated."
  );
}

const FormErrorProvider = (props) => {
  const [formError, setFormError] = useState();
  const [itemErrors, setItemErrors] = useState({});

  // form error handling
  const setErrors = (errors) =>
    errors.itemErrors
      ? setItemErrors(errors.itemErrors)
      : setFormError(errors.formError);
  const clearErrors = () => {
    setFormError();
    setItemErrors({});
  };

  return (
    <FormErrorContext.Provider
      value={{
        setErrors,
        clearErrors,
        itemErrors,
        formError,
      }}
    >
      {props.children}
    </FormErrorContext.Provider>
  );
};

FormErrorProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
export default FormErrorProvider;

export { FormErrorContext, FormErrorProvider };
