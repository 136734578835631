export const FormCodes = {
  FORM_SIS: 'MEETING_SIS',
  FORM_3457A: '3457A',
  FORM_3457B: '3457B',
  FORM_3457C: '3457C',
  FORM_3457BC: '3457BC',
  FORM_3457D: '3457D',
  FORM_3457E: '3457E',
  FORM_3457F: '3457F',
  FORM_3457G: '3457G',
  FORM_3457H: '3457H',
  FORM_3457I: '3457I',
  FORM_3457J: '3457J',
  FORM_3457K: '3457K',
  FORM_3457L: '3457L',
  FORM_3457M: '3457M',
  FORM_3457N: '3457N',
  FORM_3457O: '3457O',
  FORM_3454: '3454',
  FORM_3454A: '3454A',
  FORM_3454B: '3454B',
  FORM_3454C: '3454C',
  FORM_3454D: '3454D',
  FORM_3454E: '3454E',
  FORM_3454F: '3454F',
  FORM_3454G: '3454G',
  FORM_3454H: '3454H',
  FORM_3454I: '3454I',
  FORM_3454J: '3454J',
  FORM_3454K: '3454K',
  FORM_3454L: '3454L',
  FORM_3454M: '3454M',
  FORM_3454N: '3454N',
  FORM_3454O: '3454O',
  FORM_3454P: '3454P',
  FORM_3454Q: '3454Q',
  FORM_3454R: '3454R',
  FORM_3454S: '3454S',
  FORM_3454T: '3454T',
  FORM_3454U: '3454U',
  FORM_3454V: '3454V',
  FORM_TAMES4: 'Tames4',
  FORM_TAMES5: 'Tames5',
  FORM_TAMES6: 'Tames6',
  FORM_TAMES1: 'Tames1',
  FORM_TAMES2: 'Tames2',
  FORM_TAMES3: 'Tames3',
  FORM_TAMES7: 'Tames7',
  FORM_504A: '504A',
  FORM_504B: '504B',
  FORM_504C: '504C',
  FORM_504D: '504D',
  IAP: 'IAP',
  FORM_HEALTHPLAN: 'HEALTHPLAN'
}
