import React, { useContext } from 'react'
import PropTypes from 'prop-types'

// apollo & operations
import { useQuery } from '@apollo/client'
import { QUERY_SUBROLES } from '../../../operations/queries/subroles'

// Contexts
import AuthContext from '../../../utils/authProvider'

// ant design
import { Form, Select } from 'antd'

const FormItemSelectRole = ({
  label,
  name,
  validateStatus,
  help,
  hasFeedback,
  mode
}) => {
  const Auth = useContext(AuthContext)
  const isSuperAdmin = Auth.hasRole('SUPER_ADMIN')
  const { data, loading } = useQuery(QUERY_SUBROLES)

  return (
    <Form.Item
      label={label}
      name={name}
      validateStatus={validateStatus}
      help={help}
      hasFeedback={hasFeedback}
    >
      <Select
        defaultValue=''
        mode={mode}
        loading={loading}
        optionFilterProp='children'
        filterOption={(input, option) =>
          option?.children
            ?.toString()
            .toLowerCase()
            .indexOf(input.toString().toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) =>
          optionA?.children
            ?.toString()
            .toLowerCase()
            .localeCompare(optionB?.children?.toString().toLowerCase())
        }
      >
        <Select.Option key={Math.random()} value=''></Select.Option>
        {data?.subRoles &&
          data.subRoles.map(subrole => (
            <Select.Option key={subrole.id} value={subrole.id}>
              {isSuperAdmin ? subrole.enumName : subrole.nameGeneral}
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  )
}

FormItemSelectRole.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  help: PropTypes.string,
  validateStatus: PropTypes.string,
  hasFeedback: PropTypes.bool,
  mode: PropTypes.string
}

export default FormItemSelectRole
