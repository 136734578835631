import React, { Fragment } from "react";
import PropTypes from "prop-types";

// ant design
import { Form, Radio } from "antd";

import Editor from "../../../../elements/common/Editor";
import LookupSelect from "../../LookupSelect";

const InfoArea3457BC = ({
  name,
  handleFocusEvents,
  handleMouseEvents,
  handleEditorEvents,
  formDisabledState,
  validationProps,
}) => {
  return (
    <>
      <Form.Item
        label="Relevant"
        name={`${name}Relevance`}
        {...validationProps(`${name}Relevance`)}
      >
        <Radio.Group
          buttonStyle="solid"
          name={`${name}Relevance`}
          {...handleMouseEvents}
          disabled={formDisabledState[`${name}Relevance`]}
        >
          <Radio.Button value>Yes</Radio.Button>
          <Radio.Button value={false}>No</Radio.Button>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label="Existing information about the child"
        name={`${name}Existing`}
        {...validationProps(`${name}Existing`)}
      >
        <Editor
          formField={`${name}Existing`}
          disabled={formDisabledState[`${name}Existing`]}
          {...handleEditorEvents}
        />
      </Form.Item>

      <Form.Item
        label="Additional evaluation data needed"
        name={`${name}Additional`}
        {...validationProps(`${name}Additional`)}
      >
        <Editor
          formField={`${name}Additional`}
          disabled={formDisabledState[`${name}Additional`]}
          {...handleEditorEvents}
        />
      </Form.Item>

      <LookupSelect
        label="Sources from which data will be obtained"
        name={`${name}Sources`}
        type="Data Sources"
        placeholder="Please select"
        validation={validationProps(`${name}Sources`)}
        disabled={formDisabledState[`${name}Sources`]}

        onFocus={(event) => {
          const e = {
            target: {
              id: event.target.id,
              name: event.target.id.substring(event.target.id.indexOf('_') + 1),  //Is there an easier way to get the element's name?
            },
          };
          handleFocusEvents.onFocus(e);
        }}
        onBlur={(event, checkedValues) => {
          const e = {
            target: {
              name: event.target.id.substring(event.target.id.indexOf('_') + 1),
            },
          };
          handleMouseEvents.onChange(e);
        }}
      />
    </>
  );
};

InfoArea3457BC.propTypes = {
  name: PropTypes.string.isRequired,
  validationProps: PropTypes.func.isRequired,
  handleMouseEvents: PropTypes.func.isRequired,
  handleEditorEvents: PropTypes.func.isRequired,
  formDisabledState: PropTypes.object.isRequired,
};

export default InfoArea3457BC;
