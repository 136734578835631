// react
import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, Link, useParams } from 'react-router-dom'
import { useThemeSwitcher } from 'react-css-theme-switcher'
import { useMediaQuery } from 'react-responsive'

// ant design
import {
  Avatar,
  Button,
  Space,
  Tooltip,
  Layout,
  Menu,
  Dropdown,
  Row,
  Col,
  Switch
} from 'antd'
import {
  UserOutlined,
  DownOutlined,
  ClockCircleOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  BugOutlined,
  FilePdfOutlined,
  LogoutOutlined,
  LeftOutlined
} from '@ant-design/icons'

// Contexts
import AppContext from '../../utils/appProvider'
import AuthContext from '../../utils/authProvider'
import { QUERY_ME } from '../../operations/queries/userInfo'
import { useQuery } from '@apollo/client'

// components
import IssueModal from '../IssueModal'
import SideMenu from './SideMenu'

// images
import TamesLogo from '../../assets/images/tames_assist_logo.png'

const { SubMenu } = Menu

const Header = props => {
  const App = useContext(AppContext)
  const Auth = useContext(AuthContext)
  const history = useNavigate()
  const { districtsAdmin, schoolsAdmin, schoolsGeneral } = Auth
  const [issueModal, setIssueModal] = useState(false)
  const expirySeconds = Auth.useSessionExpiry()
  const isSuperAdmin = Auth.hasRole('SUPER_ADMIN')
  const isSchoolsGeneral = Auth.hasRole('SCHOOLS_GENERAL')
  const isSchoolsAdmin = Auth.hasRole('SCHOOLS_ADMIN')
  const isDistrictsAdmin = Auth.hasRole('DISTRICTS_ADMIN')
  const { data } = useQuery(QUERY_ME)
  const desktopData = Auth.desktopData
  if (desktopData === null) {
    Auth.fetchDesktopData()
  }
  const getUser = () => {
    //bad hack
    history('/')
    history(`./user/${data.me.id}`)
  }
  const open = (type, id) => {
    // landing page for superadmin
    if (type === 'superadmin') {
      App.openSuperAdmin()
      history('/')
    } else if (type === 'district_admin') {
      App.openDistrictAdmin(id)
      history('/')
    } else if (type === 'school_admin') {
      App.openSchoolAdmin(id)
      history('/')
    } else if (type === 'school_general') {
      App.openSchoolGeneral(id)
      history('/')
    }
  }

  const submitLogout = async e => {
    try {
      await Auth.signOut()
      history('/signin')
    } catch (error) {
      console.log(error, 'error in signout')
    }
  }
  const desktopStudents = (
    <Menu>
      {desktopData?.map(x => (
        <Menu.Item
          key={x.id}
          onClick={() => {
            history(`/student/${x.id}`)
          }}
        >
          {x.fullName}
        </Menu.Item>
      ))}
    </Menu>
  )
  const canEdit = Auth.hasPermission('EDIT_USERS')
  const myAccount = (
    <Menu>
      {canEdit && <Menu.Item onClick={() => getUser()}>Settings</Menu.Item>}
      <Menu.Divider />

      {schoolsAdmin.length + districtsAdmin.length + schoolsGeneral.length >=
        1 && (
          <>
            {isDistrictsAdmin && districtsAdmin.length && (
              <SubMenu title='District admin'>
                {districtsAdmin.map(obj => (
                  <Menu.Item
                    key={obj.id}
                    onClick={() => open('district_admin', obj.id)}
                  >
                    {obj.name}
                  </Menu.Item>
                ))}
              </SubMenu>
            )}
            {isSchoolsAdmin && schoolsAdmin.length && (
              <SubMenu title='School admin'>
                {schoolsAdmin.map(obj => (
                  <Menu.Item
                    key={obj.id}
                    onClick={() => open('school_admin', obj.id)}
                  >
                    {obj.name}
                  </Menu.Item>
                ))}
              </SubMenu>
            )}
            {isSchoolsGeneral && schoolsGeneral.length && (
              <SubMenu title='School general'>
                {schoolsGeneral.map(obj => (
                  <Menu.Item
                    key={obj.id}
                    onClick={() => open('school_general', obj.id)}
                  >
                    {obj.name}
                  </Menu.Item>
                ))}
              </SubMenu>
            )}
            <Menu.Divider />
          </>
        )}
      {isSuperAdmin && (
        <>
          <Menu.Item>
            <Link to='/districts' onClick={() => App.openSuperAdmin()}>
              Super admin
            </Link>
          </Menu.Item>
          <Menu.Divider />
        </>
      )}
      <Menu.Item>
        <Link to='/forgotpassword'>Reset password </Link>
      </Menu.Item>
      <Menu.Item key='3'>
        <Link onClick={() => submitLogout()}>
          <LogoutOutlined /> Sign out
        </Link>
      </Menu.Item>
    </Menu>
  )
  const [isDarkMode, setIsDarkMode] = React.useState()
  const { switcher, currentTheme, status, themes } = useThemeSwitcher()

  const toggleTheme = isChecked => {
    setIsDarkMode(isChecked)
    switcher({ theme: isChecked ? themes.dark : themes.light })
  }
  //https://github.com/yocontra/react-responsive
  //media queries
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  // const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  // const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  // const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  // const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

  // Avoid theme change flicker
  if (status === 'loading') {
    return null
  }
  return (
    <>
      {desktopData != null && (
        <Layout.Header
          style={
            isDarkMode
              ? { backgroundColor: '#424242' }
              : { backgroundColor: 'white' }
          }
        >
          <Row>
            {isDesktopOrLaptop ? (
              <>
                <Col span={3}>
                  {isSuperAdmin ? (
                    <>
                      <div
                        style={{
                          paddingTop: '8px',
                          paddingBottom: '8px',
                          transform: 'translateX(-20%)'
                        }}
                      >
                        <img
                          src={TamesLogo}
                          width='70%'
                          height='100%'
                          alt='Tames'
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          paddingTop: '8px',
                          paddingBottom: '8px'
                        }}
                      >
                        <img
                          src={TamesLogo}
                          width='70%'
                          height='100%'
                          alt='Tames'
                        />
                      </div>
                    </>
                  )}
                </Col>
              </>
            ) : (
              <>
                <Col span={3}>
                  <div className='header-logo'>
                    <img
                      src={TamesLogo}
                      width='70%'
                      height='100%'
                      alt='Tames'
                    />
                  </div>
                  {/* {React.createElement(
                    props.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                    {
                      className: 'trigger',
                      onClick: props.toggle
                    }
                  )}
                  {isSchoolsGeneral &&
                    !&&
                    !isSchoolsAdmin && (isSuperAdmin &&
                    !isDistrictsAdmin 
                      <div className='header-logo'>
                        <img
                          src={TamesLogo}
                          width='auto'
                          height='100%'
                          alt='Tames'
                        />
                      </div>
                    )} */}
                  <div>
                    <Tooltip title='Logout' />
                  </div>
                </Col>
              </>
            )}

            <Col
              span={21}
              className='header-col'
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Space size='small'>
                {desktopStudents.props.children.length > 0 ? <>
                  <Dropdown overlay={desktopStudents} trigger={['click']}>

                    <Button
                      type='link'
                      onClick={e => e.preventDefault()}
                      style={{ padding: 0, color: 'inherit' }}
                    >
                      Go to student <DownOutlined />
                    </Button>
                  </Dropdown>
                </>
                  :
                  <>
                  </>}

                <div>
                  <Tooltip title='Report issue'>
                    <Button
                      type='link'
                      onClick={() => setIssueModal(true)}
                      icon={<BugOutlined />}
                    />
                  </Tooltip>
                </div>
                <div className='main fade-in'>
                  <Tooltip title='Site Theme'>
                    <Switch
                      checked={isDarkMode}
                      checkedChildren='🌜'
                      unCheckedChildren='🌞'
                      onChange={toggleTheme}
                    />
                  </Tooltip>
                </div>
                {/* <div>
                  <Tooltip title='Training materials'>
                    <Link>
                      <Button type='link' icon={<FilePdfOutlined />} />
                    </Link>
                  </Tooltip>
                </div> */}
                <div>
                  <Dropdown overlay={myAccount} trigger={['click']}>
                    <Button
                      type='link'
                      onClick={e => e.preventDefault()}
                      style={{ padding: 0, color: 'inherit' }}
                    >
                      <Avatar
                        size='small'
                        icon={<UserOutlined />}
                        style={{ marginRight: 8 }}
                      />{' '}
                      My account <DownOutlined />
                    </Button>
                  </Dropdown>

                  <Tooltip title='Logout' />
                </div>
                {/* this is our session expiry, in authprovider we added a week because it is annoying for the user to get signed out every hour */}
                {/* <div>
                  <ClockCircleOutlined />
                </div>
                <div>{expirySeconds}</div> */}
              </Space>
              <Space size='small'>
                {' '}
                <Button onClick={() => history(-1)}>
                  <LeftOutlined /> Back
                </Button>
              </Space>
            </Col>
          </Row>
          {/* {!isSuperAdmin && !isDistrictsAdmin && !isSchoolsAdmin && (
            <Row>
              <SideMenu mode='horizontal' />
            </Row>
          )} */}
          <IssueModal visible={issueModal} setVisible={setIssueModal} />
        </Layout.Header>
      )}
    </>
  )
}

Header.propTypes = {
  toggle: PropTypes.func,
  collapsed: PropTypes.bool
}

export default Header

