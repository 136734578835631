import { gql } from "@apollo/client";

export const INSERT_ROLE = gql`
  mutation insertRole($name: String!) {
    insertRole(roleInput: { name: $name }) {
      id
      name
      hidden
    }
  }
`;

export const UPDATE_ROLE = gql`
  mutation updateRole($id: ID!, $userInput: RoleInput) {
    updateRole(id: $id, roleInput: $roleInput) {
      id
      name
      hidden
    }
  }
`;
