import { gql } from "@apollo/client";

export const EMAIL_REPORT = gql`
  mutation emailReport(
    $userId: ID!
    $to: [String!]
    $from: String
    $subject: String
    $text: String
    $attachments: [Object]
  ) {
    emailReport(
      userId: $userId
      to: $to
      from: $from
      subject: $subject
      text: $text
      attachments: $attachments
    )
  }
`;
