import { gql } from "@apollo/client";

export const PRINT_FORM3454D = gql`
  mutation printStudentForm3454D(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $lackAppropriateInstructionReading: Boolean
    $lackAppropriateInstructionReadingEvidence: String
    $lackAppropriateInstructionMath: Boolean
    $lackAppropriateInstructionMathEvidence: String
    $factorEnglishLearnerStatus: Boolean
    $factorEnglishLearnerStatusEvidence: String
    $factorVisualHearingMotor: Boolean
    $factorVisualHearingMotorEvidence: String
    $factorIntellectual: Boolean
    $factorIntellectualEvidence: String
    $factorEmotional: Boolean
    $factorEmotionalEvidence: String
    $factorCultural: Boolean
    $factorCulturalEvidence: String
    $factorEnvironmentalEconomic: Boolean
    $factorEnvironmentalEconomicEvidence: String
    $factorSlowerRate: String
    $factorSlowerRateAreas: String
    $factorBelowPerformance: String
    $factorBelowPerformanceAreas: String
    $formAction: String
    $formStatus: String
  ) {
    printStudentForm3454D(
      studentForm3454DInput: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        lackAppropriateInstructionReading: $lackAppropriateInstructionReading
        lackAppropriateInstructionReadingEvidence: $lackAppropriateInstructionReadingEvidence
        lackAppropriateInstructionMath: $lackAppropriateInstructionMath
        lackAppropriateInstructionMathEvidence: $lackAppropriateInstructionMathEvidence
        factorEnglishLearnerStatus: $factorEnglishLearnerStatus
        factorEnglishLearnerStatusEvidence: $factorEnglishLearnerStatusEvidence
        factorVisualHearingMotor: $factorVisualHearingMotor
        factorVisualHearingMotorEvidence: $factorVisualHearingMotorEvidence
        factorIntellectual: $factorIntellectual
        factorIntellectualEvidence: $factorIntellectualEvidence
        factorEmotional: $factorEmotional
        factorEmotionalEvidence: $factorEmotionalEvidence
        factorCultural: $factorCultural
        factorCulturalEvidence: $factorCulturalEvidence
        factorEnvironmentalEconomic: $factorEnvironmentalEconomic
        factorEnvironmentalEconomicEvidence: $factorEnvironmentalEconomicEvidence
        factorSlowerRate: $factorSlowerRate
        factorSlowerRateAreas: $factorSlowerRateAreas
        factorBelowPerformance: $factorBelowPerformance
        factorBelowPerformanceAreas: $factorBelowPerformanceAreas
        formAction: $formAction
        formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;
