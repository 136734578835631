// react
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// apollo & operations
import { useQuery, useMutation } from "@apollo/client";
import {
  QUERY_DISTRICT_IMPORT_MODELS,
  IMPORT_CSV,
} from "../../../operations/mutations/imports";

// ant design
import { Form, Input, Button, Space, Select, Typography } from "antd";

// elements
import FormItemSelectDistrict from "../../elements/formItems/FormItemSelectDistrict";
import FormItemError from "../../elements/formItems/FormItemError";

// other libraries
import formatErrors from "../../../utils/form";
import FormContainer from "../../../components/FormContainer";

/*
  LIMIT = 5mb
  file is 9.5mb / 100000 lines
  line = 110mb
  allowed lines 5000
  MAX_LENGTH = 5000 * 110
*/

const MAX_LENGTH = 5242880; // allowed lines is
const MAX_LENGTH_TEXT = `${MAX_LENGTH / 1024 / 1024}mb`;

const FormComponent = ({ data }) => {
  const { Option } = Select
  const [form] = Form.useForm();
  const [response, setResponse] = useState();
  const [lengthError, setLengthError] = useState();
  const [formError, setFormError] = useState();
  const [itemErrors, setItemErrors] = useState({});

  const [districtId, setDistrictId] = useState();
  const [models, setModels] = useState([]);

  const { data: districtData, loading: loadingDistrictData } = useQuery(
    QUERY_DISTRICT_IMPORT_MODELS,
    { variables: { id: districtId }, skip: !districtId }
  );
  console.log("after query", districtData)

  const [importCsv, { loading: importCsvLoading }] = useMutation(IMPORT_CSV);

  useEffect(() => {
    console.log("useEffect: ", districtData)
    districtData?.district &&
      setModels(districtData.district.districtImportModels.map((i) => i.model));
  }, [districtData]);

  const handleSelectedDistrictChange = (districtId) => {
    const values = form.getFieldsValue();
    console.log('handleSelectedDistrictChange: ', districtId, values)
    values.model = undefined;
    form.setFieldsValue(values);
    setModels([]);
    setDistrictId(districtId);
  };

  const onFinish = async (values) => {
    try {
      clearErrors();
      const response = await importCsv({
        variables: { importCsvInput: values },
      });
      setResponse(response.data.importCsv);
    } catch (e) {
      setErrors(formatErrors(e));
    }
  };

  const setErrors = (errors) =>
    errors.itemErrors
      ? setItemErrors(errors.itemErrors)
      : setFormError(errors.formError);

  const clearErrors = () => {
    setFormError();
    setItemErrors({});
  };

  const clearForm = () => {
    setResponse();
    form.resetFields();
    clearErrors();
  };
  console.log(models, 'what are models')
  return (
    <>
      {data && (
        <Form
          form={form}
          id="ImportForm"
          name="ImportForm"
          layout="vertical"
          onFinish={onFinish}
          preserve={false}
          initialValues={data.import}
        >
          <FormItemSelectDistrict
            label="District"
            name="districtId"
            validateStatus={itemErrors.districtId ? "error" : ""}
            help={itemErrors.districtId}
            onChange={handleSelectedDistrictChange}
          />

          <Form.Item label="Select Model" name="model" help={itemErrors.model}>
            <Select placeholder="Model" loading={loadingDistrictData}>
              {models.map((model) => (
                <Option key={model} value={model}>
                  {model}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={<div>Paste CSV ({MAX_LENGTH_TEXT} max)</div>}
            name="csv"
            extra={<div>Top line must container header field names.</div>}
            hasFeedback
            validateStatus={itemErrors.data ? "error" : ""}
            help={itemErrors.csv}
            disabled={!!response}
            onChange={(node) =>
              setLengthError(node.target.value.length > MAX_LENGTH)
            }
          >
            <Input.TextArea rows={20} />
          </Form.Item>

          <FormItemError error={formError} />

          <Form.Item>
            <Space>
              <Button
                type="primary"
                htmlType="submit"
                loading={importCsvLoading}
                disabled={!!response || lengthError}
              >
                Import
              </Button>
              <Button type="secondary" disabled={!response} onClick={clearForm}>
                Clear
              </Button>
            </Space>
          </Form.Item>
        </Form>
      )}
      {lengthError && (
        <Typography.Text type="danger">
          The CSV is too large, maximum {MAX_LENGTH_TEXT}.
        </Typography.Text>
      )}
      {response && (
        <>
          <Form.Item
            className="form-item-text"
            label="Records"
            wrapperCol={{ sm: 20 }}
          >
            <span className="ant-form-text">{response.records}</span>
          </Form.Item>
          <Form.Item
            className="form-item-text"
            label="Inserted"
            wrapperCol={{ sm: 20 }}
          >
            <span className="ant-form-text">{response.inserted}</span>
          </Form.Item>
          <Form.Item
            className="form-item-text"
            label="Updated"
            wrapperCol={{ sm: 20 }}
          >
            <span className="ant-form-text">{response.updated}</span>
          </Form.Item>
        </>
      )}
    </>
  );
};

FormComponent.propTypes = {
  data: PropTypes.object.isRequired,
};

const ImportForm = () => {
  const initialValues = { import: {} };

  return <FormContainer form={<FormComponent data={initialValues} />} />;
};

export default ImportForm;
