import { gql } from "@apollo/client";

export const GET_S3_SIGNED_URL = gql`
  query s3GetSignedURLRequest($fileName: String!, $contentType: String!) {
    s3GetSignedURLRequest(fileName: $fileName, contentType: $contentType) {
        success
        error
        fileName
        contentType
        signedURL
        url
    }
  }
`;
export const PUT_S3_SIGNED_URL = gql`
  query s3PutSignedURLRequest($fileName: String!, $contentType: String!) {
    s3PutSignedURLRequest(fileName: $fileName, contentType: $contentType) {
        success
        error
        fileName
        contentType
        uniqueName
        signedURL
        url
    }
  }
`;

export const DOES_S3_OBJECT_EXIST = gql`
  query s3ObjectExists($fileName: String!) {
    s3ObjectExists(fileName: $fileName) 
  }
`;
