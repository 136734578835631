import React, { useContext } from "react";

// contexts
import AppContext from "../../utils/appProvider";

// components
import RoleSelector from "../RoleSelector";

// layouts
import PublicLayout from "./PublicLayout";
import SchoolLayout from "./SchoolLayout";
import DistrictLayout from "./DistrictLayout";
import SuperAdminLayout from "./SuperAdminLayout";

const Layout = () => {
  const App = useContext(AppContext);
  const { role } = App.useCurrent();
  return (
    <>
      {role === "ROLE_SELECTOR" && <RoleSelector />}
      {role === "SUPER_ADMIN" && <SuperAdminLayout />}
      {role === "DISTRICTS_ADMIN" && <DistrictLayout />}
      {(role === "SCHOOLS_ADMIN" || role === "SCHOOLS_GENERAL") && (
        <SchoolLayout />
      )}
      {!role && <PublicLayout />}
    </>
  );
};

export default Layout;
