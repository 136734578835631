import React from 'react'
import PropTypes from 'prop-types'

// antd
import { Row, Col, Form, Button, DatePicker } from 'antd'

// components
import FormItemSelectDistrict from '../../elements/formItems/FormItemSelectDistrict'
import FormItemSelectSchool from '../../elements/formItems/FormItemSelectSchool'
import DateSelector from '../../elements/common/DateSelector'
const IStarReportSearchForm = ({ onSearch }) => {
  const [form] = Form.useForm()

  const onFinish = values => {
    if (!values.schools) values.schools = []
    onSearch(values)
  }

  const onFinishFailed = errorInfo => {
    console.error('Failed:', errorInfo)
  }

  return (
    <div>
      <Form
        form={form}
        layout='vertical'
        id='meetingsReportSearchFormSearchForm'
        name='basic'
        initialValues={{}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={32}>
          <Col xl={6} lg={8} md={9} sm={12} xs={24}>
            <FormItemSelectDistrict
              label='District'
              name='districtId'
              required
            />
          </Col>
          <Col xl={6} lg={8} md={9} sm={12} xs={24}>
            <FormItemSelectSchool
              label='School'
              name='schools'
              mode='multiple'
            />
          </Col>
        </Row>

        <Row gutter={32}>
          <Col xl={6} lg={8} md={9} sm={12} xs={24}>
            <Form.Item name='startDateRange' label='Start date'>
              <DatePicker
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
              />
            </Form.Item>
          </Col>
          <Col xl={6} lg={8} md={9} sm={12} xs={24}>
            <Form.Item name='endDateRange' label='End date'>
              <DatePicker
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Button type='primary' htmlType='submit'>
          Submit
        </Button>
        <Button
          style={{ margin: '0 8px' }}
          onClick={() => {
            form.resetFields()
            form.submit()
          }}
        >
          Reset
        </Button>
      </Form>
    </div>
  )
}

IStarReportSearchForm.propTypes = {
  onSearch: PropTypes.func.isRequired
}

export default IStarReportSearchForm
