import { gql } from "@apollo/client";

export const FORM_COLLABORATION_SUBSCRIPTION = gql`
  subscription formCollaboration($studentFormId: ID!) {
    formCollaboration(studentFormId: $studentFormId) {
      eventId
      eventDate
      fieldName
      fieldValue
      users {
        id
        emailObfuscated
      }
      onFocusFields {
        fieldName
        user {
          id
          emailObfuscated
        }
        focusAt
        focusAtAgo
      }
      updatedFields {
        fieldName
        fieldValue
        user {
          id
        }
      }
      currentUser {
        id
        emailObfuscated
      }
      canSave
    }
  }
`;
