// react
import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'

// Contexts
import AuthContext from '../../../utils/authProvider'

// ant design
import { Card, Button, Tag, Tooltip } from 'antd'

// operations
import { QUERY_SCHOOLS } from '../../../operations/queries/schools'

// components
import SchoolSearchForm from './SchoolSearchForm'
import Listing from '../../Listing'

const SchoolList = () => {
  const Auth = useContext(AuthContext)
  const isSchoolsGeneral = Auth.hasRole('SCHOOLS_GENERAL')
  const isSuperAdmin = Auth.hasRole('SUPER_ADMIN')
  const [criterias, setCriterias] = useState({})

  const getName = record =>
    isSchoolsGeneral ? record.name : record.nameGeneral
  const getDistrictName = record =>
    record.district?.name ? record.district.name : record.districtId
  const canAddSchools = Auth.hasPermission('ADD_SCHOOLS')
  const extra = (
    <>
      {canAddSchools && (
        <Tooltip title='Add new school'>
          <Link to='../school/add'>
            <Button type='primary'>Add School</Button>
          </Link>
        </Tooltip>
      )}
    </>
  )

  const onSearch = criterias => {
    setCriterias(criterias)
    // console.log('onSearch', criterias)
  }

  const canEditSchools = Auth.hasPermission('EDIT_SCHOOLS')
  const columns = [
    {
      title: 'School code',
      dataIndex: 'schoolCode',
      sorter: (a, b) => a.firstName.localeCompare(b.schoolCode),
      render: (value, record) => (
        <div>
          <div>
            {canEditSchools && record.isEditable ? (
              <Link to={`../school/${record.id}`}>{value}</Link>
            ) : (
              <>{value}</>
            )}
            &nbsp;
            {isSuperAdmin && record.hidden && <Tag color='orange'>Hidden</Tag>}
          </div>
          {isSuperAdmin && <div className='cellWithId'>{record.id}</div>}
          {!record.isEditable && <Tag color='geekblue'>Imported</Tag>}
        </div>
      )
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (value, record) => <>{getName(record)} </>
    },
    {
      title: 'District',
      dataIndex: 'districtName',
      sorter: (a, b) => getDistrictName(a).localeCompare(getDistrictName(b)),
      render: (value, record) => <>{getDistrictName(record)} </>
    }
  ]

  return (
    <>
      <Listing
        query={QUERY_SCHOOLS}
        columns={columns}
        dataKey='schools'
        criterias={criterias}
        render={table => {
          return (
            <>
              <div className='page-container'>
                <Card title='Search' bordered={false}>
                  <SchoolSearchForm onSearch={onSearch} />
                </Card>
              </div>
              <div className='page-container'>
                <Card title='Schools' bordered={false} extra={extra}>
                  {table}
                </Card>
              </div>
            </>
          )
        }}
      />
    </>
  )
}

export default SchoolList
