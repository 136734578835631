import { gql } from "@apollo/client";

export const QUERY_FORM504D_VERSION = gql`
  query studentForm504D(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
  ) {
    studentForm504D(
      studentFormId: $studentFormId
      studentId: $studentId
      formId: $formId
      schoolId: $schoolId
      studentMeetingId: $studentMeetingId
    ) {
      versionId
      formId
      formDate
      studentFullName
      studentDob
      parent1FullName,
      parent1Relationship,
      parent1PhoneNumber,
      parent2FullName,
      parent2Relationship,
      parent2PhoneNumber,
      parentReceived504EligibilitySummary
      parentReceived504Plan
      parentConsent
      parentSignatureName
      parentSignatureDate
      districtContactName
      districtContactTitle
      districtContactSignatureDate
      formAction
      formStatus
    }
  }
`;
