import React from "react";
import { useParams } from "react-router-dom";

import Page from "../../components/Page";
import GradeForm from "../../components/sections/grade/GradeForm";

const Grade = () => {
  const { id } = useParams();
  const title = id ? "Edit grade" : "Add grade";

  const breadcrumb = [
    {
      path: "/",
      breadcrumbName: "Home",
    },
    {
      path: "/grades",
      breadcrumbName: "Grades",
    },
    {
      path: "",
      breadcrumbName: title,
    },
  ];
  return (
    <>
      <Page title={title} breadcrumb={breadcrumb}>
        <div className="page-container">
          <div className="page-padding-leftRight page-padding-topBottom">
            <GradeForm />
          </div>
        </div>
      </Page>
    </>
  );
};

export default Grade;
