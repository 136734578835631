import { gql } from "@apollo/client";

export const DistrictFragments = {
  formFields: gql`
    fragment DistrictFormFields on District {
      id
      name
      phone
      fax
      website
      address
      city
      state
      country
      zip
      directorOfEducation
      chairOfBoard
      logo
      selectedPlans
    }
  `,
};
