import { gql } from "@apollo/client";

export const PRINT_FORM504D = gql`
  mutation printStudentForm504D(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $studentDob: String
    $parent1FullName: String
    $parent1Relationship: String
    $parent1PhoneNumber: String
    $parent2FullName: String
    $parent2Relationship: String
    $parent2PhoneNumber: String
    $parentReceived504EligibilitySummary: String
    $parentReceived504Plan: String
    $parentConsent: String
    $parentSignatureName: String
    $parentSignatureDate: String
    $districtContactName: String
    $districtContactTitle: String
    $districtContactSignatureDate: String
    $formAction: String
    $formStatus: String
  ) {
    printStudentForm504D(
      studentForm504DInput: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        studentDob: $studentDob
        parent1FullName: $parent1FullName
        parent1Relationship: $parent1Relationship
        parent1PhoneNumber: $parent1PhoneNumber
        parent2FullName: $parent2FullName
        parent2Relationship: $parent2Relationship
        parent2PhoneNumber: $parent2PhoneNumber
        parentReceived504EligibilitySummary: $parentReceived504EligibilitySummary
        parentReceived504Plan: $parentReceived504Plan
        parentConsent: $parentConsent
        parentSignatureName: $parentSignatureName
        parentSignatureDate: $parentSignatureDate
        districtContactName: $districtContactName
        districtContactTitle: $districtContactTitle
        districtContactSignatureDate: $districtContactSignatureDate
        formAction: $formAction
        formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;
