// react
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";

// apollo & operations
import { useQuery, useMutation } from "@apollo/client";

// ant design
import { Form, Input, Button, Typography, Select, Space, Switch } from "antd";

// elements
import FormItemSelectForm from "../../elements/formItems/FormItemSelectForm";

// other libraries
import formatErrors from "../../../utils/form";
import FormContainer from "../../../components/FormContainer";
import ModalDialog from "../../../components/ModalDialog";
import {
  QUERY_LOOKUP,
  QUERY_LOOKUP_TYPES,
} from "../../../operations/queries/lookup";
import FormItemsSuperAdmin from "../../elements/formItems/FormItemsSuperAdmin";
import AuthContext from "../../../utils/authProvider";
import {
  INSERT_LOOKUP,
  UPDATE_LOOKUP,
} from "../../../operations/mutations/lookup";
import notificationSuccess from "../../../utils/notification";

const { Paragraph } = Typography;
const { Option } = Select;
const FormRecord = ({ data }) => {
  const history = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [formError, setFormError] = useState();
  const [itemErrors, setItemErrors] = useState({});
  const Auth = useContext(AuthContext);
  const [updateLookup] = useMutation(UPDATE_LOOKUP);
  const [insertLookup] = useMutation(INSERT_LOOKUP);

  const setErrors = (errors) =>
    errors.itemErrors
      ? setItemErrors(errors.itemErrors)
      : setFormError(errors.formError);

  const clearErrors = () => {
    setFormError();
    setItemErrors({});
  };

  const continueEditing = (_id) => _id && history(`/lookup/${_id}`);

  const onFinish = async (values) => {
    const cancel = (mutated, isHidden) =>
      !isHidden
        ? {
            cancelText: "Continue editing",
            onCancel: () => continueEditing(mutated.data.insertLookup?.id),
          }
        : { cancelButtonProps: { style: { display: "none" } } };

    try {
      clearErrors();
      const mutationVariables = {
        ...values,
        districtId: Auth.currentDistrictId?.id,
      };
      const mutated = id
        ? await updateLookup({ variables: { id: id, ...mutationVariables } })
        : await insertLookup({ variables: mutationVariables });
      // ModalDialog.success({
      //   title: "Success",
      //   content: `The Lookup has been ${id ? "updated" : "added"}.`,
      //   okText: "Return to listing",
      //   onOk: () => history("/lookups"),
      //   ...cancel(mutated, values.isHidden),
      // })
      notificationSuccess('Lookup Updated');
    } catch (e) {
      setErrors(formatErrors(e));
    }
  };

  const [types, setTypes] = useState([]);
  const [typeName, setTypeName] = useState("");

  const { loading: typesLoading, data: typeData } =
    useQuery(QUERY_LOOKUP_TYPES);

  //Controls display of defaultFormCodes for "Meeting Type" lookup
  const handleMeetingTypeChange = (typeId) => {
    const lookupType = types.find((type) => type.id === typeId);
    const lookupTypeName = lookupType ? lookupType.name : "";
    setTypeName(lookupTypeName);
  };

  //Load the initial lookupType
  useEffect(() => {
    handleMeetingTypeChange(data?.lookup.typeId);
  }, []);

  useEffect(() => {
    if (typeData) {
      setTypes(typeData.lookupTypes);
    }
  }, [typeData]);

  return (
    <Form
      form={form}
      id="lookupForm"
      name="lookupForm"
      onFinish={onFinish}
      preserve={false}
      initialValues={data?.lookup}
      labelCol={{ sm: 4, md: 4, lg: 4, xl: 4, xxl: 4 }}
      wrapperCol={{ sm: 10, md: 7, lg: 18, xl: 18, xxl: 18 }}
    >
      <Form.Item
        className="form-item-text"
        label="District"
        wrapperCol={{ sm: 20 }}
      >
        <span className="ant-form-text">
          {data?.lookup?.district?.name ? data.lookup.district.name : "Global"}
        </span>
      </Form.Item>

      <Form.Item
        name="typeId"
        required
        label="Type"
        hasFeedback
        validateStatus={itemErrors.typeId ? "error" : ""}
        help={itemErrors.typeId}
      >
        <Select
          loading={typesLoading}
          defaultValue={data?.lookup.typeId}
          onChange={(typeId) => handleMeetingTypeChange(typeId)}
        >
          {types.map((type) => (
            <Option key={type.id} value={type.id}>
              {type.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="Value"
        name="value"
        required
        hasFeedback
        validateStatus={itemErrors.value ? "error" : ""}
        help={itemErrors.value}
      >
        <Input />
      </Form.Item>

      {typeName === "Meeting Type" ||
      (typeName === "" && data.lookup.type.name === "Meeting Type") ? (
        <FormItemSelectForm
          label="Default Forms:"
          name="defaultFormCodes"
          mode="multiple"
          validateStatus={itemErrors.defaultFormCodes ? "error" : ""}
          help={itemErrors.defaultFormCodes}
        />
      ) : (
        <></>
      )}

      {typeName === "Meeting Type" ||
      (typeName === "" && data.lookup.type.name === "Meeting Type") ? (
        <Form.Item
          className="form-item-text"
          label="Can be IEP"
          name="canBeIEP"
          valuePropName="checked"
          wrapperCol={{ sm: 20 }}
        >
          <Switch />
        </Form.Item>
      ) : (
        <></>
      )}

      {Auth.hasRole("SUPER_ADMIN") ? (
        <Form.Item
          className="form-item-text"
          label="Global"
          name="isGlobal"
          valuePropName="checked"
          wrapperCol={{ sm: 20 }}
        >
          <Switch disabled />
        </Form.Item>
      ) : (
        <></>
      )}

      <Form.Item
        name="hidden"
        style={{
          display: "none",
        }}
      >
        <Input type="hidden" />
      </Form.Item>

      <FormItemsSuperAdmin data={data.lookup} hasHidden />

      {formError && <Paragraph type="danger">{formError}</Paragraph>}

      <Space>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {id ? "Update" : "Add"}
          </Button>
        </Form.Item>

        {Auth.hasRole("DISTRICTS_ADMIN") && id ? (
          <Form.Item>
            <Button
              danger
              type="primary"
              onClick={() => {
                form.setFieldsValue({ hidden: true });
                form.submit();
              }}
            >
              Delete
            </Button>
          </Form.Item>
        ) : (
          <></>
        )}
      </Space>
    </Form>
  );
};

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
};

const LookupForm = () => {
  const { id } = useParams();
  const { data, loading } = useQuery(QUERY_LOOKUP, {
    variables: { id },
    skip: !id,
  });

  const initialValues = {
    lookup: {
      value: data?.value ? data.value : "",
      hidden: data?.hidden ? data.hidden : false,
      type: "",
      defaultFormCodes: data?.defaultFormCodes ? data.defaultFormCodes : [""],
      canBeIep: data?.canBeIep ? data.canBeIep : false,
    },
  };

  return (
    <FormContainer
      loading={loading}
      form={<FormRecord data={data || initialValues} />}
    />
  );
};

export default LookupForm;
