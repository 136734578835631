import { gql } from "@apollo/client";

export const PRINT_FORMTAMES7 = gql`
  mutation printStudentFormTames7(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $studentDob: String
    $copyIEP: Boolean
    $copyEligibilityDetermination: Boolean
    $copyInterventionPolicy: Boolean
    $copyInterventionProcedures: Boolean
    $planIndicator: Boolean
    $planIndicatorYes: String
    $formAction: String
$formStatus: String
  ) {
    printStudentFormTames7(
      studentFormTames7Input: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        studentDob: $studentDob
        copyIEP: $copyIEP
        copyEligibilityDetermination: $copyEligibilityDetermination
        copyInterventionPolicy: $copyInterventionPolicy
        copyInterventionProcedures: $copyInterventionProcedures
        planIndicator: $planIndicator
        planIndicatorYes: $planIndicatorYes
formAction: $formAction
formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;
