//--------
//Returns the start and end date for the requested time span.
//Dates are relative to today's date unless a specific date is passed.
//NOTES:
//  (1) dayjs days/months are zero based (e.g. January=0, June=5, etc.)
//  (2) School year start/end dates are set to August 15th and June 15th, respectively.  We can make more precise when we have school calendar functionality.
//  (3) Last/This/Next school year is based on the request date's month.  The inflection point is June 30/July 1.
//--------
import dayjs from "dayjs";
const weekday = require('dayjs/plugin/weekday')
const quarterOfYear = require('dayjs/plugin/quarterOfYear')
dayjs.extend(weekday)
dayjs.extend(quarterOfYear)

const getTimeSpanDates = (timeSpanParam = 'This Week', requestDateParam = dayjs()) => {
  //console.log('valid date?',dayjs(requestDateParam, 'YYYY-MM-DD', true).isValid(),requestDateParam);

  //Allow timeSpan param to be slightly misformed.
  const timeSpan = timeSpanParam.toLowerCase().replace(/\s/g,'');  //Make all lower case with no spaces

  //Funtion is designed to always return valid start/end/request dates.  Even if passed an invalid request date.
  //Do this or return an error?
  let requestDate = dayjs();  //Default to today's date so that valid dates are always returned.  If an invalid date is passed it will use this date.
  if (dayjs(requestDateParam, 'YYYY-MM-DD', true).isValid()) {  //Use passed date if valid
    requestDate = dayjs(requestDateParam);  //Running param through dayjs allows string or date type to be passed
  }
  let startDate = requestDate;
  let endDate = requestDate;

  switch (timeSpan) {
    case 'lastweek':
      startDate = dayjs(requestDate).weekday(0).subtract(7,'day');
      endDate = startDate.add(6, 'day')
      break;
    case 'thisweek':
      startDate = dayjs(requestDate).weekday(0);
      endDate = startDate.add(6, 'day')
      break;
    case 'nextweek':
      startDate = dayjs(requestDate).weekday(6).add(1,'day');
      endDate = startDate.add(6, 'day')
      break;
    case 'lastmonth':
      startDate = dayjs(requestDate).subtract(1, 'month').startOf('month');
      endDate = startDate.endOf('month')
      break;        
    case 'thismonth':
      startDate = dayjs(requestDate).startOf('month');
      endDate = startDate.endOf('month')
      break;
    case 'nextmonth':
      startDate = dayjs(requestDate).add(1, 'month').startOf('month');
      endDate = startDate.endOf('month')
      break;
    case 'lastquarter':
      startDate = dayjs(requestDate).subtract(1, 'quarter').startOf('quarter');
      endDate = startDate.endOf('quarter')
      break;  
    case 'thisquarter':
      startDate = dayjs(requestDate).startOf('quarter');
      endDate = startDate.endOf('quarter')
      break;
    case 'nextquarter':
      startDate = dayjs(requestDate).add(1, 'quarter').startOf('quarter');
      endDate = startDate.endOf('quarter')
      break;
    case 'lastyear':
      startDate = dayjs(requestDate).subtract(1, 'year').startOf('year');
      endDate = startDate.endOf('year')
      break;
    case 'thisyear':
      startDate = dayjs(requestDate).startOf('year');
      endDate = startDate.endOf('year')
      break;
    case 'nextyear':
      startDate = dayjs(requestDate).add(1, 'year').startOf('year');
      endDate = startDate.endOf('year')
      break;
    case 'lastschoolweek':
      startDate = dayjs(requestDate).weekday(1).subtract(7,'day');
      endDate = startDate.add(4, 'day')
      break;
    case 'thisschoolweek':
      startDate = dayjs(requestDate).weekday(1);
      endDate = startDate.add(4, 'day')
      break;
    case 'nextschoolweek':
      startDate = dayjs(requestDate).weekday(6).add(2,'day');
      endDate = startDate.add(4, 'day')
      break;
    case 'lastschoolyear':  //School year is set as August 15 - June 15
      if (dayjs(requestDate.month()) < 6) {  //Request date is in January-June
        startDate = dayjs().set('year', dayjs(requestDate).year()-2).set('month', 6).set('date', 1)
        endDate = dayjs().set('year', dayjs(requestDate).year()-1).set('month', 5).set('date', 30)
      }      
      if (dayjs(requestDate).month() >= 6) {  //Request date is in July-December
        startDate = dayjs().set('year', dayjs(requestDate).year()-1).set('month', 6).set('date', 1)
        endDate = dayjs().set('year', dayjs(requestDate).year()).set('month', 5).set('date', 30)
      }
      break;
    case 'thisschoolyear':  //School year is set as August 15 - June 15
      if (dayjs(requestDate.month()) < 6) { //Request date is in January-June
        startDate = dayjs().set('year', dayjs(requestDate).year()-1).set('month', 6).set('date', 1)
        endDate = dayjs().set('year', dayjs(requestDate).year()).set('month', 5).set('date', 30)
      }
      if (dayjs(requestDate).month() >= 6) {  //Request date is in July-December
        startDate = dayjs().set('year', dayjs(requestDate).year()).set('month', 6).set('date', 1)
        endDate = dayjs().set('year', dayjs(requestDate).year()+1).set('month', 5).set('date', 30)
      }
      break;
    case 'nextschoolyear':  //School year is set as August 15 - June 15
    if (dayjs(requestDate.month()) < 6) { //Request date is in January-June
      startDate = dayjs().set('year', dayjs(requestDate).year()).set('month', 6).set('date', 1)
      endDate = dayjs().set('year', dayjs(requestDate).year()+1).set('month', 5).set('date', 30)
    }
    if (dayjs(requestDate).month() >= 6) {  //Request date is in July-December
      startDate = dayjs().set('year', dayjs(requestDate).year()+1).set('month', 6).set('date', 1)
      endDate = dayjs().set('year', dayjs(requestDate).year()+2).set('month', 5).set('date', 30)
    }
    break;
    default:
      startDate = requestDate;
      endDate = requestDate;
      break;
  }
  return [startDate, endDate, requestDate]
}

export default getTimeSpanDates
