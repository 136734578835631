import { gql } from "@apollo/client";

export const PRINT_FORM3457D = gql`
  mutation printStudentForm3457D(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $studentDob: String
    $parent1: String
    $parent2: String
    $meetingDateAndTime: String
    $meetingLocation: String
    $tenDayWaiverAccepted: Boolean
    $tenDayWaiverInitials: String
    $tenDayWaiverAcceptedDate: String
    $bringOtherIndividualContactInfo: String
    $interpretationQuestionsContactInfo: String
    $requestInterpreterContactName: String
    $requestInterpreterContactTitle: String
    $requestInterpreterContactTelephone: String
    $meetingArrangementsContactName: String
    $meetingArrangementsContactTitle: String
    $meetingArrangementsContactTelephone: String
    $purposeReview: Boolean
    $purposeInitialEvaluation: Boolean
    $purposeReEvaluation: Boolean
    $purposeIndividualizedEducation: Boolean
    $purposeIEPReview: Boolean
    $purposeSecondaryTransition: Boolean
    $purposeFunctionalAssessment: Boolean
    $purposeManifestationReview: Boolean
    $purposeGraduation: Boolean
    $purposeOther: Boolean
    $generalEducationTeacherName: String
    $specialEducationTeacherName: String
    $representativeLEAName: String
    $otherNames: [String]
    $iepName: String
    $iepTitle: String
    $iepTelephone: String
    $signatureName: String
    $signatureTitle: String
    $formAction: String
$formStatus: String
  ) {
    printStudentForm3457D(
      studentForm3457DInput: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        studentDob: $studentDob
        parent1: $parent1
        parent2: $parent2
        meetingDateAndTime: $meetingDateAndTime
        meetingLocation: $meetingLocation
        tenDayWaiverAccepted: $tenDayWaiverAccepted
        tenDayWaiverInitials: $tenDayWaiverInitials
        tenDayWaiverAcceptedDate: $tenDayWaiverAcceptedDate
        bringOtherIndividualContactInfo: $bringOtherIndividualContactInfo
        interpretationQuestionsContactInfo: $interpretationQuestionsContactInfo
        requestInterpreterContactName: $requestInterpreterContactName
        requestInterpreterContactTitle: $requestInterpreterContactTitle
        requestInterpreterContactTelephone: $requestInterpreterContactTelephone
        meetingArrangementsContactName: $meetingArrangementsContactName
        meetingArrangementsContactTitle: $meetingArrangementsContactTitle
        meetingArrangementsContactTelephone: $meetingArrangementsContactTelephone
        purposeReview: $purposeReview
        purposeInitialEvaluation: $purposeInitialEvaluation
        purposeReEvaluation: $purposeReEvaluation
        purposeIndividualizedEducation: $purposeIndividualizedEducation
        purposeIEPReview: $purposeIEPReview
        purposeSecondaryTransition: $purposeSecondaryTransition
        purposeFunctionalAssessment: $purposeFunctionalAssessment
        purposeManifestationReview: $purposeManifestationReview
        purposeGraduation: $purposeGraduation
        purposeOther: $purposeOther
        generalEducationTeacherName: $generalEducationTeacherName
        specialEducationTeacherName: $specialEducationTeacherName
        representativeLEAName: $representativeLEAName
        otherNames: $otherNames
        iepName: $iepName
        iepTitle: $iepTitle
        iepTelephone: $iepTelephone
        signatureName: $signatureName
        signatureTitle: $signatureTitle
formAction: $formAction
formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;
