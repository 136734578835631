import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { IepFormContext } from "../../../utils/iepFormProvider";
import { useQuery } from "@apollo/client";
import { Form, Select, Input, Divider } from "antd";
import { QUERY_LOOKUPS } from "../../../operations/queries/lookup";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

const LookupSelect = ({
  type,
  label,
  name,
  validation,
  placeholder,
  singleMode,
  disabled,
  ...props
}) => {
  const { currentStudentInfo } = useContext(IepFormContext);
  const { districtId } = currentStudentInfo;
  const { loading: lookupLoading, data: lookupData } = useQuery(QUERY_LOOKUPS, {
    variables: { type, districtId },
  });
  const [lookups, setLookups] = useState([]);
  const [customLookup, setCustomLookup] = useState("");

  const onNameChange = (event) => {
    // Skips the | character if in the input
    if (event.target.value.indexOf("|") >= 0) {
      // Do nothing
    } else {
      setCustomLookup(event.target.value);
    }
  };

  const addItem = () => {
    if (customLookup !== "") {  //Require non-empty string
      setLookups([...lookups, { value: customLookup }]);
      setCustomLookup("");
    }
  };

  useEffect(() => {
    if (lookupData) {
      setLookups(lookupData.lookups);
    }
  }, [lookupData]);

  return (
    <Form.Item label={label} name={name} {...validation} {...props}>
      <Select
        loading={lookupLoading}
        size="large"
        showSearch
        style={{ width: "100%" }}
        mode={!singleMode && "multiple"}
        disabled={disabled}
        allowClear
        placeholder={placeholder}
        filterOption={(input, option) =>
          option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) =>
          optionA.children
            .toString().toLowerCase()
            .localeCompare(optionB.children.toString().toLowerCase())
        }
        dropdownRender={(menu) => (
          <div>
            {menu}
            <Divider style={{ margin: "4px 0" }} />
            <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
              <Input
                style={{ flex: "auto" }}
                value={customLookup}
                onChange={onNameChange}

                //A user can remove a selection in the list by clicking directly on the "x" by the entry.
                //Doing this does not trigger an onFocus and related onBlur so the change does not trigger collaboration updates?
                //How can we catch this event and trigger the collaboration update?
                //
                //onClick={console.log('onClick')}
                //onSelect={onNameChange} 
                //onDeselect={onNameChange} 
                //onMouseLeave={console.log('mouse leave')} 
                //onMouseEnter={console.log('mouse enter')} 

              />
              <a
                style={{
                  flex: "none",
                  padding: "8px",
                  display: "block",
                  cursor: "pointer",
                }}
                onClick={addItem}
              >
                <PlusOutlined /> Add item
              </a>
            </div>
          </div>
        )}
      >
        {lookups.map((x, index) => (
          <Option key={index} value={x.value}>
            {x.value}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

LookupSelect.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  validation: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
  singleMode: PropTypes.bool,
};

export default LookupSelect;
