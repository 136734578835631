import { gql } from "@apollo/client";

export const PRINT_FORM3454A = gql`
  mutation printStudentForm3454A(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $academicAchievement: String
    $functionalPerformance: String
    $cognitiveFunctioning: String
    $communicativeStatus: String
    $eLStatus: String
    $linguisticChanged: Boolean
    $healthStatus: String
    $hearingStatus: String
    $motorStatus: String
    $socialStatus: String
    $formAction: String
    $formStatus: String
  ) {
    printStudentForm3454A(
      studentForm3454AInput: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        academicAchievement: $academicAchievement
        functionalPerformance: $functionalPerformance
        cognitiveFunctioning: $cognitiveFunctioning
        communicativeStatus: $communicativeStatus
        eLStatus: $eLStatus
        linguisticChanged: $linguisticChanged
        healthStatus: $healthStatus
        hearingStatus: $hearingStatus
        motorStatus: $motorStatus
        socialStatus: $socialStatus
        formAction: $formAction
        formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;
