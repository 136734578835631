// react
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

// Contexts
import AuthContext from '../../../utils/authProvider'

// ant design
import { Card, Button, Tag, Tooltip } from 'antd'

// operations
import { QUERY_MESSAGES } from '../../../operations/queries/messages'

// components
import Listing from '../../Listing'

const MessageList = () => {
  const Auth = useContext(AuthContext)
  const isSuperAdmin = Auth.hasRole('SUPER_ADMIN')
  const canAddmessages = Auth.hasPermission('ADD_MESSAGES')
  const extra = (
    <>
      {canAddmessages && (
        <Tooltip title='Add new message'>
          <Link to='../message/add'>
            <Button type='primary'>Add Message</Button>
          </Link>
        </Tooltip>
      )}
    </>
  )
  const canEditMessages = Auth.hasPermission('EDIT_MESSAGES')
  const columns = [
    {
      title: 'Subject',
      dataIndex: 'subject',
      sorter: (a, b) => a.subject.localeCompare(b.subject),
      render: (value, record) => (
        <div>
          <div>
            {canEditMessages ? (
              <Link to={`../message/${record.id}`}>{value}</Link>
            ) : (
              <>{value}</>
            )}
            &nbsp;
            {isSuperAdmin && record.hidden && <Tag color='orange'>Hidden</Tag>}
          </div>
          {isSuperAdmin && <div className='cellWithId'>{record.id}</div>}
        </div>
      )
    }
  ]

  return (
    <>
      <Listing
        query={QUERY_MESSAGES}
        columns={columns}
        dataKey='messages'
        render={table => {
          return (
            <>
              <div className='page-container'>
                <Card title='Messages' bordered={false} extra={extra}>
                  {table}
                </Card>
              </div>
            </>
          )
        }}
      />
    </>
  )
}

export default MessageList
