import { gql } from "@apollo/client";

export const QUERY_CUSTOM_MEETING_REPORT = gql`
  query (
    $districtId: ID!
    $startDateRange: String
    $endDateRange: String
    $statusType: ID
    $schools: [ID]
  ) {
    meetingReport(
      meetingReportInput: {
        districtId: $districtId
        filter: {
          startDateRange: $startDateRange # this is studentform created date
          endDateRange: $endDateRange
          statusType: $statusType
          schools: $schools
        }
      }
    ) {
      meetingDate
      school {
        id
        name
        districtId
      }
      form {
        formCode
        formTypeId
      }
      status {
        enumName
        id
      }
      submissionInfo {
        submittedData
      }
    }
  }
`;

export const QUERY_DISTRICT_INFO_REPORT = gql`
  query ($districtId: ID!) {
    districtInfoReport(districtId: $districtId) {
      schools #number of schools in district
      students # number of students in district
      ieps # number of forms with formType IEP
      users # number of users
    }
  }
`;

export const QUERY_SCHOOL_REPORT = gql`
  query ($schoolId: ID!) {
    schoolReport(schoolId: $schoolId) {
      schoolName
      schoolAddress
      schoolCity
      schoolState
      schoolZip

      students # number of students in district
      ieps # number of forms with formType IEP
      users # number of users
    }
  }
`;

export const QUERY_DISTRICTS_SUMMARY_REPORT = gql`
  query ($districtIds: [ID], $startDateRange: String, $endDateRange: String) {
    districtSummaryReport(
      districtReportingInput: {
        districtIds: $districtIds
        startDateRange: $startDateRange # filters district create date
        endDateRange: $endDateRange
      }
    ) {
      district {
        name
      }
      schools #number of schools in district
      students # number of students in district
      ieps # number of forms with formType IEP
      users # number of users
    }
  }
`;

export const QUERY_DISTRICTS_USAGE_REPORT = gql`
  query ($districtIds: [ID], $startDateRange: String, $endDateRange: String) {
    districtUsageReport(
      districtReportingInput: {
        districtIds: $districtIds
        startDateRange: $startDateRange # Filters ieps and users create date
        endDateRange: $endDateRange # Filters ieps and users create date
      }
    ) {
      district {
        name
        id
      }
      ieps # number of forms with formType IEP
      users # number of users
    }
  }
`;

export const QUERY_ISTAR_REPORT = gql`
  query (
    $districtId: ID!
    $startDateRange: String
    $endDateRange: String
    $schools: [ID]
  ) {
    iStarReport(
      iStarReportInput: {
        districtId: $districtId
        startDateRange: $startDateRange
        endDateRange: $endDateRange
        schools: $schools
      }
    ) {
      student {
        fullName
        grade
        schools {
          name
          district {
            name
          }
        }
        gender
      }
      Form3454B {
        primaryAutism
        secondaryAutism
        primaryDeaf
        secondaryDeaf
        primaryDeafness
        secondaryDeafness
        primaryDevelopmentDelay
        secondaryDevelopmentDelay
        primaryEmotionalDisability
        secondaryEmotionalDisability
        primaryHearingIMpairment
        secondaryHearingIMpairment
        primaryItellactualDisability
        secondaryItellactualDisability
        primaryMultipleDisabilities
        secondaryMultipleDisabilities
        primaryOrthopedicImpairment
        secondaryOrthopedicImpairment
        primaryOtherHealthImpairment
        secondaryOtherHealthImpairment
        primarySpeechImpairment
        secondarySpeechImpairment
        primaryBrainInjury
        secondaryBrainInjury
        primaryVisualImpairment
        secondaryVisualImpairment
      }
      Form3454P {
        intiationDate
        durationDate
        generalEducationNoSupplementary
        generalEducationNoSupplementaryminutesPerWeek
        generalEducationSupplementaryAids
        generalEducationSupplementaryAidsMinutesPerWeek
        generalEducationClassroom
        generalEducationClassroomMinutesPerWeek
        outsideGeneralEducation
        outsideGeneralEducationMinutesPerWeek
        outsideRelatedServices
        outsideRelatedServicesMinutesPerWeek
        regular35ProgramMinutesPerWeek
        regularABProgramMinutesPerWeek
        totalBelltoBellMinutes
        totalNumberMinutesOutsideGeneralEducation
        totalNumberMinutesInsideGeneralEducation
        percentageInsideGeneralEducationEnvironment
      }
      Form3454Q {
        requiredSpecialClasses
        requiredSpecialClassesExplaination
        nonacademicParticipation
        nonacademicParticipationExplaination
        attendSchool
        attendSchoolExplain
        informedSchoolServices
        placementOptions {
          placementOptionsOptionConsidered
          placementOptionsReasonRejected
          placementOptionsTeamAcceptsPlacement
        }
        transportationSchoolRequired
        transportationBuildingsRequired
        specializedEquipmentRequired
        specializedEquipmentRequiredExplanation
        extendedSchoolYearServices
        extendedServices {
          extendedServicesSpecialEducationService
          extendedServicesLocation
          extendedServicesFrequency
          extendedServicesInitiation
          extendedServicesDuration
          extendedServicesGoalsAddressed
        }
      }
    }
  }
`;

//------------------------------------------------------------------------------
//New report builder queries
//------------------------------------------------------------------------------
export const QUERY_SCHOOLS_REPORT = gql`
  query (
      $schoolIds: [ID], 
      $selectedFields: [String],
      $includeSchoolId: Boolean! = false,
      $includeSchoolCode: Boolean! = false,
      $includeSchoolName: Boolean! =false,
      $includeSchoolAddress: Boolean! = false,
      $includeSchoolCity: Boolean! = false,
      $includeSchoolState: Boolean! = false,
      $includeSchoolZip: Boolean! = false,

      $includeSchoolCountOfStudents: Boolean! = false,
      $includeSchoolCountOfIeps: Boolean! = false,
      $includeSchoolCountOfUsers: Boolean! = false,

      $includeSchoolCountOfStudentsEthnicityAfricanAmerican: Boolean! = false,
      $includeSchoolCountOfStudentsEthnicityAmericanIndian: Boolean! = false,
      $includeSchoolCountOfStudentsEthnicityAsian: Boolean! = false,
      $includeSchoolCountOfStudentsEthnicityHispanic: Boolean! = false,
      $includeSchoolCountOfStudentsEthnicityNativeHawaiian: Boolean! = false,
      $includeSchoolCountOfStudentsEthnicityWhite: Boolean! = false,

      $includeSchoolCountOfStudentsGenderFemale: Boolean! = false,
      $includeSchoolCountOfStudentsGenderMale: Boolean! = false,

      $includeSchoolCountOfStudentsGrade1: Boolean! = false,
      $includeSchoolCountOfStudentsGrade2: Boolean! = false,
      $includeSchoolCountOfStudentsGrade3: Boolean! = false,
      $includeSchoolCountOfStudentsGrade4: Boolean! = false,
      $includeSchoolCountOfStudentsGrade5: Boolean! = false,
      $includeSchoolCountOfStudentsGrade6: Boolean! = false,
      $includeSchoolCountOfStudentsGrade7: Boolean! = false,
      $includeSchoolCountOfStudentsGrade8: Boolean! = false,
      $includeSchoolCountOfStudentsGrade9: Boolean! = false,
      $includeSchoolCountOfStudentsGrade10: Boolean! = false,
      $includeSchoolCountOfStudentsGrade11: Boolean! = false,
      $includeSchoolCountOfStudentsGrade12: Boolean! = false,
      $includeSchoolCountOfStudentsGradeKindergarten: Boolean! = false,
      $includeSchoolCountOfStudentsGradeNA: Boolean! = false,

      $includeSchoolCountOfStudentsLanguageArabic: Boolean! = false,
      $includeSchoolCountOfStudentsLanguageChinese: Boolean! = false,
      $includeSchoolCountOfStudentsLanguageEnglish: Boolean! = false,
      $includeSchoolCountOfStudentsLanguageFrench: Boolean! = false,
      $includeSchoolCountOfStudentsLanguageGerman: Boolean! = false,
      $includeSchoolCountOfStudentsLanguageKorean: Boolean! = false,
      $includeSchoolCountOfStudentsLanguageRussian: Boolean! = false,
      $includeSchoolCountOfStudentsLanguageSpanish: Boolean! = false,
      $includeSchoolCountOfStudentsLanguageTagalog: Boolean! = false,
      $includeSchoolCountOfStudentsLanguageVietnamese: Boolean! = false,
      $includeSchoolCountOfStudentsLanguageOther: Boolean! = false,

      $includeDistrictId: Boolean! = false,
      $includeDistrictName: Boolean! = false,
      $includeDistrictAddress: Boolean! = false,
      $includeDistrictCity: Boolean! = false,
      $includeDistrictState: Boolean! = false,
      $includeDistrictZip: Boolean! = false,
      $includeDistrictPhone: Boolean! = false,
      $includeDistrictFax: Boolean! = false,
      $includeDistrictWebsite: Boolean! = false,

      $includeDistrictCountOfStudents: Boolean! = false,
      $includeDistrictCountOfIeps: Boolean! = false,
      $includeDistrictCountOfUsers: Boolean! = false,

      $includeDistrictCountOfStudentsEthnicityAfricanAmerican: Boolean! = false,
      $includeDistrictCountOfStudentsEthnicityAmericanIndian: Boolean! = false,
      $includeDistrictCountOfStudentsEthnicityAsian: Boolean! = false,
      $includeDistrictCountOfStudentsEthnicityHispanic: Boolean! = false,
      $includeDistrictCountOfStudentsEthnicityNativeHawaiian: Boolean! = false,
      $includeDistrictCountOfStudentsEthnicityWhite: Boolean! = false,

      $includeDistrictCountOfStudentsGenderFemale: Boolean! = false,
      $includeDistrictCountOfStudentsGenderMale: Boolean! = false,

      $includeDistrictCountOfStudentsGrade1: Boolean! = false,
      $includeDistrictCountOfStudentsGrade2: Boolean! = false,
      $includeDistrictCountOfStudentsGrade3: Boolean! = false,
      $includeDistrictCountOfStudentsGrade4: Boolean! = false,
      $includeDistrictCountOfStudentsGrade5: Boolean! = false,
      $includeDistrictCountOfStudentsGrade6: Boolean! = false,
      $includeDistrictCountOfStudentsGrade7: Boolean! = false,
      $includeDistrictCountOfStudentsGrade8: Boolean! = false,
      $includeDistrictCountOfStudentsGrade9: Boolean! = false,
      $includeDistrictCountOfStudentsGrade10: Boolean! = false,
      $includeDistrictCountOfStudentsGrade11: Boolean! = false,
      $includeDistrictCountOfStudentsGrade12: Boolean! = false,
      $includeDistrictCountOfStudentsGradeKindergarten: Boolean! = false,
      $includeDistrictCountOfStudentsGradeNA: Boolean! = false,

      $includeDistrictCountOfStudentsLanguageArabic: Boolean! = false,
      $includeDistrictCountOfStudentsLanguageChinese: Boolean! = false,
      $includeDistrictCountOfStudentsLanguageEnglish: Boolean! = false,
      $includeDistrictCountOfStudentsLanguageFrench: Boolean! = false,
      $includeDistrictCountOfStudentsLanguageGerman: Boolean! = false,
      $includeDistrictCountOfStudentsLanguageKorean: Boolean! = false,
      $includeDistrictCountOfStudentsLanguageRussian: Boolean! = false,
      $includeDistrictCountOfStudentsLanguageSpanish: Boolean! = false,
      $includeDistrictCountOfStudentsLanguageTagalog: Boolean! = false,
      $includeDistrictCountOfStudentsLanguageVietnamese: Boolean! = false,
      $includeDistrictCountOfStudentsLanguageOther: Boolean! = false,

    ) {
    schoolsReport(schoolIds: $schoolIds, selectedFields: $selectedFields)
      {
      schoolId @include(if: $includeSchoolId)
      schoolCode @include(if: $includeSchoolCode)
      schoolName @include(if: $includeSchoolName)
      schoolAddress @include(if: $includeSchoolAddress)
      schoolCity @include(if: $includeSchoolCity)
      schoolState @include(if: $includeSchoolState)
      schoolZip @include(if: $includeSchoolZip)

      schoolCountOfStudents @include(if: $includeSchoolCountOfStudents)
      schoolCountOfIeps @include(if: $includeSchoolCountOfIeps)
      schoolCountOfUsers @include(if: $includeSchoolCountOfUsers)

      schoolCountOfStudentsEthnicityAfricanAmerican @include(if: $includeSchoolCountOfStudentsEthnicityAfricanAmerican)
      schoolCountOfStudentsEthnicityAmericanIndian @include(if: $includeSchoolCountOfStudentsEthnicityAmericanIndian)
      schoolCountOfStudentsEthnicityAsian @include(if: $includeSchoolCountOfStudentsEthnicityAsian)
      schoolCountOfStudentsEthnicityHispanic @include(if: $includeSchoolCountOfStudentsEthnicityHispanic)
      schoolCountOfStudentsEthnicityNativeHawaiian @include(if: $includeSchoolCountOfStudentsEthnicityNativeHawaiian)
      schoolCountOfStudentsEthnicityWhite @include(if: $includeSchoolCountOfStudentsEthnicityWhite)

      schoolCountOfStudentsGenderFemale @include(if: $includeSchoolCountOfStudentsGenderFemale)
      schoolCountOfStudentsGenderMale @include(if: $includeSchoolCountOfStudentsGenderMale)

      schoolCountOfStudentsGrade1 @include(if: $includeSchoolCountOfStudentsGrade1)
      schoolCountOfStudentsGrade2 @include(if: $includeSchoolCountOfStudentsGrade2)
      schoolCountOfStudentsGrade3 @include(if: $includeSchoolCountOfStudentsGrade3)
      schoolCountOfStudentsGrade4 @include(if: $includeSchoolCountOfStudentsGrade4)
      schoolCountOfStudentsGrade5 @include(if: $includeSchoolCountOfStudentsGrade5)
      schoolCountOfStudentsGrade6 @include(if: $includeSchoolCountOfStudentsGrade6)
      schoolCountOfStudentsGrade7 @include(if: $includeSchoolCountOfStudentsGrade7)
      schoolCountOfStudentsGrade8 @include(if: $includeSchoolCountOfStudentsGrade8)
      schoolCountOfStudentsGrade9 @include(if: $includeSchoolCountOfStudentsGrade9)
      schoolCountOfStudentsGrade10 @include(if: $includeSchoolCountOfStudentsGrade10)
      schoolCountOfStudentsGrade11 @include(if: $includeSchoolCountOfStudentsGrade11)
      schoolCountOfStudentsGrade12 @include(if: $includeSchoolCountOfStudentsGrade12)
      schoolCountOfStudentsGradeKindergarten @include(if: $includeSchoolCountOfStudentsGradeKindergarten)
      schoolCountOfStudentsGradeNA @include(if: $includeSchoolCountOfStudentsGradeNA)

      schoolCountOfStudentsLanguageArabic @include(if: $includeSchoolCountOfStudentsLanguageArabic)
      schoolCountOfStudentsLanguageChinese @include(if: $includeSchoolCountOfStudentsLanguageChinese)
      schoolCountOfStudentsLanguageEnglish @include(if: $includeSchoolCountOfStudentsLanguageEnglish)
      schoolCountOfStudentsLanguageFrench @include(if: $includeSchoolCountOfStudentsLanguageFrench)
      schoolCountOfStudentsLanguageGerman @include(if: $includeSchoolCountOfStudentsLanguageGerman)
      schoolCountOfStudentsLanguageKorean @include(if: $includeSchoolCountOfStudentsLanguageKorean)
      schoolCountOfStudentsLanguageRussian @include(if: $includeSchoolCountOfStudentsLanguageRussian)
      schoolCountOfStudentsLanguageSpanish @include(if: $includeSchoolCountOfStudentsLanguageSpanish)
      schoolCountOfStudentsLanguageTagalog @include(if: $includeSchoolCountOfStudentsLanguageTagalog)
      schoolCountOfStudentsLanguageVietnamese @include(if: $includeSchoolCountOfStudentsLanguageVietnamese)
      schoolCountOfStudentsLanguageOther @include(if: $includeSchoolCountOfStudentsLanguageOther)

      districtId @include(if: $includeDistrictId)
      districtName @include(if: $includeDistrictName)
      districtAddress @include(if: $includeDistrictAddress)
      districtCity @include(if: $includeDistrictCity)
      districtState @include(if: $includeDistrictState)
      districtZip @include(if: $includeDistrictZip)
      districtPhone @include(if: $includeDistrictPhone)
      districtFax @include(if: $includeDistrictFax)
      districtWebsite @include(if: $includeDistrictWebsite)

      districtCountOfStudents @include(if: $includeDistrictCountOfStudents)
      districtCountOfIeps @include(if: $includeDistrictCountOfIeps)
      districtCountOfUsers @include(if: $includeDistrictCountOfUsers)

      districtCountOfStudentsEthnicityAfricanAmerican @include(if: $includeDistrictCountOfStudentsEthnicityAfricanAmerican)
      districtCountOfStudentsEthnicityAmericanIndian @include(if: $includeDistrictCountOfStudentsEthnicityAmericanIndian)
      districtCountOfStudentsEthnicityAsian @include(if: $includeDistrictCountOfStudentsEthnicityAsian)
      districtCountOfStudentsEthnicityHispanic @include(if: $includeDistrictCountOfStudentsEthnicityHispanic)
      districtCountOfStudentsEthnicityNativeHawaiian @include(if: $includeDistrictCountOfStudentsEthnicityNativeHawaiian)
      districtCountOfStudentsEthnicityWhite @include(if: $includeDistrictCountOfStudentsEthnicityWhite)

      districtCountOfStudentsGenderFemale @include(if: $includeDistrictCountOfStudentsGenderFemale)
      districtCountOfStudentsGenderMale @include(if: $includeDistrictCountOfStudentsGenderMale)

      districtCountOfStudentsGrade1 @include(if: $includeDistrictCountOfStudentsGrade1)
      districtCountOfStudentsGrade2 @include(if: $includeDistrictCountOfStudentsGrade2)
      districtCountOfStudentsGrade3 @include(if: $includeDistrictCountOfStudentsGrade3)
      districtCountOfStudentsGrade4 @include(if: $includeDistrictCountOfStudentsGrade4)
      districtCountOfStudentsGrade5 @include(if: $includeDistrictCountOfStudentsGrade5)
      districtCountOfStudentsGrade6 @include(if: $includeDistrictCountOfStudentsGrade6)
      districtCountOfStudentsGrade7 @include(if: $includeDistrictCountOfStudentsGrade7)
      districtCountOfStudentsGrade8 @include(if: $includeDistrictCountOfStudentsGrade8)
      districtCountOfStudentsGrade9 @include(if: $includeDistrictCountOfStudentsGrade9)
      districtCountOfStudentsGrade10 @include(if: $includeDistrictCountOfStudentsGrade10)
      districtCountOfStudentsGrade11 @include(if: $includeDistrictCountOfStudentsGrade11)
      districtCountOfStudentsGrade12 @include(if: $includeDistrictCountOfStudentsGrade12)
      districtCountOfStudentsGradeKindergarten @include(if: $includeDistrictCountOfStudentsGradeKindergarten)
      districtCountOfStudentsGradeNA @include(if: $includeDistrictCountOfStudentsGradeNA)

      districtCountOfStudentsLanguageArabic @include(if: $includeDistrictCountOfStudentsLanguageArabic)
      districtCountOfStudentsLanguageChinese @include(if: $includeDistrictCountOfStudentsLanguageChinese)
      districtCountOfStudentsLanguageEnglish @include(if: $includeDistrictCountOfStudentsLanguageEnglish)
      districtCountOfStudentsLanguageFrench @include(if: $includeDistrictCountOfStudentsLanguageFrench)
      districtCountOfStudentsLanguageGerman @include(if: $includeDistrictCountOfStudentsLanguageGerman)
      districtCountOfStudentsLanguageKorean @include(if: $includeDistrictCountOfStudentsLanguageKorean)
      districtCountOfStudentsLanguageRussian @include(if: $includeDistrictCountOfStudentsLanguageRussian)
      districtCountOfStudentsLanguageSpanish @include(if: $includeDistrictCountOfStudentsLanguageSpanish)
      districtCountOfStudentsLanguageTagalog @include(if: $includeDistrictCountOfStudentsLanguageTagalog)
      districtCountOfStudentsLanguageVietnamese @include(if: $includeDistrictCountOfStudentsLanguageVietnamese)
      districtCountOfStudentsLanguageOther @include(if: $includeDistrictCountOfStudentsLanguageOther)
    }
  }
`;

export const QUERY_DISTRICTS_REPORT = gql`
  query (
      $districtIds: [ID], 
      $includeDistrictId: Boolean! = false,
      $includeDistrictName: Boolean! = false,
      $includeDistrictAddress: Boolean! = false,
      $includeDistrictCity: Boolean! = false,
      $includeDistrictState: Boolean! = false,
      $includeDistrictZip: Boolean! = false,
      $includeDistrictPhone: Boolean! = false,
      $includeDistrictFax: Boolean! = false,
      $includeDistrictWebsite: Boolean! = false,

      $includeDistrictCountOfStudents: Boolean! = false,
      $includeDistrictCountOfIeps: Boolean! = false,
      $includeDistrictCountOfUsers: Boolean! = false,

      $includeDistrictCountOfStudentsEthnicityAfricanAmerican: Boolean! = false,
      $includeDistrictCountOfStudentsEthnicityAmericanIndian: Boolean! = false,
      $includeDistrictCountOfStudentsEthnicityAsian: Boolean! = false,
      $includeDistrictCountOfStudentsEthnicityHispanic: Boolean! = false,
      $includeDistrictCountOfStudentsEthnicityNativeHawaiian: Boolean! = false,
      $includeDistrictCountOfStudentsEthnicityWhite: Boolean! = false,

      $includeDistrictCountOfStudentsGenderFemale: Boolean! = false,
      $includeDistrictCountOfStudentsGenderMale: Boolean! = false,

      $includeDistrictCountOfStudentsGrade1: Boolean! = false,
      $includeDistrictCountOfStudentsGrade2: Boolean! = false,
      $includeDistrictCountOfStudentsGrade3: Boolean! = false,
      $includeDistrictCountOfStudentsGrade4: Boolean! = false,
      $includeDistrictCountOfStudentsGrade5: Boolean! = false,
      $includeDistrictCountOfStudentsGrade6: Boolean! = false,
      $includeDistrictCountOfStudentsGrade7: Boolean! = false,
      $includeDistrictCountOfStudentsGrade8: Boolean! = false,
      $includeDistrictCountOfStudentsGrade9: Boolean! = false,
      $includeDistrictCountOfStudentsGrade10: Boolean! = false,
      $includeDistrictCountOfStudentsGrade11: Boolean! = false,
      $includeDistrictCountOfStudentsGrade12: Boolean! = false,
      $includeDistrictCountOfStudentsGradeKindergarten: Boolean! = false,
      $includeDistrictCountOfStudentsGradeNA: Boolean! = false,

      $includeDistrictCountOfStudentsLanguageArabic: Boolean! = false,
      $includeDistrictCountOfStudentsLanguageChinese: Boolean! = false,
      $includeDistrictCountOfStudentsLanguageEnglish: Boolean! = false,
      $includeDistrictCountOfStudentsLanguageFrench: Boolean! = false,
      $includeDistrictCountOfStudentsLanguageGerman: Boolean! = false,
      $includeDistrictCountOfStudentsLanguageKorean: Boolean! = false,
      $includeDistrictCountOfStudentsLanguageRussian: Boolean! = false,
      $includeDistrictCountOfStudentsLanguageSpanish: Boolean! = false,
      $includeDistrictCountOfStudentsLanguageTagalog: Boolean! = false,
      $includeDistrictCountOfStudentsLanguageVietnamese: Boolean! = false,
      $includeDistrictCountOfStudentsLanguageOther: Boolean! = false,
    ) {
    districtsReport(districtIds: $districtIds, selectedFields: $selectedFields)
      {
      districtId @include(if: $includeDistrictId)
      districtName @include(if: $includeDistrictName)
      districtAddress @include(if: $includeDistrictAddress)
      districtCity @include(if: $includeDistrictCity)
      districtState @include(if: $includeDistrictState)
      districtZip @include(if: $includeDistrictZip)
      districtPhone @include(if: $includeDistrictPhone)
      districtFax @include(if: $includeDistrictFax)
      districtWebsite @include(if: $includeDistrictWebsite)

      districtCountOfStudents @include(if: $includeDistrictCountOfStudents)
      districtCountOfIeps @include(if: $includeDistrictCountOfIeps)
      districtCountOfUsers @include(if: $includeDistrictCountOfUsers)

      districtCountOfStudentsEthnicityAfricanAmerican @include(if: $includeDistrictCountOfStudentsEthnicityAfricanAmerican)
      districtCountOfStudentsEthnicityAmericanIndian @include(if: $includeDistrictCountOfStudentsEthnicityAmericanIndian)
      districtCountOfStudentsEthnicityAsian @include(if: $includeDistrictCountOfStudentsEthnicityAsian)
      districtCountOfStudentsEthnicityHispanic @include(if: $includeDistrictCountOfStudentsEthnicityHispanic)
      districtCountOfStudentsEthnicityNativeHawaiian @include(if: $includeDistrictCountOfStudentsEthnicityNativeHawaiian)
      districtCountOfStudentsEthnicityWhite @include(if: $includeDistrictCountOfStudentsEthnicityWhite)

      districtCountOfStudentsGenderFemale @include(if: $includeDistrictCountOfStudentsGenderFemale)
      districtCountOfStudentsGenderMale @include(if: $includeDistrictCountOfStudentsGenderMale)

      districtCountOfStudentsGrade1 @include(if: $includeDistrictCountOfStudentsGrade1)
      districtCountOfStudentsGrade2 @include(if: $includeDistrictCountOfStudentsGrade2)
      districtCountOfStudentsGrade3 @include(if: $includeDistrictCountOfStudentsGrade3)
      districtCountOfStudentsGrade4 @include(if: $includeDistrictCountOfStudentsGrade4)
      districtCountOfStudentsGrade5 @include(if: $includeDistrictCountOfStudentsGrade5)
      districtCountOfStudentsGrade6 @include(if: $includeDistrictCountOfStudentsGrade6)
      districtCountOfStudentsGrade7 @include(if: $includeDistrictCountOfStudentsGrade7)
      districtCountOfStudentsGrade8 @include(if: $includeDistrictCountOfStudentsGrade8)
      districtCountOfStudentsGrade9 @include(if: $includeDistrictCountOfStudentsGrade9)
      districtCountOfStudentsGrade10 @include(if: $includeDistrictCountOfStudentsGrade10)
      districtCountOfStudentsGrade11 @include(if: $includeDistrictCountOfStudentsGrade11)
      districtCountOfStudentsGrade12 @include(if: $includeDistrictCountOfStudentsGrade12)
      districtCountOfStudentsGradeKindergarten @include(if: $includeDistrictCountOfStudentsGradeKindergarten)
      districtCountOfStudentsGradeNA @include(if: $includeDistrictCountOfStudentsGradeNA)

      districtCountOfStudentsLanguageArabic @include(if: $includeDistrictCountOfStudentsLanguageArabic)
      districtCountOfStudentsLanguageChinese @include(if: $includeDistrictCountOfStudentsLanguageChinese)
      districtCountOfStudentsLanguageEnglish @include(if: $includeDistrictCountOfStudentsLanguageEnglish)
      districtCountOfStudentsLanguageFrench @include(if: $includeDistrictCountOfStudentsLanguageFrench)
      districtCountOfStudentsLanguageGerman @include(if: $includeDistrictCountOfStudentsLanguageGerman)
      districtCountOfStudentsLanguageKorean @include(if: $includeDistrictCountOfStudentsLanguageKorean)
      districtCountOfStudentsLanguageRussian @include(if: $includeDistrictCountOfStudentsLanguageRussian)
      districtCountOfStudentsLanguageSpanish @include(if: $includeDistrictCountOfStudentsLanguageSpanish)
      districtCountOfStudentsLanguageTagalog @include(if: $includeDistrictCountOfStudentsLanguageTagalog)
      districtCountOfStudentsLanguageVietnamese @include(if: $includeDistrictCountOfStudentsLanguageVietnamese)
      districtCountOfStudentsLanguageOther @include(if: $includeDistrictCountOfStudentsLanguageOther)

    }
  }
`;

