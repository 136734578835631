import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { IepFormContext } from '../../../../utils/iepFormProvider'
import { useQuery } from '@apollo/client'
import { QUERY_STUDENT_COMMON_SIS } from '../../../../operations/queries/forms/SIS'
import { QUERY_FORM3457M_VERSION } from '../../../../operations/queries/forms/3457M'
import moment from 'moment'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import FormContainer from '../../../FormContainer'
import {
  Alert,
  Card,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Typography
} from 'antd'
import FormCollaboration from '../../../FormCollaboration'
import Title from 'antd/lib/typography/Title'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import StudentDobSIS from '../sis/StudentDobSIS'
import StudentParent1SIS from '../sis/StudentParent1SIS'
import StudentParent2SIS from '../sis/StudentParent2SIS'
import FormItem from 'antd/lib/form/FormItem'
import StudentFormActions from '../StudentFormActions'
import { PRINT_FORM3457M } from '../../../../operations/mutations/forms/3457M'
import Editor from '../../../elements/common/Editor'

const { Paragraph } = Typography

const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId
}) => {
  const [form] = Form.useForm()
  const { itemErrors, formError } = useContext(FormErrorContext)

  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()
    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      studentMeetingId: studentMeetingId
    }
  }
  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    const update = Object.assign({})
    switch (fieldName) {
      case 'formDate':
      case 'previousDate':
      case 'newDate':
      case 'agreementDate':
      case 'parentSignatureDate':
        update[fieldName] = fieldValue ? moment(fieldValue) : ''
        break
      case 'consent':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        break
      case 'additionalEvaluationDays':
        update[fieldName] = parseInt(fieldValue)
        break
      default:
        update[fieldName] = fieldValue
    }
    return update
  }

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }

  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          formDisabledState,
          canSave,
          handleActionEvents,
          validationProps
        }) => (
          <Form
            form={form}
            id='studentForm3457M'
            name='studentForm3457M'
            initialValues={data}
            preserve
            layout='vertical'
            className='page-padding-leftRight page-padding-topBottom form-labels-bold'
          >
            <Title level={4} className='center'>
              MUTUAL WRITTEN AGREEMENT TO EXTEND EVALUATION TIMELINE <br /> (FOR
              STUDENTS WITH OR SUSPECTED OF HAVING A SPECIFIC LEARNING
              DISABILITY ONLY)
            </Title>
            <Form.Item
              label='Form Created Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
              />
            </Form.Item>

            <Card
              title='Student SIS Data'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />
              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
              <StudentDobSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentDob}
                error={itemErrors.studentDob}
              />
              <StudentParent1SIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.parent1}
                error={itemErrors.parent1}
              />
              <StudentParent2SIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.parent2}
                error={itemErrors.parent2}
              />
            </Card>

            <Card
              title='Details'
              style={{
                marginBottom: 30
              }}
            >
              <FormItem
                label='Evaluation Type'
                name='evaluationType'
                {...validationProps('evaluationType')}
              >
                <Radio.Group
                  {...handleMouseEvents}
                  buttonStyle='solid'
                  disabled={formDisabledState.evaluationType}
                  name='evaluationType'
                >
                  <Radio.Button value='initial-evaluation'>
                    Initial evaluation
                  </Radio.Button>
                  <Radio.Button value='re-evaluation'>
                    Re-evaluation
                  </Radio.Button>
                </Radio.Group>
              </FormItem>

              <Form.Item
                label='Number of school days'
                name='additionalEvaluationDays'
                {...validationProps('additionalEvaluationDays')}
              >
                <InputNumber
                  min={1}
                  {...handleFocusEvents}
                  disabled={formDisabledState.additionalEvaluationDays}
                />
              </Form.Item>

              <Form.Item
                label='Previous completion date'
                name='previousDate'
                {...validationProps('previousDate')}
              >
                <DatePicker
                  {...handleFocusEvents}
                  disabled={formDisabledState.previousDate}
                  format={[
                    'MM-DD-YYYY',
                    'MM/DD/YYYY',
                    'MM.DD.YY',
                    'M-D-YYYY',
                    'M/D/YYYY',
                    'M.D.YYYY',
                    'MM.DD.YYYY',
                    'MM-DD-YY',
                    'MM/DD/YY',
                    'M-D-YY',
                    'M/D/YY',
                    'M.D.YY',
                    'MMDDYYYY',
                    'MMDDYY'
                  ]}
                />
              </Form.Item>

              <Form.Item
                label='New completion date'
                name='newDate'
                {...validationProps('newDate')}
              >
                <DatePicker
                  {...handleFocusEvents}
                  disabled={formDisabledState.newDate}
                  format={[
                    'MM-DD-YYYY',
                    'MM/DD/YYYY',
                    'MM.DD.YY',
                    'M-D-YYYY',
                    'M/D/YYYY',
                    'M.D.YYYY',
                    'MM.DD.YYYY',
                    'MM-DD-YY',
                    'MM/DD/YY',
                    'M-D-YY',
                    'M/D/YY',
                    'M.D.YY',
                    'MMDDYYYY',
                    'MMDDYY'
                  ]}
                />
              </Form.Item>

              <Form.Item
                label='Reason(s) for Timeline Extensions'
                name='extensionReason'
                {...validationProps('extensionReason')}
              >
                <Editor
                  {...handleEditorEvents}
                  formField='extensionReason'
                  disabled={formDisabledState.extensionReason}
                />
              </Form.Item>
            </Card>

            <Card
              title='Representative Information'
              style={{
                marginBottom: 30
              }}
            >
              <Form.Item
                label='Agreement Date'
                name='agreementDate'
                {...validationProps('agreementDate')}
              >
                <DatePicker
                  {...handleFocusEvents}
                  disabled={formDisabledState.agreementDate}
                  format={[
                    'MM-DD-YYYY',
                    'MM/DD/YYYY',
                    'MM.DD.YY',
                    'M-D-YYYY',
                    'M/D/YYYY',
                    'M.D.YYYY',
                    'MM.DD.YYYY',
                    'MM-DD-YY',
                    'MM/DD/YY',
                    'M-D-YY',
                    'M/D/YY',
                    'M.D.YY',
                    'MMDDYYYY',
                    'MMDDYY'
                  ]}
                />
              </Form.Item>

              <Form.Item
                label='Name'
                name='representativeName'
                {...validationProps('representativeName')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.representativeName}
                />
              </Form.Item>
              <Form.Item
                label='Title'
                name='representativeTitle'
                {...validationProps('representativeTitle')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.representativeTitle}
                />
              </Form.Item>
            </Card>

            <Card
              title='Parent/Guardian Information'
              style={{
                marginBottom: 30
              }}
            >
              <FormItem
                label='Agreement'
                name='parentAgreement'
                {...validationProps('parentAgreement')}
              >
                <Radio.Group
                  defaultValue
                  defaultChecked='n/a'
                  buttonStyle='solid'
                  {...handleMouseEvents}
                  disabled={formDisabledState.parentAgreement}
                  name='parentAgreement'
                >
                  <Radio.Button value='yes'>Yes</Radio.Button>
                  <Radio.Button value='no'>No</Radio.Button>
                  <Radio.Button value='n/a'>Decision Delayed</Radio.Button>
                </Radio.Group>
              </FormItem>

              <Form.Item
                label='Parent Signature Date'
                name='parentSignatureDate'
                {...validationProps('parentSignatureDate')}
              >
                <DatePicker
                  {...handleFocusEvents}
                  disabled={formDisabledState.parentSignatureDate}
                  format={[
                    'MM-DD-YYYY',
                    'MM/DD/YYYY',
                    'MM.DD.YY',
                    'M-D-YYYY',
                    'M/D/YYYY',
                    'M.D.YYYY',
                    'MM.DD.YYYY',
                    'MM-DD-YY',
                    'MM/DD/YY',
                    'M-D-YY',
                    'M/D/YY',
                    'M.D.YY',
                    'MMDDYYYY',
                    'MMDDYY'
                  ]}
                />
              </Form.Item>
            </Card>

            {formError && <Paragraph type='danger'>{formError}</Paragraph>}
            <StudentFormActions
              query={PRINT_FORM3457M}
              extractDataProp='printStudentForm3457M'
              canSave={canSave}
              handleActionEvents={handleActionEvents}

              getFormFieldValues={getFormFieldValues}
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired
}

const StudentForm3457M = () => {
  // get current form information
  const { currentStudentInfo, currentEditableForm, studentMeetingId } =
    useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId } = currentEditableForm

  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    QUERY_STUDENT_COMMON_SIS,
    { variables: { id: studentId } }
  )
  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORM3457M_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )

  const createInitialFormData = (studentInfo, versionInfo) => {
    let initialValues = {}

    if (!studentInfo || !versionInfo) return initialValues

    const { studentForm3457M: form } = versionInfo
    const { student } = studentInfo
    const { parent1FullName, parent2FullName, dateOfBirth } = student
    const {
      formDate,
      studentFullName,
      studentDob,
      parent1,
      parent2,
      previousDate,
      newDate,
      agreementDate,
      parentSignatureDate
    } = form

    initialValues = {
      ...form,
      formDate: formDate ? moment(formDate) : moment(Date.now()),
      previousDate: previousDate ? moment(previousDate) : moment(Date.now()),
      newDate: newDate ? moment(newDate) : moment(Date.now()),
      agreementDate: agreementDate ? moment(agreementDate) : moment(Date.now()),
      parentSignatureDate: parentSignatureDate
        ? moment(parentSignatureDate)
        : moment(Date.now()),
      studentFullName: studentFullName || student.fullName || '',
      studentDob: studentDob
        ? moment(studentDob).format('YYYY-MM-DD')
        : dateOfBirth
        ? moment(dateOfBirth).format('YYYY-MM-DD')
        : '',
      parent1: parent1 || parent1FullName || '',
      parent2: parent2 || parent2FullName || ''
    }

    return initialValues
  }
  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
          />
        }
      />
    </FormErrorProvider>
  )
}
export default StudentForm3457M
