import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { useQuery } from '@apollo/client'

// ant design
import {
  Form,
  Input,
  DatePicker,
  Radio,
  Alert,
  Typography,
  Card,
  Checkbox
} from 'antd'
import FormContainer from '../../../FormContainer'
import FormItem from 'antd/lib/form/FormItem'
import Title from 'antd/lib/typography/Title'

import { PRINT_FORM3457D } from '../../../../operations/mutations/forms/3457D'
import { QUERY_FORM3457D_VERSION } from '../../../../operations/queries/forms/3457D'
import { QUERY_STUDENT_COMMON_SIS } from '../../../../operations/queries/forms/SIS'

import FormCollaboration from '../../../FormCollaboration'

import { IepFormContext } from '../../../../utils/iepFormProvider'
import StudentFormActions from '../StudentFormActions'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import StudentDobSIS from '../sis/StudentDobSIS'
import StudentParent1SIS from '../sis/StudentParent1SIS'
import StudentParent2SIS from '../sis/StudentParent2SIS'
import { Row, Col } from 'antd'

const { Paragraph } = Typography
const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId
}) => {
  const [form] = Form.useForm()
  const { itemErrors, formError } = useContext(FormErrorContext)
  const [dateValue, setDateValue] =useState()
  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()
    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      studentMeetingId: studentMeetingId,
      otherNames: [
        formFieldValues.otherName,
        formFieldValues.otherName2,
        formFieldValues.otherName3,
        formFieldValues.otherName4,
        formFieldValues.otherName5
      ]
    }
  }

  const formPurposeFields = [
    {
      label: 'Review of Existing Data',
      name: 'purposeReview'
    },
    {
      label: 'Initial Evaluation/Eligibility',
      name: 'purposeInitialEvaluation'
    },
    {
      label: 'Reevaluation',
      name: 'purposeReEvaluation'
    },
    {
      label: 'Initial Individualized Education Program',
      name: 'purposeIndividualizedEducation'
    },
    {
      label: 'IEP Review and Revision',
      name: 'purposeIEPReview'
    },
    {
      label: 'Secondary Transition',
      name: 'purposeSecondaryTransition'
    },
    {
      label: 'Functional Behavioral Assessment / Behavioral Intervention Plan',
      name: 'purposeFunctionalAssessment'
    },
    {
      label: 'Manifestation Determination Review',
      name: 'purposeManifestationReview'
    },
    {
      label: 'Graduation',
      name: 'purposeGraduation'
    },
    {
      label: '504 Plan',
      name: 'purpose504'
    },
    {
      label: 'Other',
      name: 'purposeOther'
    }
  ]

  const formInviteFields = [
    {
      label: 'Name and/or Title (General Education Teacher)',
      name: 'generalEducationTeacherName'
    },
    {
      label: 'Name and/or Title (Special Education Teacher)',
      name: 'specialEducationTeacherName'
    },
    {
      label: 'Name and/or Title (LEA Representative)',
      name: 'representativeLEAName'
    },
    {
      label: 'Name and/or Title',
      name: 'otherName'
    },
    {
      label: 'Name and/or Title',
      name: 'otherName2'
    },
    {
      label: 'Name and/or Title',
      name: 'otherName3'
    },
    {
      label: 'Name and/or Title',
      name: 'otherName4'
    },
    {
      label: 'Name and/or Title',
      name: 'otherName5'
    }
  ]

  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    const update = Object.assign({})
    switch (true) {
      case fieldName === 'meetingDateAndTime':
      case fieldName === 'formDate':
        update[fieldName] = moment(fieldValue)
        break
      case fieldName === 'tenDayWaiverAcceptedDate':
        update[fieldName] = moment(fieldValue)
        break
      case fieldName === 'tenDayWaiverAccepted':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        break
      case /purpose/.test(fieldName):
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        break
      default:
        update[fieldName] = fieldValue
    }
    return update
  }

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }

  const [requiresTenDayWaiver, setRequiresTenDayWaiver] = useState(false)
  const handleMeetingDateChange = date => {
    const meetingDate = new Date(date).getTime()
    const tenDaysFromToday = new Date().getTime() + 10 * 24 * 60 * 60 * 1000
    //? Should this be < or <=?
    //? clear check/initials/accepted date if waiver not required?
    const requiresWaiver = meetingDate <= tenDaysFromToday ? false : true
    setDateValue(date)
    setRequiresTenDayWaiver(requiresWaiver)
  }


  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          formDisabledState,
          canSave,
          handleActionEvents,
          validationProps
        }) => (
          <Form
            form={form}
            id='studentForm3457D'
            name='studentForm3457D'
            initialValues={data}
            preserve
            layout='vertical'
            className='page-padding-leftRight page-padding-topBottom form-labels-bold'
          >
            <Title level={4} className='center'>
              PARENT/GUARDIAN NOTIFICATION OF CONFERENCE
            </Title>
            <Form.Item
              label='Form Created Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
              />
            </Form.Item>

            <Card
              title='Student SIS Data - Access is restricted.  Please contact your administrator if changes are required.'
              style={{
                marginBottom: 30
              }}
            >
              {/*
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />
            */}
              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
              <StudentDobSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentDob}
                error={itemErrors.studentDob}
              />
              <StudentParent1SIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.parent1}
                error={itemErrors.parent1}
              />
              <StudentParent2SIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.parent2}
                error={itemErrors.parent2}
              />
            </Card>

            <Card
              title='Conference Information'
              style={{
                marginBottom: 30
              }}
            >
              <FormItem
                label='Meeting Date and Time'
                name='meetingDateAndTime'
                validateStatus={itemErrors.meetingDateAndTime ? 'error' : ''}
                help={itemErrors.meetingDateAndTime}
                {...validationProps('meetingDateAndTime')}
                trigger="onSelect"
              >
                <DatePicker
                  disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 20, 21, 22, 23]}
                  showTime={{
                    hideDisabledOptions: true,
                    use12Hours: true
                  }}
                  minuteStep={5}

                  format='YYYY-MM-DD / hh:mm'
                // {...handleFocusEvents}
                value={dateValue}
                // disabled={formDisabledState.meetingDateAndTime}
                onSelect={date => handleMeetingDateChange(date)}
                // onBlur={date => handleMeetingDateChange(date)}
                // onChange={date => handleMeetingDateChange(date)}
                />
              </FormItem>
              <FormItem
                label='Meeting Location'
                name='meetingLocation'
                validateStatus={itemErrors.meetingLocation ? 'error' : ''}
                help={itemErrors.meetingLocation}
                {...validationProps('meetingLocation')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.meetingLocation}
                />
              </FormItem>
              {requiresTenDayWaiver === false ? (
                <>
                  <Paragraph style={{ color: 'red' }}>
                    The meeting date you selected is within the required 10 day
                    notice period.
                  </Paragraph>
                  <Paragraph style={{ color: 'red' }}>
                    A parental waiver is required.
                  </Paragraph>
                </>
              ) : (
                <></>
              )}{' '}
              <FormItem
                label='Parent Waives Ten Day Notice'
                name='tenDayWaiverAccepted'
                validateStatus={itemErrors.tenDayWaiverAccepted ? 'error' : ''}
                help={itemErrors.tenDayWaiverAccepted}
                {...validationProps('tenDayWaiverAccepted')}
              >
                <Radio.Group
                  defaultValue
                  buttonStyle='solid'
                  {...handleMouseEvents}
                  disabled={formDisabledState.tenDayWaiverAccepted}
                  name='tenDayWaiverAccepted'
                >
                  <Radio.Button value={true}>Yes</Radio.Button>
                  <Radio.Button value={false}>No</Radio.Button>
                </Radio.Group>
              </FormItem>
              <FormItem
                label="Parent's Initials"
                name='tenDayWaiverInitials'
                validateStatus={itemErrors.tenDayWaiverInitials ? 'error' : ''}
                help={itemErrors.tenDayWaiverInitials}
                {...validationProps('tenDayWaiverInitials')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.tenDayWaiverInitials}
                />
              </FormItem>
              <Form.Item
                label='Date Waiver Accepted'
                name='tenDayWaiverAcceptedDate'
                {...validationProps('tenDayWaiverAcceptedDate')}
              >
                <DatePicker
                  {...handleFocusEvents}
                  disabled={formDisabledState.tenDayWaiverAcceptedDate}
                  format={[
                    'MM-DD-YYYY',
                    'MM/DD/YYYY',
                    'MM.DD.YY',
                    'M-D-YYYY',
                    'M/D/YYYY',
                    'M.D.YYYY',
                    'MM.DD.YYYY',
                    'MM-DD-YY',
                    'MM/DD/YY',
                    'M-D-YY',
                    'M/D/YY',
                    'M.D.YY',
                    'MMDDYYYY',
                    'MMDDYY'
                  ]}
                />
              </Form.Item>
            </Card>

            <Card
              title='Contact To Notify If Bringing Other Individuals'
              style={{
                marginBottom: 30
              }}
            >
              <FormItem
                label='Name and Contact Information'
                name='bringOtherIndividualContactInfo'
                validateStatus={
                  itemErrors.bringOtherIndividualContactInfo ? 'error' : ''
                }
                help={itemErrors.bringOtherIndividualContactInfo}
                {...validationProps('bringOtherIndividualContactInfo')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.bringOtherIndividualContactInfo}
                />
              </FormItem>
            </Card>

            <Card
              title='Contact To Request Written Translations'
              style={{
                marginBottom: 30
              }}
            >
              <FormItem
                label='Name and Contact Information'
                name='interpretationQuestionsContactInfo'
                validateStatus={
                  itemErrors.interpretationQuestionsContactInfo ? 'error' : ''
                }
                help={itemErrors.interpretationQuestionsContactInfo}
                {...validationProps('interpretationQuestionsContactInfo')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={
                    formDisabledState.interpretationQuestionsContactInfo
                  }
                />
              </FormItem>
            </Card>

            <Card
              title='Contact To Request An Interpreter Or Translated Documents'
              style={{
                marginBottom: 30
              }}
            >
              <FormItem
                label='Name'
                name='requestInterpreterContactName'
                validateStatus={
                  itemErrors.requestInterpreterContactName ? 'error' : ''
                }
                help={itemErrors.requestInterpreterContactName}
                {...validationProps('requestInterpreterContactName')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.requestInterpreterContactName}
                />
              </FormItem>
              <FormItem
                label='Title'
                name='requestInterpreterContactTitle'
                validateStatus={
                  itemErrors.requestInterpreterContactTitle ? 'error' : ''
                }
                help={itemErrors.requestInterpreterContactTitle}
                {...validationProps('requestInterpreterContactTitle')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.requestInterpreterContactTitle}
                />
              </FormItem>
              <FormItem
                label='Phone number'
                name='requestInterpreterContactTelephone'
                validateStatus={
                  itemErrors.requestInterpreterContactTelephone ? 'error' : ''
                }
                help={itemErrors.requestInterpreterContactTelephone}
                {...validationProps('requestInterpreterContactTelephone')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={
                    formDisabledState.requestInterpreterContactTelephone
                  }
                />
              </FormItem>
            </Card>

            <Card
              title='Contact To Change Meeting Arrangements'
              style={{
                marginBottom: 30
              }}
            >
              <FormItem
                label='Name'
                name='meetingArrangementsContactName'
                validateStatus={
                  itemErrors.meetingArrangementsContactName ? 'error' : ''
                }
                help={itemErrors.meetingArrangementsContactName}
                {...validationProps('meetingArrangementsContactName')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.meetingArrangementsContactName}
                />
              </FormItem>
              <FormItem
                label='Title'
                name='meetingArrangementsContactTitle'
                validateStatus={
                  itemErrors.meetingArrangementsContactTitle ? 'error' : ''
                }
                help={itemErrors.meetingArrangementsContactTitle}
                {...validationProps('meetingArrangementsContactTitle')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.meetingArrangementsContactTitle}
                />
              </FormItem>
              <FormItem
                label='Phone number'
                name='meetingArrangementsContactTelephone'
                validateStatus={
                  itemErrors.meetingArrangementsContactTelephone ? 'error' : ''
                }
                help={itemErrors.meetingArrangementsContactTelephone}
                {...validationProps('meetingArrangementsContactTelephone')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={
                    formDisabledState.meetingArrangementsContactTelephone
                  }
                />
              </FormItem>
            </Card>

            {/*}
            <Card
              title='Purpose of conference - horizontal - just testing a "flatter" card layout'
              style={{
                marginBottom: 30,
              }}
            >
              {formPurposeFields.map(({ name, label }, index) => (
              <Row style={{ marginBottom: 0, marginTop: 0, height: '45px' }}>
                <Col span={3}>
                  <Form.Item
                    name={name}
                    style={{marginBottom: 0}}
                  >
                    <Radio.Group
                      defaultValue
                      buttonStyle='solid'
                      {...handleMouseEvents}
                      disabled={formDisabledState[name]}
                      name={name}
                      //size="small"
                    >
                      <Radio.Button value>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                      </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={20}>
                  <Form.Item 
                    label={label}
                    labelAlign='left'
                  >
                  </Form.Item>
                </Col>
              </Row>

              ))}
            </Card>
            */}

            <Card
              title='Purpose of conference'
              style={{
                marginBottom: 30
              }}
            >
              {formPurposeFields.map(({ name, label }, index) => (
                <FormItem
                  key={index}
                  label={label}
                  name={name}
                  validateStatus={itemErrors[name] ? 'error' : ''}
                  help={itemErrors[name]}
                  {...validationProps(name)}
                  layout='horizontal'
                >
                  <Radio.Group
                    defaultValue
                    buttonStyle='solid'
                    {...handleMouseEvents}
                    disabled={formDisabledState[name]}
                    name={name}
                    //size="small"
                  >
                    <Radio.Button value>Yes</Radio.Button>
                    <Radio.Button value={false}>No</Radio.Button>
                  </Radio.Group>
                </FormItem>
              ))}
            </Card>

            <Card
              title='Conference attendants'
              style={{
                marginBottom: 30
              }}
            >
              {formInviteFields.map(({ label, name }, index) => (
                <FormItem
                  key={index}
                  label={label}
                  name={name}
                  validateStatus={itemErrors[name] ? 'error' : ''}
                  help={itemErrors[name]}
                  {...validationProps(name)}
                >
                  <Input
                    {...handleFocusEvents}
                    disabled={formDisabledState[name]}
                  />
                </FormItem>
              ))}
            </Card>

            <Card
              title='Contact Information'
              style={{
                marginBottom: 30
              }}
            >
              <FormItem
                label='Name'
                name='iepName'
                validateStatus={itemErrors.iepName ? 'error' : ''}
                help={itemErrors.iepName}
                {...validationProps('iepName')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.iepName}
                />
              </FormItem>
              <FormItem
                label='Title'
                name='iepTitle'
                validateStatus={itemErrors.iepTitle ? 'error' : ''}
                help={itemErrors.iepTitle}
                {...validationProps('iepTitle')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.iepTitle}
                />
              </FormItem>
              <FormItem
                label='Phone number'
                name='iepTelephone'
                validateStatus={itemErrors.iepTelephone ? 'error' : ''}
                help={itemErrors.iepTelephone}
                {...validationProps('iepTelephone')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.iepTelephone}
                />
              </FormItem>
            </Card>

            <Card
              title='Signee Information'
              style={{
                marginBottom: 30
              }}
            >
              <FormItem
                label='Name'
                name='signatureName'
                validateStatus={itemErrors.signatureName ? 'error' : ''}
                help={itemErrors.signatureName}
                {...validationProps('signatureName')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.signatureName}
                />
              </FormItem>
              <FormItem
                label='Title'
                name='signatureTitle'
                validateStatus={itemErrors.signatureTitle ? 'error' : ''}
                help={itemErrors.signatureTitle}
                {...validationProps('signatureTitle')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.signatureTitle}
                />
              </FormItem>
            </Card>

            {formError && <Paragraph type='danger'>{formError}</Paragraph>}
            <StudentFormActions
              query={PRINT_FORM3457D}
              extractDataProp='printStudentForm3457D'
              canSave={canSave}
              handleActionEvents={handleActionEvents}

              getFormFieldValues={getFormFieldValues}
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired
}

const StudentForm3457D = () => {
  // get current form information
  const {
    currentStudentInfo,
    currentEditableForm,
    studentMeetingId,
    meetingDate
  } = useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId } = currentEditableForm

  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    QUERY_STUDENT_COMMON_SIS,
    { variables: { id: studentId } }
  )
  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORM3457D_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )

  const createInitialFormData = (studentInfo, versionInfo) => {
    let initialValues = {}

    if (!studentInfo || !versionInfo) return initialValues

    const { studentForm3457D: form } = versionInfo
    const { student } = studentInfo
    const { parent1FullName, parent2FullName, dateOfBirth } = student
    const {
      formCreationDate,
      studentFullName,
      studentDob,
      parent1,
      parent2,
      meetingDateAndTime,
      tenDayWaiverAccepted,
      tenDayWaiverInitials,
      tenDayWaiverAcceptedDate,
      otherNames
    } = form

    initialValues = {
      ...form,
      formDate: formCreationDate
        ? moment(formCreationDate)
        : moment(Date.now()),
      studentFullName: studentFullName || student.fullName || '',
      studentDob: studentDob
        ? moment(studentDob).format('YYYY-MM-DD')
        : dateOfBirth
        ? moment(dateOfBirth).format('YYYY-MM-DD')
        : '',
      parent1: parent1 || parent1FullName || '',
      parent2: parent2 || parent2FullName || '',
      meetingDateAndTime: meetingDateAndTime
        ? moment(meetingDateAndTime)
        : moment(meetingDate),
      //tenDayWaiverAccepted: tenDayWaiverAccepted || false,
      tenDayWaiverInitials: tenDayWaiverInitials || '',
      tenDayWaiverAcceptedDate: tenDayWaiverAcceptedDate
        ? moment(tenDayWaiverAcceptedDate)
        : moment(meetingDate),
      otherName: otherNames[0] || '',
      otherName2: otherNames[1] || '',
      otherName3: otherNames[2] || '',
      otherName4: otherNames[3] || '',
      otherName5: otherNames[4] || ''
    }

    return initialValues
  }

  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
          />
        }
      />
    </FormErrorProvider>
  )
}

export default StudentForm3457D
