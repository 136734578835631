import React from "react";
import PropTypes from "prop-types";

// components
import SetPasswordForm from "../../components/sections/login/SetPasswordForm";

const SetPassword = (props) => {
  const { mode = "reset" } = props;
  return <SetPasswordForm mode={mode} />;
};

SetPassword.propTypes = {
  mode: PropTypes.oneOf(["reset", "invite"]),
};

export default SetPassword;
