import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { IepFormContext } from '../../../../utils/iepFormProvider'
import { useQuery } from '@apollo/client'
import { QUERY_STUDENT_COMMON_SIS } from '../../../../operations/queries/forms/SIS'
import { QUERY_FORM3457I_VERSION } from '../../../../operations/queries/forms/3457I'
import moment from 'moment'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import FormContainer from '../../../FormContainer'
import { Alert, Card, DatePicker, Form, Input, Radio, Typography } from 'antd'
import FormCollaboration from '../../../FormCollaboration'
import Title from 'antd/lib/typography/Title'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import StudentDobSIS from '../sis/StudentDobSIS'
import StudentParent1SIS from '../sis/StudentParent1SIS'
import StudentParent2SIS from '../sis/StudentParent2SIS'
import FormItem from 'antd/lib/form/FormItem'
import StudentFormActions from '../StudentFormActions'
import { PRINT_FORM3457I } from '../../../../operations/mutations/forms/3457I'

const { Paragraph } = Typography

const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId
}) => {
  const [form] = Form.useForm()
  const { itemErrors, formError } = useContext(FormErrorContext)

  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()
    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      studentMeetingId: studentMeetingId
    }
  }

  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    const update = Object.assign({})
    switch (fieldName) {
      case 'formDate':
      case 'ageOfMajorityDate':
        update[fieldName] = fieldValue ? moment(fieldValue) : ''
        break
      case 'noticeTypeAnticipated':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        setNoticeTypeAnticipated(update[fieldName])
        break
      case 'noticeTypeTransfered':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        setNoticeTypeTransfered(update[fieldName])
        break
      default:
        update[fieldName] = fieldValue
    }
    return update
  }

  const [noticeTypeAnticipated, setNoticeTypeAnticipated] = useState(
    data?.noticeTypeAnticipated
  )
  const [noticeTypeTransfered, setNoticeTypeTransfered] = useState(
    data?.noticeTypeTransfered
  )

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }

  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          formDisabledState,
          canSave,
          handleActionEvents,
          validationProps
        }) => (
          <Form
            form={form}
            id='studentForm3457I'
            name='studentForm3457I'
            initialValues={data}
            preserve
            layout='vertical'
            className='page-padding-leftRight page-padding-topBottom form-labels-bold'
          >
            <Title level={4} className='center'>
              NOTIFICATION OF TRANSFER OF RIGHTS DUE TO AGE OF MAJORITY
            </Title>
            <Form.Item
              label='Form Created Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
              />
            </Form.Item>

            <Card
              title='Student SIS Data'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />
              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
              <StudentDobSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentDob}
                error={itemErrors.studentDob}
              />
              <StudentParent1SIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.parent1}
                error={itemErrors.parent1}
              />
              <StudentParent2SIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.parent2}
                error={itemErrors.parent2}
              />
            </Card>

            <Card
              style={{
                marginBottom: 30
              }}
            >
              <Form.Item
                label='Date of age majority'
                name='ageOfMajorityDate'
                {...validationProps('ageOfMajorityDate')}
              >
                <DatePicker
                  {...handleFocusEvents}
                  disabled={formDisabledState.ageOfMajorityDate}
                  format={[
                    'MM-DD-YYYY',
                    'MM/DD/YYYY',
                    'MM.DD.YY',
                    'M-D-YYYY',
                    'M/D/YYYY',
                    'M.D.YYYY',
                    'MM.DD.YYYY',
                    'MM-DD-YY',
                    'MM/DD/YY',
                    'M-D-YY',
                    'M/D/YY',
                    'M.D.YY',
                    'MMDDYYYY',
                    'MMDDYY'
                  ]}
                />
              </Form.Item>

              <h1>
                <strong>Check One:</strong>
              </h1>
              {!noticeTypeTransfered && (
                <FormItem
                  label='This serves as your one (1) year prior notice of the anticipated transfer of educational rights to the above named student under IDEA'
                  name='noticeTypeAnticipated'
                  {...validationProps('noticeTypeAnticipated')}
                >
                  <Radio.Group
                    {...handleMouseEvents}
                    buttonStyle='solid'
                    disabled={formDisabledState.noticeTypeAnticipated}
                    name='noticeTypeAnticipated'
                    onChange={e => {
                      setNoticeTypeAnticipated(e.target.value)
                      handleMouseEvents.onChange(e)
                    }}
                  >
                    <Radio.Button value>Yes</Radio.Button>
                    <Radio.Button value={false}>No</Radio.Button>
                  </Radio.Group>
                </FormItem>
              )}
              {!noticeTypeAnticipated && (
                <FormItem
                  label='This serves as your notice that all educational rights under IDEA have been transferred to the above named student.'
                  name='noticeTypeTransfered'
                  {...validationProps('noticeTypeTransfered')}
                >
                  <Radio.Group
                    {...handleMouseEvents}
                    buttonStyle='solid'
                    disabled={formDisabledState.noticeTypeTransfered}
                    name='noticeTypeTransfered'
                    onChange={e => {
                      setNoticeTypeTransfered(e.target.value)
                      handleMouseEvents.onChange(e)
                    }}
                  >
                    <Radio.Button value>Yes</Radio.Button>
                    <Radio.Button value={false}>No</Radio.Button>
                  </Radio.Group>
                </FormItem>
              )}
            </Card>

            <Card
              title='Contact Information'
              style={{
                marginBottom: 30
              }}
            >
              <Form.Item
                label='Name'
                name='documentContactName'
                {...validationProps('documentContactName')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.documentContactName}
                />
              </Form.Item>
              <Form.Item
                label='Title'
                name='documentContactTitle'
                {...validationProps('documentContactTitle')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.documentContactTitle}
                />
              </Form.Item>
              <Form.Item
                label='Phone'
                name='documentContactPhone'
                {...validationProps('documentContactPhone')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.documentContactPhone}
                />
              </Form.Item>
            </Card>

            <Card
              title='Signee'
              style={{
                marginBottom: 30
              }}
            >
              <Form.Item
                label='Name'
                name='signatureName'
                {...validationProps('signatureName')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.signatureName}
                />
              </Form.Item>
              <Form.Item
                label='Title'
                name='signatureTitle'
                {...validationProps('signatureTitle')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.signatureTitle}
                />
              </Form.Item>
            </Card>

            {formError && <Paragraph type='danger'>{formError}</Paragraph>}
            <StudentFormActions
              query={PRINT_FORM3457I}
              extractDataProp='printStudentForm3457I'
              canSave={canSave}
              handleActionEvents={handleActionEvents}

              getFormFieldValues={getFormFieldValues}
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired
}

const StudentForm3457I = () => {
  // get current form information
  const { currentStudentInfo, currentEditableForm, studentMeetingId } =
    useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId } = currentEditableForm

  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    QUERY_STUDENT_COMMON_SIS,
    { variables: { id: studentId } }
  )
  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORM3457I_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )

  const createInitialFormData = (studentInfo, versionInfo) => {
    let initialValues = {}

    if (!studentInfo || !versionInfo) return initialValues

    const { studentForm3457I: form } = versionInfo
    const { student } = studentInfo
    const { parent1FullName, parent2FullName, dateOfBirth } = student
    const {
      formDate,
      studentFullName,
      studentDob,
      parent1,
      parent2,
      ageOfMajorityDate
    } = form

    initialValues = {
      ...form,
      formDate: formDate ? moment(formDate) : moment(Date.now()),
      ageOfMajorityDate: ageOfMajorityDate
        ? moment(ageOfMajorityDate)
        : moment(Date.now()),
      studentFullName: studentFullName || student.fullName || '',
      studentDob: studentDob
        ? moment(studentDob).format('YYYY-MM-DD')
        : dateOfBirth
        ? moment(dateOfBirth).format('YYYY-MM-DD')
        : '',
      parent1: parent1 || parent1FullName || '',
      parent2: parent2 || parent2FullName || ''
    }

    return initialValues
  }
  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
          />
        }
      />
    </FormErrorProvider>
  )
}
export default StudentForm3457I
