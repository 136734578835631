import { gql } from "@apollo/client";

export const INSERT_LOOKUP = gql`
  mutation insertLookup(
    $value: String
    $defaultFormCodes: [String]
    $districtId: ID
    $typeId: ID
    $canBeIep: Boolean
    $hidden: Boolean
  ) {
    insertLookup(
      lookupInput: {
        districtId: $districtId
        typeId: $typeId
        value: $value
        defaultFormCodes: $defaultFormCodes
        canBeIep: $canBeIep
        hidden: $hidden
      }
    ) {
      id
      typeId
      type {
        name
        id
      }
      districtId
      value
      defaultFormCodes
      canBeIep
      hidden
      isGlobal
    }
  }
`;

export const UPDATE_LOOKUP = gql`
  mutation updateLookup(
    $id: ID!
    $value: String
    $defaultFormCodes: [String]
    $canBeIep: Boolean
    $districtId: ID
    $typeId: ID
    $hidden: Boolean
  ) {
    updateLookup(
      id: $id
      lookupInput: {
        districtId: $districtId
        typeId: $typeId
        value: $value
        defaultFormCodes: $defaultFormCodes
        canBeIep: $canBeIep
        hidden: $hidden
      }
    ) {
      id
      typeId
      type {
        name
        id
      }
      districtId
      value
      defaultFormCodes
      canBeIep
      hidden
      isGlobal
    }
  }
`;
