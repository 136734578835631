function ApplicationError(message, metadata) {
  const error = new Error(message);
  error.message = message;
  error.metadata = metadata;
  if (metadata.code) {
    error.code = metadata.code;
  }
  return error;
}

ApplicationError.prototype = Object.create(Error.prototype);

export { ApplicationError };
