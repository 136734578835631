import React from "react";
import { useParams } from "react-router-dom";

import Page from "../../components/Page";
import LookupForm from "../../components/sections/lookup/LookupForm";

const Lookup = () => {
  const { id } = useParams();
  const title = id ? "Edit Lookup" : "Add Lookup";

  const breadcrumb = [
    {
      path: "/",
      breadcrumbName: "Home",
    },
    {
      path: "/lookups",
      breadcrumbName: "Lookups",
    },
    {
      path: "",
      breadcrumbName: title,
    },
  ];
  return (
    <>
      <Page title={title} breadcrumb={breadcrumb}>
        <div className="page-container">
          <div className="page-padding-leftRight page-padding-topBottom">
            <LookupForm />
          </div>
        </div>
      </Page>
    </>
  );
};

export default Lookup;
