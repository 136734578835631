import React from "react";
import PropTypes from "prop-types";

// ant design
import { Form, Select } from "antd";
import TextNameAuthEvaluated from "../common/TextNameAuthEvaluated";
import { gender } from "../../../utils/constants";

const FormItemSelectGender = ({
  label,
  name,
  validateStatus,
  help,
  hasFeedback,
  mode,
}) => {
  return (
    <Form.Item
      label={label}
      name={name}
      validateStatus={validateStatus}
      help={help}
      hasFeedback={hasFeedback}
    >
      <Select
        mode={mode}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) =>
          optionA.children
            .toString().toLowerCase()
            .localeCompare(optionB.children.toString().toLowerCase())
        }
      >
        {gender &&
          gender.map((e) => (
            <Select.Option key={e} value={e}>
              {e}
              {/* <TextNameAuthEvaluated entity={e} /> */}
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  );
};

FormItemSelectGender.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  help: PropTypes.string,
  validateStatus: PropTypes.string,
  hasFeedback: PropTypes.bool,
  mode: PropTypes.string,
};

export default FormItemSelectGender;
