import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Modal } from 'antd'
let actuallySaved = false

const NavigationPrompt = ({ saved }) => {
  // state stuff for navigation warning
  const [warning, setWarning] = useState(false)
  const [url, setUrl] = useState()
  const [newUrl, setNewUrl] = useState()
  const [visible, setVisible] = useState(false)

  // comes back as undefined due to bad prop passing so capturing it here with a standard variable
  if (saved === true) {
    actuallySaved = true
  }

  // router definitions
  const location = useLocation()
  const history = useNavigate()
  // checks when path changes and does some comparisons
  // useEffect(() => {
  //   if (location.pathname.includes('student-form')) {
  //     setWarning(true)
  //     setUrl(location.pathname)
  //   }
  //   // prompt user for navigation
  //   if (
  //     warning === true &&
  //     location.pathname !== url &&
  //     actuallySaved === false
  //   ) {
  //     setNewUrl(location.pathname)
  //     history(url)
  //     setVisible(true)
  //   }
  //   // reset actually saved on page load (when user returns to a meeting)
  //   actuallySaved = false
  // }, [location])

  // stay on student-form (aka student meeting this should be renamed)
  const handleOk = () => {
    history(newUrl)
    setWarning(false)
    setVisible(false)
    actuallySaved = false
  }
  // leave meeting
  const handleCancel = () => {
    setVisible(false)
    history(url)
  }

  // navigation alert modal
  const NavigationAlert = () => {
    return (
      <Modal
        title='Are you sure you want to leave this meeting?'
        visible={visible}
        onOk={handleOk}
        cancelText='No'
        onCancel={handleCancel}
        okText='Yes'
      >
        You may have unsaved changes.
      </Modal>
    )
  }
  return <>{/* <NavigationAlert /> */}</>
}

export default NavigationPrompt
