import { gql } from '@apollo/client'

export const PRINT_FORMIAP = gql`
  mutation printStudentFormIAP(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $studentDob: String
    $studentAge: String
    $parent1FullName: String
    $parent2FullName: String
    $parent1Phone: String
    $parent2Phone: String
    $parent1Email: String
    $parent2Email: String
    $areasOfStrength: String
    $areasOfImprovement: String
    $instructional: String
    $environmental: String
    $assessment: String
    $backgroundInformation: String
    $scheduleDate1a: String
    $scheduleDate1b: String
    $scheduleDate2a: String
    $scheduleDate2b: String
    $scheduleDate3a: String
    $scheduleDate3b: String
    $scheduleDate4a: String
    $scheduleDate4b: String
    $scheduleDate5a: String
    $scheduleDate5b: String
    $scheduleDate6a: String
    $scheduleDate6b: String
    $scheduleDate7a: String
    $scheduleDate7b: String
    $scheduleDate8a: String
    $scheduleDate8b: String
    $parentSignatureName: String
    $parentSignatureDate: String
    $formAction: String
    $formStatus: String

  ) {
    printStudentFormIAP(
      studentFormIAPInput: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        studentDob: $studentDob
        studentAge: $studentAge
        parent1FullName: $parent1FullName
        parent2FullName: $parent2FullName
        parent1Phone: $parent1Phone
        parent2Phone: $parent2Phone
        parent1Email: $parent1Email
        parent2Email: $parent2Email
        areasOfStrength: $areasOfStrength
        areasOfImprovement: $areasOfImprovement
        instructional: $instructional
        environmental: $environmental
        assessment: $assessment
        backgroundInformation: $backgroundInformation
        scheduleDate1a: $scheduleDate1a
        scheduleDate1b: $scheduleDate1b
        scheduleDate2a: $scheduleDate2a
        scheduleDate2b: $scheduleDate2b
        scheduleDate3a: $scheduleDate3a
        scheduleDate3b: $scheduleDate3b
        scheduleDate4a: $scheduleDate4a
        scheduleDate4b: $scheduleDate4b
        scheduleDate5a: $scheduleDate5a
        scheduleDate5b: $scheduleDate5b
        scheduleDate6a: $scheduleDate6a
        scheduleDate6b: $scheduleDate6b
        scheduleDate7a: $scheduleDate7a
        scheduleDate7b: $scheduleDate7b
        scheduleDate8a: $scheduleDate8a
        scheduleDate8b: $scheduleDate8b
        parentSignatureName: $parentSignatureName
        parentSignatureDate: $parentSignatureDate
formAction: $formAction
formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`
