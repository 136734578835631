import React from "react";

import Page from "../../components/Page";
import SchoolsList from "../../components/sections/school/SchoolsList";

const Schools = () => {
  const breadcrumb = [
    {
      path: "/",
      breadcrumbName: "Home",
    },
    {
      path: "",
      breadcrumbName: "Schools",
    },
  ];
  return (
    <>
      <Page title="Schools" breadcrumb={breadcrumb}>
        <SchoolsList />
      </Page>
    </>
  );
};

export default Schools;
