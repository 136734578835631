// react
import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'

// Contexts
import AuthContext from '../../../utils/authProvider'

// ant design
import { Card, Button, Tag, Tooltip } from 'antd'

// operations
import { QUERY_GRADES } from '../../../operations/queries/grades'

// components
import GradeSearchForm from './GradeSearchForm'
import Listing from '../../Listing'

const GradeList = () => {
  const Auth = useContext(AuthContext)
  const isSuperAdmin = Auth.hasRole('SUPER_ADMIN')
  const [criterias, setCriterias] = useState({})
  const canAddGrades = Auth.hasPermission('ADD_GRADES')
  const extra = (
    <>
      {canAddGrades && (
        <Tooltip title='Add new grade'>
          <Link to='../grade/add'>
            <Button type='primary'>Add Grade</Button>
          </Link>
        </Tooltip>
      )}
    </>
  )

  const onSearch = criterias => {
    setCriterias(criterias)
  }
  const canEditGrades = Auth.hasPermission('EDIT_GRADES')
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (value, record) => (
        <div>
          <div>
            {canEditGrades && !record.isGlobal ? (
              <Link to={`../grade/${record.id}`}>{value}</Link>
            ) : (
              <>{value}</>
            )}
            &nbsp;
            {isSuperAdmin && record.hidden && <Tag color='orange'>Hidden</Tag>}
          </div>
          {isSuperAdmin && <div className='cellWithId'>{record.id}</div>}
        </div>
      )
    }
  ]

  return (
    <>
      <Listing
        query={QUERY_GRADES}
        columns={columns}
        dataKey='grades'
        criterias={criterias}
        render={table => {
          return (
            <>
              <div className='page-container'>
                <Card title='Search' bordered={false}>
                  <GradeSearchForm onSearch={onSearch} />
                </Card>
              </div>
              <div className='page-container'>
                <Card title='Grades' bordered={false} extra={extra}>
                  {table}
                </Card>
              </div>
            </>
          )
        }}
      />
    </>
  )
}

export default GradeList
