import { gql } from "@apollo/client";

export const PRINT_FORM3457F = gql`
  mutation printStudentForm3457F(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $studentDob: String
    $parent1: String
    $parent2: String
    $consent: String
    $receivedCopy: Boolean
    $receivedIepEligibilitySummary: Boolean
    $receivedEducationProgram: Boolean
    $receivedOther: Boolean
    $otherName: String
    $parentSignatureDate: String
    $acceptOptionToDelayService: String
    $documentContactName: String
    $documentContactTitle: String
    $documentContactPhone: String
    $signatureName: String
    $signatureTitle: String
    $formAction: String
$formStatus: String
  ) {
    printStudentForm3457F(
      studentForm3457FInput: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        studentDob: $studentDob
        parent1: $parent1
        parent2: $parent2
        consent: $consent
        receivedCopy: $receivedCopy
        receivedIepEligibilitySummary: $receivedIepEligibilitySummary
        receivedEducationProgram: $receivedEducationProgram
        receivedOther: $receivedOther
        otherName: $otherName
        parentSignatureDate: $parentSignatureDate
        acceptOptionToDelayService: $acceptOptionToDelayService
        documentContactName: $documentContactName
        documentContactTitle: $documentContactTitle
        documentContactPhone: $documentContactPhone
        signatureName: $signatureName
        signatureTitle: $signatureTitle
formAction: $formAction
formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;
