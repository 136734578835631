import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { ThemeSwitcherProvider } from 'react-css-theme-switcher'
import { stupidThemeHack } from './utils/appProvider'
import * as moment from 'moment-timezone'

const themes = stupidThemeHack.includes('https://tamesassist.com')
  ? {
      dark: 'https://tamesassist.com/dark-theme.css',
      light: 'https://tamesassist.com/light-theme.css'
    }
  : stupidThemeHack.includes('https://app-dev.tamesassist.com')
  ? {
      dark: 'https://app-dev.tamesassist.com/dark-theme.css',
      light: 'https://app-dev.tamesassist.com/light-theme.css'
    }
  : stupidThemeHack.includes('http://localhost:3000')
  ? {
      dark: 'http://localhost:3000/dark-theme.css',
      light: 'http://localhost:3000/light-theme.css'
    }
  : {}
// NOTE: Change timezone to UTC
moment.tz.setDefault('Etc/UTC')
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeSwitcherProvider themeMap={themes} defaultTheme='light'>
        <App />
      </ThemeSwitcherProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)

