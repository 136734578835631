import { gql } from "@apollo/client";

export const REMOVE_STUDENT_FROM_DESKTOP = gql`
  mutation RemoveStudentFromMyDesktop($studentId: ID!) {
    removeStudentFromMyDesktop(studentId: $studentId) {
      desktopStudents {
        fullName
      }
    }
  }
`;

export const ADD_STUDENT_TO_DESKTOP = gql`
  mutation AddStudentToMyDesktop($studentId: ID!) {
    addStudentToMyDesktop(studentId: $studentId) {
      desktopStudents {
        fullName
      }
    }
  }
`;

export const ADD_FORMS_TO_MEETING = gql`
  mutation AddFormsToStudentMeeting(
    $studentMeetingId: ID!
    $formIdsToAdd: [ID!]
  ) {
    addFormsToStudentMeeting(
      studentMeetingId: $studentMeetingId
      formIdsToAdd: $formIdsToAdd
    ) {
      studentFormIds
    }
  }
`;

export const REMOVE_FORM_FROM_MEETING = gql`
  mutation RemoveFormFromStudentMeeting(
    $studentMeetingId: ID! 
    $studentFormId: ID!
  ) {
    removeFormFromStudentMeeting(
      studentMeetingId: $studentMeetingId
      studentFormId: $studentFormId
    ) {
      studentFormIds
    }
  }
`;

export const UPDATE_STUDENT_FORM_STATUS = gql`
  mutation UpdateStudentFormStatus(
    $studentFormId: ID!
    $studentMeetingId: ID!
    $newStatus: String!
  ) {
    updateStudentFormStatus(
      studentFormId: $studentFormId
      studentMeetingId: $studentMeetingId
      newStatus: $newStatus
    ) {
      display
    }
  }
`;

export const INSERT_MEETING = gql`
  mutation insertStudentMeetingGeneral(
    $studentMeetingGeneralInput: StudentMeetingGeneralInput!
  ) {
    insertStudentMeetingGeneral(
      studentMeetingGeneralInput: $studentMeetingGeneralInput
    ) {
      id
    }
  }
`;

export const UPDATE_STUDENT_MEETING_GENERAL = gql`
  mutation updateStudentMeetingGeneral(
    $id: ID!
    $schoolId: ID!
    $studentId: ID!
    $studentFormIds: [ID]
    $meetingType: String
    $meetingDate: String
    $isActiveIep: Boolean
    $iepMeetingId: ID
  ) {
    updateStudentMeetingGeneral(
      id: $id
      studentMeetingGeneralInput: {
        schoolId: $schoolId
        studentId: $studentId
        studentFormIds: $studentFormIds
        meetingType: $meetingType
        meetingDate: $meetingDate
        isActiveIep: $isActiveIep
        iepMeetingId: $iepMeetingId
      }
    ) {
      id
      schoolId
      studentId
      studentFormIds
      meetingType
      meetingDate
      isActiveIep
      iepMeetingId
      parentNotifications{
        parentName
        contactMethod
        date
      }
    }
  }
`;

export const UPDATE_STUDENT_MEETING_META = gql`
  mutation updateStudentMeetingMeta(
    $studentMeetingId: ID!
    $schoolId: ID!
    $studentId: ID!
    $metaInput: MetaInput
  ) {
    updateStudentMeetingMeta(
      id: $studentMeetingId
      studentMeetingMetaInput: {
        schoolId: $schoolId
        studentId: $studentId
        meta: $metaInput
      }
    ) {
      id
      meta {
        caseManager
        servingDistrict
      }
    }
  }
`;

export const UPDATE_STUDENT_MEETING_DATE = gql`
  mutation updateStudentMeetingDate(
    $studentMeetingId: ID!
    $meetingDate: String
  ) {
    updateStudentMeetingDate(
      id: $studentMeetingId
      meetingDate: $meetingDate
    ) {
      id
      meetingDate
    }
  }
`;

export const UPDATE_STUDENT_MEETING_MEETING_TYPE = gql`
  mutation updateStudentMeetingMeetingType(
    $studentMeetingId: ID!
    $meetingType: String
  ) {
    updateStudentMeetingMeetingType(
      id: $studentMeetingId
      meetingType: $meetingType
    ) {
      id
      meetingType
    }
  }
`;

export const UPDATE_STUDENT_MEETING_ISACTIVEIEP = gql`
  mutation updateStudentMeetingIsActiveIep(
    $studentMeetingId: ID!
    $isActiveIep: Boolean
  ) {
    updateStudentMeetingIsActiveIep(
      id: $studentMeetingId
      isActiveIep: $isActiveIep
    ) {
      id
      isActiveIep
    }
  }
`;

export const INSERT_STUDENT_MEETING_PARENT_NOTIFICATION = gql`
  mutation insertStudentMeetingParentNotification(
    $studentMeetingId: ID!
    $parentNotification: ParentNotificationInput
  ) {
    insertStudentMeetingParentNotification(
      id: $studentMeetingId
      parentNotification: $parentNotification
    ) {
      id
      parentNotifications {
        id
        parentName
        contactMethod
        date
      }
    }
  }
`;

export const UPDATE_STUDENT_MEETING_PARENT_NOTIFICATION = gql`
  mutation updateStudentMeetingParentNotification(
    $studentMeetingId: ID!
    $parentNotification: ParentNotificationInput
  ) {
    updateStudentMeetingParentNotification(
      id: $studentMeetingId
      parentNotification: $parentNotification
    ) {
      id
      parentNotifications {
        id
        parentName
        contactMethod
        date
      }
    }
  }
`;

export const DELETE_STUDENT_MEETING_PARENT_NOTIFICATION = gql`
  mutation deleteStudentMeetingParentNotification(
    $studentMeetingId: ID!
    $parentNotification: ParentNotificationInput
  ) {
    deleteStudentMeetingParentNotification(
      id: $studentMeetingId
      parentNotification: $parentNotification
    ) {
      id
      parentNotifications {
        id
        parentName
        contactMethod
        date
      }
    }
  }
`;

export const UPDATE_STUDENT_MEETING_PARENT_NOTIFICATIONS = gql`
  mutation updateStudentMeetingParentNotifications(
    $studentMeetingId: ID!
    $parentNotifications: [ParentNotificationInput]
  ) {
    updateStudentMeetingParentNotifications(
      id: $studentMeetingId
      parentNotifications: $parentNotifications
    ) {
      id
      parentNotifications {
        id
        parentName
        contactMethod
        date
      }
    }
  }
`;

export const INSERT_STUDENT_MEETING_UPLOADED_DOC = gql`
  mutation insertStudentMeetingUploadedDoc(
    $id: ID!
    $uploadedDoc: UploadedDocInput
  ) {
    insertStudentMeetingUploadedDoc(
      id: $id
      uploadedDoc: $uploadedDoc
    ) {
      id
      uploadedDocs {
        id
        name
        contentType
        uniqueName
        url
        docDate
        createdAt
      }
    }
  }
`;

export const DELETE_STUDENT_MEETING_UPLOADED_DOC = gql`
  mutation deleteStudentMeetingUploadedDoc(
    $id: ID!
    $uploadedDocId: ID!
  ) {
    deleteStudentMeetingUploadedDoc(
      id: $id
      uploadedDocId: $uploadedDocId
    ) {
      id
      uploadedDocs {
        id
        name
        contentType
        uniqueName
        url
        docDate
        createdAt
      }
    }
  }
`;



export const PRINT_ALL_STUDENT_MEETING_FORMS = gql`
  mutation printAllStudentMeetingForms(
    $studentMeetingId: ID!
    $studentFormIds: [ID]!
  ) {
    printAllStudentMeetingForms(
      studentMeetingId: $studentMeetingId
      studentFormIds: $studentFormIds
    ) {
      link
      fileName
    }
  }
`;
