import React from "react";
import { useParams } from "react-router-dom";

import Page from "../../components/Page";
import UserForm from "../../components/sections/user/UserForm";

const User = () => {
  const { id } = useParams();
  const title = id ? "Edit user" : "Add user";

  const breadcrumb = [
    {
      path: "/",
      breadcrumbName: "Home",
    },
    {
      path: "/users",
      breadcrumbName: "Users",
    },
    {
      path: "",
      breadcrumbName: title,
    },
  ];
  return (
    <>
      <Page title={title} breadcrumb={breadcrumb}>
        <div className="page-container">
          <div className="page-padding-leftRight page-padding-topBottom">
            <UserForm />
          </div>
        </div>
      </Page>
    </>
  );
};

export default User;
