import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { IepFormContext } from '../../../../utils/iepFormProvider'
import { useQuery } from '@apollo/client'
import { QUERY_STUDENT_COMMON_SIS } from '../../../../operations/queries/forms/SIS'
import { QUERY_FORM3457G_VERSION } from '../../../../operations/queries/forms/3457G'
import moment from 'moment'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import FormContainer from '../../../FormContainer'
import { Alert, Card, DatePicker, Form, Input, Typography } from 'antd'
import FormCollaboration from '../../../FormCollaboration'
import Title from 'antd/lib/typography/Title'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import StudentDobSIS from '../sis/StudentDobSIS'
import StudentParent1SIS from '../sis/StudentParent1SIS'
import StudentParent2SIS from '../sis/StudentParent2SIS'
import FormItem from 'antd/lib/form/FormItem'
import StudentFormActions from '../StudentFormActions'
import { PRINT_FORM3457G } from '../../../../operations/mutations/forms/3457G'
import Editor from '../../../elements/common/Editor'
import Checkbox from 'antd/lib/checkbox/Checkbox'

const { Paragraph } = Typography

const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId
}) => {
  const [form] = Form.useForm()
  const { itemErrors, formError } = useContext(FormErrorContext)

  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()
    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      studentMeetingId: studentMeetingId
    }
  }

  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    const update = Object.assign({})
    switch (fieldName) {
      case 'communicationMode':
        update[fieldName] = fieldValue.split(',')
        break
      case 'beginChangesDate':
      case 'formDate':
      case 'dateOfContact':
        update[fieldName] = moment(fieldValue)
        break
      default:
        update[fieldName] = fieldValue
    }
    return update
  }

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }

  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          formDisabledState,
          canSave,
          handleActionEvents,
          validationProps
        }) => (
          <Form
            form={form}
            id='studentForm3457G'
            name='studentForm3457G'
            initialValues={data}
            preserve
            layout='vertical'
            className='page-padding-leftRight page-padding-topBottom form-labels-bold'
          >
            <Title level={4} className='center'>
              PARENT/GUARDIAN NOTIFICATION OF INDIVIDUALIZED EDUCATION PROGRAM
              AMENDMENT
            </Title>
            <Form.Item
              label='Form Created Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
              />
            </Form.Item>

            <Card
              title='Student SIS Data'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />
              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
              <StudentDobSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentDob}
                error={itemErrors.studentDob}
              />
              <StudentParent1SIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.parent1}
                error={itemErrors.parent1}
              />
              <StudentParent2SIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.parent2}
                error={itemErrors.parent2}
              />
            </Card>

            <Card
              title='Meeting Information'
              style={{
                marginBottom: 30
              }}
            >
              <Form.Item
                label='Date of Contact'
                name='dateOfContact'
                {...validationProps('dateOfContact')}
              >
                <DatePicker
                  {...handleFocusEvents}
                  disabled={formDisabledState.dateOfContact}
                  format={[
                    'MM-DD-YYYY',
                    'MM/DD/YYYY',
                    'MM.DD.YY',
                    'M-D-YYYY',
                    'M/D/YYYY',
                    'M.D.YYYY',
                    'MM.DD.YYYY',
                    'MM-DD-YY',
                    'MM/DD/YY',
                    'M-D-YY',
                    'M/D/YY',
                    'M.D.YY',
                    'MMDDYYYY',
                    'MMDDYY'
                  ]}
                />
              </Form.Item>
              <FormItem
                label='School District Personnel and Title'
                name='districtPersonnelName'
                {...validationProps('districtPersonnelName')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.districtPersonnelName}
                />
              </FormItem>
            </Card>

            <Card
              title='Communication Mode'
              style={{
                marginBottom: 30
              }}
            >
              <FormItem
                name='communicationMode'
                {...validationProps('communicationMode')}
              >
                <Checkbox.Group
                  onChange={checkedValues => {
                    const e = {
                      target: {
                        name: 'communicationMode',
                        value: checkedValues.toString()
                      }
                    }
                    handleMouseEvents.onChange(e)
                  }}
                  disabled={formDisabledState.communicationMode}
                  name='communicationMode'
                >
                  <Checkbox value='faxes'>Exchanged faxes</Checkbox>
                  <Checkbox value='in-person'>Met in person</Checkbox>
                  <Checkbox value='telephone'>Spoke on the telephone</Checkbox>
                  <Checkbox value='email'>Exchanged e-mails</Checkbox>
                </Checkbox.Group>
              </FormItem>
            </Card>

            <Card
              title='Change Details'
              style={{
                marginBottom: 30
              }}
            >
              <Form.Item
                label='Changes and explanation of changes'
                name='changesDescription'
                {...validationProps('changesDescription')}
              >
                <Editor
                  {...handleEditorEvents}
                  formField='changesDescription'
                  disabled={formDisabledState.changesDescription}
                />
              </Form.Item>

              <FormItem
                label='Changes will begin on'
                name='beginChangesDate'
                {...validationProps('beginChangesDate')}
              >
                <DatePicker
                  {...handleFocusEvents}
                  disabled={formDisabledState.beginChangesDate}
                  format={[
                    'MM-DD-YYYY',
                    'MM/DD/YYYY',
                    'MM.DD.YY',
                    'M-D-YYYY',
                    'M/D/YYYY',
                    'M.D.YYYY',
                    'MM.DD.YYYY',
                    'MM-DD-YY',
                    'MM/DD/YY',
                    'M-D-YY',
                    'M/D/YY',
                    'M.D.YY',
                    'MMDDYYYY',
                    'MMDDYY'
                  ]}
                />
              </FormItem>
            </Card>

            <Card
              title='Contact Information'
              style={{
                marginBottom: 30
              }}
            >
              <Form.Item
                label='Name'
                name='documentContactName'
                {...validationProps('documentContactName')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.documentContactName}
                />
              </Form.Item>
              <Form.Item
                label='Title'
                name='documentContactTitle'
                {...validationProps('documentContactTitle')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.documentContactTitle}
                />
              </Form.Item>
              <Form.Item
                label='Phone'
                name='documentContactPhone'
                {...validationProps('documentContactPhone')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.documentContactPhone}
                />
              </Form.Item>
            </Card>

            <Card
              title='Signee'
              style={{
                marginBottom: 30
              }}
            >
              <Form.Item
                label='Name'
                name='signatureName'
                {...validationProps('signatureName')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.signatureName}
                />
              </Form.Item>
              <Form.Item
                label='Title'
                name='signatureTitle'
                {...validationProps('signatureTitle')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.signatureTitle}
                />
              </Form.Item>
            </Card>

            {formError && <Paragraph type='danger'>{formError}</Paragraph>}
            <StudentFormActions
              query={PRINT_FORM3457G}
              extractDataProp='printStudentForm3457G'
              canSave={canSave}
              handleActionEvents={handleActionEvents}

              getFormFieldValues={getFormFieldValues}
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired
}

const StudentForm3457G = () => {
  // get current form information
  const { currentStudentInfo, currentEditableForm, studentMeetingId } =
    useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId } = currentEditableForm

  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    QUERY_STUDENT_COMMON_SIS,
    { variables: { id: studentId } }
  )
  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORM3457G_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )

  const createInitialFormData = (studentInfo, versionInfo) => {
    let initialValues = {}

    if (!studentInfo || !versionInfo) return initialValues

    const { studentForm3457G: form } = versionInfo
    const { student } = studentInfo
    const { parent1FullName, parent2FullName, dateOfBirth } = student
    const {
      formDate,
      studentFullName,
      studentDob,
      parent1,
      parent2,
      beginChangesDate,
      dateOfContact
    } = form

    initialValues = {
      ...form,
      formDate: formDate ? moment(formDate) : moment(Date.now()),
      dateOfContact: dateOfContact ? moment(dateOfContact) : moment(Date.now()),
      beginChangesDate: beginChangesDate
        ? moment(beginChangesDate)
        : moment(Date.now()),
      studentFullName: studentFullName || student.fullName || '',
      studentDob: studentDob
        ? moment(studentDob).format('YYYY-MM-DD')
        : dateOfBirth
        ? moment(dateOfBirth).format('YYYY-MM-DD')
        : '',
      parent1: parent1 || parent1FullName || '',
      parent2: parent2 || parent2FullName || ''
    }

    return initialValues
  }
  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
          />
        }
      />
    </FormErrorProvider>
  )
}
export default StudentForm3457G
