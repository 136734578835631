import { gql } from "@apollo/client";

export const QUERY_FORM504A_VERSION = gql`
  query studentForm504A(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
  ) {
    studentForm504A(
      studentFormId: $studentFormId
      studentId: $studentId
      formId: $formId
      schoolId: $schoolId
      studentMeetingId: $studentMeetingId
    ) {
      versionId
      formId
      formDate
      studentFullName
      studentDob
      parent1FullName
      parent2FullName
      majorLifeActivityThatMayBeAffected
      sourcesOfEvaluationInformation
      parentConsent
      parentSignatureName
      parentSignatureDate
      formAction
      formStatus
    }
  }
`;
