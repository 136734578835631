import React from "react";
import PropTypes from "prop-types";

// apollo & operations
import { useQuery } from "@apollo/client";
import { QUERY_DISTRICTS } from "../../../operations/queries/districts";

// ant design
import { Form, Select } from "antd";
import TextNameAuthEvaluated from "../common/TextNameAuthEvaluated";

const FormItemSelectDistrict = ({
  label,
  name,
  validateStatus,
  help,
  hasFeedback,
  mode,
  onChange,
  required,
  disabled,
}) => {
  const { data, loading } = useQuery(QUERY_DISTRICTS);
  return (
    <Form.Item
      label={label}
      name={name}
      validateStatus={validateStatus}
      help={help}
      hasFeedback={hasFeedback}
      required={required}
      rules={
        required && [
          { required: required, message: "Please select a district!" },
        ]
      }
    >
      <Select
        mode={mode}
        disabled={disabled}
        loading={loading}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) =>
          optionA.children
            .toString().toLowerCase()
            .localeCompare(optionB.children.toString().toLowerCase())
        }
        onChange={onChange}
      >
        {data?.districts &&
          data.districts.map((district) => (
            <Select.Option key={district.id} value={district.id}>
              <TextNameAuthEvaluated entity={district} />
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  );
};

FormItemSelectDistrict.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  help: PropTypes.string,
  validateStatus: PropTypes.string,
  hasFeedback: PropTypes.bool,
  mode: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

export default FormItemSelectDistrict;
