import { gql } from "@apollo/client";

export const PRINT_FORM3457K = gql`
  mutation printStudentForm3457K(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $studentDob: String
    $ageOfMajorityDate: String
    $parentDelegatedRightsSignatureDate: String
    $studentDelegatedRightsSignatureDate: String
    $authSignatureDate: String
    $notDelegatedRightsDate: String
    $terminateRightsStudentSignatureDate: String
    $notDelegatedRights: Boolean
    $delegatedRights: Boolean
    $parentName: String
    $parentRelationship: String
    $terminateRights: Boolean
    $formAction: String
$formStatus: String
  ) {
    printStudentForm3457K(
      studentForm3457KInput: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        studentDob: $studentDob
        ageOfMajorityDate: $ageOfMajorityDate
        parentDelegatedRightsSignatureDate: $parentDelegatedRightsSignatureDate
        studentDelegatedRightsSignatureDate: $studentDelegatedRightsSignatureDate
        authSignatureDate: $authSignatureDate
        notDelegatedRightsDate: $notDelegatedRightsDate
        terminateRightsStudentSignatureDate: $terminateRightsStudentSignatureDate
        notDelegatedRights: $notDelegatedRights
        delegatedRights: $delegatedRights
        parentName: $parentName
        parentRelationship: $parentRelationship
        terminateRights: $terminateRights
formAction: $formAction
formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;
