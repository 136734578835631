import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useLazyQuery } from "@apollo/client";

import { Form, Input } from "antd";
import { SyncOutlined } from "@ant-design/icons";

import { QUERY_STUDENT_PRIMARY_LANGUAGE } from "../../../../operations/queries/forms/SIS";
import SyncSISButton from "../../../elements/formItems/SyncSISButton";

const StudentLanguageSIS = ({
  form,
  sis,
  id,
  initialValue,
  error,
  disabled = true,
}) => {
  const [studentSIS, setStudentSIS] = useState(initialValue);
  const [getSIS, { data, loading }] = useLazyQuery(QUERY_STUDENT_PRIMARY_LANGUAGE);

  useEffect(() => {
    if (!data) return;
    form.setFieldsValue({ studentLanguage: data?.student.primaryLanguage || "" });
    setStudentSIS(data.student.primaryLanguage || "");
  }, [data]);

  return (
    <Form.Item
      label="Language"
      name="studentLanguage"
      validateStatus={error ? "error" : ""}
      help={error}
    >
      <Input
        onChange={(e) => setStudentSIS(e.target.value)}
        disabled={disabled}
        addonAfter={
          <SyncSISButton
            loading={loading}
            sisValue={sis?.student.primaryLanguage}
            currentValue={studentSIS}
          >
            <SyncOutlined onClick={() => getSIS({ variables: { id: id } })} />
          </SyncSISButton>
        }
      />
    </Form.Item>
  );
};

StudentLanguageSIS.propTypes = {
  form: PropTypes.object.isRequired,
  initialValue: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  error: PropTypes.string,
  disabled: PropTypes.bool,
};

export default StudentLanguageSIS;
