import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { notificationError } from "../../../utils/notification";
import formatErrors from "../../../utils/form";
import FormItemError from "../../elements/formItems/FormItemError";

// apollo & operations
import { useQuery, useMutation } from "@apollo/client";
import { QUERY_STUDENT_MEETING_FORMS } from "../../../operations/queries/meeting";
import { PRINT_ALL_STUDENT_MEETING_FORMS } from "../../../operations/mutations/meetings";

import { Modal, Form, Spin, List, Checkbox, Button } from "antd";

const PrintModal = ({ visible, setVisible, type, studentMeetingId }) => {
  const [blobUrl, setBlobUrl] = useState("");
  const [formError, setFormError] = useState();
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { data } = useQuery(QUERY_STUDENT_MEETING_FORMS, {
    variables: { id: studentMeetingId },
  });
  const [printStudentMeetings] = useMutation(PRINT_ALL_STUDENT_MEETING_FORMS);
  const [printableFormTypeList, setPrintableFormTypeList] = useState([]);
  const printIframe = (id) => {
    const iframe = document.frames
      ? document.frames[id]
      : document.getElementById(id);
    iframe.focus();
    console.log(id, iframe, 'this is the actual print function')
    iframe.contentWindow.print();

    closeModal();

    return false;
  };

  const unselectAll = () => {
    const values = form.getFieldsValue();
    for (const id in values.studentFormIds) {
      values.studentFormIds[id] = false;
    }
    form.setFieldsValue(values);
  };

  const closeModal = () => {
    // close the modal
    setVisible(false);
    setConfirmLoading(false);
    form.resetFields();
  };

  const handleOk = async ({ all }) => {
    clearErrors();
    if (all) {
      const  values = await form.getFieldsValue();
      for (const id in values.studentFormIds) {
        values.studentFormIds[id] = true;
      }
      form.setFieldsValue(values);
    console.log(all, 'all + values if(all) check', values)

    }
    try {
      await form.validateFields();
      setConfirmLoading(true);

      // print or download
      const studentFormIds = Object.keys(form.getFieldValue("studentFormIds") || {});
      if (studentFormIds.length) {
        const response = await printStudentMeetings({
          variables: {
            studentMeetingId: studentMeetingId,
            studentFormIds: studentFormIds,
          },
        });
        console.log(response,studentFormIds, 'response + studentFormIds')
        const { fileName, link: url } =
          response.data.printAllStudentMeetingForms;

        await fetch(url)
          .then((res) => res.blob())
          .then((blob) => {
            const url = URL.createObjectURL(blob);
            if (type === "print") {
              console.log(url, 'url, I think this is what is failing')
              setBlobUrl(url);
              setTimeout(() => {
                printIframe("print-all-iframe");
              }, 1000);
            } else {
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", `${fileName}.pdf`);
              document.body.appendChild(link);
              link.click();
              closeModal();
            }
          })
          .catch((e) => {
            notificationError(`Failed to ${type} file at : ${url}`);
          });
      }
    } catch (e) {
      setErrors(formatErrors(e));
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const formatFormName = (name) => {
    if (name) {
      return (
        name.toString().toLowerCase().charAt(0).toUpperCase() + name.toString().toLowerCase().slice(1)
      );
    }
  };

  const setErrors = (errors) => setFormError(errors.formError);

  const clearErrors = () => {
    setFormError();
  };

  useEffect(() => {
    if (!data) return;

    const unfilteredFormTypeList = data?.studentMeetingGeneral.meetingForms;

    const filteredFormTypeList = [];

    unfilteredFormTypeList.forEach((formType) => {
      const unfilteredForms = formType.forms;

      // 7/28/23: No longer true -> Only include forms where we have print permission
      //All users can print if they have at least one add/print/view permission (e.g. not all false)
      const filteredForms = unfilteredForms.filter(
        (form) => form.userSubRoleFormPermission.print || form.userSubRoleFormPermission.add || form.userSubRoleFormPermission.view
      );
      if (filteredForms.length > 0) {
        filteredFormTypeList.push({
          ...formType,
          forms: filteredForms,
        });
      }
    });

    setPrintableFormTypeList(filteredFormTypeList);
  }, [data]);
  return (
    <>
      <iframe src={blobUrl} style={{ display: "none" }} id="print-all-iframe" />
      <Modal
        title={`Choose forms to ${type}`}
        visible={visible}
        preserve={false}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okText={type === "print" ? "Print" : "Download"}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="unselect" onClick={unselectAll}>
            Uncheck all
          </Button>,
          <Button
            key="submitPrintSelected"
            type="primary"
            loading={confirmLoading}
            onClick={(e) => handleOk({ e, all: false })}
          >
            {type === "print" ? <>Print selected</> : <>Download</>}
          </Button>,
          <Button
            key="submitPrintAll"
            type="primary"
            loading={confirmLoading}
            onClick={(e) => handleOk({ e, all: true })}
          >
            {type === "print" ? <>Print All</> : <>Download All</>}
          </Button>,
        ]}
      >
        <Form name="submitPrint" form={form}>
          {!data && (
            <div style={{ textAlign: "center" }}>
              <Spin />
            </div>
          )}
          {data && (
            <>
              {printableFormTypeList.map((formType) => (
                <div key={formType.formTypeId} style={{ marginBottom: "24px" }}>
                  <h4>{formType.display}:</h4>
                  <List
                    itemLayout="horizontal"
                    dataSource={formType.forms}
                    locale={{ emptyText: " " }}
                    renderItem={(item) => (
                      <div style={{ marginLeft: "16px" }}>
                        <Form.Item
                          key={item.studentFormId}
                          name={["studentFormIds", item.studentFormId]}
                          valuePropName="checked"
                          noStyle
                        >
                          <Checkbox>{formatFormName(item.name)}</Checkbox>
                        </Form.Item>
                      </div>
                    )}
                  />
                </div>
              ))}
            </>
          )}
          <FormItemError error={formError} />
        </Form>
      </Modal>
    </>
  );
};

PrintModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired,
};

export default PrintModal;
