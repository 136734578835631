// react
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";

// apollo & operations
import { useQuery, useMutation } from "@apollo/client";
import { QUERY_GRADE, QUERY_GRADES } from "../../../operations/queries/grades";
import {
  INSERT_GRADE,
  UPDATE_GRADE,
} from "../../../operations/mutations/grades";

// ant design
import { Form, Input, Button, Typography, InputNumber } from "antd";

// elements
import FormItemsSuperAdmin from "../../elements/formItems/FormItemsSuperAdmin";
import FormItemSelectDistrict from "../../elements/formItems/FormItemSelectDistrict";

// other libraries
import formatErrors from "../../../utils/form";
import FormContainer from "../../../components/FormContainer";
import ModalDialog from "../../../components/ModalDialog";
import notificationSuccess from "../../../utils/notification";

const { Paragraph } = Typography;

const FormRecord = ({ data }) => {
  const history = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [formError, setFormError] = useState();
  const [itemErrors, setItemErrors] = useState({});
  const [updateGrade, { loading: updateLoading }] = useMutation(UPDATE_GRADE);
  const [insertGrade, { loading: insertLoading }] = useMutation(INSERT_GRADE, {
    update(cache, { data: addGrade }) {
      const data = cache.readQuery({ query: QUERY_GRADES });
      if (data) {
        cache.writeQuery({
          query: QUERY_GRADES,
          data: { grades: [addGrade.insertGrade, ...data.grades] },
        });
      }

    },
  });

  const continueEditing = (_id) => _id && history(`/grade/${_id}`);

  const onFinish = async (values) => {
    if (values) {
      try {
        clearErrors();
        const parsedValues = {
          districtId: values.districtId,
          name: values.name,
          order: values.order.toString(),
          hidden: values.hidden
        }
        const mutated = id
          ? await updateGrade({ variables: { id: id, gradeInput: parsedValues } })
          : await insertGrade({ variables: parsedValues });
        // ModalDialog.success({
        //   title: "Success",
        //   content: `The grade has been ${id ? "updated" : "added"}.`,
        //   okText: "Return to listing",
        //   onOk: () => history("/grades"),
        //   cancelText: "Continue editing",
        //   onCancel: () => continueEditing(mutated.data.insertGrade?.id),
        // }
        notificationSuccess(`The grade has been ${mutated ? 'updated' : 'added'}.`)
      } catch (e) {
        setErrors(formatErrors(e));
      }
    }

  };

  const setErrors = (errors) =>
    errors.itemErrors
      ? setItemErrors(errors.itemErrors)
      : setFormError(errors.formError);

  const clearErrors = () => {
    setFormError();
    setItemErrors({});
  };

  return (
    <Form
      form={form}
      id="gradeForm"
      name="gradeForm"
      onFinish={onFinish}
      preserve={false}
      initialValues={data?.grade}
      labelCol={{ sm: 3, md: 2, lg: 2, xl: 2, xxl: 1 }}
      wrapperCol={{ sm: 10, md: 7, lg: 8, xl: 6, xxl: 4 }}
    >
      <FormItemSelectDistrict
        label="District"
        name="districtId"
        validateStatus={itemErrors.districtId ? "error" : ""}
        help={itemErrors.districtId}
      />

      <Form.Item
        label="Name"
        name="name"
        hasFeedback
        validateStatus={itemErrors.name ? "error" : ""}
        help={itemErrors.name}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Order"
        name="order"
        hasFeedback
        validateStatus={itemErrors.order ? "error" : ""}
        help={itemErrors.order}
      >
        <InputNumber
          // defaultValue={1}
          min={1}
        // precision={1}
        />
      </Form.Item>

      <FormItemsSuperAdmin data={data.grade} hasHidden />

      {formError && <Paragraph type="danger">{formError}</Paragraph>}

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={insertLoading || updateLoading}
        >
          {id ? "Update" : "Add"}
        </Button>
      </Form.Item>
    </Form>
  );
};

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
};

const GradeForm = (props) => {
  const { id } = useParams();
  const { data, loading } = useQuery(QUERY_GRADE, {
    variables: { id },
    skip: !id,
  });
  const initialValues = { grade: { name: "", order: "" } };

  return (
    <FormContainer
      loading={loading}
      form={<FormRecord data={data || initialValues} />}
    />
  );
};

export default GradeForm;
