import { gql } from "@apollo/client";

export const QUERY_FORM3454R_VERSION = gql`
  query studentForm3454R(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
  ) {
    studentForm3454R(
      studentFormId: $studentFormId
      studentId: $studentId
      formId: $formId
      schoolId: $schoolId
      studentMeetingId: $studentMeetingId
    ) {
      versionId
      formId
      formDate
      studentFullName
      studentDisability
      disabilityIncidents
      studentIEPandPlacement
      studentObservations
      parentProvidedInformation
      causeDisability
      causeDistrict
      causeBehavior
      formAction
      formStatus
    }
  }
`;
