import { gql } from "@apollo/client";

export const QUERY_STUDENTS = gql`
  query ($searchCriteria: String, $grade: String, $offset: Int, $limit: Int) {
    students(searchCriteria: $searchCriteria, grade: $grade, offset: $offset, limit: $limit) {
      id
      studentCode #unused
      hidden #unused
      fullName
      fullNameGeneral
      firstName #unused
      lastName #unused
      grade
      dateOfBirth
      schoolName
      # schools { #removed 10/30/23 due to performance issues and reworked to load schoolName above
      #   id
      #   name
      # }
      isEditable #unused
    }
  }
`;

export const QUERY_STUDENT = gql`
  query Student($id: ID!, $includeGoals: Boolean! = true) {
    student(id: $id) {
      id
      studentCode
      firstName
      middleName
      lastName
      medicaidNumber
      dateOfBirth
      grade
      primaryLanguage
      gender
      ethnicity
      phoneNumber
      address
      city
      state
      country
      zip
      parent1FirstName
      parent1LastName
      parent1Relationship
      parent1PrimaryLanguage
      parent1PhoneNumber
      parent1Address
      parent1City
      parent1State
      parent1Country
      parent1Zip
      parent2FirstName
      parent2LastName
      parent2Relationship
      parent2PrimaryLanguage
      parent2PhoneNumber
      parent2Address
      parent2City
      parent2State
      parent2Country
      parent2Zip
      anticipatedGraduationDate
      initialEvaluationConsent
      initialEvaluationConsentDate
      reEvaluationConsent
      reEvaluationConsentDate
      lastAnnualReviewDate
      nextAnnualReviewDate
      triennialReEvaluationDueDate
      caseManager
      currentGrade
      residentDistrict
      residentSchool
      servingDistrict
      servingSchool
      nextYearCaseManager
      nextYearGrade
      nextYearResidentDistrict
      nextYearResidentSchool
      nextYearServingDistrict
      nextYearServingSchool
      hidden
      schoolIds
      schools {
        id
        name
      }
      currentIepMeetingId
      iepMeetings {
        meetingId
        meetingDate
        meetingType
        isActiveIep
      }
      currentGoalIds @include(if: $includeGoals)
      currentGoals @include(if: $includeGoals) {
        id
        studentFormId
        submittedData
      }
      priorGoalIds @include(if: $includeGoals)
      priorGoals @include(if: $includeGoals) {
        id
        studentFormId
        submittedData
      }
      uploadedDocs {
        id
        name
        contentType
        uniqueName
        url
        docDate
        createdAt
      }
      age
      studentMeetingsGeneral {
          id
          schoolId
          studentId
          meetingDate
          meetingType
          isActiveIep
          iepMeetingId
          studentFormIds
          createdAt
          district {
            id
            name
          }
          uploadedDocs {
            id
            docDate
            name
            contentType
            uniqueName
            url
          }
          studentMeetingFormTypes {
            formType {
              display
              enumName
            }
            studentMeetings {
              id
              schoolId
              studentId
              meetingDate
            }
          }
        }
      createdBy
      createdAtAgo
      updatedAtAgo
      createdAt(format: "ddd mmm dd yyyy h:MM:ss TT Z")
      updatedAt(format: "ddd mmm dd yyyy h:MM:ss TT Z")
    }
  }
`;

export const QUERY_STUDENT_IEP_META = gql`
  query Student($id: ID!) {
    student(id: $id) {
      caseManager
      anticipatedGraduationDate
      residentDistrict
      residentSchool
      servingDistrict
      servingSchool
      currentGrade
      nextYearCaseManager
      nextYearGrade
      nextYearResidentDistrict
      nextYearResidentSchool
      nextYearServingDistrict
      nextYearServingSchool
      lastAnnualReviewDate
      nextAnnualReviewDate
      initialEvaluationConsent
      initialEvaluationConsentDate
      reEvaluationConsent
      reEvaluationConsentDate
      triennialReEvaluationDueDate
    }
  }
`;

export const QUERY_STUDENT_GOALS = gql`
  query Student($id: ID!) {
    student(id: $id) {
      id
      currentGoalIds
      currentGoals {
        id
        studentFormId
        submittedData
      }
    }
  }
`;

export const GET_STUDENT_CURRENT_IEP_MEETING_ID = gql`
  query Student($id: ID!) {
    student(id: $id) {
      id
      currentIepMeetingId
    }
  }
`;

export const QUERY_STUDENT_DOCS = gql`
  query Student($id: ID!) {
    student(id: $id) {
      id
      uploadedDocs {
        id
        name
        contentType
        uniqueName
        url
        docDate
        createdAt
      }
    }
  }
`;