import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

// ant design
import { Form, Input, Button, Card, Row, Col, Layout, Typography } from "antd";
import { LockOutlined, LoadingOutlined } from "@ant-design/icons";

// components
import Page from "../../Page";

// context
import { useMutation } from "@apollo/client";
import {
  TEST_PASSWORD_RESET_TOKEN,
  SAVE_NEW_PASSWORD_WITH_PASSWORD_TOKEN,
} from "../../../operations/mutations/users.js";

// other libraries
const { Text } = Typography;

// components
const SetPasswordForm = () => {
  const [successMessage, setSuccessMessage] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState();
  const [canSetPassword, setCanSetPassword] = useState(false);

  const { token: passwordResetToken } = useParams();
  console.log(passwordResetToken, 'i am the token')

  const [testPasswordResetToken, { loading: testPasswordLoading }] =
    useMutation(TEST_PASSWORD_RESET_TOKEN);
  const [saveNewPasswordWithPasswordResetToken] = useMutation(
    SAVE_NEW_PASSWORD_WITH_PASSWORD_TOKEN
  );

  useEffect(async () => {
    try {
      const { data } = await testPasswordResetToken({
        variables: {
          passwordResetToken,
        },
      });
      if (data?.testPasswordResetToken) {
        setCanSetPassword(true);
      }
    } catch (error) {
      const message =
        error?.metadata?.graphQLErrors?.[0]?.message ||
        "The reset token provided is incorrect.";
      setErrorMessage(message);
    }
  }, []);

  const onFinish = async (values) => {
    if (!values.oldPassword) values.oldPassword = "";
    setLoading(true);
    try {
      await saveNewPasswordWithPasswordResetToken({
        variables: {
          passwordResetToken,
          saveNewPasswordWithPasswordResetTokenInput: values,
        },
      });
      setSuccessMessage("Your password has been set. You can now sign in.");
    } catch (error) {
      const message =
        error?.graphQLErrors?.[0]?.message ||
        "An unknown error occurred, please contact your administrator. E010";
      setErrorMessage(message);
    }
    setLoading(false);
  };
  return (
    <Layout className="content-layout">
      <Page>
        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 18, offset: 3 }}
            md={{ span: 14, offset: 5 }}
            lg={{ span: 12, offset: 6 }}
            xl={{ span: 10, offset: 7 }}
            xxl={{ span: 8, offset: 8 }}
          >
            <div className="page-container page-container-invitation">
              {testPasswordLoading && (
                <div className="loading">
                  <LoadingOutlined style={{ fontSize: "32px" }} />
                </div>
              )}
              {!canSetPassword && !testPasswordLoading && (
                <Card title="Invalid link" style={{ textAlign: "center" }}>
                  <Text type="warning">
                    The link you are using has expired.  Please request another link.
                  </Text>
                  <div
                    className="card-bottomLink"
                    style={{ marginTop: "12px" }}
                  >
                    <Link to="/signin">Go back to sign-in</Link>
                  </div>
                </Card>
              )}
              {canSetPassword && !successMessage && (
                <Card title="Set password">
                  <div>
                    {/* {canSetPassword && !successMessage && */}
                    <Form
                      name="setPassword"
                      onFinish={onFinish}
                      labelCol={{ span: 8 }}
                    >
                      <Form.Item
                        label="New password"
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your password",
                          },
                        ]}
                      >
                        <Input.Password
                          prefix={<LockOutlined />}
                          placeholder="Password"
                        />
                      </Form.Item>

                      <Form.Item
                        label="Confirm password"
                        name="passwordConfirm"
                        dependencies={["password"]}
                        rules={[
                          {
                            required: true,
                            message: "Please confirm your password",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue("password") === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error("The passwords do not match")
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password
                          prefix={<LockOutlined />}
                          placeholder="Confirm your password"
                        />
                      </Form.Item>

                      <Form.Item style={{ marginBottom: "0" }}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="setpassword-form-button"
                          loading={loading}
                        >
                          Submit
                        </Button>
                      </Form.Item>

                      {!!errorMessage && (
                        <div style={{ marginTop: "12px" }}>
                          <Text type="warning">{errorMessage}</Text>
                        </div>
                      )}
                    </Form>
                  </div>
                </Card>
              )}
              {!!successMessage && (
                <>
                  <Card>
                    <Text>{successMessage}</Text>
                    <div style={{ textAlign: "center", marginTop: "12px" }}>
                      <Link to="/signin">Sign-in</Link>
                    </div>
                  </Card>
                </>
              )}
            </div>
          </Col>
        </Row>
      </Page>
    </Layout>
  );
};

export default SetPasswordForm;
