import { gql } from "@apollo/client";

export const QUERY_FORMTAMES7_VERSION = gql`
  query studentFormTames7(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
  ) {
    studentFormTames7(
      studentFormId: $studentFormId
      studentId: $studentId
      formId: $formId
      schoolId: $schoolId
      studentMeetingId: $studentMeetingId
    ) {
      versionId
      formId
      formDate
      studentDob
      studentFullName
      copyIEP
      copyEligibilityDetermination
      copyInterventionPolicy
      copyInterventionProcedures
      planIndicator
      planIndicatorYes
      formAction
      formStatus
    }
  }
`;
