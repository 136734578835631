import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { IepFormContext } from '../../../../utils/iepFormProvider'
import { useQuery } from '@apollo/client'
import { QUERY_STUDENT_COMMON_SIS } from '../../../../operations/queries/forms/SIS'
import { QUERY_FORM3454A_VERSION } from '../../../../operations/queries/forms/3454A'
import moment from 'moment'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import FormContainer from '../../../FormContainer'
import { Alert, Card, DatePicker, Form, Radio, Typography } from 'antd'
import FormCollaboration from '../../../FormCollaboration'
import Title from 'antd/lib/typography/Title'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import FormItem from 'antd/lib/form/FormItem'
import StudentFormActions from '../StudentFormActions'
import { PRINT_FORM3454A } from '../../../../operations/mutations/forms/3454A'
import Editor from '../../../elements/common/Editor'

const { Paragraph } = Typography

const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId
}) => {
  const [form] = Form.useForm()

  const { itemErrors, formError } = useContext(FormErrorContext)

  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()
    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      studentMeetingId: studentMeetingId
    }
  }

  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    const update = Object.assign({})
    switch (fieldName) {
      case 'formDate':
        update[fieldName] = fieldValue ? moment(fieldValue) : ''
        break
      case 'linguisticChanged':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        break
      default:
        console.log('formatFormFieldUpdate',fieldName)
        update[fieldName] = fieldValue
    }
    return update
  }

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    console.log('handleFieldCollaborationUpdate',fieldName,fieldValue)
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }

  const formFields = [
    {
      label:
        'Academic Achievement (Current or past academic achievement data pertinent to current educational performance.)',
      name: 'academicAchievement'
    },
    {
      label:
        'Functional Performance (Current or past functional performance data pertinent to current functional performance.)',
      name: 'functionalPerformance'
    },
    {
      label:
        'Cognitive Functioning (Data and other Information regarding intellectual ability; how the student takes in information, understands information, and expresses information.)',
      name: 'cognitiveFunctioning'
    },
    {
      label:
        'Communicative Status (Information regarding communicative abilities (language, articulation, voice, fluency) affecting educational performance.)',
      name: 'communicativeStatus'
    },
    {
      label: 'For EL students explain EL STATUS',
      name: 'eLStatus'
    },
    {
      label:
        'Health (Current or past medical difficulties affecting educational performance.)',
      name: 'healthStatus'
    },
    {
      label:
        'Hearing/Vision (Auditory/visual problems that would interfere with testing or educational performance. Include dates and results of last hearing/vision test.)',
      name: 'hearingStatus'
    },
    {
      label:
        'Motor Abilities (Fine and gross motor coordination difficulties, functional mobility, or strength and endurance issues affecting educational performance.)',
      name: 'motorStatus'
    },
    {
      label:
        'Social/Emotional Status/Social Functioning (Information regarding how the environment affects educational performance--life history, adaptive behavior, independent functioning, personal and social responsibility, cultural background.)',
      name: 'socialStatus'
    }
  ]

  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          handleActionEvents,
          formDisabledState,
          canSave,
          validationProps
        }) => (
          <Form
            form={form}
            id='studentForm3454A'
            name='studentForm3454A'
            initialValues={data}
            preserve
            layout='vertical'
            className='page-padding-leftRight page-padding-topBottom form-labels-bold'
          >
            <Title level={4} className='center'>
              INDIVIDUALIZED EDUCATION PROGRAM (CONFERENCE SUMMARY REPORT)
            </Title>
            <Form.Item
              label='Form Created Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
              />
            </Form.Item>

            <Card
              title='Student SIS Data'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />
              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
            </Card>

            <Card
              title='Documentation of Evaluation Results'
              style={{
                marginBottom: 30
              }}
            >
              {formFields.map(({ name, label }, index) => (
                <Card
                  key={index}
                  style={{
                    marginBottom: '30px'
                  }}
                >
                  {name === 'eLStatus' && (
                    <FormItem
                      label='Has Linguistic status changed?'
                      name='linguisticChanged'
                      {...validationProps('linguisticChanged')}
                    >
                      <Radio.Group
                        {...handleMouseEvents}
                        buttonStyle='solid'
                        disabled={formDisabledState.linguisticChanged}
                        name='linguisticChanged'
                      >
                        <Radio.Button value>Yes</Radio.Button>
                        <Radio.Button value={false}>No</Radio.Button>
                      </Radio.Group>
                    </FormItem>
                  )}
                  <Form.Item
                    label={label}
                    name={name}
                    {...validationProps(name)}
                  >
                    <Editor
                      formField={name}
                      disabled={formDisabledState[name]}
                      {...handleEditorEvents}
                    />
                  </Form.Item>
                </Card>
              ))}
            </Card>

            {formError && <Paragraph type='danger'>{formError}</Paragraph>}

            <StudentFormActions
              query={PRINT_FORM3454A}
              extractDataProp='printStudentForm3454A'
              canSave={canSave}
              getFormFieldValues={getFormFieldValues}
              handleActionEvents={handleActionEvents}
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired
}

const StudentForm3454A = () => {
  // get current form information
  const { currentStudentInfo, currentEditableForm, studentMeetingId } =
    useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId } = currentEditableForm

  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    QUERY_STUDENT_COMMON_SIS,
    { variables: { id: studentId } }
  )
  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORM3454A_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )

  const createInitialFormData = (studentInfo, versionInfo) => {
    let initialValues = {}

    if (!studentInfo || !versionInfo) return initialValues

    const { studentForm3454A: form } = versionInfo
    const { student } = studentInfo
    const { formDate, studentFullName } = form

    initialValues = {
      ...form,
      formDate: formDate ? moment(formDate) : moment(Date.now()),
      studentFullName: studentFullName || student.fullName || ''
    }

    return initialValues
  }
  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
          />
        }
      />
    </FormErrorProvider>
  )
}
export default StudentForm3454A
