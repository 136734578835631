import React from "react";
import { useParams } from "react-router-dom";

import Page from "../../components/Page";
import StudentForm from "../../components/sections/student/StudentForm";
// import StudentCard from '../../components/sections/student/StudentCard'

const Student = () => {
  const { id } = useParams();
  const title = id ? "Edit student" : "Add student";

  const breadcrumb = [
    {
      path: "/",
      breadcrumbName: "Home",
    },
    {
      path: "/students",
      breadcrumbName: "Students",
    },
    {
      path: `/student/${id}`,
      breadcrumbName: "Student",
    },
    {
      path: "",
      breadcrumbName: title,
    },
  ];
  return (
    <>
      <Page title={title} breadcrumb={breadcrumb}>
        <div className="page-container">
          <div className="page-padding-leftRight page-padding-topBottom">
            <StudentForm />
          </div>
        </div>
      </Page>
    </>
  );
};

export default Student;
