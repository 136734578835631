import { gql } from "@apollo/client";

export const QUERY_FORMIAP_VERSION = gql`
  query studentFormIAP(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
  ) {
    studentFormIAP(
      studentFormId: $studentFormId
      studentId: $studentId
      formId: $formId
      schoolId: $schoolId
      studentMeetingId: $studentMeetingId
    ) {
      versionId
      formId
      formDate
      studentFullName
      studentDob
      parent1FullName
      parent2FullName
      parent1Phone
      parent2Phone
      parent1Email
      parent2Email
      areasOfStrength
      areasOfImprovement
      instructional
      environmental
      assessment
      backgroundInformation
      scheduleDate1a
      scheduleDate1b
      scheduleDate2a
      scheduleDate2b
      scheduleDate3a
      scheduleDate3b
      scheduleDate4a
      scheduleDate4b
      scheduleDate5a
      scheduleDate5b
      scheduleDate6a
      scheduleDate6b
      scheduleDate7a
      scheduleDate7b
      scheduleDate8a
      scheduleDate8b
      parentSignatureName
      parentSignatureDate
      formAction
      formStatus
    }
  }
`;
