import { gql } from "@apollo/client";

export const SIGN_UP = gql`
  mutation {
    signUp(
      signUpInput: {
        email: "george@humancode.ca"
        password: "Orange1"
        passwordConfirm: "Orange1"
        signUpPassword: "1306"
      }
    ) {
      id
      email
    }
  }
`;

export const SIGN_IN = gql`
  mutation SignIn($email: String!, $password: String!) {
    signIn(signInInput: { email: $email, password: $password }) {
      accessToken
      refreshToken
    }
  }
`;

// mutation UpdateTodo($id: String!, $type: String!) {
//     updateTodo(id: $id, type: $type) {
//       id
//       type
//     }
//   }
// `

export const SIGN_OUT = gql`
  mutation SignOut {
    signOut
  }
`;

// refreshAuth is used with fetch and doesn't need gql``
export const GET_TOKEN_QUERY = `
  mutation refreshAuth($accessToken: String!, $refreshToken: String!) {
    refreshAuth (refreshAuthInput: {accessToken: $accessToken, refreshToken: $refreshToken}) {
      accessToken
      refreshToken
  }
}
`;
