import { gql } from "@apollo/client";

export const PRINT_FORM3457M = gql`
  mutation printStudentForm3457M(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $studentDob: String
    $parent1: String
    $parent2: String
    $evaluationType: String
    $additionalEvaluationDays: Int
    $extensionReason: String
    $representativeName: String
    $representativeTitle: String
    $parentAgreement: String
    $previousDate: String
    $newDate: String
    $agreementDate: String
    $parentSignatureDate: String
    $formAction: String
$formStatus: String
  ) {
    printStudentForm3457M(
      studentForm3457MInput: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        studentDob: $studentDob
        parent1: $parent1
        parent2: $parent2
        evaluationType: $evaluationType
        additionalEvaluationDays: $additionalEvaluationDays
        extensionReason: $extensionReason
        representativeName: $representativeName
        representativeTitle: $representativeTitle
        parentAgreement: $parentAgreement
        previousDate: $previousDate
        newDate: $newDate
        agreementDate: $agreementDate
        parentSignatureDate: $parentSignatureDate
formAction: $formAction
formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;
