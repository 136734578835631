// react
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useParams } from 'react-router-dom'

// apollo & operations
import { useQuery, useMutation } from '@apollo/client'
import {
  QUERY_DOCUMENT,
  QUERY_DOCUMENTS
} from '../../../operations/queries/documents'
import {
  INSERT_DOCUMENT,
  UPDATE_DOCUMENT
} from '../../../operations/mutations/documents'

// ant design
import { Form, Input, Button, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'

// elements
import FormItemsSuperAdmin from '../../elements/formItems/FormItemsSuperAdmin'
import FormItemError from '../../elements/formItems/FormItemError'

// other libraries
import formatErrors from '../../../utils/form'
import FormContainer from '../../../components/FormContainer'
import ModalDialog from '../../../components/ModalDialog'
import notificationSuccess from '../../../utils/notification'

const FormComponent = ({ data }) => {
  const history = useNavigate()
  const { id } = useParams()
  const [form] = Form.useForm()
  const [formError, setFormError] = useState()
  const [itemErrors, setItemErrors] = useState({})
  const [updateDocument, { loading: updateLoading }] =
    useMutation(UPDATE_DOCUMENT)
  const [insertDocument, { loading: insertLoading }] = useMutation(
    INSERT_DOCUMENT,
    {
      update(cache, { data: addDocument }) {
        const data = cache.readQuery({ query: QUERY_DOCUMENTS })
        if (data) {
          cache.writeQuery({
            query: QUERY_DOCUMENTS,
            data: { documents: [addDocument.insertDocument, ...data.documents] }
          })
        }

      }
    }
  )

  const continueEditing = _id => _id && history(`/document/${_id}`)

  const onFinish = async values => {
    try {
      clearErrors()
      const mutated = id
        ? await updateDocument({ variables: { id: id, documentInput: values } })
        : await insertDocument({ variables: values })
      // ModalDialog.success({
      //   title: "Success",
      //   content: `The document has been ${id ? "updated" : "added"}.`,
      //   okText: "Return to listing",
      //   onOk: () => history("/documents"),
      //   cancelText: "Continue editing",
      //   onCancel: () => continueEditing(mutated.data.insertDocument?.id),
      // })
      notificationSuccess(`The document has been ${mutated ? 'updated' : 'added'}.`)
    } catch (e) {
      setErrors(formatErrors(e))
    }
  }

  const setErrors = errors =>
    errors.itemErrors
      ? setItemErrors(errors.itemErrors)
      : setFormError(errors.formError)

  const clearErrors = () => {
    setFormError()
    setItemErrors({})
  }

  return (
    <>
      {data && (
        <Form
          form={form}
          id='documentForm'
          name='documentForm'
          onFinish={onFinish}
          preserve={false}
          initialValues={data.document}
          labelCol={{ sm: 8, md: 5, lg: 6, xl: 4, xxl: 3 }}
          wrapperCol={{ sm: 10, md: 7, lg: 8, xl: 6, xxl: 4 }}
        >
          <Form.Item
            label='Title'
            name='title'
            hasFeedback
            validateStatus={itemErrors.title ? 'error' : ''}
            help={itemErrors.title}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label='Url'
            name='url'
            hasFeedback
            validateStatus={itemErrors.url ? 'error' : ''}
            help={itemErrors.url}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label='File'
            name='file'
            validateStatus={itemErrors.file ? 'error' : ''}
            help={itemErrors.file}
          >
            <Upload listType='picture'>
              <Button icon={<UploadOutlined />}>Upload a document</Button>
            </Upload>
          </Form.Item>

          <FormItemsSuperAdmin data={data.document} hasHidden />

          <FormItemError error={formError} />

          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              loading={insertLoading || updateLoading}
            >
              {id ? 'Update' : 'Add'}
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  )
}

FormComponent.propTypes = {
  data: PropTypes.object.isRequired
}

const DocumentForm = () => {
  const { id } = useParams()

  const { data, loading } = useQuery(QUERY_DOCUMENT, {
    variables: { id },
    skip: !id
  })
  const initialValues = { document: { email: '' } }

  return (
    <FormContainer
      loading={loading}
      size={2}
      form={<FormComponent data={data || initialValues} />}
    />
  )
}

export default DocumentForm
