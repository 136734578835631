import { gql } from "@apollo/client";

export const QUERY_SUBROLES = gql`
  query {
    subRoles {
      id
      enumName
      nameGeneral
      createdBy
      createdAtAgo
      updatedAtAgo
      createdAt
      updatedAt
    }
  }
`;

export const QUERY_SUBROLE = gql`
  query subRoles($id: ID!) {
    subRoles(id: $id) {
      id
      enumName
      nameGeneral
      createdBy
      createdAtAgo
      updatedAtAgo
      createdAt
      updatedAt
    }
  }
`;
