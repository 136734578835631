import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Menu } from "antd";
import PropTypes from "prop-types";
import moment from "moment";
import { iconSwitch } from "./DesktopStudents";
import { IepFormContext } from "../../../utils/iepFormProvider";

export const DesktopMeetingMenuItem = (props) => {
  const { id, meetingDate, schoolId, studentId, districtId } =
    props;
  const history = useNavigate();
  const { setStudentMeetingId, setStudentFormInfo } =
    useContext(IepFormContext);

  return (
    <Menu.Item
      {...props}
      onClick={() => {
        setStudentMeetingId(id);
        setStudentFormInfo({
          studentId,
          schoolId,
          districtId,
        });
        history(`/student-form/${id}`);
      }}
    >
      {moment(meetingDate).format("MM-DD-YYYY")}
    </Menu.Item>
  );
};

DesktopMeetingMenuItem.propTypes = {
  id: PropTypes.string,
  meetingDate: PropTypes.string,
  schoolId: PropTypes.string,
  studentId: PropTypes.string,
  districtId: PropTypes.string,
};
