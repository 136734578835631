import React, { useContext } from "react";
import { Alert } from "antd";
import { IepFormContext } from "../../utils/iepFormProvider";
const FormKeyDates = () => {
  const { keyDates } = useContext(IepFormContext);
  if (!keyDates) return <></>;

  const descriptionArray = keyDates.split("\n");

  return (
    <Alert
      description={descriptionArray.map((description) => (
        <div style={{ marginTop: "5px" }} key={Math.random()}>
          {description}
        </div>
      ))}
      type="info"
      showIcon
      closable
      style={{
        marginTop: "5px",
        marginBottom: "5px",
      }}
    />
  );
};

export default FormKeyDates;
