import { gql } from "@apollo/client";

export const QUERY_FORM504B_VERSION  = gql`
  query studentForm504B(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
  ) {
    studentForm504B(
      studentFormId: $studentFormId
      studentId: $studentId
      formId: $formId
      schoolId: $schoolId
      studentMeetingId: $studentMeetingId
    ) {
      versionId
      formId
      formDate
      nextReviewDate
      studentFullName
      address
      city
      state
      zip
      country
      studentDob
      studentCode
      studentLanguage
      studentGrade
      studentGender
      studentEthnicity
      highSchoolGraduationDate
      placement
      disabilities
      medicaidNumber
      residentDistrict
      residentSchool
      servingDistrict
      servingSchool
      parent1
      educationSurrogateParent1
      parent1Email
      parent1PhoneNumber
      parent1Address
      parent1City
      parent1State
      parent1Zip
      parent1PrimaryLanguage
      parent2
      educationSurrogateParent2
      parent2Email
      parent2PhoneNumber
      parent2Address
      parent2City
      parent2State
      parent2Zip
      parent2PrimaryLanguage
      dateParentProvidedParentsRights
      parentInitials
      meetingAttendedParent1
      meetingAttendedParent2
      meetingAttendedStudent
      meetingAttendedLEARepresentative
      meetingAttendedGeneralEducationTeacher
      meetingAttendedSpecialEducationTeacher
      meetingAttendedSchoolPsychologist
      meetingAttendedSchoolSocialWorker
      meetingAttendedSpeechLanguagePathologist
      meetingAttendedBilingualSpecialist
      meetingAttendedInterpreter
      meetingAttendedSchoolNurse
      meetingAttendedOther
      meetingAttendedOtherText
      formAction
      formStatus
    }
  }
`;
