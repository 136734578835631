// react
import React, { useEffect, useContext } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

// ant design
import { Modal } from "antd";

// contexts
import ApolloContext from "../../utils/apolloProvider";

// components
import Page from "../../components/Page";

// apollo & operations
import { QUERY_SCHOOL } from "../../operations/queries/schools";
import { UPDATE_SCHOOL } from "../../operations/mutations/schools";

// query params
const useQuery = () => new URLSearchParams(useLocation().search);

const SchoolDelete = (props) => {
  const apollo = useContext(ApolloContext);
  const history = useNavigate();
  const { id } = useParams();
  const query = useQuery();
  const breadcrumb = [
    {
      path: "/",
      breadcrumbName: "Home",
    },
    {
      path: "/schools",
      breadcrumbName: "Schools",
    },
    {
      path: "",
      breadcrumbName: "Delete school",
    },
  ];

  const deleteSchool = async () => {
    try {
      const response = await apollo.client.query({
        query: QUERY_SCHOOL,
        variables: { id },
      });
      const school = { ...response.data.school };
      // @todo should only update hidden
      await apollo.client.mutate({
        mutation: UPDATE_SCHOOL,
        variables: {
          id: id,
          schoolInput: { email: school.email, hidden: true },
        },
      });
      Modal.success({
        title: "Success",
        content: "The school has been hidden.",
        okText: "Return to listing",
        onOk: () => history.replace("/schools"),
      });
    } catch (e) {
      Modal.error({
        title: "An error has occurred",
        content: "The school has not been hidden.",
        okText: "Return to listing",
        onOk: () => history.replace("/schools"),
      });
    }
  };

  useEffect(() => {
    if (query.get("confirm") === "1") {
      deleteSchool();
    }
  }, []);

  return (
    <>
      <Page title="Delete school" breadcrumb={breadcrumb} />
    </>
  );
};

export default SchoolDelete;
