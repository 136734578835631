import { gql } from "@apollo/client";

export const QUERY_FORM3454M_VERSION = gql`
  query studentForm3454M(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
  ) {
    studentForm3454M(
      studentFormId: $studentFormId
      studentId: $studentId
      formId: $formId
      schoolId: $schoolId
      studentMeetingId: $studentMeetingId
    ) {
      versionId
      formId
      formDate
      studentFullName
      parentNotificationMethods
      parentNotificationMethodsOther
      initialEvaluationResults
      goalStatement
      goalNumber
      goalTotal
      goalArea
      illinoisLearningStandard
      goalImplementers
      formAction
      formStatus
      evaluationMeasures {
        shortTermObjective
        evaluationCriteriaAccuracyPercent
        evaluationCriteriaAttempts
        evaluationCriteriaTotalAttempts
        evaluationCriteriaOther
        evaluationCriteriaOtherText
        evaluationProcedures
        evaluationProcedureOtherText
        scheduleDeterminingProgress
        scheduleDeterminingProgressOtherText
        datesReviewed
  
      }
    }
  }
`;
