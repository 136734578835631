import { useState, useEffect } from 'react'
import { Button, Row, Affix, Col } from 'antd'
import { Document, Page } from 'react-pdf'
import { GET_S3_SIGNED_URL } from '../../operations/queries/fileUploads'
import { useQuery } from '@apollo/client'
import './pdf.css'
const RenderPdfHistory = ({ pdfData, props }) => {
  let [numPages, setNumPages] = useState(null)
  let [pageNumber, setPageNumber] = useState(1)
  const { data: s3DataGet, loading: s3LoadingGet } = useQuery(
    GET_S3_SIGNED_URL,
    {
      variables: {
        fileName: pdfData.uniqueName,
        contentType: pdfData.contentType
      }
    }
  )
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }
  const pageForward = () => {
    if (pageNumber++ <= numPages) {
      setPageNumber(pageNumber++)
    }
  }
  const pageBackward = () => {
    if (pageNumber-- != 0) {
      setPageNumber(pageNumber--)
    }
  }

  return (
    <>
      {props === false ? (
        <></>
      ) : (
        <>
          {pdfData && s3DataGet != undefined ? (
            <>
              <Affix offsetTop={5} onChange={affixed => console.log(affixed)}>
                <div>
                  <Row>
                    <Col>
                      <Button
                        type='primary'
                        onClick={pageBackward}
                        disabled={pageNumber <= 1}
                      >
                        Previous
                      </Button>
                    </Col>
                    &nbsp;&nbsp;
                    <Col>
                      <Button
                        type='primary'
                        onClick={pageForward}
                        disabled={pageNumber === numPages}
                      >
                        Next
                      </Button>
                    </Col>
                  </Row>
                  <Document
                    file={{ url: s3DataGet.s3GetSignedURLRequest.signedURL }}
                    onLoadSuccess={onDocumentLoadSuccess}
                    // error=''
                    // onLoadError={''}
                  >
                    <Page
                      pageNumber={pageNumber}
                      renderTextLayer={false}
                      scale={2}
                    />{' '}
                  </Document>
                </div>
              </Affix>
            </>
          ) : (
            <>Loading...</>
          )}
        </>
      )}
    </>
  )
}

export default RenderPdfHistory
