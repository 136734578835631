import { gql } from "@apollo/client";

export const PRINT_FORM3454P = gql`
  mutation printStudentForm3454P(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $intiationDate: String
    $durationDate: String
    $generalEducationNoSupplementary: String
    $generalEducationNoSupplementaryminutesPerWeek: String
    $generalEducationSupplementaryAids: String
    $generalEducationSupplementaryAidsMinutesPerWeek: String
    $generalEducationClassroom: String
    $generalEducationClassroomMinutesPerWeek: String
    $outsideGeneralEducation: String
    $outsideGeneralEducationMinutesPerWeek: String
    $outsideRelatedServices: String
    $outsideRelatedServicesMinutesPerWeek: String
    $regular35ProgramMinutesPerWeek: String
    $regularABProgramMinutesPerWeek: String
    $totalBelltoBellMinutes: String
    $totalNumberMinutesOutsideGeneralEducation: String
    $totalNumberMinutesInsideGeneralEducation: String
    $percentageInsideGeneralEducationEnvironment: String
    $formAction: String
$formStatus: String
  ) {
    printStudentForm3454P(
      studentForm3454PInput: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        intiationDate: $intiationDate
        durationDate: $durationDate
        generalEducationNoSupplementary: $generalEducationNoSupplementary
        generalEducationNoSupplementaryminutesPerWeek: $generalEducationNoSupplementaryminutesPerWeek
        generalEducationSupplementaryAids: $generalEducationSupplementaryAids
        generalEducationSupplementaryAidsMinutesPerWeek: $generalEducationSupplementaryAidsMinutesPerWeek
        generalEducationClassroom: $generalEducationClassroom
        generalEducationClassroomMinutesPerWeek: $generalEducationClassroomMinutesPerWeek
        outsideGeneralEducation: $outsideGeneralEducation
        outsideGeneralEducationMinutesPerWeek: $outsideGeneralEducationMinutesPerWeek
        outsideRelatedServices: $outsideRelatedServices
        outsideRelatedServicesMinutesPerWeek: $outsideRelatedServicesMinutesPerWeek
        regular35ProgramMinutesPerWeek: $regular35ProgramMinutesPerWeek
        regularABProgramMinutesPerWeek: $regularABProgramMinutesPerWeek
        totalBelltoBellMinutes: $totalBelltoBellMinutes
        totalNumberMinutesOutsideGeneralEducation: $totalNumberMinutesOutsideGeneralEducation
        totalNumberMinutesInsideGeneralEducation: $totalNumberMinutesInsideGeneralEducation
        percentageInsideGeneralEducationEnvironment: $percentageInsideGeneralEducationEnvironment
        formAction: $formAction
        formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;
