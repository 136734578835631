import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { IepFormContext } from '../../../../utils/iepFormProvider'
import { useQuery } from '@apollo/client'
import { QUERY_STUDENT_NAME } from '../../../../operations/queries/forms/SIS'
import { QUERY_FORM3454I_VERSION } from '../../../../operations/queries/forms/3454I'
import moment from 'moment'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import FormContainer from '../../../FormContainer'
import { Alert, Card, DatePicker, Form, Radio, Input, Typography } from 'antd'
import FormCollaboration from '../../../FormCollaboration'
import Title from 'antd/lib/typography/Title'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import StudentFormActions from '../StudentFormActions'
import { PRINT_FORM3454I } from '../../../../operations/mutations/forms/3454I'
import Editor from '../../../elements/common/Editor'
import FormItem from 'antd/lib/form/FormItem'

const { Paragraph } = Typography

const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId
}) => {
  const [form] = Form.useForm()
  const { itemErrors, formError } = useContext(FormErrorContext)

  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()
    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      studentMeetingId: studentMeetingId
    }
  }

  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    const update = Object.assign({})
    switch (fieldName) {
      case 'formDate':
      case 'instructionDateYearAddressed':
      case 'instructionDateYearCompleted':
      case 'relatedServicesDateYearAddressed':
      case 'relatedServicesDateYearCompleted':
      case 'communityExperiencesDateYearAddressed':
      case 'communityExperiencesDateYearCompleted':
      case 'livingObjectivesDateYearAddressed':
      case 'livingObjectivesDateYearCompleted':
      case 'dailyLivingSkillsDateYearAddressed':
      case 'dailyLivingSkillsDateYearCompleted':
      case 'linkagesDateYearAddressed':
      case 'linkagesDateYearCompleted':
        update[fieldName] = fieldValue ? moment(fieldValue) : ''
        break
      case 'studentDevelopmentalDisability':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        setStudentDevelopmentalDisability(update[fieldName])
        break
      default:
        update[fieldName] = fieldValue
    }
    return update
  }

  const [studentDevelopmentalDisability, setStudentDevelopmentalDisability] =
    useState(data?.studentDevelopmentalDisability)
  const [
    providedCareerAndTechnicalEducationInfo,
    setProvidedCareerAndTechnicalEducationInfo
  ] = useState(data?.providedCareerAndTechnicalEducationInfo)
  const [cteInfoIncludedOptions, setCteInfoIncludedOptions] = useState(
    data?.cteInfoIncludedOptions
  )
  const [
    studentMetIndependentServiceCoordinator,
    setStudentMetIndependentServiceCoordinator
  ] = useState(data?.studentMetIndependentServiceCoordinator)
  const [
    studentRegiseteredForPotentialFunding,
    setStudentRegiseteredForPotentialFunding
  ] = useState(data?.studentRegiseteredForPotentialFunding)
  const [studentRecordsUpToDate, setStudentRecordsUpToDate] = useState(
    data?.studentRecordsUpToDate
  )
  const [
    studentFamiliarWithLocalServiceProviders,
    setStudentFamiliarWithLocalServiceProviders
  ] = useState(data?.studentFamiliarWithLocalServiceProviders)
  const [
    iscInquiredAboutPlanningForServices,
    setIscInquiredAboutPlanningForServices
  ] = useState(data?.iscInquiredAboutPlanningForServices)
  const [iscDiscussedHomeBasedServices, setIscDiscussedHomeBasedServices] =
    useState(data?.iscDiscussedHomeBasedServices)

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }

  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          formDisabledState,
          canSave,
          handleActionEvents,
          validationProps
        }) => (
          <Form
            form={form}
            id='studentForm3454I'
            name='studentForm3454I'
            initialValues={data}
            preserve
            layout='vertical'
            className='page-padding-leftRight page-padding-topBottom form-labels-bold'
          >
            <Title level={4} className='center'>
              TRANSITIONS SERVICES (address by age 14&frac12;)
            </Title>
            <Form.Item
              label='Form Created Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
              />
            </Form.Item>

            <Card
              title='Student SIS Data'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />
              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
            </Card>

            <Card
              style={{
                marginBottom: 30
              }}
            >
              <FormItem
                label="The school district provided the student with information about the school district's career and technical education (CTE)
                  opportunities and post-secondary  CTE opportunities"
                name='providedCareerAndTechnicalEducationInfo'
                {...validationProps('providedCareerAndTechnicalEducationInfo')}
              >
                <Radio.Group
                  onChange={e => {
                    setProvidedCareerAndTechnicalEducationInfo(e.target.value)
                    handleMouseEvents.onChange(e)
                  }}
                  buttonStyle='solid'
                  disabled={
                    formDisabledState.providedCareerAndTechnicalEducationInfo
                  }
                  name='providedCareerAndTechnicalEducationInfo'
                >
                  <Radio.Button value='yes'>Yes</Radio.Button>
                  <Radio.Button value='no'>No</Radio.Button>
                  <Radio.Button value='unknown'>Unknown</Radio.Button>
                </Radio.Group>
              </FormItem>
              <FormItem
                label='The CTE information included a list of programming options, the scope and sequence of study for pursuing
                  those options, and the locations of those options'
                name='cteInfoIncludedOptions'
                {...validationProps('cteInfoIncludedOptions')}
              >
                <Radio.Group
                  onChange={e => {
                    setCteInfoIncludedOptions(e.target.value)
                    handleMouseEvents.onChange(e)
                  }}
                  buttonStyle='solid'
                  disabled={formDisabledState.cteInfoIncludedOptions}
                  name='cteInfoIncludedOptions'
                >
                  <Radio.Button value='yes'>Yes</Radio.Button>
                  <Radio.Button value='no'>No</Radio.Button>
                  <Radio.Button value='unknown'>Unknown</Radio.Button>
                </Radio.Group>
              </FormItem>
            </Card>

            <Card title='Transition Services' style={{ marginBottom: 30 }}>
              <Alert
                style={{ marginBottom: '16px' }}
                message='Please include, if appropriate, linkages for outside agencies (e.g., DMH, DRS, DSCC, PAS, SASS, SSI, WIC, DHFS, etc.)'
                type='info'
              />
              <Card
                style={{
                  marginBottom: 30
                }}
              >
                <big>
                  <strong>
                    INSTRUCTION (e.g., tutoring, skills training,
                    accommodations, adult basic education, etc.)
                  </strong>
                </big>
                <hr />
                <br />
                <Form.Item
                  label='Details'
                  name='instruction'
                  {...validationProps('instruction')}
                >
                  <Editor
                    {...handleEditorEvents}
                    formField='instruction'
                    disabled={formDisabledState.instruction}
                  />
                </Form.Item>
                <FormItem
                  label='Provider Agency and Position'
                  name='instructionProviderAgency'
                  validateStatus={
                    itemErrors.instructionProviderAgency ? 'error' : ''
                  }
                  help={itemErrors.instructionProviderAgency}
                  {...validationProps('instructionProviderAgency')}
                >
                  <Input
                    {...handleFocusEvents}
                    disabled={formDisabledState.instructionProviderAgency}
                  />
                </FormItem>
                <FormItem
                  label='Goal #(s) if appropriate'
                  name='instructionGoalNumber'
                  validateStatus={
                    itemErrors.instructionGoalNumber ? 'error' : ''
                  }
                  help={itemErrors.instructionGoalNumber}
                  {...validationProps('instructionGoalNumber')}
                >
                  <Input
                    {...handleFocusEvents}
                    disabled={formDisabledState.instructionGoalNumber}
                  />
                </FormItem>
                <FormItem
                  label='Date/Year to be Addressed'
                  name='instructionDateYearAddressed'
                  validateStatus={
                    itemErrors.instructionDateYearAddressed ? 'error' : ''
                  }
                  help={itemErrors.instructionDateYearAddressed}
                  {...validationProps('instructionDateYearAddressed')}
                >
                  <DatePicker
                    {...handleFocusEvents}
                    disabled={formDisabledState.instructionDateYearAddressed}
                    format={[
                      'MM-DD-YYYY',
                      'MM/DD/YYYY',
                      'MM.DD.YY',
                      'M-D-YYYY',
                      'M/D/YYYY',
                      'M.D.YYYY',
                      'MM.DD.YYYY',
                      'MM-DD-YY',
                      'MM/DD/YY',
                      'M-D-YY',
                      'M/D/YY',
                      'M.D.YY',
                      'MMDDYYYY',
                      'MMDDYY'
                    ]}
                  />
                </FormItem>
                <FormItem
                  label='Date/Year Completed'
                  name='instructionDateYearCompleted'
                  validateStatus={
                    itemErrors.instructionDateYearCompleted ? 'error' : ''
                  }
                  help={itemErrors.instructionDateYearCompleted}
                  {...validationProps('instructionDateYearCompleted')}
                >
                  <DatePicker
                    {...handleFocusEvents}
                    disabled={formDisabledState.instructionDateYearCompleted}
                    format={[
                      'MM-DD-YYYY',
                      'MM/DD/YYYY',
                      'MM.DD.YY',
                      'M-D-YYYY',
                      'M/D/YYYY',
                      'M.D.YYYY',
                      'MM.DD.YYYY',
                      'MM-DD-YY',
                      'MM/DD/YY',
                      'M-D-YY',
                      'M/D/YY',
                      'M.D.YY',
                      'MMDDYYYY',
                      'MMDDYY'
                    ]}
                  />
                </FormItem>
              </Card>

              <Card
                style={{
                  marginBottom: 30
                }}
              >
                <big>
                  <strong>
                    RELATED SERVICES (e.g., transportation, social services,
                    medical services, technology, support services, etc.)
                  </strong>
                </big>
                <hr />
                <br />
                <Form.Item
                  label='Details'
                  name='relatedServices'
                  {...validationProps('relatedServices')}
                >
                  <Editor
                    {...handleEditorEvents}
                    formField='relatedServices'
                    disabled={formDisabledState.relatedServices}
                  />
                </Form.Item>
                <FormItem
                  label='Provider Agency and Position'
                  name='relatedServicesProvider'
                  validateStatus={
                    itemErrors.relatedServicesProvider ? 'error' : ''
                  }
                  help={itemErrors.relatedServicesProvider}
                  {...validationProps('relatedServicesProvider')}
                >
                  <Input
                    {...handleFocusEvents}
                    disabled={formDisabledState.relatedServicesProvider}
                  />
                </FormItem>
                <FormItem
                  label='Goal #(s) if appropriate'
                  name='relatedServicesGoalNumber'
                  validateStatus={
                    itemErrors.relatedServicesGoalNumber ? 'error' : ''
                  }
                  help={itemErrors.relatedServicesGoalNumber}
                  {...validationProps('relatedServicesGoalNumber')}
                >
                  <Input
                    {...handleFocusEvents}
                    disabled={formDisabledState.relatedServicesGoalNumber}
                  />
                </FormItem>
                <FormItem
                  label='Date/Year to be Addressed'
                  name='relatedServicesDateYearAddressed'
                  validateStatus={
                    itemErrors.relatedServicesDateYearAddressed ? 'error' : ''
                  }
                  help={itemErrors.relatedServicesDateYearAddressed}
                  {...validationProps('relatedServicesDateYearAddressed')}
                >
                  <DatePicker
                    {...handleFocusEvents}
                    disabled={
                      formDisabledState.relatedServicesDateYearAddressed
                    }
                    format={[
                      'MM-DD-YYYY',
                      'MM/DD/YYYY',
                      'MM.DD.YY',
                      'M-D-YYYY',
                      'M/D/YYYY',
                      'M.D.YYYY',
                      'MM.DD.YYYY',
                      'MM-DD-YY',
                      'MM/DD/YY',
                      'M-D-YY',
                      'M/D/YY',
                      'M.D.YY',
                      'MMDDYYYY',
                      'MMDDYY'
                    ]}
                  />
                </FormItem>
                <FormItem
                  label='Date/Year Completed'
                  name='relatedServicesDateYearCompleted'
                  validateStatus={
                    itemErrors.relatedServicesDateYearCompleted ? 'error' : ''
                  }
                  help={itemErrors.relatedServicesDateYearCompleted}
                  {...validationProps('relatedServicesDateYearCompleted')}
                >
                  <DatePicker
                    {...handleFocusEvents}
                    disabled={
                      formDisabledState.relatedServicesDateYearCompleted
                    }
                    format={[
                      'MM-DD-YYYY',
                      'MM/DD/YYYY',
                      'MM.DD.YY',
                      'M-D-YYYY',
                      'M/D/YYYY',
                      'M.D.YYYY',
                      'MM.DD.YYYY',
                      'MM-DD-YY',
                      'MM/DD/YY',
                      'M-D-YY',
                      'M/D/YY',
                      'M.D.YY',
                      'MMDDYYYY',
                      'MMDDYY'
                    ]}
                  />
                </FormItem>
              </Card>

              <Card
                style={{
                  marginBottom: 30
                }}
              >
                <big>
                  <strong>
                    COMMUNITY EXPERIENCES (e.g., job shadow, work experiences,
                    banking, shopping, transportation, tours of post-secondary
                    settings, etc.)
                  </strong>
                </big>
                <hr />
                <br />
                <Form.Item
                  label='Details'
                  name='communityExperiences'
                  {...validationProps('communityExperiences')}
                >
                  <Editor
                    {...handleEditorEvents}
                    formField='communityExperiences'
                    disabled={formDisabledState.communityExperiences}
                  />
                </Form.Item>
                <FormItem
                  label='Provider Agency and Position'
                  name='communityExperiencesProvider'
                  validateStatus={
                    itemErrors.communityExperiencesProvider ? 'error' : ''
                  }
                  help={itemErrors.communityExperiencesProvider}
                  {...validationProps('communityExperiencesProvider')}
                >
                  <Input
                    {...handleFocusEvents}
                    disabled={formDisabledState.communityExperiencesProvider}
                  />
                </FormItem>
                <FormItem
                  label='Goal #(s) if appropriate'
                  name='communityExperiencesGoalNumber'
                  validateStatus={
                    itemErrors.communityExperiencesGoalNumber ? 'error' : ''
                  }
                  help={itemErrors.communityExperiencesGoalNumber}
                  {...validationProps('communityExperiencesGoalNumber')}
                >
                  <Input
                    {...handleFocusEvents}
                    disabled={formDisabledState.communityExperiencesGoalNumber}
                  />
                </FormItem>
                <FormItem
                  label='Date/Year to be Addressed'
                  name='communityExperiencesDateYearAddressed'
                  validateStatus={
                    itemErrors.communityExperiencesDateYearAddressed
                      ? 'error'
                      : ''
                  }
                  help={itemErrors.communityExperiencesDateYearAddressed}
                  {...validationProps('communityExperiencesDateYearAddressed')}
                >
                  <DatePicker
                    {...handleFocusEvents}
                    disabled={
                      formDisabledState.communityExperiencesDateYearAddressed
                    }
                    format={[
                      'MM-DD-YYYY',
                      'MM/DD/YYYY',
                      'MM.DD.YY',
                      'M-D-YYYY',
                      'M/D/YYYY',
                      'M.D.YYYY',
                      'MM.DD.YYYY',
                      'MM-DD-YY',
                      'MM/DD/YY',
                      'M-D-YY',
                      'M/D/YY',
                      'M.D.YY',
                      'MMDDYYYY',
                      'MMDDYY'
                    ]}
                  />
                </FormItem>
                <FormItem
                  label='Date/Year Completed'
                  name='communityExperiencesDateYearCompleted'
                  validateStatus={
                    itemErrors.communityExperiencesDateYearCompleted
                      ? 'error'
                      : ''
                  }
                  help={itemErrors.communityExperiencesDateYearCompleted}
                  {...validationProps('communityExperiencesDateYearCompleted')}
                >
                  <DatePicker
                    {...handleFocusEvents}
                    disabled={
                      formDisabledState.communityExperiencesDateYearCompleted
                    }
                    format={[
                      'MM-DD-YYYY',
                      'MM/DD/YYYY',
                      'MM.DD.YY',
                      'M-D-YYYY',
                      'M/D/YYYY',
                      'M.D.YYYY',
                      'MM.DD.YYYY',
                      'MM-DD-YY',
                      'MM/DD/YY',
                      'M-D-YY',
                      'M/D/YY',
                      'M.D.YY',
                      'MMDDYYYY',
                      'MMDDYY'
                    ]}
                  />
                </FormItem>
              </Card>

              <Card
                style={{
                  marginBottom: 30
                }}
              >
                <big>
                  <strong>
                    DEVELOPMENT OF EMPLOYMENT AND OTHER POST-SCHOOL ADULT LIVING
                    OBJECTIVES (e.g., career planning, guidance counseling, job
                    tryouts, register to vote, adult benefits planning, etc.)
                  </strong>
                </big>
                <hr />
                <br />
                <Form.Item
                  label='Details'
                  name='livingObjectives'
                  {...validationProps('livingObjectives')}
                >
                  <Editor
                    {...handleEditorEvents}
                    formField='livingObjectives'
                    disabled={formDisabledState.livingObjectives}
                  />
                </Form.Item>
                <FormItem
                  label='Provider Agency and Position'
                  name='livingObjectivesProviderAgency'
                  validateStatus={
                    itemErrors.livingObjectivesProviderAgency ? 'error' : ''
                  }
                  help={itemErrors.livingObjectivesProviderAgency}
                  {...validationProps('livingObjectivesProviderAgency')}
                >
                  <Input
                    {...handleFocusEvents}
                    disabled={formDisabledState.livingObjectivesProviderAgency}
                  />
                </FormItem>
                <FormItem
                  label='Goal #(s) if appropriate'
                  name='livingObjectivesGoalNumber'
                  validateStatus={
                    itemErrors.livingObjectivesGoalNumber ? 'error' : ''
                  }
                  help={itemErrors.livingObjectivesGoalNumber}
                  {...validationProps('livingObjectivesGoalNumber')}
                >
                  <Input
                    {...handleFocusEvents}
                    disabled={formDisabledState.livingObjectivesGoalNumber}
                  />
                </FormItem>
                <FormItem
                  label='Date/Year to be Addressed'
                  name='livingObjectivesDateYearAddressed'
                  validateStatus={
                    itemErrors.livingObjectivesDateYearAddressed ? 'error' : ''
                  }
                  help={itemErrors.livingObjectivesDateYearAddressed}
                  {...validationProps('livingObjectivesDateYearAddressed')}
                >
                  <DatePicker
                    {...handleFocusEvents}
                    disabled={
                      formDisabledState.livingObjectivesDateYearAddressed
                    }
                    format={[
                      'MM-DD-YYYY',
                      'MM/DD/YYYY',
                      'MM.DD.YY',
                      'M-D-YYYY',
                      'M/D/YYYY',
                      'M.D.YYYY',
                      'MM.DD.YYYY',
                      'MM-DD-YY',
                      'MM/DD/YY',
                      'M-D-YY',
                      'M/D/YY',
                      'M.D.YY',
                      'MMDDYYYY',
                      'MMDDYY'
                    ]}
                  />
                </FormItem>
                <FormItem
                  label='Date/Year Completed'
                  name='livingObjectivesDateYearCompleted'
                  validateStatus={
                    itemErrors.livingObjectivesDateYearCompleted ? 'error' : ''
                  }
                  help={itemErrors.livingObjectivesDateYearCompleted}
                  {...validationProps('livingObjectivesDateYearCompleted')}
                >
                  <DatePicker
                    {...handleFocusEvents}
                    disabled={
                      formDisabledState.livingObjectivesDateYearCompleted
                    }
                    format={[
                      'MM-DD-YYYY',
                      'MM/DD/YYYY',
                      'MM.DD.YY',
                      'M-D-YYYY',
                      'M/D/YYYY',
                      'M.D.YYYY',
                      'MM.DD.YYYY',
                      'MM-DD-YY',
                      'MM/DD/YY',
                      'M-D-YY',
                      'M/D/YY',
                      'M.D.YY',
                      'MMDDYYYY',
                      'MMDDYY'
                    ]}
                  />
                </FormItem>
              </Card>

              <Card
                style={{
                  marginBottom: 30
                }}
              >
                <big>
                  <strong>
                    APPROPRIATE ACQUISITION OF DAILY LIVING SKILLS AND/OR
                    FUNCTIONAL VOCATIONAL EVALUATION (e.g., self-care, home
                    repair, home health, money, independent living, job and
                    career interests, aptitudes and skills)
                  </strong>
                </big>
                <hr />
                <br />
                <Form.Item
                  label='Details'
                  name='dailyLivingSkills'
                  {...validationProps('dailyLivingSkills')}
                >
                  <Editor
                    {...handleEditorEvents}
                    formField='dailyLivingSkills'
                    disabled={formDisabledState.dailyLivingSkills}
                  />
                </Form.Item>
                <FormItem
                  label='Provider Agency and Position'
                  name='dailyLivingSkillsProviderAgency'
                  validateStatus={
                    itemErrors.dailyLivingSkillsProviderAgency ? 'error' : ''
                  }
                  help={itemErrors.dailyLivingSkillsProviderAgency}
                  {...validationProps('dailyLivingSkillsProviderAgency')}
                >
                  <Input
                    {...handleFocusEvents}
                    disabled={formDisabledState.dailyLivingSkillsProviderAgency}
                  />
                </FormItem>
                <FormItem
                  label='Goal #(s) if appropriate'
                  name='dailyLivingSkillsGoalNumber'
                  validateStatus={
                    itemErrors.dailyLivingSkillsGoalNumber ? 'error' : ''
                  }
                  help={itemErrors.dailyLivingSkillsGoalNumber}
                  {...validationProps('dailyLivingSkillsGoalNumber')}
                >
                  <Input
                    {...handleFocusEvents}
                    disabled={formDisabledState.dailyLivingSkillsGoalNumber}
                  />
                </FormItem>
                <FormItem
                  label='Date/Year to be Addressed'
                  name='dailyLivingSkillsDateYearAddressed'
                  validateStatus={
                    itemErrors.dailyLivingSkillsDateYearAddressed ? 'error' : ''
                  }
                  help={itemErrors.dailyLivingSkillsDateYearAddressed}
                  {...validationProps('dailyLivingSkillsDateYearAddressed')}
                >
                  <DatePicker
                    {...handleFocusEvents}
                    disabled={
                      formDisabledState.dailyLivingSkillsDateYearAddressed
                    }
                    format={[
                      'MM-DD-YYYY',
                      'MM/DD/YYYY',
                      'MM.DD.YY',
                      'M-D-YYYY',
                      'M/D/YYYY',
                      'M.D.YYYY',
                      'MM.DD.YYYY',
                      'MM-DD-YY',
                      'MM/DD/YY',
                      'M-D-YY',
                      'M/D/YY',
                      'M.D.YY',
                      'MMDDYYYY',
                      'MMDDYY'
                    ]}
                  />
                </FormItem>
                <FormItem
                  label='Date/Year Completed'
                  name='dailyLivingSkillsDateYearCompleted'
                  validateStatus={
                    itemErrors.dailyLivingSkillsDateYearCompleted ? 'error' : ''
                  }
                  help={itemErrors.dailyLivingSkillsDateYearCompleted}
                  {...validationProps('dailyLivingSkillsDateYearCompleted')}
                >
                  <DatePicker
                    {...handleFocusEvents}
                    disabled={
                      formDisabledState.dailyLivingSkillsDateYearCompleted
                    }
                    format={[
                      'MM-DD-YYYY',
                      'MM/DD/YYYY',
                      'MM.DD.YY',
                      'M-D-YYYY',
                      'M/D/YYYY',
                      'M.D.YYYY',
                      'MM.DD.YYYY',
                      'MM-DD-YY',
                      'MM/DD/YY',
                      'M-D-YY',
                      'M/D/YY',
                      'M.D.YY',
                      'MMDDYYYY',
                      'MMDDYY'
                    ]}
                  />
                </FormItem>
              </Card>

              <Card
                style={{
                  marginBottom: 30
                }}
              >
                <big>
                  <strong>
                    LINKAGES TO AFTER GRADUATION SUPPORTS/SERVICES (e.g. DRS,
                    DMH, DSCC, PAS, SASS, SSI, WIC, DHFS, CILs)
                  </strong>
                </big>
                <hr />
                <br />
                <Form.Item
                  label='Details'
                  name='linkages'
                  {...validationProps('linkages')}
                >
                  <Editor
                    {...handleEditorEvents}
                    formField='linkages'
                    disabled={formDisabledState.linkages}
                  />
                </Form.Item>
                <FormItem
                  label='Provider Agency and Position'
                  name='linkagesProviderAgency'
                  validateStatus={
                    itemErrors.linkagesProviderAgency ? 'error' : ''
                  }
                  help={itemErrors.linkagesProviderAgency}
                  {...validationProps('linkagesProviderAgency')}
                >
                  <Input
                    {...handleFocusEvents}
                    disabled={formDisabledState.linkagesProviderAgency}
                  />
                </FormItem>
                <FormItem
                  label='Goal #(s) if appropriate'
                  name='linkagesGoalNumber'
                  validateStatus={itemErrors.linkagesGoalNumber ? 'error' : ''}
                  help={itemErrors.linkagesGoalNumber}
                  {...validationProps('linkagesGoalNumber')}
                >
                  <Input
                    {...handleFocusEvents}
                    disabled={formDisabledState.linkagesGoalNumber}
                  />
                </FormItem>
                <FormItem
                  label='Date/Year to be Addressed'
                  name='linkagesDateYearAddressed'
                  validateStatus={
                    itemErrors.linkagesDateYearAddressed ? 'error' : ''
                  }
                  help={itemErrors.linkagesDateYearAddressed}
                  {...validationProps('linkagesDateYearAddressed')}
                >
                  <DatePicker
                    {...handleFocusEvents}
                    disabled={formDisabledState.linkagesDateYearAddressed}
                    format={[
                      'MM-DD-YYYY',
                      'MM/DD/YYYY',
                      'MM.DD.YY',
                      'M-D-YYYY',
                      'M/D/YYYY',
                      'M.D.YYYY',
                      'MM.DD.YYYY',
                      'MM-DD-YY',
                      'MM/DD/YY',
                      'M-D-YY',
                      'M/D/YY',
                      'M.D.YY',
                      'MMDDYYYY',
                      'MMDDYY'
                    ]}
                  />
                </FormItem>
                <FormItem
                  label='Date/Year Completed'
                  name='linkagesDateYearCompleted'
                  validateStatus={
                    itemErrors.linkagesDateYearCompleted ? 'error' : ''
                  }
                  help={itemErrors.linkagesDateYearCompleted}
                  {...validationProps('linkagesDateYearCompleted')}
                >
                  <DatePicker
                    {...handleFocusEvents}
                    disabled={formDisabledState.linkagesDateYearCompleted}
                    format={[
                      'MM-DD-YYYY',
                      'MM/DD/YYYY',
                      'MM.DD.YY',
                      'M-D-YYYY',
                      'M/D/YYYY',
                      'M.D.YYYY',
                      'MM.DD.YYYY',
                      'MM-DD-YY',
                      'MM/DD/YY',
                      'M-D-YY',
                      'M/D/YY',
                      'M.D.YY',
                      'MMDDYYYY',
                      'MMDDYY'
                    ]}
                  />
                </FormItem>
              </Card>
            </Card>

            <Card
              title='Home-Based Support Services Program'
              style={{ marginBottom: 30 }}
            >
              <FormItem
                label='The student has an intellectual/developmental disability and may become eligible for home-based support services
                and other adult services funded by the Illinois Department of Human Services (IDHS)/Division of Developmental Disability (DDD) 
                after reaching age 18 and when no longer receiving special education services.'
                name='studentDevelopmentalDisability'
                {...validationProps('studentDevelopmentalDisability')}
              >
                <Radio.Group
                  onChange={e => {
                    setStudentDevelopmentalDisability(e.target.value)
                    handleMouseEvents.onChange(e)
                  }}
                  buttonStyle='solid'
                  disabled={formDisabledState.studentDevelopmentalDisability}
                  name='studentDevelopmentalDisability'
                >
                  <Radio.Button value>Yes</Radio.Button>
                  <Radio.Button value={false}>No</Radio.Button>
                </Radio.Group>
              </FormItem>
              <FormItem label='If "Yes", complete the following statements:'></FormItem>

              <Card style={{ marginBottom: 30 }}>
                <big>
                  <strong>
                    Plans for determining the student's eligibility for
                    home-based services:
                  </strong>
                </big>
                <hr />
                <FormItem
                  label='The student/family has met with the local Independent Service Coordinator (ISC) provider to discuss the Prioritization
                    for Urgency of Need for Services (PUNS) system and to register for potential IDHS/DDD funding/eligibility'
                  name='studentMetIndependentServiceCoordinator'
                  {...validationProps(
                    'studentMetIndependentServiceCoordinator'
                  )}
                >
                  <Radio.Group
                    onChange={e => {
                      setStudentMetIndependentServiceCoordinator(e.target.value)
                      handleMouseEvents.onChange(e)
                    }}
                    buttonStyle='solid'
                    disabled={
                      formDisabledState.studentMetIndependentServiceCoordinator
                    }
                    name='studentMetIndependentServiceCoordinator'
                  >
                    <Radio.Button value='yes'>Yes</Radio.Button>
                    <Radio.Button value='no'>No</Radio.Button>
                    <Radio.Button value='unknown'>Unknown</Radio.Button>
                  </Radio.Group>
                </FormItem>
                <FormItem
                  label='ISC Name'
                  name='iscName'
                  validateStatus={itemErrors.iscName ? 'error' : ''}
                  help={itemErrors.iscName}
                  {...validationProps('iscName')}
                >
                  <Input
                    {...handleFocusEvents}
                    disabled={formDisabledState.iscName}
                  />
                </FormItem>
                <FormItem
                  label='ISC Contact Number'
                  name='iscContactInfo'
                  validateStatus={itemErrors.iscContactInfo ? 'error' : ''}
                  help={itemErrors.iscContactInfo}
                  {...validationProps('iscContactInfo')}
                >
                  <Input
                    {...handleFocusEvents}
                    disabled={formDisabledState.iscContactInfo}
                  />
                </FormItem>
              </Card>

              <Card style={{ marginBottom: 30 }}>
                <big>
                  <strong>
                    Plans for enrolling the student in the program for
                    home-based services:
                  </strong>
                </big>
                <hr />
                <FormItem
                  label='The student has registered for potential IDHS/DDD funding via PUNS system'
                  name='studentRegiseteredForPotentialFunding'
                  {...validationProps('studentRegiseteredForPotentialFunding')}
                >
                  <Radio.Group
                    onChange={e => {
                      setStudentRegiseteredForPotentialFunding(e.target.value)
                      handleMouseEvents.onChange(e)
                    }}
                    buttonStyle='solid'
                    disabled={
                      formDisabledState.studentRegiseteredForPotentialFunding
                    }
                    name='studentRegiseteredForPotentialFunding'
                  >
                    <Radio.Button value='yes'>Yes</Radio.Button>
                    <Radio.Button value='no'>No</Radio.Button>
                    <Radio.Button value='unknown'>Unknown</Radio.Button>
                  </Radio.Group>
                </FormItem>
                <FormItem
                  label="The student's records have been kept up to date (at least annually) with his/her assigned ISC 
                    caseworker and have been discussed with the ISC caseworker"
                  name='studentRecordsUpToDate'
                  {...validationProps('studentRecordsUpToDate')}
                >
                  <Radio.Group
                    onChange={e => {
                      setStudentRecordsUpToDate(e.target.value)
                      handleMouseEvents.onChange(e)
                    }}
                    buttonStyle='solid'
                    disabled={formDisabledState.studentRecordsUpToDate}
                    name='studentRecordsUpToDate'
                  >
                    <Radio.Button value='yes'>Yes</Radio.Button>
                    <Radio.Button value='no'>No</Radio.Button>
                    <Radio.Button value='unknown'>Unknown</Radio.Button>
                  </Radio.Group>
                </FormItem>
              </Card>

              <Card style={{ marginBottom: 30 }}>
                <big>
                  <strong>
                    Plans for developing a plan for the student's most effective
                    use of the home-based services after reaching age 18 and
                    when no longer receiving special education:
                  </strong>
                </big>
                <hr />
                <FormItem
                  label='The student/family have become familiar with local adult intellectual/developmental disability service providers
                    that may be able to provide adult services via either potential IDHS/DDD funds or other funding sources'
                  name='studentFamiliarWithLocalServiceProviders'
                  {...validationProps(
                    'studentFamiliarWithLocalServiceProviders'
                  )}
                >
                  <Radio.Group
                    onChange={e => {
                      setStudentFamiliarWithLocalServiceProviders(
                        e.target.value
                      )
                      handleMouseEvents.onChange(e)
                    }}
                    buttonStyle='solid'
                    disabled={
                      formDisabledState.studentFamiliarWithLocalServiceProviders
                    }
                    name='studentFamiliarWithLocalServiceProviders'
                  >
                    <Radio.Button value='yes'>Yes</Radio.Button>
                    <Radio.Button value='no'>No</Radio.Button>
                    <Radio.Button value='unknown'>Unknown</Radio.Button>
                  </Radio.Group>
                </FormItem>
                <FormItem
                  label='The ISC inquired whether the student/family wants to move from Planning for Services to Seeking Services at age 18'
                  name='iscInquiredAboutPlanningForServices'
                  {...validationProps('iscInquiredAboutPlanningForServices')}
                >
                  <Radio.Group
                    onChange={e => {
                      setIscInquiredAboutPlanningForServices(e.target.value)
                      handleMouseEvents.onChange(e)
                    }}
                    buttonStyle='solid'
                    disabled={
                      formDisabledState.iscInquiredAboutPlanningForServices
                    }
                    name='iscInquiredAboutPlanningForServices'
                  >
                    <Radio.Button value='yes'>Yes</Radio.Button>
                    <Radio.Button value='no'>No</Radio.Button>
                    <Radio.Button value='unknown'>Unknown</Radio.Button>
                  </Radio.Group>
                </FormItem>
                <FormItem
                  label='The ISC has discussed and documented all desired home-based services with the student/family, including, but not
                    limited to, day services, in-home supports, residential living arrangements, and support service teams'
                  name='iscDiscussedHomeBasedServices'
                  {...validationProps('iscDiscussedHomeBasedServices')}
                >
                  <Radio.Group
                    onChange={e => {
                      setIscDiscussedHomeBasedServices(e.target.value)
                      handleMouseEvents.onChange(e)
                    }}
                    buttonStyle='solid'
                    disabled={formDisabledState.iscDiscussedHomeBasedServices}
                    name='iscDiscussedHomeBasedServices'
                  >
                    <Radio.Button value='yes'>Yes</Radio.Button>
                    <Radio.Button value='no'>No</Radio.Button>
                    <Radio.Button value='unknown'>Unknown</Radio.Button>
                  </Radio.Group>
                </FormItem>
              </Card>
            </Card>

            {formError && <Paragraph type='danger'>{formError}</Paragraph>}
            <StudentFormActions
              query={PRINT_FORM3454I}
              extractDataProp='printStudentForm3454I'
              canSave={canSave}
              handleActionEvents={handleActionEvents}

              getFormFieldValues={getFormFieldValues}
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired
}

const StudentForm3454I = () => {
  // get current form information
  const { currentStudentInfo, currentEditableForm, studentMeetingId } =
    useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId } = currentEditableForm

  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    QUERY_STUDENT_NAME,
    { variables: { id: studentId } }
  )
  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORM3454I_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )

  const createInitialFormData = (studentInfo, versionInfo) => {
    let initialValues = {}

    if (!studentInfo || !versionInfo) return initialValues

    const { studentForm3454I: form } = versionInfo
    const { student } = studentInfo
    const {
      formDate,
      instructionDateYearAddressed,
      instructionDateYearCompleted,
      relatedServicesDateYearAddressed,
      relatedServicesDateYearCompleted,
      communityExperiencesDateYearAddressed,
      communityExperiencesDateYearCompleted,
      livingObjectivesDateYearAddressed,
      livingObjectivesDateYearCompleted,
      dailyLivingSkillsDateYearAddressed,
      dailyLivingSkillsDateYearCompleted,
      linkagesDateYearAddressed,
      linkagesDateYearCompleted,
      studentFullName
    } = form

    initialValues = {
      ...form,
      formDate: formDate ? moment(formDate) : moment(Date.now()),
      instructionDateYearAddressed: instructionDateYearAddressed
        ? moment(instructionDateYearAddressed)
        : '',
      instructionDateYearCompleted: instructionDateYearCompleted
        ? moment(instructionDateYearCompleted)
        : '',
      relatedServicesDateYearAddressed: relatedServicesDateYearAddressed
        ? moment(relatedServicesDateYearAddressed)
        : '',
      relatedServicesDateYearCompleted: relatedServicesDateYearCompleted
        ? moment(relatedServicesDateYearCompleted)
        : '',
      communityExperiencesDateYearAddressed:
        communityExperiencesDateYearAddressed
          ? moment(communityExperiencesDateYearAddressed)
          : '',
      communityExperiencesDateYearCompleted:
        communityExperiencesDateYearCompleted
          ? moment(communityExperiencesDateYearCompleted)
          : '',
      livingObjectivesDateYearAddressed: livingObjectivesDateYearAddressed
        ? moment(livingObjectivesDateYearAddressed)
        : '',
      livingObjectivesDateYearCompleted: livingObjectivesDateYearCompleted
        ? moment(livingObjectivesDateYearCompleted)
        : '',
      dailyLivingSkillsDateYearAddressed: dailyLivingSkillsDateYearAddressed
        ? moment(dailyLivingSkillsDateYearAddressed)
        : '',
      dailyLivingSkillsDateYearCompleted: dailyLivingSkillsDateYearCompleted
        ? moment(dailyLivingSkillsDateYearCompleted)
        : '',
      linkagesDateYearAddressed: linkagesDateYearAddressed
        ? moment(linkagesDateYearAddressed)
        : '',
      linkagesDateYearCompleted: linkagesDateYearCompleted
        ? moment(linkagesDateYearCompleted)
        : '',
      studentFullName: studentFullName || student.fullName || ''
    }

    return initialValues
  }
  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
          />
        }
      />
    </FormErrorProvider>
  )
}
export default StudentForm3454I
