import { gql } from "@apollo/client";

export const QUERY_FORMTAMES3_VERSION = gql`
  query studentFormTames3(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
  ) {
    studentFormTames3(
      studentFormId: $studentFormId
      studentId: $studentId
      formId: $formId
      schoolId: $schoolId
      studentMeetingId: $studentMeetingId
    ) {
      versionId
      formId
      formDate
      studentDob
      studentFullName
      visionScreeningDate
      visionScreeningStatus
      visionScreeningCorrected
      visionScreeningConductedBy
      hearingScreeningDate
      hearingScreeningStatus
      hearingScreeningCorrected
      hearingScreeningConductedBy
      determinantReading
      determinantMath
      determinantEnglish
      determinantEnvironmentalCultural
      formAction
      formStatus
      domains {
        domainTitle
        domainEvaluationResults
        disabilitiesForConsideration
      }
    }
  }
`;
