import { gql } from "@apollo/client";

export const PRINT_FORM3454V = gql`
  mutation printStudentForm3454V(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $studentDob: String
    $verbalStudentNeeds: String
    $verbalSupportsIdentified: String
    $socialStudentNeeds: String
    $socialSupportsIdentified: String
    $sensoryStudentNeeds: String
    $sensorySupportsIdentified: String
    $environmentalStudentNeeds: String
    $environmentalSupportsIdentified: String
    $activitiesStudentNeeds: String
    $activitiesSupportsIdentified: String
    $behavioralStudentNeeds: String
    $behavioralSupportsIdentified: String
    $otherStudentNeeds: String
    $otherSupportsIdentified: String
    $formAction: String
$formStatus: String
  ) {
    printStudentForm3454V(
      studentForm3454VInput: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        studentDob: $studentDob
        verbalStudentNeeds: $verbalStudentNeeds
        verbalSupportsIdentified: $verbalSupportsIdentified
        socialStudentNeeds: $socialStudentNeeds
        socialSupportsIdentified: $socialSupportsIdentified
        sensoryStudentNeeds: $sensoryStudentNeeds
        sensorySupportsIdentified: $sensorySupportsIdentified
        environmentalStudentNeeds: $environmentalStudentNeeds
        environmentalSupportsIdentified: $environmentalSupportsIdentified
        activitiesStudentNeeds: $activitiesStudentNeeds
        activitiesSupportsIdentified: $activitiesSupportsIdentified
        behavioralStudentNeeds: $behavioralStudentNeeds
        behavioralSupportsIdentified: $behavioralSupportsIdentified
        otherStudentNeeds: $otherStudentNeeds
        otherSupportsIdentified: $otherSupportsIdentified
formAction: $formAction
formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;
