export const ethnicities = [
  "American Indian",
  "Asian",
  "African American",
  "Hispanic",
  "Native Hawaiian",
  "White",
];
export const languagesPopular = [
  "English",
  "Spanish",
  "Chinese",
  "Tagalog",
  "Vietnamese",
  "French",
  "Arabic",
  "Korean",
  "Russian",
  "German",
];
//to do, a list of every conceivable language when someone complains about this
export const languagesAll = [""];
export const parentRelations = [
  "Father",
  "Mother",
  "Adoptive Parent",
  "Grandparent",
  "Other Relative",
  "Legal Guardian",
  "Foster Parent",
];
export const gender = ["Male", "Female", "Non-binary"];

export const timeSpans = [
  'Last Week',
  'This Week',
  'Next Week',
  'Last Month',
  'This Month',
  'Next Month',
  'Last Quarter',
  'This Quarter',
  'Next Quarter',
  'Last Year',
  'This Year',
  'Next Year',
  'Last School Week',
  'This School Week',
  'Next School Week',
  'Last School Year',
  'This School Year',
  'Next School Year',
  'Custom'
]