import React, { useContext } from "react";
import PropTypes from "prop-types";

// Contexts
import AuthContext from "../../../utils/authProvider";

const TextNameAuthEvaluated = ({ entity }) => {
  const Auth = useContext(AuthContext);
  const isSchoolsGeneral = Auth.hasRole("SCHOOLS_GENERAL");
  const getName = (entity) => isSchoolsGeneral ? entity.name : entity.nameGeneral;
  return <>{getName(entity)}</>;
};

TextNameAuthEvaluated.propTypes = {
  entity: PropTypes.object,
};

export default TextNameAuthEvaluated;
