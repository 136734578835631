// react
import React, { useEffect, useContext } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

// ant design
import { Modal } from "antd";

// contexts
import ApolloContext from "../../utils/apolloProvider";

// components
import Page from "../../components/Page";

// apollo & operations
import { QUERY_GRADE } from "../../operations/queries/grades";
import { UPDATE_GRADE } from "../../operations/mutations/grades";

// query params
const useQuery = () => new URLSearchParams(useLocation().search);

const GradeDelete = (props) => {
  const apollo = useContext(ApolloContext);
  const history = useNavigate();
  const { id } = useParams();
  const query = useQuery();
  const breadcrumb = [
    {
      path: "/",
      breadcrumbName: "Home",
    },
    {
      path: "/grades",
      breadcrumbName: "Grades",
    },
    {
      path: "",
      breadcrumbName: "Delete grade",
    },
  ];

  const deleteGrade = async () => {
    try {
      const response = await apollo.client.query({
        query: QUERY_GRADE,
        variables: { id },
      });
      const grade = { ...response.data.grade };
      // @todo should only update hidden
      await apollo.client.mutate({
        mutation: UPDATE_GRADE,
        variables: { id: id, gradeInput: { email: grade.email, hidden: true } },
      });
      Modal.success({
        title: "Success",
        content: "The grade has been hidden.",
        okText: "Return to listing",
        onOk: () => history.replace("/grades"),
      });
    } catch (e) {
      Modal.error({
        title: "An error has occurred",
        content: "The grade has not been hidden.",
        okText: "Return to listing",
        onOk: () => history.replace("/grades"),
      });
    }
  };

  useEffect(() => {
    if (query.get("confirm") === "1") {
      deleteGrade();
    }
  }, []);

  return (
    <>
      <Page title="Delete grade" breadcrumb={breadcrumb} />
    </>
  );
};

export default GradeDelete;
