// react
import React, { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'

// Contexts
import AuthContext from '../../../utils/authProvider'
import { useLazyQuery, useQuery } from '@apollo/client'
import { useReadQuery } from '@apollo/client'
// ant design
import { Card, Button, Tag, Tooltip, Space, Input, Table, Row, Col } from 'antd'

// operations
import { QUERY_STUDENTS } from '../../../operations/queries/students'

// components
import StudentSearchForm from './StudentSearchForm'
import Listing from '../../Listing'
import { DesktopOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { ADD_STUDENT_TO_DESKTOP } from '../../../operations/mutations/meetings'
import {
  notificationError,
  notificationInfoAlt,
  notificationSuccess
} from '../../../utils/notification'
import moment from 'moment'

const StudentList = () => {
  const Auth = useContext(AuthContext)
  const desktopData = Auth.desktopData
  if (desktopData === null) {
    Auth.fetchDesktopData()
  }

  const isSuperAdmin = Auth.hasRole('SUPER_ADMIN')
  const isSchoolsGeneral = Auth.hasRole('SCHOOLS_GENERAL')
  const [criterias, setCriterias] = useState({})

  const [addStudentToMyDesktop] = useMutation(ADD_STUDENT_TO_DESKTOP, {
    onCompleted: () => {
      Auth.fetchDesktopData()
      notificationSuccess('Student added to dashboard', '')
    },
    onError: (e) => {
      notificationError('Failed to add student to dashboard', e)
    }
  })
  const canAddStudents = Auth.hasPermission('ADD_STUDENTS')

  const extra = (
    <>
      {canAddStudents && (
        <Tooltip title='Add new student'>
          <Link to='../student/add'>
            <Button type='primary'>Add Student</Button>
          </Link>
        </Tooltip>
      )}
    </>
  )

  const [pagination, setPagination] = useState({ offset: 0, limit: 10 });

  const [getStudentData, { loading, data, error }] = useLazyQuery(QUERY_STUDENTS, {
    variables: { ...criterias, ...pagination },
    fetchPolicy: 'cache-first',
    enabled: false,
    staleTime: Infinity
  });
  const [dataSource, setDataSource] = useState(data?.students);
  const [value, setValue] = useState('');

  // const data = useQuery({ query: QUERY_STUDENTS })
  useEffect(async () => {
    await getStudentData('students')
    setDataSource(data?.students)
  }, [data]);
  const getFullName = record =>
    isSchoolsGeneral ? record.fullName : record.fullNameGeneral
  const getGrade = record => (record.grade ? record.grade : '')
  const getNumericGrade = (grade) => {
    let gradeValue = -99
    if (grade != undefined || null) {
      if (grade.slice(0, 5) === 'Grade') {
        gradeValue = parseInt(grade.replace('Grade', '').trim()) //Grade 1-12
      } else {
        switch (grade.toLowerCase()) {
          case 'early childhood':
            gradeValue = -3
            break
          case 'pre kindergarten':
            gradeValue = -2
            break
          case 'kindergarten':
            gradeValue = -1
            break
          default:
            gradeValue = -98
            break
        }
      }
    }
    return gradeValue
  }
  const filterTheSchools = () => {
    let setSchools = new Set()
    let antdSchools = []
    data?.students?.map(e => {
      if (e.schoolName != undefined || null) {
        setSchools.add(e.schoolName)
      }
    })
    const filterSchools = [...setSchools]
    filterSchools?.map(e => {
      antdSchools.push({ text: e, value: e })
    })
    return antdSchools
  }

  const filterTheGrades = () => {
    let gradesList = []
    data?.students?.map(student => {  //Load an array of grades with numeric equivalent
      if (student.grade != undefined || null) {
        const gradeValue = getNumericGrade(student.grade)
        gradesList.push([student.grade, gradeValue])
      }
    })
    gradesList = gradesList.sort((a, b) => a[1] - b[1]);  //Sort array of grades by gradeValue

    const gradesSet = new Set() //Load a set of unique grades from the list of grades
    gradesList.map(grade => {
      gradesSet.add(grade[0])
    })

    const antdGrades = [] //Load the antd grades filters
    gradesSet.forEach((grade) => {
      antdGrades.push({ text: grade, value: grade })
    })
    return antdGrades
  }

  const getStudentCode = record => (record.studentCode ? record.studentCode : '')
  //const getSchoolName = record => (record.schools ? record.schools[0].name : '')
  const getSchoolName = record => (record.schoolName ? record.schoolName : '')
  const getDateOfBirth = record => moment(record.dateOfBirth).format('M/D/YYYY')

  const columns = [
    {
      title: 'Full name',
      dataIndex: 'fullName',
      sorter: {
        compare: (a, b) => a.fullName.localeCompare(b.fullName),
        multiple: 2,
      },
      render: (value, record) => (
        <>
          <Link to={`../student/${record.id}`}>{getFullName(record)}</Link>
          &nbsp;
          {isSuperAdmin && record.hidden && <Tag color='orange'>Hidden</Tag>}

        </>
      )
    },
    {
      title: 'Student Code',
      dataIndex: 'studentCode',
      sorter: (a, b) => getStudentCode(a).localeCompare(getStudentCode(b)),
      render: (value, record) => <>{getStudentCode(record)} </>
    },
    {
      title: 'Date of Birth',
      dataIndex: 'dateOfBirth',
      sorter: {
        //sorter: (a, b) => getDateOfBirth(a).localeCompare(getDateOfBirth(b)), //Original. 10.8sec to sort 61k recs
        compare: (a, b) => new Date(a.dateOfBirth) - new Date(b.dateOfBirth),  //Revised. 2.3sec to sort 61k recs
        multiple: 1,
      },
      render: (value, record) => <>{getDateOfBirth(record)}</>
    },
    {
      title: 'Grade',
      dataIndex: 'grade',
      sorter: {
        compare: (a, b) => getNumericGrade(a.grade) - getNumericGrade(b.grade),
        multiple: 3,
      },
      filters: filterTheGrades(),
      onFilter: (value, record) => {
        return record.grade ? record.grade === value : false
      },
      //sorter: (a, b) => getGrade(a).localeCompare(getGrade(b)),
      render: (value, record) => <>{getGrade(record)} </>
    },
    {
      title: 'School',
      dataIndex: 'schoolName',
      sorter: {
        compare: (a, b) => getSchoolName(a).localeCompare(getSchoolName(b)),
        multiple: 4,
      },
      filters: filterTheSchools(),
      onFilter: (value, records) => {
        if (records?.schoolName) {
          return records.schoolName.indexOf(value) === 0
        }
      },
      render: (value, record) => <>{getSchoolName(record)} </>
    },
    {
      name: 'Desktop',
      title: 'Action',
      dataIndex: 'id',
      render: id => (
        <Space size='middle'>
          <Button
            type='primary'
            onClick={() => {

              addStudentToMyDesktop({ variables: { studentId: id } })
            }}
          >
            Add to Dashboard <DesktopOutlined />
          </Button>
        </Space>
      )
    }
  ]
  const reset = () => {
    setDataSource(data.students)
    setValue('')
  }
  return (
    <>
      <div className='page-container'>
        <Card title='Students' bordered={false} extra={extra}>
          <Row justify={'space-left'} style={{ marginBottom: '1rem' }}>
            <Col span={6}>
              <Input
                placeholder="Search by name, student code or date of birth"
                value={value}
                spellCheck={false}
                onChange={e => {
                  const currValue = e.target.value;
                  setValue(currValue);
                  let arr = []
                  data?.students?.filter(person => {
                    let [word1, word2] = currValue.split(' ');
                    word1 = word1 ? word1.trim().toLowerCase() : '';
                    word2 = word2 ? word2.trim().toLowerCase() : '';
                    const firstName = person.firstName ? person.firstName.toLowerCase().trim() : '';
                    const lastName = person.lastName ? person.lastName.toLowerCase().trim() : '';
                    const studentCode = person.studentCode ? person.studentCode.toString().toLowerCase().trim() : '';
                    const dateOfBirth = person.dateOfBirth ? new Date(person.dateOfBirth).toLocaleDateString("en-US") : '';
                    if (!word2) { //Single word search.
                      if (
                        firstName.includes(word1) ||
                        lastName.includes(word1) ||
                        studentCode.includes(word1) ||
                        dateOfBirth.substring(0, word1.length) === (word1)
                      ) {
                        arr.push(person)
                      }
                    } else {  //Two word search.  Can be first/last or last/first.
                      if (
                        (firstName === word1 &&
                          lastName.includes(word2)) ||
                        (lastName === word1 &&
                          firstName.includes(word2))
                      ) {
                        arr.push(person)
                      }
                    }
                  });
                  setDataSource(arr);
                }}
              />

            </Col>
            <Col>
              <Button
                type='default'
                style={{ marginLeft: '1rem' }}
                onClick={reset}
              >
                Reset
              </Button>
            </Col>
          </Row>

          <Table
            columns={columns}
            dataKey='students'
            dataSource={dataSource}
            loading={loading}
            //onChange={(pagination,filters,sorter,extra) => {console.log('Table onChange:',pagination,filters,sorter,extra)} }
            pagination={{
              showSizeChanger: true, //If excluded, antd turns this on automatically if total > 50
              // showQuickJumper: true,
              showTotal: (data) => `${data.toLocaleString("en")} Students`,
              position: ["bottomRight"],
              defaultPageSize: 20,
            }}
          />
        </Card>
      </div>
    </>
  )
}

export default StudentList
