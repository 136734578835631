import React from 'react'
import { useParams, useLocation } from 'react-router-dom'

import Page from '../../components/Page'
import StudentCard from '../../components/sections/student/StudentCard'
import StudentGoals from '../../components/sections/student/StudentGoals'
import { QUERY_STUDENT } from "../../operations/queries/students";
import { useQuery } from '@apollo/client'

const StudentProfile = () => {
  const { id } = useParams()
  const title = id ? 'Student' : 'Add student'
  const { loading, data } = useQuery(QUERY_STUDENT, {
    variables: { id, includeGoals: false },
    skip: !id
  })
  let lastName = data?.student.lastName
  const breadcrumb = [
    {
      path: '/',
      breadcrumbName: 'Home'
    },
    {
      path: '/students',
      breadcrumbName: 'Students'
    },
    {
      path: '',
      breadcrumbName: lastName
    }
  ]


  return (
    <>
      <Page title={title} breadcrumb={breadcrumb}>
        <div className='page-container'>
          <StudentCard id={id} />
        </div>
        <div className='page-container'>
          <StudentGoals id={id} />
        </div>
      </Page>
    </>
  )
}

export default StudentProfile
