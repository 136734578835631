import React from "react";

import Page from "../../components/Page";
import DocumentList from "../../components/sections/document/DocumentList";

const Documents = () => {
  const breadcrumb = [
    {
      path: "/",
      breadcrumbName: "Home",
    },
    {
      path: "",
      breadcrumbName: "Documents",
    },
  ];
  return (
    <>
      <Page title="Documents" breadcrumb={breadcrumb}>
        <DocumentList />
      </Page>
    </>
  );
};

export default Documents;
