import { gql } from "@apollo/client";

export const PRINT_FORM3454B = gql`
  mutation printStudentForm3454B(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $studentDob: String
    $lackInstructionReading: Boolean
    $evidenceLackInstructionReading: String
    $lackInstructionMath: Boolean
    $evidenceLackInstructionMath: String
    $englishLearnerStatus: Boolean
    $evidenceEnglishLearnerStatus: String
    $disibilityIdentified: Boolean
    $primaryAutism: Boolean
    $primaryDeaf: Boolean
    $primaryDeafness: Boolean
    $primaryDevelopmentDelay: Boolean
    $primaryEmotionalDisability: Boolean
    $primaryHearingIMpairment: Boolean
    $primaryItellactualDisability: Boolean
    $primaryMultipleDisabilities: Boolean
    $primaryOrthopedicImpairment: Boolean
    $primaryOtherHealthImpairment: Boolean
    $primarySpeechImpairment: Boolean
    $primaryBrainInjury: Boolean
    $primaryVisualImpairment: Boolean
    $secondaryAutism: Boolean
    $secondaryDeaf: Boolean
    $secondaryDeafness: Boolean
    $secondaryDevelopmentDelay: Boolean
    $secondaryEmotionalDisability: Boolean
    $secondaryHearingIMpairment: Boolean
    $secondaryItellactualDisability: Boolean
    $secondaryMultipleDisabilities: Boolean
    $secondaryOrthopedicImpairment: Boolean
    $secondaryOtherHealthImpairment: Boolean
    $secondarySpeechImpairment: Boolean
    $secondaryBrainInjury: Boolean
    $secondaryVisualImpairment: Boolean
    $adverseEffects: Boolean
    $adverseEffectsDescription: String
    $educationalNeedsDescription: String
    $eligibility: Boolean
    $signatureName: String
    $signatureTitle: String
    $formAction: String
    $formStatus: String
  ) {
    printStudentForm3454B(
      studentForm3454BInput: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        studentDob: $studentDob
        lackInstructionReading: $lackInstructionReading
        evidenceLackInstructionReading: $evidenceLackInstructionReading
        lackInstructionMath: $lackInstructionMath
        evidenceLackInstructionMath: $evidenceLackInstructionMath
        englishLearnerStatus: $englishLearnerStatus
        evidenceEnglishLearnerStatus: $evidenceEnglishLearnerStatus
        disibilityIdentified: $disibilityIdentified
        primaryAutism: $primaryAutism
        primaryDeaf: $primaryDeaf
        primaryDeafness: $primaryDeafness
        primaryDevelopmentDelay: $primaryDevelopmentDelay
        primaryEmotionalDisability: $primaryEmotionalDisability
        primaryHearingIMpairment: $primaryHearingIMpairment
        primaryItellactualDisability: $primaryItellactualDisability
        primaryMultipleDisabilities: $primaryMultipleDisabilities
        primaryOrthopedicImpairment: $primaryOrthopedicImpairment
        primaryOtherHealthImpairment: $primaryOtherHealthImpairment
        primarySpeechImpairment: $primarySpeechImpairment
        primaryBrainInjury: $primaryBrainInjury
        primaryVisualImpairment: $primaryVisualImpairment
        secondaryAutism: $secondaryAutism
        secondaryDeaf: $secondaryDeaf
        secondaryDeafness: $secondaryDeafness
        secondaryDevelopmentDelay: $secondaryDevelopmentDelay
        secondaryEmotionalDisability: $secondaryEmotionalDisability
        secondaryHearingIMpairment: $secondaryHearingIMpairment
        secondaryItellactualDisability: $secondaryItellactualDisability
        secondaryMultipleDisabilities: $secondaryMultipleDisabilities
        secondaryOrthopedicImpairment: $secondaryOrthopedicImpairment
        secondaryOtherHealthImpairment: $secondaryOtherHealthImpairment
        secondarySpeechImpairment: $secondarySpeechImpairment
        secondaryBrainInjury: $secondaryBrainInjury
        secondaryVisualImpairment: $secondaryVisualImpairment
        adverseEffects: $adverseEffects
        adverseEffectsDescription: $adverseEffectsDescription
        educationalNeedsDescription: $educationalNeedsDescription
        eligibility: $eligibility
        signatureName: $signatureName
        signatureTitle: $signatureTitle
        formAction: $formAction
        formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;
