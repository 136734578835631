import { gql } from "@apollo/client";

export const PRINT_FORM3454C = gql`
  mutation printStudentForm3454C(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $studentDob: String
    $districtImplementedScientificProcess: String
    $districtImplementedServiceDiscrepancyModel: String
    $severeDiscrepancyExists: String
    $severeDiscrepancyAreas: String
    $problemDescription: String
    $problemAnalysis: String
    $problemSolving: String
    $interventions: String
    $educationalProgress: String
    $discrepancy: String
    $instructionalNeeds: String
    $additionalInformation: String
    $signatureName: String
    $signatureTitle: String
    $formAction: String
    $formStatus: String
  ) {
    printStudentForm3454C(
      studentForm3454CInput: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        studentDob: $studentDob
        districtImplementedScientificProcess: $districtImplementedScientificProcess
        districtImplementedServiceDiscrepancyModel: $districtImplementedServiceDiscrepancyModel
        severeDiscrepancyExists: $severeDiscrepancyExists
        severeDiscrepancyAreas: $severeDiscrepancyAreas
        problemDescription: $problemDescription
        problemAnalysis: $problemAnalysis
        problemSolving: $problemSolving
        interventions: $interventions
        educationalProgress: $educationalProgress
        discrepancy: $discrepancy
        instructionalNeeds: $instructionalNeeds
        additionalInformation: $additionalInformation
        signatureName: $signatureName
        signatureTitle: $signatureTitle
        formAction: $formAction
        formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;
