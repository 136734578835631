import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useLazyQuery } from "@apollo/client";

import { Form, Input } from "antd";
import { SyncOutlined } from "@ant-design/icons";

import { QUERY_STUDENT_NAME } from "../../../../operations/queries/forms/SIS";
import SyncSISButton from "../../../elements/formItems/SyncSISButton";

const StudentFullNameSIS = ({
  form,
  sis,
  id,
  initialValue,
  error,
  disabled = true,
}) => {
  const [studentFullName, setStudentFullName] = useState(initialValue);
  const [getStudentName, { data: studentNameData, loading: nameLoading }] =
    useLazyQuery(QUERY_STUDENT_NAME);

  useEffect(() => {
    if (!studentNameData) return;
    form.setFieldsValue({
      studentFullName: sis?.student.fullName || "",
      //studentFullName: studentNameData?.student.fullName || "",
    });
    setStudentFullName(studentNameData?.student.fullName || "");
  }, [studentNameData]);

  return (
    <Form.Item
      label="Student Name"
      name="studentFullName"
      validateStatus={error ? "error" : ""}
      help={error}
    >
      <Input
        onChange={(e) => setStudentFullName(e.target.value)}
        disabled={disabled}
        addonAfter={
          <SyncSISButton
            loading={nameLoading}
            sisValue={sis?.student.fullName}
            currentValue={studentFullName}
          >
            <SyncOutlined
              onClick={() => getStudentName({ variables: { id: id } })}
            />
          </SyncSISButton>
        }
      />
    </Form.Item>
  );
};

StudentFullNameSIS.propTypes = {
  form: PropTypes.object.isRequired,
  initialValue: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  error: PropTypes.string,
  disabled: PropTypes.bool,
};

export default StudentFullNameSIS;
