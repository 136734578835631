// react
import { React } from 'react'

// providers & contexts
import { AppProvider } from './utils/appProvider'
import { AuthProvider } from './utils/authProvider'
import { ApolloProvider } from './utils/apolloProvider'
import { IepFormProvider } from './utils/iepFormProvider'
// layouts
import Layout from './components/layout/Layout'

// styles
import './assets/styles/index.less'

function App () {
  return (
    <ApolloProvider>
      <AuthProvider>
        <AppProvider>
          <IepFormProvider>
              <Layout />
          </IepFormProvider>
        </AppProvider>
      </AuthProvider>
    </ApolloProvider>
  )
}

export default App
