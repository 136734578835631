import React from "react";
import { useParams } from "react-router-dom";

import Page from "../../components/Page";
import DistrictFormForm from "../../components/sections/districtForm/DistrictFormForm";

const DistrictForm = () => {
  const { id } = useParams();
  const title = id ? "Edit District form" : "Add District form";

  const breadcrumb = [
    {
      path: "/",
      breadcrumbName: "Home",
    },
    {
      path: "/districtforms",
      breadcrumbName: "District forms",
    },
    {
      path: "",
      breadcrumbName: title,
    },
  ];
  return (
    <>
      <Page title={title} breadcrumb={breadcrumb}>
        <div className="page-container">
          <div className="page-padding-leftRight page-padding-topBottom">
            <DistrictFormForm />
          </div>
        </div>
      </Page>
    </>
  );
};

export default DistrictForm;
