// react
import React, { useContext } from "react";
import PropTypes from "prop-types";

// ant design
import { Drawer, Layout } from "antd";
import SideMenu from "./SideMenu";

// contexts
import AppContext from "../../utils/appProvider";

// images
import DistrictLogo from "../../assets/images/tames_assist_logo.png";

const SideBar = ({ theme, menu, collapsed, onClose, showSider }) => {
  const App = useContext(AppContext);
  const { name } = App.useCurrent();
  const onClick = (e) => {
    if (e.target.nodeName === "A") {
      onClose();
    }
  };
  return (
    <>
      {/* <div className="appMenu-logo" style={{ marginTop: "8px" }}>
        <img
          className="district-logo"
          src={DistrictLogo}
          alt="District Logo"
          style={{ boxShadow: "1px 1px 4px #ccc" }}
        />
      </div> */}
      <SideMenu mode="horizontal" key='1' theme={theme} />
      {/* {!!showSider && (
        <Layout.Sider
          theme={theme}
          breakpoint="lg"
          collapsedWidth="0"
          trigger={null}
        //style={{ backgroundColor: 'rgb(0, 142, 150)' }} //Uncomment this to get teal sidebar
        >
          <div className="appMenu-logo2" style={{ marginTop: "8px" }}>
            <img
              className="district-logo"
              src={DistrictLogo}
              alt="District Logo"
            />
          </div>
          <div className="sideMenu-name">
            <h4 style={{ color: 'white' }}>{name}</h4>
          </div>
          <SideMenu mode="horizontal" theme={theme} />
        </Layout.Sider>
      )}

      <Drawer
        placement="left"
        onClick={onClick}
        onClose={onClose}
        closable={false}
        visible={collapsed}
        bodyStyle={{ padding: 0 }}
        width="200"
      >
        <Layout.Sider
          theme={theme}
          trigger={null}
          width="100%"
          style={{ height: "100%" }}
        >
          <div className="appMenu-logo" style={{ marginTop: "8px" }}>
            <img
              className="district-logo"
              src={DistrictLogo}
              alt="District Logo"
              style={{ boxShadow: "1px 1px 4px #ccc" }}
            />
          </div>
          <div className="sideMenu-name">
            <h4>{name}</h4>
          </div>
          <SideMenu mode="horizontal" theme={theme} />
        </Layout.Sider>
      </Drawer> */}
    </>
  );
};

SideBar.propTypes = {
  onClose: PropTypes.func,
  collapsed: PropTypes.bool,
  theme: PropTypes.string,
  menu: PropTypes.element.isRequired,
  showSider: PropTypes.bool,
};

export default SideBar;
