import { gql } from "@apollo/client";

export const PRINT_FORM3457A = gql`
  mutation printStudentForm3457A(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $referrerName: String
    $referrerTitle: String
    $evaluationReasonDescription: String
    $studentFullName: String
    $studentDob: String
    $parent1: String
    $parent2: String
    $evaluationRequestDate: String
    $evaluationDecisionDescription: String
    $evaluationStatus: String
    $reEvaluationStatus: String
    $documentContactName: String
    $documentContactTitle: String
    $documentContactPhone: String
    $signatureName: String
    $signatureTitle: String
    $parentCopy: Boolean
    $formAction: String
$formStatus: String
  ) {
    printStudentForm3457A(
      studentForm3457AInput: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        referrerName: $referrerName
        referrerTitle: $referrerTitle
        evaluationReasonDescription: $evaluationReasonDescription
        studentFullName: $studentFullName
        studentDob: $studentDob
        parent1: $parent1
        parent2: $parent2
        evaluationRequestDate: $evaluationRequestDate
        evaluationDecisionDescription: $evaluationDecisionDescription
        evaluationStatus: $evaluationStatus
        reEvaluationStatus: $reEvaluationStatus
        documentContactName: $documentContactName
        documentContactTitle: $documentContactTitle
        documentContactPhone: $documentContactPhone
        signatureName: $signatureName
        signatureTitle: $signatureTitle
        parentCopy: $parentCopy
formAction: $formAction
formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;
