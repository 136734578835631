import { gql } from "@apollo/client";

export const QUERY_FORM3454I_VERSION = gql`
  query studentForm3454I(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
  ) {
    studentForm3454I(
      studentFormId: $studentFormId
      studentId: $studentId
      formId: $formId
      schoolId: $schoolId
      studentMeetingId: $studentMeetingId
    ) {
      versionId
      formId
      formDate
      studentFullName
      studentDob
      providedCareerAndTechnicalEducationInfo
      cteInfoIncludedOptions
      instruction
      instructionProviderAgency
      instructionGoalNumber
      instructionDateYearAddressed
      instructionDateYearCompleted
      relatedServices
      relatedServicesProvider
      relatedServicesGoalNumber
      relatedServicesDateYearAddressed
      relatedServicesDateYearCompleted
      communityExperiences
      communityExperiencesProvider
      communityExperiencesGoalNumber
      communityExperiencesDateYearAddressed
      communityExperiencesDateYearCompleted
      livingObjectives
      livingObjectivesProviderAgency
      livingObjectivesGoalNumber
      livingObjectivesDateYearAddressed
      livingObjectivesDateYearCompleted
      dailyLivingSkills
      dailyLivingSkillsProviderAgency
      dailyLivingSkillsGoalNumber
      dailyLivingSkillsDateYearAddressed
      dailyLivingSkillsDateYearCompleted
      linkages
      linkagesProviderAgency
      linkagesGoalNumber
      linkagesDateYearAddressed
      linkagesDateYearCompleted
      studentDevelopmentalDisability
      studentMetIndependentServiceCoordinator
      iscName
      iscContactInfo
      studentRegiseteredForPotentialFunding
      studentRecordsUpToDate
      studentFamiliarWithLocalServiceProviders
      iscInquiredAboutPlanningForServices
      iscDiscussedHomeBasedServices
      studentDevelopmentalDisabilityHomeBasedServices
      studentDevelopmentalDisabilityEnrollingHomeBasedServices
      studentDevelopmentalDisabilityEffectiveUseHomeBasedServices
      formAction
      formStatus
    }
  }
`;
