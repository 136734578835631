import React, { useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Card, Row, Col, Layout } from "antd";

// components
import Page from "../../components/Page";
import ForgotPasswordForm from "../../components/sections/login/ForgotPasswordForm";
import AuthContext from "../../utils/authProvider";
// components
//Should Auth.signedIn === be false instead of true?  It displays ForgotPasswordForm only if logged in?
const ForgotPassword = () => {
  const Auth = useContext(AuthContext)
  return (
    <Layout className="content-layout">
      <Page>
        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 18, offset: 3 }}
            md={{ span: 14, offset: 5 }}
            lg={{ span: 12, offset: 6 }}
            xl={{ span: 10, offset: 7 }}
            xxl={{ span: 8, offset: 8 }}
          >
            <div className="page-container page-container-card">
              <Card title="Forgot password">
              <ForgotPasswordForm />
                {/* {Auth.signedIn === false ? (<><ForgotPasswordForm /></>) : (<> Please contact your administrator to have your password reset <div className="card-bottomLink">
                  <Link to="/signin">Go back to sign-in</Link>
                </div></>)} */}
              </Card>
            </div>
          </Col>
        </Row>
      </Page>
    </Layout>
  );
};

export default ForgotPassword;
