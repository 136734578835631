import { gql } from "@apollo/client";

export const PRINT_FORM3457O = gql`
  mutation printStudentForm3457O(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $studentDob: String
    $parent1: String
    $parent2: String
    $servicesNotImplemented: [StudentForm3457OServicesNotImplementedInput]
    $explanationOfCompensatoryServicesProcedure: String
    $nonComplianceContactName: String
    $nonComplianceContactTitle: String
    $nonComplianceContactPhone: String
    $districtRepresentativeName: String
    $districtRepresentativeTitle: String
    $formAction: String
$formStatus: String
  ) {
    printStudentForm3457O(
      studentForm3457OInput: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        studentDob: $studentDob
        parent1: $parent1
        parent2: $parent2
        servicesNotImplemented: $servicesNotImplemented
        explanationOfCompensatoryServicesProcedure: $explanationOfCompensatoryServicesProcedure
        nonComplianceContactName: $nonComplianceContactName
        nonComplianceContactTitle: $nonComplianceContactTitle
        nonComplianceContactPhone: $nonComplianceContactPhone
        districtRepresentativeName: $districtRepresentativeName
        districtRepresentativeTitle: $districtRepresentativeTitle
formAction: $formAction
formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;
