import { gql } from "@apollo/client";

export const PRINT_FORM3454E = gql`
  mutation printStudentForm3454E(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $needsDifferent: Boolean
    $needsDifferentAreas: String
    $achievementDiscrepancy: String
    $achievementDiscrepancyAreas: String
    $specificLearningDisability: Boolean
    $specificLearningDisabilityArea: [String]
    $specializedInstruction: Boolean
    $memberCertify: [String]
    $memberCertifyName: [String]
    $formAction: String
$formStatus: String

  ) {
    printStudentForm3454E(
      studentForm3454EInput: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        needsDifferent: $needsDifferent
        needsDifferentAreas: $needsDifferentAreas
        achievementDiscrepancy: $achievementDiscrepancy
        achievementDiscrepancyAreas: $achievementDiscrepancyAreas
        specificLearningDisability: $specificLearningDisability
        specificLearningDisabilityArea: $specificLearningDisabilityArea
        specializedInstruction: $specializedInstruction
        memberCertify: $memberCertify
        memberCertifyName: $memberCertifyName

formAction: $formAction
formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;
