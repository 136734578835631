import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Form, Input, Button, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";

// Contexts
import { useMutation } from "@apollo/client";
import { EMAIL_USER_PASSWORD_RESET_LINK } from "../../../operations/mutations/users.js";
// other libraries
import { notificationError } from "../../../utils/notification";

const { Text } = Typography;

const ForgotPasswordForm = () => {
console.log("ForgotPasswordForm")
  const history = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState();
  const [sent, setSent] = useState(false);

  const [emailUserPasswordResetLink] = useMutation(
    EMAIL_USER_PASSWORD_RESET_LINK
  );

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const { email } = values;
      await emailUserPasswordResetLink({ variables: { email } });
      setSent(true);
    } catch (error) {
      const errorCode =
        error?.metadata?.graphQLErrors?.[0]?.extensions?.errorCode;
      if (errorCode === "NO_EMAIL_VERIFIED") {
        history(`/sendverification/${values.email}`);
      } else {
        const message =
          error?.metadata?.graphQLErrors?.[0]?.message ||
          "An unknown error occurred, please contact your administrator. E006";
        notificationError(
          "Reset password failed",
          message + ". Please contact your administrator."
        );
      }
    }
    form.resetFields();
    setLoading(false);
  };

  return (
    <Form name="resetPassword" onFinish={onFinish} form={form}>
      {!sent && (
        <>
          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                required: true,
                message: "Please enter your email",
              },
            ]}
          >
            <Input prefix={<UserOutlined />} placeholder="Email" />
          </Form.Item>

          <Form.Item style={{ marginBottom: "0" }}>
            <Button
              type="primary"
              htmlType="submit"
              className="card-form-button"
              loading={loading}
            >
              Submit
            </Button>
          </Form.Item>
        </>
      )}

      {sent && (
        <Form.Item style={{ marginTop: "12px" }}>
          <Text>Please check your inbox for further instructions.</Text>
        </Form.Item>
      )}

      <div className="card-bottomLink">
        <Link to="/signin">Go back to sign-in</Link>
      </div>
    </Form>
  );
};

export default ForgotPasswordForm;
