import React from 'react'

import Button from 'antd/lib/button'
import { Card, Typography, Col, Row, Progress } from 'antd'
import { Link } from "react-router-dom";
import moment from 'moment'

const { Text } = Typography
export const PriorGoals = ({ x }) => {
  return (
    <>
      {x != undefined ? (
        <>
          <Row>
            <Col span={24}>
              <Card
                title={`Student Goal: ${x.studentFormId}`}
                size='small'
                // bodyStyle={{ backgroundColor: 'rgba(232, 236, 241, .4)' }}
                style={{ height: '100%' }}
              >
                <Row>
                  <Col span={24}>
                      <Row>
                        <Col span={8}>
                          <Col>
                            <Text strong={true}>Goal Statement: &nbsp;</Text>
                            <Text>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: x?.submittedData.goalStatement
                                }}
                              />
                            </Text>
                          </Col>
                          <Col>
                            <Text strong={true}>Goal Number: &nbsp;</Text>
                            <Text>{x.submittedData.goalNumber}</Text>
                          </Col>
                          <Col>
                            <Text strong={true}>Goal Date: &nbsp;</Text>
                            <Text>
                              {' '}
                              {moment(x.submittedData.formDate).format(
                                'MM-DD-YYYY'
                              )}
                            </Text>
                          </Col>
                          <Col>
                            <Text strong={true}>
                              Initial Evaluation: &nbsp;
                            </Text>
                            <Text>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    x?.submittedData.initialEvaluationResults
                                }}
                              />
                            </Text>
                          </Col>
                        </Col>
                        <Col span={8}>
                          {x?.submittedData.evaluationMeasures.map(x => (
                            <>
                              {' '}
                              <Col>
                                {' '}
                                <Text strong={true}>
                                  Short Term Objective: &nbsp;
                                </Text>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: x?.shortTermObjective
                                  }}
                                />{' '}
                              </Col>
                              <Col>
                                <Text strong={true}>
                                  Evaluation Criteria Attempt: &nbsp;
                                </Text>
                                <Text>{x.evaluationCriteriaAttempts}</Text>
                              </Col>
                              <Col>
                                <Text strong={true}>
                                  Evaluation Criteria Total Attempts: &nbsp;
                                </Text>
                                <Text>{x.evaluationCriteriaTotalAttempts}</Text>
                              </Col>
                              <Col>
                                <Text strong={true}>
                                  Evaluation Criteria Accuracy Percentage:
                                  &nbsp;
                                </Text>
                                <Progress
                                  type='circle'
                                  width={50}
                                  percent={x.evaluationCriteriaAccuracyPercent}
                                ></Progress>
                              </Col>
                            </>
                          ))}
                        </Col>
                        <Col span={8}>
                          {x?.submittedData.evaluationMeasures.map(x => (
                            <>
                              <Col>
                                <Text strong={true}>
                                  Determing Progress Schedule: &nbsp;
                                </Text>
                                {x.scheduleDeterminingProgress.map(x => (
                                  <Text>{x}&nbsp;</Text>
                                ))}
                              </Col>
                              <Col>
                                <Text strong={true}>
                                  Evaluation Procedures: &nbsp;
                                </Text>
                                {x.evaluationProcedures.map(x => (
                                  <Text>{x}&nbsp;</Text>
                                ))}
                              </Col>
                              <Col>
                                <Text strong={true}>
                                  Evaluation Prodedure Description: &nbsp;
                                </Text>
                                <Text>{x.evaluationProcedureOtherText}</Text>
                              </Col>
                              <Col>
                                <Text strong={true}>
                                  Determining Progress Description: &nbsp;
                                </Text>
                                <Text>
                                  {x.scheduleDeterminingProgressOtherText}
                                </Text>
                              </Col>
                              <Col>
                                <Text strong={true}>
                                  Goal Implementer: &nbsp;
                                </Text>
                                <Text>{x.goalImplementers}</Text>
                              </Col>
                            </>
                          ))}
                          <Col>
                            <Text strong={true}>
                              Illinois Learning Standard: &nbsp;
                            </Text>
                            <Text>
                              {x.submittedData.illinoisLearningStandard}
                            </Text>
                          </Col>
                        </Col>
                      </Row>
                      {/* <Row justify='center'>
                  <Col style={{ padding: '10px' }}>
                    <Link to={{
                      pathname: `/student-form/${x?.submittedData.studentMeetingId}`,
                      state: {priorGoal: x.submittedData}
                    }} ><Button>Edit Progress Update</Button></Link>
                  </Col>
                </Row> */}
                  </Col>
                  {/* to be determined how we render this */}
                  {/* {x?.submittedData.progressUpdates.map(x => (
                    <Col span={8}>
                      <Card
                        title={`Progress report: ${x.progressReport}`}
                        size='small'
                        style={{ height: '100%' }}
                      >
                         <Col>
                            <Text strong={true}>Progress Update Date: &nbsp;</Text>
                            <Text>
                              {' '}
                              {moment(x.formDate).format(
                                'MM-DD-YYYY'
                              )}
                            </Text>
                          </Col>
                        {x.makingExpectedProgress === '' ? (
                          <></>
                        ) : (
                          <>
                            <Col>
                              <Text strong={true}>
                                Making Expected Progress:{' '}
                              </Text>
                              <Text>{x.makingExpectedProgress}</Text>
                            </Col>
                          </>
                        )}

                        {x.notMakingExpectedProgress === '' ? (
                          <></>
                        ) : (
                          <>
                            <Col>
                              <Text strong={true}>
                                Not Making Expected Progress:{' '}
                              </Text>
                              <Text>{x.notMakingExpectedProgress}</Text>
                            </Col>
                          </>
                        )}
                        {x.completed === '' ? (
                          <></>
                        ) : (
                          <>
                            <Col>
                              <Text strong={true}>Completed: </Text>
                              <Text>{x.completed}</Text>
                            </Col>
                          </>
                        )}

                        <Col>
                          <Text strong={true}>Type of Report: </Text>
                          <Text>{x.typeOfReport}</Text>
                        </Col>

                        <Col>
                          <Text strong={true}>Additional Comments: </Text>
                          <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    x?.additionalComments
                                }}
                              />
                        </Col>
                      </Card>
                    </Col>
                  ))}*/}
                 </Row> 
                 <Row justify='center'>
               {/* this button doesn't work if it's an old meeting */}
                    <Col style={{ padding: '10px' }}>
                    <Link 
                    to={`/student-form/${x.submittedData.studentMeetingId}`}
                    state={{priorGoal: x.submittedData}}
                     ><Button>Edit Goal</Button></Link>
                  </Col>

                </Row>
              </Card>
            </Col>
          </Row>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

//saving for possible mobile view

{/* <Col span={24}>
<Card title={'Student Goal'} size='small'>
  <Col span={24}>
    <Text strong={true}>Goal Statement: &nbsp;</Text>
    <Text>
      <div
        dangerouslySetInnerHTML={{
          __html: formData3454M.studentForm3454M.goalStatement
        }}
      />
    </Text>
  </Col>
  <Col span={24}>
    <Text strong={true}>Goal Number: &nbsp;</Text>
    <Text>{formData3454M?.studentForm3454M?.goalNumber}</Text>
  </Col>
  <Col span={24}>
    <Text strong={true}>Goal Total: &nbsp;</Text>
    <Text>{formData3454M?.studentForm3454M?.goalTotal}</Text>
  </Col>
  <Col span={24}>
    <Text strong={true}>Goal Implementers: &nbsp;</Text>
    <Text>
      {formData3454M?.studentForm3454M?.goalImplementers}
    </Text>
  </Col>
  <Col span={24}>
    <Text strong={true}>Illinois Learning Standard: &nbsp;</Text>
    <Text>
      {formData3454M?.studentForm3454M?.illinoisLearningStandard}
    </Text>
  </Col>
  <Col span={24}>
    <Text strong={true}>Initial Evaluation Results: &nbsp;</Text>
    <div
      dangerouslySetInnerHTML={{
        __html:
          formData3454M?.studentForm3454M
            ?.initialEvaluationResults
      }}
    />
  </Col>
  <Col span={24}>
    <Text strong={true}>Goal Area: &nbsp;</Text>
    {formData3454M?.studentForm3454M?.goalArea.map(x => (
      <>
        <Text>{x}&nbsp;</Text>
      </>
    ))}
  </Col>
  <Col span={24}>
    <Text strong={true}>Parent Notification Methods: &nbsp;</Text>
    {formData3454M?.studentForm3454M?.parentNotificationMethods.map(
      x => (
        <Text>{x}&nbsp;</Text>
      )
    )}
  </Col>
</Card>
</Col>

{formData3454M?.studentForm3454M?.evaluationMeasures.map(x => (
<Col span={24}>
  <Card title={'Evaluation Measure'} size='small'>
    <Col span={24}>
      <Text strong={true}>Short Term Objectives: &nbsp;</Text>
      <div
        dangerouslySetInnerHTML={{
          __html: x.shortTermObjective
        }}
      />
    </Col>
    <Col span={24}>
      <Text strong={true}>Dates Reviewed: &nbsp;</Text>
      <div
        dangerouslySetInnerHTML={{
          __html: x.datesReviewed
        }}
      />
    </Col>
    <Col span={24}>
      <Text strong={true}>
        Evaluation Criteria Accuracy Percentage: &nbsp;
      </Text>
      <Progress
        type='circle'
        width={50}
        percent={x.evaluationCriteriaAccuracyPercent}
      ></Progress>
    </Col>
    <Col span={24}>
      <Text strong={true}>
        Number of Evaluation Criteria Attempts: &nbsp;
      </Text>
      <Text>{x.evaluationCriteriaAttempts}</Text>
    </Col>
    <Col span={24}>
      <Text strong={true}>
        Total Evaluation Criteria Attempts: &nbsp;
      </Text>
      <Text>{x.evaluationCriteriaTotalAttempts}</Text>
    </Col>
    {x.evaluationCriteriaOther === true ? (
      <Col span={24}>
        <Text strong={true}>
          Other Evaluation Procedure Text: &nbsp;
        </Text>
        <Text>{x.evaluationCriteriaOtherText}</Text>
      </Col>
    ) : (
      <></>
    )}
    <Col span={24}>
      <Text strong={true}>
        Evaluation Procedure Description: &nbsp;
      </Text>
      <Text>{x.evaluationProcedureOtherText}</Text>
    </Col>
    {x.evaluationProcedures?.length != 0 ? (
      <Col span={24}>
        <Text strong={true}>Evaluation Procedures: &nbsp;</Text>
        {x.evaluationProcedures?.map(i => (
          <>
            <Text>{i}&nbsp;</Text>
          </>
        ))}
      </Col>
    ) : (
      <></>
    )}
    {x.scheduleDeterminingProgress?.length != 0 ? (
      <Col span={24}>
        <Text strong={true}>
          Scheduled Determining Progress: &nbsp;
        </Text>
        {x.scheduleDeterminingProgress?.map(i => (
          <>
            <Text>{i}&nbsp;</Text>
          </>
        ))}
      </Col>
    ) : (
      <></>
    )}
    <Col span={24}>
      <Text strong={true}>
        Other Determining Progress Text: &nbsp;
      </Text>
      <Text>{x.scheduleDeterminingProgressOtherText}</Text>
    </Col>
  </Card>
</Col>
))}
</> */}
