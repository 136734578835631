import { gql } from "@apollo/client";

export const PRINT_FORMTAMES2 = gql`
  mutation printStudentFormTames2(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $studentDob: String
    $parentConsentDate: String
    $parentConsentGranted: String
    $doNotIncludeReport: Boolean
    $formAction: String
$formStatus: String
    $domains: [StudentFormTames2DomainsInput]
  ) {
    printStudentFormTames2(
      studentFormTames2Input: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        studentDob: $studentDob
        parentConsentDate: $parentConsentDate
        parentConsentGranted: $parentConsentGranted
        doNotIncludeReport: $doNotIncludeReport
formAction: $formAction
formStatus: $formStatus
        domains: $domains
      }
    ) {
      link
      fileName
    }
  }
`;
