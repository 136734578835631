import { gql } from "@apollo/client";

export const PRINT_FORM3454J = gql`
  mutation printStudentForm3454J(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $studentStrengths: String
    $operationalDefinitionTargetBehavior: String
    $setting: String
    $antecedents: String
    $consequences: String
    $environmentalVariables: String
    $behavioralFunction: String
    $formAction: String
$formStatus: String

  ) {
    printStudentForm3454J(
      studentForm3454JInput: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        studentStrengths: $studentStrengths
        operationalDefinitionTargetBehavior: $operationalDefinitionTargetBehavior
        setting: $setting
        antecedents: $antecedents
        consequences: $consequences
        environmentalVariables: $environmentalVariables
        behavioralFunction: $behavioralFunction

formAction: $formAction
formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;
