import { gql } from "@apollo/client";
import { DistrictFragments } from "../fragments/district";

export const INSERT_DISTRICT = gql`
  mutation insertDistrict($districtInput: DistrictInput) {
    insertDistrict(districtInput: $districtInput) {
      ...DistrictFormFields
    }
  }
  ${DistrictFragments.formFields}
`;

export const UPDATE_DISTRICT = gql`
  mutation updateDistrict($id: ID!, $districtInput: DistrictInput) {
    updateDistrict(id: $id, districtInput: $districtInput) {
      ...DistrictFormFields
      hidden
    }
  }
  ${DistrictFragments.formFields}
`;

export const INSERT_DISTRICT_FORM = gql`
  mutation insertDistrictForm($districtFormInput: DistrictFormInput) {
    insertDistrictForm(districtFormInput: $districtFormInput) {
      id
      formId
      active
      downloadFileName
      allowMultipleInstances
      form {
        id
        formCode
        name
      }
      createdBy
      createdAtAgo
      updatedAtAgo
      createdAt(format: "ddd mmm dd yyyy h:MM:ss TT Z")
      updatedAt(format: "ddd mmm dd yyyy h:MM:ss TT Z")
    }
  }
`;

export const UPDATE_DISTRICT_FORM = gql`
  mutation updateDistrictForm($id: ID!, $districtFormInput: DistrictFormInput) {
    updateDistrictForm(id: $id, districtFormInput: $districtFormInput) {
      id
      formId
      active
      downloadFileName
      allowMultipleInstances
      form {
        id
        formCode
        name
      }
      createdBy
      createdAtAgo
      updatedAtAgo
      createdAt(format: "ddd mmm dd yyyy h:MM:ss TT Z")
      updatedAt(format: "ddd mmm dd yyyy h:MM:ss TT Z")
    }
  }
`;

export const UPDATE_DISTRICT_FORM_PERMISSION = gql`
  mutation updateSubRolePermissionToForm(
    $formSubRolePermissionInput: FormSubRolePermissionInput!
  ) {
    updateSubRolePermissionToForm(
      formSubRolePermissionInput: $formSubRolePermissionInput
    ) {
      id
    }
  }
`;

export const INSERT_DISTRICT_REPORT = gql`
  mutation insertDistrictReport($districtReportInput: DistrictReportInput) {
    insertDistrictReport(districtReportInput: $districtReportInput) {
      id
      districtId
      userId
      isUserReport
      baseReportId
      reportCode
      reportName
      reportTitle
      reportGroup
      reportRowNotes {
        rowId
        notes
      }
      reportRowNotes
      reportParameters
      selectedFields {
        fieldName
        fieldGroup
        fieldCaption
        fieldIncludesGroup
        fieldOrder
        fieldWidth
        dataType
        dataFormat
        visible
        default
        required
      }
      reportColumns
      sortInfo {
        field
        order
      }
      filterValues,
      filters {
        fieldName
        comparisonOperator
        fieldValue
        logicalOperator
        default
      }
      grouping {
        fieldName
        function
        includeGrandTotal
      }
      reportPageLayout {
        pageFormat
        pageHeight
        pageWidth
        pageOrientation
        pageMargins
        marginTop
        marginBottom
        marginLeft
        marginRight
        fontName
        fontStyle
        fontSize
      }
      distribution {
        emailRecipients {
          email
        }
        emailFrom
        emailSubject
        emailText
        emailAttachments
      }
      schedule {
        scheduleActive
        recurrenceTimePeriod
        recurrenceInterval
        recurrencePeriods
        recurrenceDayNumber
        recurrenceDate
        scheduleStartDate
        scheduleEndDate
      }
      createdBy
      createdAtAgo
      updatedAtAgo
      createdAt(format: "ddd mmm dd yyyy h:MM:ss TT Z")
      updatedAt(format: "ddd mmm dd yyyy h:MM:ss TT Z")
    }
  }
`;

export const UPDATE_DISTRICT_REPORT = gql`
  mutation updateDistrictReport($id: ID!, $districtReportInput: DistrictReportInput) {
    updateDistrictReport(id: $id, districtReportInput: $districtReportInput) {
      id
      districtId
      userId
      isUserReport
      baseReportId
      reportCode
      reportName
      reportTitle
      reportGroup
      reportNotes
      reportRowNotes {
        rowId
        notes
      }
      reportParameters
      selectedFields {
        fieldName
        fieldGroup
        fieldCaption
        fieldIncludesGroup
        fieldOrder
        fieldWidth
        dataType
        dataFormat
        visible
        default
        required
      }
      reportColumns
      sortInfo {
        field
        order
      }
      filterValues,
      filters {
        fieldName
        comparisonOperator
        fieldValue
        logicalOperator
        default
      }
      grouping {
        fieldName
        function
        includeGrandTotal
      }
      reportPageLayout {
        pageFormat
        pageHeight
        pageWidth
        pageOrientation
        pageMargins
        marginTop
        marginBottom
        marginLeft
        marginRight
        fontName
        fontStyle
        fontSize
      }
      distribution {
        emailRecipients {
          email
        }
        emailFrom
        emailSubject
        emailText
        emailAttachments
      }
      schedule {
        scheduleActive
        recurrenceTimePeriod
        recurrenceInterval
        recurrencePeriods
        recurrenceDayNumber
        recurrenceDate
        scheduleStartDate
        scheduleEndDate
      }
      createdBy
      createdAtAgo
      updatedAtAgo
      createdAt(format: "ddd mmm dd yyyy h:MM:ss TT Z")
      updatedAt(format: "ddd mmm dd yyyy h:MM:ss TT Z")
    }
  }
`;