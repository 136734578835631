import { gql } from "@apollo/client";

export const QUERY_FORM3454H_VERSION = gql`
  query studentForm3454H(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
  ) {
    studentForm3454H(
      studentFormId: $studentFormId
      studentId: $studentId
      formId: $formId
      schoolId: $schoolId
      studentMeetingId: $studentMeetingId
    ) {
      versionId
      formId
      formDate
      studentFullName
      employmentAssessmentType
      employmentResponsibleAgency
      employmentDateConducted
      educationAssessmentType
      educationResponsibleAgency
      educationDateConducted
      trainingAssessmentType
      trainingResponsibleAgency
      trainingDateConducted
      livingSkillsAssessmentType
      livingSkillsResponsibleAgency
      livingSkillsDateConducted
      employmentOutcomes
      postSecondaryEducationOutcome
      postSecondaryTrainingOutcome
      independentLivingOutcome
      formAction
      formStatus
      coursesOfStudy {
        coursesOfStudyYear1
        coursesOfStudyYear2
        coursesOfStudyYear3
        coursesOfStudyYear4
        coursesOfStudyExtended
      }
    }
  }
`;
