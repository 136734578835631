import { gql } from "@apollo/client";

export const PRINT_FORM3454I = gql`
  mutation printStudentForm3454I(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $studentDob: String
    $providedCareerAndTechnicalEducationInfo: String
    $cteInfoIncludedOptions: String
    $instruction: String
    $instructionProviderAgency: String
    $instructionGoalNumber: String
    $instructionDateYearAddressed: String
    $instructionDateYearCompleted: String
    $relatedServices: String
    $relatedServicesProvider: String
    $relatedServicesGoalNumber: String
    $relatedServicesDateYearAddressed: String
    $relatedServicesDateYearCompleted: String
    $communityExperiences: String
    $communityExperiencesProvider: String
    $communityExperiencesGoalNumber: String
    $communityExperiencesDateYearAddressed: String
    $communityExperiencesDateYearCompleted: String
    $livingObjectives: String
    $livingObjectivesProviderAgency: String
    $livingObjectivesGoalNumber: String
    $livingObjectivesDateYearAddressed: String
    $livingObjectivesDateYearCompleted: String
    $dailyLivingSkills: String
    $dailyLivingSkillsProviderAgency: String
    $dailyLivingSkillsGoalNumber: String
    $dailyLivingSkillsDateYearAddressed: String
    $dailyLivingSkillsDateYearCompleted: String
    $linkages: String
    $linkagesProviderAgency: String
    $linkagesGoalNumber: String
    $linkagesDateYearAddressed: String
    $linkagesDateYearCompleted: String
    $studentDevelopmentalDisability: Boolean
    $studentMetIndependentServiceCoordinator: String
    $iscName: String
    $iscContactInfo: String
    $studentRegiseteredForPotentialFunding: String
    $studentRecordsUpToDate: String
    $studentFamiliarWithLocalServiceProviders: String
    $iscInquiredAboutPlanningForServices: String
    $iscDiscussedHomeBasedServices: String
    $studentDevelopmentalDisabilityHomeBasedServices: String
    $studentDevelopmentalDisabilityEnrollingHomeBasedServices: String
    $studentDevelopmentalDisabilityEffectiveUseHomeBasedServices: String
    $formAction: String
$formStatus: String

  ) {
    printStudentForm3454I(
      studentForm3454IInput: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        studentDob: $studentDob
        providedCareerAndTechnicalEducationInfo: $providedCareerAndTechnicalEducationInfo
        cteInfoIncludedOptions: $cteInfoIncludedOptions
        instruction: $instruction
        instructionProviderAgency: $instructionProviderAgency
        instructionGoalNumber: $instructionGoalNumber
        instructionDateYearAddressed: $instructionDateYearAddressed
        instructionDateYearCompleted: $instructionDateYearCompleted
        relatedServices: $relatedServices
        relatedServicesProvider: $relatedServicesProvider
        relatedServicesGoalNumber: $relatedServicesGoalNumber
        relatedServicesDateYearAddressed: $relatedServicesDateYearAddressed
        relatedServicesDateYearCompleted: $relatedServicesDateYearCompleted
        communityExperiences: $communityExperiences
        communityExperiencesProvider: $communityExperiencesProvider
        communityExperiencesGoalNumber: $communityExperiencesGoalNumber
        communityExperiencesDateYearAddressed: $communityExperiencesDateYearAddressed
        communityExperiencesDateYearCompleted: $communityExperiencesDateYearCompleted
        livingObjectives: $livingObjectives
        livingObjectivesProviderAgency: $livingObjectivesProviderAgency
        livingObjectivesGoalNumber: $livingObjectivesGoalNumber
        livingObjectivesDateYearAddressed: $livingObjectivesDateYearAddressed
        livingObjectivesDateYearCompleted: $livingObjectivesDateYearCompleted
        dailyLivingSkills: $dailyLivingSkills
        dailyLivingSkillsProviderAgency: $dailyLivingSkillsProviderAgency
        dailyLivingSkillsGoalNumber: $dailyLivingSkillsGoalNumber
        dailyLivingSkillsDateYearAddressed: $dailyLivingSkillsDateYearAddressed
        dailyLivingSkillsDateYearCompleted: $dailyLivingSkillsDateYearCompleted
        linkages: $linkages
        linkagesProviderAgency: $linkagesProviderAgency
        linkagesGoalNumber: $linkagesGoalNumber
        linkagesDateYearAddressed: $linkagesDateYearAddressed
        linkagesDateYearCompleted: $linkagesDateYearCompleted
        studentDevelopmentalDisability: $studentDevelopmentalDisability
        studentMetIndependentServiceCoordinator: $studentMetIndependentServiceCoordinator
        iscName: $iscName
        iscContactInfo: $iscContactInfo
        studentRegiseteredForPotentialFunding: $studentRegiseteredForPotentialFunding
        studentRecordsUpToDate: $studentRecordsUpToDate
        studentFamiliarWithLocalServiceProviders: $studentFamiliarWithLocalServiceProviders
        iscInquiredAboutPlanningForServices: $iscInquiredAboutPlanningForServices
        iscDiscussedHomeBasedServices: $iscDiscussedHomeBasedServices
        studentDevelopmentalDisabilityHomeBasedServices: $studentDevelopmentalDisabilityHomeBasedServices
        studentDevelopmentalDisabilityEnrollingHomeBasedServices: $studentDevelopmentalDisabilityEnrollingHomeBasedServices
        studentDevelopmentalDisabilityEffectiveUseHomeBasedServices: $studentDevelopmentalDisabilityEffectiveUseHomeBasedServices

formAction: $formAction
formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;
