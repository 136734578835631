import { gql } from "@apollo/client";

export const QUERY_FORM3454B_VERSION = gql`
  query studentForm3454B(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
  ) {
    studentForm3454B(
      studentFormId: $studentFormId
      studentId: $studentId
      formId: $formId
      schoolId: $schoolId
      studentMeetingId: $studentMeetingId
    ) {
      versionId
      formId
      formDate
      studentFullName
      studentDob
      lackInstructionReading
      evidenceLackInstructionReading
      lackInstructionMath
      evidenceLackInstructionMath
      englishLearnerStatus
      evidenceEnglishLearnerStatus
      disibilityIdentified
      primaryAutism
      primaryDeaf
      primaryDeafness
      primaryDevelopmentDelay
      primaryEmotionalDisability
      primaryHearingIMpairment
      primaryItellactualDisability
      primaryMultipleDisabilities
      primaryOrthopedicImpairment
      primaryOtherHealthImpairment
      primarySpeechImpairment
      primaryBrainInjury
      primaryVisualImpairment
      secondaryAutism
      secondaryDeaf
      secondaryDeafness
      secondaryDevelopmentDelay
      secondaryEmotionalDisability
      secondaryHearingIMpairment
      secondaryItellactualDisability
      secondaryMultipleDisabilities
      secondaryOrthopedicImpairment
      secondaryOtherHealthImpairment
      secondarySpeechImpairment
      secondaryBrainInjury
      secondaryVisualImpairment
      adverseEffects
      adverseEffectsDescription
      educationalNeedsDescription
      eligibility
      signatureName
      signatureTitle
      formAction
      formStatus
    }
  }
`;
