import React from "react";
import PropTypes from "prop-types";

// apollo & operations
import { useQuery } from "@apollo/client";
import { QUERY_SCHOOLS } from "../../../operations/queries/schools";

// ant design
import { Form, Select, Typography } from "antd";
import TextNameAuthEvaluated from "../common/TextNameAuthEvaluated";

const FormItemSelectSchool = ({
  label,
  name,
  validateStatus,
  help,
  hasFeedback,
  mode,
  required,
}) => {
  const { data, loading } = useQuery(QUERY_SCHOOLS);
  const { Paragraph } = Typography;
  return (
    <Form.Item
      label={label}
      name={name}
      validateStatus={validateStatus}
      help={help}
      hasFeedback={hasFeedback}
      requiredMark={"optional"}
      rules={[
        {
          required: true,
          message: (
            <Paragraph type="danger">
              "School" is not allowed to be empty
            </Paragraph>
          ),
        },
      ]}
    >
      <Select
        mode={mode}
        loading={loading}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) =>
          optionA.children
            .toString().toLowerCase()
            .localeCompare(optionB.children.toString().toLowerCase())
        }
      >
        {data?.schools &&
          data.schools.map((school) => (
            <Select.Option key={school.id} value={school.id}>
              <TextNameAuthEvaluated entity={school} />
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  );
};

FormItemSelectSchool.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  help: PropTypes.string,
  validateStatus: PropTypes.string,
  hasFeedback: PropTypes.bool,
  mode: PropTypes.string,
  required: PropTypes.bool,
};

export default FormItemSelectSchool;
