/*
 * Confirm modal box.
 * Modal dialogs with two buttons. Modal dialogs have only one button unless you use Modal.confirm.
 * This uses Modal.confirm to create two buttons dialogs that work with success/warning/error.
 */

// react
import React from "react";

// ant design
import { Space, Modal } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";

const ModalDialog = {};

ModalDialog.warning = ({
  title,
  content,
  okText,
  cancelText,
  onOk,
  onCancel,
}) => {
  Modal.confirm({
    className: "modalDialogWarning",
    title: (
      <Space size="middle">
        <CheckCircleOutlined />
        {title}
      </Space>
    ),
    icon: "",
    content: content,
    okText: okText,
    cancelText: cancelText,
    onOk: onOk,
    onCancel: onCancel,
  });
};

ModalDialog.success = ({
  title,
  content,
  okText,
  cancelText,
  onOk,
  onCancel,
  ...rest
}) => {
  Modal.confirm({
    className: "modalDialogSuccess",
    title: (
      <Space size="middle">
        <CheckCircleOutlined />
        {title}
      </Space>
    ),
    icon: "",
    content: content,
    okText: okText,
    cancelText: cancelText,
    onOk: onOk,
    onCancel: onCancel,
    ...rest,
  });
};

ModalDialog.error = ({
  title,
  content,
  okText,
  cancelText,
  onOk,
  onCancel,
}) => {
  Modal.confirm({
    className: "modalDialogError",
    title: (
      <Space size="middle">
        <CheckCircleOutlined />
        {title}
      </Space>
    ),
    icon: "",
    content: content,
    okText: okText,
    cancelText: cancelText,
    onOk: onOk,
    onCancel: onCancel,
  });
};

ModalDialog.fail = ({ content }) => {
  Modal.error({
    content,
  });
};

ModalDialog.ok = ({ content }) => {
  Modal.success({
    content: content,
  });
};

export default ModalDialog;
