import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { IepFormContext } from '../../../../utils/iepFormProvider'
import { useQuery } from '@apollo/client'
import { QUERY_STUDENT_COMMON_SIS } from '../../../../operations/queries/forms/SIS'
import { QUERY_FORM3457F_VERSION } from '../../../../operations/queries/forms/3457F'
import moment from 'moment'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import FormContainer from '../../../FormContainer'
import { Alert, Card, DatePicker, Form, Input, Radio, Typography } from 'antd'
import FormCollaboration from '../../../FormCollaboration'
import Title from 'antd/lib/typography/Title'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import StudentDobSIS from '../sis/StudentDobSIS'
import StudentParent1SIS from '../sis/StudentParent1SIS'
import StudentParent2SIS from '../sis/StudentParent2SIS'
import FormItem from 'antd/lib/form/FormItem'
import StudentFormActions from '../StudentFormActions'
import { PRINT_FORM3457F } from '../../../../operations/mutations/forms/3457F'

const { Paragraph } = Typography

const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId
}) => {
  const [form] = Form.useForm()
  const { itemErrors, formError } = useContext(FormErrorContext)

  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()
    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      studentMeetingId: studentMeetingId
    }
  }

  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    const update = Object.assign({})
    switch (fieldName) {
      case 'formDate':
      case 'parentSignatureDate':
        update[fieldName] = moment(fieldValue)
        break
      case 'receivedCopy':
      case 'receivedIepEligibilitySummary':
      case 'receivedEducationProgram':
      case 'receivedOther':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        break
      default:
        update[fieldName] = fieldValue
    }
    return update
  }

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }

  const documentNames = [
    {
      label: 'Copy of the IEP Eligibility Summary',
      name: 'receivedIepEligibilitySummary'
    },
    {
      label: 'Copy of the Individualized Education Program (IEP)',
      name: 'receivedEducationProgram'
    },
    {
      label: 'Other',
      name: 'receivedOther'
    }
  ]

  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          formDisabledState,
          canSave,
          handleActionEvents,
          validationProps
        }) => (
          <Form
            form={form}
            id='studentForm3457F'
            name='studentForm3457F'
            initialValues={data}
            preserve
            layout='vertical'
            className='page-padding-leftRight page-padding-topBottom form-labels-bold'
          >
            <Title level={4} className='center'>
              CONSENT FOR INITIAL PROVISION OF SPECIAL EDUCATION AND RELATED
              SERVICES
            </Title>
            <Form.Item
              label='Form Created Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
              />
            </Form.Item>

            <Card
              title='Student SIS Data'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />
              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
              <StudentDobSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentDob}
                error={itemErrors.studentDob}
              />
              <StudentParent1SIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.parent1}
                error={itemErrors.parent1}
              />
              <StudentParent2SIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.parent2}
                error={itemErrors.parent2}
              />
            </Card>

            <Card
              title='Details'
              style={{
                marginBottom: 30
              }}
            >
              <FormItem
                label='Consent'
                name='consent'
                {...validationProps('consent')}
              >
                <Radio.Group
                  defaultValue
                  defaultChecked='n/a'
                  buttonStyle='solid'
                  {...handleMouseEvents}
                  disabled={formDisabledState.consent}
                  name='consent'
                >
                  <Radio.Button value='yes'>I give consent</Radio.Button>
                  <Radio.Button value='no'>I do not give consent</Radio.Button>
                  <Radio.Button value='n/a'>Will be Marked Later</Radio.Button>
                </Radio.Group>
              </FormItem>

              {/* <FormItem
                label='I have received'
                name='receivedCopy'
                {...validationProps('receivedCopy')}
              >
                <Radio.Group
                  defaultValue
                  buttonStyle='solid'
                  {...handleMouseEvents}
                  disabled={formDisabledState.receivedCopy}
                  name='receivedCopy'
                >
                  <Radio.Button value>Yes</Radio.Button>
                  <Radio.Button value={false}>No</Radio.Button>
                </Radio.Group>
              </FormItem> */}

              {documentNames.map(({ label, name }, index) => (
                <FormItem
                  key={index}
                  label={label}
                  name={name}
                  {...validationProps(name)}
                >
                  <Radio.Group
                    {...handleMouseEvents}
                    buttonStyle='solid'
                    disabled={formDisabledState[name]}
                    name={name}
                  >
                    <Radio.Button value>Yes</Radio.Button>
                    <Radio.Button value={false}>No</Radio.Button>
                  </Radio.Group>
                </FormItem>
              ))}
              <Form.Item
                label='Name of other document'
                name='otherName'
                {...validationProps('otherName')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.otherName}
                />
              </Form.Item>
              <Form.Item
                label='Parent Signature Date'
                name='parentSignatureDate'
                {...validationProps('parentSignatureDate')}
              >
                <DatePicker
                  {...handleFocusEvents}
                  disabled={formDisabledState.parentSignatureDate}
                  format={[
                    'MM-DD-YYYY',
                    'MM/DD/YYYY',
                    'MM.DD.YY',
                    'M-D-YYYY',
                    'M/D/YYYY',
                    'M.D.YYYY',
                    'MM.DD.YYYY',
                    'MM-DD-YY',
                    'MM/DD/YY',
                    'M-D-YY',
                    'M/D/YY',
                    'M.D.YY',
                    'MMDDYYYY',
                    'MMDDYY'
                  ]}
                />
              </Form.Item>

              <FormItem
                label="Parent has accepted the option to delay child's service until the beginning of the school year following their third birthday"
                name='acceptOptionToDelayService'
                {...validationProps('acceptOptionToDelayService')}
              >
                <Radio.Group
                  defaultValue
                  defaultChecked='n/a'
                  buttonStyle='solid'
                  {...handleMouseEvents}
                  disabled={formDisabledState.acceptOptionToDelayService}
                  name='acceptOptionToDelayService'
                >
                  <Radio.Button value='yes'>Yes</Radio.Button>
                  <Radio.Button value='no'>No</Radio.Button>
                  <Radio.Button value='n/a'>N/A</Radio.Button>
                </Radio.Group>
              </FormItem>
            </Card>

            <Card
              title='Contact Information'
              style={{
                marginBottom: 30
              }}
            >
              <Form.Item
                label='Name'
                name='documentContactName'
                {...validationProps('documentContactName')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.documentContactName}
                />
              </Form.Item>
              <Form.Item
                label='Title'
                name='documentContactTitle'
                {...validationProps('documentContactTitle')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.documentContactTitle}
                />
              </Form.Item>
              <Form.Item
                label='Phone'
                name='documentContactPhone'
                {...validationProps('documentContactPhone')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.documentContactPhone}
                />
              </Form.Item>
            </Card>

            <Card
              title='Signee'
              style={{
                marginBottom: 30
              }}
            >
              <Form.Item
                label='Name'
                name='signatureName'
                {...validationProps('signatureName')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.signatureName}
                />
              </Form.Item>
              <Form.Item
                label='Title'
                name='signatureTitle'
                {...validationProps('signatureTitle')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.signatureTitle}
                />
              </Form.Item>
            </Card>

            {formError && <Paragraph type='danger'>{formError}</Paragraph>}
            <StudentFormActions
              query={PRINT_FORM3457F}
              extractDataProp='printStudentForm3457F'
              canSave={canSave}
              handleActionEvents={handleActionEvents}

              getFormFieldValues={getFormFieldValues}
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired
}

const StudentForm3457F = () => {
  // get current form information
  const { currentStudentInfo, currentEditableForm, studentMeetingId } =
    useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId } = currentEditableForm

  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    QUERY_STUDENT_COMMON_SIS,
    { variables: { id: studentId } }
  )
  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORM3457F_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )

  const createInitialFormData = (studentInfo, versionInfo) => {
    let initialValues = {}

    if (!studentInfo || !versionInfo) return initialValues

    const { studentForm3457F: form } = versionInfo
    const { student } = studentInfo
    const { parent1FullName, parent2FullName, dateOfBirth } = student
    const {
      formDate,
      studentFullName,
      studentDob,
      parent1,
      parent2,
      parentSignatureDate,
      acceptOptionToDelayService
    } = form

    initialValues = {
      ...form,
      formDate: formDate ? moment(formDate) : moment(Date.now()),
      studentFullName: studentFullName || student.fullName || '',
      studentDob: studentDob
        ? moment(studentDob).format('YYYY-MM-DD')
        : dateOfBirth
          ? moment(dateOfBirth).format('YYYY-MM-DD')
          : '',
      parent1: parent1 || parent1FullName || '',
      parent2: parent2 || parent2FullName || '',
      parentSignatureDate: parentSignatureDate
        ? moment(parentSignatureDate)
        : moment(Date.now()),
      acceptOptionToDelayService: acceptOptionToDelayService || 'n/a'
    }

    return initialValues
  }
  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
          />
        }
      />
    </FormErrorProvider>
  )
}
export default StudentForm3457F
