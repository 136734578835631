// react
import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useParams } from 'react-router-dom'

// ant design
import { Form, Input, Button, Select, Typography, message } from 'antd'

// components
import FormContainer from '../../../components/FormContainer'
import ModalDialog from '../../../components/ModalDialog'

// elements
import FormItemsSuperAdmin from '../../elements/formItems/FormItemsSuperAdmin'

// other libraries
import formatErrors from '../../../utils/form'

// apollo & operations
import { useQuery, useMutation } from '@apollo/client'
import {
  QUERY_DISTRICT,
  QUERY_DISTRICTS
} from '../../../operations/queries/districts'
import {
  INSERT_DISTRICT,
  UPDATE_DISTRICT
} from '../../../operations/mutations/districts'
import notificationSuccess from '../../../utils/notification'

//Contexts
import AuthContext from '../../../utils/authProvider'

const { Paragraph } = Typography

const FormRecord = ({ data }) => {
  const history = useNavigate()
  const { id } = useParams()
  const [form] = Form.useForm()
  const [formError, setFormError] = useState()
  const [itemErrors, setItemErrors] = useState({})
  const Auth = useContext(AuthContext)
  const isSuperAdmin = Auth.hasRole('SUPER_ADMIN')

  const [updateDistrict, { loading: updateLoading }] =
    useMutation(UPDATE_DISTRICT)
  const [insertDistrict, { loading: insertLoading }] = useMutation(
    INSERT_DISTRICT,
    {
      update(cache, { data: addDistrict }) {
        const data = cache.readQuery({ query: QUERY_DISTRICTS })
        if (data) {
          cache.writeQuery({
            query: QUERY_DISTRICTS,
            data: { districts: [addDistrict.insertDistrict, ...data.districts] }
          })
        }

      }
    }
  )

  const continueEditing = _id => _id && history(`/district/${_id}`)

  const onFinish = async values => {
    try {
      clearErrors()
      const mutated = id
        ? await updateDistrict({ variables: { id: id, districtInput: values } })
        : await insertDistrict({ variables: { districtInput: values } })
      // ModalDialog.success({
      //   title: 'Success',
      //   content: `The district has been ${id ? 'updated' : 'added'}.`,
      //   okText: 'Return to listing',
      //   onOk: () => history('/districts'),
      //   cancelText: 'Continue editing',
      //   onCancel: () => continueEditing(mutated.data.insertDistrict?.id)
      // })
      // const message = `The district has been ${id ? 'updated' : 'added'}.`
      notificationSuccess(`The district has been ${mutated ? 'updated' : 'added'}.`)
    } catch (e) {
      message.error(
        'Error(s) need to be corrected before the district can be updated.'
      )
      setErrors(formatErrors(e))
    }
  }

  const clearErrors = () => {
    setFormError()
    setItemErrors({})
  }

  const setErrors = errors => {
    if (errors.itemErrors) {
      setItemErrors(errors.itemErrors)
    } else {
      setFormError(errors.formError)
    }
  }
  return (
    <>
      <Form
        form={form}
        id='districtForm'
        name='districtForm'
        onFinish={onFinish}
        preserve={false}
        initialValues={data.district}
        labelCol={{ sm: 3, md: 2, lg: 2, xl: 2, xxl: 3 }}
        wrapperCol={{ sm: 10, md: 7, lg: 8, xl: 6, xxl: 4 }}
      >
        <Form.Item
          label='Name'
          name='name'
          hasFeedback
          validateStatus={itemErrors.name ? 'error' : ''}
          help={itemErrors.name}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Phone'
          name='phone'
          hasFeedback
          validateStatus={itemErrors.address ? 'phone' : ''}
          help={itemErrors.phone}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Fax'
          name='fax'
          hasFeedback
          validateStatus={itemErrors.fax ? 'error' : ''}
          help={itemErrors.fax}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Website'
          name='website'
          hasFeedback
          validateStatus={itemErrors.website ? 'error' : ''}
          help={itemErrors.website}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Address'
          name='address'
          hasFeedback
          validateStatus={itemErrors.address ? 'error' : ''}
          help={itemErrors.address}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='City'
          name='city'
          hasFeedback
          validateStatus={itemErrors.city ? 'error' : ''}
          help={itemErrors.city}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='State'
          name='state'
          hasFeedback
          validateStatus={itemErrors.state ? 'error' : ''}
          help={itemErrors.state}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Country'
          name='country'
          hasFeedback
          validateStatus={itemErrors.country ? 'error' : ''}
          help={itemErrors.country}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Zip'
          name='zip'
          hasFeedback
          validateStatus={itemErrors.zip ? 'error' : ''}
          help={itemErrors.zip}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Director of education'
          name='directorOfEducation'
          hasFeedback
          validateStatus={itemErrors.directorOfEducation ? 'error' : ''}
          help={itemErrors.directorOfEducation}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Chair of the board'
          name='chairOfBoard'
          hasFeedback
          validateStatus={itemErrors.chairOfBoard ? 'error' : ''}
          help={itemErrors.chairOfBoard}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='District Logo'
          name='logo'
          hasFeedback
          validateStatus={itemErrors.logo ? 'error' : ''}
          help={itemErrors.logo}
        >
          <Input />
        </Form.Item>

        {isSuperAdmin &&
        <Form.Item
          label='Selected Plans'
          name='selectedPlans'
        >
          <Select
              name='selectedPlans'
              placeholder="Please select all plans that apply"
              mode="multiple"
              //virtual={false}
              allowClear
          >
            <Select.Option value='IEP'>IEP</Select.Option>
            <Select.Option value='504'>504</Select.Option>
            <Select.Option value='Healthcare'>Healthcare</Select.Option>
            <Select.Option value='CNG IEP'>CNG IEP</Select.Option>
          </Select>
        </Form.Item>
        }

        <FormItemsSuperAdmin data={data.district} hasHidden />

        {formError && <Paragraph type='danger'>{formError}</Paragraph>}

        <Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            loading={insertLoading || updateLoading}
          >
            {id ? 'Update' : 'Add'}
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired
}

const DistrictForm = () => {
  const { id } = useParams()

  const { data, loading } = useQuery(QUERY_DISTRICT, {
    variables: { id },
    skip: !id
  })
  const initialValues = {
    district: {
      name: '',
      phone: '',
      fax: '',
      website: '',
      address: '',
      city: '',
      state: 'IL',
      country: 'United States',
      zip: '',
      directorOfEducation: '',
      chairOfBoard: '',
      logo: ''
    }
  }

  return (
    <FormContainer
      loading={loading}
      form={<FormRecord data={data || initialValues} />}
    />
  )
}

export default DistrictForm
