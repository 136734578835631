// reactmodal
import React, { Suspense, useContext, useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import ReactDragListView from 'react-drag-listview'

import { useQuery, useLazyQuery, useMutation } from '@apollo/client'

import { useLocation } from 'react-router-dom'

// contexts
import AuthContext from '../../../utils/authProvider'
import { ApolloContext } from '../../../utils/apolloProvider'

import {
  notificationSuccess,
  notificationError
} from '../../../utils/notification'

// ant design
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Icon,
  Input,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Skeleton,
  Space,
  Switch,
  Table,
  Tabs,
  Tooltip,
  TreeSelect,
  Typography
} from 'antd'
const { Title } = Typography
const { TextArea } = Input
import {
  DeleteOutlined,
  DeleteTwoTone,
  MinusCircleOutlined,
  PlusOutlined,
  SaveTwoTone,
  CopyTwoTone,
  FontSizeOutlined,
  LayoutOutlined,
} from '@ant-design/icons'

// operations
import { QUERY_SCHOOL_REPORT } from '../../../operations/queries/reportBuilder'
// import { QUERY_BASE_REPORTS } from '../../../operations/queries/reports'
// import { QUERY_BASE_REPORT } from '../../../operations/queries/reports'
import { QUERY_DISTRICT_REPORTS } from '../../../operations/queries/districts'
import { QUERY_DISTRICT_REPORT } from '../../../operations/queries/districts'
import { QUERY_USER_DISTRICT_REPORTS } from '../../../operations/queries/districts' //Test. Unused by schoolReport
import { QUERY_DISTRICT_REPORTS_LIST } from '../../../operations/queries/districts' //Test. Unused by schoolReport
import { QUERY_USER_DISTRICT_REPORTS_LIST } from '../../../operations/queries/districts' //Test. Unused by schoolReport

import { QUERY_SCHOOLS_REPORT } from '../../../operations/queries/reportBuilder' //New report builder query
import { QUERY_SCHOOLS } from '../../../operations/queries/schools'
import { UPDATE_DISTRICT_REPORT } from '../../../operations/mutations/districts'
import { EMAIL_REPORT } from '../../../operations/mutations/reportBuilder'

// components
//import SchoolReportSearchForm from "./SchoolReportSearchForm";
//import FormItemSelectDistrictReport from "../../elements/formItems/FormItemSelectDistrictReport";
import TimeSpanPicker from './TimeSpanPicker'
import './SchoolReports.css'
import FormItem from 'antd/lib/form/FormItem'

// other libraries
import formatErrors from '../../../utils/form'
import FormContainer from '../../../components/FormContainer'
import JsPDF from 'jspdf'
import 'jspdf-autotable'

//Functions
import getTimeSpanDates from '../../../utils/getTimeSpanDates'
import { LayoutContext } from 'antd/lib/layout/layout'

//Globals
let currentReportData = []
let districtReports = []
let fieldIncludes = {}
let availableFieldsTreeData = []
let queryData = []
let queryIncludes = {}

const ReportForm = ({ data }) => {
  //Temporary: testing date stuff
  // const requestDate = new Date('2022-01-01T08:00:00.000Z')

  // const [startDateLY, endDateLY] = getTimeSpanDates('Last Year'); //pass no date.  Don't get request date used
  // console.log('Last Year-start/end/base',startDateLY.format('MM/DD/YY'),endDateLY.format('MM/DD/YY'))

  // const [startDateNW, endDateNW, requestDateNW] = getTimeSpanDates('next      week'); //pass no date, misformed request.  will use today's date
  // console.log('Next Week-start/end/base',startDateNW.format('MM/DD/YY'),endDateNW.format('MM/DD/YY'),requestDateNW.format('MM/DD/YY'))

  // const [startDateTSY, endDateTSY, requestDateTSY] = getTimeSpanDates('thisschoolyear','10/5/2022'); //pass string type for request date
  // console.log('This School Year-start/end/base',startDateTSY.format('MM/DD/YY'),endDateTSY.format('MM/DD/YY'),requestDateTSY.format('MM/DD/YY'))

  // const [startDateLM, endDateLM, requestDateLM] = getTimeSpanDates('LAST MONTH',requestDate);  //pass date type for request date
  // console.log('Last Month-start/end/base',startDateLM.format('MM/DD/YY'),endDateLM.format('MM/DD/YY'),requestDateLM.format('MM/DD/YY'))

  // const [startDateLMB, endDateLMB, requestDateLMB] = getTimeSpanDates('LAST MONTH','10/45/99');  //bad date
  // console.log('Last Month Bad Date pARAM-start/end/base',startDateLMB.format('MM/DD/YY'),endDateLMB.format('MM/DD/YY'),requestDateLMB.format('MM/DD/YY'))
  //End of date testing stuff

  const districtReportsLoaded = useRef(false)

  //let districtReport = {};
  districtReports = data

  const Auth = useContext(AuthContext)
  const Apollo = useContext(ApolloContext)

  //const Apollo = useContext(AppContext)

  const [columns, setColumns] = useState([])
  const [newcolumns, setNewColumns] = useState([])
  const [reportData, setReportData] = useState([])

  const [dataNeedsRefresh, setDataNeedsRefresh] = useState(true)
  const [reportDefChanged, setReportDefChanged] = useState(false)
  const [reportTitle, setReportTitle] = useState('')
  const [openPdfModal, setOpenPdfModal] = useState(false)
  const [openDistributReportModal, setOpenDistributReportModal] =
    useState(false)
  //--------
  //State
  //--------\
  const isSuperAdmin = Auth.hasRole('SUPER_ADMIN')
  const isSchoolsGeneral = Auth.hasRole('SCHOOLS_GENERAL')
  const isSchoolsAdmin = Auth.hasRole('SCHOOLS_ADMIN')
  const isDistrictsAdmin = Auth.hasRole('DISTRICTS_ADMIN')
  console.log(Auth, 'what is auth please')

  // const districtIds = isSuperAdmin ? [Auth?.districtsAdmin.map(x => x.id)] : [Auth?.districtsAdmin.map(x => x.id)] //Set to user current district
  const districtIds = Auth?.districtsAdmin.map(x => x.id)
  console.log(districtIds, 'are we getting district ids')

  // const [baseReport, setBaseReport] = useState([]) //Base(global) report of selected district report
  // const [baseReports, setBaseReports] = useState(data.baseReports) //All base(global) reports
  //const [districtReports, setDistrictReports] = useState([]); //All district reports for current district
  const [districtReport, setDistrictReport] = useState({}) //Selected district report
  const [availableFields, setAvailableFields] = useState([]) //The baseReport.fields array from the selected base report
  const [availableFieldsList, setAvailableFieldsList] = useState([]) //The list of available fields for the selected base report
  const [selectedFieldDefs, setSelectedFieldDefs] = useState([]) //the districtReport.fields array for fields selected from availableFields list
  const [selectedFieldsList, setSelectedFieldsList] = useState([]) //Field list for field selection Select
  //const [selectedFields, setSelectedFields] = useState([]) //Fields selected from availableFields list
  const selectedFields = useRef()
  //const [availableFieldsTreeData, setAvailableFieldsTreeData] = useState([]);
  //const [fieldIncludes, setFieldIncludes] = useState([])
  //let fieldIncludes = [];

  const [selectedDistrictReportId, setSelectedDistrictReportId] = useState()
  const [selectedSchoolIds, setSelectedSchoolIds] = useState([])

  const [sortInfo, setSortInfo] = useState({})
  const [filterInfo, setFilterInfo] = useState([])

  const [form] = Form.useForm()

  const [formError, setFormError] = useState()
  const [itemErrors, setItemErrors] = useState({})

  //saveas
  const [isReportDefSaveAsModalOpen, setIsReportDefSaveAsModalOpen] =
    useState(false)

  const setErrors = errors =>
    errors.itemErrors
      ? setItemErrors(errors.itemErrors)
      : setFormError(errors.formError)

  const clearErrors = () => {
    setFormError()
    setItemErrors({})
  }
  const [updateDistrictReport, { loading: updateLoading }] = useMutation(
    UPDATE_DISTRICT_REPORT
  )

  //==============================================================================
  //Distribute Report stuff
  //==============================================================================
  const distributeReport = async () => {
    //Check required data
    const recipients = form.getFieldValue('emailRecipients')
    if (!recipients || recipients?.length === 0) {
      //Require at least one recipient
      notificationError('No Recipients')
      console.log('no recipients') //Better messaging
      return
    }

    const emailAttachments = form.getFieldValue('emailAttachments')
    if (!emailAttachments || emailAttachments?.length === 0) {
      //Require at least one attachment
      console.log('no selections') //Better messaging
      return
    }

    const emailFrom = form.getFieldValue('emailFrom')
    if (!emailFrom || emailFrom?.length === 0) {
      //Require at least one recipient
      notificationError('No From Email')
      console.log('no from email') //Better messaging
      return
    }

    //Get other email parameters from form
    const userId = Auth.userId
    const emailTo = recipients.map(recipient => recipient.email) //SendGrid wants an array of strings for multiple 'to' addresses
    const emailSubject = form.getFieldValue('emailSubject') //Has default in api
    const emailText = form.getFieldValue('emailText') //Has default in api

    //Get the requested attachements
    let emailFileAttachments = []
    if (emailAttachments.includes('csv')) {
      //Need to wait for returned blob promise to finish
      await getCsv('blob') //returns pdfBlob.text() - utf-8 text of content
        .then(result => {
          console.log('attach csv', result)
          //NOTE: unescape is deprecated.  Need to find alternative.
          const encodedBlob = btoa(unescape(encodeURIComponent(result))) //base64 encode
          emailFileAttachments.push({
            content: encodedBlob,
            filename:
              districtReport?.reportName !== undefined
                ? districtReport?.reportName + '.csv'
                : 'Untitled_Report.csv',
            type: 'text/csv',
            disposition: 'attachment'
          })
        })
        .catch(error => console.log(error))
    }

    if (emailAttachments.includes('pdf')) {
      //Need to wait for returned blob promise to finish
      await getPdf('blob') //returns pdfBlob.text() - utf-8 text of content
        .then(result => {
          //NOTE: unescape is deprecated.  Need to find alternative.
          const encodedBlob = btoa(unescape(encodeURIComponent(result))) //base64 encode
          emailFileAttachments.push({
            content: encodedBlob,
            filename:
              districtReport?.reportName !== undefined
                ? districtReport?.reportName + '.pdf'
                : 'Untitled_Report.pdf',
            type: 'application/pdf',
            disposition: 'attachment'
          })
        })
        .catch(error => console.log(error))
    }

    //Send the email
    try {
      const response = Apollo.client.mutate({
        mutation: EMAIL_REPORT,
        variables: {
          userId: userId,
          to: emailTo,
          from: emailFrom,
          subject: emailSubject,
          text: emailText,
          attachments: emailFileAttachments
        }
      })
      await response
      console.log(response, 'what is the response')
      notificationSuccess('Your report has been sent.')
    } catch (error) {
      console.log(error)
      const message =
        error?.metadata?.graphQLErrors?.[0]?.message ||
        error?.message ||
        'An unknown error occurred, please contact your administrator. E006'
      notificationError(
        'The following error occurred so your report was not sent.',
        message + '.'
      )
    }

    // close the modal
  }

  //==============================================================================
  //Export to CSV stuff
  //==============================================================================
  const getCsv = async () => {
    let outputOption = 'download'
    console.log(outputOption, columns, currentReportData, 'what are you 3')
    let filename = 'Untitled_Report'
    if (districtReport?.reportName !== '' && districtReport !== undefined) {
      filename = districtReport?.reportName
    }
    let ShowLabel = false

    //Load an array using currentReportData (antd table extra.currentDataSource object).  reportData filtered and sorted in table.
    //currentDataSource includes __typedef column and does not guarantee proper column order (? not positive about column order).
    //Following removes __typedef property and puts column data in order as displayed in UI.
    const headerNames = columns.map(column => column.title.props.children)
    const fieldNames = columns.map(column => column.dataIndex)
    let csvData = []
    if (headerNames) {
      csvData.push(headerNames)
    }
    if (currentReportData !== undefined) {
      for (const dataRow of currentReportData) {
        let newRow = []
        for (const field of fieldNames) {
          newRow.push(dataRow[field])
        }
        csvData.push(newRow)
      }
    } else {
      for (const dataRow of reportData) {
        let newRow = []
        for (const field of fieldNames) {
          newRow.push(dataRow[field])
        }
        csvData.push(newRow)
      }
    }

    //If reportData is not an object then JSON.parse will parse the JSON string in an Object
    if (csvData !== undefined) {
      var arrData = typeof csvData != 'object' ? JSON.parse(csvData) : csvData
    }
    // if (reportData !== undefined) {
    //   var arrData =
    //     typeof reportData != 'object' ? JSON.parse(reportData) : reportData
    // }

    var CSV = ''
    //This condition will generate the Label/Header
    if (ShowLabel && arrData?.length > 0) {
      var row = ''

      //This loop will extract the label from 1st index of on array
      for (var index in arrData[0]) {
        //Now convert each value to string and comma-seprated
        row += index + ','
      }
      row = row.slice(0, -1)
      //append Label row with line break
      CSV += row + '\r\n'
    }

    //1st loop is to extract each row
    for (var i = 0; i < arrData?.length; i++) {
      var row = ''
      //2nd loop will extract each column and convert it in string comma-seprated
      for (var index in arrData[i]) {
        row += '"' + arrData[i][index] + '",'
      }
      row.slice(0, row?.length - 1)
      //add a line break after each row
      CSV += row + '\r\n'
    }

    if (CSV == '') {
      alert('Invalid Data')
      return
    }
    if (outputOption == 'download') {
      var link = document.createElement('a')
      link.id = 'lnkDwnldLnk'

      //this part will append the anchor tag and remove it after automatic click
      document.body.appendChild(link)

      var csv = CSV
      var link = document.createElement('a')
      link.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv)
      )
      link.setAttribute('download', filename)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
    if (outputOption === 'blob') {
      const csvBlob = new Blob([CSV], {
        type: 'application/pdf'
      })
      return await csvBlob.text() //utf-8 text of pdf content

      // //var csv = CSV
    }
    //this trick will generate a temp "a" tag
  }

  //==============================================================================
  //PDF generation stuff
  //NOTE: Works with currentReportData which is data as it appears in table(e.g. filtered and sorted)
  //Should we add a "Print All Data" option?  Probably not.
  //==============================================================================
  const closeDistributionModal = () => {
    setOpenDistributReportModal(false)
  }
  const showDistributionModal = () => {
    setOpenDistributReportModal(true)
  }
  const TheDistributionModal = () => {
    return (
      <>
        <Modal
          title='Configure Report Distribution'
          open={openDistributReportModal}
          // disabled={false}
          footer={[
            <Button key='cancel' onClick={closeDistributionModal}>
              Cancel
            </Button>,
            <Button type='primary' key='save' onClick={distributeReport}>
              Export
            </Button>
          ]}
        >
          <div>
            <Title level={5} underline>
              Recipients:{' '}
            </Title>
          </div>
          <Form.List
            name='emailRecipients' //How can we prevent empty entries?
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Space>
                    <Form.Item
                      {...field}
                      label='Email'
                      name={[field.name, 'email']}
                      key={[field.fieldKey, 'email']}
                      rules={[
                        { required: true, message: 'Email is empty' },
                        {
                          type: 'email',
                          warningOnly: true,
                          message: 'Email is invalid'
                        },
                        { type: 'string', whitespace: true }
                      ]}
                    >
                      <Input placeholder="Enter recipient's email" />
                    </Form.Item>

                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type='dashed'
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add a Recipient
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item
            label='From:'
            name='emailFrom'
            rules={[
              { required: true, message: 'Email is empty' },
              {
                type: 'email',
                warningOnly: true,
                message: 'Email is invalid'
              },
              { type: 'string', whitespace: true }
            ]}
          >
            <Input
              type='text'
              placeholder="Enter the 'From' email address"
              //initialValue={districtReport.distribution.emailFrom ? districtReport.distribution.emailFrom : ''}
            ></Input>
          </Form.Item>
          <Form.Item label='Subject:' name='emailSubject'>
            <Input
              type='text'
              placeholder="Enter the email 'Subject'"
              //initialValue={districtReport.distribution.emailSubject ? districtReport.distribution.emailSubject : ''}
            ></Input>
          </Form.Item>
          <Form.Item label='Message:' name='emailText' initialValue=''>
            <Input.TextArea
              placeholder='Enter email text'
              autoSize={{ minRows: 5, maxRows: 10 }}
            />
          </Form.Item>

          <FormItem
            label='Attachments:'
            name='emailAttachments'
            //{...validationProps('emailAttachments')}
          >
            <Checkbox.Group
              // onChange={checkedValues => {
              //   const e = {
              //     target: {
              //       name: 'communicationMode',
              //       value: checkedValues.toString()
              //     }
              //   }
              //   handleMouseEvents.onChange(e)
              // }}
              // disabled={formDisabledState.communicationMode}
              name='emailAttachments'
            >
              <Checkbox value='pdf'>Report Pdf</Checkbox>
              <Checkbox value='csv'>Report CSV</Checkbox>
            </Checkbox.Group>
          </FormItem>
          <Form.Item>
            <Tooltip title='Save report definition'>
              <Button
                //type="primary"
                tooltip='Save report definition'
                // icon={<SaveTwoTone style={{ fontSize: '125%' }} />}
                //disabled={!reportDefChanged}
                onClick={() => {
                  saveDistrictReportDefinition()
                  setReportDefChanged(false)
                }}
              >
                Save
              </Button>
            </Tooltip>
          </Form.Item>
        </Modal>
      </>
    )
  }
  const ThePdfModal = () => {
    return (
      <>
        <Modal
          title='Configure Pdf Layout'
          open={openPdfModal}
          // disabled={false}
          footer={[
            <Button key='cancel' onClick={closePdfModal}>
              Cancel
            </Button>,
            <Button type='primary' key='save' onClick={() => getPdf('open')}>
              Export
            </Button>
          ]}
        >
          {' '}
          <Card
            title='Page orientation and size'
            bordered={true}
            style={{
              marginBottom: 30
            }}
          >
            <Col span={5}>
              <Row>
                <Form.Item
                  className='form-item-text'
                  label='Page Orientation:'
                  name='pageOrientation'
                >
                  <Select
                    defaultValue='Portrait'
                    //style={{ width: "125px" }}
                    options={[
                      { value: 'Portrait', label: 'Portrait' },
                      { value: 'Landscape', label: 'Landscape' }
                    ]}
                  />
                </Form.Item>
              </Row>
              <Row>
                <Form.Item
                  className='form-item-text'
                  label='Page Size:'
                  name='pageFormat' //store in doc?
                >
                  <Select
                    defaultValue='Letter'
                    //style={{ width: "125px" }}
                    onChange={value => handlePaperSizeChange(value)}
                  >
                    {Object.keys(paperSizesEnum).map(paperSize => (
                      <option key={paperSize} value={paperSize}>
                        {' '}
                        {paperSize}{' '}
                      </option>
                    ))}
                  </Select>
                </Form.Item>
              </Row>

              <Row>
                <Form.Item
                  label='Page Width:' //Set when pageFormat selected
                  name='pageWidth'
                >
                  <InputNumber disabled defaultValue={8.5} precision={2} />
                </Form.Item>
              </Row>

              <Row>
                <Form.Item
                  label='Page Height:' //Set when pageFormat selected
                  name='pageHeight'
                  validateStatus={itemErrors.pageHeight ? 'error' : ''} //Test how we handle errors
                  help={itemErrors.pageHeight} //Test how we handle errors
                >
                  <InputNumber disabled defaultValue={11.0} precision={2} />
                </Form.Item>
              </Row>
            </Col>
          </Card>
          <Card
            title='Page margins'
            bordered={true}
            style={{
              marginBottom: 30
            }}
          >
            <Col span={5} alignItems='right' Justify='right'>
              <Row>
                <Form.Item
                  className='form-item-text'
                  label='Page Margins:'
                  name='pageMargins' //Store in doc?  change marginRight to marginRight
                >
                  <Select
                    defaultValue='Normal'
                    //style={{ width: "100px" }}
                    onChange={value => handlePageMarginsChange(value)}
                  >
                    {Object.keys(pageMarginsEnum).map(margin => (
                      <option key={margin} value={margin}>
                        {' '}
                        {margin}{' '}
                      </option>
                    ))}
                  </Select>
                </Form.Item>
              </Row>

              <Row>
                <Form.Item
                  label='Top Margin:' //Set when pageMargins selected
                  name='marginTop'
                >
                  <InputNumber disabled defaultValue={0.75} precision={2} />
                </Form.Item>
              </Row>

              <Row>
                <Form.Item
                  label='Bottom Margin:' //Set when pageMargins selected
                  name='marginBottom'
                >
                  <InputNumber disabled defaultValue={0.75} precision={2} />
                </Form.Item>
              </Row>

              <Row>
                <Form.Item
                  label='Left Margin:' //Set when pageMargins selected
                  name='marginLeft'
                >
                  <InputNumber disabled defaultValue={0.75} precision={2} />
                </Form.Item>
              </Row>

              <Row>
                <Form.Item
                  label='Right Margin:' //Set when pageMargins selected
                  name='marginRight'
                >
                  <InputNumber disabled defaultValue={0.75} precision={2} />
                </Form.Item>
              </Row>
            </Col>
          </Card>
          <Card
            title='Font'
            bordered={true}
            style={{
              marginBottom: 30
            }}
          >
            <Col span={5} alignItems='right' Justify='right'>
              <Row>
                <Form.Item
                  className='form-item-text'
                  label='Font:'
                  name='fontName'
                >
                  <Select
                    defaultValue='Helvetica'
                    //style={{ width: "175px" }}
                  >
                    {Object.keys(fontNamesEnum).map(font => (
                      <option key={font} value={font}>
                        {' '}
                        {font}{' '}
                      </option>
                    ))}
                  </Select>
                </Form.Item>
              </Row>

              <Row>
                <Form.Item
                  className='form-item-text'
                  label='Font Style:'
                  name='fontStyle'
                >
                  <Select defaultValue='Normal'>
                    {Object.keys(fontStylesEnum).map(fontStyle => (
                      <option key={fontStyle} value={fontStyle}>
                        {' '}
                        {fontStyle}{' '}
                      </option>
                    ))}
                  </Select>
                </Form.Item>
              </Row>

              <Row>
                <Form.Item label='Font Size:' name='fontSize'>
                  <InputNumber
                    defaultValue={16}
                    min={4}
                    max={72}
                    step={0.5}
                    precision={1}
                  />
                </Form.Item>
              </Row>
            </Col>
          </Card>
          <Form.Item>
            <Tooltip title='Save report definition'>
              <Button
                //type="primary"
                tooltip='Save report definition'
                // icon={<SaveTwoTone style={{ fontSize: '125%' }} />}
                //disabled={!reportDefChanged}
                onClick={() => {
                  saveDistrictReportDefinition()
                  setReportDefChanged(false)
                }}
              >
                Save
              </Button>
            </Tooltip>
          </Form.Item>
        </Modal>
      </>
    )
  }
  const closePdfModal = () => {
    setOpenPdfModal(false)
  }
  const showPdfModal = () => {
    setOpenPdfModal(true)
  }
  const getPdf = async (outputOption = 'download') => {
    //download/open/blob
    //Function constants
    const pointsPerInch = 72
    const lineHeightFactor = 1.15 //Add to pageLayout in db?  ? jspdf.lineHeightFactor - what is this used for
    const fileName =
      districtReport?.reportName !== undefined
        ? districtReport?.reportName
        : 'Untitled_Report' //For saving pdf file
    let xOffset = 0
    let yOffset = 0

    //Get user selected layout options from report def/UI
    const reportName =
      districtReport?.reportName !== undefined
        ? districtReport?.reportName
        : 'Untitled_Report'
    const reportTitle = form.getFieldValue('reportTitle')
    const reportNotes = form.getFieldValue('reportNotes')

    const pageOrientation = form.getFieldValue('pageOrientation')
      ? form.getFieldValue('pageOrientation')
      : 'Landscape'

    //page Height/Width are dependent on orientation.  Convert inches to points.
    const pageFormat = form.getFieldValue('pageFormat')
      ? form.getFieldValue('pageFormat')
      : 'Letter' //e.g. paper size
    const pageHeight =
      pageOrientation === 'Portrait'
        ? paperSizesEnum[pageFormat].pageHeight * pointsPerInch
        : paperSizesEnum[pageFormat].pageWidth * pointsPerInch
    const pageWidth =
      pageOrientation === 'Portrait'
        ? paperSizesEnum[pageFormat].pageWidth * pointsPerInch
        : paperSizesEnum[pageFormat].pageHeight * pointsPerInch

    const pageMargins = form.getFieldValue('pageMargins')
      ? form.getFieldValue('pageMargins')
      : 'Normal'

    // const marginTop = districtReport?.reportPageLayout.marginTop * pointsPerInch //Convert inches(db unit) to points(pdf unit)
    // const marginBottom =
    //   districtReport?.reportPageLayout.marginBottom * pointsPerInch //Convert inches(db unit) to points(pdf unit)
    // const marginLeft =
    //   districtReport?.reportPageLayout.marginLeft * pointsPerInch //Convert inches(db unit) to points(pdf unit)
    // const marginRight =
    //   districtReport?.reportPageLayout.marginRight * pointsPerInch //Convert inches(db unit) to points(pdf unit)
    const marginTop = pageMarginsEnum[pageMargins].marginTop * pointsPerInch //Convert inches(db unit) to points(pdf unit)
    const marginBottom =
      pageMarginsEnum[pageMargins].marginBottom * pointsPerInch //Convert inches(db unit) to points(pdf unit)
    const marginLeft = pageMarginsEnum[pageMargins].marginLeft * pointsPerInch //Convert inches(db unit) to points(pdf unit)
    const marginRight = pageMarginsEnum[pageMargins].marginRight * pointsPerInch //Convert inches(db unit) to points(pdf unit)
    // const marginTop = 0.5 //Convert inches(db unit) to points(pdf unit)
    // const marginBottom = 0.5 //Convert inches(db unit) to points(pdf unit)
    // const marginLeft = 0.5 //Convert inches(db unit) to points(pdf unit)
    // const marginRight = 0.5 //Convert inches(db unit) to points(pdf unit)

    const fontName = form.getFieldValue('fontName')
      ? form.getFieldValue('fontName')
      : 'Helvetica'
    const fontStyle = form.getFieldValue('fontStyle')
      ? form.getFieldValue('fontStyle')
      : 'Normal'
    const fontSize = districtReport?.reportPageLayout.fontSize
      ? districtReport?.reportPageLayout.fontSize
      : 10

    //Get list of fieldNames and titles from columns (use columns because it has the current order of columns) and set header names used by autotable.
    const fieldNames = columns.map(column => column.dataIndex)
    const headerNames = columns.map(column => column.title.props.children)
    //Load an array using currentReportData (antd table extra.currentDataSource object).  reportData filtered and sorted in table.
    //currentDataSource includes __typedef column and does not guarantee proper column order (? not positive about column order).
    //Following removes __typedef property and puts column data in order as displayed in UI.
    let pdfData = []

    if (currentReportData !== undefined) {
      for (const dataRow of currentReportData) {
        let newRow = []
        for (const field of fieldNames) {
          newRow.push(dataRow[field])
        }
        pdfData.push(newRow)
      }
    } else {
      for (const dataRow of reportData) {
        let newRow = []
        for (const field of fieldNames) {
          newRow.push(dataRow[field])
        }
        pdfData.push(newRow)
      }
    }

    //Create pdf doc and set margins (setting "pageFormat" sets page height/width).
    const doc = JsPDF({
      orientation: pageOrientation,
      unit: 'pt',
      format: pageFormat,
      lineHeight: 2
    })
    doc.margins = {
      top: marginTop,
      bottom: marginBottom,
      left: marginLeft,
      width: pageWidth
    } //Note width vs. right margin

    //Calculate yOffset for table based on existence of title and notes.
    let tableStartY = marginTop + fontSize * 1.2 * (1 / lineHeightFactor) //Top margin and report name
    tableStartY += reportTitle ? fontSize * 1.1 * lineHeightFactor : 0 //reportTitle if it exists
    const tableTopMargin = tableStartY + fontSize * lineHeightFactor //Starting y position on all pages other than first
    const reportNotesSplit = doc.splitTextToSize(
      reportNotes,
      pageWidth - marginLeft - marginRight - 10,
      { fontSize: fontSize }
    ) //Handle long notes that span multiple lines
    const reportNotesHeight =
      reportNotesSplit.length * (fontSize * lineHeightFactor)
    tableStartY += reportNotesHeight + fontSize * lineHeightFactor + 24 //height of reportNotes + box around notes padding and line width
    tableStartY += fontSize * lineHeightFactor //blank line before table
    console.log(headerNames, 'what is headerNames please')
    //currentReportData table
    doc.autoTable({
      head: [headerNames],
      body: pdfData,
      colunWidth: 'wrap',
      theme: 'striped', //striped, grid or plain
      startY: tableStartY,
      showHead: 'everyPage',
      headStyles: { cellPadding: { top: 10, right: 2, bottom: 10, left: 2 } },
      bodyStyles: { cellPadding: { top: 3, right: 2, bottom: 3, left: 2 } }, //Effectively changes row heights
      tableWidth: 'auto',
      margin: { top: tableTopMargin },
      styles: {
        minCellHeight: 9,
        halign: 'left',
        valign: 'bottom',
        overflow: 'linebreak',
        cellWidth: '100',
        cellPadding: {
          cellPadding: { top: 10, right: 3, bottom: 10, left: 3 }
        },
        overflowColumns: 'linebreak',
        font: fontName,
        fontStyle: fontStyle,
        fontSize: fontSize
      },

      //Autotable only does a fair job of setting the correct header row height when column headers contain multiple rows.
      //willDrawCells fires immediately before a cell(row) is drawn.  Use it to vary the lineHeightFactor to get a better row height.
      willDrawCell: function (hookData) {
        if (hookData.row.section === 'head') {
          const headerRows = hookData.row.height / fontSize
          switch (true) {
            case headerRows < 3:
              doc.setLineHeightFactor(1.15)
              break
            case headerRows >= 3 && headerRows < 5:
              doc.setLineHeightFactor(1.25)
              break
            case headerRows >= 5 && headerRows < 7:
              doc.setLineHeightFactor(1.5)
              break
            default:
              doc.setLineHeightFactor(1.15)
              break
          }
        }
      },

      //Fires for every page
      didDrawPage: function (hookData) {
        //reportName and reportTitle.  Every page.
        doc.setFontSize(fontSize * 1.2) //font 20% larger
        doc.setFont(fontName, 'bold')
        yOffset = marginTop + fontSize * (1 / lineHeightFactor) //topMargin + row height(font size) w/o lineHeightFactor
        xOffset = pageWidth / 2
        doc.text(reportName, xOffset, yOffset, { align: 'center' })
        yOffset += fontSize * 1.2 * lineHeightFactor

        if (reportTitle) {
          doc.setFontSize(fontSize * 1.1) //font 10% larger
          doc.setFont(fontName, 'bold')
          doc.text(reportTitle, xOffset, yOffset, { align: 'center' })
          yOffset += fontSize * 1.1 * lineHeightFactor
        }

        //reportNotes on first page only.  This could be adjusted slightly to better handle fitting based on number of rows required (e.g. change rect height)
        if (reportNotes && hookData.pageNumber === 1) {
          doc.setFontSize(fontSize)
          doc.setFont(fontName, 'normal')
          yOffset += fontSize * lineHeightFactor
          doc.text('Notes:', marginLeft, yOffset)
          yOffset += 5
          doc.setLineWidth(1.0)
          doc.rect(
            marginLeft + 1,
            yOffset,
            pageWidth - marginLeft - marginRight - 2,
            reportNotesHeight + 5
          ) //Draw box around notes?  x/y/width/height
          yOffset += 12
          doc.text(reportNotes, marginLeft + 5, yOffset, {
            maxWidth: pageWidth - marginLeft - marginRight - 10,
            lineHeightFactor: 1.15
          }) //Internal padding of 5? style for linebreak
        }
        tableStartY = 50

        //Footer, all pages.
        var pageString = 'Page ' + doc.internal.getNumberOfPages()
        doc.setFontSize(fontSize)
        doc.setFont(fontName, 'normal')
        doc.text(
          pageString,
          marginLeft,
          pageHeight - fontSize * lineHeightFactor
        )
      }
    })
    //Saves to user's download path (e.g. c:\users\user name\Downloads) and prompts in browser
    if (outputOption === 'download') {
      doc.save(`${fileName}.pdf`)
    }

    //Opens the pdf in a new browser tab
    if (outputOption === 'open') {
      const pdfBlob = new Blob([doc.output()], { type: 'application/pdf' })
      const blobUrl = URL.createObjectURL(pdfBlob)
      window.open(blobUrl) // will open a new tab
    }

    //Returns blob
    if (outputOption === 'blob') {
      const pdfBlob = new Blob([doc.output('blob')], {
        type: 'application/pdf'
      })
      return await pdfBlob.text() //utf-8 text of pdf content
    }
  }

  //==============================================================================
  //Page Layout stuff.  Put all Enums in src/utils/constants?  Probably.
  //==============================================================================
  const paperSizesEnum = Object.freeze({
    Letter: {
      pageHeight: 11,
      pageWidth: 8.5
    },
    Legal: {
      pageHeight: 14,
      pageWidth: 8.5
    },
    Ledger: {
      pageHeight: 17,
      pageWidth: 11
    },
    Custom: {
      pageHeight: 11,
      pageWidth: 8.5
    }
  })

  const pageMarginsEnum = Object.freeze({
    Normal: {
      marginTop: 0.5,
      marginBottom: 0.5,
      marginLeft: 0.5,
      marginRight: 0.5
    },
    Narrow: {
      marginTop: 0.25,
      marginBottom: 0.25,
      marginLeft: 0.25,
      marginRight: 0.25
    },
    Wide: {
      marginTop: 0.75,
      marginBottom: 0.75,
      marginLeft: 0.75,
      marginRight: 0.75
    },
    Custom: {
      marginTop: 0.5,
      marginBottom: 0.5,
      marginLeft: 0.5,
      marginRight: 0.5
    }
  })

  const fontNamesEnum = Object.freeze({
    //Specific to jsPDF?
    Courier: 'courier',
    Helvetica: 'Helvetica',
    TimesRoman: 'times-roman'
  })

  const fontStylesEnum = Object.freeze({
    //Specific to jsPDF?
    Bold: 'bold',
    BoldItalic: 'bolditalic',
    BoldOblique: 'boldoblique',
    Italic: 'italic',
    Normal: 'normal',
    Oblique: 'oblique',
    Roman: 'roman'
  })

  const handlePaperSizeChange = value => {
    form.setFieldsValue({ pageHeight: paperSizesEnum[value].pageHeight })
    form.setFieldsValue({ pageWidth: paperSizesEnum[value].pageWidth })
  }
  const handlePageMarginsChange = value => {
    form.setFieldsValue({ marginTop: pageMarginsEnum[value].marginTop })
    form.setFieldsValue({ marginBottom: pageMarginsEnum[value].marginBottom })
    form.setFieldsValue({ marginLeft: pageMarginsEnum[value].marginLeft })
    form.setFieldsValue({ marginRight: pageMarginsEnum[value].marginRight })

    // if (districtReport) {
    //   districtReport.reportPageLayout.marginTop = 33;
    // }
  }

  //==============================================================================
  //Save/SaveAs/Delete report definition stuff
  //==============================================================================
  const showReportDefModal = () => {
    setIsReportDefSaveAsModalOpen(true)
  }

  const handleReportDefModalSave = () => {
    setIsReportDefSaveAsModalOpen(false)
  }

  const handleReportDefModalCancel = () => {
    setIsReportDefSaveAsModalOpen(false)
  }

  const deleteDistrictReportDefinition = () => {
    if (!districtReport) {
      return
    }

    //District report is not physically deleted.  hidden is set true.
    const newDef = {
      districtId: districtReport.districtId, //Requred in typeDef.  Remove requirement?
      isUserReport: districtReport.isUserReport, //Requred in typeDef.  Remove requirement?
      baseReportId: districtReport.baseReportId, //Requred in typeDef.  Remove requirement?
      reportCode: districtReport.reportCode, //Requred in typeDef.  Remove requirement?
      reportName: districtReport.reportName, //Requred in typeDef.  Remove requirement?
      hidden: true
    }

    try {
      clearErrors()
      const mutated = updateDistrictReport({
        variables: { id: selectedDistrictReportId, districtReportInput: newDef }
      })
      //There has to be a better way to reset this data.
      setSelectedDistrictReportId('')
      setDistrictReport({})
      setAvailableFields([])
      setSelectedFieldDefs([])
      setSelectedSchoolIds([])
      //setSelectedFields([])
      selectedFields.current = []
      notificationSuccess('Report definition deleted')
    } catch (error) {
      if (error) {
        console.log(error, 'error')
      }
      message.error(
        'Error(s) need to be corrected before the report definition can be deleted.'
      )
      setErrors(formatErrors(error))
    }
  }
  //==============================================================================
  //Available fields selector related functions
  //==============================================================================
  //--------
  //
  //--------
  const handleAvailableFieldsOnChange = value => {
    if (!selectedFields || selectedFields.length === 0) {
      console.log('handle no data', selectedFields)
      return
    }
    console.log('handleAvailableFieldsOnChange', value, selectedFields)
    //event.stopPropagation()
    //setCurrentDataIndex(sorter.field)
    //setState({currentDataIndex: sorter.field})
    //const newSelectedFields = value
    const newSelectedFields = value

    //let newSelectedFields = selectedFields
    //newSelectedFields.push(...value)
    //Update reportTable columns.  Should probably create separate function
    let newColumns = []
    for (const field of newSelectedFields) {
      newColumns.push(getReportTableColumnObject(field, districtReport))
    }
    setColumns([...newColumns])
    //setSelectedFields(value)
    selectedFields.current = [...value]
  }

  const handleAvailableFieldsOnBlur = () => {
    if (!selectedFields || selectedFields.length === 0) {
      console.log('handle no data', selectedFields)
      return
    }
    //const newSelectedFields = selectedFields;

    let newColumns = []
    for (const field of selectedFields) {
      newColumns.push(getReportTableColumnObject(field, districtReport))
    }
    setColumns([...newColumns])
    console.log('handleAvailableFieldsOnBlur', selectedFields, columns)
  }

  //--------
  //Add a column for the fieldName to the reportTable
  //--------
  const handleAvailableFieldsOnSelect = fieldName => {
    //handleAddColumn(fieldName, districtReport)
  }

  //--------
  //Remove an antd table column when a field is deselected from the list of availableFields
  //--------
  const handleAvailableFieldsOnDeselect = fieldName => {
    removeReportTableColumn(fieldName)
  }

  //==============================================================================
  //Report data table/column related functions
  //==============================================================================
  //--------
  //Returns fieldName's reportTable column index
  //--------
  const getFieldNameColumnIndex = fieldName => {
    let columnIndex = -1
    //const column = columns.filter((column) => { return column.dataIndex === fieldName })
    for (let i = 0; i < columns.length; i++) {
      if (columns[i].dataIndex === fieldName) {
        columnIndex = i
        break
      }
    }
    // columnIndex = columns.findIndex( (column) => {
    //   if (column.dataIndex === fieldName) { return true; }
    // });
    return columnIndex
  }

  //--------
  //Return a column's filter object containing all unique values for fieldName in reportData
  //--------
  const getReportTableColumnFilterValues = (fieldName, newReportData) => {
    if (!newReportData) {
      newReportData = reportData
      return
    }
    const uniqueValues = [...new Set(newReportData.map(row => row[fieldName]))] //fieldName's unique values in reportData

    //Build filters object
    let filters = []
    if (uniqueValues[0] !== undefined) {
      uniqueValues.map(uniqueValue => {
        let filterText = ''
        switch (typeof uniqueValue) {
          case 'string':
            filterText = uniqueValue
            break
          case 'number':
          case 'bigint':
            filterText = uniqueValue.toString()
            break
          case 'boolean': //How do we want to load booleans?
            filterText = yesNo[Number(uniqueValue)]
            break
          default:
            break
        }
        filters.push({ text: filterText, value: uniqueValue })
      })
    } else {
      filters = [''] //fieldName not in dataset
    }
    return filters
  }

  //--------
  //Return a column's render value
  //--------
  const getReportTableColumnRender = fieldValue => {
    if (typeof fieldValue === 'boolean') {
      return yesNo[Number(fieldValue)]
    } else {
      return fieldValue
    }
  }

  const handleColumnHeaderEdit = fieldName => {
    //Call modal to get new header text

    //Update columns with new header
    let newColumns = columns
    const columnIndex = getFieldNameColumnIndex(fieldName)
    if (columnIndex >= 0) {
      newColumns[columnIndex].title = newColumns[columnIndex].title + '='
      setColumns([...newColumns])
    }
  }

  //--------
  //Return a reportTable column object for fieldName
  //The basic approach is to load data from district report first and then default to base report if it doesn't exist
  //--------
  const getReportTableColumnObject = (newFieldName, districtReport) => {
    //Get the field defs for the base and district reports
    const baseFieldDef = districtReport.baseReport.fields.find(
      ({ fieldName }) => fieldName === newFieldName
    )
    //Should following be necessary?
    if (!baseFieldDef) {
      //The requested field name must be in the base report's list of available fields
      return
    }
    const districtFieldDef = districtReport.selectedFields.find(
      ({ fieldName }) => fieldName === newFieldName
    )
    //const fieldGroup = districtFieldDef ? districtFieldDef.fieldGroup : baseFieldDef.fieldGroup;
    const fieldCaption = districtFieldDef
      ? districtFieldDef.fieldCaption
      : baseFieldDef.fieldCaption
    //const fieldOrder = districtFieldDef ? districtFieldDef.fieldOrder : baseFieldDef.fieldOrder;
    const fieldWidth = districtFieldDef
      ? districtFieldDef.fieldWidth
      : baseFieldDef.fieldWidth
    const fieldAlignment = districtFieldDef
      ? districtFieldDef.fieldAlignment
      : baseFieldDef.fieldAlignment
    const fieldHasFilter = districtFieldDef
      ? districtFieldDef.fieldHasFilter
      : baseFieldDef.fieldHasFilter
    const fieldHasSort = districtFieldDef
      ? districtFieldDef.fieldHasSort
      : baseFieldDef.fieldHasSort

    let newColumn = {
      //key: newFieldName,
      //title: <><span onClick={(e) => {handleColumnHeaderEdit(newFieldName); e.stopPropagation()}}><EditOutlined /></span><span style={{ color: 'darkblue' }} onClick={(e)=>{e.stopPropagation()}} >{fieldCaption}</span></>,
      //title: <span style={{ color: 'darkblue' }} >{fieldCaption}</span>,
      title: <span className='dragHandler'>{fieldCaption}</span>,
      dataIndex: newFieldName,
      filterMultiple: true,
      //filteredValue: null,
      //filters: filterValues,
      filterSearch: true,
      defaultSortOrder: 'ascend',
      //showSorterTooltip: false,
      onFilter: (value, record) =>
        handleReportTableFilter(value, record, newFieldName),
      render: value => getReportTableColumnRender(value)
      //ellipsis: true, //does this have to be true to get column resizing?
      //width: 500  //testing width stuff
      // onHeaderCell: (column) => {
      //   const fieldName = column.dataIndex;
      //   const columnIndex = getFieldNameColumnIndex(fieldName)
      //   return {
      //     onDoubleClick: () => {
      //       const fieldName = column.dataIndex
      //       const columnIndex = getFieldNameColumnIndex(fieldName)
      //       // const headerText = column.title.props.children[0].props.children.props.children[1].props.children //Yikes!  But it works
      //       //column.title.props.children[0].props.children.props.children[1].props.children = headerText + '=' //Can't do.  Immutable.

      //     }, // not picked up.  because of sort/filter?
      //   };
      // }
    }

    if (fieldAlignment !== '') {
      newColumn.align = fieldAlignment
    }
    if (fieldWidth > 0) {
      newColumn.width = fieldWidth
    }

    if (fieldHasFilter) {
      newColumn.filters = getReportTableColumnFilterValues(
        newFieldName,
        reportData
      )
      newColumn.onFilter = (value, record) =>
        handleReportTableFilter(value, record, newFieldName)
    }

    if (fieldHasSort && sortInfo) {
      newColumn.sorter = (a, b) => handleReportTableSort(a, b, newFieldName)
      newColumn.sortOrder = sortInfo.field === newFieldName && sortInfo.order //testing sort info load
    }

    return newColumn
  }

  //--------
  //Remove a column(fieldName) from the reportTable
  //--------
  const removeReportTableColumn = fieldName => {
    if (columns) {
      const newColumns = columns.filter(row => {
        return row.dataIndex !== fieldName
      }) //Filter existing columns to all columns EXCEPT the passed fieldName
      setColumns(newColumns) //Set new columns in state. Use spread (e.g. [...newColumns]) to cause re-render
      //setColumns([...newColumns]);  //Set new columns in state. Use spread (e.g. [...newColumns]) to cause re-render
      setReportDefChanged(true) //Indicate report definitions changed
    }
  }

  //--------
  //Sort column
  //--------
  const handleReportTableSort = (a, b, fieldName) => {
    //handleReportTableSort is passed two rows of data (not two values)
    const c = a[fieldName]
    const d = b[fieldName]
    return isNaN(c) && isNaN(d) ? (c || '').localeCompare(d || '') : c - d
  }

  //--------
  //Filter column
  //--------
  const handleReportTableFilter = (value, record, fieldName) => {
    switch (typeof value) {
      case 'string':
        return record[fieldName].indexOf(value) === 0
      case 'number':
      case 'bigint':
        return record[fieldName] === value
      case 'boolean':
        return record[fieldName] === value
      default:
        return
    }
  }

  //--------
  //Handle any changes to table.
  //--------
  const handleReportTableOnChange = (pagination, filters, sorter, extra) => {
    console.log('FP table onChange - extra', extra)
    //Save report's currentDataSource(e.g. reportData filtered and sorted) to global for reference by csv export and pdf generation
    if (extra == undefined && reportData !== undefined) {
      currentReportData = reportData
    } else {
      currentReportData = extra?.currentDataSource
    }
    //Handle action causing onChange
    if (extra?.action === 'sort') {
      sortTable(sorter.field, sorter.order)
    }
    if (extra?.action === 'filter') {
      filterTable(filters)
    }

    //Update current filters and sorter
    setFilterInfo(filters)
    setSortInfo(sorter)
  }

  //--------
  //Handle all table sorts.
  //Call with empty fieldName and null order to clear existing sort.
  //--------
  const sortTable = (fieldName, order) => {
    //--------
    //Get column index of column containing fieldName.
    //--------
    const columnIndex = getFieldNameColumnIndex(fieldName)
    if (fieldName !== '' && columnIndex < 0) {
      return
    } //Bad fieldName

    //--------
    //Clear any existing sortOrders in columns object.
    //--------
    let newColumns = columns
    for (const column of columns) {
      column.sortOrder = null
    }

    //--------
    //Set the column's sortOrder if this isn't a 'clear sort' request.
    //--------
    if (columnIndex >= 0) {
      newColumns[columnIndex].sortOrder = order
    }

    //--------
    //Save sort info to state
    //--------
    setSortInfo({
      field: fieldName,
      order: order
    })

    //--------
    //Set columns state.  Table sorted on next render.
    //--------

    setColumns([...newColumns])
    setReportDefChanged(true)
  }

  //--------
  //Handle all table filters.
  //Call with empty fieldName and null order to clear existing sort.
  //--------
  const filterTable = filters => {
    let newColumns = columns

    //filters is an object with a prop for each fieldName and a value containing any selected filter values
    //(e.g. schoolName: ['Test School 1', 'Test School 2']... )
    const filterProps = Object.keys(filters) //Get an array of properties from filters object

    //Clear existing filters in columns object
    for (const filterProp of filterProps) {
      const fieldName = filterProp
      const columnIndex = getFieldNameColumnIndex(fieldName)
      if (columnIndex >= 0) {
        newColumns[columnIndex].filtered = false
        newColumns[columnIndex].filteredValue = null
      }
    }

    //Set filters where prop value is non-null.  Skip if a "clearFilters" request was made.
    for (const filterProp of filterProps) {
      const fieldName = filterProp
      const filterValues = filters[filterProp]
      const columnIndex = getFieldNameColumnIndex(fieldName)
      if (filterValues && columnIndex >= 0) {
        newColumns[columnIndex].filtered = true
        newColumns[columnIndex].filteredValue = filterValues
      }
    }

    //Update state
    setColumns([...newColumns])
    setReportDefChanged(true)
  }

  const handleClearSort = () => {
    sortTable('', null)
  }

  const handleClearFilters = () => {
    let newColumns = columns

    //Clear existing filters in columns object
    for (const newColumn of newColumns) {
      newColumn.filtered = false
      newColumn.filteredValue = null
    }

    //Update state
    setColumns([...newColumns])
    setFilterInfo(null)
    setReportDefChanged(true)
  }

  //--------
  //Add a column to the antd table
  //--------
  const handleAddColumn = (newFieldName, districtReport) => {
    //if (!columns){return}

    //Return if the fieldName already exists in the table
    if (getFieldNameColumnIndex(newFieldName) >= 0) {
      return
    }

    let newColumns = columns
    newColumns.push(getReportTableColumnObject(newFieldName, districtReport))
    setColumns([...newColumns])
    setReportDefChanged(true)

    return
  }

  //==============================================================================
  //Report data related functions
  //==============================================================================
  queryIncludes = {
    includeSchoolId: fieldIncludes.schoolId,
    includeSchoolCode: fieldIncludes.schoolCode,
    includeSchoolName: fieldIncludes.schoolName,
    includeSchoolAddress: fieldIncludes.schoolAddress,
    includeSchoolCity: fieldIncludes.schoolCity,
    includeSchoolState: fieldIncludes.schoolState,
    includeSchoolZip: fieldIncludes.schoolZip,

    includeSchoolCountOfStudents: fieldIncludes.schoolCountOfStudents,
    includeSchoolCountOfIeps: fieldIncludes.schoolCountOfIeps,
    includeSchoolCountOfUsers: fieldIncludes.schoolCountOfUsers,

    includeSchoolCountOfStudentsEthnicityAfricanAmerican:
      fieldIncludes.schoolCountOfStudentsEthnicityAfricanAmerican,
    includeSchoolCountOfStudentsEthnicityAmericanIndian:
      fieldIncludes.schoolCountOfStudentsEthnicityAmericanIndian,
    includeSchoolCountOfStudentsEthnicityAsian:
      fieldIncludes.schoolCountOfStudentsEthnicityAsian,
    includeSchoolCountOfStudentsEthnicityHispanic:
      fieldIncludes.schoolCountOfStudentsEthnicityHispanic,
    includeSchoolCountOfStudentsEthnicityNativeHawaiian:
      fieldIncludes.schoolCountOfStudentsEthnicityNativeHawaiian,
    includeSchoolCountOfStudentsEthnicityWhite:
      fieldIncludes.schoolCountOfStudentsEthnicityWhite,

    includeSchoolCountOfStudentsGenderFemale:
      fieldIncludes.schoolCountOfStudentsGenderFemale,
    includeSchoolCountOfStudentsGenderMale:
      fieldIncludes.schoolCountOfStudentsGenderMale,

    includeSchoolCountOfStudentsGrade1:
      fieldIncludes.schoolCountOfStudentsGrade1,
    includeSchoolCountOfStudentsGrade2:
      fieldIncludes.schoolCountOfStudentsGrade2,
    includeSchoolCountOfStudentsGrade3:
      fieldIncludes.schoolCountOfStudentsGrade3,
    includeSchoolCountOfStudentsGrade4:
      fieldIncludes.schoolCountOfStudentsGrade4,
    includeSchoolCountOfStudentsGrade5:
      fieldIncludes.schoolCountOfStudentsGrade5,
    includeSchoolCountOfStudentsGrade6:
      fieldIncludes.schoolCountOfStudentsGrade6,
    includeSchoolCountOfStudentsGrade7:
      fieldIncludes.schoolCountOfStudentsGrade7,
    includeSchoolCountOfStudentsGrade8:
      fieldIncludes.schoolCountOfStudentsGrade8,
    includeSchoolCountOfStudentsGrade9:
      fieldIncludes.schoolCountOfStudentsGrade9,
    includeSchoolCountOfStudentsGrade10:
      fieldIncludes.schoolCountOfStudentsGrade10,
    includeSchoolCountOfStudentsGrade11:
      fieldIncludes.schoolCountOfStudentsGrade11,
    includeSchoolCountOfStudentsGrade12:
      fieldIncludes.schoolCountOfStudentsGrade12,
    includeSchoolCountOfStudentsGradeKindergarten:
      fieldIncludes.schoolCountOfStudentsGradeKindergarten,
    includeSchoolCountOfStudentsGradeNA:
      fieldIncludes.schoolCountOfStudentsGradeNA,

    includeSchoolCountOfStudentsLanguageArabic:
      fieldIncludes.schoolCountOfStudentsLanguageArabic,
    includeSchoolCountOfStudentsLanguageChinese:
      fieldIncludes.schoolCountOfStudentsLanguageChinese,
    includeSchoolCountOfStudentsLanguageEnglish:
      fieldIncludes.schoolCountOfStudentsLanguageEnglish,
    includeSchoolCountOfStudentsLanguageFrench:
      fieldIncludes.schoolCountOfStudentsLanguageFrench,
    includeSchoolCountOfStudentsLanguageGerman:
      fieldIncludes.schoolCountOfStudentsLanguageGerman,
    includeSchoolCountOfStudentsLanguageKorean:
      fieldIncludes.schoolCountOfStudentsLanguageKorean,
    includeSchoolCountOfStudentsLanguageRussian:
      fieldIncludes.schoolCountOfStudentsLanguageRussian,
    includeSchoolCountOfStudentsLanguageSpanish:
      fieldIncludes.schoolCountOfStudentsLanguageSpanish,
    includeSchoolCountOfStudentsLanguageTagalog:
      fieldIncludes.schoolCountOfStudentsLanguageTagalog,
    includeSchoolCountOfStudentsLanguageVietnamese:
      fieldIncludes.schoolCountOfStudentsLanguageVietnamese,
    includeSchoolCountOfStudentsLanguageOther:
      fieldIncludes.schoolCountOfStudentsLanguageOther,

    includeDistrictId: fieldIncludes.districtId,
    includeDistrictName: fieldIncludes.districtName,
    includeDistrictAddress: fieldIncludes.districtAddress,
    includeDistrictCity: fieldIncludes.districtCity,
    includeDistrictState: fieldIncludes.districtState,
    includeDistrictZip: fieldIncludes.districtZip,
    includeDistrictPhone: fieldIncludes.districtPhone,
    includeDistrictFax: fieldIncludes.districtFax,
    includeDistrictWebsite: fieldIncludes.districtWebsite,

    includeDistrictCountOfStudents: fieldIncludes.districtCountOfStudents,
    includeDistrictCountOfIeps: fieldIncludes.districtCountOfIeps,
    includeDistrictCountOfUsers: fieldIncludes.districtCountOfUsers,

    includeDistrictCountOfStudentsEthnicityAfricanAmerican:
      fieldIncludes.districtCountOfStudentsEthnicityAfricanAmerican,
    includeDistrictCountOfStudentsEthnicityAmericanIndian:
      fieldIncludes.districtCountOfStudentsEthnicityAmericanIndian,
    includeDistrictCountOfStudentsEthnicityAsian:
      fieldIncludes.districtCountOfStudentsEthnicityAsian,
    includeDistrictCountOfStudentsEthnicityHispanic:
      fieldIncludes.districtCountOfStudentsEthnicityHispanic,
    includeDistrictCountOfStudentsEthnicityNativeHawaiian:
      fieldIncludes.districtCountOfStudentsEthnicityNativeHawaiian,
    includeDistrictCountOfStudentsEthnicityWhite:
      fieldIncludes.districtCountOfStudentsEthnicityWhite,

    includeDistrictCountOfStudentsGenderFemale:
      fieldIncludes.districtCountOfStudentsGenderFemale,
    includeDistrictCountOfStudentsGenderMale:
      fieldIncludes.districtCountOfStudentsGenderMale,

    includeDistrictCountOfStudentsGrade1:
      fieldIncludes.districtCountOfStudentsGrade1,
    includeDistrictCountOfStudentsGrade2:
      fieldIncludes.districtCountOfStudentsGrade2,
    includeDistrictCountOfStudentsGrade3:
      fieldIncludes.districtCountOfStudentsGrade3,
    includeDistrictCountOfStudentsGrade4:
      fieldIncludes.districtCountOfStudentsGrade4,
    includeDistrictCountOfStudentsGrade5:
      fieldIncludes.districtCountOfStudentsGrade5,
    includeDistrictCountOfStudentsGrade6:
      fieldIncludes.districtCountOfStudentsGrade6,
    includeDistrictCountOfStudentsGrade7:
      fieldIncludes.districtCountOfStudentsGrade7,
    includeDistrictCountOfStudentsGrade8:
      fieldIncludes.districtCountOfStudentsGrade8,
    includeDistrictCountOfStudentsGrade9:
      fieldIncludes.districtCountOfStudentsGrade9,
    includeDistrictCountOfStudentsGrade10:
      fieldIncludes.districtCountOfStudentsGrade10,
    includeDistrictCountOfStudentsGrade11:
      fieldIncludes.districtCountOfStudentsGrade11,
    includeDistrictCountOfStudentsGrade12:
      fieldIncludes.districtCountOfStudentsGrade12,
    includeDistrictCountOfStudentsGradeKindergarten:
      fieldIncludes.districtCountOfStudentsGradeKindergarten,
    includeDistrictCountOfStudentsGradeNA:
      fieldIncludes.districtCountOfStudentsGradeNA,

    includeDistrictCountOfStudentsLanguageArabic:
      fieldIncludes.districtCountOfStudentsLanguageArabic,
    includeDistrictCountOfStudentsLanguageChinese:
      fieldIncludes.districtCountOfStudentsLanguageChinese,
    includeDistrictCountOfStudentsLanguageEnglish:
      fieldIncludes.districtCountOfStudentsLanguageEnglish,
    includeDistrictCountOfStudentsLanguageFrench:
      fieldIncludes.districtCountOfStudentsLanguageFrench,
    includeDistrictCountOfStudentsLanguageGerman:
      fieldIncludes.districtCountOfStudentsLanguageGerman,
    includeDistrictCountOfStudentsLanguageKorean:
      fieldIncludes.districtCountOfStudentsLanguageKorean,
    includeDistrictCountOfStudentsLanguageRussian:
      fieldIncludes.districtCountOfStudentsLanguageRussian,
    includeDistrictCountOfStudentsLanguageSpanish:
      fieldIncludes.districtCountOfStudentsLanguageSpanish,
    includeDistrictCountOfStudentsLanguageTagalog:
      fieldIncludes.districtCountOfStudentsLanguageTagalog,
    includeDistrictCountOfStudentsLanguageVietnamese:
      fieldIncludes.districtCountOfStudentsLanguageVietnamese,
    includeDistrictCountOfStudentsLanguageOther:
      fieldIncludes.districtCountOfStudentsLanguageOther
  }

  const [queryReportData, { loading: queryLoading }] = useLazyQuery(
    QUERY_SCHOOLS_REPORT,
    {
      variables: {
        schoolIds: selectedSchoolIds,
        selectedFields: selectedFields.current,
        ...queryIncludes
      }
    }
  )

  const [dummyQuery] = useLazyQuery(QUERY_SCHOOLS_REPORT, {
    variables: {
      schoolIds: [],
      selectedFields: []
      //...queryIncludes,
    }
  })

  //--------
  //Returns true if the fieldName exists in the current reportData dataset
  //--------
  const fieldNameIsInReportData = fieldName => {
    return reportData ? reportData.some(dataRow => fieldName in dataRow) : false
  }

  //--------
  //Get data
  //--------
  const getReportData = async () => {
    if (!selectedSchoolIds || !queryIncludes) {
      return
    }

    //Build graphql query includes object setting @include to true for selected fields.
    //Query will only return fields with @include set to true.
    let newFieldIncludes = {} //Load an array of availableFields and initialze all to false
    const availableFields = districtReport?.baseReport?.fields
    if (availableFields?.length > 0) {
      for (const field of availableFields) {
        newFieldIncludes[field.fieldName] = false
      }
    }
    selectedFields.current.forEach(field => (newFieldIncludes[field] = true)) //Set to true if the field is selected
    fieldIncludes = newFieldIncludes
    queryIncludes = newFieldIncludes
    console.log(
      'in getreportdata',
      selectedFields,
      fieldIncludes,
      queryIncludes
    )

    //For some reason even though queryIncludes are set correctly(checked log), the first time queryReportData is called the fields set to true aren't picked up and a "no fields except __typedef" response is returned.
    //Without changing anything and running the query again everything is fine.
    let result = []
    result = await dummyQuery() //Returns no data but causes @includes to "take"
    result = await queryReportData()
    queryData = result.data
    console.log('after get data', queryData, result)

    if (queryData?.schoolsReport) {
      setReportData(queryData?.schoolsReport) //Object used by resultsTable
      currentReportData = queryData?.schoolsReport
      sortTable(districtReport.sortField, districtReport.sortOrder)

      const newColumns = columns
      for (const field of selectedFields.current) {
        const filterValues = getReportTableColumnFilterValues(
          field,
          queryData.schoolsReport
        )
        const columnIndex = getFieldNameColumnIndex(field)
        if (columnIndex >= 0 && filterValues?.length > 0) {
          newColumns[columnIndex].filters = filterValues
        } else {
          newColumns[columnIndex].filters = []
        }
      }
      setColumns([...newColumns])
    }
  }

  //==============================================================================
  //District report definition related functions
  //==============================================================================
  const loadAvailableFieldsTreeData = availableFields => {
    if (!districtReport) {
      return
    }

    //const availableFields = districtReport.availableFields
    console.log('availableFieldsTreeData', availableFields)

    availableFieldsTreeData = []
    const fieldGroupIds = [
      ...new Set(availableFields.map(field => field.fieldGroup))
    ] //Unique fieldGroups in availableFields
    for (const fieldGroupId of fieldGroupIds) {
      const fieldGroupFields = availableFields.filter(field => {
        return field.fieldGroup === fieldGroupId
      }) //All fields in the current fieldGroup
      let fieldGroupChildren = []
      for (const fieldGroupField of fieldGroupFields) {
        //Load the list of child fields for the current filedGroup
        fieldGroupChildren.push({
          key: fieldGroupField.fieldName,
          value: fieldGroupField.fieldName,
          title: fieldGroupField.fieldCaption
        })
      }
      availableFieldsTreeData.push({
        key: fieldGroupId,
        value: fieldGroupId,
        title: fieldGroupId,
        selectable: false,
        children: fieldGroupChildren
      })
    }
    console.log(
      'availableFieldsTreeData',
      availableFieldsTreeData,
      availableFields
    )
  }

  //--------
  //Open district report
  //--------
  const openDistrictReport = districtReportId => {
    setSelectedDistrictReportId(districtReportId)

    //Get selected district report from state (could change this to a query if districtReports object gets too big)
    const newDistrictReport = districtReports.find(
      ({ id }) => id === districtReportId
    )
    setDistrictReport(newDistrictReport)

    //Set availableFields from base report
    const newAvailableFields = newDistrictReport.baseReport.fields
      ? newDistrictReport.baseReport.fields
      : null
    setAvailableFields(newAvailableFields)
    loadAvailableFieldsTreeData(newAvailableFields)

    //Set selectedField, selectedFieldDefs and selectedFieldsList and update selected status for availableFieldsList
    const newSelectedFieldDefs = newDistrictReport
      ? newDistrictReport.selectedFields
      : [{}]
    setSelectedFieldDefs(newSelectedFieldDefs)

    let newSelectedFields = []
    for (const field of newSelectedFieldDefs) {
      newSelectedFields.push(field.fieldName)
    }
    selectedFields.current = newSelectedFields
    //setSelectedFields(newSelectedFields)

    let newSelectedFieldsList = []
    for (const field of newAvailableFields) {
      newSelectedFieldsList.push({
        value: field.fieldName,
        label: field.fieldCaption
      })
    }
    selectedFields.current = newSelectedFields
    //setSelectedFieldsList(newSelectedFieldsList)
    // console.log('values',form.getFieldsValue())
    // console.log('tree',form.getFieldValue('availableFieldsTreeSelect'))
    // form.setFieldsValue({ availableFieldsTreeSelect: newSelectedFields })

    // console.log('newSelectedFiuelds',newSelectedFields)
    //Set resultsTable columns
    //Testing whether entire columns object can be saved/reloaded
    if (false) {
      //if (newDistrictReport.reportColumns && newDistrictReport.reportColumns.length > 0) {
      //colomn sort and filter don't work if i do this.  Do I bypass/fix sort/filter loads below?
      setColumns(newDistrictReport.reportColumns) //Not sure if this is going to work.  Trying to save/restore entire columns object from db
    } else {
      let newColumns = []
      for (const field of newSelectedFields) {
        newColumns.push(getReportTableColumnObject(field, newDistrictReport))
      }
      setColumns([...newColumns])
    }

    // let newColumns = [];
    // for (const field of newSelectedFields) {
    //   newColumns.push(getReportTableColumnObject(field, newDistrictReport));
    // }
    // setColumns([...newColumns])

    //Set graphql query field @include to true for selected fields
    // let newFieldIncludes = {} //Load an array of availableFields and initialze all to false
    // for (const field of newAvailableFields) {
    //   newFieldIncludes[field.fieldName] = false
    // }
    // newSelectedFields.forEach(field => (newFieldIncludes[field] = true)) //Set to true if the field is selected
    //setFieldIncludes(newFieldIncludes)

    let fieldIncludes = {} //Load an array of availableFields and initialze all to false
    for (const field of newAvailableFields) {
      fieldIncludes[field.fieldName] = false
    }
    newSelectedFields.forEach(field => (fieldIncludes[field] = true)) //Set to true if the field is selected
    //queryIncludes = fieldIncludes

    //Load sort info
    if (newDistrictReport.sortInfo.field && newDistrictReport.sortInfo.order) {
      sortTable(newDistrictReport.sortField, newDistrictReport.sortOrder)
    }

    //Code here to load filters

    if (newDistrictReport.filterValues) {
      setFilterInfo(newDistrictReport.filterValues)
      //filterTable(newDistrictReport.filterValues) //Need to fix filtering
    }

    //Load report parameters
    if (
      newDistrictReport.reportParameters &&
      newDistrictReport.reportParameters.schoolIds
    ) {
      setSelectedSchoolIds(newDistrictReport.reportParameters.schoolIds)
    }

    //Load report distribution data
    form.setFieldsValue({
      emailRecipients: newDistrictReport.distribution.emailRecipients
    })
    form.setFieldsValue({ emailFrom: newDistrictReport.distribution.emailFrom })
    form.setFieldsValue({
      emailSubject: newDistrictReport.distribution.emailSubject
    })
    form.setFieldsValue({ emailText: newDistrictReport.distribution.emailText })
    form.setFieldsValue({
      emailAttachments: newDistrictReport.distribution.emailAttachments
    })

    //Load page layout info

    form.setFieldsValue({
      pageFormat: newDistrictReport.reportPageLayout.pageFormat
    })
    form.setFieldsValue({
      pageHeight: newDistrictReport.reportPageLayout.pageHeight
    })
    form.setFieldsValue({
      pageWidth: newDistrictReport.reportPageLayout.pageWidth
    })
    form.setFieldsValue({
      pageOrientation: newDistrictReport.reportPageLayout.pageOrientation
    })
    form.setFieldsValue({
      pageMargins: newDistrictReport.reportPageLayout.pageMargins
    })
    form.setFieldsValue({
      marginTop: newDistrictReport.reportPageLayout.marginTop
    })
    form.setFieldsValue({
      marginBottom: newDistrictReport.reportPageLayout.marginBottom
    })
    form.setFieldsValue({
      marginLeft: newDistrictReport.reportPageLayout.marginLeft
    })
    form.setFieldsValue({
      marginRight: newDistrictReport.reportPageLayout.marginRight
    })
    form.setFieldsValue({
      fontName: newDistrictReport.reportPageLayout.fontName
    })
    form.setFieldsValue({
      fontStyle: newDistrictReport.reportPageLayout.fontStyle
    })
    form.setFieldsValue({
      fontSize: newDistrictReport.reportPageLayout.fontSize
    })

    //reportTitle and reportNotes
    form.setFieldsValue({ reportTitle: newDistrictReport.reportTitle })
    form.setFieldsValue({ reportNotes: newDistrictReport.reportNotes })

    //Get data
    //getReportData()

    setReportDefChanged(false)
    setDataNeedsRefresh(false)
    setReportTitle(newDistrictReport.reportTitle)
    console.log(newDistrictReport, 'what are you')
  }

  //--------
  //Save district report definition
  //--------
  const saveDistrictReportDefinition = districtReportId => {
    //districtReportId will be empty if it is an add request

    //Get current def from state
    //let newDef = {...districtReport}; //Spread so we can modify
    let newDef = {}

    //Fields that are unchanged.  Don't need because they aren't being changed?
    newDef.districtId = districtReport.districtId
    newDef.userId = districtReport.userId
    newDef.isUserReport = districtReport.isUserReport
    newDef.baseReportId = districtReport.baseReportId
    newDef.reportCode = districtReport.reportCode
    newDef.reportName = districtReport.reportName
    newDef.reportGroup = districtReport.reportGroup

    //reportTitle/reportNotes.  From form.
    newDef.reportTitle = form.getFieldValue('reportTitle')
    const title = newDef.reportTitle
    setReportTitle(title)
    newDef.reportNotes = form.getFieldValue('reportNotes')

    //reportRowNotes.  How are we going to store this during entry? in state?
    //Following assumes a reportRowNotes state variable
    // let newReportRowNotes = [];
    // for (const reportRowNote of reportRowNotes) {
    //   const rowNote = {
    //     rowId: reportRowNote.rowId,
    //     notes: reportRowNote.notes,
    //   }
    //   newReportRowNotes.push(rowNote)
    // }

    //Report parameters.  From form.
    //Other reports with multiple parameters will have multiple entries here (e.g. start/end dates, etc.)
    const newReportParameters = {
      schoolIds: selectedSchoolIds
    }
    newDef.reportParameters = newReportParameters

    //selectedFields(column defs).  From state.
    //selectedFields must be stored in the order we want the columns to appear in the report
    //Load them from columns/reportData(?) object which reflects the current order

    const newSelectedFields = []
    for (const column of columns) {
      //This is not getting a columndef for added columns.  availableFields selector needs to save to state?
      let columnDef = {}
      columnDef = selectedFieldDefs.find(
        ({ fieldName }) => fieldName === column.dataIndex
      )
      if (!columnDef) {
        columnDef = districtReport.baseReport.fields.find(
          ({ fieldName }) => fieldName === column.dataIndex
        )
      }
      if (columnDef) {
        const newColumnDef = {
          fieldName: column.dataIndex, //fieldName.
          fieldGroup: columnDef.fieldGroup,
          fieldCaption: columnDef.fieldCaption,
          fieldIncludesGroup: columnDef.fieldIncludesGroup,
          fieldOrder: columnDef.fieldOrder,
          fieldWidth: columnDef.fieldWidth,
          fieldAlignment: columnDef.fieldAlignment,
          fieldhasFilter: columnDef.fieldhasFilter,
          fieldHasSort: columnDef.fieldHasSort,
          dataType: columnDef.dataType,
          dataFormat: columnDef.dataFormat,
          visible: columnDef.visible,
          default: columnDef.default,
          required: columnDef.required
        }
        //delete columnDef['__typename']  //errors out
        newSelectedFields.push(newColumnDef)
      }
    }
    newDef.selectedFields = newSelectedFields

    //report table columnns
    newDef.reportColumns = columns ? columns : {}

    //sortInfo.  From state.
    newDef.sortInfo = {
      field: sortInfo.field,
      order: sortInfo.order
    }

    //filterValues.  from state.
    newDef.filterValues = filterInfo

    //filters & grouping.  Unused.
    newDef.filters = []
    newDef.grouping = {}

    //reportPageLayout.  From form.
    const newReportPageLayout = {
      pageFormat: form.getFieldValue('pageFormat'),
      pageHeight: parseFloat(form.getFieldValue('pageHeight')),
      pageWidth: parseFloat(form.getFieldValue('pageWidth')),
      pageOrientation: form.getFieldValue('pageOrientation'),
      pageMargins: form.getFieldValue('pageMargins'),
      marginTop: parseFloat(form.getFieldValue('marginTop')),
      marginBottom: parseFloat(form.getFieldValue('marginBottom')),
      marginLeft: parseFloat(form.getFieldValue('marginLeft')),
      marginRight: parseFloat(form.getFieldValue('marginRight')),
      fontName: form.getFieldValue('fontName'),
      fontStyle: form.getFieldValue('fontStyle'),
      fontSize: parseFloat(form.getFieldValue('fontSize'))
    }
    newDef.reportPageLayout = newReportPageLayout

    //distribution.  From form.
    let newRecipients = []
    const formRecipients = form.getFieldValue('emailRecipients')
    if (formRecipients) {
      for (const formRecipient of formRecipients) {
        if (
          formRecipient &&
          formRecipient.email.match(
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
          )
        ) {
          //Ignore blank/invalid emails
          newRecipients.push({ email: formRecipient.email })
        }
      }
    }
    //let newAttachments = [];
    newDef.distribution = {
      emailRecipients: newRecipients,
      emailFrom: form.getFieldValue('emailFrom'),
      emailSubject: form.getFieldValue('emailSubject'),
      emailText: form.getFieldValue('emailText'),
      emailAttachments: form.getFieldValue('emailAttachments')
    }

    //schedule.  From form.
    const newSchedule = {
      scheduleActive: form.getFieldValue('scheduleActive'),
      recurrenceTimePeriod: form.getFieldValue('recurrenceTimePeriod'),
      recurrenceInterval: form.getFieldValue('recurrenceInterval'),
      recurrencePeriods: form.getFieldValue('recurrencePeriods'),
      recurrenceDayNumber: form.getFieldValue('recurrenceDayNumber'),
      recurrenceDate: form.getFieldValue('recurrenceDate'),
      scheduleStartDate: form.getFieldValue('scheduleStartDate'),
      scheduleEndDate: form.getFieldValue('scheduleEndDate')
    }
    newDef.schedule = newSchedule

    //Mutate districtReports collection
    //const districtReportId = districtReport._id

    try {
      clearErrors()
      const mutated = updateDistrictReport({
        variables: { id: selectedDistrictReportId, districtReportInput: newDef }
      })
      notificationSuccess('Report definition saved')
    } catch (e) {
      if (e) {
        console.log(e, 'error')
      }
      message.error(
        'Error(s) need to be corrected before the report definition can be saved.'
      )
      setErrors(formatErrors(e))
    }
  }

  //==============================================================================
  //Report components
  //==============================================================================
  //--------
  //District Reports list
  //--------
  // const FormItemSelectDistrictReport = (
  //   label,
  //   name,
  //   placeholder,
  // ) => {

  //   const { loading, error, data } = useQuery(
  //     QUERY_DISTRICT_REPORTS,
  //     {
  //       variables: {districtIds: districtIds}
  //     }
  //   );

  //   if (loading) return 'Loading...';
  //   if (error) return `Error! ${error.message}`;
  //   setDistrictReports(data.districtReports)

  //   return (
  //     <Select
  //       label={label}
  //       name={name}
  //       placeholder={placeholder}
  //       onChange={(value, event) => {openDistrictReport(value, event)}}
  //       value={selectedDistrictReportId}
  //     >
  //       {districtReports && districtReports.map((districtReport) => (
  //         <Select.Option
  //           key={districtReport.id}
  //           value={districtReport.id}
  //         >
  //           {districtReport.reportName}
  //         </Select.Option>
  //       ))}
  //     </Select>
  //   );
  // }

  //--------
  //District Reports TreeSelect
  //--------
  //const [districtReportsLoaded, setDistrictReportsLoaded] = useState(false)

  const DistrictReportTreeSelect = (label, name) => {
    const skipLoad = districtReportsLoaded.current
    //console.log('districttree',districtReportsLoaded.current,skipLoad)
    const { data, loading, error } = useQuery(QUERY_DISTRICT_REPORTS, {
      skip: false,
      //skip: districtReportsLoaded,
      variables: { districtIds: districtIds }
    })

    if (loading) return 'Loading...'
    if (error) return `Error! ${error.message}`
    if (!loading) {
      districtReports = data?.districtReports //global
      districtReportsLoaded.current = true
    }

    //districtReports = data.districtReports;
    //setDistrictReports(districtReports)

    //Load treeData
    let treeData = []

    if (districtReports) {
      const reportGroupIds = [
        ...new Set(districtReports.map(report => report.reportGroup))
      ] //Unique reportGroups in districtReports
      for (const reportGroupId of reportGroupIds) {
        const reportGroupReports = districtReports.filter(report => {
          return report.reportGroup === reportGroupId
        }) //All districtReports in the current reportGroup
        let reportGroupChildren = []
        for (const reportGroupReport of reportGroupReports) {
          //Load the list of children reports for the current reportGroup
          reportGroupChildren.push({
            key: reportGroupReport.id,
            value: reportGroupReport.id,
            title: reportGroupReport.reportName
          })
        }
        treeData.push({
          key: reportGroupId,
          value: reportGroupId,
          title: reportGroupId,
          selectable: false,
          children: reportGroupChildren
        })
      }
    }

    return (
      <>
        {loading ? (
          <>
            <TreeSelect />
          </>
        ) : (
          <>
            {' '}
            <TreeSelect
              allowclear='true'
              label={label}
              loading={loading}
              name={name}
              onChange={(value, event) => {
                openDistrictReport(value, event)
              }}
              //onChange={(value) => {console.log('FP treeSelect',value)}}
              placeholder='Select a report from this list'
              showSearch
              treeData={treeData}
              treeDefaultExpandAll
              value={selectedDistrictReportId || undefined}
            />
          </>
        )}
      </>
    )
  }

  //--------
  //Available Fields TreeSelect
  //--------
  const AvailableFieldsTreeSelect = parms => {
    //console.log('tree',parms)

    return (
      <TreeSelect
        showAction='focus'
        allowClear
        label={parms.name}
        multiple
        showSearch={true}
        name={parms.id}
        //open={availableFieldsListOpen}
        listHeight={640} //Each option is 32px in standard size so listHeight=320 is 10 options
        onChange={value => handleAvailableFieldsOnChange(value)}
        //onBlur={handleAvailableFieldsOnBlur()}
        //onBlur={(value) => handleAvailableFieldsOnChange(value)}
        //onBlur={console.log('FP tree onblur')}
        //onClick={(e) => {console.log('availablefields.onClick()', e)}}
        //onDropdownVisibleChange={(open) =>{console.log('drop',open); handleAvailableFieldsOnBlur() }}
        // onFocus={(e) => {console.log('availablefields.onFocus()');  }}
        //onBlur={console.log('FP tree onblur')}
        //onClick={(e) => {console.log('availablefields.onClick()', e); open=true; e.preventDefault(); e.stopPropagation()}}
        //onSelect={ (value)  => handleAvailableFieldsOnSelect(value, event)}  //value of single option selected
        onDeselect={value => handleAvailableFieldsOnDeselect(value)} //value of single option selected
        //onDropdownVisibleChange={(open) => {console.log('dropdownVisibleChange'); open=!open}} //Do nothing
        //onDropdownVisibleChange={''} //Do nothing

        placeholder='Select the fields you want to see on your report'
        showCheckedStrategy='SHOW_CHILD'
        treeCheckable={true}
        treeData={availableFieldsTreeData}
        treeDefaultExpandAll
        value={selectedFields.current}
      />
    )
  }

  //--------
  //Report Table
  //--------
  const ReportTable = (label, name) => {
    // const { loading, error, data } = useQuery(QUERY_SCHOOLS_REPORT, {
    //   variables: { schoolIds: selectedSchoolIds }
    // })

    // if (loading) return 'Loading...'
    //if (error) return `Error! ${error.message}`
    // if (!queryData) return 'Waiting'

    //setReportData(queryData?.schoolsReport)
    //currentReportData = reportData

    const onDragEnd = (fromIndex, toIndex) => {
      setColumns(prev => {
        const nextColumns = [...prev]
        const item = nextColumns.splice(fromIndex, 1)[0]
        nextColumns.splice(toIndex, 0, item)
        return nextColumns
      })
    }
    const tableColumns = columns.map((col, index) => ({
      ...col,
      onHeaderCell: column => ({
        width: column.width
      })
    }))
    return (
      <>
        <ReactDragListView.DragColumn
          onDragEnd={onDragEnd}
          nodeSelector='th'
          handleSelector='.dragHandler'
          ignoreSelector='react-resizable-handle'
        >
          <Table
            label={label}
            name={name}
            columns={tableColumns}
            loading={queryLoading}
            dataSource={reportData}
            //borderCollapse={'collapse'}
            onChange={handleReportTableOnChange}
            style={{ width: '100%', marginTop: '24px' }}
            scroll={{ x: 1500 }}
            // onHeaderRow={(columns, index) => {
            //   return {
            //     //onClick: () => {console.log('header onClick',columns,index,columns[index].title)}, // click header row
            //     onDoubleClick: () => {console.log('header onDoubleClick', columns, index)}, // not picked up.  because of sort/filter?
            //   };
            // }}
          />
        </ReactDragListView.DragColumn>
      </>
    )
  }

  //------------------------------------------------------------------------------
  //Handle a change to schools selections
  //------------------------------------------------------------------------------
  const handleSchoolIdsChange = selectedSchoolIds => {
    setSelectedSchoolIds(selectedSchoolIds)
    setReportDefChanged(true)
    setDataNeedsRefresh(true)
  }

  //--------
  //
  //--------
  const FormItemSelectSchools = ({
    label,
    name,
    validateStatus,
    help,
    hasFeedback,
    mode,
    required,
    selectedSchoolIds
  }) => {
    const { data, loading } = useQuery(QUERY_SCHOOLS)
    const { Paragraph } = Typography
    const schoolOptions = []
    data?.schools.map(x =>
      schoolOptions.push({
        value: x.name,
        label: x.name
      })
    )
    return (
      <>
        <div>
          <Title level={5}>3. Select Schools: </Title>
        </div>
        <Form.Item
          validateStatus={validateStatus}
          help={help}
          // label='3. Select Schools:'
          name='schoolIds'
          //hasFeedback={hasFeedback}
          requiredMark={required}
          rules={[
            {
              required: true,
              message: (
                <Paragraph type='danger'>
                  At least one school must be selected.
                </Paragraph>
              )
            }
          ]}
        >
          <Select
            mode={mode}
            loading={loading}
            allowClear
            onChange={(value, event) => {
              handleSchoolIdsChange(value, event, selectedSchoolIds)
            }}
            value={selectedDistrictReportId ? schoolOptions : []}
            optionFilterProp='children'
            filterOption={(input, option) =>
              option.children
                .toString()
                .toLowerCase()
                .indexOf(input.toString().toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toString()
                .toLowerCase()
                .localeCompare(optionB.children.toString().toLowerCase())
            }
          >
            {data?.schools &&
              data.schools.map(school => (
                <Select.Option key={school.id} value={school.id}>
                  {school.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      </>
    )
  }

  const handleTimeSpanSelected = (
    timeSpan,
    startDate,
    endDate,
    requestDate
  ) => {
    console.log(
      'handleTimeSpanSelected:',
      timeSpan,
      startDate.format('MM/DD/YYYY'),
      endDate.format('MM/DD/YYYY'),
      requestDate.format('MM/DD/YYYY')
    )
  }
  const changeReportTitle = e => {
    setReportTitle(e.target.value)
  }
  return (
    <Form form={form} name='schoolReport'>
      <div className='page-container'>
        <Tabs type='card' defaultActiveKey='Report'>
          <Tabs.TabPane tab='Report' key='Report'>
            <Card
              title='Select your report and customize its content'
              style={{
                marginBottom: 30
              }}
              //bordered={false}
            >
              <div>
                <Title level={5}>1. Select Report: </Title>
              </div>
              <Form.Item
                // label='1. Select Report:'
                name='districtReportsTreeSelect'
                required={true}
                districtIds={districtIds}
                //help={itemErrors.districtReports}
              >
                <DistrictReportTreeSelect
                  districtIds={districtIds}
                  onChange={(value, event) => {
                    openDistrictReport(value, event)
                  }}
                />
              </Form.Item>
              <div>
                <Title level={5}>2. Select Report Fields: </Title>
              </div>
              <Form.Item
                // label='2. Select Report Fields:'
                //name='availableFieldsTreeSelect'
                required={true}
                //onChange={(e) => {console.log('form.item.onchange'); e.stopPropagation(); e.preventDefault()}}
                //onClick={(e) => {console.log('form.item.onclick'); e.stopPropagation(); e.preventDefault()}}

                //onFocus={console.log('FP formitem-onfocus')}
                //help={itemErrors.districtReports}
              >
                <AvailableFieldsTreeSelect
                  //label='2. Select Report Fieldsx:'
                  name='availableFieldsTreeSelect'
                  //treeData={[{key:'field1',value: 'field1',title: 'field1'},{key:'field12',value: 'field12',title: 'field12'}]}
                  //onChange={event => handleAvailableFieldsOnChange(event)}
                  // onSelect={value => handleAvailableFieldsOnSelect(value)}
                  // onDeselect={value => handleAvailableFieldsOnDeselect(value)}
                  //onDropDownVisibleChange={console.log('FP ondrop')}
                />
              </Form.Item>

              <FormItemSelectSchools
                selectedSchoolIds={selectedSchoolIds}
                mode='multiple'

                //onChange={(value, event) => {handleSchoolIdsChange(value, event)}}
              />
              {/* <div><b><u>Testing Time Span Picker:</u></b></div>
              <TimeSpanPicker
                name='timeSpan'
                onTimeSpanSelected={handleTimeSpanSelected}
              /> */}

              {/* Limit save, save as and delete to districtAdmin/superAdmin */}
              <Row style={{ marginTop: '36px' }}>
                <Col
                  style={{ marginRight: '8px', justifyContent: 'flex-start' }}
                >
                  {' '}
                  <Form.Item>
                    <Button
                      //disabled={!dataNeedsRefresh}
                      disabled={!selectedDistrictReportId}
                      onClick={getReportData}
                    >
                      Generate Report
                    </Button>
                  </Form.Item>
                </Col>
                <Col style={{ marginRight: '8px', justifyContent: 'flex-end' }}>
                  <Form.Item>
                    <Tooltip title='Save this report'>
                      <Button
                        disabled={!selectedDistrictReportId} //Disable if a district report isn't open
                        // icon={<SaveTwoTone style={{ fontSize: '125%' }} />}
                        //disabled={!reportDefChanged}
                        onClick={() => {
                          saveDistrictReportDefinition()
                          setReportDefChanged(false)
                        }}
                      >
                        Save
                      </Button>
                    </Tooltip>
                  </Form.Item>
                </Col>
                <Col style={{ marginRight: '8px' }}>
                  <Tooltip title='Save a copy of this report under a new name'>
                    <Button
                      disabled={!selectedDistrictReportId} //Disable if a district report isn't open
                      // icon={<CopyTwoTone style={{ fontSize: '125%' }} />}
                      onClick={showReportDefModal}
                    >
                      Save As
                    </Button>
                  </Tooltip>
                </Col>
                <Col style={{ marginRight: '8px' }}>
                  <Popconfirm
                    title='Are you sure you want to delete this report'
                    //description="Are you sure you want to delete this report?"  //This isn't working for some reason
                    icon={<DeleteOutlined style={{ color: 'red' }} />}
                    placement='rightBottom'
                    onConfirm={deleteDistrictReportDefinition}
                    //onCancel={cancel}
                    okText='Yes'
                    cancelText='No'
                  >
                    <Tooltip title='Delete this report' placement='leftTop'>
                      <Button
                        disabled={!selectedDistrictReportId} //Disable if a district report isn't open
                        //danger
                        // icon={<DeleteTwoTone style={{ fontSize: '125%' }} />}
                      >
                        Delete
                      </Button>
                    </Tooltip>
                  </Popconfirm>
                </Col>
              </Row>
            </Card>

            <Card
              title={reportTitle !== '' ? reportTitle : 'Untitled Report'}
              bordered={true}
              style={{
                marginBottom: 30
              }}
            >
              <Modal
                title='Save this report definition as...'
                open={isReportDefSaveAsModalOpen}
                disabled={false}
                footer={[
                  <Button key='cancel' onClick={handleReportDefModalCancel}>
                    Cancel
                  </Button>,
                  <Button
                    type='primary'
                    key='save'
                    onClick={handleReportDefModalSave}
                  >
                    Save
                  </Button>
                ]}
              >
                <Form.Item
                  label='Report Definition Name:'
                  name='reportDefName'
                  //style={{ width: "75%" }}
                >
                  <Input
                    type='text'
                    placeholder='Enter a name for this report'
                    initialValue={districtReport.reportName}
                  ></Input>
                </Form.Item>
              </Modal>
              <div>
                <Title level={5}>Report Title: </Title>
              </div>

              <Tooltip title='Page Layout'>
                <Button
                  type="primary"
                  icon={<LayoutOutlined />}
                  size='large' 
                />  
              </Tooltip>







              <Form.Item name='reportTitle' style={{ width: '100%' }}>
                <Input
                  onChange={changeReportTitle}
                  placeholder='Title your report.'
                />
              </Form.Item>
              <div>
                <Title level={5}>Report Notes: </Title>
              </div>
              <Form.Item
                //label='Notes'
                name='reportNotes'
                style={{ width: '100%' }}
              >
                <TextArea
                  rows={3}
                  placeholder='Enter any comments you would like on this report.'
                />
              </Form.Item>
              {/* <ThePdfModal /> */}
              <TheDistributionModal />
              <Row style={{ marginTop: '24px' }}>
                {/* <Col style={{ marginRight: '8px' }}>
                  <Form.Item>
                    <Button
                      //disabled={!dataNeedsRefresh}
                      onClick={getReportData}
                    >
                      Refresh Data
                    </Button>
                  </Form.Item>
                </Col> */}
                <Col style={{ marginRight: '8px' }}>
                  <Form.Item>
                    <Button
                      type='primary'
                      key='save'
                      onClick={() => getPdf('open')}
                    >
                      Export to PDF
                    </Button>
                  </Form.Item>
                </Col>
                <Col style={{ marginRight: '8px' }}>
                  <Form.Item>
                    <Button
                      disabled={!selectedDistrictReportId}
                      onClick={() => getCsv('download')}
                    >
                      Export to CSV
                    </Button>
                  </Form.Item>
                </Col>
                <Col style={{ marginRight: '8px' }}>
                  <Form.Item>
                    <Button
                      disabled={!selectedDistrictReportId}
                      onClick={showDistributionModal}
                    >
                      Distribute Report
                    </Button>
                  </Form.Item>
                </Col>
                <Col style={{ marginRight: '8px' }}>
                  <Form.Item>
                    <Button
                      disabled={!selectedDistrictReportId}
                      onClick={handleClearFilters}
                    >
                      Clear Filters
                    </Button>
                  </Form.Item>
                </Col>
                <Col style={{ marginRight: '8px' }}>
                  <Form.Item>
                    <Button
                      disabled={!selectedDistrictReportId}
                      onClick={handleClearSort}
                    >
                      Clear Sort
                    </Button>
                  </Form.Item>
                </Col>
              </Row>

              <ReportTable label='Results Table' name='reportTable' />
            </Card>
          </Tabs.TabPane>

          <Tabs.TabPane tab='Report Distribution' key='Report Distribution'>
            <Card
              title='Report Distribution'
              style={{
                marginBottom: 30
              }}
              //bordered={false}
            >
              <div>
                <Title level={5} underline>
                  Recipients:{' '}
                </Title>
              </div>
              <Form.List
                name='emailRecipients' //How can we prevent empty entries?
              >
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (
                      <Space
                        style={{ display: 'flex', marginBottom: 8 }}
                        align='baseline'
                      >
                        <Form.Item
                          {...field}
                          style={{ width: '350px' }}
                          label='Email'
                          name={[field.name, 'email']}
                          key={[field.fieldKey, 'email']}
                          rules={[
                            { required: true, message: 'Email is empty' },
                            {
                              type: 'email',
                              warningOnly: true,
                              message: 'Email is invalid'
                            },
                            { type: 'string', whitespace: true }
                          ]}
                        >
                          <Input placeholder="Enter recipient's email" />
                        </Form.Item>

                        <MinusCircleOutlined
                          onClick={() => remove(field.name)}
                        />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type='dashed'
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                        style={{ width: '24%' }}
                      >
                        Add a Recipient
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              <Divider style={{ backgroundColor: 'darkblue' }} />
              <Form.Item
                label='From:'
                name='emailFrom'
                rules={[
                  { required: true, message: 'Email is empty' },
                  {
                    type: 'email',
                    warningOnly: true,
                    message: 'Email is invalid'
                  },
                  { type: 'string', whitespace: true }
                ]}
                style={{ width: '25%' }}
              >
                <Input
                  type='text'
                  placeholder="Enter the 'From' email address"
                  //initialValue={districtReport.distribution.emailFrom ? districtReport.distribution.emailFrom : ''}
                ></Input>
              </Form.Item>
              <Form.Item
                label='Subject:'
                name='emailSubject'
                style={{ width: '50%' }}
              >
                <Input
                  type='text'
                  placeholder="Enter the email 'Subject'"
                  //initialValue={districtReport.distribution.emailSubject ? districtReport.distribution.emailSubject : ''}
                ></Input>
              </Form.Item>
              <Form.Item label='Message:' name='emailText' initialValue=''>
                <Input.TextArea
                  placeholder='Enter email text'
                  autoSize={{ minRows: 5, maxRows: 10 }}
                />
              </Form.Item>

              <FormItem
                label='Attachments:'
                name='emailAttachments'
                //{...validationProps('emailAttachments')}
              >
                <Checkbox.Group
                  // onChange={checkedValues => {
                  //   const e = {
                  //     target: {
                  //       name: 'communicationMode',
                  //       value: checkedValues.toString()
                  //     }
                  //   }
                  //   handleMouseEvents.onChange(e)
                  // }}
                  // disabled={formDisabledState.communicationMode}
                  name='emailAttachments'
                >
                  <Checkbox value='pdf'>Report Pdf</Checkbox>
                  <Checkbox value='csv'>Report CSV</Checkbox>
                </Checkbox.Group>
              </FormItem>
            </Card>
            <Form.Item>
              <Tooltip title='Save report definition'>
                <Button
                  //type="primary"
                  tooltip='Save report definition'
                  // icon={<SaveTwoTone style={{ fontSize: '125%' }} />}
                  //disabled={!reportDefChanged}
                  onClick={() => {
                    saveDistrictReportDefinition()
                    setReportDefChanged(false)
                  }}
                >
                  Save
                </Button>
              </Tooltip>
            </Form.Item>
          </Tabs.TabPane>

          {/* <Tabs.TabPane tab='Field Details' key='Field Details'>
            <Card
              title='Report field definitions'
              bordered={true}
              style={{
                marginBottom: 30
              }}
            >
              <div>Finer field definitions including:</div>
              <div>field caption - the column header text</div>
              <div>has sort - does column have sort option?</div>
              <div>has filter - does column have filter option?</div>
              <div>
                column width - if we don't do resizable columns in results
                table.
              </div>
            </Card>
            <Form.Item>
              <Tooltip title='Save report definition'>
                <Button
                  //type="primary"
                  tooltip='Save report definition'
                  icon={<SaveTwoTone style={{ fontSize: '125%' }} />}
                  //disabled={!reportDefChanged}
                  onClick={() => {
                    saveDistrictReportDefinition()
                    setReportDefChanged(false)
                  }}
                ></Button>
              </Tooltip>
            </Form.Item>
          </Tabs.TabPane> */}

          <Tabs.TabPane tab='Page Layout' key='Page Layout'>
            <Card
              title='Page orientation and size'
              bordered={true}
              style={{
                marginBottom: 30
              }}
            >
              <Col span={5} alignItems='right' Justify='right'>
                <Row align='end'>
                  <Form.Item
                    className='form-item-text'
                    label='Page Orientation:'
                    name='pageOrientation'
                  >
                    <Select
                      defaultValue='Portrait'
                      //style={{ width: "125px" }}
                      options={[
                        { value: 'Portrait', label: 'Portrait' },
                        { value: 'Landscape', label: 'Landscape' }
                      ]}
                    />
                  </Form.Item>
                </Row>
                <Row align='end'>
                  <Form.Item
                    className='form-item-text'
                    label='Page Size:'
                    name='pageFormat' //store in doc?
                  >
                    <Select
                      defaultValue='Letter'
                      //style={{ width: "125px" }}
                      onChange={value => handlePaperSizeChange(value)}
                    >
                      {Object.keys(paperSizesEnum).map(paperSize => (
                        <option key={paperSize} value={paperSize}>
                          {' '}
                          {paperSize}{' '}
                        </option>
                      ))}
                    </Select>
                  </Form.Item>
                </Row>

                <Row align='end'>
                  <Form.Item
                    label='Page Width:' //Set when pageFormat selected
                    name='pageWidth'
                  >
                    <InputNumber disabled defaultValue={8.5} precision={2} />
                  </Form.Item>
                </Row>

                <Row align='end'>
                  <Form.Item
                    label='Page Height:' //Set when pageFormat selected
                    name='pageHeight'
                    validateStatus={itemErrors.pageHeight ? 'error' : ''} //Test how we handle errors
                    help={itemErrors.pageHeight} //Test how we handle errors
                  >
                    <InputNumber disabled defaultValue={11.0} precision={2} />
                  </Form.Item>
                </Row>
              </Col>
            </Card>

            <Card
              title='Page margins'
              bordered={true}
              style={{
                marginBottom: 30
              }}
            >
              <Col span={5} alignItems='right' Justify='right'>
                <Row align='end'>
                  <Form.Item
                    className='form-item-text'
                    label='Page Margins:'
                    name='pageMargins' //Store in doc?  change marginRight to marginRight
                  >
                    <Select
                      defaultValue='Normal'
                      //style={{ width: "100px" }}
                      onChange={value => handlePageMarginsChange(value)}
                    >
                      {Object.keys(pageMarginsEnum).map(margin => (
                        <option key={margin} value={margin}>
                          {' '}
                          {margin}{' '}
                        </option>
                      ))}
                    </Select>
                  </Form.Item>
                </Row>

                <Row align='end'>
                  <Form.Item
                    label='Top Margin:' //Set when pageMargins selected
                    name='marginTop'
                  >
                    <InputNumber disabled defaultValue={0.75} precision={2} />
                  </Form.Item>
                </Row>

                <Row align='end'>
                  <Form.Item
                    label='Bottom Margin:' //Set when pageMargins selected
                    name='marginBottom'
                  >
                    <InputNumber disabled defaultValue={0.75} precision={2} />
                  </Form.Item>
                </Row>

                <Row align='end'>
                  <Form.Item
                    label='Left Margin:' //Set when pageMargins selected
                    name='marginLeft'
                  >
                    <InputNumber disabled defaultValue={0.75} precision={2} />
                  </Form.Item>
                </Row>

                <Row align='end'>
                  <Form.Item
                    label='Right Margin:' //Set when pageMargins selected
                    name='marginRight'
                  >
                    <InputNumber disabled defaultValue={0.75} precision={2} />
                  </Form.Item>
                </Row>
              </Col>
            </Card>

            <Card
              title='Font'
              bordered={true}
              style={{
                marginBottom: 30
              }}
            >
              <Col span={5} alignItems='right' Justify='right'>
                <Row align='end'>
                  <Form.Item
                    className='form-item-text'
                    label='Font:'
                    name='fontName'
                  >
                    <Select
                      defaultValue='Helvetica'
                      //style={{ width: "175px" }}
                    >
                      {Object.keys(fontNamesEnum).map(font => (
                        <option key={font} value={font}>
                          {' '}
                          {font}{' '}
                        </option>
                      ))}
                    </Select>
                  </Form.Item>
                </Row>

                <Row align='end'>
                  <Form.Item
                    className='form-item-text'
                    label='Font Style:'
                    name='fontStyle'
                  >
                    <Select defaultValue='Normal'>
                      {Object.keys(fontStylesEnum).map(fontStyle => (
                        <option key={fontStyle} value={fontStyle}>
                          {' '}
                          {fontStyle}{' '}
                        </option>
                      ))}
                    </Select>
                  </Form.Item>
                </Row>

                <Row align='end'>
                  <Form.Item label='Font Size:' name='fontSize'>
                    <InputNumber
                      defaultValue={16}
                      min={4}
                      max={72}
                      step={0.5}
                      precision={1}
                    />
                  </Form.Item>
                </Row>
              </Col>
            </Card>

            <Form.Item>
              <Tooltip title='Save report definition'>
                <Button
                  //type="primary"
                  tooltip='Save report definition'
                  // icon={<SaveTwoTone style={{ fontSize: '125%' }} />}
                  //disabled={!reportDefChanged}
                  onClick={() => {
                    saveDistrictReportDefinition()
                    setReportDefChanged(false)
                  }}
                >
                  Save
                </Button>
              </Tooltip>
            </Form.Item>
            {/* <div>
              Maybe add page size and orientation to reports page for quick
              reference?
            </div>
            <div>
              Page height/width set when page size selected. "Custom" selection
              allows entry
            </div>
            <div>
              Maybe have defined "margin groups" as
              "normal/wide/narrow/custom/etc" that set actual values?
            </div> */}
          </Tabs.TabPane>

          {/* <Tabs.TabPane tab='Scheduling' key='Scheduling'>
            <Card
              title='Schedule your report'
              bordered={true}
              style={{
                marginBottom: 30
              }}
            >
              <Form.Item
                className='form-item-text'
                label='Run as schuduled:'
                name='scheduleActive'
                valuePropName='checked'
                wrapperCol={{ sm: 20 }}
              >
                <Switch
                  checkedChildren='Yes'
                  unCheckedChildren='No'
                  size='default'
                />
              </Form.Item>

              <Form.Item
                className='form-item-text'
                label='Repeat:'
                name='recurrenceTimePeriod'
              >
                <Select
                  defaultValue='everyday'
                  style={{ width: '10%' }}
                  //onChange={handleChange}
                  options={[
                    { value: 'everyday', label: 'Everyday' },
                    { value: 'weekly', label: 'Every Week' },
                    { value: 'monthly', label: 'Every Month' },
                    { value: 'yearly', label: 'Every Year' }
                  ]}
                />
              </Form.Item>

              <Form.Item
                className='form-item-text'
                label='Day of Week:'
                name='day'
              >
                <Select
                  defaultValue='sunday'
                  style={{ width: '10%' }}
                  //onChange={handleChange}
                  options={[
                    { value: 'sunday', label: 'Sunday' },
                    { value: 'monday', label: 'Monday' },
                    { value: 'tuesday', label: 'Tuesday' },
                    { value: 'wednesday', label: 'Wednesday' },
                    { value: 'thursday', label: 'Thursday' },
                    { value: 'friday', label: 'Friday' },
                    { value: 'saturday', label: 'Saturday' }
                  ]}
                />
              </Form.Item>

              <Form.Item className='form-item-text' label='Month:' name='month'>
                <Select
                  defaultValue='january'
                  style={{ width: '10%' }}
                  //onChange={handleChange}
                  options={[
                    { value: 'january', label: 'January' },
                    { value: 'february', label: 'February' },
                    { value: 'march', label: 'March' },
                    { value: 'april', label: 'April' },
                    { value: 'may', label: 'May' },
                    { value: 'june', label: 'June' },
                    { value: 'july', label: 'July' },
                    { value: 'august', label: 'August' },
                    { value: 'september', label: 'September' },
                    { value: 'october', label: 'October' },
                    { value: 'november', label: 'November' },
                    { value: 'december', label: 'December' }
                  ]}
                />
              </Form.Item>
            </Card>
            <Form.Item>
              <Tooltip title='Save report definition'>
                <Button
                  //type="primary"
                  tooltip='Save report definition'
                  icon={<SaveTwoTone style={{ fontSize: '125%' }} />}
                  //disabled={!reportDefChanged}
                  onClick={() => {
                    saveDistrictReportDefinition()
                    setReportDefChanged(false)
                  }}
                ></Button>
              </Tooltip>
            </Form.Item>
            <div>
              Basically mirror entering a task in outlook or on your phone.
            </div>
            <div>
              Much more info is required. This is just some basic stuff so we
              can test db load/save..ludes
            </div>
          </Tabs.TabPane> */}
        </Tabs>
      </div>
    </Form>
  )
}

ReportForm.propTypes = {
  data: PropTypes.object.isRequired
}

const SchoolReports = props => {
  const location = useLocation()

  //Following two lines get districtReportId from URL if we pass in url.
  //const pathnameParts = location.pathname.split('/')
  //const districtReportId = pathnameParts[pathnameParts.length - 1];
  const districtReportId = location?.state?.districtReportId
  // console.log('SchoolReports-location',districtReportId, location);

  //Now we can open the districtReport here and remove the "1. Select Report" field.  This will greatly simplify this code.

  //const { loading, data: baseReports } = useQuery(QUERY_BASE_REPORTS)
  const { loading, data: districtReports } = useQuery(QUERY_DISTRICT_REPORTS)

  return (
    <>
      {/* {!loading && districtReports !== undefined ? ( */}
      <>
        <FormContainer
          loading={loading}
          form={<ReportForm data={districtReports} />}

          //form={<ReportForm, data={baseReports} />}
          //form={<reportForm, data={initializedData() || initialValues} />}
        />
      </>
      {/* ) : (
        <></>
      )} */}
    </>
  )
}

export default SchoolReports
