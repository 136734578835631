/**
 * High Order components
 * Responsible for handling Print, Save, Download functionality
 */
import React, { useState, useContext, useEffect, MouseEvent } from 'react'
import PropTypes from 'prop-types'
import {
  Form,
  Space,
  Button,
  Checkbox,
  Alert,
  Affix,
  Card,
  message
} from 'antd'
import { useLazyQuery, useMutation } from '@apollo/client'

import FileAction, { FILE_ACTIONS } from '../../elements/FileAction'
import formatErrors from '../../../utils/form'
import ModalDialog from '../../ModalDialog'
import { FormErrorContext } from './errorProvider'
import { IepFormContext } from '../../../utils/iepFormProvider'

import {
  QUERY_STUDENT_MEETING_FORMS,
  QUERY_STUDENT_FORM_STATUS
} from '../../../operations/queries/meeting'
import { UPDATE_STUDENT_FORM_STATUS } from '../../../operations/mutations/meetings'
import {
  notificationError,
  notificationInfo
} from '../../../utils/notification'
import NavigationPrompt from '../../layout/NavigationPrompt'
import { PropertySafetyFilled } from '@ant-design/icons'

const StudentFormActions = ({
  query,
  extractDataProp,
  canSave,
  getFormFieldValues,
  children,
  handleActionEvents,
  form
}) => {
  const [printForm, { loading: submissionLoading }] = useMutation(query)
  const { clearErrors, setErrors } = useContext(FormErrorContext)
  const {
    hasAddPermission,
    hasPrintPermission,
    hasViewPermission,
    currentEditableForm,
    setCurrentEditableForm
  } = useContext(IepFormContext)
  const {
    studentFormId,
    formStatusEnumName,
    isFinalized,
    isAmendChecked,
    isFinalizeChecked
  } = currentEditableForm

  //Default newFormStatus to current formStatusEnumName if it is not set (e.g. form just opened)
  const newFormStatus = currentEditableForm.newFormStatus ? currentEditableForm.newFormStatus : currentEditableForm.formStatusEnumName

  const initialFileActionState = {
    action: '',
    filename: '',
    link: '',
    version: Date.now().toString()
  }

  const [fileActionState, setFileActionState] = useState(initialFileActionState)
  const [saved, setSaved] = useState(false)
  const [saveDisabled, setSaveDisabled] = useState(false)

  const formStatusDisplay = Object.freeze({
    SPAWNED: "Initiated",
    FIRSTDRAFT: "First Draft",
    FINALIZED: "Finalized",
    DRAFT: "Amended Draft",
    AMENDED: "Amended",
  });

  // const handleFormActionSave = (event) => {
  //   console.log('form saved',event,event.target,event.target.buttonName);
  // };

  const handleAmendCheckboxChange = (event) => {
    const formStatus = event.target.checked ? "AMENDED" : ""
    setCurrentEditableForm({
      ...currentEditableForm,
      newFormStatus: formStatus,
      isAmendChecked: !isAmendChecked
    })
    notificationInfo("Click 'Save' to confirm amendment and unlock fields.")
  };

  const handleFinalizeCheckboxChange = (event) => {
    const formStatus = event.target.checked ? "FINALIZED" : ""
    setCurrentEditableForm({
      ...currentEditableForm,
      newFormStatus: formStatus,
      isFinalizeChecked: !isFinalizeChecked
    })
    notificationInfo("Click 'Save' to confirm finalization.")
  };

  // const [
  //   getFormStatus,
  //   { loading: formStatusLoading, error: formStatusError, data: formStatusData }
  // ] = useLazyQuery(QUERY_STUDENT_FORM_STATUS)

  const [updateFormStatus, { data: updateStatusData }] = useMutation(
    UPDATE_STUDENT_FORM_STATUS,
    {
      onError: e => {
        notificationError('Failed to update form status', '')
      }
    }
  )

  useEffect(() => {
    const { studentMeetingId, formId } = getFormFieldValues()
    //getFormStatus({ variables: { studentFormId, studentMeetingId, formId } })
  }, [updateStatusData, submissionLoading])

  //useEffect(() => {}, [newFormStatus])

  useEffect(() => {
    console.log('StudentFormActions-useEffect-isFinalizeChecked', isFinalizeChecked)
  }, [isFinalizeChecked])
  useEffect(() => {
    console.log('StudentFormActions-useEffect-isAmendChecked', isAmendChecked)
  }, [isAmendChecked])

  const initFileState = action => {
    setFileActionState({ ...initialFileActionState, action })
  }

  const onActionClick = async (actionType) => {
    const values = getFormFieldValues()
    values.formAction = actionType //save/print/download
    values.formStatus = newFormStatus ? newFormStatus : currentEditableForm.formStatusEnumName //empty, FINALIZE or AMENDED.  Set by final/amend checkboxes onChange().
    console.log('onActionClick-event', actionType, newFormStatus, values)
    try {
      clearErrors()
      initFileState(actionType)
      const mutated = await printForm({
        variables: values,
        refetchQueries: [
          {
            query: QUERY_STUDENT_MEETING_FORMS,
            variables: { id: values.studentMeetingId }
          }
        ]
      })
      const { link, fileName } = mutated.data[extractDataProp]

      //Set form statuses and update currentEditableForm context
      //Form statuses are set as follows:
      //1) If isFinalizedChecked: formStatusEnumName = FINALIZED
      //2) If isAmendedChecked (only possible if form previously finalized): formStatusEnumName = DRAFT
      //3) If current formStatusEnumName=SPAWNED (only possible when form first added to meeting): formStatusEnumName = FIRSTDRAFT
      //4) If current FormStatusEnumName=FIRSTDRAFT or DRAFT and form not marked as final: formStatusEnumName is unchanged
      if (actionType === 'save') {
        setSaved(true)
        let isFinalizedUpdate = false
        let formStatusEnumNameUpdate = formStatusEnumName
        if (isFinalizeChecked) {
          isFinalizedUpdate = true
          formStatusEnumNameUpdate = "FINALIZED"
        } else if (isAmendChecked) {
          formStatusEnumNameUpdate = "DRAFT"
        } else if (formStatusEnumName === "SPAWNED") {
          formStatusEnumNameUpdate = "FIRSTDRAFT"
        }
        setCurrentEditableForm({  //Clear amend/finalize checkboxes
          ...currentEditableForm,
          isFinalized: isFinalizedUpdate,
          formStatusEnumName: formStatusEnumNameUpdate,
          isAmendChecked: false,
          isFinalizeChecked: false
        })
      }

      switch (actionType) {
        case FILE_ACTIONS.print:
          setFileActionState({
            action: FILE_ACTIONS.print,
            fileName,
            link,
            version: Date.now().toString()
          })
          break
        case FILE_ACTIONS.download:
          setFileActionState({
            action: FILE_ACTIONS.download,
            fileName,
            link,
            version: Date.now().toString()
          })
          break
        case FILE_ACTIONS.save:
          ModalDialog.ok({
            content: 'Form has been saved.'
          })
          break
        default:
          return ModalDialog.fail({
            content: 'Form has not been saved.'
          })
      }
    } catch (e) {
      setErrors(formatErrors(e))
      // ModalDialog.fail({
      //   // content: formatErrors(e)
      //   content: e.toString()
      // })
    }
  }

  const formStatusOutput = () => {
    if (formStatusLoading) {
      return <p>Loading form status...</p>
    }

    if (formStatusError) {
      return <p>Failed to get form status!</p>
    }

    if (!formStatusData) {
      return <>No form status data</>
    }
  }

  //if (!hasPrintPermission) {
  //  return <>User does not have print permission. </>;
  //}

  return (
    <>
      <Affix offsetBottom={0} onChange={affixed => console.log(affixed)}>
        {/* <div> */}
        <Card
          style={{
            // padding: '0px',
            // marginBottom: '10px',
            height: '45px'
          }}
        >
          <Space
            style={{
              position: 'relative',
              top: '-1.3rem'
            }}
          >
            <Button
              name='formActionSave'
              type='primary'
              //disabled={!canSave}
              //disabled={formStatusEnumName !== "FINALIZED" || newFormStatus !== "FINALIZED" ? false : true}
              hidden={!hasPrintPermission && !hasAddPermission}
              loading={
                submissionLoading &&
                fileActionState.action === FILE_ACTIONS.save
              }
              onClick={(event) => {
                onActionClick(FILE_ACTIONS.save);
                handleActionEvents.onClick(event, "formActionSave", true);
              }}
            >
              Save
            </Button>

            <Button
              name='formActionPrint'
              type='primary'
              disabled={isFinalizeChecked ? true : false}  //Disable if amend pending?
              // disabled={!canSave}
              loading={
                submissionLoading &&
                fileActionState.action === FILE_ACTIONS.print
              }
              onClick={(event) => {
                onActionClick(FILE_ACTIONS.print);  //Print the form
                handleActionEvents.onClick(event, "formActionPrint", true);  //Handle collaboration
              }}
            >
              Print
            </Button>

            <Button
              type='primary'
              disabled={isFinalizeChecked ? true : false}  //Disable if finalize pending?
              // disabled={!canSave}
              loading={
                submissionLoading &&
                fileActionState.action === FILE_ACTIONS.download
              }
              onClick={(event) => {
                onActionClick(FILE_ACTIONS.download);   //Download the form
                handleActionEvents.onClick(event, "formActionDownload", true); //Handle collaboration
              }}
            >
              Download
            </Button>
            <Alert
              //message={`Current Form Status : ${formStatusData?.studentFormStatus?.display  ? formStatusData.studentFormStatus.display : ""}`}
              message={`Form Status : ${formStatusDisplay[formStatusEnumName]}`}
              type='info'
              showIcon
            />
            {formStatusEnumName !== "FINALIZED" //Conditionally render finalize/amend checkboxes
              ? <Checkbox
                checked={isFinalizeChecked}
                //disabled={isFinalizeChecked}
                onChange={(event, isFinalizeChecked) => {
                  handleFinalizeCheckboxChange(event);
                  handleActionEvents.onClick(event, "finalizeCheckbox", isFinalizeChecked);
                }}
              >
                Finalize
              </Checkbox>

              : <Checkbox
                checked={isAmendChecked}
                //disabled={isFinalized ? false : true}
                onChange={(event, isAmendChecked) => {
                  handleAmendCheckboxChange(event);
                  handleActionEvents.onClick(event, "amendCheckbox", isAmendChecked);
                }}
              >
                Amend
              </Checkbox>
            }
            {/* {formStatusOutput()} */}
          </Space>
        </Card>
        {/* </div> */}
      </Affix>
      <FileAction
        url={fileActionState.link}
        fileName={fileActionState.fileName}
        version={fileActionState.version}
        action={fileActionState.action}
      />
      {/* <NavigationPrompt saved={saved} /> */}
    </>
  )
}

StudentFormActions.propTypes = {
  query: PropTypes.object.isRequired,
  canSave: PropTypes.bool.isRequired,
  getFormFieldValues: PropTypes.func,
  children: PropTypes.object.isRequired,
  extractDataProp: PropTypes.string.isRequired
}
export default StudentFormActions
