import React from "react";
import { Row, Col, Menu, Card } from "antd";
import Page from "../components/Page";
import WorkflowNavigation from "../components/sections/iep/WorkflowNavigation";
import IprcDetails from "../components/sections/iep/IprcDetails";
// import { GET_VERSION } from '../operations/queries/'

const { SubMenu } = Menu;

const Iep = () => {
  return (
    <>
      <Page title="Search Students">
        <div className="page-container">
          <div className="page-padding-leftRight page-padding-topBottom">
            <WorkflowNavigation />
          </div>
        </div>

        <div className="page-container">
          <Card title="IPRC Details" bordered={false}>
            <Row>
              <Col flex="200px">
                <Menu
                  mode="inline"
                  defaultSelectedKeys={["1"]}
                  defaultOpenKeys={["sub1"]}
                  style={{ height: "100%" }}
                >
                  <SubMenu key="sub1" title="subnav 1">
                    <Menu.Item key="1">option1</Menu.Item>
                    <Menu.Item key="2">option2</Menu.Item>
                    <Menu.Item key="3">option3</Menu.Item>
                    <Menu.Item key="4">option4</Menu.Item>
                  </SubMenu>
                  <SubMenu key="sub2" title="subnav 2">
                    <Menu.Item key="5">option5</Menu.Item>
                    <Menu.Item key="6">option6</Menu.Item>
                    <Menu.Item key="7">option7</Menu.Item>
                    <Menu.Item key="8">option8</Menu.Item>
                  </SubMenu>
                  <SubMenu key="sub3" title="subnav 3">
                    <Menu.Item key="9">option9</Menu.Item>
                    <Menu.Item key="10">option10</Menu.Item>
                    <Menu.Item key="11">option11</Menu.Item>
                    <Menu.Item key="12">option12</Menu.Item>
                  </SubMenu>
                </Menu>
              </Col>
              <Col flex="auto">
                <div className="page-padding-leftRight page-padding-topBottom">
                  <IprcDetails />
                </div>
              </Col>
            </Row>
          </Card>
        </div>
      </Page>
    </>
  );
};

export default Iep;
