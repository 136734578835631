import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Spin, List, Space, Row, Col, Card, Typography } from 'antd'
import { IepFormContext } from '../../../utils/iepFormProvider'
import { useParams } from 'react-router-dom'

// apollo & operations
import { useMutation, useQuery } from '@apollo/client'
import { QUERY_STUDENT_MEETING_FORMS } from '../../../operations/queries/meeting'
import {
  UPDATE_STUDENT_MEETING_DATE,
  UPDATE_STUDENT_MEETING_MEETING_TYPE,
  UPDATE_STUDENT_MEETING_ISACTIVEIEP,
  UPDATE_STUDENT_MEETING_PARENT_NOTIFICATIONS
} from '../../../operations/mutations/meetings'
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons'
import {
  notificationInfo,
  notificationSuccess
} from '../../../utils/notification'

import notAvailableIcon from '../../../assets/icons/not-available.svg'
import { Link } from 'react-router-dom'
const StudentFormSidebar = ({
  setEditMode,
  setEditMeeting,
  editMeeting,
  currentGoalInfo,
  priorGoalInfo
}) => {
  const { studentMeetingId } = useParams()
  const {
    setCurrentEditableForm,
    setStudentFormInfo,
    setStudentMeetingId,
    setKeyDates,
    setFormWarning,
    // this is incorrect use of state and breaks goals links -john
    setViewPermission,
    setPrintPermission,
    setAddPermission,
    setMeetingDate
  } = useContext(IepFormContext)
  const fetchPolicy = 'network-only'
  const { data, loading } = useQuery(QUERY_STUDENT_MEETING_FORMS, {
    fetchPolicy,
    variables: { id: studentMeetingId }
  })
  //console.log('studentMeetingForms',data)

  // const [hasViewPermission, setViewPermission] = useState(false)
  // const [hasPrintPermission, setPrintPermission] = useState(false)
  // const [hasAddPermission, setAddPermission] = useState(false)
  const [selectedStudentFormId, setSelectedStudentFormId] = useState('')
  const [saveSwitch, setSaveSwitch] = useState(false)
  // Set Student Info for IEP Provider
  useEffect(() => {
    if (data && !loading && saveSwitch == false) {
      setStudentFormInfo({
        studentFormId: data?.studentMeetingGeneral.studentFormId,
        studentId: data?.studentMeetingGeneral.studentId,
        schoolId: data?.studentMeetingGeneral.schoolId,
        districtId: data?.studentMeetingGeneral?.district?.id
      })
      setStudentMeetingId(studentMeetingId)

      // if has no forms and no uploaded docs display the sidebarEdit instead
      if (
        !(data?.studentMeetingGeneral?.meetingForms.length > 0) &&
        !(data?.studentMeetingGeneral?.uploadedDocs.length > 0)
      ) {
        setEditMode(true)
      }

      if (data?.studentMeetingGeneral.meetingDate) {
        setMeetingDate(data?.studentMeetingGeneral.meetingDate)
      }
      //navigate to current goal from student profile
      if (currentGoalInfo != undefined) {
        const allForms = data?.studentMeetingGeneral.meetingForms.map(
          e => e.forms
        )
        const flatAllForms = allForms?.flat(1)
        let match = {}

        flatAllForms?.map(e => {
          if (e.studentFormId === currentGoalInfo.studentFormId) {
            match = e
          }
        })
        setCurrentEditableForm({
          studentFormId: match?.studentFormId,
          versionId: match?.currentVersionId,
          formCode: match?.formCode,
          formId: match?.formId,
          meetingType: data?.studentMeetingGeneral?.meetingType
        })
        setKeyDates(match?.keyDates)
        setFormWarning(match?.formWarning)
        setViewPermission(match?.userSubRoleFormPermission?.view)
        setPrintPermission(match?.userSubRoleFormPermission?.print)
        setAddPermission(match?.userSubRoleFormPermission?.add)
        handleListItemClick(match?.studentFormId)
        if (!match?.userSubRoleFormPermission?.view) {
          notificationInfo(
            'Not allowed!',
            "Your sub-role doesn't have permission to view this form",
            'bottomLeft'
          )
          return
        }
      }
      //navigate to prior goal from student profile
      if (priorGoalInfo != undefined) {
        const allForms = data?.studentMeetingGeneral.meetingForms.map(
          e => e.forms
        )
        const flatAllForms = allForms?.flat(1)
        let match = {}
        flatAllForms?.map(e => {
          if (e.studentFormId === priorGoalInfo.studentFormId) {
            match = e
          }
        })
        setCurrentEditableForm({
          studentFormId: match?.studentFormId,
          versionId: match?.currentVersionId,
          formCode: match?.formCode,
          formId: match?.formId,
          meetingType: data?.studentMeetingGeneral?.meetingType
        })
        setKeyDates(match?.keyDates)
        setFormWarning(match?.formWarning)
        setViewPermission(match?.userSubRoleFormPermission?.view)
        setPrintPermission(match?.userSubRoleFormPermission?.print)
        setAddPermission(match?.userSubRoleFormPermission?.add)
        handleListItemClick(match?.studentFormId)
        if (!match?.userSubRoleFormPermission?.view) {
          notificationInfo(
            'Not allowed!',
            "Your sub-role doesn't have permission to view this form",
            'bottomLeft'
          )
          return
        }
      }
      setSaveSwitch(true)
    }
  }, [data])

  const formatFormName = name => {
    if (name) {
      return (
        name.toString().toLowerCase().charAt(0).toUpperCase() +
        name.toString().toLowerCase().slice(1)
      )
    }
  }

  const handleListItemClick = studentFormId => {
    setSelectedStudentFormId(studentFormId)
  }
  const handleSISManage = () => {
    setKeyDates('')
    setFormWarning('')
    setCurrentEditableForm({ formCode: 'MEETING_SIS', formId: '123' })
  }
  return (
    <>
      {/* <Card style={{ padding: '0px !important', margin: '0px !important' }}> */}
      {editMeeting ? (
        <></>
      ) : (
        <>
          {/* no longer using this button, keeping it just in case. relocated to edit meeting info area */}
          {/* <div
              style={{
                marginTop: '20px',
                marginBottom: '40px'
              }}
            >
              <Button onClick={handleSISManage}>Edit Key Dates and Placement Info</Button> */}
          {/* saving link in case we want to use this */}
          {/* <Link
                to={`../student/edit/${data?.studentMeetingGeneral.studentId}`}
              >
                <Button>Manage Accompanying Information</Button>
              </Link> */}
          {/* </div> */}
          {/* <Row>
            <Col span={24}>
              <h3>Forms list:</h3>
            </Col>
          </Row> */}
          <Row>
            {data && (
              <>

                {data?.studentMeetingGeneral.meetingType === '504 Plan' ? (
                  <>
                    {' '}
                    {data?.studentMeetingGeneral.meetingForms.map(formType => (
                      <div
                        style={{ marginTop: '24px' }}
                        key={formType.formTypeId}
                      >
                        {formType.display === 'IEP Forms' ? (
                          <>
                            <h4>Conference Forms:</h4>
                          </>
                        ) : (
                          <>
                            <h4>{formType.display}:</h4>
                          </>
                        )}
                        <List
                          itemLayout='vertical'
                          dataSource={formType.forms}
                          locale={{ emptyText: ' ' }}
                          className='meetings-list'
                          renderItem={item => (
                            <List.Item
                              style={{
                                borderStyle: 'none',
                                // marginLeft: '10px',
                                cursor: 'pointer'
                                // padding: '4px 0'
                              }}
                              className={
                                !item.userSubRoleFormPermission.view
                                  ? 'disabled'
                                  : ''
                              }
                            >
                              {item.formStatusIsDraft === true ? (
                                <>Draft &nbsp;</>
                              ) : (
                                <>Final &nbsp;</>
                              )}
                              <Space>
                                <span
                                  onClick={() => {
                                    if (!item.userSubRoleFormPermission.view) {
                                      notificationInfo(
                                        'Not allowed!',
                                        "Your sub-role doesn't have permission to view this form",
                                        'bottomLeft'
                                      )
                                      return
                                    }
                                    setCurrentEditableForm({
                                      studentFormId: item.studentFormId,
                                      versionId: item.currentVersionId,
                                      formCode: item.formCode,
                                      formId: item.formId,
                                      formStatusId: item.formStatusId,
                                      isFinalized: item.isFinalized,
                                      formStatusEnumName: item.formStatusEnumName,
                                      meetingType:
                                        data?.studentMeetingGeneral?.meetingType
                                    })

                                    setKeyDates(item.keyDates)
                                    setFormWarning(item.formWarning)
                                    setViewPermission(
                                      item.userSubRoleFormPermission.view
                                    )
                                    setPrintPermission(
                                      item.userSubRoleFormPermission.print
                                    )
                                    setAddPermission(
                                      item.userSubRoleFormPermission.add
                                    )
                                    handleListItemClick(item.studentFormId)
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontWeight:
                                        item.studentFormId ===
                                          selectedStudentFormId
                                          ? 'bold'
                                          : '',
                                      fontSize:
                                        item.studentFormId ===
                                          selectedStudentFormId
                                          ? '16px'
                                          : '14px'
                                      // 'color': item.studentFormId === selectedStudentFormId ? 'rgba(22, 160, 133, 1)' : 'black'
                                    }}
                                  >
                                    {formatFormName(item.name) ===
                                      'Individualized education program (conference summary report)' ? (
                                      <>Conference Summary Report (504)</>
                                    ) : (
                                      <>{formatFormName(item.name)}</>
                                    )}
                                  </Typography>
                                  {/* {item.formStatusIsDraft === true ? (
                                    <>Draft</>
                                  ) : (
                                    <>Final</>
                                  )} */}

                                  {!item.userSubRoleFormPermission.view && (
                                    <img
                                      style={{
                                        marginLeft: '5px'
                                      }}
                                      src={notAvailableIcon}
                                      width={15}
                                      height={15}
                                    />
                                  )}
                                </span>
                              </Space>
                            </List.Item>
                          )}
                        />
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    {' '}
                    {data?.studentMeetingGeneral.meetingForms.map(formType => (
                      <div
                        style={{ marginTop: '24px' }}
                        key={formType.formTypeId}
                      >
                        <h4>{formType.display}:</h4>
                        <List
                          itemLayout='vertical'
                          size='small'
                          dataSource={formType.forms}
                          locale={{ emptyText: ' ' }}
                          className='meetings-list'
                          renderItem={item => (
                            <List.Item
                              style={{
                                borderStyle: 'none',
                                // marginLeft: '100px',
                                cursor: 'pointer'
                                // padding: '4px 0'
                              }}
                              className={
                                !item.userSubRoleFormPermission.view
                                  ? 'disabled'
                                  : ''
                              }
                            >
                              <Space>
                                <span>
                                  {item.formStatusIsDraft === true ? (
                                    <>Draft &nbsp;</>
                                  ) : (
                                    <>Final &nbsp;</>
                                  )}
                                </span>
                                <span
                                  onClick={() => {
                                    if (!item.userSubRoleFormPermission.view) {
                                      notificationInfo(
                                        'Not allowed!',
                                        "Your sub-role doesn't have permission to view this form",
                                        'bottomLeft'
                                      )
                                      return
                                    }
                                    setCurrentEditableForm({
                                      studentFormId: item.studentFormId,
                                      versionId: item.currentVersionId,
                                      formCode: item.formCode,
                                      formId: item.formId,
                                      formStatusId: item.formStatusId,
                                      formStatusEnumName: item.formStatusEnumName,
                                      isFinalized: item.isFinalized,
                                      meetingType:
                                        data?.studentMeetingGeneral?.meetingType
                                    })

                                    setKeyDates(item.keyDates)
                                    setFormWarning(item.formWarning)
                                    setViewPermission(
                                      item.userSubRoleFormPermission.view
                                    )
                                    setPrintPermission(
                                      item.userSubRoleFormPermission.print
                                    )
                                    setAddPermission(
                                      item.userSubRoleFormPermission.add
                                    )
                                    handleListItemClick(item.studentFormId)
                                  }}
                                >
                                  <Typography
                                    // style={{
                                    //   fontWeight:
                                    //     item.studentFormId ===
                                    //     selectedStudentFormId
                                    //       ? 'bold'
                                    //       : '',
                                    //   fontSize:
                                    //     item.studentFormId ===
                                    //     selectedStudentFormId
                                    //       ? '16px'
                                    //       : '14px'
                                    //   // 'color': item.studentFormId === selectedStudentFormId ? 'rgba(22, 160, 133, 1)' : 'black'
                                    // }}
                                    style={
                                      item.studentFormId ===
                                        selectedStudentFormId
                                        ? {
                                          fontWeight: 'bold',
                                          fontSize: '16px'

                                          // 'color': item.studentFormId === selectedStudentFormId ? 'rgba(22, 160, 133, 1)' : 'black'
                                        }
                                        : {
                                          fontWeight: '',
                                          fontSize: '14px'
                                        }
                                    }
                                  >
                                    {formatFormName(item.name)}
                                    {/* '(' +
                                          item.formCode +
                                          ')'} */}
                                  </Typography>

                                  {!item.userSubRoleFormPermission.view && (
                                    <img
                                      style={{
                                        marginLeft: '5px'
                                      }}
                                      src={notAvailableIcon}
                                      width={15}
                                      height={15}
                                    />
                                  )}
                                </span>
                              </Space>
                            </List.Item>
                          )}
                        />
                      </div>
                    ))}
                  </>
                )}

                {data?.studentMeetingGeneral.uploadedDocs.length > 0 && (
                  <>
                    <h4>Imported Documents:</h4>
                    <List
                      itemLayout='vertical'
                      dataSource={data?.studentMeetingGeneral.uploadedDocs}
                      locale={{ emptyText: ' ' }}
                      className='meetings-list'
                      renderItem={item => (
                        <List.Item
                          style={{
                            borderStyle: 'none',
                            // marginLeft: '10px',
                            cursor: 'pointer'
                            // padding: '4px 0'
                          }}
                        >
                          <Space>
                            <span
                              onClick={() => {
                                setCurrentEditableForm({
                                  studentFormId: item.id,
                                  versionId: item.id,
                                  formCode: 'imported',
                                  formId: item.id,
                                  pdfData: item,
                                  meetingType:
                                    data?.studentMeetingGeneral?.meetingType
                                })
                                handleListItemClick(item.id)
                              }}
                            >
                              {item.name}
                            </span>
                          </Space>
                        </List.Item>
                      )}
                    />
                  </>
                )}
              </>
            )}
          </Row>
        </>
      )}
      {/* </Card> */}
    </>
  )
}

StudentFormSidebar.propTypes = {
  setEditMode: PropTypes.func
}

export default StudentFormSidebar
