import { gql } from "@apollo/client";

export const QUERY_FORM3457BC_VERSION = gql`
  query studentForm3457BC(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
  ) {
    studentForm3457BC(
      studentFormId: $studentFormId
      studentId: $studentId
      formId: $formId
      schoolId: $schoolId
      studentMeetingId: $studentMeetingId
    ) {
      versionId
      formId
      formDate
      studentFullName

      academicRelevance
      academicExisting
      academicAdditional
      academicSources

      functionalRelevance
      functionalExisting
      functionalAdditional
      functionalSources

      cognitiveRelevance
      cognitiveExisting
      cognitiveAdditional
      cognitiveSources

      communicationRelevance
      communicationExisting
      communicationAdditional
      communicationSources

      performanceRelevance
      performanceExisting
      performanceAdditional
      performanceSources

      hearingRelevance
      hearingExisting
      hearingAdditional
      hearingSources

      motorRelevance
      motorExisting
      motorAdditional
      motorSources

      socialRelevance
      socialExisting
      socialAdditional
      socialSources

      formAction
      formStatus
    }
  }
`;
