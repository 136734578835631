import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Form, Button, Select } from "antd";
import { QUERY_LOOKUP_TYPES } from "../../../operations/queries/lookup";
import { useQuery } from "@apollo/client";
import AuthContext from "../../../utils/authProvider";

const { Option } = Select;

const LookupSearchForm = ({ onSearch, defaultType }) => {
  const [form] = Form.useForm();
  const Auth = useContext(AuthContext);
  const [types, setTypes] = useState([]);
  const { loading, data } = useQuery(QUERY_LOOKUP_TYPES);

  useEffect(() => {
    if (data) {
      setTypes(data.lookupTypes);
    }
  }, [data]);

  const onFinish = (values) => {
    onSearch({ ...values, districtId: Auth.currentDistrictId?.id });
  };

  const onFinishFailed = (errorInfo) => { };

  return (
    <div>
      <Form
        form={form}
        layout="horizontal"
        id="gradeSearchForm"
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={32}>
          <Col xl={6} lg={8} md={9} sm={12} xs={24}>
            <Form.Item name="type" required>
              <Select defaultValue="Select Lookup Type" loading={loading}>
                {types.map((type) => (
                  <Option key={type.id} value={type.name}>
                    {type.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col>
            <Button type="primary" htmlType="submit">
              Filter
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

LookupSearchForm.propTypes = {
  onSearch: PropTypes.func.isRequired,
  defaultType: PropTypes.string.isRequired,
};

export default LookupSearchForm;
