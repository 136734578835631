import { gql } from "@apollo/client";

export const QUERY_FORMS = gql`
  query baseForms($searchCriteria: String, $hidden: Boolean) {
    baseForms(searchCriteria: $searchCriteria, hidden: $hidden) {
      id
      formCode
      name
      formTypeId
      formType {
        id
        enumName
        display
        order
      }
      keyDates
      allowMultipleInstances
    }
  }

`;

export const QUERY_FORM = gql`
  query baseForm($id: ID!) {
    baseForm(id: $id) {
      id
      formCode
      name
      formTypeId
      formType {
        id
        enumName
        display
        order
      }
      keyDates
      allowMultipleInstances
      hidden
      createdBy
      createdAtAgo
      updatedAtAgo
      createdAt(format: "ddd mmm dd yyyy h:MM:ss TT Z")
      updatedAt(format: "ddd mmm dd yyyy h:MM:ss TT Z")
    }
  }
`;
