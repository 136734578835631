// react
import React, { useEffect, useContext } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

// ant design
import { Modal } from "antd";

// contexts
import ApolloContext from "../../utils/apolloProvider";

// components
import Page from "../../components/Page";

// apollo & operations
import { QUERY_DISTRICT } from "../../operations/queries/districts";
import { UPDATE_DISTRICT } from "../../operations/mutations/districts";

// query params
const useQuery = () => new URLSearchParams(useLocation().search);

const DistrictDelete = (props) => {
  const apollo = useContext(ApolloContext);
  const history = useNavigate();
  const { id } = useParams();
  const query = useQuery();
  const breadcrumb = [
    {
      path: "/",
      breadcrumbName: "Home",
    },
    {
      path: "/districts",
      breadcrumbName: "Districts",
    },
    {
      path: "",
      breadcrumbName: "Delete district",
    },
  ];

  const deleteDistrict = async () => {
    try {
      const response = await apollo.client.query({
        query: QUERY_DISTRICT,
        variables: { id },
      });
      const district = { ...response.data.district };
      // @todo should only update hidden
      await apollo.client.mutate({
        mutation: UPDATE_DISTRICT,
        variables: {
          id: id,
          districtInput: { name: district.name, hidden: true },
        },
      });
      Modal.success({
        title: "Success",
        content: "The district has been hidden.",
        okText: "Return to listing",
        onOk: () => history.replace("/districts"),
      });
    } catch (e) {
      Modal.error({
        title: "An error has occurred",
        content: "The district has not been hidden.",
        okText: "Return to listing",
        onOk: () => history.replace("/districts"),
      });
    }
  };

  useEffect(() => {
    if (query.get("confirm") === "1") {
      deleteDistrict();
    }
  }, []);

  return (
    <>
      <Page title="Delete district" breadcrumb={breadcrumb} />
    </>
  );
};

export default DistrictDelete;
