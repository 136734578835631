import { gql } from "@apollo/client";

export const QUERY_FORM3454K_VERSION = gql`
  query studentForm3454K(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
  ) {
    studentForm3454K(
      studentFormId: $studentFormId
      studentId: $studentId
      formId: $formId
      schoolId: $schoolId
      studentMeetingId: $studentMeetingId
    ) {
      versionId
      formId
      formDate
      studentFullName
      targetBehaviour
      behaviourType
      studentStrengths
      behavioralFunction
      previousInterventionsAttempted
      replacementBehaviour
      formAction
      formStatus
    }
  }
`;
