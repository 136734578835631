/* eslint no-unused-vars: ["error", { "varsIgnorePattern": "moment" }] */

// React
import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'

// Providers & Contexts
import AuthContext from './authProvider'
import { ApolloContext } from './apolloProvider'
import { ConfigProvider } from 'antd'

// antd locale
import enUS from 'antd/lib/locale-provider/en_US'

// other libraries
// import { ApplicationError } from './applicationError'
import { notificationWarn } from './notification'

// graphql operations
import { GET_VERSION } from '../operations/queries/appInfo'

// MomentJs: define its locales (moment is used by ant design)
import moment from 'moment'
// add more moment locales here:
// import "moment/locale/fr"

const AppContext = React.createContext()

/**
 * AppProvider
 */
function AppProvider(props) {
  const Auth = useContext(AuthContext)
  const authCurrentDistrictId = Auth.currentDistrictId
  const authCurrentSchoolId = Auth.currentSchoolId
  const { districtsAdmin, schoolsAdmin, schoolsGeneral } = Auth
  const isSuperAdmin = Auth.hasRole('SUPER_ADMIN')
  const isDistrictsAdmin = Auth.hasRole('DISTRICTS_ADMIN')
  const isSchoolsAdmin = Auth.hasRole('SCHOOLS_ADMIN')
  const isSchoolsGeneral = Auth.hasRole('SCHOOLS_GENERAL')

  // retrieve apollo client instance
  const apollo = useContext(ApolloContext)
  // current api version
  const [apiVersion, setApiVersion] = useState('')

  // role of the user in the current environment
  const [role, setRole] = useState()

  // school id or district id of the current environment
  const [currentDistrictId, setCurrentDistrictId] = useState()
  const [currentSchoolId, setCurrentSchoolId] = useState()

  // retrieve the version of the api once
  const fetchApiVersion = async () => {
    // try {
    const response = await apollo.client.query({ query: GET_VERSION })
    setApiVersion(response.data?.version)
    // }
    // who cares???
    // catch (error) {
    //   throw new ApplicationError('Couldn\'t retrieve api version number', error)
    // }
  }
  const openSuperAdmin = () => {
    setRole('SUPER_ADMIN')
    setCurrentDistrictId()
    setCurrentSchoolId()
  }

  const openDistrictAdmin = id => {
    setRole('DISTRICTS_ADMIN')
    setCurrentDistrictId(id)
    setCurrentSchoolId()
    Auth.updateCurrentDistrictId(id)
  }

  const openSchoolAdmin = id => {
    setRole('SCHOOLS_ADMIN')
    setCurrentSchoolId(id)
    setCurrentDistrictId()
    Auth.updateCurrentSchoolId(id)
  }

  const openSchoolGeneral = id => {
    setRole('SCHOOLS_GENERAL')
    setCurrentSchoolId(id)
    setCurrentDistrictId()
    Auth.updateCurrentSchoolId(id)
  }

  // returns the currently loaded environment (role, district id/name or school name/id)
  const useCurrent = () => {
    const [name, setName] = useState()
    const [districtId, setDistrictId] = useState()
    const [schoolId, setSchoolId] = useState()

    useEffect(() => {
      setDistrictId(currentDistrictId)
      setSchoolId(currentSchoolId)
      if (role === 'DISTRICTS_ADMIN') {
        const district = districtsAdmin.find(d => d.id === currentDistrictId)
        setName(district?.name)
      } else if (role === 'SCHOOLS_ADMIN') {
        const school = schoolsAdmin.find(d => d.id === currentSchoolId)
        setName(school?.name)
      } else if (role === 'SCHOOLS_GENERAL') {
        const school = schoolsGeneral.find(d => d.id === currentSchoolId)
        setName(school?.name)
      }
    }, [currentDistrictId, currentSchoolId])
    return { name, role, districtId, schoolId }
  }

  const closeCurrent = () => {
    setRole()
    setCurrentDistrictId()
    setCurrentSchoolId()
  }

  // runs when the user signedIn
  useEffect(() => {
    // console.log('authCurrentDistrictId', authCurrentDistrictId)
    if (Auth.signedIn) {
      if (isSuperAdmin) {
        openSuperAdmin()
      } else if (authCurrentDistrictId && isDistrictsAdmin) {
        openDistrictAdmin(authCurrentDistrictId)
      } else if (
        authCurrentSchoolId &&
        isSchoolsAdmin &&
        schoolsAdmin.find(d => d.id === authCurrentSchoolId)
      ) {
        // open as school admin if the current school id is found in schoolsAdmin
        openSchoolAdmin(authCurrentSchoolId)
      } else if (
        authCurrentSchoolId &&
        isSchoolsGeneral &&
        schoolsGeneral.find(d => d.id === authCurrentSchoolId)
      ) {
        // open as school general if the current school id is found in schoolsGeneral
        openSchoolAdmin(authCurrentSchoolId)
      } else if (
        !isSuperAdmin &&
        districtsAdmin.length === 0 &&
        schoolsAdmin.length === 0 &&
        schoolsGeneral.length === 0
      ) {
        // the user doesn't have any permissions
        Auth.signOut()
        notificationWarn(
          'No permissions found',
          "You account doesn't have any permissions. Please contact your administrator."
        )
      } else {
        setRole('ROLE_SELECTOR') // opens the selector only if we received the permissions
      }
    }

    // when user sign out close the current
    if (!Auth.signedIn) closeCurrent()
  }, [Auth.signedIn])

  // returns UI version
  const getUIVersion = () => process.env.REACT_APP_VERSION

  // returns API version
  const getAPIVersion = () => apiVersion

  // runs once
  useEffect(() => {
    fetchApiVersion()
  }, [])

  return (
    <AppContext.Provider
      value={{
        getUIVersion,
        getAPIVersion,
        openSuperAdmin,
        openDistrictAdmin,
        openSchoolAdmin,
        openSchoolGeneral,
        useCurrent,
        currentDistrictId,
        currentSchoolId
      }}
    >
      <ConfigProvider locale={enUS}>{props.children}</ConfigProvider>
    </AppContext.Provider>
  )
}
const stupidThemeHack = window.location.href

AppProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export default AppContext

export { AppProvider, AppContext, stupidThemeHack }
