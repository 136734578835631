import React, { useState, useEffect } from 'react'

import PdfPage from '../../components/PdfPage'

import readXlsxFile from 'read-excel-file'
import { InboxOutlined, PropertySafetyTwoTone } from '@ant-design/icons'
import { Upload, Button, Table, Col, Row, Image } from 'antd'
import { Document, Page, Text, View, StyleSheet, pdf } from '@react-pdf/renderer'
import CngLogo from './cngLogo.png'
import ReactPdfTable from "react-pdf-table";
import './CngReports.css'
// import { Table, TR, TH, TD } from '@ag-media/react-pdf-table';

import { saveAs } from 'file-saver'
import Papa from "papaparse";

const CngReports = () => {
  const breadcrumb = [
    {
      path: '/',
      breadcrumbName: 'Home'
    },
    {
      path: '',
      breadcrumbName: 'CNG Report'
    }
  ]
  const { Dragger } = Upload

  const [dataCsv, setDataCsv] = useState([])
  const [dataXlsx, setDataXlxs] = useState([])
  const [dataExtra, setDataExtra] = useState()
  const [dataExtraFreshman, setDataExtraFreshman] = useState()
  const [dataExtraJunior, setDataExtraJunior] = useState()
  const [dataExtraSophomore, setDataExtraSophomore] = useState()
  const [dataExtraSenior, setDataExtraSenior] = useState()
  const freshman = []
  const sophomore = []
  const junior = []
  const senior = []

  useEffect(() => {

  }, [dataCsv, dataExtra, dataExtraFreshman, dataExtraJunior, dataExtraSophomore, dataExtraSenior])
  const columns = [
    {
      title: 'Courses',
      dataIndex: 'courseDescription',
      render: (value, record, records) => (
        <>
          <span >{value}</span>&nbsp;
        </>
      )
    },
    {
      title: '1st Semester Grade',
      dataIndex: 'sem1Grade',
      render: (value, record, records) => (
        <>
          <span >{value}</span>&nbsp;
        </>
      )
    },
    {
      title: '1st Semester Credits',
      dataIndex: 'sem1Credits',
      render: (value, record, records) => (
        <>
          <span >{value}</span>&nbsp;
        </>
      )
    },
    {
      title: '2nd Semester Grade',
      dataIndex: 'sem2Grade',
      render: (value, record, records) => (
        <>
          <span >{value}</span>&nbsp;
        </>
      )
    },
    {
      title: '2nd Semester Credits',
      dataIndex: 'sem2Credits',
      render: (value, record, records) => (
        <>
          <span >{value}</span>&nbsp;
        </>
      )
    },
  ]
  dataCsv.map(array => {
    array.map(row => {
      if (12 - ((row[4]) - (row[6].slice(-4))) == 9) {
        freshman.push(row)
      }
      if (12 - ((row[4]) - (row[6].slice(-4))) == 10) {
        sophomore.push(row)
      }
      if (12 - ((row[4]) - (row[6].slice(-4))) == 11) {
        junior.push(row)
      }
      if (12 - ((row[4]) - (row[6].slice(-4))) == 12) {
        senior.push(row)
      }
      row.map(item => {
        // 12 - ((Grad year) - (School Year))
        // do stuff
      })
    })
  })

  // neat but useless
  // let obj = {}
  // freshman.map(item => {
  //   if (item[8] in obj) {
  //     obj[item[8]]++
  //   }
  //   else {
  //     obj[item[8]] = 1
  //   }
  //   console.log(Object.entries(obj), 'potato')
  // })
  const keys = ['studentCode', 'name', 'currentlyActive', 'currentGrade',
    'graduationYear', 'courseAndSection', 'schoolYear', 'courseDescription', 'departmentCode',
    'departmentDescription', 'teacherName', 'enrollementStatus', 'empty', 'empty', 'empty',
    'sem1Grade', 'sem2Grade', 'finalGrade', 'sectionCode', 'active', 'credits', 'dropped']

  const shapeData = (data) => {
    let arr = []
    data.map(row => {
      const merged = keys.reduce((obj, key, index) => ({ ...obj, [key]: row[index] }), {});
      arr.push(merged)
    })
    arr.forEach(item => delete item.active && delete item.courseAndSection && item.currentlyActive
      && delete item.departmentCode && delete item.departmentDescription && delete item.dropped
      && delete item.empty && delete item.enrollementStatus && delete item.graduationYear
      && delete item.name && delete item.sectionCode && delete item.studentCode && item.finalGrade
      && delete item.teacherName && delete item.currentlyActive && item.currentGrade);
    arr.map(item => {
      if (item.finalGrade !== '') {
        if (item.sem1Grade !== '') {
          item.sem1Grade = item.finalGrade
          if (item.credits !== '') {
            item.sem1Credits = item.credits
          }
        }
        if (item.sem2Grade !== '') {
          item.sem2Grade = item.finalGrade
          if (item.credits !== '' && item.credits !== undefined) {
            item.sem2Credits = item.credits
          }
        }
      }
    })
    const arr2 = arr
    const arr3 = []
    let item = {}
    if (arr.length > 1) {
      arr.map(item1 => {
        arr2.map(item2 => {
          if (item1.courseDescription.toLowerCase() === item2.courseDescription.toLowerCase() &&
            arr3.some(item => item.courseDescription.toLowerCase() === item1.courseDescription.toLowerCase())
          ) {
            arr3.map(item => {
              if (item.courseDescription.toLowerCase() == item1.courseDescription.toLowerCase() && item.courseDescription.toLowerCase() == item2.courseDescription.toLowerCase()) {

                if (item.sem1Credits == undefined) {
                  item.sem1Credits = item1.sem1Credits !== '' ? item1.sem1Credits : item2.sem1Credits
                }
                if (item.sem2Credits == undefined) {
                  item.sem2Credits = item1.sem2Credits !== '' ? item1.sem2Credits : item2.sem2Credits
                }
                if (item.sem1Grade == '') {
                  item.sem1Grade = item1.sem1Grade !== '' ? item1.sem1Grade : item2.sem1Grade
                }
                if (item.sem2Grade == '') {
                  item.sem2Grade = item1.sem2Grade !== '' ? item1.sem2Grade : item2.sem2Grade
                }
              }

            })
          }
          else if (item1.courseDescription.toLowerCase() === item2.courseDescription.toLowerCase()) {
            item = {
              courseDescription: item1.courseDescription,
              schoolYear: item1.schoolYear,
              sem1Credits: item1.sem1Credits !== '' ? item1.sem1Credits : item2.sem1Credits,
              sem2Credits: item1.sem2Credits !== '' ? item1.sem2Credits : item2.sem1Credits,
              sem1Grade: item1.sem1Grade !== '' ? item1.sem1Grade : item2.sem1Grade,
              sem2Grade: item1.sem2Grade !== '' ? item1.sem2Grade : item2.sem2Grade,
            }
            arr3.push(item)
            item = {}
          }
        })
      })
    }

    return (arr3)
  }
  let allSem1Creds2 = 0
  let allSem2Creds2 = 0
  let totalCreds2 = 0
  let gpa2 = 0
  let runningNumber2 = 0

  const grabCumulativeGpa = (data) => {

    let arr = []
    const arr2 = arr
    const arr3 = []
    let item = {}

    data.map(row => {
      const merged = keys.reduce((obj, key, index) => ({ ...obj, [key]: row[index] }), {});
      arr.push(merged)
    })
    arr.forEach(item => delete item.active && delete item.courseAndSection && item.currentlyActive
      && delete item.departmentCode && delete item.departmentDescription && delete item.dropped
      && delete item.empty && delete item.enrollementStatus && delete item.graduationYear
      && delete item.name && delete item.sectionCode && delete item.studentCode && item.finalGrade
      && delete item.teacherName && delete item.currentlyActive && item.currentGrade);
    arr.map(item => {
      if (item.finalGrade !== '') {
        if (item.sem1Grade !== '') {
          item.sem1Grade = item.finalGrade
          if (item.credits !== '') {
            item.sem1Credits = item.credits
          }
        }
        if (item.sem2Grade !== '') {
          item.sem2Grade = item.finalGrade
          if (item.credits !== '' && item.credits !== undefined) {
            item.sem2Credits = item.credits
          }
        }
      }
    })

    if (arr.length > 1) {
      arr.map(item1 => {
        arr2.map(item2 => {
          if (item1.courseDescription.toLowerCase() === item2.courseDescription.toLowerCase() &&
            arr3.some(item => item.courseDescription.toLowerCase() === item1.courseDescription.toLowerCase())
          ) {
            arr3.map(item => {
              if (item.courseDescription.toLowerCase() == item1.courseDescription.toLowerCase() && item.courseDescription.toLowerCase() == item2.courseDescription.toLowerCase()) {

                if (item.sem1Credits == undefined) {
                  item.sem1Credits = item1.sem1Credits !== '' ? item1.sem1Credits : item2.sem1Credits
                }
                if (item.sem2Credits == undefined) {
                  item.sem2Credits = item1.sem2Credits !== '' ? item1.sem2Credits : item2.sem2Credits
                }
                if (item.sem1Grade == '') {
                  item.sem1Grade = item1.sem1Grade !== '' ? item1.sem1Grade : item2.sem1Grade
                }
                if (item.sem2Grade == '') {
                  item.sem2Grade = item1.sem2Grade !== '' ? item1.sem2Grade : item2.sem2Grade
                }
              }

            })
          }
          else if (item1.courseDescription.toLowerCase() === item2.courseDescription.toLowerCase()) {
            item = {
              courseDescription: item1.courseDescription,
              schoolYear: item1.schoolYear,
              sem1Credits: item1.sem1Credits !== '' ? item1.sem1Credits : item2.sem1Credits,
              sem2Credits: item1.sem2Credits !== '' ? item1.sem2Credits : item2.sem1Credits,
              sem1Grade: item1.sem1Grade !== '' ? item1.sem1Grade : item2.sem1Grade,
              sem2Grade: item1.sem2Grade !== '' ? item1.sem2Grade : item2.sem2Grade,
            }
            arr3.push(item)
            item = {}
          }
        })
      })


    }
    arr3.map(x => {
      if (x.sem1Credits !== undefined && x.sem1Grade !== '' && x.sem1Grade !== undefined
        && !isNaN(x.sem1Grade)

      ) {
        runningNumber2 += parseFloat(x.sem1Grade) * parseFloat(x.sem1Credits)

        allSem1Creds2 += 0.5
      }
      if (x.sem2Credits !== undefined && x.sem2Grade !== '' && x.sem2Grade !== undefined && x.sem2Gradereg !== isNaN(x.sem1Grade)) {
        runningNumber2 += parseFloat(x.sem2Grade) * parseFloat(x.sem2Credits)
        allSem2Creds2 += 0.5
      }
    })
    totalCreds2 = allSem1Creds2 + allSem2Creds2
    gpa2 = runningNumber2 / totalCreds2
    return (<>{gpa2.toFixed(2)}</>)

  }
  const grabGpa = (data) => {
    let allSem1Creds = 0
    let allSem2Creds = 0
    let totalCreds = 0
    let gpa = 0
    let runningNumber = 0
    let arr = []
    const arr2 = arr
    const arr3 = []
    let item = {}

    data.map(row => {
      const merged = keys.reduce((obj, key, index) => ({ ...obj, [key]: row[index] }), {});
      arr.push(merged)
    })
    arr.forEach(item => delete item.active && delete item.courseAndSection && item.currentlyActive
      && delete item.departmentCode && delete item.departmentDescription && delete item.dropped
      && delete item.empty && delete item.enrollementStatus && delete item.graduationYear
      && delete item.name && delete item.sectionCode && delete item.studentCode && item.finalGrade
      && delete item.teacherName && delete item.currentlyActive && item.currentGrade);
    arr.map(item => {
      if (item.finalGrade !== '') {
        if (item.sem1Grade !== '') {
          item.sem1Grade = item.finalGrade
          if (item.credits !== '') {
            item.sem1Credits = item.credits
          }
        }
        if (item.sem2Grade !== '') {
          item.sem2Grade = item.finalGrade
          if (item.credits !== '' && item.credits !== undefined) {
            item.sem2Credits = item.credits
          }
        }
      }
    })

    if (arr.length > 1) {
      arr.map(item1 => {
        arr2.map(item2 => {
          if (item1.courseDescription.toLowerCase() === item2.courseDescription.toLowerCase() &&
            arr3.some(item => item.courseDescription.toLowerCase() === item1.courseDescription.toLowerCase())
          ) {
            arr3.map(item => {
              if (item.courseDescription.toLowerCase() == item1.courseDescription.toLowerCase() && item.courseDescription.toLowerCase() == item2.courseDescription.toLowerCase()) {

                if (item.sem1Credits == undefined) {
                  item.sem1Credits = item1.sem1Credits !== '' ? item1.sem1Credits : item2.sem1Credits
                }
                if (item.sem2Credits == undefined) {
                  item.sem2Credits = item1.sem2Credits !== '' ? item1.sem2Credits : item2.sem2Credits
                }
                if (item.sem1Grade == '') {
                  item.sem1Grade = item1.sem1Grade !== '' ? item1.sem1Grade : item2.sem1Grade
                }
                if (item.sem2Grade == '') {
                  item.sem2Grade = item1.sem2Grade !== '' ? item1.sem2Grade : item2.sem2Grade
                }
              }

            })
          }
          else if (item1.courseDescription.toLowerCase() === item2.courseDescription.toLowerCase()) {
            item = {
              courseDescription: item1.courseDescription,
              schoolYear: item1.schoolYear,
              sem1Credits: item1.sem1Credits !== '' ? item1.sem1Credits : item2.sem1Credits,
              sem2Credits: item1.sem2Credits !== '' ? item1.sem2Credits : item2.sem1Credits,
              sem1Grade: item1.sem1Grade !== '' ? item1.sem1Grade : item2.sem1Grade,
              sem2Grade: item1.sem2Grade !== '' ? item1.sem2Grade : item2.sem2Grade,
            }
            arr3.push(item)
            item = {}
          }
        })
      })


    }
    arr3.map(x => {
      if (x.sem1Credits !== undefined && x.sem1Grade !== '' && x.sem1Grade !== undefined
        // && x.sem1Grade == /^\d+$/
        && !isNaN(x.sem1Grade)

      ) {
        runningNumber += parseFloat(x.sem1Grade) * parseFloat(x.sem1Credits)

        allSem1Creds += 0.5
      }
      if (x.sem2Credits !== undefined && x.sem2Grade !== '' && x.sem2Grade !== undefined && x.sem2Gradereg !== isNaN(x.sem1Grade)) {
        runningNumber += parseFloat(x.sem2Grade) * parseFloat(x.sem2Credits)
        allSem2Creds += 0.5
      }
    })
    totalCreds = allSem1Creds + allSem2Creds
    gpa = runningNumber / totalCreds
    return (<>GPA: {gpa.toFixed(2)}</>)

  }



  const props = {
    name: 'file',
    multiple: true,
    accept: '.xls,.xlsx,.csv',
    onChange(info) {
      info.fileList.map(file => {
        if (file.originFileObj.name.includes('.xlsx')) {
          readXlsxFile(file.originFileObj).then(rows => {
            // `rows` is an array of rows
            // each row being an array of cells.
            dataXlsx.push(...rows)
            let set = new Set(dataXlsx.map(JSON.stringify))
            let arr2 = Array.from(set).map(JSON.parse)
            setDataXlxs([arr2])
          })
        }
        if (file.originFileObj.name.includes('.csv')) {
          Papa.parse(file.originFileObj, {
            skipEmptyLines: true,
            complete: (result) => {
              if (result.data.length == 13) {
                let arr1 = []
                let arr2 = []
                let arr3 = []
                let arr4 = []
                let num1 = 0
                let num2 = 0
                let num3 = 0
                let num4 = 0
                result.data.map(x => {
                  let year = ''
                  if (!isNaN(x[9])) {
                    let gradYear = x[9]
                    year = x[6].slice(6, 11)
                    if (x[6].slice(0, 2) > 7) {
                      year = parseInt(year, 10)
                      year += 1

                    }
                    if ((12 - ((gradYear) - (year)) == 9)) {
                      arr1.push(x[5])
                    }
                    if ((12 - ((gradYear) - (year)) == 10)) {
                      arr2.push(x[5])
                    }
                    if ((12 - ((gradYear) - (year)) == 11)) {
                      arr3.push(x[5])
                    }
                    if ((12 - ((gradYear) - (year)) == 12)) {
                      arr4.push(x[5])
                    }
                  }
                  setDataExtra(x[7])
                })
                arr1.map(x => {
                  let num = parseFloat(x, 10)
                  num1 += num
                })
                arr2.map(x => {
                  let num = parseFloat(x, 10)

                  num2 += num
                })
                arr3.map(x => {
                  let num = parseFloat(x, 10)

                  num3 += num
                })
                arr4.map(x => {
                  let num = parseFloat(x, 10)

                  num4 += num
                })
                setDataExtraFreshman(num1)
                setDataExtraSophomore(num2)
                setDataExtraJunior(num3)
                setDataExtraSenior(num4)
              }
              dataCsv.push(...result.data)
              let set = new Set(dataCsv.map(JSON.stringify))
              let arr2 = Array.from(set).map(JSON.parse)
              setDataCsv([arr2])
            }
          })
        }
      })
    },
    // onDrop(e, info) {
    //   //   console.log('Dropped files', e.dataTransfer.files.length)
    // }
  }
  const styles = StyleSheet.create({

  });

  const Download = () => {
    const handleDownload = async () => {
      const blob = await pdf(<MyDoc />).toBlob()
      console.log(blob, 'what is blob')
      saveAs(blob, 'untitled.pdf')
    }
    // disabled={dataCsv.length > 0}

    return <Button onClick={handleDownload}>Download</Button>

  }

  const MyDoc = () => {
    return <>
      <Document>
        <Page size="A4" >
          <Text>
            Hello, we are still working on this.
          </Text>
        </Page>
      </Document>
    </>
  }

  return (
    <>
      <PdfPage title='CNG Report' breadcrumb={breadcrumb}  >

        <Row>
          <Col span={8}>
          </Col>
          <Col span={8}>
            <Image src={CngLogo} preview={false} />
          </Col>
          <Col span={8}>
          </Col>
        </Row>
        <div id='noprint'>
          <Dragger {...props} beforeUpload={() => false} >
            <p className='ant-upload-drag-icon'>
              <InboxOutlined />
            </p>
            <p className='ant-upload-text'>
              Click or drag file to this area to upload
            </p>
            <p className='ant-upload-hint'>
              Support for a single or bulk upload. Accepts CSV files only.
            </p>
          </Dragger>
          <Row >
            <Col span={8}>
            </Col>
            <Col span={8} type="flex" align="middle">
              <Download />
            </Col>
            <Col span={8}>
            </Col>
          </Row>

        </div>
        <Row>
          <Col span={12}>
            <Table
              style={{ margin: '10px' }}
              title={() => grabGpa(freshman)}
              dataSource={shapeData(freshman)}
              rowKey={record => record.dataSource}
              columns={columns}
            />
          </Col>
          <Col span={12}>
            <Table
              style={{ margin: '10px' }}
              title={() => grabGpa(sophomore)}
              dataSource={shapeData(sophomore)}
              rowKey={record => record.dataSource}
              columns={columns}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Table
              style={{ margin: '10px' }}
              title={() => grabGpa(junior)}
              dataSource={shapeData(junior)}
              rowKey={record => record.dataSource}
              columns={columns}
            />
          </Col>
          <Col span={12}>
            <Table
              style={{ margin: '10px' }}
              title={() => grabGpa(senior)}
              dataSource={shapeData(senior)}
              rowKey={record => record.dataSource}
              columns={columns}
            />
          </Col>
        </Row>
        <Row >
          <Col span={8}>
          </Col>
          <Col span={8} type="flex" align="middle">
            <h1 >HS Social Service Hours: {dataExtra}</h1>
            <div style={{ display: 'none' }}>
              {grabCumulativeGpa(freshman)}
              {grabCumulativeGpa(sophomore)}
              {grabCumulativeGpa(junior)}
            </div>
            <h1 >Cumulative GPA: {grabCumulativeGpa(senior)}</h1>
          </Col>
          <Col span={8}>
          </Col>
        </Row>
        <Row>
          <h1>
            Freshman Social Service Hours: {' '}{dataExtraFreshman?.toFixed(2)}
          </h1>
        </Row>
        <Row>
          <h1>
            Sophomore Social Service Hours: {' '}{dataExtraSophomore?.toFixed(2)}
          </h1>
        </Row>
        <Row>
          <h1>
            Junior Social Service Hours: {' '}{dataExtraJunior?.toFixed(2)}
          </h1>
        </Row>
        <Row>
          <h1>
            Senior Social Service Hours: {' '}{dataExtraSenior?.toFixed(2)}
          </h1>
        </Row>
      </PdfPage>
    </>
  )
}

export default CngReports
