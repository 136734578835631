import { gql } from "@apollo/client";

export const PRINT_FORM3454K = gql`
  mutation printStudentForm3454K(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $studentStrengths: String
    $targetBehaviour: String
    $behaviourType: String
    $behavioralFunction: String
    $previousInterventionsAttempted: String
    $replacementBehaviour: String
    $formAction: String
$formStatus: String

  ) {
    printStudentForm3454K(
      studentForm3454KInput: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        studentStrengths: $studentStrengths
        targetBehaviour: $targetBehaviour
        behaviourType: $behaviourType
        behavioralFunction: $behavioralFunction
        previousInterventionsAttempted: $previousInterventionsAttempted
        replacementBehaviour: $replacementBehaviour
        
formAction: $formAction
formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;
