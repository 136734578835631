import React from "react";
import { useParams } from "react-router-dom";

import Page from "../../components/Page";
import DistrictForm from "../../components/sections/district/DistrictForm";

const District = () => {
  const { id } = useParams();
  const title = id ? "Edit district" : "Add district";

  const breadcrumb = [
    {
      path: "/",
      breadcrumbName: "Home",
    },
    {
      path: "/districts",
      breadcrumbName: "Districts",
    },
    {
      path: "",
      breadcrumbName: title,
    },
  ];
  return (
    <>
      <Page title={title} breadcrumb={breadcrumb}>
        <div className="page-container">
          <div className="page-padding-leftRight page-padding-topBottom">
            <DistrictForm />
          </div>
        </div>
      </Page>
    </>
  );
};

export default District;
