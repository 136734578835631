import { gql } from "@apollo/client";

export const QUERY_FORM504C_VERSION = gql`
  query studentForm504C(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
  ) {
    studentForm504C(
      studentFormId: $studentFormId
      studentId: $studentId
      formId: $formId
      schoolId: $schoolId
      studentMeetingId: $studentMeetingId
    ) {
      versionId
      formId
      formDate
      studentFullName
      studentDob
      studentImpairment
      studentLifeActivityLimited
      studentAccommodations
      studentAccommodationsForStateAndDistrict
      additionalComments
      annualReviewDate
      triennialReassessmentDate
      districtContact
      meetingAttendedParent1
      meetingAttendedParent2
      meetingAttendedStudent
      meetingAttendedLEARepresentative
      meetingAttendedGeneralEducationTeacher
      meetingAttendedSpecialEducationTeacher
            meetingAttendedSchoolPsychologist
      meetingAttendedSchoolSocialWorker
      meetingAttendedSpeechLanguagePathologist
      meetingAttendedBilingualSpecialist
      meetingAttendedInterpreter
      meetingAttendedSchoolNurse
      meetingAttendedOther
      meetingAttendedOtherText
      formAction
      formStatus
    }
  }
`;
