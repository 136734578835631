import { useEffect, useState, useContext } from 'react'
import { QUERY_STUDENT_MEETING_FORMS } from '../../operations/queries/meeting'
import { useQuery } from '@apollo/client'
import RenderPdfHistory from '../../components/elements/RenderPdfHistory'
import RenderFormPdf from '../../components/elements/RenderFormPdf'
import { Collapse, Button, Row } from 'antd'
import { CaretRightOutlined } from '@ant-design/icons'
import './Panel.css'
import AuthContext from '../../utils/authProvider'

const HistoryHelper = ({ meetingData }) => {
  const { data } = useQuery(QUERY_STUDENT_MEETING_FORMS, {
    variables: { id: meetingData?.id }
  })
  const [meetingDataProps, setMeetingDataProps] = useState()
  const { Panel } = Collapse
  useEffect(() => {
    if (!data) return
    setMeetingDataProps(meetingData)
  }, [data])
  let meetingForms = []
  const allForms = data?.studentMeetingGeneral.meetingForms.map(e => e.forms)
  const flatAllForms = allForms?.flat(1)
  if (meetingData?.studentFormIds.length > 0) {
    meetingData?.studentFormIds.map(x => {
      let id = x
      meetingForms?.push({
        formId: x,
        meetingId: meetingData?.id,
        formData: flatAllForms?.filter(y => {
          if (y?.studentFormId === id) {
            return true
          }
        })
      })
    })
  }
  const Auth = useContext(AuthContext)

  Auth.hasPermission()

  const [activePanelImport, setActivePanelImport] = useState('')
  const [activePanelTames, setActivePanelTames] = useState('')
  const [showUploadedForms, setShowUploadedForms] = useState(true)
  const [showTamesForms, setShowTamesForms] = useState(true)
  const [showTamesPdf, setShowTames] = useState(false)
  const [showImportedPdf, setShowImportedPdf] = useState(false)

  const UploadedForms = () => {
    return (
      <>
        {' '}
        {meetingData?.uploadedDocs.map(x => {
          const changeActivePanel = () => {
            let id = x.id
            setActivePanelTames('')
            setShowTames(false)
            setShowTamesForms(false)
            if (activePanelImport === `${id}`) {
              setActivePanelImport('')
              setShowImportedPdf(false)
              setShowTamesForms(true)
            } else {
              setActivePanelImport(`${id}`)
              setShowImportedPdf(true)
            }
          }
          return (
            <>
              <Collapse
                activeKey={activePanelImport}
                onChange={changeActivePanel}
                style={{ overflow: 'hidden !important' }}
                expandIcon={({ isActive }) => {
                  if (isActive === true) {
                    return (
                      <Row>
                        <Button
                          onClick={() => {
                            setShowTamesForms(true)
                            setShowUploadedForms(true)
                          }}
                          type='primary'
                        >
                          Close
                        </Button>
                      </Row>
                    )
                  } else {
                    return <CaretRightOutlined />
                  }
                }}
              >
                <Panel
                  header={`${x.name} (Imported)`}
                  key={`${x.id}`}
                  style={
                    `${x.id}` !== activePanelImport && activePanelImport !== ''
                      ? { display: 'none' }
                      : { display: 'block' }
                  }
                >
                  <RenderPdfHistory pdfData={x} props={showImportedPdf} />
                </Panel>
              </Collapse>
            </>
          )
        })}
      </>
    )
  }

  const TamesForms = () => {
    return (
      <>
        {' '}
        {meetingForms?.map(x => {
          const changeActivePanel = () => {
            let id = x.formId
            setActivePanelImport('')
            setShowImportedPdf(false)
            setShowUploadedForms(false)

            if (activePanelTames === `${id}`) {
              setActivePanelTames('')
              setShowTames(false)
              setShowUploadedForms(true)
            } else {
              setActivePanelTames(`${id}`)
              setShowTames(true)
            }
          }
          return (
            <>
              <Collapse
                activeKey={activePanelTames}
                onChange={changeActivePanel}
                style={{ overflow: 'hidden !important' }}
                expandIcon={({ isActive }) => {
                  if (isActive === true) {
                    return (
                      <Row>
                        <Button
                          onClick={() => {
                            setShowTamesForms(true)
                            setShowUploadedForms(true)
                          }}
                          type='primary'
                        >
                          Close
                        </Button>
                      </Row>
                    )
                  } else {
                    return <CaretRightOutlined />
                  }
                }}
              >
                <Panel
                  className='custom'
                  header={
                    x.formData !== undefined
                      ? x?.formData[0]?.name
                      : 'Loading...'
                  }
                  key={`${x.formId}`}
                  style={
                    `${x.formId}` !== activePanelTames &&
                    activePanelTames !== ''
                      ? { display: 'none' }
                      : { display: 'block' }
                  }
                >
                  <RenderFormPdf
                    props={showTamesPdf}
                    formData={x}
                    meetingData={meetingDataProps}
                  />
                </Panel>
              </Collapse>
            </>
          )
        })}
      </>
    )
  }
  return (
    <>
      {meetingData && (
        <>
          {meetingData?.uploadedDocs.length === 0 &&
          meetingForms?.length === 0 ? (
            <>Meeting has no forms.</>
          ) : (
            <>
              {meetingForms?.length > 0 && (
                <>{showTamesForms === true && <TamesForms />}</>
              )}

              {meetingData?.uploadedDocs.length > 0 && (
                <>{showUploadedForms == true && <UploadedForms />}</>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

export default HistoryHelper
