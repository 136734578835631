import { gql } from "@apollo/client";

export const QUERY_FORM3454_VERSION = gql`
  query studentForm3454(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
  ) {
    studentForm3454(
      studentFormId: $studentFormId
      studentId: $studentId
      formId: $formId
      schoolId: $schoolId
      studentMeetingId: $studentMeetingId
    ) {
      versionId
      formId
      recentEvaluationDate
      nextEvaluationDate
      purposeOfConference
      purposeOfConferenceOtherDescription
      formDate
      studentFullName
      address
      city
      state
      country
      studentDob
      studentCode
      studentLanguage
      studentGrade
      studentGender
      studentEthnicity
      highSchoolGraduationDate
      placement
      disabilities
      medicaidNumber
      residentDistrict
      residentSchool
      servingDistrict
      servingSchool
      parent1
      educationSurrogateParent1
      parent1Email
      parent1PhoneNumber
      parent1Address
      parent1City
      parent1State
      parent1Zip
      parent1PrimaryLanguage
      interpreterParent1
      parent2
      educationSurrogateParent2
      parent2Email
      parent2PhoneNumber
      parent2Address
      parent2City
      parent2State
      parent2Zip
      parent2PrimaryLanguage
      interpreterParent2
      parentRequestedInterpreter
      interpreterProvided
      interpreterProvidedLanguage
      parentRequestedInterpreterServeNoOtherRole
      parentInterpreterRoleRequestGranted
      safeguardReviewDate
      safeguardsLanguage
      transferOfRights
      parentGivenEvaluationReport
      evaluationReportLanguage
      parentGivenIep
      iepLanguage
      parentGivenDistrictBehavioralPolicy
      parentGivenDistrictBehavioralInterventionProcedure
      parentGivenPuns
      parentGivenAble
      parentGivenThreeDayDraft
      threeDayDraftProvidedVia
      threeDayDraftDate
      threeDayDraftLanguage
      meetingsAttendedParent1
      meetingsAttendedParent2
      meetingsAttendedStudent
      meetingsAttendedLEARepresentative
      meetingsAttendedGeneralEducationTeacher
      meetingsAttendedSpecialEducationTeacher
      meetingsAttendedSchoolPsychologist
      meetingsAttendedSchoolSocialWorker
      meetingsAttendedSpeechLanguagePathologist
      meetingsAttendedBilingualSpecialist
      meetingsAttendedInterpreter
      meetingsAttendedSchoolNurse
      meetingsAttendedOther
      meetingsAttendedOtherText
      parentContactAttemptsBeforeMeeting
      meetingScheduledAtAgreedTimeAndPlace
      meetingScheduleExplanation
      formAction
      formStatus
    }
  }
`;
