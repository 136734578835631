import React, { useState, useContext, useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'

import Page from '../../components/Page'
import StudentFormSidebar from '../../components/sections/studentForm/StudentFormSidebar'
import StudentFormSidebarEdit from '../../components/sections/studentForm/StudentFormSidebarEdit'
import StudentFormEditArea from '../../components/sections/studentForm/StudentFormEditArea'
import { IepFormContext } from '../../utils/iepFormProvider'
import PrintModal from '../../components/sections/studentForm/PrintModal'
import FileHistory from '../student/FileHistory'
import EditMeetingInfo from '../../components/sections/studentForm/EditMeetingInfo'
import { QUERY_STUDENT } from '../../operations/queries/students'
import { useLazyQuery } from '@apollo/client'
import { Layout, Grid, Button, Dropdown, Menu, Card, Space, Affix } from 'antd'

import {
  CaretDownOutlined,
  FilePdfOutlined,
  PrinterOutlined
} from '@ant-design/icons'

const { useBreakpoint } = Grid

const StudentForm = () => {
  const { studentMeetingId } = useParams()
  const [editMode, setEditMode] = useState(false)
  const [editMeeting, setEditMeeting] = useState(false)
  const [printModal, setPrintModal] = useState(false)
  const [showMeetingHistory, setShowMeetingHistory] = useState(false)
  const { setCurrentEditableForm } = useContext(IepFormContext)
  const { hasAddPermission, hasPrintPermission, hasViewPermission } =
    useContext(IepFormContext)
  const screens = useBreakpoint()
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false)
  let title = 'Meeting Forms'

  if (editMeeting === true) {
    title = 'Edit Meeting Info'
  }

  if (editMode === true) {
    title = 'Edit Meeting Forms'
  }

  useEffect(() => {
    setCurrentEditableForm({
      formId: '',
      formCode: ''
    })
  }, [editMode])

  const breadcrumb = [
    {
      path: '/',
      breadcrumbName: 'Home'
    },
    {
      path: '/student-form',
      breadcrumbName: title
    }
  ]

  const location = useLocation()
  let currentGoalInfo = location?.state?.currentGoal
  let priorGoalInfo = location?.state?.priorGoal
  let studentData = location?.state?.studentData
  const { currentStudentInfo, currentEditableForm } = useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { id } = useParams()
  const [getStudentData, { data: data, loadingStudent }] = useLazyQuery(
    QUERY_STUDENT,
    {
      variables: { id: studentId },
      skip: !id,
      nextFetchPolicy: 'cache-first' //fixes infinite render bug
    }
  )
  useEffect(() => {
    getStudentData()
  }, [])
  const showTheHistory = () => {
    setShowMeetingHistory(true)
    setSidebarCollapsed(true)
    setEditMeeting(false)
  }
  const undoShowTheHistory = () => {
    setShowMeetingHistory(false)
    setSidebarCollapsed(false)
    setEditMeeting(false)
  }

  const editMeetingForms = () => {
    setEditMode(true)
    setEditMeeting(false)
    setSidebarCollapsed(false)
    if (showMeetingHistory === true) {
      setShowMeetingHistory(false)
    }
  }
  const returnToMeeting = () => {
    setEditMode(false)
    setEditMeeting(false)
    setSidebarCollapsed(false)
    if (showMeetingHistory === true) {
      setShowMeetingHistory(false)
    }
  }

  return (
    <>
      <PrintModal
        visible={printModal}
        setVisible={setPrintModal}
        type={printModal}
        studentMeetingId={studentMeetingId}
      />
      <Page
        title={title}
        breadcrumb={breadcrumb}
      // extra={
      // this is where we used to store some buttons but it is not a great place to do so
      // }
      >
        <div className='page-container'>
          <EditMeetingInfo studentData={data} />
          <Affix
            offsetTop={0}
            style={showMeetingHistory ? { width: '50%' } : { width: '100%' }}
            onChange={affixed => console.log(affixed)}
          >
            <div>
              <Card
                style={{
                  padding: '0px',
                  marginBottom: '10px',
                  height: '40px',
                  overflow: 'hidden'
                }}
              >
                {editMode ? (
                  <>
                    <Space
                      style={{
                        position: 'relative',
                        top: '-1.3rem'
                      }}
                      align='start'
                    >
                      <Button type='primary' onClick={returnToMeeting}>
                        Go to Meeting
                      </Button>

                      <Dropdown
                        key={1}
                        overlay={
                          <Menu>
                            <Menu.Item onClick={() => setPrintModal('print')}>
                              <PrinterOutlined /> Print
                            </Menu.Item>
                            <Menu.Item
                              onClick={() => setPrintModal('download')}
                            >
                              <FilePdfOutlined /> Download
                            </Menu.Item>
                          </Menu>
                        }
                      >
                        <Button type='primary'>
                          <FilePdfOutlined /> Print / Download Multiple{' '}
                          <CaretDownOutlined />
                        </Button>
                      </Dropdown>
                      {showMeetingHistory === true ? (
                        <>
                          {editMeeting === false && (
                            <Button type='primary' onClick={undoShowTheHistory}>
                              Close History
                            </Button>
                          )}
                        </>
                      ) : (
                        <>
                          {editMeeting === false && (
                            <Button type='primary' onClick={showTheHistory}>
                              View History
                            </Button>
                          )}
                        </>
                      )}
                    </Space>
                  </>
                ) : (
                  <>
                    {' '}
                    <Space
                      style={{
                        position: 'relative',
                        top: '-1.3rem'
                      }}
                    >
                      <Button type='primary' onClick={editMeetingForms}>
                        Edit Meeting Forms
                      </Button>

                      <Dropdown
                        key={1}
                        overlay={
                          <Menu>
                            <Menu.Item onClick={() => setPrintModal('print')}>
                              <PrinterOutlined /> Print
                            </Menu.Item>
                            <Menu.Item
                              onClick={() => setPrintModal('download')}
                            >
                              <FilePdfOutlined /> Download
                            </Menu.Item>
                          </Menu>
                        }
                      >
                        <Button type='primary'>
                          <FilePdfOutlined /> Print / Download Multiple{' '}
                          <CaretDownOutlined />
                        </Button>
                      </Dropdown>
                      {showMeetingHistory === true ? (
                        <>
                          {editMeeting === false && (
                            <Button type='primary' onClick={undoShowTheHistory}>
                              Close History
                            </Button>
                          )}
                        </>
                      ) : (
                        <>
                          {editMeeting === false && (
                            <Button type='primary' onClick={showTheHistory}>
                              View History
                            </Button>
                          )}
                        </>
                      )}
                    </Space>
                  </>
                )}
              </Card>
            </div>
          </Affix>
          <Layout>
            {editMode ? (
              <>
                {!sidebarCollapsed && (
                  <Layout.Sider
                    theme='light'
                    // breakpoint='lg'
                    // trigger={null}
                    width='18%'
                    collapsedWidth='0'
                    // collapsed={sidebarCollapsed}
                    // onBreakpoint={broken => {
                    //   setSidebarCollapsed(broken)
                    // }}
                    style={{ marginRight: '24px' }}
                  >
                    <StudentFormSidebarEdit
                      setEditMode={setEditMode}
                      setEditMeeting={setEditMeeting}
                      editMode={editMode}
                      editMeeting={editMeeting}
                    />
                  </Layout.Sider>
                )}
              </>
            ) : (
              <>
                {editMeeting ? (
                  <></>
                ) : (
                  <>
                    {!sidebarCollapsed && location && (
                      <Layout.Sider
                        theme='light'
                        // breakpoint='lg'
                        // trigger={null}
                        width='18%'
                        collapsedWidth='0'
                        // collapsed={sidebarCollapsed}
                        // onBreakpoint={broken => {
                        //   setSidebarCollapsed(broken)
                        // }}
                        style={{ marginRight: '24px' }}
                      >
                        <StudentFormSidebar
                          setEditMode={setEditMode}
                          setEditMeeting={setEditMeeting}
                          editMode={editMode}
                          editMeeting={editMeeting}
                          currentGoalInfo={currentGoalInfo}
                          priorGoalInfo={priorGoalInfo}
                        />
                      </Layout.Sider>
                    )}
                  </>
                )}
              </>
            )}

            <Layout.Content>
              <StudentFormEditArea
                editMode={editMode}
                editMeeting={editMeeting}
              />
            </Layout.Content>
            {showMeetingHistory === true ? (
              <Layout.Sider
                theme='light'
                // breakpoint='lg'
                // trigger={null}
                width='50%'
                collapsedWidth='0'
              >
                <div style={{ paddingLeft: '10px' }}>
                  <FileHistory studentData={studentData} />
                </div>
              </Layout.Sider>
            ) : (
              <></>
            )}
          </Layout>
        </div>
      </Page>
    </>
  )
}

export default StudentForm
