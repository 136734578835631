// react
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";

// apollo & operations
import { useQuery, useMutation } from "@apollo/client";
import { QUERY_ROLE, QUERY_ROLES } from "../../../operations/queries/roles";
import { INSERT_ROLE, UPDATE_ROLE } from "../../../operations/mutations/roles";

// ant design
import { Form, Input, Button, Typography } from "antd";

// elements
import FormItemsSuperAdmin from "../../elements/formItems/FormItemsSuperAdmin";

// other libraries
import formatErrors from "../../../utils/form";
import FormContainer from "../../../components/FormContainer";
import ModalDialog from "../../../components/ModalDialog";
import notificationSuccess from "../../../utils/notification";
const { Paragraph } = Typography;

const FormRecord = ({ data }) => {
  const history = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [formError, setFormError] = useState();
  const [itemErrors, setItemErrors] = useState({});
  const [updateRole, { loading: updateLoading }] = useMutation(UPDATE_ROLE);
  const [insertRole, { loading: insertLoading }] = useMutation(INSERT_ROLE, {
    update(cache, { data: addRole }) {
      const data = cache.readQuery({ query: QUERY_ROLES });
      if (data) {
        cache.writeQuery({
          query: QUERY_ROLES,
          data: { roles: [addRole.insertRole, ...data.roles] },
        });
      }

    },
  });

  const continueEditing = (_id) => _id && history(`/role/${_id}`);

  const onFinish = async (values) => {
    try {
      clearErrors();
      const mutated = id
        ? await updateRole({ variables: { id: id, roleInput: values } })
        : await insertRole({ variables: values });
      // ModalDialog.success({
      //   title: "Success",
      //   content: `The role has been ${id ? "updated" : "added"}.`,
      //   okText: "Return to listing",
      //   onOk: () => history("/roles"),
      //   cancelText: "Continue editing",
      //   onCancel: () => continueEditing(mutated.data.insertRole?.id),
      // })
      notificationSuccess("Role Updated");
    } catch (e) {
      setErrors(formatErrors(e));
    }
  };

  const setErrors = (errors) =>
    errors.itemErrors
      ? setItemErrors(errors.itemErrors)
      : setFormError(errors.formError);

  const clearErrors = () => {
    setFormError();
    setItemErrors({});
  };

  return (
    <Form
      form={form}
      id="roleForm"
      name="roleForm"
      onFinish={onFinish}
      preserve={false}
      initialValues={data?.role}
      labelCol={{ sm: 3, md: 2, lg: 2, xl: 2, xxl: 1 }}
      wrapperCol={{ sm: 10, md: 7, lg: 8, xl: 6, xxl: 4 }}
    >
      <Form.Item
        label="Name"
        name="name"
        hasFeedback
        validateStatus={itemErrors.name ? "error" : ""}
        help={itemErrors.name}
      >
        <Input />
      </Form.Item>

      <FormItemsSuperAdmin data={data.role} hasHidden />

      {formError && <Paragraph type="danger">{formError}</Paragraph>}

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={insertLoading || updateLoading}
        >
          {id ? "Update" : "Add"}
        </Button>
      </Form.Item>
    </Form>
  );
};

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
};

const RoleForm = (props) => {
  const { id } = useParams();
  const { data, loading } = useQuery(QUERY_ROLE, {
    variables: { id },
    skip: !id,
  });
  const initialValues = { role: { name: "" } };

  return (
    <FormContainer
      loading={loading}
      form={<FormRecord data={data || initialValues} />}
    />
  );
};

export default RoleForm;
