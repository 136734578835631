import { gql } from "@apollo/client";

export const INSERT_DOCUMENT = gql`
  mutation insertUser(
    $email: String!
    $roleIds: [ID!]
    $userAdminDistrictIds: [ID!]
    $userAdminSchoolIds: [ID!]
    $userGeneralSchoolIds: [ID!]
    $hidden: Boolean
  ) {
    insertUser(
      userInput: {
        email: $email
        roleIds: $roleIds
        userAdminDistrictIds: $userAdminDistrictIds
        userAdminSchoolIds: $userAdminSchoolIds
        userGeneralSchoolIds: $userGeneralSchoolIds
        hidden: $hidden
      }
    ) {
      id
      email
      roleIds
      userAdminDistrictIds
      userAdminSchoolIds
      userGeneralSchoolIds
      hidden
    }
  }
`;

export const UPDATE_DOCUMENT = gql`
  mutation updateUser($id: ID!, $userInput: UserInput) {
    updateUser(id: $id, userInput: $userInput) {
      id
      email
      roleIds
      userAdminDistrictIds
      userAdminSchoolIds
      userGeneralSchoolIds
      hidden
    }
  }
`;
