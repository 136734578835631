import { useState, useEffect } from 'react'
import { UploadOutlined } from '@ant-design/icons'
import {
  message,
  Upload,
  Button,
  Icon,
  Card,
  Form,
  Modal,
  DatePicker
} from 'antd'
import { Document, Page, View, pdfjs } from 'react-pdf'
import { useMutation, useQuery, useLazyQuery } from '@apollo/client'
import axios from 'axios'
import DateSelector from './common/DateSelector'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

import { PUT_S3_SIGNED_URL } from '../../operations/queries/fileUploads'
import { INSERT_STUDENT_UPLOADED_DOC } from '../../operations/mutations/students'
import { QUERY_STUDENT } from '../../operations/queries/students'

const FileUploadStudent = ({ studentData }) => {
  const studentId = studentData.student.id

  const [fileData, setFileData] = useState({})
  const [uploading, setUploading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [form] = Form.useForm()
  const [pdf, setPdf] = useState('')

  const [insertStudentUploadedDoc, { loading: insertStudentDocLoading }] =
    useMutation(INSERT_STUDENT_UPLOADED_DOC, {
      refetchQueries: [
        {
          query: QUERY_STUDENT,
          variables: { id: studentId }
        }
      ]
    })

  const [s3PutSignedURLRequest, { data: s3DataPut, loading: s3LoadingPut }] =
    useLazyQuery(PUT_S3_SIGNED_URL)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = ({ onError, onSuccess }) => {
    setUploading(true)
    form
      .validateFields()
      .then(async () => {
        if (!pdf.includes('data:application/pdf;base64,JVBER')) {
          message.error('upload failed.')
        } else {
          const file = fileData.file

          if (s3DataPut != undefined && s3LoadingPut === false) {
            const options = {
              headers: {
                'Content-Type': 'application/pdf'
              }
            }

            console.log(
              'before upload',
              s3DataPut.s3PutSignedURLRequest.signedURL,
              file,
              options
            )
            const t0 = performance.now()
            await axios
              .put(s3DataPut.s3PutSignedURLRequest.signedURL, file, options)
              .then(() => {
                const t1 = performance.now()
                console.log(
                  'Successfully uploaded ' +
                    file.name +
                    '. Bytes: ' +
                    file.size +
                    ', Milliseconds ' +
                    (t1 - t0) +
                    ', BPS: ' +
                    file.size / ((t1 - t0) / 1000)
                )
                //setUploading(false);
              })
            insertStudentUploadedDoc({
              variables: {
                id: studentId,
                uploadedDoc: {
                  name: fileData?.file.name,
                  contentType: fileData?.file.type,
                  uniqueName: s3DataPut.s3PutSignedURLRequest.uniqueName,
                  url: s3DataPut.s3PutSignedURLRequest.url,
                  docDate: form.getFieldValue('documentDate')
                }
              }
            }).catch(e => {
              console.log(e, 'error')
            })
            message.success('uploaded to student successfully.')
          }
        }
      })

      .finally(() => {
        setUploading(false)
      })
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const props = {
    beforeUpload: file => {
      const reader = new FileReader()
      reader.onloadend = () => {
        setPdf(reader.result)
      }
      //setFileData({ file })
      reader.readAsDataURL(file)

      return false
    },

    multiple: false,
    accept: '.pdf',
    maxCount: 1,
    onChange (info) {
      const { status } = info.file
      const file = info.file
      setFileData({ file })
      console.log('onChange():', info, status, fileData, file)
    },
    onSuccess: (result, file) => {
      console.log('On onSuccess')
    }
  }
  useEffect(() => {
    const fileName = fileData.file?.name ? fileData.file.name : null
    const fileType = fileData.file?.type ? fileData.file.type : null
    if (fileName) {
      s3PutSignedURLRequest({
        variables: {
          fileName: fileName,
          contentType: fileType
        }
      })
    }

    console.log('useEffect', fileData, s3DataPut, fileName, fileType)
  }, [fileData])

  return (
    <>
      <Button onClick={showModal}>Upload Document</Button>
      <Modal
        title='Upload Student Document'
        loading={uploading}
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText='Confirm'
        // disabled={fileList.length === 0}
      >
        <Form layout='vertical' name='addStudentDoc' form={form}>
          <Form.Item>
            <Upload {...props}>
              <Button icon={<UploadOutlined />}>Select a PDF</Button>
            </Upload>
          </Form.Item>
          <Form.Item name='documentDate' label='Document Date'>
            <DatePicker
              format={[
                'MM-DD-YYYY',
                'MM/DD/YYYY',
                'MM.DD.YY',
                'M-D-YYYY',
                'M/D/YYYY',
                'M.D.YYYY',
                'MM.DD.YYYY',
                'MM-DD-YY',
                'MM/DD/YY',
                'M-D-YY',
                'M/D/YY',
                'M.D.YY',
                'MMDDYYYY',
                'MMDDYY'
              ]}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default FileUploadStudent
