import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { useQuery } from '@apollo/client'

// ant design
import {
  Card,
  Form,
  Input,
  DatePicker,
  Radio,
  Divider,
  Alert,
  Typography
} from 'antd'

import FormContainer from '../../../FormContainer'
import FormItem from 'antd/lib/form/FormItem'
import Title from 'antd/lib/typography/Title'

import { PRINT_FORM3457A } from '../../../../operations/mutations/forms/3457A'
import {
  STUDENT_INFO_FORM3457A,
  QUERY_FORM3457A_VERSION
} from '../../../../operations/queries/forms/3457A'

import Editor from '../../../elements/common/Editor'
import FormCollaboration from '../../../FormCollaboration'

import { IepFormContext } from '../../../../utils/iepFormProvider'
import StudentFormActions from '../StudentFormActions'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import StudentDobSIS from '../sis/StudentDobSIS'
import StudentParent1SIS from '../sis/StudentParent1SIS'
import StudentParent2SIS from '../sis/StudentParent2SIS'

const { Paragraph } = Typography

const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId
}) => {
  const [form] = Form.useForm()
  const { itemErrors, formError } = useContext(FormErrorContext)
  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()
    //console.log("In StudentForm3457A.getFormFieldValues", formFieldValues)
    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      studentMeetingId: studentMeetingId
    }
  }

  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    //console.log("In StudentForm3457A.formatFormFieldUpdate")
    const update = Object.assign({})
    switch (fieldName) {
      case 'evaluationRequestDate':
      case 'formDate':
        update[fieldName] = moment(fieldValue)
        break
      case 'parentCopy':
        update[fieldName] = fieldValue === 'true'
        break
      default:
        update[fieldName] = fieldValue
    }
    return update
  }

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }

  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          formDisabledState,
          canSave,
          handleActionEvents,
          validationProps
        }) => (
          <Form
            form={form}
            id='studentForm3457A'
            name='studentForm3457A'
            initialValues={data}
            preserve
            layout='vertical'
            className='page-padding-leftRight page-padding-topBottom'
          >
            <Title level={4} className='center'>
              PARENT/GUARDIAN NOTIFICATION OF DECISION REGARDING A REQUEST FOR
              AN EVALUATION
            </Title>
            <Form.Item
              label='Form Created Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
              />
            </Form.Item>

            <Card
              title='Student SIS Data'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />

              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
              <StudentDobSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentDob}
                error={itemErrors.studentDob}
              />
              <StudentParent1SIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.parent1}
                error={itemErrors.parent1}
              />
              <StudentParent2SIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.parent2}
                error={itemErrors.parent2}
              />
            </Card>

            <Card
              title='Referrer Information'
              style={{
                marginBottom: 30
              }}
            >
              <Form.Item
                label='Referrer Request Date'
                name='evaluationRequestDate'
                {...validationProps('evaluationRequestDate', formDisabledState)}
              >
                <DatePicker
                  {...handleFocusEvents}
                  disabled={formDisabledState.evaluationRequestDate}
                  format={[
                    'MM-DD-YYYY',
                    'MM/DD/YYYY',
                    'MM.DD.YY',
                    'M-D-YYYY',
                    'M/D/YYYY',
                    'M.D.YYYY',
                    'MM.DD.YYYY',
                    'MM-DD-YY',
                    'MM/DD/YY',
                    'M-D-YY',
                    'M/D/YY',
                    'M.D.YY',
                    'MMDDYYYY',
                    'MMDDYY'
                  ]}
                />
              </Form.Item>
              <Form.Item
                label='Referrer Title'
                name='referrerTitle'
                {...validationProps('referrerTitle')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.referrerTitle}
                />
              </Form.Item>
              <Form.Item
                label='Referrer'
                name='referrerName'
                validateStatus={itemErrors.referrerName ? 'error' : ''}
                help={itemErrors.referrerName}
                {...validationProps('referrerName')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.referrerName}
                />
              </Form.Item>
              <Form.Item
                label='Evaluation request reason'
                name='evaluationReasonDescription'
                disabled={formDisabledState.evaluationReasonDescription}
                {...validationProps('evaluationReasonDescription')}
              >
                <Editor
                  {...handleEditorEvents}
                  formField='evaluationReasonDescription'
                  disabled={formDisabledState.evaluationReasonDescription}
                />
              </Form.Item>
            </Card>

            <Card
              title='Evaluation Decision Information'
              style={{
                marginBottom: 30
              }}
            >
              <FormItem
                label='Evaluation'
                name='evaluationStatus'
                {...validationProps('evaluationStatus')}
              >
                <Radio.Group
                  defaultValue
                  buttonStyle='solid'
                  {...handleMouseEvents}
                  disabled={formDisabledState.evaluationStatus}
                  name='evaluationStatus'
                >
                  <Radio.Button value='passed'>Necessary</Radio.Button>
                  <Radio.Button value='rejected'>Not Necessary</Radio.Button>
                  <Radio.Button value='n/a'>Not Applicable</Radio.Button>
                </Radio.Group>
              </FormItem>
              <FormItem
                label='Re-Evaluation'
                name='reEvaluationStatus'
                {...validationProps('reEvaluationStatus')}
              >
                <Radio.Group
                  defaultValue
                  buttonStyle='solid'
                  {...handleMouseEvents}
                  disabled={formDisabledState.reEvaluationStatus}
                  name='reEvaluationStatus'
                >
                  <Radio.Button value='passed'>Necessary</Radio.Button>
                  <Radio.Button value='rejected'>Not Necessary</Radio.Button>
                  <Radio.Button value='n/a'>Not Applicable</Radio.Button>
                </Radio.Group>
              </FormItem>
              <Form.Item
                label='Relevant factors for decision '
                name='evaluationDecisionDescription'
                {...validationProps('evaluationDecisionDescription')}
              >
                <Editor
                  {...handleEditorEvents}
                  formField='evaluationDecisionDescription'
                  disabled={formDisabledState.evaluationDecisionDescription}
                />
              </Form.Item>
            </Card>

            <Card
              title='Contact Information'
              style={{
                marginBottom: 30
              }}
            >
              <Form.Item
                label='Contact Name'
                name='documentContactName'
                {...validationProps('documentContactName')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.documentContactName}
                />
              </Form.Item>
              <Form.Item
                label='Contact Title'
                name='documentContactTitle'
                {...validationProps('documentContactTitle')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.documentContactTitle}
                />
              </Form.Item>
              <Form.Item
                label='Contact Phone'
                name='documentContactPhone'
                {...validationProps('documentContactPhone')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.documentContactPhone}
                />
              </Form.Item>
            </Card>

            <Card
              title='Signee Information'
              style={{
                marginBottom: 30
              }}
            >
              <Form.Item
                label='Signature Name'
                name='signatureName'
                {...validationProps('signatureName')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.signatureName}
                />
              </Form.Item>
              <Form.Item
                label='Signature Title'
                name='signatureTitle'
                {...validationProps('signatureTitle')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.signatureTitle}
                />
              </Form.Item>
            </Card>

            <Form.Item
              label={
                <span className='multi-line-label'>
                  Parent/Guardian provided a copy of the Explanation of
                  Procedural Safeguards
                </span>
              }
              name='parentCopy'
              {...validationProps('parentCopy')}
            >
              <Radio.Group
                {...handleMouseEvents}
                buttonStyle='solid'
                disabled={formDisabledState.parentCopy}
                name='parentCopy'
              >
                <Radio.Button value>Copy provided</Radio.Button>
                <Radio.Button value={false}>Copy not provided</Radio.Button>
              </Radio.Group>
            </Form.Item>
            {formError && <Paragraph type='danger'>{formError}</Paragraph>}
            <StudentFormActions
              query={PRINT_FORM3457A}
              extractDataProp='printStudentForm3457A'
              canSave={canSave}
              handleActionEvents={handleActionEvents}

              getFormFieldValues={getFormFieldValues}
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired
}

const StudentForm3457A = () => {
  // get current form information
  const { currentStudentInfo, currentEditableForm, studentMeetingId } =
    useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId } = currentEditableForm
  //console.log("In StudentForm3457A studentFormId: ", studentFormId)

  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    STUDENT_INFO_FORM3457A,
    { variables: { id: studentId } }
  )
  //console.log("In StudentForm3457A=>After STUDENT_INFO_FORM3457A query")

  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORM3457A_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )
  //console.log("In StudentForm3457A=>After QUERY_FORM3457A_VERSION query")

  const createInitialFormData = (studentInfo, versionInfo) => {
    //console.log("In StudentForm3457A.createInitialFormData")

    let initialValues = {}

    if (!studentInfo || !versionInfo) return initialValues

    const { studentForm3457A: form } = versionInfo
    const { student } = studentInfo
    const { parent1FullName, parent2FullName, dateOfBirth } = student
    const {
      formDate,
      studentFullName,
      studentDob,
      parent1,
      parent2,
      evaluationRequestDate
    } = form

    initialValues = {
      ...form,
      formDate: formDate ? moment(formDate) : moment(Date.now()),
      studentFullName: studentFullName || student.fullName || '',
      studentDob: studentDob
        ? moment(studentDob).format('YYYY-MM-DD')
        : dateOfBirth
        ? moment(dateOfBirth).format('YYYY-MM-DD')
        : '',
      parent1: parent1 || parent1FullName || '',
      parent2: parent2 || parent2FullName || '',
      evaluationRequestDate: evaluationRequestDate
        ? moment(evaluationRequestDate)
        : moment(Date.now())
    }

    return initialValues
  }

  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
          />
        }
      />
    </FormErrorProvider>
  )
}

export default StudentForm3457A
