import { gql } from "@apollo/client";

export const QUERY_FORMTAMES2_VERSION = gql`
  query studentFormTames2(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
  ) {
    studentFormTames2(
      studentFormId: $studentFormId
      studentId: $studentId
      formId: $formId
      schoolId: $schoolId
      studentMeetingId: $studentMeetingId
    ) {
      versionId
      formId
      formDate
      studentDob
      studentFullName
      parentConsentDate
      parentConsentGranted
      doNotIncludeReport
      formAction
      formStatus
      domains {
        domain
        domainDateCompleted
        domainEvaluationSummary
        domainEvaluationReport
      }
    }
  }
`;
