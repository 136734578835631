import { gql } from "@apollo/client";

export const PRINT_FORM3454O = gql`
  mutation printStudentForm3454O(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $studentDob: String
    $participateClassroomAssessments: Boolean
    $districtAdministerAssessments: Boolean
    $districtAdministerAssessmentInGrade: Boolean
    $gradeLevel: String
    $studentParticipation: [String]
    $stateAcademicAssessmentNotAdministered: String
    $illinoisReadinessAssessment: [String]
    $dynamicLearningMaps: [String]
    $collegeBoard: [String]
    $illinoisScienceAssessment: [String]
    $physicalFitnessAssessmentFitnessGram: [String]
    $willNotParticipatePhysicalFitnessAssessment: String
    $physicalFitnessAssessmentBrockport: [String]
    $willNotParticipatePhysicalFitnessAssessment2: String
    $kindergartenDevelopment: [String]
    $indicateSubset: [String]
    $indicateWithAccommodationSubset: [String]
    $englishLearner: Boolean
    $ifYesStudentWill: [String]
    $classroomAssessments: String
    $districtAssessments: String
    $collegeAssessments: String
    $scienceAssessment: String
    $physicalFitnessAssessment: String
    $kidsAssessment: [String]
    $accessAlternativeAccess: String
    $formAction: String
$formStatus: String
  ) {
    printStudentForm3454O(
      studentForm3454OInput: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        studentDob: $studentDob
        participateClassroomAssessments: $participateClassroomAssessments
        districtAdministerAssessments: $districtAdministerAssessments
        districtAdministerAssessmentInGrade: $districtAdministerAssessmentInGrade
        gradeLevel: $gradeLevel
        studentParticipation: $studentParticipation
        stateAcademicAssessmentNotAdministered: $stateAcademicAssessmentNotAdministered
        illinoisReadinessAssessment: $illinoisReadinessAssessment
        dynamicLearningMaps: $dynamicLearningMaps
        collegeBoard: $collegeBoard
        illinoisScienceAssessment: $illinoisScienceAssessment
        physicalFitnessAssessmentFitnessGram: $physicalFitnessAssessmentFitnessGram
        willNotParticipatePhysicalFitnessAssessment: $willNotParticipatePhysicalFitnessAssessment
        physicalFitnessAssessmentBrockport: $physicalFitnessAssessmentBrockport
        willNotParticipatePhysicalFitnessAssessment2: $willNotParticipatePhysicalFitnessAssessment2
        kindergartenDevelopment: $kindergartenDevelopment
        indicateSubset: $indicateSubset
        indicateWithAccommodationSubset: $indicateWithAccommodationSubset
        englishLearner: $englishLearner
        ifYesStudentWill: $ifYesStudentWill
        classroomAssessments: $classroomAssessments
        districtAssessments: $districtAssessments
        collegeAssessments: $collegeAssessments
        scienceAssessment: $scienceAssessment
        physicalFitnessAssessment: $physicalFitnessAssessment
        kidsAssessment: $kidsAssessment
        accessAlternativeAccess: $accessAlternativeAccess
formAction: $formAction
formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;
