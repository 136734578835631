import { gql } from "@apollo/client";

export const PRINT_FORM3454H = gql`
  mutation printStudentForm3454H(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $employmentAssessmentType: String
    $employmentResponsibleAgency: String
    $educationAssessmentType: String
    $educationResponsibleAgency: String
    $trainingAssessmentType: String
    $trainingResponsibleAgency: String
    $livingSkillsAssessmentType: String
    $livingSkillsResponsibleAgency: String
    $employmentOutcomes: String
    $postSecondaryEducationOutcome: String
    $postSecondaryTrainingOutcome: String
    $independentLivingOutcome: String
    $formAction: String
$formStatus: String

    $coursesOfStudy: [StudentForm3454HCoursesOfStudyInput]
  ) {
    printStudentForm3454H(
      studentForm3454HInput: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        employmentAssessmentType: $employmentAssessmentType
        employmentResponsibleAgency: $employmentResponsibleAgency
        educationAssessmentType: $educationAssessmentType
        educationResponsibleAgency: $educationResponsibleAgency
        trainingAssessmentType: $trainingAssessmentType
        trainingResponsibleAgency: $trainingResponsibleAgency
        livingSkillsAssessmentType: $livingSkillsAssessmentType
        livingSkillsResponsibleAgency: $livingSkillsResponsibleAgency
        employmentOutcomes: $employmentOutcomes
        postSecondaryEducationOutcome: $postSecondaryEducationOutcome
        postSecondaryTrainingOutcome: $postSecondaryTrainingOutcome
        independentLivingOutcome: $independentLivingOutcome

formAction: $formAction
formStatus: $formStatus
        coursesOfStudy: $coursesOfStudy
      }
    ) {
      link
      fileName
    }
  }
`;
