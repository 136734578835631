// react
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";

// apollo & operations
import { useQuery, useMutation } from "@apollo/client";
import {
  QUERY_MESSAGE,
  QUERY_MESSAGES,
} from "../../../operations/queries/messages";
import {
  INSERT_MESSAGE,
  UPDATE_MESSAGE,
} from "../../../operations/mutations/messages";

// ant design
import { Form, Input, Button, DatePicker } from "antd";

// elements
import FormItemsSuperAdmin from "../../elements/formItems/FormItemsSuperAdmin";
import FormItemError from "../../elements/formItems/FormItemError";

// other libraries
import formatErrors from "../../../utils/form";
import FormContainer from "../../../components/FormContainer";
import ModalDialog from "../../../components/ModalDialog";
import notificationSuccess from "../../../utils/notification";

const { RangePicker } = DatePicker;

const FormComponent = ({ data }) => {
  const history = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [formError, setFormError] = useState();
  const [itemErrors, setItemErrors] = useState({});
  const [updateMessage, { loading: updateLoading }] =
    useMutation(UPDATE_MESSAGE);
  const [insertMessage, { loading: insertLoading }] = useMutation(
    INSERT_MESSAGE,
    {
      update(cache, { data: addMessage }) {
        const data = cache.readQuery({ query: QUERY_MESSAGES });
        if (data) {
          cache.writeQuery({
            query: QUERY_MESSAGES,
            data: { messages: [addMessage.insertMessage, ...data.messages] },
          });
        }

      },
    }
  );

  const continueEditing = (_id) => _id && history(`/message/${_id}`);

  const onFinish = async (values) => {
    try {
      clearErrors();
      const mutated = id
        ? await updateMessage({ variables: { id: id, messageInput: values } })
        : await insertMessage({ variables: values });
      // ModalDialog.success({
      //   title: "Success",
      //   content: `The message has been ${id ? "updated" : "added"}.`,
      //   okText: "Return to listing",
      //   onOk: () => history("/messages"),
      //   cancelText: "Continue editing",
      //   onCancel: () => continueEditing(mutated.data.insertMessage?.id),
      // })
      notificationSuccess(`The message has been ${mutated ? 'updated' : 'added'}.`);
    } catch (e) {
      setErrors(formatErrors(e));
    }
  };

  const setErrors = (errors) =>
    errors.itemErrors
      ? setItemErrors(errors.itemErrors)
      : setFormError(errors.formError);

  const clearErrors = () => {
    setFormError();
    setItemErrors({});
  };

  return (
    <>
      {data && (
        <Form
          form={form}
          id="messageForm"
          name="messageForm"
          onFinish={onFinish}
          preserve={false}
          initialValues={data.message}
          labelCol={{ sm: 8, md: 5, lg: 6, xl: 4, xxl: 3 }}
          wrapperCol={{ sm: 10, md: 7, lg: 8, xl: 6, xxl: 4 }}
        >
          <Form.Item
            label="Subject"
            name="subject"
            hasFeedback
            validateStatus={itemErrors.subject ? "error" : ""}
            help={itemErrors.subject}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Message"
            name="message"
            hasFeedback
            validateStatus={itemErrors.message ? "error" : ""}
            help={itemErrors.message}
          >
            <Input.TextArea />
          </Form.Item>

          <Form.Item
            label="Date"
            name="date"
            hasFeedback
            validateStatus={itemErrors.date ? "error" : ""}
            help={itemErrors.date}
          >
            <RangePicker />
          </Form.Item>

          <FormItemsSuperAdmin data={data.message} hasHidden />

          <FormItemError error={formError} />

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={insertLoading || updateLoading}
            >
              {id ? "Update" : "Add"}
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
};

FormComponent.propTypes = {
  data: PropTypes.object.isRequired,
};

const MessageForm = () => {
  const { id } = useParams();

  const { data, loading } = useQuery(QUERY_MESSAGE, {
    variables: { id },
    skip: !id,
  });
  const initialValues = { message: { email: "" } };

  return (
    <FormContainer
      loading={loading}
      size={2}
      form={<FormComponent data={data || initialValues} />}
    />
  );
};

export default MessageForm;
