// react
import React, { useEffect, useContext } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

// ant design
import { Modal } from "antd";

// contexts
import ApolloContext from "../../utils/apolloProvider";

// components
import Page from "../../components/Page";

// apollo & operations
import { QUERY_USER } from "../../operations/queries/users";
import { UPDATE_USER } from "../../operations/mutations/users";

// query params
const useQuery = () => new URLSearchParams(useLocation().search);

const UserDelete = (props) => {
  const apollo = useContext(ApolloContext);
  const history = useNavigate();
  const { id } = useParams();
  const query = useQuery();
  const breadcrumb = [
    {
      path: "/",
      breadcrumbName: "Home",
    },
    {
      path: "/users",
      breadcrumbName: "Users",
    },
    {
      path: "",
      breadcrumbName: "Delete user",
    },
  ];

  const deleteUser = async () => {
    try {
      const response = await apollo.client.query({
        query: QUERY_USER,
        variables: { id },
      });
      const user = { ...response.data.user };
      // @todo should only update hidden
      await apollo.client.mutate({
        mutation: UPDATE_USER,
        variables: { id: id, userInput: { email: user.email, hidden: true } },
      });
      Modal.success({
        title: "Success",
        content: "The user has been hidden.",
        okText: "Return to listing",
        onOk: () => history.replace("/users"),
      });
    } catch (e) {
      Modal.error({
        title: "An error has occurred",
        content: "The user has not been hidden.",
        okText: "Return to listing",
        onOk: () => history.replace("/users"),
      });
    }
  };

  useEffect(() => {
    if (query.get("confirm") === "1") {
      deleteUser();
    }
  }, []);

  return (
    <>
      <Page title="Delete user" breadcrumb={breadcrumb} />
    </>
  );
};

export default UserDelete;
