import { gql } from "@apollo/client";

export const PRINT_FORM3457J = gql`
  mutation printStudentForm3457J(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $studentDob: String
    $parent1: String
    $parent2: String
    $dateProvidedToParent: String
    $parentInitials: String
    $formAction: String
$formStatus: String
  ) {
    printStudentForm3457J(
      studentForm3457JInput: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        studentDob: $studentDob
        parent1: $parent1
        parent2: $parent2
        dateProvidedToParent: $dateProvidedToParent
        parentInitials: $parentInitials
formAction: $formAction
formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;

