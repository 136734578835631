import { gql } from "@apollo/client";

export const PRINT_FORMTAMES6 = gql`
  mutation printStudentFormTames6(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $studentDob: String
    $studentAttendedConference: Boolean
    $parentName: String
    $parentAttended: Boolean
    $leaName: String
    $leaAttended: Boolean
    $specedName: String
    $specedAttended: Boolean
    $formAction: String
$formStatus: String
    $otherParticipants: [StudentFormTames6OtherParticipantsInput]
  ) {
    printStudentFormTames6(
      studentFormTames6Input: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        studentDob: $studentDob
        studentAttendedConference: $studentAttendedConference
        parentName: $parentName
        parentAttended: $parentAttended
        leaName: $leaName
        leaAttended: $leaAttended
        specedName: $specedName
        specedAttended: $specedAttended
        otherParticipants: $otherParticipants
formAction: $formAction
formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;
