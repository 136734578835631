import React from "react";
import PropTypes from "prop-types";

// apollo & operations
import { useQuery } from "@apollo/client";
import { QUERY_FORMS } from "../../../operations/queries/baseForms";


// ant design
import { Form, Select } from "antd";

const FormItemSelectForm = ({
  label,
  name,
  validateStatus,
  help,
  hasFeedback,
  mode,
}) => {

  const { data, loading } = useQuery(QUERY_FORMS);
  console.log("forms SelectForm-baseForms:", data);
  
  return (
    <Form.Item
      label={label}
      name={name}
      validateStatus={validateStatus}
      help={help}
      hasFeedback={hasFeedback}
    >
      <Select
        mode={mode}
        loading={loading}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) =>
          optionA.children
            .toString().toLowerCase()
            .localeCompare(optionB.children.toString().toLowerCase())
        }
      >
        {data?.baseForms &&
          data.baseForms.map((form) => (
            <Select.Option key={form.formCode} value={form.formCode}>
              {form.name.toString().toLowerCase().replace(/\b\w/g, s => s.toUpperCase()) + " (" + form.formCode + ")"} 
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  );
};

FormItemSelectForm.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  help: PropTypes.help,
  validateStatus: PropTypes.string,
  hasFeedback: PropTypes.bool,
  mode: PropTypes.string,
};

export default FormItemSelectForm;
