import { gql } from "@apollo/client";

export const PRINT_FORMTAMES1 = gql`
  mutation printStudentFormTames1(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $teamFormationDate: String
    $teamInitialMeetingDate: String
    $studentFullName: String
    $studentDob: String
    $teamMemberName: [String]
    $teamMemberTitle: [String]
    $formAction: String
$formStatus: String
  ) {
    printStudentFormTames1(
      studentFormTames1Input: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        teamFormationDate: $teamFormationDate
        teamInitialMeetingDate: $teamInitialMeetingDate
        studentFullName: $studentFullName
        studentDob: $studentDob
        teamMemberName: $teamMemberName
        teamMemberTitle: $teamMemberTitle
formAction: $formAction
formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;
