import React from "react";

import Page from "../../components/Page";
import UserList from "../../components/sections/user/UserList";

const Users = () => {
  const breadcrumb = [
    {
      path: "/",
      breadcrumbName: "Home",
    },
    {
      path: "",
      breadcrumbName: "Users",
    },
  ];
  return (
    <>
      <Page title="Users" breadcrumb={breadcrumb}>
        <UserList />
      </Page>
    </>
  );
};

export default Users;
