import React, { useContext } from 'react'
import PropTypes from 'prop-types'

// contexts
import AuthContext from '../../../utils/authProvider'

// ant design
import { Form, Switch, Divider, Row, Col } from 'antd'

const FormItemsSuperAdmin = ({
  data,
  hasHidden,
  itemsCreating,
  itemsUpdating
}) => {
  const Auth = useContext(AuthContext)
  const isSuperAdmin = Auth.hasRole('SUPER_ADMIN')
  return (
    <>
      {isSuperAdmin && (
        <>
          <Divider plain>Super admin</Divider>
          {data.id && (
            <>
              <Form.Item
                className='form-item-text'
                label='Created by'
                wrapperCol={{ sm: 20 }}
              >
                <span className='ant-form-text'>{data.createdBy}</span>
              </Form.Item>
              <Form.Item
                className='form-item-text'
                label='Created at'
                wrapperCol={{ sm: 20 }}
              >
                <span className='ant-form-text'>
                  {data.createdAt} ({data.createdAtAgo})
                </span>
              </Form.Item>
              <Form.Item
                className='form-item-text'
                label='Updated at'
                wrapperCol={{ sm: 20 }}
              >
                <span className='ant-form-text'>
                  {data.updatedAt} ({data.updatedAtAgo})
                </span>
              </Form.Item>
              {itemsCreating && itemsCreating}
            </>
          )}
          {data.id && itemsUpdating}
          {hasHidden && (
            <Form.Item
              className='form-item-text'
              label='Hidden'
              name='hidden'
              valuePropName='checked'
              wrapperCol={{ sm: 20 }}
            >
              {/* <Row> */}
              {/* <Col span={12}>Hidden:</Col>
                <Col span={12}> */}
              <Switch />
              {/* </Col> */}
              {/* </Row> */}
            </Form.Item>
          )}
        </>
      )}
    </>
  )
}

FormItemsSuperAdmin.propTypes = {
  data: PropTypes.object.isRequired,
  hasHidden: PropTypes.bool,
  itemsCreating: PropTypes.element,
  itemsUpdating: PropTypes.element
}

export default FormItemsSuperAdmin
