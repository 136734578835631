import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Spin, List, Popconfirm, Card, Col, Row, Tooltip, Divider } from 'antd'
import { IepFormContext } from '../../../utils/iepFormProvider'
import AuthContext from '../../../utils/authProvider'
import { useParams } from 'react-router-dom'

// apollo & operations
import { useMutation, useQuery } from '@apollo/client'
import { QUERY_STUDENT_MEETING_FORMS } from '../../../operations/queries/meeting'
import { REMOVE_FORM_FROM_MEETING } from '../../../operations/mutations/meetings'
import { DeleteFilled } from '@ant-design/icons'
import {
  notificationSuccess,
  notificationInfo
} from '../../../utils/notification'
import notAvailableIcon from '../../../assets/icons/not-available.svg'
const MeetingForms = ({ setEditMode }) => {
  const { studentMeetingId } = useParams()
  const Auth = useContext(AuthContext)
  const { setStudentFormInfo, setStudentMeetingId, setMeetingDate } =
    useContext(IepFormContext)
  const fetchPolicy = 'network-only'
  const { data, loading } = useQuery(QUERY_STUDENT_MEETING_FORMS, {
    fetchPolicy,
    variables: { id: studentMeetingId }
  })
  const isSuperAdmin = Auth.hasRole('SUPER_ADMIN')
  const isDistrictAdmin = Auth.hasRole('DISTRICTS_ADMIN')
  const [removeFormFromMeeting, { loading: removeFormLoading }] = useMutation(
    REMOVE_FORM_FROM_MEETING,
    {
      refetchQueries: [
        {
          query: QUERY_STUDENT_MEETING_FORMS,
          variables: { id: studentMeetingId }
        }
      ]
    }
  )

  // Set Student Info for IEP Provider
  useEffect(() => {
    if (data && !loading) {
      setStudentFormInfo({
        studentFormId: data?.studentMeetingGeneral.studentFormId,
        studentId: data?.studentMeetingGeneral.studentId,
        schoolId: data?.studentMeetingGeneral.schoolId,
        districtId: data?.studentMeetingGeneral?.district?.id
      })
      setStudentMeetingId(studentMeetingId)

      //if has no forms display the sidebarEdit instead
      //if (!(data?.studentMeetingGeneral?.meetingForms.length > 0)) {
      //  setEditMode(true)
      //}

      if (data?.studentMeetingGeneral.meetingDate) {
        setMeetingDate(data?.studentMeetingGeneral.meetingDate)
      }
    }
  }, [data])
  const formatFormName = name => {
    if (name) {
      return (
        name.toString().toLowerCase().charAt(0).toUpperCase() +
        name.toString().toLowerCase().slice(1)
      )
    }
  }

  const DisplayUploadedDocs = () => {
    return (
      <>
        {data ? (
          <>
            {data?.studentMeetingGeneral.uploadedDocs.length > 0 && (
              <>
                {' '}
                <span>
                  <h4>Uploaded Documents:</h4>
                </span>
              </>
            )}

            {data?.studentMeetingGeneral.uploadedDocs.map(x => {
              return (
                <>
                  <Row>
                    <Col>
                      {/* <Popconfirm
                        title='Confirm Form Removal'
                        onConfirm={async () => {
                          console.log('to do meeting forms line 90')
                        }}
                        okText='Work In Progress'
                        cancelText='No'
                      >
                        <DeleteFilled style={{ color: 'red' }} fill='red' />
                      </Popconfirm>{' '} */}
                      {x.name}
                    </Col>
                  </Row>
                </>
              )
            })}
          </>
        ) : (
          <></>
        )}
      </>
    )
  }

  return (
    <div>
      <Card title='Current Meeting Forms'>
        {!data && (
          <div style={{ textAlign: 'center' }}>
            <Spin />
          </div>
        )}
        {data && (
          <>
            {data?.studentMeetingGeneral.meetingType === '504 Plan' ? (
              <>
                {' '}
                {data?.studentMeetingGeneral.meetingForms.map(formType => (
                  <div style={{ marginTop: '24px' }} key={formType.formTypeId}>
                    {/* {formType.display === 'IEP Forms' ? (
                      <>
                        <h4>Conference Forms:</h4>
                      </>
                    ) : ( */}
                    <>
                      <h4>{formType.display}:</h4>
                    </>
                    {/* // )} */}

                    <List
                      itemLayout='horizontal'
                      dataSource={formType.forms}
                      locale={{ emptyText: ' ' }}
                      className='meetings-list'
                      loading={removeFormLoading}
                      renderItem={item => (
                        <>
                          <List.Item
                            style={{
                              borderStyle: 'none',
                              marginLeft: '10px',
                              cursor: 'pointer'
                              // padding: '4px 0'
                            }}
                            className={
                              !item.userSubRoleFormPermission.view
                                ? 'disabled'
                                : ''
                            }
                          >
                            <span>
                              {item.name ===
                                'INDIVIDUALIZED EDUCATION PROGRAM (CONFERENCE SUMMARY REPORT)' ? (
                                <>Conference Summary Report</>
                              ) : (
                                <>
                                  {' '}
                                  {formatFormName(item.name)}
                                  {!item.userSubRoleFormPermission.view && (
                                    <img
                                      style={{
                                        marginLeft: '5px'
                                      }}
                                      src={notAvailableIcon}
                                      width={15}
                                      height={15}
                                    />
                                  )}
                                </>
                              )}
                            </span>
                            <span>
                              {item.formStatusIsDraft === true ? (
                                <>Draft</>
                              ) : (
                                <>Final</>
                              )}
                              {item?.formStatusEnumName === 'SPAWNED' ? (
                                <Popconfirm
                                  title='Confirm Form Removal'
                                  onConfirm={async () => {
                                    if (
                                      item.userSubRoleFormPermission.add ===
                                      true
                                    ) {
                                      await removeFormFromMeeting({
                                        variables: {
                                          studentMeetingId,
                                          studentFormId: item.studentFormId
                                        }
                                      })
                                      notificationSuccess(
                                        'Removed ' +
                                        item.name +
                                        ' from meeting',
                                        ''
                                      )
                                    }
                                    if (
                                      item.userSubRoleFormPermission.add ===
                                      false
                                    ) {
                                      notificationInfo(
                                        'You lack permission to remove ' +
                                        item.name +
                                        ' from meeting',
                                        ''
                                      )
                                    }
                                  }}
                                  okText='Yes'
                                  cancelText='No'
                                >
                                  {' '}
                                  <DeleteFilled
                                    style={{ color: 'red' }}
                                    fill='red'
                                  />
                                </Popconfirm>
                              ) : (
                                <>
                                  {' '}
                                  {!isSuperAdmin && !isDistrictAdmin ? (
                                    <Popconfirm
                                      okText='Ok'
                                      showCancel={false}
                                      title='Form has been saved. Please contact your administrator to remove this form.'
                                    >
                                      <DeleteFilled
                                        style={{ color: 'grey' }}
                                        fill='grey'
                                      />
                                    </Popconfirm>
                                  ) : (
                                    <Popconfirm
                                      title='Confirm Form Removal'
                                      onConfirm={async () => {
                                        if (
                                          item.userSubRoleFormPermission.add ===
                                          true
                                        ) {
                                          await removeFormFromMeeting({
                                            variables: {
                                              studentMeetingId,
                                              studentFormId: item.studentFormId
                                            }
                                          })
                                          notificationSuccess(
                                            'Removed ' +
                                            item.name +
                                            ' from meeting',
                                            ''
                                          )
                                        }
                                        if (
                                          item.userSubRoleFormPermission.add ===
                                          false
                                        ) {
                                          notificationInfo(
                                            'You lack permission to remove ' +
                                            item.name +
                                            ' from meeting',
                                            ''
                                          )
                                        }
                                      }}
                                      okText='Yes'
                                      cancelText='No'
                                    >
                                      {' '}
                                      <DeleteFilled
                                        style={{ color: 'red' }}
                                        fill='red'
                                      />
                                    </Popconfirm>
                                  )}
                                </>
                              )}
                            </span>
                          </List.Item>
                          <Divider style={{ padding: '0', margin: '0' }} />
                        </>
                      )}
                    />
                  </div>
                ))}
              </>
            ) : (
              <>
                {' '}
                {data?.studentMeetingGeneral.meetingForms.map(formType => (
                  <div style={{ marginTop: '24px' }} key={formType.formTypeId}>
                    <h4>{formType.display}:</h4>

                    <List
                      itemLayout='horizontal'
                      dataSource={formType.forms}
                      locale={{ emptyText: ' ' }}
                      className='meetings-list'
                      loading={removeFormLoading}
                      renderItem={item => (
                        <>
                          <List.Item
                            style={{
                              borderStyle: 'none',
                              marginLeft: '10px',
                              cursor: 'pointer'
                              // padding: '4px 0'
                            }}
                            className={
                              !item.userSubRoleFormPermission.view
                                ? 'disabled'
                                : ''
                            }
                          >
                            <span>
                              {formatFormName(item.name)}
                              {!item.userSubRoleFormPermission.view && (
                                <img
                                  style={{
                                    marginLeft: '5px'
                                  }}
                                  src={notAvailableIcon}
                                  width={15}
                                  height={15}
                                />
                              )}
                            </span>
                            <span>
                              {item.formStatusIsDraft === true ? (
                                <>Draft</>
                              ) : (
                                <>Final</>
                              )}
                              {item?.formStatusEnumName === 'SPAWNED' ? (
                                <>
                                  <Popconfirm
                                    title='Confirm Form Removal'
                                    onConfirm={async () => {
                                      if (
                                        item.userSubRoleFormPermission.add ===
                                        true
                                      ) {
                                        await removeFormFromMeeting({
                                          variables: {
                                            studentMeetingId,
                                            studentFormId: item.studentFormId
                                          }
                                        })
                                        notificationSuccess(
                                          'Removed ' +
                                          item.name +
                                          ' from meeting',
                                          ''
                                        )
                                      }
                                      if (
                                        item.userSubRoleFormPermission.add ===
                                        false
                                      ) {
                                        notificationInfo(
                                          'You lack permission to remove ' +
                                          item.name +
                                          ' from meeting',
                                          ''
                                        )
                                      }
                                    }}
                                    okText='Yes'
                                    cancelText='No'
                                  >
                                    {' '}
                                    <DeleteFilled
                                      style={{ color: 'red' }}
                                      fill='red'
                                    />
                                  </Popconfirm>
                                </>
                              ) : (
                                <>
                                  {' '}
                                  {!isSuperAdmin && !isDistrictAdmin ? (
                                    <>
                                      <Popconfirm
                                        okText='Ok'
                                        showCancel={false}
                                        title='Form has been saved. Please contact your administrator to remove this form.'
                                      >
                                        <DeleteFilled
                                          style={{ color: 'grey' }}
                                          fill='grey'
                                        />
                                      </Popconfirm>
                                    </>
                                  ) : (
                                    <>
                                      {' '}
                                      <Popconfirm
                                        title='Confirm Form Removal'
                                        onConfirm={async () => {
                                          if (
                                            item.userSubRoleFormPermission
                                              .add === true
                                          ) {
                                            await removeFormFromMeeting({
                                              variables: {
                                                studentMeetingId,
                                                studentFormId:
                                                  item.studentFormId
                                              }
                                            })
                                            notificationSuccess(
                                              'Removed ' +
                                              item.name +
                                              ' from meeting',
                                              ''
                                            )
                                          }
                                          if (
                                            item.userSubRoleFormPermission
                                              .add === false
                                          ) {
                                            notificationInfo(
                                              'You lack permission to remove ' +
                                              item.name +
                                              ' from meeting',
                                              ''
                                            )
                                          }
                                        }}
                                        okText='Yes'
                                        cancelText='No'
                                      >
                                        {' '}
                                        <DeleteFilled
                                          style={{ color: 'red' }}
                                          fill='red'
                                        />
                                      </Popconfirm>
                                    </>
                                  )}
                                </>
                              )}
                            </span>
                          </List.Item>
                          <Divider style={{ padding: '0', margin: '0' }} />
                        </>
                      )}
                    />
                  </div>
                ))}
              </>
            )}
          </>
        )}
        <DisplayUploadedDocs />
      </Card>
    </div>
  )
}

MeetingForms.propTypes = {
  setEditMode: PropTypes.func
}

export default MeetingForms
