// react
import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'

// contexts
import AuthContext from '../../../utils/authProvider'

// ant design
import { Card, Button, Tooltip, Tag } from 'antd'

// components
import DistrictSearchForm from './DistrictSearchForm'
import Listing from '../../Listing'

// operations
import { QUERY_DISTRICTS } from '../../../operations/queries/districts'

const DistrictList = () => {
  const Auth = useContext(AuthContext)
  const isSuperAdmin = Auth.hasRole('SUPER_ADMIN')
  const canEdit = Auth.hasPermission('EDIT_DISTRICTS')
  const canAdd = Auth.hasPermission('ADD_DISTRICTS')
  const isSchoolsGeneral = Auth.hasRole('SCHOOLS_GENERAL')
  const [criterias, setCriterias] = useState({})

  const extra = (
    <>
      {canAdd && (
        <Tooltip title='Add new district'>
          <Link to='../district/add'>
            <Button type='primary'>Add District</Button>
          </Link>
        </Tooltip>
      )}
    </>
  )

  const onSearch = criterias => {
    setCriterias(criterias)
  }

  const getName = record =>
    isSchoolsGeneral ? record.name : record.nameGeneral

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (value, record) => (
        <div>
          <div>
            {canEdit ? (
              <Link to={`../district/${record.id}`}>{getName(record)}</Link>
            ) : (
              <>{getName(record)}</>
            )}
            &nbsp;
            {isSuperAdmin && record.hidden && <Tag color='orange'>hidden</Tag>}
          </div>
          {isSuperAdmin && <div className='cellWithId'>{record.id}</div>}
        </div>
      )
    }
  ]

  return (
    <>
      <Listing
        query={QUERY_DISTRICTS}
        columns={columns}
        dataKey='districts'
        criterias={criterias}
        render={table => {
          return (
            <>
              <div className='page-container'>
                <Card title='Search' bordered={false}>
                  <DistrictSearchForm onSearch={onSearch} />
                </Card>
              </div>
              <div className='page-container'>
                <Card title='Districts' bordered={false} extra={extra}>
                  {table}
                </Card>
              </div>
            </>
          )
        }}
      />
    </>
  )
}

export default DistrictList
