// react
import React, { useContext, useState } from 'react'
import { Layout, Row, Col } from 'antd'
// context
import AuthContext from '../../utils/authProvider'
import AppContext from '../../utils/appProvider'

// components
import Header from './Header'
import SideBar from './SideBar'
import AdminSideMenu from './AdminSideMenu'
import NavigationPrompt from './NavigationPrompt'

// routes
import Router from '../Routes'

const { Content, Footer } = Layout

const SuperAdminLayout = () => {
  const Auth = useContext(AuthContext)
  const isSuperAdmin = Auth.hasRole('SUPER_ADMIN')
  const isDistrictAdmin = Auth.hasRole('DISTRICTS_ADMIN')
  const isSchoolsAdmin = Auth.hasRole('SCHOOLS_ADMIN')
  const [collapsed, setCollapsed] = useState(false)
  const app = useContext(AppContext)

  const onClose = () => {
    setCollapsed(false)
  }

  const toggle = () => {
    setCollapsed(!collapsed)
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <NavigationPrompt saved={false} />
      <Header toggle={toggle} collapsed={collapsed} />

      <SideBar
        menu={<AdminSideMenu />}
        theme='light'
        onClose={onClose}
        collapsed={collapsed}
        showSider={isSuperAdmin || isDistrictAdmin || isSchoolsAdmin}
      />
      <Layout className='content-layout'>
        <Content>
          <Layout>
            <Router />
          </Layout>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          <Row justify='center'>
            <Col>
              <a href='http://tameshrs.com/' target='_blank'>
                © {new Date().getFullYear()} TAMES/Health Resource Service
                Management, Inc.
              </a>
            </Col>
          </Row>
        </Footer>
      </Layout>
    </Layout>
  )
}

export default SuperAdminLayout
