import { gql } from "@apollo/client";

export const STUDENT_INFO_FORM3457A = gql`
  query student($id: ID!) {
    student(id: $id) {
      fullName
      dateOfBirth
      parent1FullName
      parent2FullName
    }
  }
`;
export const QUERY_FORM3457A_VERSION = gql`
  query studentForm3457A(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
  ) {
    studentForm3457A(
      studentFormId: $studentFormId
      studentId: $studentId
      formId: $formId
      schoolId: $schoolId
      studentMeetingId: $studentMeetingId
    ) {
      versionId
      formId
      formDate
      referrerName
      referrerTitle
      evaluationReasonDescription
      studentFullName
      studentDob
      parent1
      parent2
      evaluationRequestDate
      evaluationDecisionDescription
      evaluationStatus
      reEvaluationStatus
      documentContactName
      documentContactTitle
      documentContactPhone
      signatureName
      signatureTitle
      parentCopy
      formAction
      formStatus
    }
  }
`;
