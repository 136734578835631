import { gql } from '@apollo/client'

export const QUERY_ME = gql`
  query {
    me {
      id
      email
      currentDistrictId
      currentSchoolId
      userAdminDistricts {
        id
        name
      }
      userAdminSchools {
        id
        name
      }
      userGeneralSchools {
        id
        name
      }
      permissions {
        SUPER_ADMIN
        DISTRICTS_ADMIN
        SCHOOLS_ADMIN
        SCHOOLS_GENERAL
      }
      desktopStudents {
        fullName
        grade
        schools {
          name
        }
        studentMeetingsGeneral {
          school {
            name
          }
          district {
            id
            name
          }
        }
      }
    }
  }
`

export const QUERY_DESKTOP = gql`
  query {
    me {
      desktopStudents {
        id
        fullName
        nextAnnualReviewDate
        reEvaluationConsentDate
        schools {
          id
          name
          districtId
        }
        grade
        currentIepMeetingId
        studentMeetingsGeneral {
          id
          schoolId
          studentId
          meetingDate
          meetingType
          isActiveIep
          iepMeetingId
          studentFormIds
          createdAt
          district {
            id
            name
          }
          uploadedDocs {
            id
            docDate
            name
            contentType
            uniqueName
            url
          }
          studentMeetingFormTypes {
            formType {
              display
              enumName
            }
            studentMeetings {
              id
              schoolId
              studentId
              meetingDate
            }
          }
        }
      }
    }
  }
`
