import { gql } from "@apollo/client";

export const QUERY_FORM3454T_VERSION = gql`
  query studentForm3454T(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
  ) {
    studentForm3454T(
      studentFormId: $studentFormId
      studentId: $studentId
      formId: $formId
      schoolId: $schoolId
      studentMeetingId: $studentMeetingId
    ) {
      versionId
      formId
      formDate
      studentFullName
      staffName
      title
      typeOfReport
      reportCard
      progressReport
      parentReport
      formAction
      formStatus
      annualGoals {
        goalId
        goalNumber
        measurableAnnualGoal
        completed
        makingExpectedProgress
        notMakingExpectedProgress
        additionalComments
      }
    }
  }
`;
