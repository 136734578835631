import { Empty, Card } from 'antd'
import React, { useContext, useEffect } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { useMutation, useLazyQuery, useQuery } from '@apollo/client'
import { QUERY_STUDENT } from '../../../operations/queries/students'
import PropTypes from 'prop-types'

import { FormCodes } from '../../../utils/formCodes'
import { IepFormContext } from '../../../utils/iepFormProvider'

import StudentForm3457A from './forms/StudentForm3457A'
import StudentForm3457B from './forms/StudentForm3457B'
import StudentForm3457C from './forms/StudentForm3457C'
import StudentForm3457BC from './forms/StudentForm3457BC'
import StudentForm3457D from './forms/StudentForm3457D'
import StudentForm3457G from './forms/StudentForm3457G'
import StudentForm3457F from './forms/StudentForm3457F'
import StudentForm3457I from './forms/StudentForm3457I'
import StudentForm3457J from './forms/StudentForm3457J'
import StudentForm3457K from './forms/StudentForm3457K'
import StudentForm3457L from './forms/StudentForm3457L'
import StudentForm3457N from './forms/StudentForm3457N'
import StudentForm3457O from './forms/StudentForm3457O'
import StudentForm3457M from './forms/StudentForm3457M'
import StudentForm3454D from './forms/StudentForm3454D'
import StudentForm3457E from './forms/StudentForm3457E'
import StudentForm3454H from './forms/StudentForm3454H'
import StudentForm3454J from './forms/StudentForm3454J'
import StudentForm3454Q from './forms/StudentForm3454Q'
import StudentForm3454K from './forms/StudentForm3454K'
import StudentForm3454N from './forms/StudentForm3454N'
import StudentForm3454T from './forms/StudentForm3454T'
import StudentForm3454R from './forms/StudentForm3454R'
import StudentForm3454U from './forms/StudentForm3454U'
import StudentForm3454 from './forms/StudentForm3454'
import StudentForm3454A from './forms/StudentForm3454A'
import StudentForm3454G from './forms/StudentForm3454G'
import StudentForm3454S from './forms/StudentForm3454S'
import StudentForm3457H from './forms/StudentForm3457H'
import StudentForm3454I from './forms/StudentForm3454I'
import StudentForm3454C from './forms/StudentForm3454C'
import StudentForm3454E from './forms/StudentForm3454E'
import StudentForm3454L from './forms/StudentForm3454L'
import StudentForm3454F from './forms/StudentForm3454F'
import StudentForm3454O from './forms/StudentForm3454O'
import StudentForm3454V from './forms/StudentForm3454V'
import StudentForm3454M from './forms/StudentForm3454M'
import StudentForm3454P from './forms/StudentForm3454P'
import StudentForm3454B from './forms/StudentForm3454B'
import StudentFormTames4 from './forms/StudentFormTames4'
import StudentFormTames5 from './forms/StudentFormTames5'
import StudentFormTames6 from './forms/StudentFormTames6'
import StudentFormTames1 from './forms/StudentFormTames1'
import StudentFormTames2 from './forms/StudentFormTames2'
import StudentFormTames3 from './forms/StudentFormTames3'
import StudentFormTames7 from './forms/StudentFormTames7'
import StudentForm504A from './forms/StudentForm504A'
import StudentForm504B from './forms/StudentForm504B'
import StudentForm504C from './forms/StudentForm504C'
import StudentForm504D from './forms/StudentForm504D'
import StudentFormHealthPlan from './forms/StudentFormHealthPlan'
import MeetingSISForm from './MeetingSISForm'
import FormKeyDates from '../../elements/FormKeyDates'
import FormWarning from '../../elements/FormWarning'
import MeetingSISProvider from '../../../utils/meetingSISProvider'
import MeetingForms from './MeetingForms'
import EditMeetingInfo from './EditMeetingInfo'
import RenderPdf from '../../elements/RenderPdf'
import IAP from './forms/StudentFormIAP'
const StudentFormEditArea = ({ editMode, editMeeting }) => {
  const { currentStudentInfo, currentEditableForm } = useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, formCode, pdfData, meetingType } =
    currentEditableForm
  const commonFormProps = {
    studentFormId,
    studentId,
    formId,
    schoolId,
    pdfData,
    meetingType
  }
  const { id } = useParams()
  const [getStudentData, { data: data, loadingStudent }] = useLazyQuery(
    QUERY_STUDENT,
    {
      variables: { id: studentId },
      skip: !id,
      nextFetchPolicy: 'cache-first' //fixes infinite render bug
    }
  )
  useEffect(() => {
    getStudentData()
  }, [])
  const renderCurrentForm = () => {
    let form = null
    switch (formCode) {
      case FormCodes.FORM_SIS:
        form = <MeetingSISForm {...commonFormProps} {...data} />
        break
      case FormCodes.FORM_3457A:
        form = <StudentForm3457A {...commonFormProps} />
        break

      case FormCodes.FORM_3457B:
        form = <StudentForm3457B {...commonFormProps} />
        break

      case FormCodes.FORM_3457C:
        form = <StudentForm3457C {...commonFormProps} />
        break

      case FormCodes.FORM_3457BC:
        form = <StudentForm3457BC {...commonFormProps} />
        break

      case FormCodes.FORM_3457D:
        form = <StudentForm3457D {...commonFormProps} />
        break

      case FormCodes.FORM_3457E:
        form = <StudentForm3457E {...commonFormProps} />
        break

      case FormCodes.FORM_3457F:
        form = <StudentForm3457F {...commonFormProps} />
        break

      case FormCodes.FORM_3457G:
        form = <StudentForm3457G {...commonFormProps} />
        break

      case FormCodes.FORM_3457I:
        form = <StudentForm3457I {...commonFormProps} />
        break

      case FormCodes.FORM_3457J:
        form = <StudentForm3457J {...commonFormProps} />
        break

      case FormCodes.FORM_3457K:
        form = <StudentForm3457K {...commonFormProps} />
        break

      case FormCodes.FORM_3457L:
        form = <StudentForm3457L {...commonFormProps} />
        break

      case FormCodes.FORM_3457N:
        form = <StudentForm3457N {...commonFormProps} />
        break

      case FormCodes.FORM_3457O:
        form = <StudentForm3457O {...commonFormProps} />
        break

      case FormCodes.FORM_3457M:
        form = <StudentForm3457M {...commonFormProps} />
        break

      case FormCodes.FORM_3454A:
        form = <StudentForm3454A {...commonFormProps} />
        break

      case FormCodes.FORM_3454D:
        form = <StudentForm3454D {...commonFormProps} />
        break

      case FormCodes.FORM_3454J:
        form = <StudentForm3454J {...commonFormProps} />
        break

      case FormCodes.FORM_3454K:
        form = <StudentForm3454K {...commonFormProps} />
        break

      case FormCodes.FORM_3454H:
        form = <StudentForm3454H {...commonFormProps} />
        break

      case FormCodes.FORM_3454N:
        form = <StudentForm3454N {...commonFormProps} />
        break

      case FormCodes.FORM_3454Q:
        form = <StudentForm3454Q {...commonFormProps} />
        break

      case FormCodes.FORM_3454T:
        form = <StudentForm3454T {...commonFormProps} />
        break

      case FormCodes.FORM_3454R:
        form = <StudentForm3454R {...commonFormProps} />
        break

      case FormCodes.FORM_3454U:
        form = <StudentForm3454U {...commonFormProps} />
        break

      case FormCodes.FORM_3454:
        form = <StudentForm3454 {...commonFormProps} />
        break

      case FormCodes.FORM_3454S:
        form = <StudentForm3454S {...commonFormProps} />
        break

      case FormCodes.FORM_3457H:
        form = <StudentForm3457H {...commonFormProps} />
        break

      case FormCodes.FORM_3454I:
        form = <StudentForm3454I {...commonFormProps} />
        break

      case FormCodes.FORM_3454G:
        form = <StudentForm3454G {...commonFormProps} />
        break

      case FormCodes.FORM_3454C:
        form = <StudentForm3454C {...commonFormProps} />
        break

      case FormCodes.FORM_3454E:
        form = <StudentForm3454E {...commonFormProps} />
        break

      case FormCodes.FORM_3454L:
        form = <StudentForm3454L {...commonFormProps} />
        break

      case FormCodes.FORM_3454F:
        form = <StudentForm3454F {...commonFormProps} />
        break

      case FormCodes.FORM_3454O:
        form = <StudentForm3454O {...commonFormProps} />
        break

      case FormCodes.FORM_3454V:
        form = <StudentForm3454V {...commonFormProps} />
        break

      case FormCodes.FORM_3454M:
        form = <StudentForm3454M {...commonFormProps} />
        break

      case FormCodes.FORM_3454P:
        form = <StudentForm3454P {...commonFormProps} />
        break

      case FormCodes.FORM_3454B:
        form = <StudentForm3454B {...commonFormProps} />
        break

      case FormCodes.FORM_TAMES4:
        form = <StudentFormTames4 {...commonFormProps} />
        break

      case FormCodes.FORM_TAMES5:
        form = <StudentFormTames5 {...commonFormProps} />
        break

      case FormCodes.FORM_TAMES6:
        form = <StudentFormTames6 {...commonFormProps} />
        break

      case FormCodes.FORM_TAMES1:
        form = <StudentFormTames1 {...commonFormProps} />
        break

      case FormCodes.FORM_TAMES2:
        form = <StudentFormTames2 {...commonFormProps} />
        break

      case FormCodes.FORM_TAMES3:
        form = <StudentFormTames3 {...commonFormProps} />
        break
      case FormCodes.FORM_504A:
        form = <StudentForm504A {...commonFormProps} />
        break
      case FormCodes.FORM_504B:
        form = <StudentForm504B {...commonFormProps} />
        break
      case FormCodes.FORM_504C:
        form = <StudentForm504C {...commonFormProps} />
        break
      case FormCodes.FORM_504D:
        form = <StudentForm504D {...commonFormProps} />
        break
      case 'imported':
        form = <RenderPdf {...commonFormProps} />
        break

      case FormCodes.FORM_TAMES7:
        form = <StudentFormTames7 {...commonFormProps} />
        break
      case FormCodes.FORM_HEALTHPLAN:
        form = <StudentFormHealthPlan {...commonFormProps} />
        break
      case FormCodes.IAP:
        form = <IAP {...commonFormProps} />
        break

      default:
        form = <>not found</>
        break
    }
    const output = []
    // key make sure these alerts re-render on content change
    output.push(<FormWarning key={`warning-${formCode}`} />)
    output.push(<FormKeyDates key={`keyDate-${formCode}`} />)
    output.push(form) //Renders the form
    return output
  }

  if (!formId && !formCode) {
    return (
      <>
        {editMode ? (
          <>
            <MeetingForms />
          </>
        ) : (
          <></>
        )}
        {editMeeting ? (
          <>
            <EditMeetingInfo studentData={data} />
          </>
        ) : (
          <></>
        )}
        {editMeeting === false && editMode === false ? (
          <>
            {' '}
            <Card title='Select a form to start'>
              <Empty
                description={<></>}
                style={{
                  padding: '120px 0px'
                }}
              />{' '}
            </Card>
          </>
        ) : (
          <></>
        )}
      </>
    )
  }

  if (!formCode || !formId) {
    return <p>Invalid Form</p>
  }

  return (
    <>
      {editMeeting ? (
        <>
          <EditMeetingInfo studentData={data} />
        </>
      ) : (
        <>
          {' '}
          <MeetingSISProvider>{renderCurrentForm(formCode)}</MeetingSISProvider>
        </>
      )}
    </>
  )
}

StudentFormEditArea.propTypes = {
  editMode: PropTypes.bool
}

export default StudentFormEditArea
