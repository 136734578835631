import { gql } from "@apollo/client";

export const QUERY_FORM3457O_VERSION = gql`
  query studentForm3457O(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
  ) {
    studentForm3457O(
      studentFormId: $studentFormId
      studentId: $studentId
      formId: $formId
      schoolId: $schoolId
      studentMeetingId: $studentMeetingId
    ) {
      versionId
      formId
      formDate
      studentFullName
      studentDob
      parent1
      parent2
      servicesNotImplemented {
        serviceNotImplementedDateRequiredToInitiate
        serviceNotImplementedProviderNameAndTitle
        serviceNotImplemented
      }
      explanationOfCompensatoryServicesProcedure
      nonComplianceContactName
      nonComplianceContactTitle
      nonComplianceContactPhone
      districtRepresentativeName
      districtRepresentativeTitle
      formAction
      formStatus
    }
  }
`;
