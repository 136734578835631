import { gql } from "@apollo/client";

export const PRINT_FORM3454F = gql`
  mutation printStudentForm3454F(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $reportOfPerformanceChart: String
    $reportOfPerformanceChart2: String
    $formAction: String
$formStatus: String

  ) {
    printStudentForm3454F(
      studentForm3454FInput: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        reportOfPerformanceChart: $reportOfPerformanceChart
        reportOfPerformanceChart2: $reportOfPerformanceChart2

formAction: $formAction
formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;
