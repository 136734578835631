import { gql } from "@apollo/client";

export const PRINT_FORMHealthPlan = gql`
  mutation printStudentFormHealthPlan(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $studentDob: String
    $studentGrade: String
    $startDate: String
    $endDate: String
    $service: String
    $disorder: [String]
    $medicalNecessity: String
    $diagnosed: String
    $doctorName: String
    $dateOfDiagnosis: String
    $officialDiagnosis: String
    $minutes: String
    $frequency: String
    $location: String
    $behavior: [String]
    $behaviorNotes: String
    $goal: [String]
    $dismissed: [String]
    $goalNotes: String
    $formAction: String
    $formStatus: String
  ) {
    printStudentFormHealthPlan(
      studentFormHealthPlanInput: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        studentDob: $studentDob
        studentGrade: $studentGrade
        startDate: $startDate
        endDate: $endDate
        service: $service
        disorder: $disorder
        medicalNecessity: $medicalNecessity
        diagnosed: $diagnosed
        doctorName: $doctorName
        dateOfDiagnosis: $dateOfDiagnosis
        officialDiagnosis: $officialDiagnosis
        minutes: $minutes
        frequency: $frequency
        location: $location
        behavior: $behavior
        behaviorNotes: $behaviorNotes
        goal: $goal
        dismissed: $dismissed
        goalNotes: $goalNotes
        formAction: $formAction
        formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;

