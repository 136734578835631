// react
import React, { useEffect, useContext } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

// ant design
import { Modal } from "antd";

// contexts
import ApolloContext from "../../utils/apolloProvider";

// components
import Page from "../../components/Page";

// apollo & operations
import { QUERY_ROLE } from "../../operations/queries/roles";
import { UPDATE_ROLE } from "../../operations/mutations/roles";

// query params
const useQuery = () => new URLSearchParams(useLocation().search);

const RoleDelete = (props) => {
  const apollo = useContext(ApolloContext);
  const history = useNavigate();
  const { id } = useParams();
  const query = useQuery();
  const breadcrumb = [
    {
      path: "/",
      breadcrumbName: "Home",
    },
    {
      path: "/roles",
      breadcrumbName: "Roles",
    },
    {
      path: "",
      breadcrumbName: "Delete role",
    },
  ];

  const deleteRole = async () => {
    try {
      const response = await apollo.client.query({
        query: QUERY_ROLE,
        variables: { id },
      });
      const role = { ...response.data.role };
      // @todo should only update hidden
      await apollo.client.mutate({
        mutation: UPDATE_ROLE,
        variables: { id: id, roleInput: { email: role.email, hidden: true } },
      });
      Modal.success({
        title: "Success",
        content: "The role has been hidden.",
        okText: "Return to listing",
        onOk: () => history.replace("/roles"),
      });
    } catch (e) {
      Modal.error({
        title: "An error has occurred",
        content: "The role has not been hidden.",
        okText: "Return to listing",
        onOk: () => history.replace("/roles"),
      });
    }
  };

  useEffect(() => {
    if (query.get("confirm") === "1") {
      deleteRole();
    }
  }, []);

  return (
    <>
      <Page title="Delete role" breadcrumb={breadcrumb} />
    </>
  );
};

export default RoleDelete;
