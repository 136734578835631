import { gql } from "@apollo/client";

export const PRINT_FORM504B = gql`
  mutation printStudentForm504B(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $nextReviewDate: String

    $studentFullName: String
    $address: String
    $city: String
    $state: String
    $zip: String
    $country: String
    $studentDob: String
    $studentCode: String
    $studentLanguage: String
    $studentGrade: String
    $studentGender: String
    $studentEthnicity: String
    $highSchoolGraduationDate: String
    $placement: Boolean
    $disabilities: String
    $medicaidNumber: String
    $residentDistrict: String
    $residentSchool: String
    $servingDistrict: String
    $servingSchool: String
    $parent1: String
    $educationSurrogateParent1: Boolean
    $parent1Email: String
    $parent1PhoneNumber: String
    $parent1Address: String
    $parent1City: String
    $parent1State: String
    $parent1Zip: String
    $parent1PrimaryLanguage: String
    $parent2: String
    $educationSurrogateParent2: Boolean
    $parent2Email: String
    $parent2PhoneNumber: String
    $parent2Address: String
    $parent2City: String
    $parent2State: String
    $parent2Zip: String
    $parent2PrimaryLanguage: String
    $dateParentProvidedParentsRights: String
    $parentInitials: String
    $meetingAttendedParent1: String
    $meetingAttendedParent2: String
    $meetingAttendedStudent: String
    $meetingAttendedLEARepresentative: String
    $meetingAttendedGeneralEducationTeacher: String
    $meetingAttendedSpecialEducationTeacher: String
    $meetingAttendedSchoolPsychologist: String
    $meetingAttendedSchoolSocialWorker: String
    $meetingAttendedSpeechLanguagePathologist: String
    $meetingAttendedBilingualSpecialist: String
    $meetingAttendedInterpreter: String
    $meetingAttendedSchoolNurse: String
    $meetingAttendedOther: String
    $meetingAttendedOtherText: String
    $formAction: String
    $formStatus: String
  ) {
    printStudentForm504B(
      studentForm504BInput: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        nextReviewDate: $nextReviewDate
        studentFullName: $studentFullName
        address: $address
        city: $city
        state: $state
        zip: $zip
        country: $country
        studentDob: $studentDob
        studentCode: $studentCode
        studentLanguage: $studentLanguage
        studentGrade: $studentGrade
        studentGender: $studentGender
        studentEthnicity: $studentEthnicity
        highSchoolGraduationDate: $highSchoolGraduationDate       
        placement: $placement
        disabilities: $disabilities
        medicaidNumber: $medicaidNumber
        residentDistrict: $residentDistrict
        residentSchool: $residentSchool
        servingDistrict: $servingDistrict
        servingSchool: $servingSchool
        parent1: $parent1
        educationSurrogateParent1: $educationSurrogateParent1
        parent1Email: $parent1Email
        parent1PhoneNumber: $parent1PhoneNumber
        parent1Address: $parent1Address
        parent1City: $parent1City
        parent1State: $parent1State
        parent1Zip: $parent1Zip
        parent1PrimaryLanguage: $parent1PrimaryLanguage
        parent2: $parent2
        educationSurrogateParent2: $educationSurrogateParent2
        parent2Email: $parent2Email
        parent2PhoneNumber: $parent2PhoneNumber
        parent2Address: $parent2Address
        parent2City: $parent2City
        parent2State: $parent2State
        parent2Zip: $parent2Zip
        parent2PrimaryLanguage: $parent2PrimaryLanguage
        dateParentProvidedParentsRights: $dateParentProvidedParentsRights
        parentInitials: $parentInitials
        meetingAttendedParent1: $meetingAttendedParent1
        meetingAttendedParent2: $meetingAttendedParent2
        meetingAttendedStudent: $meetingAttendedStudent
        meetingAttendedLEARepresentative: $meetingAttendedLEARepresentative
        meetingAttendedGeneralEducationTeacher: $meetingAttendedGeneralEducationTeacher
        meetingAttendedSpecialEducationTeacher: $meetingAttendedSpecialEducationTeacher
        meetingAttendedSchoolPsychologist: $meetingAttendedSchoolPsychologist
        meetingAttendedSchoolSocialWorker: $meetingAttendedSchoolSocialWorker
        meetingAttendedSpeechLanguagePathologist: $meetingAttendedSpeechLanguagePathologist
        meetingAttendedBilingualSpecialist: $meetingAttendedBilingualSpecialist
        meetingAttendedInterpreter: $meetingAttendedInterpreter
        meetingAttendedSchoolNurse: $meetingAttendedSchoolNurse
        meetingAttendedOther: $meetingAttendedOther
        meetingAttendedOtherText: $meetingAttendedOtherText
        formAction: $formAction
        formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;
