import { gql } from "@apollo/client";

export const QUERY_FORMTAMES6_VERSION = gql`
  query studentFormTames6(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
  ) {
    studentFormTames6(
      studentFormId: $studentFormId
      studentId: $studentId
      formId: $formId
      schoolId: $schoolId
      studentMeetingId: $studentMeetingId
    ) {
      versionId
      formId
      formDate
      studentDob
      studentFullName
      studentAttendedConference
      parentName
      parentAttended
      leaName
      leaAttended
      specedName
      specedAttended
      formAction
      formStatus
      otherParticipants {
        otherParticipantName
        otherParticipantTitle
        otherParticipantAttended
      }
    }
  }
`;
