/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';

import Nav0 from './Nav0';
import Feature1 from './Feature1';
import Feature2 from './Feature2';
import Feature3 from './Feature3';
import Feature4 from './Feature4';
import Feature5 from './Feature5';

import Footer0 from './Footer0';

import {
  Nav00DataSource,
  Feature10DataSource,
  Feature20DataSource,
  Feature30DataSource,
  Feature40DataSource,
  Feature50DataSource,
  Footer00DataSource,
} from './data.source';
import './less/antMotionStyle.less';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
    };
  }



  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
  }
  render() {
    const children2 = [
      <Nav0
        id="Nav0_0"
        key="Nav0_0"
        dataSource={Nav00DataSource}
        isMobile={this.state.isMobile}
      />,
      <Feature1
        id="Feature1_0"
        key="Feature1_0"
        dataSource={Feature10DataSource}
        isMobile={this.state.isMobile}
      />,
      <Feature2
      id="Feature2_0"
      key="Feature2_0"
      dataSource={Feature20DataSource}
      isMobile={this.state.isMobile}
    />,
    <Feature4
    id="Feature4_0"
    key="Feature4_0"
    dataSource={Feature40DataSource}
    isMobile={this.state.isMobile}
  />,
    <Feature3
    id="Feature3_0"
    key="Feature3_0"
    dataSource={Feature30DataSource}
    isMobile={this.state.isMobile}
  />,      
  <Feature5
  id="Feature5_0"
  key="Feature5_0"
  dataSource={Feature50DataSource}
  isMobile={this.state.isMobile}
/>, 

      <Footer0
        id="Footer0_0"
        key="Footer0_0"
        dataSource={Footer00DataSource}
        isMobile={this.state.isMobile}
      />,
    ];
    const children = [
      <Nav0
        id="Nav0_0"
        key="Nav0_0"
        dataSource={Nav00DataSource}
        isMobile={this.state.isMobile}
      />,
      <Feature1
        id="Feature1_0"
        key="Feature1_0"
        dataSource={Feature10DataSource}
        isMobile={this.state.isMobile}
      />,
      <Feature2
      id="Feature2_0"
      key="Feature2_0"
      dataSource={Feature20DataSource}
      isMobile={this.state.isMobile}
    />,
    <Feature3
    id="Feature3_0"
    key="Feature3_0"
    dataSource={Feature30DataSource}
    isMobile={this.state.isMobile}
  />,      
  <Feature4
  id="Feature4_0"
  key="Feature4_0"
  dataSource={Feature40DataSource}
  isMobile={this.state.isMobile}
/>,

<Feature5
id="Feature5_0"
key="Feature5_0"
dataSource={Feature50DataSource}
isMobile={this.state.isMobile}
/>,
      <Footer0
        id="Footer0_0"
        key="Footer0_0"
        dataSource={Footer00DataSource}
        isMobile={this.state.isMobile}
      />,
    ];
    return (
      <>
       {/* <div
        // className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {/* {this.state.show && children} */}
        {/* why */}

        {/* 如果不是 dva 2.0 替换成 {children} end */}
      {/* </div> */} 
      <div style={{paddingTop: '-500px !important'}}>
      {isMobile == true && this.state.show && children2}
        {!isMobile && this.state.show && children}
      </div>

      </>
     
    );
  }
}
