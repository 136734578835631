import { gql } from "@apollo/client";

export const PRINT_FORM3454Q = gql`
  mutation printStudentForm3454Q(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $requiredSpecialClasses: String
    $requiredSpecialClassesExplaination: String
    $nonacademicParticipation: String
    $nonacademicParticipationExplaination: String
    $attendSchool: String
    $attendSchoolExplain: String
    $informedSchoolServices: String
    $residentialFacilityOption: String
    $placementOptions: [StudentForm3454QPlacementOptionsInput]
    $transportationSchoolRequired: String
    $transportationBuildingsRequired: String
    $specializedEquipmentRequired: String
    $specializedEquipmentRequiredExplanation: String
    $extendedSchoolYearServices: String
    $extendedServices: [StudentForm3454QExtendedServicesInput]
    $formAction: String
$formStatus: String
  ) {
    printStudentForm3454Q(
      studentForm3454QInput: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        requiredSpecialClasses: $requiredSpecialClasses
        requiredSpecialClassesExplaination: $requiredSpecialClassesExplaination
        nonacademicParticipation: $nonacademicParticipation
        nonacademicParticipationExplaination: $nonacademicParticipationExplaination
        attendSchool: $attendSchool
        attendSchoolExplain: $attendSchoolExplain
        informedSchoolServices: $informedSchoolServices
        residentialFacilityOption: $residentialFacilityOption
        placementOptions: $placementOptions
        transportationSchoolRequired: $transportationSchoolRequired
        transportationBuildingsRequired: $transportationBuildingsRequired
        specializedEquipmentRequired: $specializedEquipmentRequired
        specializedEquipmentRequiredExplanation: $specializedEquipmentRequiredExplanation
        extendedSchoolYearServices: $extendedSchoolYearServices
        extendedServices: $extendedServices
formAction: $formAction
formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;
