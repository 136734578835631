import { gql } from "@apollo/client";

export const QUERY_FORM3454G_VERSION = gql`
  query studentForm3454G(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
  ) {
    studentForm3454G(
      studentFormId: $studentFormId
      studentId: $studentId
      formId: $formId
      schoolId: $schoolId
      studentMeetingId: $studentMeetingId
    ) {
      versionId
      formId
      formDate
      studentFullName
      studentDob
      studentStrengths
      parentalEducationConcerns
      presentLevelAcademicAchievement
      presentLevelFunctionalPerformance
      evaluationResults
      disabilityEffects
      formAction
      formStatus
    }
  }
`;
