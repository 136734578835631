import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useLazyQuery } from "@apollo/client";

import { Form, Input } from "antd";
import { SyncOutlined } from "@ant-design/icons";

import { QUERY_STUDENT_PARENT_NAMES } from "../../../../operations/queries/forms/SIS";
import SyncSISButton from "../../../elements/formItems/SyncSISButton";

const StudentParent1SIS = ({
  form,
  sis,
  id,
  initialValue,
  error,
  disabled = true,
}) => {
  const [
    getStudentParents,
    { data: studentParentsData, loading: parentsLoading },
  ] = useLazyQuery(QUERY_STUDENT_PARENT_NAMES);

  const [studentParent1FullName, setStudentParent1FullName] =
    useState(initialValue);

  useEffect(() => {
    if (!studentParentsData) return;
    const parent1 = studentParentsData.student.parent1FullName || "";
    form.setFieldsValue({
      parent1,
    });
    setStudentParent1FullName(parent1);
  }, [studentParentsData]);

  return (
    <>
      <Form.Item
        label="Parent/Guardian Name #1"
        name="parent1"
        validateStatus={error ? "error" : ""}
        help={error}
      >
        <Input
          onChange={(e) => setStudentParent1FullName(e.target.value)}
          disabled={disabled}
          addonAfter={
            <SyncSISButton
              loading={parentsLoading}
              sisValue={sis?.student.parent1FullName}
              currentValue={studentParent1FullName}
            >
              <SyncOutlined
                onClick={() => getStudentParents({ variables: { id: id } })}
              />
            </SyncSISButton>
          }
        />
      </Form.Item>
    </>
  );
};

StudentParent1SIS.propTypes = {
  form: PropTypes.object.isRequired,
  initialValue: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  error: PropTypes.string,
  disabled: PropTypes.bool,
};

export default StudentParent1SIS;
