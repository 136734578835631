import { gql } from "@apollo/client";

export const QUERY_FORM3454Q_VERSION = gql`
  query studentForm3454Q(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
  ) {
    studentForm3454Q(
      studentFormId: $studentFormId
      studentId: $studentId
      formId: $formId
      schoolId: $schoolId
      studentMeetingId: $studentMeetingId
    ) {
      versionId
      formId
      formDate
      studentFullName
      requiredSpecialClasses
      requiredSpecialClassesExplaination
      nonacademicParticipation
      nonacademicParticipationExplaination
      attendSchool
      attendSchoolExplain
      informedSchoolServices
      residentialFacilityOption
      placementOptions {
        placementOptionsOptionConsidered
        placementOptionsReasonRejected
        placementOptionsTeamAcceptsPlacement
      }
      transportationSchoolRequired
      transportationBuildingsRequired
      specializedEquipmentRequired
      specializedEquipmentRequiredExplanation
      extendedSchoolYearServices
      formAction
      formStatus
      extendedServices {
        extendedServicesSpecialEducationService
        extendedServicesLocation
        extendedServicesFrequency
        extendedServicesInitiation
        extendedServicesDuration
        extendedServicesGoalsAddressed
      }
    }
  }
`;
