import React from "react";

import Page from "../../components/Page";
import DistrictList from "../../components/sections/district/DistrictList";

const Districts = () => {
  const breadcrumb = [
    {
      path: "/",
      breadcrumbName: "Home",
    },
    {
      path: "",
      breadcrumbName: "Districts",
    },
  ];
  return (
    <>
      <Page title="Districts" breadcrumb={breadcrumb}>
        <DistrictList />
      </Page>
    </>
  );
};

export default Districts;
