import { gql } from "@apollo/client";

export const PRINT_FORM3457N = gql`
  mutation printStudentForm3457N(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $studentDob: String
    $parent1: String
    $parent2: String
    $communicationType: String
    $documentContactName: String
    $documentContactPhone: String
    $districtRepresentativeName: String
    $districtRepresentativeTitle: String
    $communicationDate: String
    $programDate: String
    $formAction: String
$formStatus: String
  ) {
    printStudentForm3457N(
      studentForm3457NInput: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        studentDob: $studentDob
        parent1: $parent1
        parent2: $parent2
        communicationType: $communicationType
        documentContactName: $documentContactName
        documentContactPhone: $documentContactPhone
        districtRepresentativeName: $districtRepresentativeName
        districtRepresentativeTitle: $districtRepresentativeTitle
        communicationDate: $communicationDate
        programDate: $programDate
formAction: $formAction
formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;
