import React from "react";
import PropTypes from "prop-types";

// apollo & operations
import { useQuery } from "@apollo/client";
import { QUERY_GRADES } from "../../../operations/queries/grades";

// ant design
import { Form, Select } from "antd";

import TextNameAuthEvaluated from "../common/TextNameAuthEvaluated";

const FormItemSelectGrade = ({
  label,
  name,
  validateStatus,
  help,
  hasFeedback,
  mode,
}) => {
  const { data, loading } = useQuery(QUERY_GRADES);
  return (
    <Form.Item
      label={label}
      name={name}
      validateStatus={validateStatus}
      help={help}
      hasFeedback={hasFeedback}
    >
      <Select
        allowClear={true}
        mode={mode}
        loading={loading}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) =>
          optionA.children
            .toString().toLowerCase()
            .localeCompare(optionB.children.toString().toLowerCase())
        }
      >
        {data?.grades &&
          data.grades.map((grade) => (
            <Select.Option key={grade.id} value={grade.name}>
              <TextNameAuthEvaluated entity={grade} />
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  );
};

FormItemSelectGrade.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  help: PropTypes.string,
  validateStatus: PropTypes.string,
  hasFeedback: PropTypes.bool,
  mode: PropTypes.string,
};

export default FormItemSelectGrade;
