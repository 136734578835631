import { gql } from "@apollo/client";

export const MUTATE_FORM_COLLABORATION = gql`
  mutation MutateFormCollaboration(
    $studentFormId: ID!
    $eventId: String!
    $fieldName: String
    $fieldValue: String
  ) {
    mutateFormCollaboration(
      formCollaborationInput: {
        studentFormId: $studentFormId
        eventId: $eventId
        fieldName: $fieldName
        fieldValue: $fieldValue
      }
    )
  }
`;
