import { gql } from "@apollo/client";

export const PRINT_FORM3454T = gql`
  mutation printStudentForm3454T(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $staffName: String
    $title: String
    $typeOfReport: String
    $reportCard: Int
    $progressReport: Int
    $parentReport: Boolean
    $formAction: String
$formStatus: String
    $annualGoals: [AnnualGoalsInput]
  ) {
    printStudentForm3454T(
      studentForm3454TInput: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        staffName: $staffName
        title: $title
        typeOfReport: $typeOfReport
        reportCard: $reportCard
        progressReport: $progressReport
        parentReport: $parentReport
        annualGoals: $annualGoals
formAction: $formAction
formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;
