import { gql } from "@apollo/client";

export const PRINT_FORM3454M = gql`
  mutation printStudentForm3454M(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $parentNotificationMethods: [String]
    $parentNotificationMethodsOther: String
    $initialEvaluationResults: String
    $goalStatement: String
    $goalNumber: String
    $goalTotal: String
    $goalArea: [String]
    $illinoisLearningStandard: String
    $goalImplementers: String
    $formAction: String
$formStatus: String
    $evaluationMeasures: [StudentForm3454MEvaluationMeasuresInput]
  ) {
    printStudentForm3454M(
      studentForm3454MInput: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        parentNotificationMethods: $parentNotificationMethods
        parentNotificationMethodsOther: $parentNotificationMethodsOther
        initialEvaluationResults: $initialEvaluationResults
        goalStatement: $goalStatement
        goalNumber: $goalNumber
        goalTotal: $goalTotal
        goalArea: $goalArea
        illinoisLearningStandard: $illinoisLearningStandard
        goalImplementers: $goalImplementers
        evaluationMeasures: $evaluationMeasures
formAction: $formAction
formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;

