const AccompanyingSISMapping = {
  // GLOBAL SIS FIELD -> MATCHING FORM FIELD
  3454: {
    //residentDistrict: "residentDistrict",
    //residentSchool: "residentSchool",
    //servingDistrict: "servingDistrict",
    //servingSchool: "servingSchool",
    //lastAnnualReviewDate: "recentEvaluationDate",
    //nextAnnualReviewDate: "nextEvaluationDate",
    //highSchoolGraduationDate: "highSchoolGraduationDate",
  },
  '3454S': {
    studentNotes: 'additionalInformation'
  },
  '3457B': {
    //guardianInitialEvaluationConsent: "guardianInitialEvaluationConsent",
    //signatureDate: "signatureDate",
  },
  '3457C': {
    //reEvaluationConsent: "guardianEvaluationConsent",
    //reEvaluationConsentDate: "signatureDate",
  }
}

export const mapAccompanyingSIS = (formCode, meta) => {
  // Find all fields for current form code
  // console.log('Received', meta)
  const mappings = Object.assign({}, AccompanyingSISMapping[formCode])
  const mappedMeta = {}

  Object.keys(mappings).forEach(key => {
    mappedMeta[mappings[key]] = meta[key]
  })
  // console.log('Mapped', mappedMeta)
  return mappedMeta
}
