import { gql } from "@apollo/client";

export const PRINT_FORMTAMES4 = gql`
  mutation printStudentFormTames4(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $studentDob: String
    $determinationSpecificLearningDisability: Boolean
    $intellectualGeneralIntelligence: Boolean
    $adaptiveBehavior: Boolean
    $normTest: Boolean
    $criterionTest: Boolean
    $portfolio: Boolean
    $observations: Boolean
    $interview: Boolean
    $hearingScreeningConductedBy: Boolean
    $other: Boolean
    $descriptiveAnalysis: String
    $intellectualCriteria: Boolean
    $determinantEnvironmentalCultural: Boolean
    $formAction: String
$formStatus: String
  ) {
    printStudentFormTames4(
      studentFormTames4Input: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        studentDob: $studentDob
        determinationSpecificLearningDisability: $determinationSpecificLearningDisability
        intellectualGeneralIntelligence: $intellectualGeneralIntelligence
        adaptiveBehavior: $adaptiveBehavior
        normTest: $normTest
        criterionTest: $criterionTest
        portfolio: $portfolio
        observations: $observations
        interview: $interview
        hearingScreeningConductedBy: $hearingScreeningConductedBy
        other: $other
        descriptiveAnalysis: $descriptiveAnalysis
        intellectualCriteria: $intellectualCriteria
        determinantEnvironmentalCultural: $determinantEnvironmentalCultural
formAction: $formAction
formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;
