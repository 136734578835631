// react
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useParams } from 'react-router-dom'

// apollo & operations
import { useQuery, useMutation } from '@apollo/client'
import {
  QUERY_SCHOOL,
  QUERY_SCHOOLS
} from '../../../operations/queries/schools'
import {
  INSERT_SCHOOL,
  UPDATE_SCHOOL
} from '../../../operations/mutations/schools'

// ant design
import { Form, Input, Button, Typography, message } from 'antd'

// elements
import FormItemsSuperAdmin from '../../elements/formItems/FormItemsSuperAdmin'
import FormItemSelectDistrict from '../../elements/formItems/FormItemSelectDistrict'

// other libraries
import formatErrors from '../../../utils/form'
import FormContainer from '../../../components/FormContainer'
import ModalDialog from '../../../components/ModalDialog'
import notificationSuccess from '../../../utils/notification'

const { Paragraph } = Typography

const FormRecord = ({ data }) => {
  const history = useNavigate()
  const { id } = useParams()
  const [form] = Form.useForm()
  const [formError, setFormError] = useState()
  const [itemErrors, setItemErrors] = useState({})
  const [updateSchool, { loading: updateLoading }] = useMutation(UPDATE_SCHOOL)
  const [insertSchool, { loading: insertLoading }] = useMutation(
    INSERT_SCHOOL,
    {
      update(cache, { data: addSchool }) {
        const data = cache.readQuery({ query: QUERY_SCHOOLS })
        if (data) {
          cache.writeQuery({
            query: QUERY_SCHOOLS,
            data: { schools: [addSchool.insertSchool, ...data.schools] }
          })
        }

      }
    }
  )

  const continueEditing = _id => _id && history(`/school/${_id}`)

  const onFinish = async values => {
    try {
      clearErrors()
      const mutated = id
        ? await updateSchool({ variables: { id: id, schoolInput: values } })
        : await insertSchool({ variables: values })
      // ModalDialog.success({
      //   title: "Success",
      //   content: `The school has been ${id ? "updated" : "added"}.`,
      //   okText: "Return to listing",
      //   onOk: () => history("/schools"),
      //   cancelText: "Continue editing",
      //   onCancel: () => continueEditing(mutated.data.insertSchool?.id),
      // })
      notificationSuccess(`The school has been ${mutated ? 'updated' : 'added'}.`)
    } catch (e) {
      message.error(
        'Error(s) need to be corrected before the school can be updated.'
      )
      setErrors(formatErrors(e))
    }
  }

  const setErrors = errors =>
    errors.itemErrors
      ? setItemErrors(errors.itemErrors)
      : setFormError(errors.formError)

  const clearErrors = () => {
    setFormError()
    setItemErrors({})
  }

  return (
    <Form
      form={form}
      id='schoolForm'
      name='schoolForm'
      onFinish={onFinish}
      preserve={false}
      initialValues={data?.school}
      labelCol={{ sm: 6, md: 4, lg: 4, xl: 3, xxl: 2 }}
      wrapperCol={{ sm: 10, md: 7, lg: 8, xl: 6, xxl: 6 }}
    >
      <Form.Item
        label='Name'
        name='name'
        hasFeedback
        validateStatus={itemErrors.name ? 'error' : ''}
        help={itemErrors.name}
      >
        <Input />
      </Form.Item>

      <FormItemSelectDistrict
        label='District'
        name='districtId'
        validateStatus={itemErrors.districtId ? 'error' : ''}
        help={itemErrors.districtId}
      />

      <Form.Item
        label='Code'
        name='schoolCode'
        hasFeedback
        validateStatus={itemErrors.schoolCode ? 'error' : ''}
        help={itemErrors.schoolCode}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label='Address'
        name='address'
        hasFeedback
        validateStatus={itemErrors.address ? 'error' : ''}
        help={itemErrors.address}
      >
        <Input.TextArea />
      </Form.Item>

      <Form.Item
        label='City'
        name='city'
        hasFeedback
        validateStatus={itemErrors.city ? 'error' : ''}
        help={itemErrors.city}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label='State'
        name='state'
        hasFeedback
        validateStatus={itemErrors.state ? 'error' : ''}
        help={itemErrors.state}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label='Country'
        name='country'
        hasFeedback
        validateStatus={itemErrors.country ? 'error' : ''}
        help={itemErrors.country}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label='Zip'
        name='zip'
        hasFeedback
        validateStatus={itemErrors.zip ? 'error' : ''}
        help={itemErrors.zip}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label='Principal name'
        name='principalName'
        hasFeedback
        validateStatus={itemErrors.principalName ? 'error' : ''}
        help={itemErrors.principalName}
      >
        <Input />
      </Form.Item>

      <FormItemsSuperAdmin data={data.school} hasHidden />

      {formError && <Paragraph type='danger'>{formError}</Paragraph>}

      <Form.Item>
        <Button
          type='primary'
          htmlType='submit'
          loading={insertLoading || updateLoading}
        >
          {id ? 'Update' : 'Add'}
        </Button>
      </Form.Item>
    </Form>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired
}

const SchoolForm = props => {
  const { id } = useParams()

  const { data, loading } = useQuery(QUERY_SCHOOL, {
    variables: { id },
    skip: !id
  })
  const initialValues = {
    school: { name: '', schoolCode: '', districtId: '' }
  }

  return (
    <FormContainer
      loading={loading}
      form={<FormRecord data={data || initialValues} />}
    />
  )
}

export default SchoolForm
