import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { IepFormContext } from '../../../../utils/iepFormProvider'
import { useQuery } from '@apollo/client'
import { QUERY_STUDENT_COMMON_SIS } from '../../../../operations/queries/forms/SIS'
import { QUERY_FORM3457L_VERSION } from '../../../../operations/queries/forms/3457L'
import moment from 'moment'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import FormContainer from '../../../FormContainer'
import { Alert, Card, DatePicker, Form, Input, Radio, Typography } from 'antd'
import FormCollaboration from '../../../FormCollaboration'
import Title from 'antd/lib/typography/Title'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import StudentDobSIS from '../sis/StudentDobSIS'
import StudentParent1SIS from '../sis/StudentParent1SIS'
import StudentParent2SIS from '../sis/StudentParent2SIS'
import FormItem from 'antd/lib/form/FormItem'
import StudentFormActions from '../StudentFormActions'
import { PRINT_FORM3457L } from '../../../../operations/mutations/forms/3457L'

const { Paragraph } = Typography

const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId
}) => {
  const [form] = Form.useForm()
  const [isNameOther, setIsNameOther] = useState(data?.includeOther)
  const { itemErrors, formError } = useContext(FormErrorContext)

  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()
    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      studentMeetingId: studentMeetingId
    }
  }

  const onChangeIncludeOther = (name, value) => {
    if (name === 'includeOther') setIsNameOther(value)
    console.log('onchange', value)
  }

  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    const update = Object.assign({})
    switch (fieldName) {
      case 'formDate':
      case 'parentSignatureDate':
        update[fieldName] = fieldValue ? moment(fieldValue) : ''
        break
      case 'studentSignatureDate':
        update[fieldName] = fieldValue ? moment(fieldValue) : ''
        break
      case 'includeOther':
        setIsNameOther(fieldValue === 'true' ? true : false)
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        break
      case 'includeDHS':
      case 'includeDRS':
      case 'includeDD':
      case 'includeDSCC':
      case 'includePEDS':
      case 'includeMilitary':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        break
      default:
        update[fieldName] = fieldValue
    }
    return update
  }

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }

  const agencyIncludes = [
    {
      label: 'Department of Human Services (DHS)',
      name: 'includeDHS'
    },
    {
      label:
        'Department of Human Services Division of Rehabilitation Services (DRS)',
      name: 'includeDRS'
    },
    {
      label:
        'Department of Human Services Division of Developmental Disabilities (DD)',
      name: 'includeDD'
    },
    {
      label: 'Division of Specialized Care for Children (DSCC)',
      name: 'includeDSCC'
    },
    {
      label: 'Post-Secondary Education Disability Services',
      name: 'includePEDS'
    },
    {
      label: 'Military',
      name: 'includeMilitary'
    },
    {
      label: 'Other',
      name: 'includeOther'
    }
  ]

  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          formDisabledState,
          canSave,
          handleActionEvents,
          validationProps
        }) => (
          <Form
            form={form}
            id='studentForm3457L'
            name='studentForm3457L'
            initialValues={data}
            preserve
            layout='vertical'
            className='page-padding-leftRight page-padding-topBottom form-labels-bold'
          >
            <Title level={4} className='center'>
              CONSENT FOR AGENCY/AGENCIES INVITATION TO TRANSITION MEETING
            </Title>
            <Form.Item
              label='Form Created Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
              />
            </Form.Item>

            <Card
              title='Student SIS Data'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />
              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
              <StudentDobSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentDob}
                error={itemErrors.studentDob}
              />
              <StudentParent1SIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.parent1}
                error={itemErrors.parent1}
              />
              <StudentParent2SIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.parent2}
                error={itemErrors.parent2}
              />
            </Card>

            <Card
              title='Consents'
              style={{
                marginBottom: 30
              }}
            >
              <FormItem
                label='Parent consent'
                name='parentConsent'
                {...validationProps('parentConsent')}
              >
                <Radio.Group
                  defaultValue
                  defaultChecked='n/a'
                  buttonStyle='solid'
                  {...handleMouseEvents}
                  disabled={formDisabledState.parentConsent}
                  name='parentConsent'
                >
                  <Radio.Button value='yes'>Yes</Radio.Button>
                  <Radio.Button value='no'>No</Radio.Button>
                  <Radio.Button value='n/a'>Decision Delayed</Radio.Button>
                </Radio.Group>
              </FormItem>

              <FormItem
                label='Student consent'
                name='studentConsent'
                {...validationProps('studentConsent')}
              >
                <Radio.Group
                  defaultValue
                  defaultChecked='n/a'
                  buttonStyle='solid'
                  {...handleMouseEvents}
                  disabled={formDisabledState.studentConsent}
                  name='studentConsent'
                >
                  <Radio.Button value='yes'>Yes</Radio.Button>
                  <Radio.Button value='no'>No</Radio.Button>
                  <Radio.Button value='n/a'>Decision Delayed</Radio.Button>
                </Radio.Group>
              </FormItem>
            </Card>

            <Card
              title='The specific agency/agencies include'
              style={{
                marginBottom: 30
              }}
            >
              {agencyIncludes.map(({ label, name }, index) => (
                <FormItem
                  key={index}
                  label={label}
                  name={name}
                  {...validationProps(name)}
                >
                  <Radio.Group
                    onChange={e => {
                      handleMouseEvents.onChange(e)
                    }}
                    buttonStyle='solid'
                    disabled={formDisabledState[name]}
                    name={name}
                  >
                    <Radio.Button value>Yes</Radio.Button>
                    <Radio.Button value={false}>No</Radio.Button>
                  </Radio.Group>
                </FormItem>
              ))}

              {isNameOther && (
                <Form.Item
                  label='Name other'
                  name='nameOther'
                  {...validationProps('nameOther')}
                >
                  <Input
                    {...handleFocusEvents}
                    disabled={formDisabledState.nameOther}
                  />
                </Form.Item>
              )}
            </Card>

            <Card
              title='Signee'
              style={{
                marginBottom: 30
              }}
            >
              <Form.Item
                label='School Personnel Signature and Title'
                name='schoolPersonnelTitle'
                {...validationProps('schoolPersonnelTitle')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.schoolPersonnelTitle}
                />
              </Form.Item>

              <Form.Item
                label='School Personnel telephone'
                name='schoolPersonnelPhone'
                {...validationProps('schoolPersonnelPhone')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.schoolPersonnelPhone}
                />
              </Form.Item>

              <FormItem
                label='Consent'
                name='consentAgencyInvitation'
                {...validationProps('consentAgencyInvitation')}
              >
                <Radio.Group
                  defaultValue
                  defaultChecked='n/a'
                  buttonStyle='solid'
                  {...handleMouseEvents}
                  disabled={formDisabledState.consentAgencyInvitation}
                  name='consentAgencyInvitation'
                >
                  <Radio.Button value='yes'>Yes</Radio.Button>
                  <Radio.Button value='no'>No</Radio.Button>
                  <Radio.Button value='n/a'>Decision Delayed</Radio.Button>
                </Radio.Group>
              </FormItem>

              <Form.Item
                label='Parent signature date'
                name='parentSignatureDate'
                {...validationProps('parentSignatureDate')}
              >
                <DatePicker
                  {...handleFocusEvents}
                  disabled={formDisabledState.parentSignatureDate}
                  format={[
                    'MM-DD-YYYY',
                    'MM/DD/YYYY',
                    'MM.DD.YY',
                    'M-D-YYYY',
                    'M/D/YYYY',
                    'M.D.YYYY',
                    'MM.DD.YYYY',
                    'MM-DD-YY',
                    'MM/DD/YY',
                    'M-D-YY',
                    'M/D/YY',
                    'M.D.YY',
                    'MMDDYYYY',
                    'MMDDYY'
                  ]}
                />
              </Form.Item>
              <Form.Item
                label='Student signature date'
                name='studentSignatureDate'
                {...validationProps('studentSignatureDate')}
              >
                <DatePicker
                  {...handleFocusEvents}
                  disabled={formDisabledState.studentSignatureDate}
                  format={[
                    'MM-DD-YYYY',
                    'MM/DD/YYYY',
                    'MM.DD.YY',
                    'M-D-YYYY',
                    'M/D/YYYY',
                    'M.D.YYYY',
                    'MM.DD.YYYY',
                    'MM-DD-YY',
                    'MM/DD/YY',
                    'M-D-YY',
                    'M/D/YY',
                    'M.D.YY',
                    'MMDDYYYY',
                    'MMDDYY'
                  ]}
                />
              </Form.Item>
            </Card>

            {formError && <Paragraph type='danger'>{formError}</Paragraph>}
            <StudentFormActions
              query={PRINT_FORM3457L}
              extractDataProp='printStudentForm3457L'
              canSave={canSave}
              handleActionEvents={handleActionEvents}

              getFormFieldValues={getFormFieldValues}
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired
}

const StudentForm3457L = () => {
  // get current form information
  const { currentStudentInfo, currentEditableForm, studentMeetingId } =
    useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId } = currentEditableForm

  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    QUERY_STUDENT_COMMON_SIS,
    { variables: { id: studentId } }
  )
  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORM3457L_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )

  const createInitialFormData = (studentInfo, versionInfo) => {
    let initialValues = {}

    if (!studentInfo || !versionInfo) return initialValues

    const { studentForm3457L: form } = versionInfo
    const { student } = studentInfo
    const { parent1FullName, parent2FullName, dateOfBirth } = student
    const {
      formDate,
      studentFullName,
      studentDob,
      parent1,
      parent2,
      parentSignatureDate,
      studentSignatureDate
    } = form

    initialValues = {
      ...form,
      formDate: formDate ? moment(formDate) : moment(Date.now()),
      parentSignatureDate: parentSignatureDate
        ? moment(parentSignatureDate)
        : moment(Date.now()),
      studentSignatureDate: studentSignatureDate
        ? moment(studentSignatureDate)
        : moment(Date.now()),
      studentFullName: studentFullName || student.fullName || '',
      studentDob: studentDob
        ? moment(studentDob).format('YYYY-MM-DD')
        : dateOfBirth
        ? moment(dateOfBirth).format('YYYY-MM-DD')
        : '',
      parent1: parent1 || parent1FullName || '',
      parent2: parent2 || parent2FullName || ''
    }

    return initialValues
  }
  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
          />
        }
      />
    </FormErrorProvider>
  )
}
export default StudentForm3457L
