import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { useQuery } from '@apollo/client'

// ant design
import {
  Card,
  Form,
  Input,
  DatePicker,
  Radio,
  Divider,
  Alert,
  Typography
} from 'antd'

import FormContainer from '../../../FormContainer'
import FormItem from 'antd/lib/form/FormItem'
import Title from 'antd/lib/typography/Title'

import { PRINT_FORM3457J } from '../../../../operations/mutations/forms/3457J'
import { QUERY_FORM3457J_VERSION } from '../../../../operations/queries/forms/3457J'
import { QUERY_STUDENT_COMMON_SIS } from '../../../../operations/queries/forms/SIS'

import Editor from '../../../elements/common/Editor'
import FormCollaboration from '../../../FormCollaboration'

import { IepFormContext } from '../../../../utils/iepFormProvider'
import StudentFormActions from '../StudentFormActions'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import StudentDobSIS from '../sis/StudentDobSIS'
import StudentParent1SIS from '../sis/StudentParent1SIS'
import StudentParent2SIS from '../sis/StudentParent2SIS'

const { Paragraph } = Typography

const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId
}) => {
  const [form] = Form.useForm()
  const { itemErrors, formError } = useContext(FormErrorContext)

  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()
    //console.log("In StudentForm3457J.getFormFieldValues", formFieldValues)
    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      studentMeetingId: studentMeetingId
    }
  }

  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    //console.log("In StudentForm3457J.formatFormFieldUpdate")
    const update = Object.assign({})
    switch (fieldName) {
      case 'dateProvidedToParent':
      case 'formDate':
        update[fieldName] = moment(fieldValue)
        break
      default:
        update[fieldName] = fieldValue
    }
    return update
  }

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }

  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          formDisabledState,
          canSave,
          handleActionEvents,
          validationProps
        }) => (
          <Form
            form={form}
            id='studentForm3457J'
            name='studentForm3457J'
            initialValues={data}
            preserve
            layout='vertical'
            className='page-padding-leftRight page-padding-topBottom'
          >
            <Title level={4} className='center'>
              NOTICE OF PROCEDURAL SAFEGUARDS FOR PARENTS/GUARDIANS OF STUDENTS
              WITH DISABILITIES
            </Title>
            <Alert
              style={{ marginBottom: '24px' }}
              message='The Notice of Procedural Safeguards for Parents/Guardians of Students With Disabilities must be made available to parent(s)/guardian(s) at least one time a year. 
                However a copy also must be given upon an initial request for an evaluation, upon receipt of the first written complaint or first due process complaint to the Illinois State 
                Board of Education (ISBE), upon a disciplinary removal that constitutes a change in placement, or upon request.'
              description="Click 'Print' below generate the Notice of Procedural Safeguards for Parents/Guardians of Students With Disabilities document."
              type='info'
            />

            <Form.Item
              label='Form Created Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
              />
            </Form.Item>

            <Card
              title='Student SIS Data'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />

              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
              <StudentDobSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentDob}
                error={itemErrors.studentDob}
              />
              <StudentParent1SIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.parent1}
                error={itemErrors.parent1}
              />
              <StudentParent2SIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.parent2}
                error={itemErrors.parent2}
              />
            </Card>

            <Card
              title='Parent/Guardian Acknowledgement of Receipt'
              style={{
                marginBottom: 30
              }}
            >
              <Form.Item
                label='Date Notice of Procedural Safeguards provided to Parent/Guardian'
                name='dateProvidedToParent'
                {...validationProps('dateProvidedToParent')}
              >
                <DatePicker
                  {...handleFocusEvents}
                  disabled={formDisabledState.dateProvidedToParent}
                  format={[
                    'MM-DD-YYYY',
                    'MM/DD/YYYY',
                    'MM.DD.YY',
                    'M-D-YYYY',
                    'M/D/YYYY',
                    'M.D.YYYY',
                    'MM.DD.YYYY',
                    'MM-DD-YY',
                    'MM/DD/YY',
                    'M-D-YY',
                    'M/D/YY',
                    'M.D.YY',
                    'MMDDYYYY',
                    'MMDDYY'
                  ]}
                />
              </Form.Item>
              <Form.Item
                label='Parent Initials'
                name='parentInitials'
                {...validationProps('parentInitials')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.parentInitials}
                />
              </Form.Item>
            </Card>

            {formError && <Paragraph type='danger'>{formError}</Paragraph>}
            <StudentFormActions
              query={PRINT_FORM3457J}
              extractDataProp='printStudentForm3457J'
              canSave={canSave}
              handleActionEvents={handleActionEvents}

              getFormFieldValues={getFormFieldValues}
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired
}

const StudentForm3457J = () => {
  // get current form information
  const { currentStudentInfo, currentEditableForm, studentMeetingId } =
    useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId } = currentEditableForm

  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    QUERY_STUDENT_COMMON_SIS,
    { variables: { id: studentId } }
  )

  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORM3457J_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )

  const createInitialFormData = (studentInfo, versionInfo) => {
    console.log(studentInfo, versionInfo)

    let initialValues = {}

    if (!studentInfo || !versionInfo) return initialValues

    const { studentForm3457J: form } = versionInfo
    const { student } = studentInfo
    const { parent1FullName, parent2FullName, dateOfBirth } = student
    const {
      formDate,
      studentFullName,
      studentDob,
      parent1,
      parent2,
      dateProvidedToParent,
      parentInitials
    } = form

    initialValues = {
      ...form,
      formDate: formDate ? moment(formDate) : moment(Date.now()),
      studentFullName: studentFullName || student.fullName || '',
      studentDob: studentDob
        ? moment(studentDob).format('YYYY-MM-DD')
        : dateOfBirth
          ? moment(dateOfBirth).format('YYYY-MM-DD')
          : '',
      parent1: parent1 || parent1FullName || '',
      parent2: parent2 || parent2FullName || '',
      dateProvidedToParent: dateProvidedToParent
        ? moment(dateProvidedToParent)
        : moment(Date.now()),
      parentInitials: parentInitials ? parentInitials : ''
    }

    return initialValues
  }

  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
          />
        }
      />
    </FormErrorProvider>
  )
}

export default StudentForm3457J
