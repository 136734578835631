import { gql } from "@apollo/client";

export const QUERY_STUDENT_COMMON_SIS = gql`
  query student($id: ID!) {
    student(id: $id) {
      fullName
      dateOfBirth
      grade
      parent1FullName
      parent1PrimaryLanguage
      parent1Relationship
      parent1PhoneNumber
      parent2FullName
      parent2PrimaryLanguage
      parent2Relationship
      parent2PhoneNumber
    }
  }
`;

export const QUERY_STUDENT_NAME = gql`
  query student($id: ID!) {
    student(id: $id) {
      fullName
    }
  }
`;
export const QUERY_STUDENT_DOB = gql`
  query student($id: ID!) {
    student(id: $id) {
      dateOfBirth
    }
  }
`;
export const QUERY_STUDENT_PARENT_NAMES = gql`
  query student($id: ID!) {
    student(id: $id) {
      parent1FullName
      parent2FullName
    }
  }
`;
export const QUERY_STUDENT_GENDER = gql`
  query student($id: ID!) {
    student(id: $id) {
      gender
    }
  }
`;

export const QUERY_STUDENT_ETHNICITY = gql`
  query student($id: ID!) {
    student(id: $id) {
      ethnicity
    }
  }
`;

export const QUERY_STUDENT_PRIMARY_LANGUAGE = gql`
  query student($id: ID!) {
    student(id: $id) {
      primaryLanguage
    }
  }
`;

export const QUERY_STUDENT_GRADE = gql`
  query student($id: ID!) {
    student(id: $id) {
      grade
    }
  }
`;

export const QUERY_STUDENT_ADDRESS = gql`
  query student($id: ID!) {
    student(id: $id) {
      address
    }
  }
`;

export const QUERY_STUDENT_CITY = gql`
  query student($id: ID!) {
    student(id: $id) {
      city
    }
  }
`;

export const QUERY_STUDENT_STATE = gql`
  query student($id: ID!) {
    student(id: $id) {
      state
    }
  }
`;

export const QUERY_STUDENT_ZIP = gql`
  query student($id: ID!) {
    student(id: $id) {
      zip
    }
  }
`;

export const QUERY_STUDENT_COUNTRY = gql`
  query student($id: ID!) {
    student(id: $id) {
      country
    }
  }
`;

export const QUERY_STUDENT_CODE = gql`
  query student($id: ID!) {
    student(id: $id) {
      studentCode
    }
  }
`;

export const QUERY_STUDENT_MEDICAID_NUMBER = gql`
  query student($id: ID!) {
    student(id: $id) {
      medicaidNumber
    }
  }
`;

//Begin Parent1 queries
export const QUERY_STUDENT_PARENT1ADDRESS = gql`
  query student($id: ID!) {
    student(id: $id) {
      parent1Address
    }
  }
`;

export const QUERY_STUDENT_PARENT1CITY = gql`
  query student($id: ID!) {
    student(id: $id) {
      parent1City
    }
  }
`;

export const QUERY_STUDENT_PARENT1STATE = gql`
  query student($id: ID!) {
    student(id: $id) {
      parent1State
    }
  }
`;

export const QUERY_STUDENT_PARENT1COUNTRY = gql`
  query student($id: ID!) {
    student(id: $id) {
      parent1Country
    }
  }
`;
export const QUERY_STUDENT_PARENT1ZIP = gql`
  query student($id: ID!) {
    student(id: $id) {
      parent1Zip
    }
  }
`;
export const QUERY_STUDENT_PARENT1_EMAIL = gql`
  query student($id: ID!) {
    student(id: $id) {
      parent1Email
    }    
  }
`;
export const QUERY_STUDENT_PARENT1PHONE = gql`
  query student($id: ID!) {
    student(id: $id) {
      parent1PhoneNumber
    }
  }
`;
export const QUERY_STUDENT_PARENT1_PRIMARY_LANGUAGE = gql`
  query student($id: ID!) {
    student(id: $id) {
      parent1PrimaryLanguage
    }
  }
`;

//Begin Parent2 queries
export const QUERY_STUDENT_PARENT2ADDRESS = gql`
  query student($id: ID!) {
    student(id: $id) {
      parent2Address
    }
  }
`;

export const QUERY_STUDENT_PARENT2CITY = gql`
  query student($id: ID!) {
    student(id: $id) {
      parent2City
    }
  }
`;

export const QUERY_STUDENT_PARENT2STATE = gql`
  query student($id: ID!) {
    student(id: $id) {
      parent2State
    }
  }
`;

export const QUERY_STUDENT_PARENT2COUNTRY = gql`
  query student($id: ID!) {
    student(id: $id) {
      parent2Country
    }
  }
`;
export const QUERY_STUDENT_PARENT2ZIP = gql`
  query student($id: ID!) {
    student(id: $id) {
      parent2Zip
    }
  }
`;
export const QUERY_STUDENT_PARENT2_EMAIL = gql`
  query student($id: ID!) {
    student(id: $id) {
      parent2Email
    }    
  }
`;

export const QUERY_STUDENT_PARENT2PHONE = gql`
  query student($id: ID!) {
    student(id: $id) {
      parent2PhoneNumber
    }
  }
`;
export const QUERY_STUDENT_PARENT2_PRIMARY_LANGUAGE = gql`
  query student($id: ID!) {
    student(id: $id) {
      parent2PrimaryLanguage
    }
  }
`;

export const QUERY_STUDENT_IEP_SIS_DATA = gql`
  query student($id: ID!) {
    student(id: $id) {
      fullName
      address
      city
      state
      zip
      country
      dateOfBirth
      grade
      primaryLanguage
      gender
      ethnicity
      studentCode
      medicaidNumber
      parent1FullName
      parent1Address
      parent1City
      parent1State
      parent1Country
      parent1Zip
      parent1PhoneNumber
      parent1Email
      parent1PrimaryLanguage
      parent2FullName
      parent2Address
      parent2City
      parent2State
      parent2Country
      parent2Zip
      parent2PhoneNumber
      parent2Email
      parent2PrimaryLanguage
      caseManager
      anticipatedGraduationDate
      residentDistrict
      residentSchool
      servingDistrict
      servingSchool
      currentGrade
      nextYearCaseManager
      nextYearGrade
      nextYearResidentDistrict
      nextYearResidentSchool
      nextYearServingDistrict
      nextYearServingSchool
      lastAnnualReviewDate
      nextAnnualReviewDate
      initialEvaluationConsent
      initialEvaluationConsentDate
      reEvaluationConsent
      reEvaluationConsentDate
      triennialReEvaluationDueDate
    }
  }
`;
