import { gql } from "@apollo/client";

export const QUERY_FORM3454D_VERSION = gql`
  query studentForm3454D(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
  ) {
    studentForm3454D(
      studentFormId: $studentFormId
      studentId: $studentId
      formId: $formId
      schoolId: $schoolId
      studentMeetingId: $studentMeetingId
    ) {
      versionId
      formId
      formDate
      studentFullName
      lackAppropriateInstructionReading
      lackAppropriateInstructionReadingEvidence
      lackAppropriateInstructionMath
      lackAppropriateInstructionMathEvidence
      factorEnglishLearnerStatus
      factorEnglishLearnerStatusEvidence
      factorVisualHearingMotor
      factorVisualHearingMotorEvidence
      factorIntellectual
      factorIntellectualEvidence
      factorEmotional
      factorEmotionalEvidence
      factorCultural
      factorCulturalEvidence
      factorEnvironmentalEconomic
      factorEnvironmentalEconomicEvidence
      factorSlowerRate
      factorSlowerRateAreas
      factorBelowPerformance
      factorBelowPerformanceAreas
      formAction
      formStatus
    }
  }
`;
