// components
import { ErrorWithNotification } from './errorWithNotification'

const formatErrors = error => {
  let formError
  let itemErrors = {}
  console.log(error, 'what is the error here')
  const graphQLErrors = error?.graphQLErrors[0]
  if (error?.graphQLErrors?.[0]) {

    if (graphQLErrors?.extensions?.exception?.details) {
      graphQLErrors?.extensions?.exception?.details.forEach(
        _err => {
          itemErrors[_err.context.key] = _err.message
        }
      )
    }

    if (graphQLErrors?.message) {
      formError = graphQLErrors?.message
      ErrorWithNotification('Form error', {
        title: 'Form error',
        message: error.message + ' - E004',
        error
      })
    } else {
      formError = error
      // ErrorWithNotification('Form error', {
      //   title: 'Form error',
      //   message: error.message + ' - E004',
      //   error
      // })
    }
  } else if (!error?.networkError?.result && error.message) {
    ErrorWithNotification('Form error', {
      title: 'Form error',
      message: error.message + ' - E004',
      error
    })
  }
  return { itemErrors, formError }
}

export default formatErrors

export { formatErrors }
