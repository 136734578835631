import React, { useContext } from 'react'

import Page from '../../components/Page'
import IStarReport from '../../components/sections/report/IStarReport'
import Card from 'antd/lib/card/Card'
import AuthContext from '../../utils/authProvider'
const IStarReportPage = () => {
  const breadcrumb = [
    {
      path: '/',
      breadcrumbName: 'Home'
    },
    {
      path: '',
      breadcrumbName: 'IStar report'
    }
  ]
  const Auth = useContext(AuthContext)
  const isSuperAdmin = Auth.hasRole('SUPER_ADMIN')
  return (
    <>
      <Page title='IStar report' breadcrumb={breadcrumb}>
        {isSuperAdmin ? (
          <>
            <IStarReport />
          </>
        ) : (
          <>
            {' '}
            <Card
              title='Report Builder is currently in development.'
              style={{ margin: '24px' }}
            >
              Check back soon for updates!
            </Card>
          </>
        )}
      </Page>
    </>
  )
}

export default IStarReportPage
