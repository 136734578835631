import { gql } from "@apollo/client";

export const QUERY_SCHOOLS = gql`
  query ($searchCriteria: String) {
    schools(searchCriteria: $searchCriteria) {
      id
      districtId
      name
      nameGeneral
      schoolCode
      name
      address
      city
      state
      country
      zip
      principalName
      hidden
      district {
        name
      }
      isEditable
    }
  }
`;

export const QUERY_SCHOOL = gql`
  query School($id: ID!) {
    school(id: $id) {
      id
      districtId
      name
      schoolCode
      name
      address
      city
      state
      country
      zip
      principalName
      hidden
      createdBy
      createdAtAgo
      updatedAtAgo
      createdAt(format: "ddd mmm dd yyyy h:MM:ss TT Z")
      updatedAt(format: "ddd mmm dd yyyy h:MM:ss TT Z")
    }
  }
`;
