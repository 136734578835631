import { gql } from "@apollo/client";

export const PRINT_FORM3454N = gql`
  mutation printStudentForm3454N(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $requiresAssistiveTechnology: String
    $assistiveTechnologyNeeded: String
    $communicationNeeds: String
    $englishLearner: String
    $provisionBraille: String
    $behaviorImpedesLearning: String
    $behaviorImpedesLearningForms: String
    $requiresLinguisticCultural: String
    $linguisticCulturalAccommodations: String
    $specialEducationRelatedServicesLanguage: String
    $specialEducationLinguisticAccommodations: String
    $languageCommunicationNeeds: [String]
    $otherText: String
    $directCommunicationOpportunities: String
    $modeOfCommunication: String
    $supplementaryAids: String
    $programTrainings: String
    $programTrainingsText: String
    $parentTraining: String
    $parentTrainingText: String
    $formAction: String
$formStatus: String
  ) {
    printStudentForm3454N(
      studentForm3454NInput: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        requiresAssistiveTechnology: $requiresAssistiveTechnology
        assistiveTechnologyNeeded: $assistiveTechnologyNeeded
        communicationNeeds: $communicationNeeds
        englishLearner: $englishLearner
        provisionBraille: $provisionBraille
        behaviorImpedesLearning: $behaviorImpedesLearning
        behaviorImpedesLearningForms: $behaviorImpedesLearningForms
        requiresLinguisticCultural: $requiresLinguisticCultural
        linguisticCulturalAccommodations: $linguisticCulturalAccommodations
        specialEducationRelatedServicesLanguage: $specialEducationRelatedServicesLanguage
        specialEducationLinguisticAccommodations: $specialEducationLinguisticAccommodations
        languageCommunicationNeeds: $languageCommunicationNeeds
        otherText: $otherText
        directCommunicationOpportunities: $directCommunicationOpportunities
        modeOfCommunication: $modeOfCommunication
        supplementaryAids: $supplementaryAids
        programTrainings: $programTrainings
        programTrainingsText: $programTrainingsText
        parentTraining: $parentTraining
        parentTrainingText: $parentTrainingText
formAction: $formAction
formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;
