import { notificationError } from "./notification";
import { ApplicationError } from "./applicationError";

function ErrorWithNotification(message, metadata) {
  const error = new ApplicationError(message, metadata);
  notificationError(metadata.title || "Error", metadata.message || message);
  return error;
}

ErrorWithNotification.prototype = Object.create(Error.prototype);

export { ErrorWithNotification };
