import React, {useContext} from "react";
import { Button, Card, Spin } from "antd";
import Page from "../components/Page";
import { Link } from "react-router-dom";
import DesktopStudents from "../components/sections/desktop/DesktopStudents";
import AuthContext from "../utils/authProvider";

const Desktop = () => {
  const Auth = useContext(AuthContext)
  const desktopData = Auth.desktopData
  if (desktopData === null) {
    Auth.fetchDesktopData()
  }
  return (
    <Page title="Dashboard">
      <div className="page-container">
        <Card
          title="Students on Dashboard"
          extra={
            <Link to="/students">
              <Button type="primary">Add Students to Dashboard</Button>
            </Link>
          }
          bordered={false}
        >
          {desktopData === null ? <><Spin/> </>:<><DesktopStudents /></>}
        </Card>
      </div>
    </Page>
  );
};

export default Desktop;
