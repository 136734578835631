import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { IepFormContext } from '../../../../utils/iepFormProvider'
import { useQuery } from '@apollo/client'
import { QUERY_STUDENT_COMMON_SIS } from '../../../../operations/queries/forms/SIS'
import { QUERY_FORM3454Q_VERSION } from '../../../../operations/queries/forms/3454Q'
import moment from 'moment'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import FormContainer from '../../../FormContainer'
import {
  Alert,
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Radio,
  Typography
} from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import FormCollaboration from '../../../FormCollaboration'
import Title from 'antd/lib/typography/Title'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import FormItem from 'antd/lib/form/FormItem'
import StudentFormActions from '../StudentFormActions'
import { PRINT_FORM3454Q } from '../../../../operations/mutations/forms/3454Q'
import Editor from '../../../elements/common/Editor'

const { Paragraph, Text } = Typography

const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId
}) => {
  const [form] = Form.useForm()
  const [extendedSchoolYearServices, setExtendedSchoolYearServices] = useState(
    data?.extendedSchoolYearServices
  )
  const [nonacademicParticipation, setNonacademicParticipation] = useState(
    data?.nonacademicParticipation
  )
  const [attendSchool, setAttendSchool] = useState(data?.attendSchool)

  const { itemErrors, formError } = useContext(FormErrorContext)

  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()
    const placementOptions = formFieldValues.placementOptions
      ? formFieldValues.placementOptions.map(
          ({ __typename, ...props }) => props
        )
      : []
    const extendedServices = formFieldValues.extendedServices
      ? formFieldValues.extendedServices.map(
          ({ __typename, ...props }) => props
        )
      : []

    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      studentMeetingId: studentMeetingId,
      placementOptions: placementOptions,
      extendedServices: extendedServices
    }
  }

  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    const update = Object.assign({})
    switch (fieldName) {
      case 'formDate':
        update[fieldName] = fieldValue ? moment(fieldValue) : ''
        break
      case 'nonacademicParticipation':
        update[fieldName] = fieldValue
        setNonacademicParticipation(fieldValue)
        break
      case 'attendSchool':
        update[fieldName] = fieldValue
        setAttendSchool(fieldValue)
        break
      case 'extendedSchoolYearServices':
        update[fieldName] = fieldValue
        setExtendedSchoolYearServices(fieldValue)
        break
      case 'placementOptions':
      case 'extendedServices':
        update[fieldName] = JSON.parse(fieldValue)
        break
      default:
        update[fieldName] = fieldValue
    }
    return update
  }

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }

  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          formDisabledState,
          canSave,
          handleActionEvents,
          validationProps
        }) => (
          <Form
            form={form}
            id='studentForm3454Q'
            name='studentForm3454Q'
            initialValues={data}
            preserve
            layout='vertical'
            className='page-padding-leftRight page-padding-topBottom form-labels-bold'
          >
            <Title level={4} className='center'>
              EDUCATIONAL SERVICES AND PLACEMENT
            </Title>
            <Form.Item
              label='Form Created Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
              />
            </Form.Item>

            <Card
              title='Student SIS Data'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />
              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
            </Card>

            <Card
              title='Educational Environment Considerations'
              style={{
                marginBottom: 30
              }}
            >
              <FormItem
                label='Special education classes, separate schooling, or removal from the regular education environment is required because the nature or severity of the student’s disability is such that education in general classes with the use of supplementary aids and services cannot be achieved satisfactorily.'
                name='requiredSpecialClasses'
                {...validationProps('requiredSpecialClasses')}
              >
                <Radio.Group
                  defaultValue={data.requiredSpecialClasses}
                  onChange={e => {
                    handleMouseEvents.onChange(e)
                  }}
                  buttonStyle='solid'
                  disabled={formDisabledState.requiredSpecialClasses}
                  name='requiredSpecialClasses'
                >
                  <Radio.Button value='yes'>Yes</Radio.Button>
                  <Radio.Button value='no'>No</Radio.Button>
                </Radio.Group>
              </FormItem>

              <Form.Item
                label='Explain'
                name='requiredSpecialClassesExplaination'
                {...validationProps('requiredSpecialClassesExplaination')}
              >
                <Editor
                  formField='requiredSpecialClassesExplaination'
                  disabled={
                    formDisabledState.requiredSpecialClassesExplaination
                  }
                  {...handleEditorEvents}
                />
              </Form.Item>

              <FormItem
                label='Will participate in nonacademic activities with non-disabled peers and have the same opportunity to participate in extracurricular activities as non-disabled peers?'
                name='nonacademicParticipation'
                {...validationProps('nonacademicParticipation')}
              >
                <Radio.Group
                  defaultValue={data.nonacademicParticipation}
                  onChange={e => {
                    setNonacademicParticipation(e.target.value)
                    handleMouseEvents.onChange(e)
                  }}
                  buttonStyle='solid'
                  disabled={formDisabledState.nonacademicParticipation}
                  name='nonacademicParticipation'
                >
                  <Radio.Button value='yes'>Yes</Radio.Button>
                  <Radio.Button value='no'>No</Radio.Button>
                </Radio.Group>
              </FormItem>

              {nonacademicParticipation === 'no' && (
                <Form.Item
                  label='Explain'
                  name='nonacademicParticipationExplaination'
                  {...validationProps('nonacademicParticipationExplaination')}
                >
                  <Editor
                    formField='nonacademicParticipationExplaination'
                    disabled={
                      formDisabledState.nonacademicParticipationExplaination
                    }
                    {...handleEditorEvents}
                  />
                </Form.Item>
              )}

              <FormItem
                label='Will attend the school he or she would attend if non-disabled?'
                name='attendSchool'
                {...validationProps('attendSchool')}
              >
                <Radio.Group
                  defaultValue={data.attendSchool}
                  onChange={e => {
                    setAttendSchool(e.target.value)
                    handleMouseEvents.onChange(e)
                  }}
                  buttonStyle='solid'
                  disabled={formDisabledState.attendSchool}
                  name='attendSchool'
                >
                  <Radio.Button value='yes'>Yes</Radio.Button>
                  <Radio.Button value='no'>No</Radio.Button>
                </Radio.Group>
              </FormItem>

              {attendSchool === 'no' && (
                <Form.Item
                  label='Explain'
                  name='attendSchoolExplain'
                  {...validationProps('attendSchoolExplain')}
                >
                  <Editor
                    formField='attendSchoolExplain'
                    disabled={formDisabledState.attendSchoolExplain}
                    {...handleEditorEvents}
                  />
                </Form.Item>
              )}
            </Card>

            <Card
              title='Placement Considerations'
              style={{
                marginBottom: 30
              }}
            >
              <FormItem
                label='If the student might be eligible to receive services from the Illinois School for the Deaf, the Illinois School for the Visually Impaired, or the Illinois Center for Rehabilitation and Education-Roosevelt, the school district notified the parents, in writing, of the existence of these schools and the services they provide and made a reasonable effort to inform the parents of the existence of other, local schools that provide similar services and the services that these other schools provide.  This notification shall include without limitation information on school services, school admissions criteria, and school contact information.'
                name='informedSchoolServices'
                {...validationProps('informedSchoolServices')}
              >
                <Radio.Group
                  defaultValue={data.informedSchoolServices}
                  onChange={e => {
                    handleMouseEvents.onChange(e)
                  }}
                  buttonStyle='solid'
                  disabled={formDisabledState.informedSchoolServices}
                  name='informedSchoolServices'
                >
                  <Radio.Button value='yes'>Yes</Radio.Button>
                  <Radio.Button value='n/a'>N/A</Radio.Button>
                </Radio.Group>
              </FormItem>

              <FormItem
                label='For a child who is placed in an out-of-state special education residential facility, prior to the placement, the school district referred to the child and/or the parent/guardian the option to place the child in a special education residential facility located within this State, if any, that provides treatment and services comparable to those provided by the out-of-state special education residential facility.'
                name='residentialFacilityOption'
                {...validationProps('residentialFacilityOption')}
              >
                <Radio.Group
                  defaultValue={data.residentialFacilityOption}
                  onChange={e => {
                    handleMouseEvents.onChange(e)
                  }}
                  buttonStyle='solid'
                  disabled={formDisabledState.residentialFacilityOption}
                  name='residentialFacilityOption'
                >
                  <Radio.Button value='yes'>Yes</Radio.Button>
                  <Radio.Button value='n/a'>N/A</Radio.Button>
                </Radio.Group>
              </FormItem>

              <Card
                title='Placement Options'
                style={{
                  marginBottom: '30px'
                }}
              >
                <Input.Group
                  onFocus={() => {
                    handleEditorEvents.onFocus('placementOptions')
                  }}
                  onBlur={() => {
                    handleEditorEvents.onBlur(
                      'placementOptions',
                      JSON.stringify(form.getFieldValue('placementOptions'))
                    )
                  }}
                >
                  <Form.List
                    name='placementOptions'
                    {...validationProps('placementOptions')}
                  >
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field, index) => (
                          <div key={field.key}>
                            <Card
                              style={{
                                marginBottom: '30px',
                                borderStyle: 'dashed'
                              }}
                            >
                              <Form.Item
                                {...field}
                                label='Placement Option Considered'
                                name={[
                                  field.name,
                                  'placementOptionsOptionConsidered'
                                ]}
                                key={[
                                  field.fieldKey,
                                  'placementOptionsOptionConsidered'
                                ]}
                              >
                                <Input
                                  disabled={formDisabledState.placementOptions}
                                />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                label='Potentially Harmful Effect/Reasons Rejected'
                                name={[
                                  field.name,
                                  'placementOptionsReasonRejected'
                                ]}
                                key={[
                                  field.fieldKey,
                                  'placementOptionsReasonRejected'
                                ]}
                              >
                                <Input
                                  disabled={formDisabledState.placementOptions}
                                />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                label='Team Accepts Placement'
                                name={[
                                  field.name,
                                  'placementOptionsTeamAcceptsPlacement'
                                ]}
                                key={[
                                  field.fieldKey,
                                  'placementOptionsTeamAcceptsPlacement'
                                ]}
                              >
                                <Radio.Group
                                  buttonStyle='solid'
                                  disabled={formDisabledState.placementOptions}
                                  onChange={() => {
                                    handleEditorEvents.onBlur(
                                      'placementOptions',
                                      JSON.stringify(
                                        form.getFieldValue('placementOptions')
                                      )
                                    )
                                  }}
                                >
                                  <Radio.Button value='yes'>Yes</Radio.Button>
                                  <Radio.Button value='no'>No</Radio.Button>
                                </Radio.Group>
                              </Form.Item>

                              <Button
                                danger
                                disabled={formDisabledState.placementOptions}
                                onClick={() => {
                                  remove(field.name)
                                  handleEditorEvents.onBlur(
                                    'placementOptions',
                                    JSON.stringify(
                                      form.getFieldValue('placementOptions')
                                    )
                                  )
                                }}
                              >
                                Remove Placement Option
                              </Button>
                            </Card>
                          </div>
                        ))}

                        <Form.Item>
                          {
                            // Default values below prevents Quill from breaking the render
                          }
                          <Button
                            disabled={formDisabledState.placementOptions}
                            type='dashed'
                            onClick={() =>
                              add({
                                placementOptionsOptionConsidered: '',
                                placementOptionsReasonRejected: '',
                                placementOptionsTeamAcceptsPlacement: 'no'
                              })
                            }
                            block
                            icon={<PlusOutlined />}
                          >
                            Add Placement Consideration
                          </Button>
                        </Form.Item>
                        <Text type='warning'>
                          {validationProps('placementOptions').help}
                        </Text>
                      </>
                    )}
                  </Form.List>
                </Input.Group>
              </Card>
            </Card>

            <Card
              title='Transportation'
              style={{
                marginBottom: 30
              }}
            >
              <FormItem
                label='Check all that apply'
                style={{ marginBottom: 0 }}
              ></FormItem>

              <FormItem
                label='Special transportation is required to and from schools and/or between schools.'
                name='transportationSchoolRequired'
                {...validationProps('transportationSchoolRequired')}
              >
                <Radio.Group
                  defaultValue={data.transportationSchoolRequired}
                  {...handleMouseEvents}
                  buttonStyle='solid'
                  disabled={formDisabledState.transportationSchoolRequired}
                  name='transportationSchoolRequired'
                >
                  <Radio.Button value='yes'>Yes</Radio.Button>
                  <Radio.Button value='no'>No</Radio.Button>
                </Radio.Group>
              </FormItem>

              <FormItem
                label='Special transportation is required in and around school buildings.'
                name='transportationBuildingsRequired'
                {...validationProps('transportationBuildingsRequired')}
              >
                <Radio.Group
                  defaultValue={data.transportationBuildingsRequired}
                  {...handleMouseEvents}
                  buttonStyle='solid'
                  disabled={formDisabledState.transportationBuildingsRequired}
                  name='transportationBuildingsRequired'
                >
                  <Radio.Button value='yes'>Yes</Radio.Button>
                  <Radio.Button value='no'>No</Radio.Button>
                </Radio.Group>
              </FormItem>

              <FormItem
                label='Specialized equipment (such as special or adapted buses, lifts, and ramps) is required.'
                name='specializedEquipmentRequired'
                {...validationProps('specializedEquipmentRequired')}
              >
                <Radio.Group
                  defaultValue={data.specializedEquipmentRequired}
                  {...handleMouseEvents}
                  buttonStyle='solid'
                  disabled={formDisabledState.specializedEquipmentRequired}
                  name='specializedEquipmentRequired'
                >
                  <Radio.Button value='yes'>Yes</Radio.Button>
                  <Radio.Button value='no'>No</Radio.Button>
                </Radio.Group>
              </FormItem>

              <Form.Item
                label='Plan explanation'
                name='specializedEquipmentRequiredExplanation'
                {...validationProps('specializedEquipmentRequiredExplanation')}
              >
                <Editor
                  {...handleEditorEvents}
                  formField='specializedEquipmentRequiredExplanation'
                  disabled={
                    formDisabledState.specializedEquipmentRequiredExplanation
                  }
                />
              </Form.Item>
            </Card>

            <Card
              title='Extended School Year Services'
              style={{
                marginBottom: 30
              }}
            >
              <FormItem
                label='Extended school year services are needed.'
                name='extendedSchoolYearServices'
                {...validationProps('extendedSchoolYearServices')}
              >
                <Radio.Group
                  defaultValue={data.extendedSchoolYearServices}
                  onChange={e => {
                    setExtendedSchoolYearServices(e.target.value)
                    handleMouseEvents.onChange(e)
                  }}
                  buttonStyle='solid'
                  disabled={formDisabledState.extendedSchoolYearServices}
                  name='extendedSchoolYearServices'
                >
                  <Radio.Button value='yes'>Yes</Radio.Button>
                  <Radio.Button value='no'>No</Radio.Button>
                </Radio.Group>
              </FormItem>

              {extendedSchoolYearServices === 'yes' && (
                <>
                  <Card
                    title='Extended Services'
                    style={{
                      marginBottom: '30px'
                    }}
                  >
                    <Input.Group
                      onFocus={() => {
                        handleEditorEvents.onFocus('extendedServices')
                      }}
                      onBlur={() => {
                        handleEditorEvents.onBlur(
                          'extendedServices',
                          JSON.stringify(form.getFieldValue('extendedServices'))
                        )
                      }}
                    >
                      <Form.List
                        name='extendedServices'
                        {...validationProps('extendedServices')}
                      >
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map((field, index) => (
                              <div key={field.key}>
                                <Card
                                  style={{
                                    marginBottom: '30px',
                                    borderStyle: 'dashed'
                                  }}
                                >
                                  <Form.Item
                                    {...field}
                                    label='Special Education Service'
                                    name={[
                                      field.name,
                                      'extendedServicesSpecialEducationService'
                                    ]}
                                    key={[
                                      field.fieldKey,
                                      'extendedServicesSpecialEducationService'
                                    ]}
                                  >
                                    <Input
                                      disabled={
                                        formDisabledState.extendedServices
                                      }
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    {...field}
                                    label='Location'
                                    name={[
                                      field.name,
                                      'extendedServicesLocation'
                                    ]}
                                    key={[
                                      field.fieldKey,
                                      'extendedServicesLocation'
                                    ]}
                                  >
                                    <Input
                                      disabled={
                                        formDisabledState.extendedServices
                                      }
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    {...field}
                                    label='Amount/Frequency of Services'
                                    name={[
                                      field.name,
                                      'extendedServicesFrequency'
                                    ]}
                                    key={[
                                      field.fieldKey,
                                      'extendedServicesFrequency'
                                    ]}
                                  >
                                    <Input
                                      disabled={
                                        formDisabledState.extendedServices
                                      }
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    {...field}
                                    label='Initiation of Services'
                                    name={[
                                      field.name,
                                      'extendedServicesInitiation'
                                    ]}
                                    key={[
                                      field.fieldKey,
                                      'extendedServicesInitiation'
                                    ]}
                                  >
                                    <Input
                                      disabled={
                                        formDisabledState.extendedServices
                                      }
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    {...field}
                                    label='Duration of Services'
                                    name={[
                                      field.name,
                                      'extendedServicesDuration'
                                    ]}
                                    key={[
                                      field.fieldKey,
                                      'extendedServicesDuration'
                                    ]}
                                  >
                                    <Input
                                      disabled={
                                        formDisabledState.extendedServices
                                      }
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    {...field}
                                    label='Goal #(s) Addressed'
                                    name={[
                                      field.name,
                                      'extendedServicesGoalsAddressed'
                                    ]}
                                    key={[
                                      field.fieldKey,
                                      'extendedServicesGoalsAddressed'
                                    ]}
                                  >
                                    <Input
                                      disabled={
                                        formDisabledState.extendedServices
                                      }
                                    />
                                  </Form.Item>
                                  <Button
                                    danger
                                    disabled={
                                      formDisabledState.extendedServices
                                    }
                                    onClick={() => {
                                      remove(field.name)
                                      handleEditorEvents.onBlur(
                                        'extendedServices',
                                        JSON.stringify(
                                          form.getFieldValue('extendedServices')
                                        )
                                      )
                                    }}
                                  >
                                    Remove Extended Service
                                  </Button>
                                </Card>
                              </div>
                            ))}

                            <Form.Item>
                              {
                                // Default value below prevents Quill from breaking the render
                              }
                              <Button
                                disabled={formDisabledState.extendedServices}
                                type='dashed'
                                onClick={() =>
                                  add({
                                    extendedServicesSpecialEducationService: '',
                                    extendedServicesLocation: '',
                                    extendedServicesFrequency: '',
                                    extendedServicesInitiation: '',
                                    extendedServicesDuration: '',
                                    extendedServicesGoalsAddressed: ''
                                  })
                                }
                                block
                                icon={<PlusOutlined />}
                              >
                                Add Extended Service
                              </Button>
                            </Form.Item>
                            <Text type='warning'>
                              {validationProps('extendedServices').help}
                            </Text>
                          </>
                        )}
                      </Form.List>
                    </Input.Group>
                  </Card>
                </>
              )}
            </Card>

            {formError && <Paragraph type='danger'>{formError}</Paragraph>}
            <StudentFormActions
              query={PRINT_FORM3454Q}
              extractDataProp='printStudentForm3454Q'
              canSave={canSave}
              handleActionEvents={handleActionEvents}

              getFormFieldValues={getFormFieldValues}
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired
}

const StudentForm3454Q = () => {
  // get current form information
  const { currentStudentInfo, currentEditableForm, studentMeetingId } =
    useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId } = currentEditableForm

  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    QUERY_STUDENT_COMMON_SIS,
    { variables: { id: studentId } }
  )
  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORM3454Q_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )

  const createInitialFormData = (studentInfo, versionInfo) => {
    let initialValues = {}

    if (!studentInfo || !versionInfo) return initialValues

    const { studentForm3454Q: form } = versionInfo
    const { student } = studentInfo
    const { formDate, studentFullName } = form

    initialValues = {
      ...form,
      formDate: formDate ? moment(formDate) : moment(Date.now()),
      studentFullName: studentFullName || student.fullName || ''
    }

    return initialValues
  }
  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
          />
        }
      />
    </FormErrorProvider>
  )
}
export default StudentForm3454Q
