import React from "react";

import Page from "../../components/Page";
import StudentsList from "../../components/sections/student/StudentsList";
import { QUERY_STUDENT } from "../../operations/queries/students";
import { useQuery } from '@apollo/client'

const Students = () => {
  let id = window.location.pathname
  console.log(id, 'what are you')
  const breadcrumb = [
    {
      path: "/",
      breadcrumbName: "Home",
    },
    {
      path: "",
      breadcrumbName: "Students",
    },
  ];
  return (
    <>
      <Page title="Students" breadcrumb={breadcrumb}>
        <StudentsList />
      </Page>
    </>
  );
};

export default Students;
