// react
import React from "react";

// react router
import { Link, useLocation } from "react-router-dom";

// ant design
import { Menu } from "antd";
import { FileOutlined } from "@ant-design/icons";

// images
import TamesLogo from "../../assets/images/spedassist-logo.gif";

const SideMenu = () => {
  const location = useLocation();
  return (
    <>
      <div className="sideMenu-logo">
        <img className="district-logo" src={TamesLogo} alt="Tames Logo" />
      </div>
      <div className="superAdminSideMenu">
        <Menu
          theme="light"
          defaultSelectedKeys={["/districts"]}
          mode="horizontal"
          selectedKeys={[location.pathname]}
        >
          <Menu.Item key="/districts" icon={<FileOutlined />}>
            <Link to="/districts">Districts</Link>
          </Menu.Item>
          <Menu.Item key="/schools" icon={<FileOutlined />}>
            <Link to="/schools">Schools</Link>
          </Menu.Item>
          <Menu.Item key="/users" icon={<FileOutlined />}>
            <Link to="/users">Users</Link>
          </Menu.Item>
          <Menu.Item key="/students" icon={<FileOutlined />}>
            <Link to="/students">Students</Link>
          </Menu.Item>
          <Menu.Item key="/grades" icon={<FileOutlined />}>
            <Link to="/grades">Grades</Link>
          </Menu.Item>
          <Menu.Item key="/documents" icon={<FileOutlined />}>
            <Link to="/documents">Documents</Link>
          </Menu.Item>
          <Menu.Item key="/messages" icon={<FileOutlined />}>
            <Link to="/messages">Messages</Link>
          </Menu.Item>
        </Menu>
      </div>
    </>
  );
};

export default SideMenu;
