import { gql } from "@apollo/client";

export const PRINT_FORM3457G = gql`
  mutation printStudentForm3457G(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $studentDob: String
    $parent1: String
    $parent2: String
    $dateOfContact: String
    $districtPersonnelName: String
    $beginChangesDate: String
    $communicationMode: [String]
    $changesDescription: String
    $documentContactName: String
    $documentContactTitle: String
    $documentContactPhone: String
    $signatureName: String
    $signatureTitle: String
    $formAction: String
$formStatus: String
  ) {
    printStudentForm3457G(
      studentForm3457GInput: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        studentDob: $studentDob
        parent1: $parent1
        parent2: $parent2
        dateOfContact: $dateOfContact
        districtPersonnelName: $districtPersonnelName
        beginChangesDate: $beginChangesDate
        changesDescription: $changesDescription
        communicationMode: $communicationMode
        documentContactName: $documentContactName
        documentContactTitle: $documentContactTitle
        documentContactPhone: $documentContactPhone
        signatureName: $signatureName
        signatureTitle: $signatureTitle
formAction: $formAction
formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;
