import { gql } from "@apollo/client";

export const PRINT_FORM3457L = gql`
  mutation printStudentForm3457L(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $studentDob: String
    $parentConsent: String
    $studentConsent: String
    $parent1: String
    $parent2: String
    $includeDHS: Boolean
    $includeDRS: Boolean
    $includeDD: Boolean
    $includeDSCC: Boolean
    $includePEDS: Boolean
    $includeMilitary: Boolean
    $includeOther: Boolean
    $nameOther: String
    $schoolPersonnelTitle: String
    $schoolPersonnelPhone: String
    $consentAgencyInvitation: String
    $parentSignatureDate: String
    $studentSignatureDate: String
    $formAction: String
$formStatus: String
  ) {
    printStudentForm3457L(
      studentForm3457LInput: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        studentDob: $studentDob
        parentConsent: $parentConsent
        studentConsent: $studentConsent
        parent1: $parent1
        parent2: $parent2
        includeDHS: $includeDHS
        includeDRS: $includeDRS
        includeDD: $includeDD
        includeDSCC: $includeDSCC
        includePEDS: $includePEDS
        includeMilitary: $includeMilitary
        includeOther: $includeOther
        nameOther: $nameOther
        schoolPersonnelTitle: $schoolPersonnelTitle
        schoolPersonnelPhone: $schoolPersonnelPhone
        consentAgencyInvitation: $consentAgencyInvitation
        parentSignatureDate: $parentSignatureDate
        studentSignatureDate: $studentSignatureDate
formAction: $formAction
formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;
