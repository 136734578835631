import { gql } from "@apollo/client";

export const QUERY_FORM3454S_VERSION = gql`
  query studentForm3454S(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
  ) {
    studentForm3454S(
      studentFormId: $studentFormId
      studentId: $studentId
      formId: $formId
      schoolId: $schoolId
      studentMeetingId: $studentMeetingId
    ) {
      versionId
      formId
      formDate
      studentFullName
      additionalInformation
      formAction
      formStatus
    }
  }
`;
