import { gql } from "@apollo/client";

export const QUERY_FORM3457M_VERSION = gql`
  query studentForm3457M(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
  ) {
    studentForm3457M(
      studentFormId: $studentFormId
      studentId: $studentId
      formId: $formId
      schoolId: $schoolId
      studentMeetingId: $studentMeetingId
    ) {
      versionId
      formId
      formDate
      studentFullName
      studentDob
      parent1
      parent2
      evaluationType
      additionalEvaluationDays
      extensionReason
      representativeName
      representativeTitle
      parentAgreement
      previousDate
      newDate
      agreementDate
      parentSignatureDate
      formAction
      formStatus
    }
  }
`;
