import { gql } from "@apollo/client";

export const PRINT_FORM3457C = gql`
  mutation printStudentForm3457C(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $studentDob: String
    $parent1: String
    $parent2: String
    $guardianAdditionalRequirementConsent: String
    $guardianEvaluationConsent: String
    $signatureDate: String
    $formAction: String
$formStatus: String
  ) {
    printStudentForm3457C(
      studentForm3457CInput: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        studentDob: $studentDob
        parent1: $parent1
        parent2: $parent2
        guardianAdditionalRequirementConsent: $guardianAdditionalRequirementConsent
        guardianEvaluationConsent: $guardianEvaluationConsent
        signatureDate: $signatureDate
formAction: $formAction
formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;
