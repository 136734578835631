import React from "react";

import Page from "../../components/Page";
import RolesList from "../../components/sections/role/RolesList";

const Roles = () => {
  const breadcrumb = [
    {
      path: "/",
      breadcrumbName: "Home",
    },
    {
      path: "",
      breadcrumbName: "Roles",
    },
  ];
  return (
    <>
      <Page title="Roles" breadcrumb={breadcrumb}>
        <div className="page-container">
          <RolesList />
        </div>
      </Page>
    </>
  );
};

export default Roles;
