import { gql } from "@apollo/client";

export const PRINT_FORMTAMES3 = gql`
  mutation printStudentFormTames3(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $studentDob: String
    $visionScreeningDate: String
    $visionScreeningStatus: Boolean
    $visionScreeningCorrected: String
    $visionScreeningConductedBy: String
    $hearingScreeningDate: String
    $hearingScreeningStatus: Boolean
    $hearingScreeningCorrected: String
    $hearingScreeningConductedBy: String
    $determinantReading: Boolean
    $determinantMath: Boolean
    $determinantEnglish: Boolean
    $determinantEnvironmentalCultural: Boolean
    $formAction: String
$formStatus: String
    $domains: [StudentFormTames3DomainsInput]
  ) {
    printStudentFormTames3(
      studentFormTames3Input: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        studentDob: $studentDob
        visionScreeningDate: $visionScreeningDate
        visionScreeningStatus: $visionScreeningStatus
        visionScreeningCorrected: $visionScreeningCorrected
        visionScreeningConductedBy: $visionScreeningConductedBy
        hearingScreeningDate: $hearingScreeningDate
        hearingScreeningStatus: $hearingScreeningStatus
        hearingScreeningCorrected: $hearingScreeningCorrected
        hearingScreeningConductedBy: $hearingScreeningConductedBy
        determinantReading: $determinantReading
        determinantMath: $determinantMath
        determinantEnglish: $determinantEnglish
        determinantEnvironmentalCultural: $determinantEnvironmentalCultural
formAction: $formAction
formStatus: $formStatus
        domains: $domains
      }
    ) {
      link
      fileName
    }
  }
`;
