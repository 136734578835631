import { gql } from "@apollo/client";

export const PRINT_FORM3457H = gql`
  mutation printStudentForm3457H(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $studentDob: String
    $parent1: String
    $parent2: String
    $meetingDateAndTime: String
    $parentSignatureDate: String
    $authSignatureDate: String
    $communicationMode: [String]
    $agreeMemberNotDiscussed: Boolean
    $membersNotDiscussed: [String]
    $agreeMemberDiscussed: Boolean
    $membersDiscussed: [String]
    $documentContactName: String
    $documentContactTitle: String
    $documentContactPhone: String
    $signatureName: String
    $signatureTitle: String
    $formAction: String
$formStatus: String
  ) {
    printStudentForm3457H(
      studentForm3457HInput: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        studentDob: $studentDob
        parent1: $parent1
        parent2: $parent2
        meetingDateAndTime: $meetingDateAndTime
        parentSignatureDate: $parentSignatureDate
        authSignatureDate: $authSignatureDate
        communicationMode: $communicationMode
        agreeMemberNotDiscussed: $agreeMemberNotDiscussed
        membersNotDiscussed: $membersNotDiscussed
        agreeMemberDiscussed: $agreeMemberDiscussed
        membersDiscussed: $membersDiscussed
        documentContactName: $documentContactName
        documentContactTitle: $documentContactTitle
        documentContactPhone: $documentContactPhone
        signatureName: $signatureName
        signatureTitle: $signatureTitle
formAction: $formAction
formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;
