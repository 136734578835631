import { gql } from "@apollo/client";

export const INSERT_GRADE = gql`
  mutation insertGrade($name: String!, $order: String!, $districtId: ID!) {
    insertGrade(
      gradeInput: { name: $name, order: $order, districtId: $districtId }
    ) {
      id
      districtId
      name
      order
      hidden
    }
  }
`;

export const UPDATE_GRADE = gql`
  mutation updateGrade($id: ID!, $gradeInput: GradeInput) {
    updateGrade(id: $id, gradeInput: $gradeInput) {
      id
      districtId
      name
      order
      hidden
    }
  }
`;
