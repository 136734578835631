import React, { useState } from 'react'

import PropTypes from 'prop-types'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const Editor = ({ value, onChange, onFocus, onBlur, formField, disabled }) => {
  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'color',
    'background',
    'align'
  ]

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' }
      ],
      [{ align: [] }],
      [{ color: [] }, { background: [] }],
      ['link', 'image'],
      ['clean']
    ]
  }

  //Pasting data into editor using Ctrl + V causes onBlur to fire.
  //Ignore these events (quill source will be "silent").
  const handleQuillBlur = (range, source, editor) => {
    setTimeout(() => {
      if (source !== 'silent') {
        onBlur(formField, value)
      }
    }, 100) // 100ms is random...
  }
  return (
    <div className={`text-editor ${disabled ? 'text-editor-disabled' : ''}`}>
      <ReactQuill
        readOnly={disabled}
        value={value || ''}
        theme='snow'
        onChange={onChange}
        modules={modules}
        formats={formats}
        formField={formField}
        onFocus={disabled ? "" : ((e) => onFocus(formField))}
        onBlur={disabled ? "" : ((range, source, editor) => handleQuillBlur(range, source, editor))}
      />
    </div>
  )
}

Editor.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  formField: PropTypes.string
}

export default Editor
