import React from "react";

const IprcDetails = () => {
  return (
    <>
      <div className="iep-data">
        <p>
          <strong>IPRC Annual Review Date: </strong>
          <span>Not Set</span>
        </p>{" "}
        <br />
        <p>
          <strong>IPRC Exceptionalities: </strong>
          <ul>
            <li> Communication - Deaf and Hard of Hearing </li>
          </ul>
        </p>
        <br />
        <p>
          <strong>IPRC Placement Decision(s): </strong>
          <ul>
            <li> Communication - Deaf and Hard of Hearing </li>
          </ul>
        </p>
        <br />
      </div>
    </>
  );
};

export default IprcDetails;
