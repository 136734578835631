// react
import React, { useState } from "react";

// ant design
import { Card } from "antd";

// operations
import { QUERY_DISTRICT_INFO_REPORT } from "../../../operations/queries/reportBuilder";

// components
import Listing from "../../Listing";
import DistrictReportSearchForm from "./DistrictReportSearchForm";

const DistrictReport = () => {
  const [criterias, setCriterias] = useState();

  const onSearch = (criterias) => {
    setCriterias(criterias);
  };

  const columns = [
    {
      title: "Number of schools",
      dataIndex: "schools",
    },
    {
      title: "Number of students",
      dataIndex: "students",
    },
    {
      title: "Number of IEPs",
      dataIndex: "ieps",
    },
    {
      title: "Number of users",
      dataIndex: "users",
    },
  ];

  const fields = {
    schools: {
      header: "Number of schools",
      formatter: (fieldValue) => fieldValue,
    },
    students: {
      header: "Number of students",
      formatter: (fieldValue) => fieldValue,
    },
    ieps: {
      header: "Number of IEPs",
      formatter: (fieldValue) => fieldValue,
    },
    users: {
      header: "Number of users",
      formatter: (fieldValue) => fieldValue,
    },
  };

  return (
    <>
      <div className="page-container">
        <Card title="Filters" bordered={false}>
          <DistrictReportSearchForm onSearch={onSearch} />
        </Card>
      </div>
      {!!criterias && (
        <Listing
          query={QUERY_DISTRICT_INFO_REPORT}
          exportable={{
            exportTableButton: {
              fields,
            },
          }}
          columns={columns}
          criterias={criterias}
          fields={fields}
          dataKey="districtInfoReport"
          render={(table, exportCsvButton) => {
            return (
              <>
                <div className="page-container">
                  <Card
                    title="District information report"
                    bordered={false}
                    extra={exportCsvButton}
                  >
                    {table}
                  </Card>
                </div>
              </>
            );
          }}
        />
      )}
    </>
  );
};

export default DistrictReport;
