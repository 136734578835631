import { gql } from "@apollo/client";

export const PRINT_FORM3457BC = gql`
  mutation printStudentForm3457BC(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $academicRelevance: Boolean
    $academicExisting: String
    $academicAdditional: String
    $academicSources: String
    $functionalRelevance: Boolean
    $functionalExisting: String
    $functionalAdditional: String
    $functionalSources: String
    $cognitiveRelevance: Boolean
    $cognitiveExisting: String
    $cognitiveAdditional: String
    $cognitiveSources: String
    $communicationRelevance: Boolean
    $communicationExisting: String
    $communicationAdditional: String
    $communicationSources: String
    $performanceRelevance: Boolean
    $performanceExisting: String
    $performanceAdditional: String
    $performanceSources: String
    $hearingRelevance: Boolean
    $hearingExisting: String
    $hearingAdditional: String
    $hearingSources: String
    $motorRelevance: Boolean
    $motorExisting: String
    $motorAdditional: String
    $motorSources: String
    $socialRelevance: Boolean
    $socialExisting: String
    $socialAdditional: String
    $socialSources: String
    $formAction: String
$formStatus: String
  ) {
    printStudentForm3457BC(
      studentForm3457BCInput: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        academicRelevance: $academicRelevance
        academicExisting: $academicExisting
        academicAdditional: $academicAdditional
        academicSources: $academicSources
        functionalRelevance: $functionalRelevance
        functionalExisting: $functionalExisting
        functionalAdditional: $functionalAdditional
        functionalSources: $functionalSources
        cognitiveRelevance: $cognitiveRelevance
        cognitiveExisting: $cognitiveExisting
        cognitiveAdditional: $cognitiveAdditional
        cognitiveSources: $cognitiveSources
        communicationRelevance: $communicationRelevance
        communicationExisting: $communicationExisting
        communicationAdditional: $communicationAdditional
        communicationSources: $communicationSources
        performanceRelevance: $performanceRelevance
        performanceExisting: $performanceExisting
        performanceAdditional: $performanceAdditional
        performanceSources: $performanceSources
        hearingRelevance: $hearingRelevance
        hearingExisting: $hearingExisting
        hearingAdditional: $hearingAdditional
        hearingSources: $hearingSources
        motorRelevance: $motorRelevance
        motorExisting: $motorExisting
        motorAdditional: $motorAdditional
        motorSources: $motorSources
        socialRelevance: $socialRelevance
        socialExisting: $socialExisting
        socialAdditional: $socialAdditional
        socialSources: $socialSources
formAction: $formAction
formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;
