import React from "react";

import Page from "../../components/Page";
import ImportForm from "../../components/sections/import/ImportForm";

const Import = () => {
  const title = "Import";

  const breadcrumb = [
    {
      path: "/",
      breadcrumbName: "Home",
    },
    {
      path: "/import",
      breadcrumbName: title,
    },
  ];
  return (
    <>
      <Page title={title} breadcrumb={breadcrumb}>
        <div className="page-container">
          <div className="page-padding-leftRight page-padding-topBottom">
            <ImportForm />
          </div>
        </div>
      </Page>
    </>
  );
};

export default Import;
