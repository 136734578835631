import { gql } from "@apollo/client";

export const INSERT_SCHOOL = gql`
  mutation insertSchool(
    $districtId: ID!
    $name: String!
    $schoolCode: String!
    $address: String
    $city: String
    $state: String
    $country: String
    $zip: String
    $principalName: String
    $hidden: Boolean
  ) {
    insertSchool(
      schoolInput: {
        districtId: $districtId
        name: $name
        schoolCode: $schoolCode
        address: $address
        city: $city
        state: $state
        country: $country
        zip: $zip
        principalName: $principalName
        hidden: $hidden
      }
    ) {
      id
      name
      schoolCode
      address
      city
      state
      country
      zip
      principalName
      hidden
      district {
        id
      }
    }
  }
`;

export const UPDATE_SCHOOL = gql`
  mutation updateSchool($id: ID!, $schoolInput: SchoolInput) {
    updateSchool(id: $id, schoolInput: $schoolInput) {
      id
      name
      schoolCode
      address
      city
      state
      country
      zip
      principalName
      hidden
      district {
        id
      }
    }
  }
`;
