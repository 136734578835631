import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { notificationError } from "../../utils/notification";

const FileDownload = ({ url, fileName, version }) => {
  //console.log("In FileDownload: ", url, fileName, version)
  useEffect(() => {
    if (!url || !fileName) return;
    fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => {
        notificationError(`Failed to download file at : ${url}`);
      });
  }, [version]);
  return <></>;
};

FileDownload.propTypes = {
  url: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
};

export default FileDownload;
