import { gql } from "@apollo/client";

export const QUERY_FORM3454O_VERSION = gql`
  query studentForm3454O(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
  ) {
    studentForm3454O(
      studentFormId: $studentFormId
      studentId: $studentId
      formId: $formId
      schoolId: $schoolId
      studentMeetingId: $studentMeetingId
    ) {
      versionId
      formId
      formDate
      studentFullName
      studentDob
      participateClassroomAssessments
      districtAdministerAssessments
      districtAdministerAssessmentInGrade
      gradeLevel
      studentParticipation
      stateAcademicAssessmentNotAdministered
      illinoisReadinessAssessment
      dynamicLearningMaps
      collegeBoard
      illinoisScienceAssessment
      physicalFitnessAssessmentFitnessGram
      willNotParticipatePhysicalFitnessAssessment
      physicalFitnessAssessmentBrockport
      willNotParticipatePhysicalFitnessAssessment2
      kindergartenDevelopment
      indicateSubset
      indicateWithAccommodationSubset
      englishLearner
      ifYesStudentWill
      classroomAssessments
      districtAssessments
      collegeAssessments
      scienceAssessment
      physicalFitnessAssessment
      kidsAssessment
      accessAlternativeAccess
      formAction
      formStatus
    }
  }
`;
