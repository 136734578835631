import { gql } from "@apollo/client";

export const PRINT_FORM3454S = gql`
  mutation printStudentForm3454S(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $additionalInformation: String
    $formAction: String
$formStatus: String
  ) {
    printStudentForm3454S(
      studentForm3454SInput: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        additionalInformation: $additionalInformation
formAction: $formAction
formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;
