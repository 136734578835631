import { gql } from "@apollo/client";

export const PRINT_FORM3454 = gql`
  mutation printStudentForm3454(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentDob: String
    $recentEvaluationDate: String
    $nextEvaluationDate: String
    $safeguardReviewDate: String
    $safeguardsLanguage: String
    $highSchoolGraduationDate: String
    $purposeOfConference: [String]
    $purposeOfConferenceOtherDescription: String
    $studentFullName: String
    $address: String
    $city: String
    $state: String
    $country: String
    $studentCode: String
    $studentLanguage: String
    $studentGrade: String
    $studentGender: String
    $studentEthnicity: String
    $placement: Boolean
    $disabilities: String
    $medicaidNumber: String
    $residentDistrict: String
    $residentSchool: String
    $servingDistrict: String
    $servingSchool: String
    $parent1: String
    $educationSurrogateParent1: Boolean
    $parent1Email: String
    $parent1PhoneNumber: String
    $parent1Address: String
    $parent1City: String
    $parent1State: String
    $parent1Zip: String
    $parent1PrimaryLanguage: String
    $interpreterParent1: Boolean
    $parent2: String
    $educationSurrogateParent2: Boolean
    $parent2Email: String
    $parent2PhoneNumber: String
    $parent2Address: String
    $parent2City: String
    $parent2State: String
    $parent2Zip: String
    $parent2PrimaryLanguage: String
    $interpreterParent2: Boolean
    $parentRequestedInterpreter: String
    $interpreterProvided: String
    $interpreterProvidedLanguage: String
    $parentRequestedInterpreterServeNoOtherRole: String
    $parentInterpreterRoleRequestGranted: String
    $transferOfRights: String
    $parentGivenEvaluationReport: String
    $evaluationReportLanguage: String
    $parentGivenIep: String
    $iepLanguage: String
    $parentGivenDistrictBehavioralPolicy: String
    $parentGivenDistrictBehavioralInterventionProcedure: String
    $parentGivenPuns: String
    $parentGivenAble: String
    $parentGivenThreeDayDraft: String
    $threeDayDraftProvidedVia: String
    $threeDayDraftDate: String
    $threeDayDraftLanguage: String
    $meetingsAttendedParent1: [String]
    $meetingsAttendedParent2: [String]
    $meetingsAttendedStudent: [String]
    $meetingsAttendedLEARepresentative: [String]
    $meetingsAttendedGeneralEducationTeacher: [String]
    $meetingsAttendedSpecialEducationTeacher: [String]
    $meetingsAttendedSchoolPsychologist: [String]
    $meetingsAttendedSchoolSocialWorker: [String]
    $meetingsAttendedSpeechLanguagePathologist: [String]
    $meetingsAttendedBilingualSpecialist: [String]
    $meetingsAttendedInterpreter: [String]
    $meetingsAttendedSchoolNurse: [String]
    $meetingsAttendedOther: [String]
    $meetingsAttendedOtherText: String
    $parentContactAttemptsBeforeMeeting: String
    $meetingScheduledAtAgreedTimeAndPlace: String
    $meetingScheduleExplanation: String
    $formAction: String
    $formStatus: String
  ) {
    printStudentForm3454(
      studentForm3454Input: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentDob: $studentDob
        recentEvaluationDate: $recentEvaluationDate
        nextEvaluationDate: $nextEvaluationDate
        safeguardReviewDate: $safeguardReviewDate
        safeguardsLanguage: $safeguardsLanguage
        highSchoolGraduationDate: $highSchoolGraduationDate
        purposeOfConference: $purposeOfConference
        purposeOfConferenceOtherDescription: $purposeOfConferenceOtherDescription
        studentFullName: $studentFullName
        address: $address
        city: $city
        state: $state
        country: $country
        studentCode: $studentCode
        studentLanguage: $studentLanguage
        studentGrade: $studentGrade
        studentGender: $studentGender
        studentEthnicity: $studentEthnicity
        placement: $placement
        disabilities: $disabilities
        medicaidNumber: $medicaidNumber
        residentDistrict: $residentDistrict
        residentSchool: $residentSchool
        servingDistrict: $servingDistrict
        servingSchool: $servingSchool
        parent1: $parent1
        educationSurrogateParent1: $educationSurrogateParent1
        parent1Email: $parent1Email
        parent1PhoneNumber: $parent1PhoneNumber
        parent1Address: $parent1Address
        parent1City: $parent1City
        parent1State: $parent1State
        parent1Zip: $parent1Zip
        parent1PrimaryLanguage: $parent1PrimaryLanguage
        interpreterParent1: $interpreterParent1
        parent2: $parent2
        educationSurrogateParent2: $educationSurrogateParent2
        parent2Email: $parent2Email
        parent2PhoneNumber: $parent2PhoneNumber
        parent2Address: $parent2Address
        parent2City: $parent2City
        parent2State: $parent2State
        parent2Zip: $parent2Zip
        parent2PrimaryLanguage: $parent2PrimaryLanguage
        interpreterParent2: $interpreterParent2
        parentRequestedInterpreter: $parentRequestedInterpreter
        interpreterProvided: $interpreterProvided
        interpreterProvidedLanguage: $interpreterProvidedLanguage
        parentRequestedInterpreterServeNoOtherRole: $parentRequestedInterpreterServeNoOtherRole
        parentInterpreterRoleRequestGranted: $parentInterpreterRoleRequestGranted
        transferOfRights: $transferOfRights
        parentGivenEvaluationReport: $parentGivenEvaluationReport
        evaluationReportLanguage: $evaluationReportLanguage
        parentGivenIep: $parentGivenIep
        iepLanguage: $iepLanguage
        parentGivenDistrictBehavioralPolicy: $parentGivenDistrictBehavioralPolicy
        parentGivenDistrictBehavioralInterventionProcedure: $parentGivenDistrictBehavioralInterventionProcedure
        parentGivenPuns: $parentGivenPuns
        parentGivenAble: $parentGivenAble
        parentGivenThreeDayDraft: $parentGivenThreeDayDraft
        threeDayDraftProvidedVia: $threeDayDraftProvidedVia
        threeDayDraftDate: $threeDayDraftDate
        threeDayDraftLanguage: $threeDayDraftLanguage
        meetingsAttendedParent1: $meetingsAttendedParent1
        meetingsAttendedParent2: $meetingsAttendedParent2
        meetingsAttendedStudent: $meetingsAttendedStudent
        meetingsAttendedLEARepresentative: $meetingsAttendedLEARepresentative
        meetingsAttendedGeneralEducationTeacher: $meetingsAttendedGeneralEducationTeacher
        meetingsAttendedSpecialEducationTeacher: $meetingsAttendedSpecialEducationTeacher
        meetingsAttendedSchoolPsychologist: $meetingsAttendedSchoolPsychologist
        meetingsAttendedSchoolSocialWorker: $meetingsAttendedSchoolSocialWorker
        meetingsAttendedSpeechLanguagePathologist: $meetingsAttendedSpeechLanguagePathologist
        meetingsAttendedBilingualSpecialist: $meetingsAttendedBilingualSpecialist
        meetingsAttendedInterpreter: $meetingsAttendedInterpreter
        meetingsAttendedSchoolNurse: $meetingsAttendedSchoolNurse
        meetingsAttendedOther: $meetingsAttendedOther
        meetingsAttendedOtherText: $meetingsAttendedOtherText
        parentContactAttemptsBeforeMeeting: $parentContactAttemptsBeforeMeeting
        meetingScheduledAtAgreedTimeAndPlace: $meetingScheduledAtAgreedTimeAndPlace
        meetingScheduleExplanation: $meetingScheduleExplanation
        formAction: $formAction
        formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;
