import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { Modal, Form, Input } from 'antd'
import AuthContext from "../utils/authProvider";
import { notificationSuccess, notificationError } from '../utils/notification'
import { EMAIL_ISSUE_REPORT } from '../operations/mutations/users'
import Editor from './elements/common/Editor'

const IssueModal = ({ visible, setVisible }) => {
  const Auth = useContext(AuthContext);

  const [form] = Form.useForm()
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [emailIssueReport] = useMutation(EMAIL_ISSUE_REPORT)
  const handleOk = async () => {
    try {
      await form.validateFields()
      setConfirmLoading(true)

      //Send the issue report to Tames
      try {
        const userId = Auth.userId;
        const description = form.getFieldsValue('description').description
        const response = await emailIssueReport({
          variables: { userId: userId, description: description }
        })
        console.log('emailIssueReport-response', response)
        notificationSuccess('Your issue as been sent to the Tames Assist team.')
      } catch (error) {
        const message =
          error?.metadata?.graphQLErrors?.[0]?.message ||
          error?.message ||
          'An unknown error occurred, please contact your administrator. E006'
        notificationError('The following error occurred and your issue report was not sent.', message + '.')
      }

      // close the modal
      form.resetFields()
      setConfirmLoading(false)
      setVisible(false)

    } catch (e) { }
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const doNothing = () => {
    console.log('do nothing')
  }

  return (
    <Modal
      title='Report an issue'
      open={visible}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
    >
      <Form name='submitIssue' form={form}>
        <Form.Item
          name='description'
          initialValue=''
          rules={[
            {
              required: true,
              message: 'Please enter the description of your issue.'
            }
          ]}
        >
          {/* <Input.TextArea
            placeholder="Details of issue"
            autoSize={{ minRows: 5, maxRows: 10 }}
          /> */}
          <Editor
            value=''
            formField='description'
            onChange={doNothing}
            onFocus={doNothing}
            onBlur={doNothing}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

IssueModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired
}

export default IssueModal
