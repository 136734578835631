import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Form, Input, Button, Row, Col, Card } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

// Contexts
import AuthContext from "../../../utils/authProvider";

// other libraries
import { notificationError } from "../../../utils/notification";
import TamesLogo from "../../../assets/images/tames_assist_logo.png";

const LoginForm = () => {
  const Auth = useContext(AuthContext);
  const location = useLocation();
  const history = useNavigate();
  const [loading, setLoading] = useState();
  useEffect(() => {
    // if already signed in redirects to the dashboard
    if (Auth.isSignedIn) {
      history("/");
    }
  }, []);

  const onFinish = async (values) => {
    const { email, password } = values;
    setLoading(true);
    try {
      await Auth.signIn(email, password);
      // redirect to dashboard if the current route is /signin
      //when signed in redirect to dashboard from anywhere so that certain routes have props
      history("/");
    } catch (error) {
      const code = error?.metadata?.graphQLErrors?.[0]?.extensions?.code;
      const errorCode =
        error?.metadata?.graphQLErrors?.[0]?.extensions?.errorCode;
      if (errorCode === "NO_EMAIL_VERIFIED") {
        history(`/sendverification/${email}`);
      } else {
        const message =
          error?.metadata?.graphQLErrors?.[0]?.message ||
          "An unknown error occurred, please contact your administrator. E002";
          console.log(error?.metadata?.graphQLErrors);

        notificationError(
          "Sign-in failed",
          code === "UNAUTHENTICATED" ? message : "Email or password is invalid"
        );
      }
    }
    setLoading(false);
  };

  return (
    <Row>
      <Col
        xs={{ span: 24 }}
        sm={{ span: 18, offset: 3 }}
        md={{ span: 14, offset: 5 }}
        lg={{ span: 12, offset: 6 }}
        xl={{ span: 10, offset: 7 }}
        xxl={{ span: 8, offset: 8 }}
      >
        <div className="appMenu-logo">
          <img
            className="tames-logo"
            src={TamesLogo}
            alt="Tames Logo"
            style={{ maxWidth: "200px", boxShadow: "1px 1px 4px #ccc" }}
          />
        </div>
        <div className="page-container page-container-card">
          <Card title="Sign in">
            <Form name="login" onFinish={onFinish}>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Please enter your email",
                  },
                ]}
              >
                <Input prefix={<UserOutlined />} placeholder="Email" />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please enter your password" },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="Password"
                />
              </Form.Item>

              <Form.Item style={{ marginBottom: "0" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="card-form-button"
                  loading={loading}
                >
                  Log in
                </Button>
              </Form.Item>
              <div className="card-bottomLink">
                <Link to="/forgotpassword">Forgot password</Link>
              </div>
            </Form>
          </Card>
        </div>
      </Col>
    </Row>
  );
};

export default LoginForm;
