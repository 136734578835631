import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import FormItem from 'antd/lib/form/FormItem'
import { Input, Typography, DatePicker, Radio } from 'antd/lib/'
import { IepFormContext } from '../../../../utils/iepFormProvider'
import ModalDialog from '../../../ModalDialog'
import Editor from '../../../elements/common/Editor'

const { Paragraph } = Typography

const MeetingSISInput = ({ name, label, type = 'text', disabled = false }) => {
  const { setCurrentEditableForm, setKeyDates, setFormWarning } =
    useContext(IepFormContext)
  const handleEditClick = () => {
    ModalDialog.warning({
      title: 'Confirm',
      content:
        'Are you sure you want to continue, any content updates will be lost. Press cancel to go back.',
      okText: 'Continue',
      cancelText: 'Cancel',
      onOk: () => {
        setKeyDates('')
        setFormWarning('')
        // setCurrentEditableForm({ formCode: 'StudentIEPMetaForm', formId: '123' })
        setCurrentEditableForm({ formCode: 'MEETING_SIS', formId: '123' })
        window.scrollTo(0, 0)
      },
      onCancel: () => {}
    })
  }

  const renderInput = (type, name) => {
    switch (type) {
      case 'date':
        return (
          <DatePicker
            format={[
              'MM-DD-YYYY',
              'MM/DD/YYYY',
              'MM.DD.YY',
              'M-D-YYYY',
              'M/D/YYYY',
              'M.D.YYYY',
              'MM.DD.YYYY',
              'MM-DD-YY',
              'MM/DD/YY',
              'M-D-YY',
              'M/D/YY',
              'M.D.YY',
              'MMDDYYYY',
              'MMDDYY'
            ]}
            disabled
          />
        )
      case 'consent':
        return (
          <Radio.Group disabled defaultValue='n/a' name={name}>
            <Radio.Button value='yes'>I give consent</Radio.Button>
            <Radio.Button value='no'>I do not give consent</Radio.Button>
            <Radio.Button value='n/a'>Decision Delayed</Radio.Button>
          </Radio.Group>
        )
      case 'textarea':
        return (
          <Editor
            onFocus={() => {}}
            onBlur={() => {}}
            onChange={() => {}}
            value='-'
            formField={name}
            disabled
          />
        )
      default:
        return <Input disabled />
    }
  }

  const additionalProps =
    type === 'textarea'
      ? {
          initialValue: ''
        }
      : {}

  return (
    <FormItem
      label={label}
      name={name}
      help={
        disabled ? (
          ''
        ) : (
          <Paragraph type='warning'>
            Global SIS Accompanying Information{' '}
            <a disabled={disabled} onClick={handleEditClick}>
              Edit
            </a>
          </Paragraph>
        )
      }
      {...additionalProps}
    >
      {renderInput(type, name)}
    </FormItem>
  )
}

MeetingSISInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string
}

export default MeetingSISInput
