import React, { useContext } from "react";
import { Alert } from "antd";
import { IepFormContext } from "../../utils/iepFormProvider";
const FormWarning = () => {
  const { formWarning } = useContext(IepFormContext);
  if (!formWarning) return <></>;
  return (
    <Alert
      message="Warning"
      description={formWarning}
      type="warning"
      showIcon
      closable
      style={{
        marginTop: "5px",
        marginBottom: "5px",
      }}
    />
  );
};

export default FormWarning;
