import { notification as antNotification } from "antd";

const notificationSuccess = (message, description, placement) => {
  antNotification.success({
    message,
    description,
    placement: placement || "bottomRight",
  });
};

const notificationError = (message, description, placement) => {
  antNotification.error({
    message,
    description,
    placement: placement || "bottomRight",
  });
};

const notificationInfo = (message, description, placement, duration) => {
  antNotification.info({
    message,
    description,
    placement: placement || "bottomRight",
  });
};

const notificationInfoAlt = (message, description, placement, duration) => {
  antNotification.info({
    message,
    description,
    placement: placement || "bottomRight",
    duration: duration || 4.5,
  });
};

const notificationWarning = (message, description, placement) => {
  antNotification.warning({
    message,
    description,
    placement: placement || "bottomRight",
  });
};

const notificationWarn = (message, description, placement) => {
  antNotification.warn({
    message,
    description,
    placement: placement || "bottomRight",
  });
};

export default notificationSuccess;

export {
  notificationSuccess,
  notificationError,
  notificationInfo,
  notificationWarning,
  notificationWarn,
  notificationInfoAlt,
};
