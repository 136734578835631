import { gql } from "@apollo/client";

export const PRINT_FORM504A = gql`
  mutation printStudentForm504A(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $studentDob: String
    $parent1FullName: String
    $parent2FullName: String
    $majorLifeActivityThatMayBeAffected: String
    $sourcesOfEvaluationInformation: String
    $parentConsent: String
    $parentSignatureName: String
    $parentSignatureDate: String
    $formAction: String
    $formStatus: String
  ) {
    printStudentForm504A(
      studentForm504AInput: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        studentDob: $studentDob
        parent1FullName: $parent1FullName
        parent2FullName: $parent2FullName
        majorLifeActivityThatMayBeAffected: $majorLifeActivityThatMayBeAffected
        sourcesOfEvaluationInformation: $sourcesOfEvaluationInformation
        parentConsent: $parentConsent
        parentSignatureName: $parentSignatureName
        parentSignatureDate: $parentSignatureDate
        formAction: $formAction
        formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;
