import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { IepFormContext } from '../../../../utils/iepFormProvider'
import { useQuery } from '@apollo/client'
import { QUERY_STUDENT_COMMON_SIS } from '../../../../operations/queries/forms/SIS'
import { QUERY_FORM3454P_VERSION } from '../../../../operations/queries/forms/3454P'
import moment from 'moment'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import FormContainer from '../../../FormContainer'
import { Alert, Card, DatePicker, Form, Input, Typography, Col, Row } from 'antd'
import FormCollaboration from '../../../FormCollaboration'
import Title from 'antd/lib/typography/Title'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import StudentFormActions from '../StudentFormActions'
import { PRINT_FORM3454P } from '../../../../operations/mutations/forms/3454P'
import Editor from '../../../elements/common/Editor'

const { Paragraph } = Typography

const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId
}) => {
  const [form] = Form.useForm()

  const { itemErrors, formError } = useContext(FormErrorContext)

  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()
    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      studentMeetingId: studentMeetingId
    }
  }

  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    const update = Object.assign({})
    switch (fieldName) {
      case 'formDate':
      case 'intiationDate':
      case 'durationDate':
        update[fieldName] = fieldValue ? moment(fieldValue) : ''
        break
      case 'totalBelltoBellMinutes':
        //update[fieldName] = fieldValue ? moment(fieldValue) : "";
        reCalculateMinutes()
      default:
        update[fieldName] = fieldValue
    }
    return update
  }

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }

  const reCalculateMinutes = () => {
    // Setup
    const {
      outsideGeneralEducationMinutesPerWeek,
      outsideRelatedServicesMinutesPerWeek,
      totalBelltoBellMinutes
    } = form.getFieldsValue()

    let A
    let B
    let BellToBell = 0
    try {
      A = parseInt(outsideGeneralEducationMinutesPerWeek)
      B = parseInt(outsideRelatedServicesMinutesPerWeek)
      BellToBell = parseInt(totalBelltoBellMinutes)
    } catch (error) {
      console.log(error)
    }

    // Calculate
    const AB = A + B
    const insideMinutes = BellToBell - AB
    const percentage = (insideMinutes / BellToBell) * 100

    // Render
    form.setFieldsValue({
      regularABProgramMinutesPerWeek: AB.toString(),
      totalNumberMinutesOutsideGeneralEducation: AB.toString(),
      totalNumberMinutesInsideGeneralEducation: insideMinutes.toString(),
      percentageInsideGeneralEducationEnvironment: percentage
        .toFixed(0)
        .toString()
    })
  }

  useEffect(() => {
    reCalculateMinutes()
  }, [data])

  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          formDisabledState,
          canSave,
          handleActionEvents,
          validationProps
        }) => (
          <Form
            form={form}
            id='studentForm3454P'
            name='studentForm3454P'
            initialValues={data}
            preserve
            layout='vertical'
            className='page-padding-leftRight page-padding-topBottom form-labels-bold'
          >
            <Title level={4} className='center'>
              Educational Services and Placement Issues
            </Title>
            <Form.Item
              label='Form Created Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
              />
            </Form.Item>

            <Card
              title='Student SIS Data'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />
              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
            </Card>

            <Form.Item
              label='Initiation Date'
              name='intiationDate'
              {...validationProps('intiationDate')}
            >
              <DatePicker
                {...handleFocusEvents}
                disabled={formDisabledState.intiationDate}
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
              />
            </Form.Item>
            <Form.Item
              label='Duration Date'
              name='durationDate'
              {...validationProps('durationDate')}
            >
              <DatePicker
                {...handleFocusEvents}
                disabled={formDisabledState.durationDate}
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
              />
            </Form.Item>

            <Card
              title='Participation in General Education Classes'
              style={{
                marginBottom: 30
              }}
            >
              <Row>
                <Col>

                </Col>
              </Row>

              <Form.Item
                label='General Education with No Supplementary Aids'
                name='generalEducationNoSupplementary'
                {...validationProps('generalEducationNoSupplementary')}
                style={{ maxWidth: 500 }}
              >
                <Editor
                  {...handleEditorEvents}
                  formField='generalEducationNoSupplementary'
                  disabled={formDisabledState.generalEducationNoSupplementary}
                />
              </Form.Item>
              <Form.Item
                label='Minutes Per Week In Setting'
                name='generalEducationNoSupplementaryminutesPerWeek'
                {...validationProps(
                  'generalEducationNoSupplementaryminutesPerWeek'
                )}
              >
                <Input
                  type='number'
                  defaultValue={0}
                  onWheel={e => e.target.blur()}
                  {...handleFocusEvents}
                  disabled={
                    formDisabledState.generalEducationNoSupplementaryminutesPerWeek
                  }
                />
              </Form.Item>

              <Form.Item
                label='General Education with Supplementary Aids'
                name='generalEducationSupplementaryAids'
                {...validationProps('generalEducationSupplementaryAids')}
                style={{ maxWidth: 500 }}
              >
                <Editor
                  {...handleEditorEvents}
                  formField='generalEducationSupplementaryAids'
                  disabled={
                    formDisabledState.generalEducationSupplementaryAids
                  }
                />
              </Form.Item>
              <Form.Item
                label='Minutes Per Week In Setting '
                name='generalEducationSupplementaryAidsMinutesPerWeek'
                {...validationProps(
                  'generalEducationSupplementaryAidsMinutesPerWeek'
                )}
              >
                <Input
                  type='number'
                  onWheel={e => e.target.blur()}
                  {...handleFocusEvents}
                  disabled={
                    formDisabledState.generalEducationSupplementaryAidsMinutesPerWeek
                  }
                />
              </Form.Item>

              <Form.Item
                label='Special Education and Related Services within the General Education Classroom'
                name='generalEducationClassroom'
                {...validationProps('generalEducationClassroom')}
                style={{ maxWidth: 500 }}
              >
                <Editor
                  {...handleEditorEvents}
                  formField='generalEducationClassroom'
                  disabled={formDisabledState.generalEducationClassroom}
                />
              </Form.Item>
              <Form.Item
                label='Minutes Per Week In Setting '
                name='generalEducationClassroomMinutesPerWeek'
                {...validationProps(
                  'generalEducationClassroomMinutesPerWeek'
                )}
              >
                <Input
                  type='number'
                  onWheel={e => e.target.blur()}
                  {...handleFocusEvents}
                  disabled={
                    formDisabledState.generalEducationClassroomMinutesPerWeek
                  }
                />
              </Form.Item>

            </Card>

            <Card
              title='PARTICIPATION IN SPECIAL EDUCATION CLASSES/SERVICES'
              style={{
                marginBottom: 30
              }}
            >

              <Form.Item
                label='Special Education Services – Outside General Education'
                name='outsideGeneralEducation'
                {...validationProps('outsideGeneralEducation')}
                style={{ maxWidth: 500 }}
              >
                <Editor
                  {...handleEditorEvents}
                  formField='outsideGeneralEducation'
                  disabled={formDisabledState.outsideGeneralEducation}
                />
              </Form.Item>
              <Form.Item
                label='Minutes Per Week In Setting(A)'
                name='outsideGeneralEducationMinutesPerWeek'
                {...validationProps('outsideGeneralEducationMinutesPerWeek')}
              >
                <Input
                  type='number'
                  onWheel={e => e.target.blur()}
                  onChange={() => {
                    reCalculateMinutes()
                  }}
                  {...handleFocusEvents}
                  disabled={
                    formDisabledState.outsideGeneralEducationMinutesPerWeek
                  }
                />
              </Form.Item>


              <Form.Item
                label='Related Services – Outside General Education'
                name='outsideRelatedServices'
                {...validationProps('outsideRelatedServices')}
                style={{ maxWidth: 500 }}
              >
                <Editor
                  {...handleEditorEvents}
                  formField='outsideRelatedServices'
                  disabled={formDisabledState.outsideRelatedServices}
                />
              </Form.Item>
              <Form.Item
                label='Minutes Per Week In Setting(B)'
                name='outsideRelatedServicesMinutesPerWeek'
                {...validationProps('outsideRelatedServicesMinutesPerWeek')}
              >
                <Input
                  type='number'
                  onWheel={e => e.target.blur()}
                  onChange={() => {
                    reCalculateMinutes()
                  }}
                  {...handleFocusEvents}
                  disabled={
                    formDisabledState.outsideRelatedServicesMinutesPerWeek
                  }
                />
              </Form.Item>


              <Card
                title='Educational Environment (EE) Calculation (Ages 3-5)'
                style={{ borderStyle: 'dashed' }}
              >
                <Form.Item
                  label='1. Minutes spent in regular early childhood program Calculation (Ages 3-5)'
                  name='regular35ProgramMinutesPerWeek'
                  {...validationProps('regular35ProgramMinutesPerWeek')}
                >
                  <Input
                    type='number'
                    onWheel={e => e.target.blur()}
                    {...handleFocusEvents}
                    disabled={formDisabledState.regular35ProgramMinutesPerWeek}
                  />
                </Form.Item>

                <Form.Item
                  label='2. Minutes spent receiving special education and related
                  services outside regular early childhood (A+B)'
                  name='regularABProgramMinutesPerWeek'
                  {...validationProps('regularABProgramMinutesPerWeek')}
                >
                  <Input
                    type='number'
                    disabled
                    onWheel={e => e.target.blur()}
                  />
                </Form.Item>
              </Card>

              <Card
                title='Educational Environment (EE) Calculation (Ages 6-21)'
                style={{ borderStyle: 'dashed' }}
              >
                <Form.Item
                  label='1. Total Bell to Bell Minutes'
                  name='totalBelltoBellMinutes'
                  {...validationProps('totalBelltoBellMinutes')}
                >
                  <Input
                    type='number'
                    onWheel={e => e.target.blur()}
                    onChange={() => {
                      reCalculateMinutes()
                    }}
                    {...handleFocusEvents}
                    disabled={formDisabledState.totalBelltoBellMinutes}
                  />
                </Form.Item>

                <Form.Item
                  label='2. Total Number of Minutes Outside of the General Education Setting (A+B)'
                  name='totalNumberMinutesOutsideGeneralEducation'
                  {...validationProps(
                    'totalNumberMinutesOutsideGeneralEducation'
                  )}
                >
                  <Input
                    type='number'
                    onWheel={e => e.target.blur()}
                    disabled
                  />
                </Form.Item>

                <Form.Item
                  label='3. Total Number of Minutes inside the General Education Setting (line #1 minus line #2)'
                  name='totalNumberMinutesInsideGeneralEducation'
                  {...validationProps(
                    'totalNumberMinutesInsideGeneralEducation'
                  )}
                >
                  <Input
                    type='number'
                    onWheel={e => e.target.blur()}
                    disabled
                  />
                </Form.Item>

                <Form.Item
                  label='4. Percentage of time inside the General Education Environment (line #3 divided by line #1)'
                  name='percentageInsideGeneralEducationEnvironment'
                  {...validationProps(
                    'percentageInsideGeneralEducationEnvironment'
                  )}
                >
                  <Input
                    type='number'
                    onWheel={e => e.target.blur()}
                    disabled
                  />
                </Form.Item>
              </Card>
            </Card>

            {formError && <Paragraph type='danger'>{formError}</Paragraph>}
            <StudentFormActions
              query={PRINT_FORM3454P}
              extractDataProp='printStudentForm3454P'
              canSave={canSave}
              handleActionEvents={handleActionEvents}

              getFormFieldValues={getFormFieldValues}
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired
}

const StudentForm3454P = () => {
  // get current form information
  const { currentStudentInfo, currentEditableForm, studentMeetingId } =
    useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId } = currentEditableForm

  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    QUERY_STUDENT_COMMON_SIS,
    { variables: { id: studentId } }
  )
  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORM3454P_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )

  const createInitialFormData = (studentInfo, versionInfo) => {
    let initialValues = {}

    if (!studentInfo || !versionInfo) return initialValues

    const { studentForm3454P: form } = versionInfo
    const { student } = studentInfo
    const { formDate, studentFullName, intiationDate, durationDate } = form

    initialValues = {
      ...form,
      formDate: formDate ? moment(formDate) : moment(Date.now()),
      studentFullName: studentFullName || student.fullName || '',
      intiationDate: intiationDate ? moment(intiationDate) : moment(Date.now()),
      durationDate: durationDate ? moment(durationDate) : moment(Date.now())
    }

    return initialValues
  }
  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
          />
        }
      />
    </FormErrorProvider>
  )
}
export default StudentForm3454P
