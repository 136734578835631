import { gql } from "@apollo/client";

export const QUERY_STATUES = gql`
  query {
    formStatuses {
      id
      enumName
    }
  }
`;
