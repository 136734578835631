import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { IepFormContext } from '../../../../utils/iepFormProvider'
import { useQuery } from '@apollo/client'
import { QUERY_STUDENT_COMMON_SIS } from '../../../../operations/queries/forms/SIS'
import { QUERY_FORMTAMES3_VERSION } from '../../../../operations/queries/forms/tames3'
import moment from 'moment'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import FormContainer from '../../../FormContainer'
import {
  Alert,
  Button,
  Card,
  Checkbox,
  DatePicker,
  Input,
  Form,
  Radio,
  Typography
} from 'antd'
import FormCollaboration from '../../../FormCollaboration'
import Title from 'antd/lib/typography/Title'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import StudentDobSIS from '../sis/StudentDobSIS'
import StudentFormActions from '../StudentFormActions'
import { PRINT_FORMTAMES3 } from '../../../../operations/mutations/forms/tames3'
import { PlusOutlined } from '@ant-design/icons'
import Editor from '../../../elements/common/Editor'

const { Paragraph, Text } = Typography

const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId
}) => {
  const [form] = Form.useForm()

  const { itemErrors, formError } = useContext(FormErrorContext)

  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()
    const domains = formFieldValues.domains.map(
      ({ __typename, ...props }) => props
    )

    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      studentMeetingId: studentMeetingId,
      domains: domains
    }
  }

  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    const update = Object.assign({})
    switch (fieldName) {
      case 'formDate':
      case 'visionScreeningDate':
      case 'hearingScreeningDate':
        update[fieldName] = fieldValue ? moment(fieldValue) : ''
        break
      case 'visionScreeningStatus':
      case 'hearingScreeningStatus':
      case 'determinantReading':
      case 'determinantMath':
      case 'determinantEnglish':
      case 'determinantEnvironmentalCultural':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        break
      case 'domains':
        update[fieldName] = JSON.parse(fieldValue)
        break
      default:
        update[fieldName] = fieldValue
    }
    return update
  }

  const disabilityTypes = [
    {
      label: 'Intellectual Disability (IntD)',
      value: 'intd',
      style: { whiteSpace: 'pre-line', paddingBottom: '10px' }
    },
    {
      label: 'Orthopedic Impairment (PI)',
      value: 'pi',
      style: { whiteSpace: 'pre-line', paddingBottom: '10px' }
    },
    {
      label: 'Specific Learning Disability (SLD)',
      value: 'sld',
      style: { whiteSpace: 'pre-line', paddingBottom: '10px' }
    },
    {
      label: 'Visual Impairment (VI)',
      value: 'vi',
      style: { whiteSpace: 'pre-line', paddingBottom: '10px' }
    },
    {
      label: 'Hearing Impairment (HI)',
      value: 'hi',
      style: { whiteSpace: 'pre-line', paddingBottom: '10px' }
    },
    {
      label: 'Deafness (D)',
      value: 'd',
      style: { whiteSpace: 'pre-line', paddingBottom: '10px' }
    },
    {
      label: 'Deaf-Blindness (D-B)',
      value: 'db',
      style: { whiteSpace: 'pre-line', paddingBottom: '10px' }
    },
    {
      label: 'Speech or Language Impairment (SLI)',
      value: 'sli',
      style: { whiteSpace: 'pre-line', paddingBottom: '10px' }
    },
    {
      label: 'Emotional Disability (ED)',
      value: 'ed',
      style: { whiteSpace: 'pre-line', paddingBottom: '10px' }
    },
    {
      label: 'Other Health Impairment (OHI)',
      value: 'ohi',
      style: { whiteSpace: 'pre-line', paddingBottom: '10px' }
    },
    {
      label: 'Multiple Disability (MD)',
      value: 'md',
      style: { whiteSpace: 'pre-line', paddingBottom: '10px' }
    },
    {
      label: 'Autism (AUT)',
      value: 'aut',
      style: { whiteSpace: 'pre-line', paddingBottom: '10px' }
    },
    {
      label: 'Traumatic Brain Injury (TBI)',
      value: 'tbi',
      style: { whiteSpace: 'pre-line' }
    }
  ]

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }

  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          formDisabledState,
          canSave,
          handleActionEvents,
          validationProps
        }) => (
          <Form
            form={form}
            id='studentFormTames3'
            name='studentFormTames3'
            initialValues={data}
            preserve
            layout='vertical'
            className='page-padding-leftRight page-padding-topBottom form-labels-bold'
          >
            <Title level={4} className='center'>
              DOMAIN DOCUMENTATION
            </Title>
            <Form.Item
              label='Form Created Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
              />
            </Form.Item>

            <Card
              title='Student SIS Data'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />
              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
              <StudentDobSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentDob}
                error={itemErrors.studentDob}
              />
            </Card>

            <Card
              title='Domain(s) Information'
              style={{
                marginBottom: '30px'
              }}
            >
              <Input.Group
                onFocus={() => {
                  console.log('onFocus---')
                  handleEditorEvents.onFocus('domains')
                }}
                onBlur={() => {
                  console.log('onBlur---')
                  handleEditorEvents.onBlur(
                    'domains',
                    JSON.stringify(form.getFieldValue('domains'))
                  )
                }}
              >
                <Form.List name='domains' {...validationProps('domains')}>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, index) => (
                        <div key={field.key}>
                          <Card
                            style={{
                              marginBottom: '30px',
                              borderStyle: 'dashed'
                            }}
                          >
                            <Form.Item
                              {...field}
                              label='Domain Title'
                              name={[field.name, 'domainTitle']}
                              key={[field.fieldKey, 'domainTitle']}
                            >
                              <Input
                                {...field}
                                disabled={formDisabledState.domains}
                              />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              label='Evaluation Results'
                              name={[field.name, 'domainEvaluationResults']}
                              key={[field.fieldKey, 'domainEvaluationResults']}
                            >
                              <Editor
                                disabled={formDisabledState.domains}
                                onFocus={() => {}}
                                onBlur={() => {}}
                              />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              label='Disabilities for Consideration'
                              name={[
                                field.name,
                                'disabilitiesForConsideration'
                              ]}
                              key={[
                                field.fieldKey,
                                'disabilitiesForConsideration'
                              ]}
                            >
                              <Checkbox.Group
                                options={disabilityTypes}
                                disabled={formDisabledState.domains}
                                onChange={() => {
                                  handleEditorEvents.onBlur(
                                    'domains',
                                    JSON.stringify(
                                      form.getFieldValue('domains')
                                    )
                                  )
                                }}
                              />
                            </Form.Item>
                            <Button
                              danger
                              disabled={formDisabledState.domains}
                              onClick={() => {
                                remove(field.name)
                                handleEditorEvents.onBlur(
                                  'domains',
                                  JSON.stringify(form.getFieldValue('domains'))
                                )
                              }}
                            >
                              Remove Domain
                            </Button>
                          </Card>
                        </div>
                      ))}

                      <Form.Item>
                        {
                          // Default value below prevents Quill from breaking the render
                        }
                        <Button
                          disabled={formDisabledState.domains}
                          type='dashed'
                          onClick={() =>
                            add({
                              domainTitle: '',
                              domainEvaluationResults: '',
                              disabilitiesForConsideration: []
                            })
                          }
                          block
                          icon={<PlusOutlined />}
                        >
                          Add Domain
                        </Button>
                      </Form.Item>
                      <Text type='warning'>
                        {validationProps('domains').help}
                      </Text>
                    </>
                  )}
                </Form.List>
              </Input.Group>
            </Card>

            <Card
              title='Vision Screening Information'
              style={{
                marginBottom: 30
              }}
            >
              <Form.Item
                label='Vision Screening Date'
                name='visionScreeningDate'
                {...validationProps('visionScreeningDate')}
              >
                <DatePicker
                  {...handleFocusEvents}
                  disabled={formDisabledState.visionScreeningDate}
                  format={[
                    'MM-DD-YYYY',
                    'MM/DD/YYYY',
                    'MM.DD.YY',
                    'M-D-YYYY',
                    'M/D/YYYY',
                    'M.D.YYYY',
                    'MM.DD.YYYY',
                    'MM-DD-YY',
                    'MM/DD/YY',
                    'M-D-YY',
                    'M/D/YY',
                    'M.D.YY',
                    'MMDDYYYY',
                    'MMDDYY'
                  ]}
                />
              </Form.Item>

              <Form.Item
                label='Vision Screening Status'
                name='visionScreeningStatus'
                {...validationProps('visionScreeningStatus')}
              >
                <Radio.Group
                  {...handleMouseEvents}
                  buttonStyle='solid'
                  disabled={formDisabledState.visionScreeningStatus}
                  name='visionScreeningStatus'
                >
                  <Radio value>Pass</Radio>
                  <Radio value={false}>Fail</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                label='Vision Screening Corrected'
                name='visionScreeningCorrected'
                {...validationProps('visionScreeningCorrected')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.visionScreeningCorrected}
                />
              </Form.Item>

              <Form.Item
                label='Vision Screening Conducted By'
                name='visionScreeningConductedBy'
                {...validationProps('visionScreeningConductedBy')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.visionScreeningConductedBy}
                />
              </Form.Item>
            </Card>

            <Card
              title='Hearing Screening Information'
              style={{
                marginBottom: 30
              }}
            >
              <Form.Item
                label='Hearing Screening Date'
                name='hearingScreeningDate'
                {...validationProps('hearingScreeningDate')}
              >
                <DatePicker
                  {...handleFocusEvents}
                  disabled={formDisabledState.hearingScreeningDate}
                  format={[
                    'MM-DD-YYYY',
                    'MM/DD/YYYY',
                    'MM.DD.YY',
                    'M-D-YYYY',
                    'M/D/YYYY',
                    'M.D.YYYY',
                    'MM.DD.YYYY',
                    'MM-DD-YY',
                    'MM/DD/YY',
                    'M-D-YY',
                    'M/D/YY',
                    'M.D.YY',
                    'MMDDYYYY',
                    'MMDDYY'
                  ]}
                />
              </Form.Item>

              <Form.Item
                label='Hearing Screening Status'
                name='hearingScreeningStatus'
                {...validationProps('hearingScreeningStatus')}
              >
                <Radio.Group
                  {...handleMouseEvents}
                  buttonStyle='solid'
                  disabled={formDisabledState.hearingScreeningStatus}
                  name='hearingScreeningStatus'
                >
                  <Radio value>Pass</Radio>
                  <Radio value={false}>Fail</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                label='Hearing Screening Corrected'
                name='hearingScreeningCorrected'
                {...validationProps('hearingScreeningCorrected')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.hearingScreeningCorrected}
                />
              </Form.Item>

              <Form.Item
                label='Hearing Screening Conducted By'
                name='hearingScreeningConductedBy'
                {...validationProps('hearingScreeningConductedBy')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.hearingScreeningConductedBy}
                />
              </Form.Item>
            </Card>

            <Card
              title='Determinant Factor(s)'
              style={{
                marginBottom: 30
              }}
            >
              <p>
                <strong>
                  The determinant factor for the student's suspected disability
                  is:
                </strong>
              </p>
              <Form.Item
                label='Lack of appropriate instruction in reading, including the essential components of reading instruction'
                name='determinantReading'
                {...validationProps('determinantReading')}
              >
                <Radio.Group
                  {...handleMouseEvents}
                  buttonStyle='solid'
                  disabled={formDisabledState.determinantReading}
                  name='determinantReading'
                >
                  <Radio value>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label='Lack of appropriate instruction in Math'
                name='determinantMath'
                {...validationProps('determinantMath')}
              >
                <Radio.Group
                  {...handleMouseEvents}
                  buttonStyle='solid'
                  disabled={formDisabledState.determinantMath}
                  name='determinantMath'
                >
                  <Radio value>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label='Limited English proficiency'
                name='determinantEnglish'
                {...validationProps('determinantEnglish')}
              >
                <Radio.Group
                  {...handleMouseEvents}
                  buttonStyle='solid'
                  disabled={formDisabledState.determinantEnglish}
                  name='determinantEnglish'
                >
                  <Radio value>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label='Environmental and Cultural Factors and/or Economic Disadvantage'
                name='determinantEnvironmentalCultural'
                {...validationProps('determinantEnvironmentalCultural')}
              >
                <Radio.Group
                  {...handleMouseEvents}
                  buttonStyle='solid'
                  disabled={formDisabledState.determinantEnvironmentalCultural}
                  name='determinantEnvironmentalCultural'
                >
                  <Radio value>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Card>

            {formError && <Paragraph type='danger'>{formError}</Paragraph>}
            <StudentFormActions
              query={PRINT_FORMTAMES3}
              extractDataProp='printStudentFormTames3'
              canSave={canSave}
              handleActionEvents={handleActionEvents}

              getFormFieldValues={getFormFieldValues}
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired
}

const StudentFormTames3 = () => {
  // get current form information
  const { currentStudentInfo, currentEditableForm, studentMeetingId } =
    useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId } = currentEditableForm

  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    QUERY_STUDENT_COMMON_SIS,
    { variables: { id: studentId } }
  )
  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORMTAMES3_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )

  const createInitialFormData = (studentInfo, versionInfo) => {
    let initialValues = {}

    if (!studentInfo || !versionInfo) return initialValues

    const { studentFormTames3: form } = versionInfo
    const { student } = studentInfo
    const { dateOfBirth } = student
    const {
      formDate,
      studentFullName,
      studentDob,
      visionScreeningDate,
      hearingScreeningDate
    } = form

    initialValues = {
      ...form,
      formDate: formDate ? moment(formDate) : moment(Date.now()),
      studentFullName: studentFullName || student.fullName || '',
      studentDob: studentDob
        ? moment(studentDob).format('YYYY-MM-DD')
        : dateOfBirth
        ? moment(dateOfBirth).format('YYYY-MM-DD')
        : moment(Date.now()).format('YYYY-MM-DD'),
      visionScreeningDate: visionScreeningDate
        ? moment(visionScreeningDate)
        : moment(Date.now()),
      hearingScreeningDate: hearingScreeningDate
        ? moment(hearingScreeningDate)
        : moment(Date.now())
    }

    return initialValues
  }
  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
          />
        }
      />
    </FormErrorProvider>
  )
}
export default StudentFormTames3
