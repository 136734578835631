import { gql } from "@apollo/client";

export const QUERY_FORM3454J_VERSION = gql`
  query studentForm3454J(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
  ) {
    studentForm3454J(
      studentFormId: $studentFormId
      studentId: $studentId
      formId: $formId
      schoolId: $schoolId
      studentMeetingId: $studentMeetingId
    ) {
      versionId
      formId
      formDate
      studentFullName
      studentStrengths
      operationalDefinitionTargetBehavior
      setting
      antecedents
      consequences
      environmentalVariables
      behavioralFunction
      formAction
      formStatus
    }
  }
`;
