import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { IepFormContext } from '../../../../utils/iepFormProvider'
import { useQuery } from '@apollo/client'
import { QUERY_STUDENT_COMMON_SIS } from '../../../../operations/queries/forms/SIS'
import { QUERY_FORMTAMES7_VERSION } from '../../../../operations/queries/forms/tames7'
import moment from 'moment'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import FormContainer from '../../../FormContainer'
import { Alert, Card, DatePicker, Form, Radio, Input, Typography } from 'antd'
import FormCollaboration from '../../../FormCollaboration'
import Title from 'antd/lib/typography/Title'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import StudentDobSIS from '../sis/StudentDobSIS'
import StudentFormActions from '../StudentFormActions'
import { PRINT_FORMTAMES7 } from '../../../../operations/mutations/forms/tames7'

const { Paragraph } = Typography

const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId
}) => {
  const [form] = Form.useForm()

  const { itemErrors, formError } = useContext(FormErrorContext)

  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()

    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      studentMeetingId: studentMeetingId
    }
  }

  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    const update = Object.assign({})
    switch (fieldName) {
      case 'formDate':
        update[fieldName] = fieldValue ? moment(fieldValue) : ''
        break
      case 'copyIEP':
      case 'copyEligibilityDetermination':
      case 'copyInterventionPolicy':
      case 'copyInterventionProcedures':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        break
      case 'planIndicator':
        update[fieldName] = fieldValue === 'null' ? null : fieldValue === 'true'
        setPlanIndicator(update[fieldName])
        break
      default:
        update[fieldName] = fieldValue
    }
    return update
  }

  const [planIndicator, setPlanIndicator] = useState(data?.planIndicator)

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }

  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          formDisabledState,
          canSave,
          handleActionEvents,
          validationProps
        }) => (
          <Form
            form={form}
            id='studentFormTames7'
            name='studentFormTames7'
            initialValues={data}
            preserve
            layout='vertical'
            className='page-padding-leftRight page-padding-topBottom form-labels-bold'
          >
            <Title level={4} className='center'>
              CREATE ELIGIBILITY DOCUMENTS
            </Title>
            <Form.Item
              label='Form Created Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
              />
            </Form.Item>

            <Card
              title='Student SIS Data'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />
              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
              <StudentDobSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentDob}
                error={itemErrors.studentDob}
              />
            </Card>

            <Card
              style={{
                marginBottom: 30
              }}
            >
              <h3>
                <strong>Parent(s) given a copy of the:</strong>
              </h3>
              <Form.Item
                label='IEP'
                name='copyIEP'
                {...validationProps('copyIEP')}
              >
                <Radio.Group
                  {...handleMouseEvents}
                  buttonStyle='solid'
                  disabled={formDisabledState.copyIEP}
                  name='copyIEP'
                >
                  <Radio.Button value>Yes</Radio.Button>
                  <Radio.Button value={false}>N/A</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label='Eligibility determination'
                name='copyEligibilityDetermination'
                {...validationProps('copyEligibilityDetermination')}
              >
                <Radio.Group
                  {...handleMouseEvents}
                  buttonStyle='solid'
                  disabled={formDisabledState.copyEligibilityDetermination}
                  name='copyEligibilityDetermination'
                >
                  <Radio.Button value>Yes</Radio.Button>
                  <Radio.Button value={false}>N/A</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="District's behavioral intervention policy"
                name='copyInterventionPolicy'
                {...validationProps('copyInterventionPolicy')}
              >
                <Radio.Group
                  {...handleMouseEvents}
                  buttonStyle='solid'
                  disabled={formDisabledState.copyInterventionPolicy}
                  name='copyInterventionPolicy'
                >
                  <Radio.Button value>Yes</Radio.Button>
                  <Radio.Button value={false}>N/A</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="District's behavioral intervention procedures (initial IEP only)"
                name='copyInterventionProcedures'
                {...validationProps('copyInterventionProcedures')}
              >
                <Radio.Group
                  {...handleMouseEvents}
                  buttonStyle='solid'
                  disabled={formDisabledState.copyInterventionProcedures}
                  name='copyInterventionProcedures'
                >
                  <Radio.Button value>Yes</Radio.Button>
                  <Radio.Button value={false}>N/A</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Card>

            <Card
              title='State Performance Plan Indicator 11'
              style={{
                marginBottom: 30
              }}
            >
              <Form.Item
                label='Did the student have an initial eligibility determination completed beyond the 60 school day timeline?'
                name='planIndicator'
                {...validationProps('planIndicator')}
              >
                <Radio.Group
                  {...handleMouseEvents}
                  buttonStyle='solid'
                  disabled={formDisabledState.planIndicator}
                  name='planIndicator'
                  onChange={e => {
                    setPlanIndicator(e.target.value)
                    handleMouseEvents.onChange(e)
                  }}
                >
                  <Radio.Button value>Yes</Radio.Button>
                  <Radio.Button value={false}>No</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name='planIndicatorYes'
                label='State Performance Plan Delay Reason'
                hidden={!planIndicator}
                {...validationProps('planIndicatorYes')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.planIndicatorYes}
                />
              </Form.Item>
            </Card>

            {formError && <Paragraph type='danger'>{formError}</Paragraph>}
            <StudentFormActions
              query={PRINT_FORMTAMES7}
              extractDataProp='printStudentFormTames7'
              canSave={canSave}
              handleActionEvents={handleActionEvents}

              getFormFieldValues={getFormFieldValues}
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired
}

const StudentFormTames7 = () => {
  // get current form information
  const { currentStudentInfo, currentEditableForm, studentMeetingId } =
    useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId } = currentEditableForm

  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    QUERY_STUDENT_COMMON_SIS,
    { variables: { id: studentId } }
  )
  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORMTAMES7_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )

  const createInitialFormData = (studentInfo, versionInfo) => {
    let initialValues = {}

    if (!studentInfo || !versionInfo) return initialValues

    const { studentFormTames7: form } = versionInfo
    const { student } = studentInfo
    const { dateOfBirth } = student
    const { formDate, studentFullName, studentDob } = form

    initialValues = {
      ...form,
      formDate: formDate ? moment(formDate) : moment(Date.now()),
      studentFullName: studentFullName || student.fullName || '',
      studentDob: studentDob
        ? moment(studentDob).format('YYYY-MM-DD')
        : dateOfBirth
        ? moment(dateOfBirth).format('YYYY-MM-DD')
        : moment(Date.now()).format('YYYY-MM-DD')
    }

    return initialValues
  }
  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
          />
        }
      />
    </FormErrorProvider>
  )
}
export default StudentFormTames7
