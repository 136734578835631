import { gql } from "@apollo/client";

export const QUERY_DOCUMENTS = gql`
  query {
    users {
      id
      email
      roleIds
      userAdminDistrictIds
      userAdminSchoolIds
      userGeneralSchoolIds
      hidden
    }
  }
`;

export const QUERY_DOCUMENT = gql`
  query User($id: ID!) {
    user(id: $id) {
      id
      email
      roleIds
      userAdminDistrictIds
      userAdminSchoolIds
      userGeneralSchoolIds
      hidden
      createdBy
      createdAtAgo
      updatedAtAgo
      createdAt(format: "ddd mmm dd yyyy h:MM:ss TT Z")
      updatedAt(format: "ddd mmm dd yyyy h:MM:ss TT Z")
    }
  }
`;
