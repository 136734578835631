import React from "react";
import PropTypes from "prop-types";
import { Skeleton } from "antd";

const FormContainer = (props) => {
  const size = props.size || 1;
  return (
    <div className="formContainer">
      {props.loading === true ? (
        <>
          {Array(size)
            .fill()
            .map((_, i) => (
              <div key={i}>
                <Skeleton active />
              </div>
            ))}
          <div>
            <Skeleton.Button active />
          </div>
        </>
      ) : (
        <>{props.form}</>
      )}
    </div>
  );
};
// todo why were these proptypes throwing errors when they were integer and boolean

FormContainer.propTypes = {
  form: PropTypes.element.isRequired,
  loading: PropTypes.element.isRequired,
  size: PropTypes.element.isRequired,
};

export default FormContainer;
