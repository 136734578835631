import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment/moment'
import { useNavigate, useParams, Link } from 'react-router-dom'
import Table from 'antd/lib/table'
import Space from 'antd/lib/space'
import Button from 'antd/lib/button'
import DateSelector from '../../elements/common/DateSelector'
import {
  Card,
  Menu,
  Modal,
  Form,
  DatePicker,
  Select,
  Typography,
  Spin,
  Collapse,
  Switch
} from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import AuthContext from '../../../utils/authProvider'
// icons
import iepIcon from '../../../assets/icons/iep.svg'
import eligibilityIcon from '../../../assets/icons/eligibility.svg'
import referralIcon from '../../../assets/icons/referral.svg'
import ispIcon from '../../../assets/icons/isp.svg'
import { useMutation, useLazyQuery, useQuery } from '@apollo/client'

import { QUERY_STUDENT } from '../../../operations/queries/students'
import { UPDATE_STUDENT_MEETING_GENERAL } from '../../../operations/mutations/meetings'
import { INSERT_MEETING } from '../../../operations/mutations/meetings'
import { DesktopMeetingMenuItem } from '../desktop/DesktopMeetingMenuItem'
import MeetingSISForm from '../studentForm/MeetingSISForm'
import MeetingSISProvider from '../../../utils/meetingSISProvider'
import { IepFormContext } from '../../../utils/iepFormProvider'
import LookupSelectGeneric from '../../elements/common/LookupSelectGeneric'
import { CurrentGoals } from './CurrentGoals'
import { PriorGoals } from './PriorGoals'
import RenderStudentHistory from '../../elements/RenderStudentHistory'
import '../../styles/styleStudentGoals.css'
const { Text } = Typography

const { Panel } = Collapse

export const iconSwitch = iconName => {
  switch (iconName) {
    case 'iep':
      return iepIcon
    case 'eligibility':
      return eligibilityIcon
    case 'referral':
      return referralIcon
    case 'isp':
      return ispIcon
    default:
      break
  }
}

const AddMeetingForm = ({
  student,
  visible,
  afterCreated,
  onCancel,
  validationProps
}) => {
  const [form] = Form.useForm()
  const Auth = useContext(AuthContext)

  const [errorMessage, setErrorMessage] = useState()
  const [insertMeeting, { loading: insertMeetingLoading }] =
    useMutation(INSERT_MEETING)
  const [
    updateStudentMeetingGeneral,
    { loading: updateStudentMeetingGeneralLoading }
  ] = useMutation(UPDATE_STUDENT_MEETING_GENERAL)

  return (
    <Modal
      visible={visible}
      title='New meeting'
      okText='Add'
      confirmLoading={
        insertMeetingLoading && updateStudentMeetingGeneralLoading
      }
      cancelText='Cancel'
      onCancel={onCancel}
      onOk={() => {
        setErrorMessage()
        form
          .validateFields()
          .then(async values => {
            form.resetFields()
            let allowed = true
            if (
              values.meetingType === '504 Plan' &&
              values.isActiveIep === true
            ) {
              allowed = false
              notificationError(
                '504 Plan meetings are not allowed to be part of the active IEP'
              )
            }
            if (allowed === true) {
              const response = await insertMeeting({
                variables: {
                  studentMeetingGeneralInput: {
                    schoolId: values.schoolId,
                    studentId: student.id,
                    studentFormIds: [],
                    meetingType: values.meetingType,
                    meetingDate: values.date.format('MM-DD-YYYY'),
                    isActiveIep: values.isActiveIep,
                    iepMeetingId: student.currentIepMeetingId,
                    parentNotifications: []
                  }
                }
              })
              afterCreated(response?.data?.insertStudentMeetingGeneral.id)

              student.studentMeetingsGeneral.map(async e => {
                //mutate all the meetings if the new meeting is an active iep and any existing meeting is active,
                // if no meeting type ignore (crash bandaid for old data)
                if (
                  e.meetingType != null &&
                  e.isActiveIep === true &&
                  values.isActiveIep === true
                ) {
                  await updateStudentMeetingGeneral({
                    variables: {
                      id: e.id,
                      schoolId: e.schoolId,
                      studentId: e.studentId,
                      studentFormIds: e.studentFormIds,
                      meetingType: e.meetingType,
                      meetingDate: e.meetingDate,
                      isActiveIep: false,
                      iepMeetingId: e.currentIepMeetingId,
                      parentNotifications: []
                    }
                  })
                }
              })
              Auth.fetchDesktopData()
            }
          })
          .catch(info => {
            console.log(info, 'error')
            setErrorMessage('An error occured while adding the meeting.')
          })
      }}
    >
      <Form
        form={form}
        layout='vertical'
        name='addMeetingForm'
        initialValues={{
          schoolId:
            student.schools.length === 1 ? student.schools[0].id : undefined
        }}
      >
        <Form.Item
          label='School'
          name='schoolId'
          rules={[
            {
              required: true,
              message: 'Please select a school'
            }
          ]}
        >
          <Select>
            {student?.schools &&
              student.schools.map(school => (
                <Select.Option key={school.id} value={school.id}>
                  {school.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        <LookupSelectGeneric
          singleMode={true}
          name='meetingType'
          type='Meeting Type'
          placeholder='Please select a meeting type'
        />
        <Form.Item name='date' label='Meeting date'>
          <DatePicker
            format={[
              'MM-DD-YYYY',
              'MM/DD/YYYY',
              'MM.DD.YY',
              'M-D-YYYY',
              'M/D/YYYY',
              'M.D.YYYY',
              'MM.DD.YYYY',
              'MM-DD-YY',
              'MM/DD/YY',
              'M-D-YY',
              'M/D/YY',
              'M.D.YY',
              'MMDDYYYY',
              'MMDDYY'
            ]}
          />
        </Form.Item>
        <Form.Item
          name='isActiveIep'
          label='Active IEP'
          rules={[
            {
              required: true
            }
          ]}
          initialValue={true}
        >
          <Switch defaultChecked={true} />
        </Form.Item>
        {errorMessage && <Text type='danger'>{errorMessage}</Text>}
      </Form>
    </Modal>
  )
}

AddMeetingForm.propTypes = {
  student: PropTypes.object,
  visible: PropTypes.bool,
  afterCreated: PropTypes.func,
  onCancel: PropTypes.func
}

const Listing = ({ addMeeting }) => {
  const Auth = useContext(AuthContext)

  const desktopData = Auth.desktopData
  const { id } = useParams()

  const { currentStudentInfo, currentEditableForm } = useContext(IepFormContext)
  const { formId, formCode, studentFormId } = currentEditableForm

  const { loading, data } = useQuery(QUERY_STUDENT, {
    variables: { id: id, includeGoals: true },
    skip: !id,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first' //fixes infinite render bug
  })

  if (desktopData === null) {
    Auth.fetchDesktopData()
  }
  const meetingItems = (meetings, icons = false) => {
    if (meetings === undefined || meetings.length === 0) {
      return (
        <Space
          direction='horizontal'
          style={{ width: '100%', justifyContent: 'center' }}
        >
          No Meetings
        </Space>
      )
    }
    return meetings.map((record, index) => (
      <DesktopMeetingMenuItem
        key={index}
        id={record.id}
        meetingDate={record.meetingDate}
        studentId={record.studentId}
        schoolId={record.schoolId}
        districtId={record?.district?.id}
        meetingType={record.meetingType}
        iepMeetingId={record.iepMeetingId}
      />
    ))
  }

  const expandedRowRender = row => {
    const filterTheMeetings = () => {
      let setMeetings = new Set()
      let antdMeetings = []
      row.studentMeetingsGeneral.map(e => {
        setMeetings.add(e.meetingType)
      })
      const filterMeetings = [...setMeetings]
      filterMeetings.map(e => {
        antdMeetings.push({ text: e, value: e })
      })
      return antdMeetings
    }

    const columns = [
      {
        title: 'Meeting Type',
        filters: filterTheMeetings(),
        dataIndex: 'studentMeetingsGeneral',
        filterMultiple: true,
        // defaultFilteredValue: ['IEP', 'Referral', 'ISP', 'Evaluation & Eligibility'],
        // filterResetToDefaultFilteredValue: true,

        onFilter: (value, records, record) => {
          if (records.meetingType != undefined) {
            return records.meetingType.includes(value)
          }
        },
        render: (record, records) => {
          return <>{records.meetingType}</>
        }
      },
      {
        title: 'Meeting Date',
        dataIndex: 'studentMeetingsGeneral',
        sorter: (a, b) =>
          moment(a.meetingDate).unix() - moment(b.meetingDate).unix(),
        render: (record, records) => {
          return <> {moment(records.meetingDate).format('MM-DD-YYYY')}</>
        }
      },
      {
        title: 'Active IEP',
        dataIndex: 'studentMeetingsGeneral',

        render: (record, records) => {
          return (
            <>{records.isActiveIep === true ? <>ACTIVE</> : <>INACTIVE</>}</>
          )
        }
      },
      {
        title: '',
        dataIndex: 'studentMeetingsGeneral',

        render: (record, records) => {
          return (
            // <Button
            //   onClick={() => {
            //     history(`/student-form/${records.id}`)
            //   }}
            // >
            //   Edit Meeting{' '}
            // </Button>
            <Link
              to={`/student-form/${records.id}`}
              state={{ studentData: records }}
            >
              <Button>Edit Meeting</Button>
            </Link>
          )
        }
      }
    ]
    return (
      <Table
        columns={columns}
        dataSource={row.studentMeetingsGeneral}
        pagination={false}
      />
    )
  }

  const columns = [
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      render: (value, record) => (
        <div>
          <Text>{value}</Text>&nbsp;
        </div>
      )
    },
    {
      title: 'School',
      dataIndex: 'schools',
      onFilter: (value, records) => {
        if (records.schools[0] != undefined) {
          return records.schools[0].name.indexOf(value) === 0
        }
      },
      render: records => {
        return <>{records.map(e => e.name)}</>
      }
    },
    {
      title: 'Grade',
      dataIndex: ['grade']
    },
    {
      title: '',
      dataIndex: 'studentMeetingsGeneral',

      render: (records, record) => {
        const menu = <Menu>{meetingItems(records, true)}</Menu>
        return (
          <Space size='small'>
            <Button type='primary' onClick={() => addMeeting(record)}>
              <PlusOutlined />
              Add a Meeting
            </Button>
          </Space>
        )
      }
    }
  ]

  let singleStudent = desktopData?.filter(
    variable => variable.id === data?.student.id
  )
  let studentId = data?.student.id
  let schoolId = data?.student.schoolIds[0]
  const commonFormProps = {
    studentId,
    formId,
    schoolId
  }

  const CurrentGoalsDisplay = () => {
    return (
      <>
        {data.student.currentGoals ? (
          <>
            {' '}
            {data?.student?.currentGoals.map(x => (
              <CurrentGoals x={x} />
            ))}
          </>
        ) : (
          <Spin />
        )}
      </>
    )
  }

  const PriorGoalsDisplay = () => {
    return (
      <>
        {data.student.priorGoals ? (
          <>
            {' '}
            {data?.student?.priorGoals.map(x => (
              <PriorGoals x={x} />
            ))}
          </>
        ) : (
          <Spin />
        )}
      </>
    )
  }
  return (
    <>
      {data != undefined && !loading ? (
        <>
          <Table
            columns={columns}
            dataSource={singleStudent}
            expandable={{
              expandedRowRender,
              defaultExpandAllRows: false,
            }}
            rowKey={record => record.id}
            pagination={false}
            scroll={{ x: 'calc(400px + 50%)' }}
          />
          <Collapse defaultActiveKey={['1']}>
            <Panel header='Active IEP Goals'>
              {data?.student.currentGoals.length > 0 ? (
                <>
                  <CurrentGoalsDisplay />
                </>
              ) : (
                <>Student has no active IEP goals</>
              )}
            </Panel>
          </Collapse>
          <Collapse defaultActiveKey={['999']}>
            <Panel header='Prior IEP Goals'>
              {data?.student.priorGoals.length > 0 ? (
                <>
                  <PriorGoalsDisplay />
                </>
              ) : (
                <>Student has no prior IEP goals</>
              )}
            </Panel>
          </Collapse>
          <MeetingSISProvider>
            <Collapse defaultActiveKey={['1']}>
              <Panel
                header='Student IEP Meta and Placement Information'
                key='9001'
              >
                <MeetingSISForm {...commonFormProps} {...data} />
              </Panel>
            </Collapse>
          </MeetingSISProvider>
          <Collapse defaultActiveKey={['1']}>
            <Panel header='Student Documents' key='9001'>
              {data?.student?.uploadedDocs.length === 0 && (
                <>Student has no imported documents.</>
              )}
              <>
                {data?.student?.uploadedDocs.map(x => {
                  return <RenderStudentHistory pdfData={x} />
                })}
              </>
            </Panel>
          </Collapse>
        </>
      ) : (
        <Card>
          <Spin />
        </Card>
      )}
    </>
  )
}

Listing.propTypes = {
  addMeeting: PropTypes.func
}

const StudentGoals = ({ desktopData, id }) => {
  const [addMeetingVisible, setAddMeetingVisible] = useState(false)
  const [addMeetingStudent, setAddMeetingStudent] = useState()
  const history = useNavigate()
  const addMeeting = record => {
    setAddMeetingStudent(record)
    setAddMeetingVisible(true)
  }
  const afterMeetingCreated = meetingId => {
    setAddMeetingVisible(false)
    setAddMeetingStudent()
    history('/student-form/' + meetingId)
  }

  return (
    <>
      <Listing addMeeting={addMeeting} desktopData={desktopData} id={id} />
      {addMeetingVisible ? (
        <AddMeetingForm
          student={addMeetingStudent}
          visible={addMeetingVisible}
          afterCreated={afterMeetingCreated}
          onCancel={() => {
            setAddMeetingVisible(false)
          }}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default StudentGoals
