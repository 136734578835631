import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
// Create Auth context
const IepFormContext = React.createContext()

const IepFormProvider = props => {
  // selected form to render in FormArea
  const [currentEditableForm, setCurrentEditableForm] = useState({
    studentFormId: '',
    formId: '',
    formCode: '',
    versionId: '',
    formStatusId: '',
    formStatusEnumName: '',
    isFinalized: false,
    newFormStatus: '',
    isFinalizeChecked: false,
    isAmendChecked: false,
    pdfData: {},
    meetingType: ''
  })
  const [currentStudentInfo, setStudentFormInfo] = useState({
    studentId: '',
    schoolId: '',
    districtId: ''
  })

  const [hasViewPermission, setViewPermission] = useState(false)
  const [hasPrintPermission, setPrintPermission] = useState(false)
  const [hasAddPermission, setAddPermission] = useState(false)

  const [studentMeetingId, setStudentMeetingId] = useState('')

  const [keyDates, setKeyDates] = useState('')
  const [formWarning, setFormWarning] = useState('')

  const [meetingDate, setMeetingDate] = useState(Date.now())

  return (
    <IepFormContext.Provider
      value={{
        currentEditableForm,
        currentStudentInfo,
        setCurrentEditableForm,
        setStudentFormInfo,
        studentMeetingId,
        setStudentMeetingId,
        keyDates,
        setKeyDates,
        formWarning,
        setFormWarning,
        hasAddPermission,
        setAddPermission,
        hasViewPermission,
        setViewPermission,
        hasPrintPermission,
        setPrintPermission,
        meetingDate,
        setMeetingDate
      }}
    >
      {props.children}
    </IepFormContext.Provider>
  )
}

IepFormProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export default IepFormProvider

export { IepFormContext, IepFormProvider }
