import React from "react";
import PropTypes from "prop-types";

import FileDownload from "./FileDownload";
import FilePrint from "./FilePrint";

const FILE_ACTIONS = { print: "print", download: "download", save: "save" };

const FileAction = (props) => {
  const { action } = props;
  //console.log("In FileAction: ", props)
  return (
    <>
      {action === FILE_ACTIONS.print && <FilePrint {...props} />}
      {action === FILE_ACTIONS.download && <FileDownload {...props} />}
    </>
  );
};

FileAction.propTypes = {
  url: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
};

export default FileAction;

export { FILE_ACTIONS };
