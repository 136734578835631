import { gql } from "@apollo/client";

export const QUERY_FORM3457K_VERSION = gql`
  query studentForm3457K(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
  ) {
    studentForm3457K(
      studentFormId: $studentFormId
      studentId: $studentId
      formId: $formId
      schoolId: $schoolId
      studentMeetingId: $studentMeetingId
    ) {
      versionId
      formId
      formDate
      studentFullName
      studentDob
      ageOfMajorityDate
      notDelegatedRights
      notDelegatedRightsDate
      delegatedRights
      parentName
      parentRelationship
      parentDelegatedRightsSignatureDate
      studentDelegatedRightsSignatureDate
      authSignatureDate
      terminateRights
      terminateRightsStudentSignatureDate
      formAction
      formStatus
    }
  }
`;
