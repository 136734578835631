import React from "react";
import Space from "antd/lib/space";
import Button from "antd/lib/button";

const WorkflowNavigation = () => {
  return (
    <div className="workflow-links grey-area">
      <Space size={0}>
        <Button className="view-btn" type="default">
          View
        </Button>
        <Button className="view-btn" type="default">
          View
        </Button>
        <Button className="view-btn" type="default">
          View
        </Button>
      </Space>
    </div>
  );
};

export default WorkflowNavigation;
