import { gql } from "@apollo/client";

export const QUERY_GRADES = gql`
  query ($searchCriteria: String) {
    grades(searchCriteria: $searchCriteria) {
      id
      districtId
      name
      order
      nameGeneral
      hidden
      isGlobal
    }
  }
`;

export const QUERY_GRADE = gql`
  query Grade($id: ID!) {
    grade(id: $id) {
      id
      districtId
      name
      order
      hidden
      createdBy
      createdAtAgo
      updatedAtAgo
      createdAt(format: "ddd mmm dd yyyy h:MM:ss TT Z")
      updatedAt(format: "ddd mmm dd yyyy h:MM:ss TT Z")
    }
  }
`;
