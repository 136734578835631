import { gql } from "@apollo/client";

export const QUERY_FORM3454P_VERSION = gql`
  query studentForm3454P(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
  ) {
    studentForm3454P(
      studentFormId: $studentFormId
      studentId: $studentId
      formId: $formId
      schoolId: $schoolId
      studentMeetingId: $studentMeetingId
    ) {
      versionId
      formId
      formDate
      studentFullName
      intiationDate
      durationDate
      generalEducationNoSupplementary
      generalEducationNoSupplementaryminutesPerWeek
      generalEducationSupplementaryAids
      generalEducationSupplementaryAidsMinutesPerWeek
      generalEducationClassroom
      generalEducationClassroomMinutesPerWeek
      outsideGeneralEducation
      outsideGeneralEducationMinutesPerWeek
      outsideRelatedServices
      outsideRelatedServicesMinutesPerWeek
      regular35ProgramMinutesPerWeek
      regularABProgramMinutesPerWeek
      totalBelltoBellMinutes
      totalNumberMinutesOutsideGeneralEducation
      totalNumberMinutesInsideGeneralEducation
      percentageInsideGeneralEducationEnvironment
      formAction
      formStatus
    }
  }
`;
