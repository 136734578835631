import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Form, Input, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const DistrictSearchForm = ({ onSearch }) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    onSearch(values);
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <div>
      <Form
        form={form}
        layout="horizontal"
        id="districtSearchForm"
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={32}>
          <Col xl={6} lg={8} md={9} sm={12} xs={24}>
            <Form.Item name="searchCriteria">
              <Input
                placeholder="Search districts"
                prefix={<SearchOutlined />}
              />
            </Form.Item>
          </Col>

          <Col>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
            <Button
              style={{ margin: "0 8px" }}
              onClick={() => {
                form.resetFields();
                form.submit();
              }}
            >
              Reset
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

DistrictSearchForm.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

export default DistrictSearchForm;
