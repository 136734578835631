// react
import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useParams, Link } from 'react-router-dom'

// apollo & operations
import { useQuery, useMutation } from '@apollo/client'
import { QUERY_USER, QUERY_USERS } from '../../../operations/queries/users'
import {
  INSERT_USER,
  UPDATE_USER,
  EMAIL_INVITE_LINK,
  EMAIL_USER_PASSWORD_RESET_LINK
} from '../../../operations/mutations/users'

import { ADD_SUBROLE_TO_USER } from '../../../operations/mutations/subroles'

// ant design
import { Form, Input, Button, Space, notification, Divider } from 'antd'
import { MailOutlined } from '@ant-design/icons'

// elements
import FormItemSelectRole from '../../elements/formItems/FormItemSelectRole'
import FormItemSelectSubRole from '../../elements/formItems/FormItemSelectSubRole'
import FormItemSelectDistrict from '../../elements/formItems/FormItemSelectDistrict'
import FormItemSelectSchool from '../../elements/formItems/FormItemSelectSchool'
import FormItemsSuperAdmin from '../../elements/formItems/FormItemsSuperAdmin'
import FormItemError from '../../elements/formItems/FormItemError'

// other libraries
import AuthContext from '../../../utils/authProvider'
import formatErrors from '../../../utils/form'
import FormContainer from '../../../components/FormContainer'
import ModalDialog from '../../../components/ModalDialog'

import notificationSuccess from '../../../utils/notification'
import { notificationError } from '../../../utils/notification'

const FormComponent = ({ data }) => {
  const Auth = useContext(AuthContext)
  const isSuperAdmin = Auth.hasRole('SUPER_ADMIN')
  console.log('isSuper?', isSuperAdmin)
  const history = useNavigate()
  const { id } = useParams()
  const [form] = Form.useForm()
  const [formError, setFormError] = useState()
  const [itemErrors, setItemErrors] = useState({})
  const [addSubRoleToUser] = useMutation(ADD_SUBROLE_TO_USER)
  const [updateUser, { loading: updateLoading }] = useMutation(UPDATE_USER)
  const [insertUser, { loading: insertLoading }] = useMutation(INSERT_USER, {
    update(cache, { data: addUser }) {
      const data = cache.readQuery({ query: QUERY_USERS })
      if (data) {
        cache.writeQuery({
          query: QUERY_USERS,
          data: { users: [addUser.insertUser, ...data.users] }
        })
      }

    }
  })

  const [sendEmailInvite, { loading: inviteLoading }] =
    useMutation(EMAIL_INVITE_LINK)

  const [emailUserPasswordResetLink] = useMutation(
    EMAIL_USER_PASSWORD_RESET_LINK
  )

  const continueEditing = _id => _id && history(`/user/${_id}`)

  const onFinish = async values => {
    const subRoleId = values.subRoleId
    // delete (values.subRoleId)
    // if (!subRoleId) console.log('data', data)
    try {
      clearErrors()
      const mutated = id
        ? await updateUser({ variables: { id: id, userInput: values } })
        : await insertUser({ variables: values })

      if (id || mutated.data.insertUser?.id) {
        // this is weird because creating multiple subRoleIds per user copuld be possible in fut
        await addSubRoleToUser({
          variables: { userId: id || mutated.data.insertUser?.id, subRoleId }
        })
      }

      //Send invite if this is a new user (id===null)
      if (id === null) {
        await sendEmailInvite({
          variables: { id: mutated.data.insertuser?.id }
        })
      }

      // ModalDialog.success({
      //   title: 'Success',
      //   content: `The user has been ${id ? 'updated.' : 'added and an invite email has been sent.'}`,
      //   okText: 'Return to listing',
      //   onOk: () => history('/users'),
      //   cancelText: 'Continue editing',
      //   onCancel: () => continueEditing(mutated.data.insertUser?.id)
      // })
      notificationSuccess(
        `The user has been ${id ? 'updated.' : 'added and an invite email has been sent.'
        }`
      )
    } catch (e) {
      setErrors(formatErrors(e))
    }
  }

  const setErrors = errors => {
    if (errors.itemErrors) {
      setItemErrors(errors.itemErrors)
    }
    if (errors.formError) {
      setFormError(errors.formError)
    }
  }

  const clearErrors = () => {
    setFormError()
    setItemErrors({})
  }

  const handleResetPasswordClick = async () => {
    try {
      const email = data.user.email
      await emailUserPasswordResetLink({ variables: { email: email } })
      notificationSuccess('A reset password link was sent to ' + email)
    } catch (error) {
      const message =
        error?.metadata?.graphQLErrors?.[0]?.message ||
        error?.message ||
        'An unknown error occurred, please contact your administrator. E006'
      notificationError(
        'Reset password failed',
        message + '. Please contact your administrator.'
      )
    }
  }

  return (
    <>
      {data && (
        <Form
          form={form}
          id='userForm'
          name='userForm'
          onFinish={onFinish}
          preserve={false}
          initialValues={data.user}
          labelCol={{ sm: 8, md: 5, lg: 6, xl: 4, xxl: 3 }}
          wrapperCol={{ sm: 10, md: 7, lg: 8, xl: 6, xxl: 4 }}
        >
          <Form.Item
            label='Email'
            name='email'
            hasFeedback
            validateStatus={itemErrors.email ? 'error' : ''}
            help={itemErrors.email}
          >
            <Input />
          </Form.Item>

          <FormItemSelectRole
            label='Role(s)'
            name='roleIds'
            mode='multiple'
            disabled={!isSuperAdmin}
            validateStatus={itemErrors.roleIds ? 'error' : ''}
            help={itemErrors.rolesIds}
          />

          <FormItemSelectSubRole
            label='Schools General Subrole'
            name='subRoleId'
            validateStatus={itemErrors.subRoleId ? 'error' : ''}
            help={itemErrors.subRoleId}
          />

          <FormItemSelectDistrict
            label='District admin'
            name='userAdminDistrictIds'
            mode='multiple'
            disabled={!isSuperAdmin}
            validateStatus={itemErrors.userAdminDistrictIds ? 'error' : ''}
            help={itemErrors.userAdminDistrictIds}
          />

          <FormItemSelectSchool
            label='School admin'
            name='userAdminSchoolIds'
            mode='multiple'
            validateStatus={itemErrors.userAdminSchoolIds ? 'error' : ''}
            help={itemErrors.userAdminSchoolIds}
          />

          <FormItemSelectSchool
            label='School general access'
            name='userGeneralSchoolIds'
            mode='multiple'
            validateStatus={itemErrors.userGeneralSchoolIds ? 'error' : ''}
            help={itemErrors.userGeneralSchoolIds}
          />

          <Space direction='vertical'>
            {id && (
              <Button
                type='primary'
                icon={<MailOutlined />}
                loading={inviteLoading}
                //fix this eventually, bandaid
                onClick={handleResetPasswordClick}
              >
                Send password reset link{' '}
              </Button>
            )}

            <FormItemsSuperAdmin data={data.user} hasHidden />

            <FormItemError error={formError} />

            <Form.Item>
              <Button
                type='primary'
                htmlType='submit'
                loading={insertLoading || updateLoading}
              >
                {id ? 'Update' : 'Add'}
              </Button>
            </Form.Item>
          </Space>
        </Form>
      )}
    </>
  )
}

FormComponent.propTypes = {
  data: PropTypes.object.isRequired
}

const UserForm = () => {
  const { id } = useParams()

  const { data, loading } = useQuery(QUERY_USER, {
    variables: { id },
    skip: !id
  })
  const initialValues = { user: { email: '' } }

  return (
    <FormContainer
      loading={loading}
      size={2}
      form={<FormComponent data={{ ...initialValues, ...data }} />}
    />
  )
}

export default UserForm
