import { gql } from "@apollo/client";

export const PRINT_FORM3457E = gql`
  mutation printStudentForm3457E(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $studentDob: String
    $parent1: String
    $parent2: String
    $meetingDateAndTime: String
    $meetingLocation: String
    $eligibility: Boolean
    $eligibilityCategory: String
    $requiresSpecialEducationChange: Boolean
    $requiresSeparateEducationalSetting: Boolean
    $willReceiveSpecialEducation: Boolean
    $willReceiveGoalsTransitions: Boolean
    $willReceiveFunctionalBehavioral: Boolean
    $disciplinaryCodeViolation: String
    $graduationRecommended: Boolean
    $other: Boolean
    $otherDescription: String
    $waiveTenDaysRequirement: String
    $parentSignatureDate: String
    $documentContactName: String
    $documentContactTitle: String
    $documentContactPhone: String
    $signatureName: String
    $signatureTitle: String
    $formAction: String
$formStatus: String
  ) {
    printStudentForm3457E(
      studentForm3457EInput: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        studentDob: $studentDob
        parent1: $parent1
        parent2: $parent2
        meetingDateAndTime: $meetingDateAndTime
        meetingLocation: $meetingLocation
        eligibility: $eligibility
        eligibilityCategory: $eligibilityCategory
        requiresSpecialEducationChange: $requiresSpecialEducationChange
        requiresSeparateEducationalSetting: $requiresSeparateEducationalSetting
        willReceiveSpecialEducation: $willReceiveSpecialEducation
        willReceiveGoalsTransitions: $willReceiveGoalsTransitions
        willReceiveFunctionalBehavioral: $willReceiveFunctionalBehavioral
        disciplinaryCodeViolation: $disciplinaryCodeViolation
        graduationRecommended: $graduationRecommended
        other: $other
        otherDescription: $otherDescription
        waiveTenDaysRequirement: $waiveTenDaysRequirement
        parentSignatureDate: $parentSignatureDate
        documentContactName: $documentContactName
        documentContactTitle: $documentContactTitle
        documentContactPhone: $documentContactPhone
        signatureName: $signatureName
        signatureTitle: $signatureTitle
formAction: $formAction
formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;
