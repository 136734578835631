import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "antd";
import { LoadingOutlined, CheckCircleTwoTone } from "@ant-design/icons";

const SyncSISButton = ({ children, loading, sisValue, currentValue }) => {
  if (sisValue === currentValue) {
    return (
      <Tooltip title="No update required">
        <CheckCircleTwoTone twoToneColor="#52c41a" />
      </Tooltip>
    );
  }

  if (loading) {
    return <LoadingOutlined />;
  }

  return <Tooltip title={`Update available: replace ${currentValue} with ${sisValue} ?`}>{children}</Tooltip>;
};

SyncSISButton.propTypes = {
  children: PropTypes.element.isRequired,
  loading: PropTypes.bool.isRequired,
  sisValue: PropTypes.string.isRequired,
  currentValue: PropTypes.string.isRequired,
};

export default SyncSISButton;
