import { gql } from "@apollo/client";

export const PRINT_FORM3454L = gql`
  mutation printStudentForm3454L(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $studentDob: String
    $environment: String
    $instructionCurriculum: String
    $positiveSupports: String
    $motivatorsRewards: String
    $restrictiveDisciplinaryMeasures: String
    $crisisPlan: String
    $dataCollectionProcedures: String
    $caregiverProvisions: String
    $formAction: String
$formStatus: String
  ) {
    printStudentForm3454L(
      studentForm3454LInput: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        studentDob: $studentDob
        environment: $environment
        instructionCurriculum: $instructionCurriculum
        positiveSupports: $positiveSupports
        motivatorsRewards: $motivatorsRewards
        restrictiveDisciplinaryMeasures: $restrictiveDisciplinaryMeasures
        crisisPlan: $crisisPlan
        dataCollectionProcedures: $dataCollectionProcedures
        caregiverProvisions: $caregiverProvisions
formAction: $formAction
formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;
