import React, { useContext } from "react";
import PropTypes from "prop-types";

// apollo & operations
import { useQuery } from "@apollo/client";
import { QUERY_ROLES } from "../../../operations/queries/roles";

// ant design
import { Form, Select } from "antd";

const FormItemSelectRole = ({
  label,
  name,
  disabled,
  validateStatus,
  help,
  hasFeedback,
  mode,
}) => {
  const { data, loading } = useQuery(QUERY_ROLES);
  return (
    <Form.Item
      label={label}
      name={name}
      validateStatus={validateStatus}
      help={help}
      hasFeedback={hasFeedback}
    >
      <Select
        mode={mode}
        disabled = {disabled}
        loading={loading}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) =>
          optionA.children
            .toString().toLowerCase()
            .localeCompare(optionB.children.toString().toLowerCase())
        }
      >
        {data?.roles &&
          data.roles.map((role) => (
            <Select.Option key={role.id} value={role.id}>
              {role.name}
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  );
};

FormItemSelectRole.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  help: PropTypes.string,
  validateStatus: PropTypes.string,
  hasFeedback: PropTypes.bool,
  mode: PropTypes.string,
};

export default FormItemSelectRole;
