import { gql } from "@apollo/client";

export const PRINT_FORM504C = gql`
  mutation printStudentForm504C(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
    $formDate: String
    $studentFullName: String
    $studentDob: String
    $studentImpairment: String
    $studentLifeActivityLimited: String
    $studentAccommodations: String
    $studentAccommodationsForStateAndDistrict: String
    $additionalComments: String
    $annualReviewDate: String
    $triennialReassessmentDate: String
    $districtContact: String
    $meetingAttendedParent1: String
    $meetingAttendedParent2: String
    $meetingAttendedStudent: String
    $meetingAttendedLEARepresentative: String
    $meetingAttendedGeneralEducationTeacher: String
    $meetingAttendedSpecialEducationTeacher: String
    $meetingAttendedSchoolPsychologist: String
    $meetingAttendedSchoolSocialWorker: String
    $meetingAttendedSpeechLanguagePathologist: String
    $meetingAttendedBilingualSpecialist: String
    $meetingAttendedInterpreter: String
    $meetingAttendedSchoolNurse: String
    $meetingAttendedOther: String
    $meetingAttendedOtherText: String
    $formAction: String
    $formStatus: String
  ) {
    printStudentForm504C(
      studentForm504CInput: {
        studentFormId: $studentFormId
        formId: $formId
        studentId: $studentId
        schoolId: $schoolId
        studentMeetingId: $studentMeetingId
        formDate: $formDate
        studentFullName: $studentFullName
        studentDob: $studentDob
        studentImpairment: $studentImpairment
        studentLifeActivityLimited: $studentLifeActivityLimited
        studentAccommodations: $studentAccommodations
        studentAccommodationsForStateAndDistrict: $studentAccommodationsForStateAndDistrict
        additionalComments: $additionalComments
        annualReviewDate: $annualReviewDate
        triennialReassessmentDate: $triennialReassessmentDate
        districtContact: $districtContact
        meetingAttendedParent1: $meetingAttendedParent1
        meetingAttendedParent2: $meetingAttendedParent2
        meetingAttendedStudent: $meetingAttendedStudent
        meetingAttendedLEARepresentative: $meetingAttendedLEARepresentative
        meetingAttendedGeneralEducationTeacher: $meetingAttendedGeneralEducationTeacher
        meetingAttendedSpecialEducationTeacher: $meetingAttendedSpecialEducationTeacher
        meetingAttendedSchoolPsychologist: $meetingAttendedSchoolPsychologist
        meetingAttendedSchoolSocialWorker: $meetingAttendedSchoolSocialWorker
        meetingAttendedSpeechLanguagePathologist: $meetingAttendedSpeechLanguagePathologist
        meetingAttendedBilingualSpecialist: $meetingAttendedBilingualSpecialist
        meetingAttendedInterpreter: $meetingAttendedInterpreter
        meetingAttendedSchoolNurse: $meetingAttendedSchoolNurse
        meetingAttendedOther: $meetingAttendedOther
        meetingAttendedOtherText: $meetingAttendedOtherText
        formAction: $formAction
        formStatus: $formStatus
      }
    ) {
      link
      fileName
    }
  }
`;
