import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { IepFormContext } from '../../../../utils/iepFormProvider'
import { useQuery } from '@apollo/client'
import { QUERY_STUDENT_COMMON_SIS } from '../../../../operations/queries/forms/SIS'
import { QUERY_FORM504D_VERSION } from '../../../../operations/queries/forms/504D'
import moment from 'moment'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import FormContainer from '../../../FormContainer'
import {
  Alert,
  Card,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Typography
} from 'antd'
import FormCollaboration from '../../../FormCollaboration'
import Title from 'antd/lib/typography/Title'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import StudentDobSIS from '../sis/StudentDobSIS'
import StudentParent1SIS from '../sis/StudentParent1SIS'
import StudentParent1PhoneSIS from '../sis/StudentParent1PhoneSIS'
import StudentParent2SIS from '../sis/StudentParent2SIS'
import StudentParent2PhoneSIS from '../sis/StudentParent2PhoneSIS'

import FormItem from 'antd/lib/form/FormItem'
import StudentFormActions from '../StudentFormActions'
import { PRINT_FORM504D } from '../../../../operations/mutations/forms/504D'
import Editor from '../../../elements/common/Editor'

const { Paragraph } = Typography

const FormRecord = ({
  data,
  studentFormId,
  studentId,
  formId,
  schoolId,
  studentMeetingId,
  sis,
  versionId
}) => {
  const [form] = Form.useForm()

  const { itemErrors, formError } = useContext(FormErrorContext)
  const [parentsList, setParentsList] = useState([])

  useEffect(() => {
    setParentsList(data.parents)
  }, [])

  const getFormFieldValues = () => {
    const formFieldValues = form.getFieldsValue()
    console.log(formFieldValues)
    return {
      ...formFieldValues,
      studentFormId: studentFormId,
      formId: formId,
      studentId: studentId,
      schoolId: schoolId,
      studentMeetingId: studentMeetingId
    }
  }

  const formatFormFieldUpdate = (fieldName, fieldValue) => {
    console.log(fieldName, fieldValue)
    const update = Object.assign({})
    switch (fieldName) {
      case 'formDate':
      case 'parentSignatureDate':
      case 'districtContactSignatureDate':
        update[fieldName] = fieldValue ? moment(fieldValue) : ''
        break
      case 'parentReceived504EligibilitySummary':
      case 'parentReceived504Plan':
      case 'parentConsent':
        update[fieldName] = fieldValue ? fieldValue : 'no'
        break
      default:
        update[fieldName] = fieldValue
    }
    return update
  }

  const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
    const update = formatFormFieldUpdate(fieldName, fieldValue)
    form.setFieldsValue(update)
  }

  return (
    <>
      <FormCollaboration
        form={form}
        itemErrors={itemErrors}
        studentFormId={studentFormId}
        handleUpdate={handleFieldCollaborationUpdate}
      >
        {({
          handleMouseEvents,
          handleFocusEvents,
          handleEditorEvents,
          formDisabledState,
          canSave,
          handleActionEvents,
          validationProps
        }) => (
          <Form
            form={form}
            id='studentForm504D'
            name='studentForm504D'
            initialValues={data}
            preserve
            layout='vertical'
            className='page-padding-leftRight page-padding-topBottom form-labels-bold'
          >
            <Title level={4} className='center'>
              CONSENT FOR SECTION 504 SERVICES
            </Title>
            <Form.Item
              label='Form Created Date'
              name='formDate'
              {...validationProps('formDate')}
            >
              <DatePicker
                format={[
                  'MM-DD-YYYY',
                  'MM/DD/YYYY',
                  'MM.DD.YY',
                  'M-D-YYYY',
                  'M/D/YYYY',
                  'M.D.YYYY',
                  'MM.DD.YYYY',
                  'MM-DD-YY',
                  'MM/DD/YY',
                  'M-D-YY',
                  'M/D/YY',
                  'M.D.YY',
                  'MMDDYYYY',
                  'MMDDYY'
                ]}
                {...handleFocusEvents}
                disabled={formDisabledState.formDate}
              />
            </Form.Item>

            <Card
              title='Student SIS Data'
              style={{
                marginBottom: 30
              }}
            >
              <Alert
                message='SIS fields are locked, please contact administrator in case of error.'
                type='warning'
                style={{ marginBottom: 20 }}
              />
              <StudentFullNameSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentFullName}
                error={itemErrors.studentFullName}
              />
              <StudentDobSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.studentDob}
                error={itemErrors.studentDob}
              />
              <StudentParent1SIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.parent1FullName}
                error={itemErrors.parent1FullName}
              />

              {/*Add Relationship sis when ready */}

              <StudentParent1PhoneSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.parent1PhoneNumber}
                error={itemErrors.parent1PhoneNumber}
              />
              <StudentParent2SIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.parent2FullName}
                error={itemErrors.parent2FullName}
              />

              {/*Add Relationship sis when ready */}

              <StudentParent2PhoneSIS
                form={form}
                sis={sis}
                id={studentId}
                initialValue={data.parent2PhoneNumber}
                error={itemErrors.parent2PhoneNumber}
              />
            </Card>

            <Card
              title='Materials Received'
              style={{
                marginBottom: 30
              }}
            >
              <FormItem
                label='Parent has received 504 Eligibility Summary'
                name='parentReceived504EligibilitySummary'
                {...validationProps('parentReceived504EligibilitySummary')}
              >
                <Radio.Group
                  defaultValue
                  defaultChecked='no'
                  buttonStyle='solid'
                  {...handleMouseEvents}
                  name='parentReceived504EligibilitySummary'
                  disabled={
                    formDisabledState.parentReceived504EligibilitySummary
                  }
                >
                  <Radio.Button value='yes'>Yes</Radio.Button>
                  <Radio.Button value='no'>No</Radio.Button>
                </Radio.Group>
              </FormItem>
              <FormItem
                label='Parent has received 504 Plan'
                name='parentReceived504Plan'
                {...validationProps('parentReceived504Plan')}
              >
                <Radio.Group
                  defaultValue
                  defaultChecked='no'
                  buttonStyle='solid'
                  {...handleMouseEvents}
                  name='parentReceived504Plan'
                  disabled={formDisabledState.parentReceived504Plan}
                >
                  <Radio.Button value='yes'>Yes</Radio.Button>
                  <Radio.Button value='no'>No</Radio.Button>
                </Radio.Group>
              </FormItem>
            </Card>

            <Card
              title='Parent Consent'
              style={{
                marginBottom: 30
              }}
            >
              <FormItem
                label='Parent grants consent for provision of 504 plan services'
                name='parentConsent'
                {...validationProps('parentConsent')}
              >
                <Radio.Group
                  defaultValue
                  defaultChecked='no'
                  buttonStyle='solid'
                  {...handleMouseEvents}
                  name='parentConsent'
                  disabled={formDisabledState.parentConsent}
                >
                  <Radio.Button value='yes'>Yes</Radio.Button>
                  <Radio.Button value='no'>No</Radio.Button>
                </Radio.Group>
              </FormItem>

              <Form.Item
                label='Signing Parent'
                name='parentSignatureName'
                validateStatus={itemErrors.parentSignatureName ? 'error' : ''}
                help={itemErrors.parentSignatureName}
                {...handleFocusEvents}
                {...validationProps('parentSignatureName')}
                hasFeedback
              >
                <Select
                  style={{ width: '100%' }}
                  allowClear
                  disabled={formDisabledState.parentSignatureName}
                >
                  {parentsList.map(parent => (
                    <Select.Option key={parent} value={parent}>
                      {parent}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label='Signature Date'
                name='parentSignatureDate'
                {...validationProps('parentSignatureDate')}
              >
                <DatePicker
                  format={[
                    'MM-DD-YYYY',
                    'MM/DD/YYYY',
                    'MM.DD.YY',
                    'M-D-YYYY',
                    'M/D/YYYY',
                    'M.D.YYYY',
                    'MM.DD.YYYY',
                    'MM-DD-YY',
                    'MM/DD/YY',
                    'M-D-YY',
                    'M/D/YY',
                    'M.D.YY',
                    'MMDDYYYY',
                    'MMDDYY'
                  ]}
                  {...handleFocusEvents}
                  disabled={formDisabledState.parentSignatureDate}
                />
              </Form.Item>
            </Card>

            <Card
              title='District Consent'
              style={{
                marginBottom: 30
              }}
            >
              <FormItem
                label='Name'
                name='districtContactName'
                {...validationProps('districtContactName')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.districtContactName}
                />
              </FormItem>
              <FormItem
                label='Title'
                name='districtContactTitle'
                {...validationProps('districtContactTitle')}
              >
                <Input
                  {...handleFocusEvents}
                  disabled={formDisabledState.districtContactTitle}
                />
              </FormItem>
              <Form.Item
                label='Signature Date'
                name='districtContactSignatureDate'
                {...validationProps('districtContactSignatureDate')}
              >
                <DatePicker
                  format={[
                    'MM-DD-YYYY',
                    'MM/DD/YYYY',
                    'MM.DD.YY',
                    'M-D-YYYY',
                    'M/D/YYYY',
                    'M.D.YYYY',
                    'MM.DD.YYYY',
                    'MM-DD-YY',
                    'MM/DD/YY',
                    'M-D-YY',
                    'M/D/YY',
                    'M.D.YY',
                    'MMDDYYYY',
                    'MMDDYY'
                  ]}
                  {...handleFocusEvents}
                  disabled={formDisabledState.districtContactSignatureDate}
                />
              </Form.Item>
            </Card>

            {formError && <Paragraph type='danger'>{formError}</Paragraph>}
            <StudentFormActions
              query={PRINT_FORM504D}
              extractDataProp='printStudentForm504D'
              canSave={canSave}
              handleActionEvents={handleActionEvents}

              getFormFieldValues={getFormFieldValues}
            />
          </Form>
        )}
      </FormCollaboration>
    </>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired,
  studentFormId: PropTypes.object.isRequired,
  studentId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  schoolId: PropTypes.string.isRequired,
  studentMeetingId: PropTypes.string.isRequired,
  sis: PropTypes.object.isRequired,
  versionId: PropTypes.string.isRequired
}

const StudentForm504D = () => {
  // get current form information
  const { currentStudentInfo, currentEditableForm, studentMeetingId } =
    useContext(IepFormContext)
  const { studentId, schoolId } = currentStudentInfo
  const { studentFormId, formId, versionId } = currentEditableForm

  // Load SIS Data / Form Version Data
  const { loading: studentLoading, data: studentData } = useQuery(
    QUERY_STUDENT_COMMON_SIS,
    { variables: { id: studentId } }
  )
  const { loading: formLoading, data: formData } = useQuery(
    QUERY_FORM504D_VERSION,
    {
      variables: {
        studentFormId: studentFormId,
        studentId: studentId,
        schoolId: schoolId,
        formId: formId,
        studentMeetingId: studentMeetingId
      }
    }
  )
  console.log(formData)
  const createInitialFormData = (studentInfo, versionInfo) => {
    let initialValues = {}
    if (!studentInfo || !versionInfo) return initialValues

    const { studentForm504D: form } = versionInfo
    console.log('versionInfo', versionInfo, form)
    const { student } = studentInfo
    const { dateOfBirth } = student
    const {
      formDate,
      studentFullName,
      studentDob,
      parent1FullName,
      parent1PhoneNumber,
      parent1Relationship,
      parent2FullName,
      parent2PhoneNumber,
      parent2Relationship,
      parentReceived504EligibilitySummary,
      parentReceived504Plan,
      parentConsent,
      parentSignatureName,
      parentSignatureDate,
      districtContactName,
      districtContactTitle,
      districtContactSignatureDate
    } = form

    initialValues = {
      ...form,
      formDate: formDate ? moment(formDate) : moment(Date.now()),
      studentFullName: studentFullName || student.fullName || '',
      studentDob: studentDob
        ? moment(studentDob).format('YYYY-MM-DD')
        : student.dateOfBirth
        ? moment(student.dateOfBirth).format('YYYY-MM-DD')
        : '',
      parent1FullName: parent1FullName
        ? parent1FullName
        : student.parent1FullName,
      parent1PhoneNumber: parent1PhoneNumber
        ? parent1PhoneNumber
        : student.parent1PhoneNumber,
      parent1Relationship: parent1Relationship
        ? parent1Relationship
        : student.parent1Relationship,
      parent2FullName: parent2FullName
        ? parent2FullName
        : student.parent2FullName,
      parent2PhoneNumber: parent2PhoneNumber
        ? parent2PhoneNumber
        : student.parent2PhoneNumber,
      parent2Relationship: parent2Relationship
        ? parent2Relationship
        : student.parent2Relationship,
      parentReceived504EligibilitySummary: parentReceived504EligibilitySummary
        ? parentReceived504EligibilitySummary
        : 'no',
      parentReceived504Plan: parentReceived504Plan
        ? parentReceived504Plan
        : 'no',
      parentConsent: parentConsent ? parentConsent : 'no',
      parentSignatureName: parentSignatureName || student.parent1FullName || '',
      parentSignatureDate: parentSignatureDate
        ? moment(parentSignatureDate)
        : moment(Date.now()),
      districtContactName: districtContactName ? districtContactName : '',
      districtContactTitle: districtContactTitle ? districtContactTitle : '',
      districtContactSignatureDate: districtContactSignatureDate
        ? moment(districtContactSignatureDate)
        : moment(Date.now()),
      parents: [student.parent1FullName, student.parent2FullName]
    }

    return initialValues
  }

  return (
    <FormErrorProvider>
      <FormContainer
        loading={studentLoading || formLoading}
        form={
          <FormRecord
            data={createInitialFormData(studentData, formData)}
            studentFormId={studentFormId}
            studentId={studentId}
            formId={formId}
            schoolId={schoolId}
            studentMeetingId={studentMeetingId}
            sis={studentData}
            versionId={studentFormId}
          />
        }
      />
    </FormErrorProvider>
  )
}
export default StudentForm504D
