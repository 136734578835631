import { gql } from "@apollo/client";

export const QUERY_FORM3457H_VERSION = gql`
  query studentForm3457H(
    $studentFormId: ID!
    $formId: ID!
    $studentId: ID!
    $schoolId: ID!
    $studentMeetingId: ID!
  ) {
    studentForm3457H(
      studentFormId: $studentFormId
      studentId: $studentId
      formId: $formId
      schoolId: $schoolId
      studentMeetingId: $studentMeetingId
    ) {
      versionId
      formId
      formDate
      studentFullName
      studentDob
      parent1
      parent2
      meetingDateAndTime
      parentSignatureDate
      authSignatureDate
      communicationMode
      agreeMemberNotDiscussed
      membersNotDiscussed
      agreeMemberDiscussed
      membersDiscussed
      documentContactName
      documentContactTitle
      documentContactPhone
      signatureName
      signatureTitle
      formAction
      formStatus
    }
  }
`;
