// react
import React, { useState } from "react";

// ant design
import { Card } from "antd";

// operations
import { QUERY_ISTAR_REPORT } from "../../../operations/queries/reportBuilder";

// components
import Listing from "../../Listing";

import { flatten } from "flat";
import IStarReportSearchForm from "./IStarReportSearchForm";

const IStarReport = () => {
  const [criterias, setCriterias] = useState();

  const onSearch = (criterias) => {
    console.log(criterias);
    setCriterias(criterias);
  };

  /**
   * This function expands the data for the csv download
   * @param {*} data query data
   * @returns Array of objects, query data expended for csv columns
   */
  const expandData = (data) =>
    data.iStarReport.map((item) => {
      const { student, Form3454B, Form3454Q, Form3454P } = item;
      const forms = { Form3454B, Form3454Q, Form3454P };
      const flatData = {
        ...flatten(student),
        ...flatten(forms),
      };

      // Remove __typename fields
      const filteredKeys = Object.keys(flatData).filter((x) => {
        if (x.indexOf("__typename") !== -1) {
          return false;
        }
        return true;
      });

      const data = {};
      filteredKeys.forEach((key) => {
        data[key] = flatData[key];
      });

      return data;
    });

  const columns = [
    {
      title: "Student Name",
      dataIndex: ["student", "fullName"],
      // sorter: (a, b) => a.meetingDate.localeCompare(b.meetingDate)
    },
    {
      title: "Gender",
      dataIndex: ["student", "gender", "name"],
      render: (value, record) => {
        if (!value) {
          return "";
        }
        return value;
      },
    },
    {
      title: "School",
      dataIndex: ["student", "schools"],
      render: (value, record) => {
        if (!value) {
          return "";
        }
        const schoolNames = value.map((x) => x.name);
        return schoolNames.join(", ");
      },
    },
  ];

  const fields = {
    studentName: {
      header: "Student Name",
      formatter: (fieldValue) => fieldValue,
    },
    gender: {
      header: "Gender",
      formatter: (fieldValue) => fieldValue,
    },
    school: {
      header: "Schools",
      formatter: (fieldValue) => fieldValue,
    },
  };

  return (
    <>
      <div className="page-container">
        <Card title="Filters" bordered={false}>
          <IStarReportSearchForm onSearch={onSearch} />
        </Card>
      </div>
      {!!criterias && (
        <Listing
          query={QUERY_ISTAR_REPORT}
          exportable={{
            exportTableButton: {
              fields,
            },
          }}
          columns={columns}
          criterias={criterias}
          fields={fields}
          dataKey="iStarReport"
          expandedFields
          expandData={expandData}
          render={(table, exportCsvButton) => {
            return (
              <>
                <div className="page-container">
                  <Card
                    title="Meetings report"
                    bordered={false}
                    extra={exportCsvButton}
                  >
                    {table}
                  </Card>
                </div>
              </>
            );
          }}
        />
      )}
    </>
  );
};

export default IStarReport;
