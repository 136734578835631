// react
import React, { useState } from "react";

// ant design
import { Card } from "antd";

// operations
import { QUERY_CUSTOM_MEETING_REPORT } from "../../../operations/queries/reportBuilder";

// components
import MeetingsReportSearchForm from "./MeetingsReportSearchForm";
import Listing from "../../Listing";

import { flatten } from "flat";

const MeetingsReport = () => {
  const [criterias, setCriterias] = useState();

  const onSearch = (criterias) => {
    console.log(criterias);
    setCriterias(criterias);
  };

  /**
   * This function expands the data for the csv download
   * @param {*} data query data
   * @returns Array of objects, query data expended for csv columns
   */
  const expandData = (data) =>
    data.meetingReport.map((item) => {
      const submittedData = item.submissionInfo.submittedData;
      const formCode = item.form.formCode;
      const dataKeys = Object.keys(submittedData);
      if (!dataKeys) return {};
      const entry = {};
      dataKeys.forEach((dataPoint) => {
        entry[`${formCode}.${dataPoint}`] = submittedData[dataPoint];
      });
      return {
        meetingDate: item.meetingDate,
        school: item.school.name,
        formType: item?.form?.formTypes?.map((type) => type.enumName).join(","),
        status: item?.status?.enumName,
        ...flatten(entry),
      };
    });

  const columns = [
    {
      title: "Meeting date",
      dataIndex: "meetingDate",
      sorter: (a, b) => a.meetingDate.localeCompare(b.meetingDate),
    },
    {
      title: "School",
      dataIndex: ["school", "name"],
    },
    {
      title: "Form",
      dataIndex: "form",
      render: (value, record) => value.formCode,
    },
  ];

  const fields = {
    meetingDate: {
      header: "Meeting date",
      formatter: (fieldValue) => fieldValue,
    },
    school_name: {
      header: "School",
      formatter: (_, record) => {
        return record?.school?.name;
      },
    },
    formCode: {
      header: "Form",
      formatter: (_, record) => {
        return record?.form?.formCode;
      },
    },
    formType: {
      header: "Form type",
      formatter: (_, record) => {
        return record?.form?.formTypes?.map((type) => type.enumName).join(",");
      },
    },
    status: {
      header: "Status",
      formatter: (_, record) => {
        return record?.status?.enumName;
      },
    },
    submissionInfo: {
      header: "Submission Data",
      formatter: (_, record) => {
        return JSON.stringify(record?.submissionInfo?.submittedData);
      },
    },
  };

  return (
    <>
      <div className="page-container">
        <Card title="Filters" bordered={false}>
          <MeetingsReportSearchForm onSearch={onSearch} />
        </Card>
      </div>
      {!!criterias && (
        <Listing
          query={QUERY_CUSTOM_MEETING_REPORT}
          exportable={{
            exportTableButton: {
              fields,
            },
          }}
          columns={columns}
          criterias={criterias}
          fields={fields}
          dataKey="meetingReport"
          expandedFields
          expandData={expandData}
          render={(table, exportCsvButton) => {
            return (
              <>
                <div className="page-container">
                  <Card
                    title="Meetings report"
                    bordered={false}
                    extra={exportCsvButton}
                  >
                    {table}
                  </Card>
                </div>
              </>
            );
          }}
        />
      )}
    </>
  );
};

export default MeetingsReport;
