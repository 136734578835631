import React, { useState, useContext } from "react";
import { Layout, Row, Col } from "antd";
import { Link } from "react-router-dom";

// components
import Header from "./Header";
import SideBar from "./SideBar";
import SideMenu from "./SideMenu";

// routes
import Router from "../Routes";

// contexts
import AuthContext from "../../utils/authProvider";

const { Content, Footer } = Layout;

const SchoolLayout = () => {
  const Auth = useContext(AuthContext);
  const isSuperAdmin = Auth.hasRole("SUPER_ADMIN");
  const isDistrictAdmin = Auth.hasRole("DISTRICTS_ADMIN");
  const isSchoolsAdmin = Auth.hasRole("SCHOOLS_ADMIN");
  const [collapsed, setCollapsed] = useState(false);

  const onClose = () => {
    setCollapsed(false);
  };

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Layout className="app-layout" style={{ minHeight: "100vh" }}>
      <Header toggle={toggle} collapsed={collapsed} />

      <SideBar
        menu={<SideMenu />}
        theme="light"
        onClose={onClose}
        collapsed={collapsed}
        showSider={isSuperAdmin || isDistrictAdmin || isSchoolsAdmin}
      />
      <Layout className="content-layout">
        <Content>
          <Layout>
            <Router />
          </Layout>
        </Content>
        <Row justify="center">
          <Col >
            <a href="http://tameshrs.com/" >
              © {new Date().getFullYear()} TAMES/Health Resource Service Management,
              Inc.
            </a>
          </Col>
        </Row>
      </Layout>
    </Layout>
  );
};

export default SchoolLayout;
