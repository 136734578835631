import React from "react";

import Page from "../../components/Page";
import LookupList from "../../components/sections/lookup/LookupList";

const Lookups = () => {
  const breadcrumb = [
    {
      path: "/",
      breadcrumbName: "Home",
    },
    {
      path: "/lookups",
      breadcrumbName: "Lookups",
    },
  ];
  return (
    <>
      <Page title="Lookups" breadcrumb={breadcrumb}>
        <LookupList />
      </Page>
    </>
  );
};

export default Lookups;
