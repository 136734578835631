import { gql } from "@apollo/client";

export const MEETING_META_SUBSCRIPTION = gql`
  subscription meetingMetaUpdated($meetingId: ID!) {
    meetingMetaUpdated(meetingId: $meetingId) {
      meetingId
      meta {
        caseManager
        anticipatedGraduationDate
        residentDistrict
        residentSchool
        servingDistrict
        servingSchool
        currentGrade
        nextYearCaseManager
        nextYearGrade
        nextYearResidentDistrict
        nextYearResidentSchool
        nextYearServingDistrict
        nextYearServingSchool
        lastAnnualReviewDate
        nextAnnualReviewDate
        initialEvaluationConsent
        initialEvaluationConsentDate
        reEvaluationConsent
        reEvaluationConsentDate
        triennialReEvaluationDueDate
      }
    }
  }
`;
